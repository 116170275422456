import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { AssetFactory, IMAGE_ASSET_TYPE } from '@eagleview/mapviewer';
import EVMapViewer from '@eagleview/mapviewer-react';
import ErrorBoundary from 'components/ErrorBoundary';
import { REACT_APP_ENV, ZOOM_WITH_EASE_DURATION } from 'constants.js';
import useResizeObserver from 'utils/useResizeObserver';
import { isEmpty, get } from 'lodash';
import cameraIcon from 'assets/pointer-camera.svg';
import cameraIconSelected from 'assets/pointer-camera-selected.svg';
import { errorHandlerService } from 'utils/utils';
import { DEFAULT_ZOOM_LEVEL, PHOTOS_MAX_ZOOM_LEVEL } from 'layout/photos/photos.constant';
import debounce from 'lodash/debounce';
import * as photosUtil from '../photos.utils';

const PhotosMapViewer = ({
  imageCorners,
  image,
  cameraPositions,
  zoomChanged,
  setZoomLevel,
  setSelectedAnnotation,
  cameraIconHoveredFromGrid,
  rotation,
}) => {
  // local state
  const [initialZoomLevel, setInitialZoomLevel] = useState();
  const [bounds, setBounds] = useState();
  const [localCurrentView, setLocalCurrentView] = useState({});
  const [imageAsset, setImageAsset] = useState([]);
  const [cameraAsset, setCameraAsset] = useState([]);
  const [isMapReady, setIsMapReady] = useState(false);
  const [interactionObject, setInteractionObject] = useState({});
  const [iconHovered, setIconHovered] = useState('');
  const [mapView, setMapView] = useState({ zoom: DEFAULT_ZOOM_LEVEL, rotation: 0, lonLat: { lat: 0, lon: 0 } });

  // refs and constants
  const { elRef: mapViewerRef, size: mapSize } = useResizeObserver();
  const imageName = get(image, 'imageName', '');

  const resizeMap = () => {
    const mapRef = mapViewerRef;
    if (mapRef.current && mapRef.current.resizeMap) {
      const zoom = mapRef.current.resizeMap();
      setInitialZoomLevel(parseFloat(zoom.toFixed(4)));
    }
  };

  const getImageBoundPolygon = (coords) => [
    [coords.ul.lon, coords.ul.lat],
    [coords.ur.lon, coords.ur.lat],
    [coords.br.lon, coords.br.lat],
    [coords.bl.lon, coords.bl.lat],
    [coords.ul.lon, coords.ul.lat],
  ];

  const getCameraPositionsAsset = (camPositions) => {
    const data = camPositions.map((item) => ({
      geometries: [
        {
          type: 'Point',
          coordinates: [
            parseFloat(get(item, 'camera_position.lon', 0)),
            parseFloat(get(item, 'camera_position.lat', 0)),
            0,
          ],
        },
      ],
      properties: {
        trackEnabled: true,
        cursorStyle: 'pointer',
        annotationID: get(item, 'name', ''),
        rotatedLabel: false,
        showTextAnchor: true,
        showLabel: true,
        iconRotation: item.rotation_angle,
      },
      style: {
        text: {
          color: '#fff',
          backgroundImage: cameraIcon,
          zIndex: 5,
        },
      },
    }));

    return AssetFactory.getAsset({
      assetType: 'annotation',
      data,
    });
  };

  const zoomWithEaseTo = (zoom) => {
    mapViewerRef.current.easeTo({
      zoom,
      duration: ZOOM_WITH_EASE_DURATION,
      essential: true,
      animate: true,
    });
  };

  const highlightCameraIcon = (imgName) => {
    const cameraPositionsAsset = cameraAsset;
    if (!cameraPositionsAsset || isEmpty(cameraPositionsAsset.data)) return;
    const updatedCameraAsset = {
      ...cameraPositionsAsset,
      data: cameraPositionsAsset.data.map((item) => {
        const selectedIcon = get(item, 'properties.annotationID', '') === imgName;
        if (selectedIcon) {
          return {
            ...item,
            style: {
              text: {
                color: '#fff',
                backgroundImage: cameraIconSelected,
                zIndex: 5,
              },
            },
          };
        } if (imgName === '' || !selectedIcon) {
          return {
            ...item,
            style: {
              text: {
                color: '#fff',
                backgroundImage: cameraIcon,
                zIndex: 5,
              },
            },
          };
        }
        return item;
      }),
    };
    setCameraAsset(updatedCameraAsset);
  };

  useEffect(() => {
    if (!isEmpty(cameraIconHoveredFromGrid)) {
      highlightCameraIcon(cameraIconHoveredFromGrid);
    } else highlightCameraIcon(iconHovered);
  }, [iconHovered, cameraIconHoveredFromGrid]);

  useEffect(() => {
    const mapRef = mapViewerRef;
    if (rotation && mapView && mapRef.current) {
      setMapView({ ...localCurrentView, rotation });
    }
  }, [rotation, mapView.rotation]);

  const handleMapClick = (e) => {
    const eventType = get(e, 'event.type', false);
    if (eventType === 'click' || eventType === 'hover') {
      const annotation = get(e, 'annotation.annotation', {});
      if (!isEmpty(annotation)) {
        const selectedImageName = get(annotation, 'properties.annotationID', '');
        setSelectedAnnotation({ annotation, eventType: e.event.type, selectedImageName });
        setIconHovered(selectedImageName);
      } else {
        setSelectedAnnotation({ annotation: {}, eventType: e.event.type, selectedImageName: '' });
        setIconHovered('');
      }
    }
  };

  const updateInteractionObject = (interaction, mapReadyParam = false) => {
    try {
      if (isEmpty(interaction) || typeof (interaction) !== 'object' || !get(interaction, 'type', null)) {
        throw new Error('Interaction object is not set properly');
      }
      let newInteractionObj = {
        enable: mapReadyParam || isMapReady,
      };
      if (interaction.enable === false) {
        newInteractionObj = {
          ...interactionObject,
          enable: false,
        };
      } else if (interaction.type === 'tracking') {
        newInteractionObj = {
          ...newInteractionObj,
          type: 'tracking',
          mode: { hover: true, click: true },
          handler: handleMapClick,
        };
      } else {
        newInteractionObj = {
          ...interaction,
          ...newInteractionObj,
        };
      }
      setInteractionObject(newInteractionObj);
    } catch (err) {
      setTimeout(() => {
        errorHandlerService(err, err.message || '', 'Photos');
      }, 0);
    }
  };

  const handleOnMapReady = () => {
    setIsMapReady(true);
    updateInteractionObject(
      { type: 'tracking' },
      true,
    );
  };

  const debouncedZoomWithEaseTo = debounce(zoomWithEaseTo, 0);

  useEffect(() => {
    if (zoomChanged !== null && mapViewerRef.current) {
      debouncedZoomWithEaseTo(zoomChanged);
    }

    // Clean up function
    return () => {
      debouncedZoomWithEaseTo.cancel();
    };
  }, [zoomChanged]);

  useEffect(() => {
    setZoomLevel(localCurrentView.zoom);
  }, [localCurrentView]);

  useEffect(() => {
    const imageUrl = window.URL.createObjectURL(new Blob([image.data]));
    const mapViewerImageAsset = AssetFactory.getAsset({
      uuid: imageName,
      assetType: IMAGE_ASSET_TYPE,
      corners: imageCorners,
      url: imageUrl,
    });
    setImageAsset(mapViewerImageAsset);
  }, [image]);

  useEffect(() => {
    if (!isEmpty(cameraPositions) && !isEmpty(imageAsset)) {
      setCameraAsset(getCameraPositionsAsset(cameraPositions));
    }
  }, [cameraPositions, imageAsset]);

  useEffect(() => {
    resizeMap();
  }, [window.innerWidth, mapSize.width, mapSize.height]);

  useEffect(() => {
    if (!mapViewerRef.current || isEmpty(imageCorners)) return;
    const { mapContainer } = mapViewerRef.current;
    const { clientWidth, clientHeight } = mapContainer;
    const imageBounds = getImageBoundPolygon(imageCorners);
    const bound = photosUtil.getBounds(imageBounds, clientWidth, clientHeight);
    setBounds(bound);
  }, [imageCorners, mapViewerRef.current]);

  const handleBoundsSet = ({ view }) => {
    if (!initialZoomLevel) {
      setInitialZoomLevel(view.zoom || DEFAULT_ZOOM_LEVEL);
    }
    // apply the rotation also after the bound set
    setLocalCurrentView({ ...view, rotation });
  };

  const onMapViewUpdated = ({ bound, ...newView }) => {
    setLocalCurrentView({ ...newView });
  };

  return (
    <ErrorBoundary componentName="PhotosGallery: MapViewer">
      <EVMapViewer
        ref={mapViewerRef}
        view={mapView}
        assets={[imageAsset, cameraAsset]}
        baseMap="blank"
        navigationControl={{
          disablePitchAndRotation: true,
        }}
        onMapReady={handleOnMapReady}
        onViewUpdated={onMapViewUpdated}
        bound={bounds}
        onBoundsSet={handleBoundsSet}
        initialConfig={{
          style: { backgroundColor: '#000' },
          envName: REACT_APP_ENV,
        }}
        interaction={interactionObject}
        maxZoom={PHOTOS_MAX_ZOOM_LEVEL}
      />
    </ErrorBoundary>
  );
};

PhotosMapViewer.propTypes = {
  cameraPositions: PropTypes.arrayOf(PropTypes.shape({
    Latitude: PropTypes.string.isRequired,
    Longitude: PropTypes.string.isRequired,
  })).isRequired,
  image: PropTypes.arrayOf(PropTypes.number).isRequired,
  imageCorners: PropTypes.shape({
    ul: PropTypes.shape({
      lat: PropTypes.number.isRequired,
      lon: PropTypes.number.isRequired,
    }).isRequired,
    ur: PropTypes.shape({
      lat: PropTypes.number.isRequired,
      lon: PropTypes.number.isRequired,
    }).isRequired,
    br: PropTypes.shape({
      lat: PropTypes.number.isRequired,
      lon: PropTypes.number.isRequired,
    }).isRequired,
    bl: PropTypes.shape({
      lat: PropTypes.number.isRequired,
      lon: PropTypes.number.isRequired,
    }).isRequired,
  }).isRequired,
  zoomChanged: PropTypes.bool.isRequired,
  setZoomLevel: PropTypes.func.isRequired,
  setSelectedAnnotation: PropTypes.func.isRequired,
  cameraIconHoveredFromGrid: PropTypes.string,
  rotation: PropTypes.number,
};

PhotosMapViewer.defaultProps = {
  cameraIconHoveredFromGrid: '',
  rotation: 0,
};

export default PhotosMapViewer;
