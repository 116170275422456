import React from 'react';
import PropTypes from 'prop-types';
import {
  EVBox, EVToolbarPanel, EVIconButton, EVAdd, EVRemove, useTheme,
} from '@eagleview/ev-comp-library';
import useStyles from './PhotosToolbar.styles';

const PhotosToolbar = ({ handleZoomInButton, handleZoomOutButton }) => {
  // hooks
  const theme = useTheme();
  const styles = useStyles();

  return (
    <EVBox className={styles.zoomToolBar}>
      <EVToolbarPanel
        displayType="vertical"
        iconStyles={{ height: 'auto', borderRadius: '4px' }}
        backgroundColor={theme.palette.common.white}
        style={{ borderRadius: '4px' }}
        border="1px solid #ccc"
      >
        <EVIconButton key="1" id="zoomInButton" onClick={handleZoomInButton}>
          <EVAdd />
        </EVIconButton>
        <EVIconButton key="2" id="zoomOutButton" onClick={handleZoomOutButton}>
          <EVRemove />
        </EVIconButton>
      </EVToolbarPanel>
    </EVBox>
  );
};

PhotosToolbar.propTypes = {
  handleZoomInButton: PropTypes.func.isRequired,
  handleZoomOutButton: PropTypes.func.isRequired,
};

export default PhotosToolbar;
