import React from 'react';
import { useSelector } from 'react-redux';
import {
  EVBox, EVTypography, useTheme, EVNavigationIcon,
} from '@eagleview/ev-comp-library';
import {
  MAP_FLIGHT_TYPE,
} from 'layout/adjuster/Adjuster.constants';
import { useTranslation } from 'react-i18next';
import useStyles from './PhotosSidePanel.styles';

const SidePanelDetails = () => {
  // selectors
  const policyInsurancePerilCode = useSelector((state) => state.photosReducer.policyInsurancePerilCode);
  const policyDateOfLoss = useSelector((state) => state.photosReducer.policyDateOfLoss);
  const stormDate = useSelector((state) => state.photosReducer.stormDate);
  const windDirection = useSelector((state) => state.photosReducer.windDirection);
  const windGust = useSelector((state) => state.photosReducer.windGust);
  const hailSize = useSelector((state) => state.photosReducer.hailSize);
  const flightType = useSelector((state) => state.photosReducer.flightType);
  const dateOrderCapture = useSelector((state) => state.photosReducer.dateOrderCapture);
  const dateCreated = useSelector((state) => state.photosReducer.dateCreated);

  // utility hooks
  const styles = useStyles({ windDirection });
  const { t } = useTranslation();
  const theme = useTheme();

  const flightTypeValue = (MAP_FLIGHT_TYPE)[flightType];

  return (
    <EVBox id="info" className={styles.detailsTab}>
      <EVTypography variant="h4" className={styles.detailsTabSectionText}>
        {t('adjuster.tab.capture')}
      </EVTypography>
      <EVBox
        borderBottom={`1px solid ${theme.palette.grey[100]}`}
        padding={theme.evSpacing['ev-spacing--16']}
        marginBottom={theme.evSpacing['ev-spacing--16']}
        display="flex"
        flexWrap="wrap"
      >
        <EVBox flex="1 1 45%" marginBottom={theme.evSpacing['ev-spacing--16']}>
          <EVTypography className={styles.tabTableText} variant="h4">
            {t('adjuster.tab.flightType')}
          </EVTypography>
        </EVBox>
        <EVBox flex="1 1 55%" marginBottom={theme.evSpacing['ev-spacing--16']}>
          <EVTypography variant="h4" className={styles.tabTableValues}>
            {t(flightTypeValue) || '-'}
          </EVTypography>
        </EVBox>
        <EVBox flex="1 1 45%" marginBottom={theme.evSpacing['ev-spacing--16']}>
          <EVTypography className={styles.tabTableText} variant="h4">
            {t('adjuster.tab.dateCaptured')}
          </EVTypography>
        </EVBox>
        <EVBox flex="1 1 55%" marginBottom={theme.evSpacing['ev-spacing--16']}>
          <EVTypography variant="h4" className={styles.tabTableValues}>
            {dateOrderCapture || '-'}
          </EVTypography>
        </EVBox>
        <EVBox flex="1 1 45%">
          <EVTypography className={styles.tabTableText} variant="h4">
            {t('adjuster.tab.dateCreated')}
          </EVTypography>
        </EVBox>
        <EVBox flex="1 1 55%">
          <EVTypography variant="h4" className={styles.tabTableValues}>
            {dateCreated || '-'}
          </EVTypography>
        </EVBox>
      </EVBox>
      <EVTypography variant="h4" className={styles.detailsTabSectionText}>
        {t('adjuster.tab.policy')}
      </EVTypography>
      <EVBox
        borderBottom={`1px solid ${theme.palette.grey[100]}`}
        padding={theme.evSpacing['ev-spacing--16']}
        marginBottom={theme.evSpacing['ev-spacing--16']}
        display="flex"
        flexWrap="wrap"
      >
        <EVBox flex="1 1 45%" marginBottom={theme.evSpacing['ev-spacing--16']}>
          <EVTypography className={styles.tabTableText} variant="h4">
            {t('adjuster.tab.dateOfLoss')}
          </EVTypography>
        </EVBox>
        <EVBox flex="1 1 55%" marginBottom={theme.evSpacing['ev-spacing--16']}>
          <EVTypography variant="h4" className={styles.tabTableValues}>
            {policyDateOfLoss || '-'}
          </EVTypography>
        </EVBox>
        {policyInsurancePerilCode && (
          <>
            <EVBox flex="1 1 45%">
              <EVTypography className={styles.tabTableText} variant="h4">
                {t('adjuster.tab.perilCode')}
              </EVTypography>
            </EVBox>
            <EVBox flex="1 1 55%">
              <EVTypography variant="h4" className={styles.tabTableValues}>
                {policyInsurancePerilCode}
              </EVTypography>
            </EVBox>
          </>
        )}
      </EVBox>
      <EVTypography variant="h4" className={styles.detailsTabSectionText}>
        {t('adjuster.tab.weather')}
      </EVTypography>
      <EVBox
        borderBottom={`1px solid ${theme.palette.grey[100]}`}
        padding={theme.evSpacing['ev-spacing--16']}
        marginBottom={theme.evSpacing['ev-spacing--16']}
        display="flex"
        flexWrap="wrap"
      >
        <EVBox flex="1 1 45%" marginBottom={theme.evSpacing['ev-spacing--16']}>
          <EVTypography className={styles.tabTableText} variant="h4">
            {t('adjuster.tab.stormDate')}
          </EVTypography>
        </EVBox>
        <EVBox flex="1 1 55%" marginBottom={theme.evSpacing['ev-spacing--16']}>
          <EVTypography variant="h4" className={styles.tabTableValues}>
            {stormDate || '-'}
          </EVTypography>
        </EVBox>
        <EVBox flex="1 1 45%" marginBottom={theme.evSpacing['ev-spacing--16']}>
          <EVTypography className={styles.tabTableText} variant="h4">
            {t('adjuster.tab.windDirection')}
          </EVTypography>
        </EVBox>
        <EVBox flex="1 1 55%" marginBottom={theme.evSpacing['ev-spacing--16']}>
          <EVTypography variant="h4" className={styles.tabTableValues}>
            <EVBox display="flex" alignItems="center">
              {windDirection || '-'}
              {windDirection && <EVNavigationIcon className={styles.windDirectionIcon} />}
            </EVBox>
          </EVTypography>
        </EVBox>
        <EVBox flex="1 1 45%" marginBottom={theme.evSpacing['ev-spacing--16']}>
          <EVTypography className={styles.tabTableText} variant="h4">
            {t('adjuster.tab.windGust')}
          </EVTypography>
        </EVBox>
        <EVBox flex="1 1 55%" marginBottom={theme.evSpacing['ev-spacing--16']}>
          <EVTypography variant="h4" className={styles.tabTableValues}>
            {windGust ? `${windGust} mph` : '-'}
          </EVTypography>
        </EVBox>
        <EVBox flex="1 1 45%">
          <EVTypography className={styles.tabTableText} variant="h4">
            {t('adjuster.tab.hailSize')}
          </EVTypography>
        </EVBox>
        <EVBox flex="1 1 55%">
          <EVTypography variant="h4" className={styles.tabTableValues}>
            {hailSize ? `${hailSize} in.` : '-'}
          </EVTypography>
        </EVBox>
      </EVBox>
    </EVBox>
  );
};

export default SidePanelDetails;
