import React, { useState, useEffect } from 'react';
import {
  EVButton,
  EVBox,
  EVAdvancedSearch,
  EVBackdrop,
  EVTypography,
  EVDialog,
  EVDialogContent,
  EVDialogContentText,
  EVDialogActions,
  EVClear,
  EVIconButton,
  EVCard,
  EVCardContent,
} from '@eagleview/ev-comp-library';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { shape } from 'prop-types';
import clsx from 'clsx';
import { isEntitled } from 'utils/auth.utils';
import { FEATURE_ENTITLEMENTS } from 'layout/entitleUser/EntitleUser.constants';
import * as actions from '../Home.actions';
import { CURRENT_USER_EXTENSION } from '../Home.constants';
import useStyles from './HomeAdjusterAssignmentPanel.styles';

/**
 * Component used to select and assign the adjuster to a claim
 */
const HomeAdjusterAssignmentPanel = ({ claimData }) => {
  const { t } = useTranslation();
  const styles = useStyles();

  // selectors
  const entitlements = useSelector((state) => state.entitleUserReducer.entitlements);
  const currentUser = useSelector((state) => state.homeReducer.currentUser);
  const adjusterList = useSelector((state) => state.homeReducer.adjusterList);

  // local state
  const [assignedAdjuster, setAssignedAdjuster] = useState({});
  const [showCancelDialog, setShowCancelDialog] = useState(false);

  // actions
  const dispatch = useDispatch();
  const setShowAssignmentPanel = (payload) => dispatch(actions.setShowAssignmentPanelAction(payload));
  const setClaimUnderAssignment = (payload) => dispatch(actions.setClaimUnderAssignmentAction(payload));
  const fetchAdjusters = (payload) => dispatch(actions.fetchAdjustersAction(payload));
  const assignAdjuster = (payload) => dispatch(actions.assignAdjusterAction(payload));

  const defaultOption = [{
    firstName: currentUser.firstName,
    lastName: currentUser.lastName,
    userName: currentUser.userName,
    extension: CURRENT_USER_EXTENSION,
  }];

  const isEntitledToAssignAdjuster = isEntitled(entitlements, FEATURE_ENTITLEMENTS.ASSIGN_ASSESS_ORDER);

  useEffect(() => {
    if (claimData.claimOwnerFirstName && claimData.claimOwnerLastName && claimData.claimOwnerEmailId) {
      setAssignedAdjuster({
        firstName: claimData.claimOwnerFirstName,
        lastName: claimData.claimOwnerLastName,
        userName: claimData.claimOwnerEmailId,
      });
    }
  }, [claimData]);

  const handleSearchTermChange = (searchTerm) => {
    if (searchTerm.length > 0) {
      fetchAdjusters(searchTerm);
    }
  };

  const handleSearchTermCleared = () => {
    setAssignedAdjuster({});
  };

  const handleResultSelected = (selectedOption) => {
    setAssignedAdjuster(selectedOption);
  };

  const handleCloseAssignmentDialog = () => {
    setShowAssignmentPanel(false);
    setClaimUnderAssignment({});
  };

  const handleAssign = () => {
    if (isEntitledToAssignAdjuster && claimData.claimOwnerEmailId !== assignedAdjuster.userName) {
      assignAdjuster({
        orderId: claimData.orderId,
        adjuster: {
          assignedTo: assignedAdjuster.userName ? assignedAdjuster.userName : '',
        },
      });
    }
    handleCloseAssignmentDialog();
  };

  const handleCancel = () => {
    if (isEntitledToAssignAdjuster && typeof assignedAdjuster.userName === 'string' && claimData.claimOwnerEmailId !== assignedAdjuster.userName) {
      setShowCancelDialog(true);
    } else {
      handleCloseAssignmentDialog();
    }
  };

  const handleCancelConfirmation = () => {
    setShowCancelDialog(false);
    handleCloseAssignmentDialog();
  };
  const handleUnassign = () => {
    if (claimData.claimOwnerEmailId) {
      assignAdjuster({ orderId: claimData.orderId, adjuster: { assignedTo: '' } });
    }
    handleCloseAssignmentDialog();
  };

  const SearchMessage = () => <EVTypography>{t('home.resultNotFound')}</EVTypography>;

  return (
    <>
      <EVCard className={styles.infoPanel}>
        <EVBox display="flex" justifyContent="space-between" className={styles.header}>
          <EVTypography className={styles.titleText}>{t('home.assignAdjuster')}</EVTypography>
          <EVIconButton className={styles.closeIconButton} onClick={handleCloseAssignmentDialog}>
            <EVClear classes={{ root: styles.closeIcon }} viewBox="0 0 23.25 23.25" data-testid="assignment-close-icon" />
          </EVIconButton>
        </EVBox>
        <EVCardContent className={styles.cardContent}>
          <EVBox className={styles.panelContainer}>
            <EVTypography className={styles.contentLabel} variant="body2">{t('home.selectAdjuster')}</EVTypography>
            <EVTypography className={styles.contentDataClaimId}>{claimData.claimId}</EVTypography>
            <EVTypography variant="h4" className={styles.contentDataClaimStatus}>{claimData.status}</EVTypography>
            <EVBox className={styles.assignmentSearchField}>
              <EVAdvancedSearch
                label={t('home.assignee')}
                shadowText={t('home.adjusterName')}
                data={adjusterList}
                onResultSelected={handleResultSelected}
                noAutoSuggestResultMessage={<SearchMessage />}
                searchInputReceived={handleSearchTermChange}
                defaultList={defaultOption}
                value={assignedAdjuster}
                onSearchTermCleared={handleSearchTermCleared}
                data-testid="assignment-field"
              />
            </EVBox>
          </EVBox>
          <EVBox className={styles.footer}>
            <EVButton color="primary" className={styles.button} onClick={handleUnassign} disabled={!assignedAdjuster.userName}>
              {t('home.unassign')}
            </EVButton>
            <EVBox display="flex" className={styles.buttonBox}>
              <EVButton color="primary" className={clsx(styles.button, styles.cancelButton)} onClick={handleCancel}>
                {t('home.cancel')}
              </EVButton>
              <EVButton color="primary" className={styles.button} variant="contained" disabled={!assignedAdjuster.userName} onClick={handleAssign}>
                {t('home.assign')}
              </EVButton>
            </EVBox>
          </EVBox>
        </EVCardContent>
      </EVCard>
      <EVBackdrop className={styles.backdrop} open />
      <EVDialog open={showCancelDialog} onClose={() => setShowCancelDialog(false)} data-testid="cancel-assignment-dialog">
        <EVDialogContent>
          <EVDialogContentText>{t('home.assignmentCancelConfirmation')}</EVDialogContentText>
        </EVDialogContent>
        <EVDialogActions>
          <EVButton onClick={() => setShowCancelDialog(false)} data-testid="cancel-dialog-no">
            {t('no')}
          </EVButton>
          <EVButton onClick={handleCancelConfirmation} data-testid="cancel-dialog-yes">
            {t('yes')}
          </EVButton>
        </EVDialogActions>
      </EVDialog>
    </>
  );
};

HomeAdjusterAssignmentPanel.propTypes = {
  claimData: shape().isRequired,
};

export default HomeAdjusterAssignmentPanel;
