import { makeStyles } from '@eagleview/ev-comp-library';

export default makeStyles((theme) => ({
  wrapper: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    overflowY: 'auto',
    top: 0,
    backgroundColor: theme.palette.common.black,
    zIndex: 5,
  },
  tagAccessory: {
    top: ({ accessoryPanelLocation: { top: accessoryPanelTop } }) => accessoryPanelTop || 0,
    left: ({ accessoryPanelLocation: { left: accessoryPanelLeft } }) => accessoryPanelLeft || 0,
    position: 'absolute',
    zIndex: 4,
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '4px',
  },
  viewSwitcherStyle: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    top: '17px',
    width: '100%',
  },
}));
