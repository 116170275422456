import React, { useState } from 'react';
import {
  EVAutocomplete, EVTextField, EVDeleteIcon, EVBox, EVIconButton, createFilterOptions,
} from '@eagleview/ev-comp-library';
import PropTypes from 'prop-types';
import * as authUtils from 'utils/auth.utils';
import { useSelector } from 'react-redux';
import { FEATURE_ENTITLEMENTS } from 'layout/entitleUser/EntitleUser.constants';
import useStyles from './AccessoryDropdown.styles';

const AccessoryDropdown = ({
  accessoryMasterList, accessoryPanelLocation, onAccessorySelection, deleteAccessory, existingAccessory, imagePreviewRef,
}) => {
  // selectors
  const entitlements = useSelector((state) => state.entitleUserReducer.entitlements);

  const editAllowed = authUtils.isEntitled(entitlements, FEATURE_ENTITLEMENTS.MANAGE_DECISIONS);

  const filter = createFilterOptions({ trim: true });
  const classes = useStyles({ accessoryPanelLocation, imagePreviewRef, editAllowed });

  // contains selected value as a string
  const [value, setValue] = useState(existingAccessory);

  return (
    <EVBox className={classes.container}>
      <EVAutocomplete
        className={classes.autocomplete}
        value={value}
        classes={{
          popper: classes.dropdown,
        }}
        onChange={(event, newValue) => {
          setValue(newValue);
          onAccessorySelection(newValue);
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          const { inputValue } = params;
          // Suggest the creation of a new value
          const isExisting = options.some((option) => inputValue.trim().toLowerCase() === option.trim().toLowerCase());
          if (inputValue !== '' && !isExisting) {
            filtered.unshift(inputValue);
          }

          return filtered;
        }}
        id="accessory-dropdown"
        disabled={!editAllowed}
        data-testid="accessory-dropdown"
        options={accessoryMasterList}
        getOptionLabel={(option) => option.trim()}
        renderOption={(option) => option}
        freeSolo
        autoSelect
        renderInput={(params) => (
          <EVTextField
            {...params}
            InputProps={{
              ...params.InputProps,
              disableUnderline: true,
            }}
            placeholder="Accessory Type"
            variant="standard"
          />
        )}
      />
      {editAllowed
      && (
      <EVIconButton onClick={() => deleteAccessory()} disabled={!editAllowed}>
        <EVDeleteIcon />
      </EVIconButton>
      )}
    </EVBox>
  );
};

AccessoryDropdown.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  accessoryMasterList: PropTypes.arrayOf(PropTypes.string).isRequired,
  accessoryPanelLocation: PropTypes.shape({}).isRequired,
  onAccessorySelection: PropTypes.func.isRequired,
  deleteAccessory: PropTypes.func.isRequired,
  existingAccessory: PropTypes.string.isRequired,
  imagePreviewRef: PropTypes.shape({}).isRequired,
};

export default AccessoryDropdown;
