import { makeStyles } from '@eagleview/ev-comp-library';

export default makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
    color: theme.evPalette.charcoal[600],
  },
  missingSummaryJsonContent: {
    paddingTop: theme.spacing(6),
  },
  thumbnails: {
    overflowY: 'scroll',
    height: `calc(100vh - ${theme.spacing(16)}px)`,
  },
  capturedImageryTitle: {
    color: theme.palette.grey[800],
    fontWeight: 'bold',
    textTransform: 'unset',
    fontSize: theme.typography.pxToRem(20),
    paddingTop: theme.spacing(1),
  },
}));
