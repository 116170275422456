import {
  all, call, put, takeLatest, select, takeEvery,
} from 'redux-saga/effects';
import get from 'lodash/get';
import axios from 'axios';
import * as util from 'utils/utils';
import { isEntitled } from 'utils/auth.utils';
import { FEATURE_ENTITLEMENTS } from 'layout/entitleUser/EntitleUser.constants';
import * as action from './ManualAtAdjustment.actions';
import * as api from './ManualAtAdjustment.api';
import * as utils from './ManualAtAdjustment.utils';
import * as constants from './ManualAtAdjustment.constants';
import * as adjusterUtil from '../adjuster/Adjuster.utils';

let manualAtApiSource;
const lngFromMercatorX = (x) => x * 360 - 180;
const latFromMercatorY = (y) => {
  const y2 = 180 - y * 360;
  return (360 / Math.PI) * Math.atan(Math.exp(y2 * (Math.PI / 180))) - 90;
};

const getCornersFromImage = (width, height) => {
  const max = Math.max(width, height);
  const maxTiles = Math.ceil(max / 256);
  const maxZoom = Math.log2(maxTiles);
  const maxPixel = 2 ** maxZoom * 256;
  const brLon = lngFromMercatorX(width / maxPixel);
  const brLat = latFromMercatorY(height / maxPixel);
  return {
    ul: { lon: -180, lat: 85 },
    ur: { lon: brLon, lat: 85 },
    br: { lon: brLon, lat: brLat },
    bl: { lon: -180, lat: brLat },
  };
};

export const mapImagesPayload = (images, blobs) => images.map((image) => {
  const currBlob = blobs.find((blob) => blob.imageName === image.image_name);
  const corners = getCornersFromImage(4056, 3040);
  const polygon = adjusterUtil.getPolygonFromCorners(corners);
  if (currBlob.error) return false;
  return {
    ...image,
    url: window.URL.createObjectURL(new Blob([currBlob.data])),
    width: 4056,
    height: 3040,
    corners,
    polygon,
    available: true,
  };
}).filter(Boolean);

export function* fetchImagesSaga({ payload }) {
  try {
    const { orderId, structureId, imageType } = payload;
    const imagesData = yield call(api.fetchImagesApi, orderId, structureId, get(manualAtApiSource, 'token', ''), imageType);
    const images = imagesData.overview_image;
    const obliqueAvailable = imagesData.oblique_available;
    const isImageTypeOblique = imageType === 'EXPLORE_OBLIQUES';
    if (isImageTypeOblique) {
      yield put(action.setExploreObliqueImages({ images, structureId, obliqueAvailable }));
    } else {
      yield put(action.setInitialImagesDetails({ images, structureId, obliqueAvailable }));
    }
    const blobs = yield all(images.map((image) => call(api.fetchRoofImageApi, { orderId, structureId, imageName: image.image_name },
      get(manualAtApiSource, 'token', ''))));
    const imagesWithUrl = yield call(mapImagesPayload, images, blobs);
    yield put(action.fetchImagesSuccessAction({
      imagesWithUrl, structureId, isImageTypeOblique, obliqueAvailable,
    }));

    if (!isImageTypeOblique) {
      const selectedImagesList = yield select((state) => state.manualAtMultiStructureReducer.structuresDetails[structureId].selectedImagesList);
      if (selectedImagesList.length > 0) {
        yield put(action.setActiveImageAction({ image: imagesWithUrl.find((img) => img.image_name === selectedImagesList[0]), structureId }));
      }
    }
  } catch (error) {
    yield put(action.fetchImagesFailureAction(util.parseServerError(error)));
  }
}

export function* fetchOrthoPolygonSaga({ payload }) {
  try {
    const response = yield call(api.fetchOrthoPolygon, payload, get(manualAtApiSource, 'token', ''));
    yield put(
      action.fetchOrthoPolygonSuccessAction({
        vertices: utils.featureCollectionToPoints(response),
        polygon: utils.featureCollectionToPolygon(response),
        reportId: payload,
      }),
    );
    yield put(action.setViewAction({ view: utils.getCenterOfAllVertices(response), reportId: payload }));
    yield put(action.setInitialViewAction({ initialView: utils.getCenterOfAllVertices(response), reportId: payload }));
  } catch (error) {
    yield put(action.fetchOrthoPolygonFailureAction({ ...util.parseServerError(error), reportId: payload }));
  }
}

export function* fetchOrthoImageSaga({ payload }) {
  try {
    const response = yield call(api.fetchOrthoImageMeta, payload, get(manualAtApiSource, 'token', ''));
    const orthoImage = yield call(api.fetchOrthoImage, payload, get(manualAtApiSource, 'token', ''));

    yield put(action.fetchOrthoImageSuccessAction({ imageData: utils.orthoImageToAsset(response, orthoImage), reportId: payload }));
  } catch (error) {
    yield put(action.fetchOrthoImageFailureAction(util.parseServerError(error)));
  }
}

export function* fetchStructureDetailsAndImagesSaga({ payload }) {
  try {
    const response = yield call(api.fetchStructureDetailsApi, payload.orderId, payload.structureId, get(manualAtApiSource, 'token', ''));
    const structureDetails = get(response, 'structures', [])[0];
    yield put(action.fetchStructureDetailsAndImagesSuccess({ structureDetails, structureId: payload.structureId }));
    yield put(action.downloadFacetImage(payload));
    yield put(action.fetchImagesAction(payload));
  } catch (error) {
    yield put(action.fetchStructureDetailsAndImagesFailure(util.parseServerError(error)));
  }
}

export function* fetchOrthoImageAndDetails(payload) {
  try {
    yield put(action.fetchOrthoPolygonAction(payload));
    yield put(action.fetchOrthoImageAction(payload));
  } catch (error) {
    yield put(action.fetchImagesAndDetailsFailureAction(util.parseServerError(error)));
  }
}

export function* fetchImagesAndDetailsSaga({ payload }) {
  try {
    const reportList = payload.reportIds;
    yield put(action.setHideUiAction(false));
    yield all(reportList.map((reportId) => call(fetchOrthoImageAndDetails, reportId)));
  } catch (error) {
    yield put(action.fetchImagesAndDetailsFailureAction(util.parseServerError(error)));
  }
}

export function* fetchOrderDetailsSaga({ payload }) {
  try {
    manualAtApiSource = axios.CancelToken.source();
    const entitlements = yield select((state) => state.entitleUserReducer.entitlements);
    const isEntitledWithViaOpsPrime = isEntitled(entitlements, FEATURE_ENTITLEMENTS.MANAGE_MANUAL_AT_VIA_OPS_PRIME)
        || isEntitled(entitlements, FEATURE_ENTITLEMENTS.MANAGE_MANUAL_AT_ESCALATION_VIA_OPS_PRIME);
    const details = yield call(api.fetchOrderDetails, payload.orderId);
    yield put(action.fetchOrderDetailsSuccessAction(details));
    try {
      if (isEntitledWithViaOpsPrime) {
        const checkout = yield call(api.checkoutByReportId, details.orderID);
        yield put(action.updateTaskStateIdAction(checkout.ID));
        if (!checkout.Active) {
          yield put(action.enableReadOnlyMode());
        }
        yield put(action.fetchImagesAndDetailsAction({ orderId: payload.orderId, reportIds: details.reports }));
      } else {
        yield put(action.fetchImagesAndDetailsAction({ orderId: payload.orderId, reportIds: details.reports }));
      }
    } catch (err) {
      if (err.status) {
        yield put(action.enableReadOnlyMode());
        yield put(action.fetchImagesAndDetailsAction({ orderId: payload.orderId, reportIds: details.reports }));
      } else {
        yield put(action.handleCheckoutErrorAction('manualAtAdjustment.checkoutOpsPrimeErrorFailed'));
      }
    }
  } catch (error) {
    yield put(action.fetchOrderDetailsFailureAction(util.parseServerError(error)));
  }
}

export function* completeSaga({ payload }) {
  try {
    const entitlements = yield select((state) => state.entitleUserReducer.entitlements);
    yield call(api.completeOrRejectApi, { orderId: payload.orderId, status: payload.status });
    if (constants.rolesEntitledToCallOpsPrime.some((role) => entitlements.includes(role))) {
      yield call(api.checkInSimple, payload.taskStateId);
    }
  } catch (error) {
    yield put(action.completeFailureAction(util.parseServerError(error)));
    return;
  }
  yield put(action.completeSuccessAction());
}

export function* saveStructureTiePointSaga({ payload }) {
  try {
    const response = yield call(api.saveStructureTiePointApi,
      { orderId: payload.orderId, structureId: payload.structureId, data: payload.omBody });
    yield put(action.saveStructureTiePointSuccessAction({ response, reportId: payload.omBody.reportID, structureId: payload.structureId }));
    if (payload.isLastStructure) {
      yield put(action.completeAction({ orderId: payload.orderId, status: payload.omBody.status, taskStateId: payload.taskStateId }));
    } else {
      yield put(action.clearReportAndStructureTiePointData());
      yield put(action.setCurrentStructureAction(payload.nextStructureNumber));
    }
  } catch (err) {
    yield put(action.saveStructureTiePointFailedAction(util.parseServerError(err)));
  }
}

export function* rejectManualATOrderSaga({ payload }) {
  try {
    const entitlements = yield select((state) => state.entitleUserReducer.entitlements);
    const response = yield call(api.saveStructureTiePointApi,
      { orderId: payload.orderId, structureId: payload.structureId, data: payload.omBody });
    yield put(action.saveStructureTiePointSuccessAction({ response, reportId: payload.omBody.reportID, structureId: payload.structureId }));
    yield call(api.completeOrRejectApi, { orderId: payload.orderId, status: payload.rejectReason, note: payload.omBody.note });
    if (constants.rolesEntitledToCallOpsPrime.some((role) => entitlements.includes(role))) {
      if (payload.rejectReason === constants.REJECTED_REMEASURE) {
        yield call(api.checkInSimple, payload.taskStateId);
      } else {
        yield call(api.rejectSimpleNotes, { taskStateId: payload.taskStateId, rejectReasonId: payload.rejectReasonId, body: payload.opBody });
      }
    }
    yield put(action.rejectManualATOrderSuccessAction());
    yield window.location.reload();
  } catch (error) {
    yield put(action.rejectManualATOrderFailureAction(util.parseServerError(error)));
  }
}

export function* downloadFacetImageSaga({ payload }) {
  try {
    const response = yield call(api.downloadFacetImage, payload.orderId, payload.structureId, get(manualAtApiSource, 'token', ''));
    const facetImageUrl = window.URL.createObjectURL(new Blob([response]));
    yield put(action.downloadFacetImageSuccessAction({ facetImageUrl, structureId: payload.structureId }));
  } catch (err) {
    yield put(action.downloadFacetImageFailedAction(util.parseServerError(err)));
  }
}

export function* downloadSkydioMosaicImageSaga({ payload }) {
  try {
    const response = yield call(api.downloadSkydioMosaicImageApi, payload.orderId, payload.structureId);
    const skydioImageUrl = window.URL.createObjectURL(new Blob([response]));
    yield put(action.downloadSkydioMosaicImageSuccessAction({ skydioImageUrl, structureId: payload.structureId }));
  } catch (err) {
    yield put(action.downloadSkydioMosaicImageFailureAction({ error: util.parseServerError(err), structureId: payload.structureId }));
  }
}

export function* resetOrderTiePointsSaga({ payload }) {
  try {
    yield call(api.resetOrderTiePointsApi, payload);
    const orderDetails = yield call(api.fetchOrderDetails, payload);
    const structures = get(orderDetails, 'structures', []);
    yield put(action.resetOrderTiePointsSuccessAction({ structures }));
  } catch (err) {
    yield put(action.resetOrderTiePointsFailureAction(util.parseServerError(err)));
  }
}

export function* skipStructureSaga({ payload }) {
  try {
    const response = yield call(api.skipStructureApi, payload.orderId, payload.structureId, payload.requestBody);
    yield put(action.skipStructureSuccessAction({ structureId: payload.structureId, response }));
    if (payload.isLastStructure) {
      yield put(action.completeAction({ orderId: payload.orderId, status: payload.status, taskStateId: payload.taskStateId }));
    } else {
      yield put(action.clearReportAndStructureTiePointData());
      yield put(action.setCurrentStructureAction(payload.nextStructure));
    }
  } catch (err) {
    yield put(action.skipStructureFailureAction(util.parseServerError(err)));
  }
}

export function* resetOrderSaga() {
  if (manualAtApiSource && manualAtApiSource.cancel) {
    yield call(manualAtApiSource.cancel);
  }
}

export default function* manualAtAdjustmentSaga() {
  yield takeLatest(constants.FETCH_IMAGES, fetchImagesSaga);
  yield takeEvery(constants.FETCH_ORTHO_POLYGON, fetchOrthoPolygonSaga);
  yield takeEvery(constants.FETCH_ORTHO_IMAGE, fetchOrthoImageSaga);
  yield takeLatest(constants.FETCH_ORDER_DETAILS, fetchOrderDetailsSaga);
  yield takeLatest(constants.REJECT, rejectManualATOrderSaga);
  yield takeLatest(constants.COMPLETE, completeSaga);
  yield takeLatest(constants.FETCH_IMAGES_AND_DETAILS, fetchImagesAndDetailsSaga);
  yield takeLatest(constants.DOWNLOAD_FACET_IMAGE, downloadFacetImageSaga);
  yield takeLatest(constants.FETCH_STRUCTURE_DETAILS_AND_IMAGES, fetchStructureDetailsAndImagesSaga);
  yield takeLatest(constants.SAVE_STRUCTURE_TIE_POINT, saveStructureTiePointSaga);
  yield takeLatest(constants.DOWNLOAD_SKYDIO_MOSAIC_IMAGE, downloadSkydioMosaicImageSaga);
  yield takeLatest(constants.RESET_ORDER_TIE_POINTS, resetOrderTiePointsSaga);
  yield takeLatest(constants.SKIP_STRUCTURE, skipStructureSaga);
  yield takeLatest(constants.RESET_ORDER, resetOrderSaga);
}
