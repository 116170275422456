import React from 'react';
import {
  EVBox,
  EVDialog,
  EVIconButton,
  EVClear,
  EVTypography,
  EVButton,
} from '@eagleview/ev-comp-library';
import { bool, func } from 'prop-types';
import { useTranslation } from 'react-i18next';

const ConfirmDialog = ({ open, handleClose, handleSaveAndExit }) => {
  const { t } = useTranslation();
  return (
    <EVDialog open={open} onClose={handleClose}>
      <EVBox
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        overflow="hidden"
        width="430px"
        height="220px"
        p={2}
      >
        <EVBox>
          <EVBox display="flex" justifyContent="space-between" alignItems="center">
            <EVBox>
              <EVTypography variant="h3">{t('galleryMenu.confirmCompleteAndExit.dialogTitle')}</EVTypography>
            </EVBox>
            <EVIconButton onClick={handleClose}>
              <EVClear />
            </EVIconButton>
          </EVBox>
          <EVBox pt={2} pb={2}>
            <EVTypography style={{ color: 'gray' }} variant="body1">{t('galleryMenu.confirmCompleteAndExit.dialogMsg')}</EVTypography>
          </EVBox>
        </EVBox>
        <EVBox display="flex" justifyContent="flex-end">
          <EVBox clone mr={1}>
            <EVButton
              id="cancelCompleteOrderBtn"
              onClick={() => {
                handleClose();
              }}
              color="primary"
            >
              {t('cancel')}
            </EVButton>
          </EVBox>
          <EVButton id="confirmCompleteOrderBtn" onClick={() => handleSaveAndExit()} variant="contained" color="primary">
            {t('confirm')}
          </EVButton>
        </EVBox>
      </EVBox>
    </EVDialog>
  );
};

ConfirmDialog.propTypes = {
  open: bool.isRequired,
  handleClose: func.isRequired,
  handleSaveAndExit: func.isRequired,
};

export default ConfirmDialog;
