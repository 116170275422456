import omit from 'lodash/omit';
import {
  SET_POLL_START_TIME,
  UNSET_POLL_START_TIME,
  SET_EXPORT_IMAGERY_POLL_START_TIME,
  UNSET_EXPORT_IMAGERY_POLL_START_TIME,
} from 'layout/adjuster/Adjuster.constants';
import {
  FETCH_CURRENT_CLAIMS,
  FETCH_CURRENT_CLAIMS_SUCCESS,
  FETCH_CURRENT_CLAIMS_FAILURE,
  FETCH_PAST_CLAIMS,
  FETCH_PAST_CLAIMS_SUCCESS,
  FETCH_PAST_CLAIMS_FAILURE,
  CLOSE_ERROR_TOAST,
  SET_TAB,
  SET_SAVED_TAB,
  SET_SEARCH_QUERY,
  SET_CURRENT_USER,
  SET_CHECKED_ASSIGNMENT_FILTER_OPTIONS,
  SET_SHOW_ASSIGNMENT_PANEL,
  SET_CLAIM_UNDER_ASSIGNMENT,
  FETCH_ADJUSTERS,
  FETCH_ADJUSTERS_SUCCESS,
  FETCH_ADJUSTERS_FAILURE,
  CURRENT_USER_EXTENSION,
  ASSIGN_ADJUSTER,
  ASSIGN_ADJUSTER_SUCCESS,
  ASSIGN_ADJUSTER_FAILURE,
  SET_SHOW_TRACK_PANEL,
  SET_CLAIM_BEING_TRACKED,
  GET_APPOINTMENT_DETAILS,
  GET_APPOINTMENT_DETAILS_FAILURE,
  GET_APPOINTMENT_DETAILS_SUCCESS,
  CLEAR_APPOINTMENT_DETAILS,
  RESCHEDULE_APPOINTMENT,
  RESCHEDULE_APPOINTMENT_SUCCESS,
  RESCHEDULE_APPOINTMENT_FAILURE,
  CLEAR_RESCHEDULE_APPOINTMENT_RESPONSE,
} from './Home.constants';
import mapClaims from './Home.utils';

const initialState = {
  loading: {},
  currentClaims: [],
  pastClaims: [],
  error: {},
  showErrorToast: false,
  errorToastMessage: '',
  errorCode: 0,
  tab: 0,
  savedTab: 0,
  searchQuery: '',
  reportsPollStartTime: {},
  exportImageryPollStartTime: {},
  currentUser: {},
  checkedAssignmentFilterOptions: [],
  showAssignmentPanel: false,
  claimUnderAssignment: {},
  adjusterList: [],
  showClaimTrackPanel: false,
  claimBeingTracked: {},
  appointmentDetails: {},
  rescheduleResponseMsg: '',
};

const homeReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_CURRENT_CLAIMS:
      return {
        ...state,
        loading: { ...state.loading, [type]: true },
      };
    case FETCH_CURRENT_CLAIMS_SUCCESS:
      return {
        ...state,
        currentClaims: mapClaims(payload),
        loading: { ...state.loading, [FETCH_CURRENT_CLAIMS]: false },
      };
    case FETCH_CURRENT_CLAIMS_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, [FETCH_CURRENT_CLAIMS]: false },
        showErrorToast: payload.errorCode !== 0 && true,
        errorToastMessage: 'home.claimsListingError',
        errorCode: payload.errorCode,
      };
    case FETCH_PAST_CLAIMS:
      return {
        ...state,
        loading: { ...state.loading, [FETCH_PAST_CLAIMS]: true },
      };
    case FETCH_PAST_CLAIMS_SUCCESS:
      return {
        ...state,
        pastClaims: mapClaims(payload),
        loading: { ...state.loading, [FETCH_PAST_CLAIMS]: false },
      };
    case FETCH_PAST_CLAIMS_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, [FETCH_PAST_CLAIMS]: false },
        showErrorToast: payload.errorCode !== 0 && true,
        errorToastMessage: 'home.claimsListingError',
        errorCode: payload.errorCode,
      };
    case CLOSE_ERROR_TOAST: {
      return {
        ...state,
        showErrorToast: false,
      };
    }
    case SET_TAB: {
      return {
        ...state,
        tab: payload,
      };
    }
    case SET_SAVED_TAB: {
      return {
        ...state,
        savedTab: payload,
      };
    }
    case SET_SEARCH_QUERY: {
      return {
        ...state,
        searchQuery: payload,
      };
    }
    case SET_POLL_START_TIME: {
      return {
        ...state,
        reportsPollStartTime: { ...state.reportsPollStartTime, [payload]: new Date() },
      };
    }
    case UNSET_POLL_START_TIME:
      return {
        ...state,
        reportsPollStartTime: omit({ ...state.reportsPollStartTime }, [payload]),
      };
    case SET_EXPORT_IMAGERY_POLL_START_TIME:
      return {
        ...state,
        exportImageryPollStartTime: { ...state.exportImageryPollStartTime, [payload]: new Date() },
      };
    case UNSET_EXPORT_IMAGERY_POLL_START_TIME:
      return {
        ...state,
        exportImageryPollStartTime: omit({ ...state.exportImageryPollStartTime }, [payload]),
      };
    case SET_CURRENT_USER:
      return {
        ...state,
        currentUser: payload,
      };
    case SET_CHECKED_ASSIGNMENT_FILTER_OPTIONS:
      return {
        ...state,
        checkedAssignmentFilterOptions: payload,
      };
    case SET_SHOW_ASSIGNMENT_PANEL:
      return {
        ...state,
        showAssignmentPanel: payload,
      };
    case SET_CLAIM_UNDER_ASSIGNMENT:
      return {
        ...state,
        claimUnderAssignment: payload,
      };
    case GET_APPOINTMENT_DETAILS:
      return {
        ...state,
        loading: { ...state.loading, [type]: true },
      };
    case GET_APPOINTMENT_DETAILS_SUCCESS: return {
      ...state,
      appointmentDetails: payload,
      loading: { ...state.loading, [GET_APPOINTMENT_DETAILS]: false },
    };
    case GET_APPOINTMENT_DETAILS_FAILURE: return {
      ...state,
      loading: { ...state.loading, [GET_APPOINTMENT_DETAILS]: false },
      showErrorToast: payload.errorCode !== 0 && true,
      errorToastMessage: 'home.appoitnmentDetailsError',
      errorCode: payload.errorCode,
    };
    case CLEAR_APPOINTMENT_DETAILS: return {
      ...state,
      appointmentDetails: {},
    };
    case FETCH_ADJUSTERS:
      return {
        ...state,
        loading: { ...state.loading, [type]: true },
      };
    case FETCH_ADJUSTERS_SUCCESS:
      return {
        ...state,
        adjusterList: payload.map((adjuster) => (adjuster.userName === state.currentUser.userName
          ? { ...adjuster, extension: CURRENT_USER_EXTENSION } : adjuster)),
        loading: { ...state.loading, [FETCH_ADJUSTERS]: false },
      };
    case FETCH_ADJUSTERS_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, [FETCH_ADJUSTERS]: false },
        showErrorToast: payload.errorCode !== 0 && true,
        errorToastMessage: 'home.adjusterListingError',
        errorCode: payload.errorCode,
      };
    case ASSIGN_ADJUSTER:
      return {
        ...state,
        loading: { ...state.loading, [type]: true },
      };
    case ASSIGN_ADJUSTER_SUCCESS:
      return {
        ...state,
        currentClaims: state.currentClaims.map((claim) => {
          if (claim.orderId === payload.orderId) {
            return { ...claim, ...payload.assignedTo };
          }
          return claim;
        }),
        pastClaims: state.pastClaims.map((claim) => {
          if (claim.orderId === payload.orderId) {
            return { ...claim, ...payload.assignedTo };
          }
          return claim;
        }),
        loading: { ...state.loading, [ASSIGN_ADJUSTER]: false },
      };
    case ASSIGN_ADJUSTER_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, [ASSIGN_ADJUSTER]: false },
        showErrorToast: payload.errorCode !== 0 && true,
        errorToastMessage: 'home.adjusterAssignmentError',
        errorCode: payload.errorCode,
      };
    case SET_SHOW_TRACK_PANEL:
      return {
        ...state,
        showClaimTrackPanel: payload,
      };
    case SET_CLAIM_BEING_TRACKED:
      return {
        ...state,
        claimBeingTracked: payload,
      };
    case RESCHEDULE_APPOINTMENT:
      return {
        ...state,
        loading: { ...state.loading, [type]: true },
        rescheduleResponseMsg: '',
      };
    case RESCHEDULE_APPOINTMENT_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, [RESCHEDULE_APPOINTMENT]: false },
        rescheduleResponseMsg: 'home.rescheduleSuccessRespMsg',
      };
    case RESCHEDULE_APPOINTMENT_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, [RESCHEDULE_APPOINTMENT]: false },
        rescheduleResponseMsg: 'home.rescheduleFailureRespMsg',
        showErrorToast: payload.errorCode !== 0 && false,
        errorToastMessage: 'home.rescheduleAppointmentError',
        errorCode: payload.errorCode,
      };
    case CLEAR_RESCHEDULE_APPOINTMENT_RESPONSE: return {
      ...state,
      rescheduleResponseMsg: '',
    };
    default:
      return state;
  }
};

export default homeReducer;
