import { makeStyles } from '@eagleview/ev-comp-library';
import addAnomalies from 'assets/addCountIcn.svg';

export default makeStyles((theme) => ({
  roofItemsDialogTitle: {
    fontSize: '18px',
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: '18px',
    color: theme.evPalette.charcoal[700],
  },
  closeButton: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
    color: theme.evPalette.charcoal[600],
  },
  roofItemsDialogSubTitle: {
    color: theme.palette.grey[600],
    fontSize: '14px',
    fontWeight: '600',
  },
  imageName: {
    color: theme.palette.grey[600],
    fontSize: '14px',
    fontWeight: '500',
    marginTop: theme.spacing(0.5),
  },
  galleryThumbnailListItem: {
    display: 'inline-table',
    width: 'calc(25% - 4px)',
    margin: '0 2px',
    padding: 0,
    position: 'relative',
  },
  galleryImageThumbnail: {
    width: '70px',
    height: '70px',
    borderRadius: '5px',
    cursor: 'pointer',
  },
  galleryImageThumbnailActive: {
    border: `${theme.evSpacing['ev-spacing--4']} solid ${theme.evPalette.huckleberry[500]}`,
    borderRadius: theme.evSpacing['ev-spacing--4'],
    padding: '2px',
  },
  gallerySelectedImageContainer: {
    backgroundColor: theme.palette.common.black,
    '&:hover': {
      '& $galleryArrowButton': {
        display: 'block',
      },
    },
  },
  galleryArrowButton: {
    display: 'none',
    transform: 'translateY(-50%)',
    color: theme.palette.common.white,
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.6)',
    },
  },
  paperStyle: {
    height: '100%',
    width: '100%',
    maxWidth: '100%',
  },
  toolbarPanel: {
    backgroundColor: theme.palette.common.white,
    border: '1px solid #ccc',
    borderRadius: '5px',
  },
  toolbarPanelSpacing: {
    marginTop: '10px',
    marginBottom: '15px',
  },
  thumbnailOverlay: {
    position: 'absolute',
    bottom: theme.typography.pxToRem(5),
    left: 0,
    background: 'rgba(0,0,0,0.5)',
    width: '98%',
    padding: theme.evSpacing['ev-spacing--4'],
    display: 'flex',
    borderRadius: `0px 0px ${theme.spacing(1)}px ${theme.spacing(1)}px`,
    height: '40%',
  },
  thumbnailOverlaySelected: {
    borderRadius: `0px 0px ${theme.spacing(0.8)} ${theme.spacing(0.8)}`,
  },
  tagIconStyle: {
    color: theme.evPalette.salt[100],
    display: 'block',
    width: theme.typography.pxToRem(26),
    height: 'auto',
    padding: theme.spacing(0.125),
    marginLeft: 'auto',
  },
  iconOpacity: {
    opacity: 0,
  },
  reportSwitch: {
    border: 'unset',
    justifyContent: 'left',

  },
  divider: {
    backgroundColor: theme.evPalette.charcoal[200],
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  subContainer: {
    padding: `0px ${theme.spacing(2)}px`,
  },
  heightBarDamages: {
    minHeight: '40px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  heightBar: {
    minHeight: '32px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  anomalyCountFont: {
    fontWeight: theme.typography.fontWeightBold,
  },
  addDamageButton: {
    textTransform: 'none',
    fontWeight: theme.typography.fontWeightMedium,
  },
  addDamageSelected: {
    backgroundColor: theme.evPalette.ocean[100],
  },
  menuItemButton: {
    padding: 0,
    margin: 0,
    width: '24px',
  },
  menuItemIcon: {
    marginLeft: theme.spacing(1.25),
    color: theme.evPalette.charcoal[600],
  },
  iconButtons: {
    minWidth: '20px',
    padding: theme.spacing(1),
    margin: 0,
  },
  flexEnd: {
    justifyContent: 'flex-end',
  },
  buttonConfirm: {
    textTransform: 'none',
    marginRight: theme.spacing(1),
  },
  buttonRemove: {
    textTransform: 'none',
    color: theme.evPalette.ocean[600],
  },
  actionButtons: {
    marginTop: theme.spacing(2),
    minHeight: '32px',
    display: 'flex',
  },
  anomalyTypeText: {
    color: theme.evPalette.charcoal[900],
    fontWeight: theme.typography.fontWeightBold,
  },
  remainingSpace: {
    flex: 1,
  },
  addAnomalies: {
    cursor: `url(${addAnomalies}) 10 10, auto`,
  },
  anomalyNotesButtonSelected: {
    backgroundColor: theme.evPalette.charcoal[100],
  },
  anomalyNotesButtonHighlighted: {
    color: theme.evPalette.ocean[600],
  },
}));
