import React from 'react';
import PropTypes, {
  func, bool, string,
} from 'prop-types';
import {
  EVBox,
  EVTypography,
  EVTagIcon,
  EVNoteIcon,
  EVPaperClipIcon,
} from '@eagleview/ev-comp-library';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ASSESS_IMAGES_API_ENDPOINT } from 'constants.js';
import * as sharedUtil from 'utils/utils';
import clsx from 'clsx';
import useStyles from '../AdjusterSidePanel.styles';

const UnableToCaptureComponent = ({
  image, setSelectedImage, imageOrientation, imageIsSelected, hasEitherTagsNotesIncluded,
}) => {
  // utility hooks
  const styles = useStyles();
  const { t } = useTranslation();
  const authToken = useSelector((state) => state.adjusterReducer.accessToken);

  return (
    <EVBox className={styles.unableToCaptureBox}>
      <EVTypography className={styles.unableToCaptureText}>{t('gallery.unableToCapture')}</EVTypography>
      <EVTypography className={styles.reasonText}>{image.utcReason}</EVTypography>
      <EVBox
        onClick={() => setSelectedImage(image.urn)}
        className={styles.thumbnailWrapper}
        key={image.urn}
        data-testid="select-image"
      >
        <img
          style={{ transform: `rotate(${360 - imageOrientation}deg)` }}
          className={clsx(styles.thumbnailStyle, imageIsSelected && styles.thumbnailSelected)}
          src={`${ASSESS_IMAGES_API_ENDPOINT}/${image.urn}/thumb?width=100&height=100&${sharedUtil.getAuthParam(authToken)}`}
          alt={image.urn}
        />
        {hasEitherTagsNotesIncluded ? (
          <EVBox className={clsx(styles.thumbnailOverlay, imageIsSelected && styles.thumbnailOverlaySelected)}>
            <EVTagIcon className={clsx(styles.tagIconStyle, !image.tagsIncluded && styles.iconOpacity)} />
            <EVNoteIcon className={clsx(styles.tagIconStyle, !image.notesIncluded && styles.iconOpacity)} />
            <EVPaperClipIcon className={clsx(styles.tagIconStyle, !image.reportIncluded && styles.iconOpacity)} />
          </EVBox>
        ) : null}
      </EVBox>
    </EVBox>
  );
};

UnableToCaptureComponent.propTypes = {
  image: PropTypes.shape().isRequired,
  setSelectedImage: func.isRequired,
  imageOrientation: string.isRequired,
  imageIsSelected: bool.isRequired,
  hasEitherTagsNotesIncluded: bool.isRequired,
};

export default UnableToCaptureComponent;
