import React, { useEffect, useState } from 'react';
import {
  EVTypography,
  EVBox,
  EVDialog,
  EVDialogTitle,
  EVDialogContent,
  EVList,
  EVListItem,
  EVKeyboardArrowUp,
  EVKeyboardArrowDown,
  EVLink,
} from '@eagleview/ev-comp-library';
import { FEATURE_ENTITLEMENTS } from 'layout/entitleUser/EntitleUser.constants';
import EntitledComponent from 'components/EntitledComponent';
import { useDispatch, useSelector } from 'react-redux';
import orderBy from 'lodash/orderBy';
import { useTranslation } from 'react-i18next';
import { SORT_ORDER } from 'constants.js';
import PropType from 'prop-types';
import ReportLink from './reportLink';
import * as action from '../Adjuster.actions';

const PreviousReports = ({ styles }) => {
  // selectors
  const reportList = useSelector((state) => state.adjusterReducer.reportList);

  // actions
  const dispatch = useDispatch();
  const showReport = (payload) => dispatch(action.showReport(payload));

  // local state
  const [showPreviousReportDialog, setShowPreviousReportDialog] = useState(false);
  const [sortReportsOrder, setReportsSortOrder] = useState(SORT_ORDER.desc);
  const [reportListInDialog, setReportListInDialog] = useState([]);

  // utility hooks
  const { t } = useTranslation();

  // derived values
  const reportListInPanel = reportList.slice(0, 3).sort((a, b) => a.createdOn - b.createdOn);

  const handleShowReport = (reportUrl) => {
    showReport(reportUrl);
  };

  const handleShowPreviousReport = () => {
    setShowPreviousReportDialog(true);
  };
  useEffect(() => {
    setReportListInDialog(orderBy(reportList, 'createdOn', sortReportsOrder));
  }, [reportList, sortReportsOrder]);

  return (
    <EntitledComponent entitlement={FEATURE_ENTITLEMENTS.VIEW_REPORTS}>
      {reportListInPanel.length > 0 && (
        <EVBox className={styles.reportsContainer}>
          {reportList.length > 3 && (
          <div className={styles.viewPreviousLink}>
            <EVLink id="viewPreviousLink" onClick={handleShowPreviousReport}>{t('report.viewPrevious')}</EVLink>
          </div>
          )}
          {reportListInPanel.map((report) => (
            <div className={styles.report}>
              <ReportLink key={report.url} reportCreationDate={report.date} handleClick={() => handleShowReport(report.url)} />
            </div>
          ))}
        </EVBox>
      )}
      <EVDialog id="chartDialog" className={styles.versionDialog} open={showPreviousReportDialog} onClose={() => setShowPreviousReportDialog(false)}>
        <EVDialogTitle>{t('report.reportVersions')}</EVDialogTitle>
        <EVDialogContent className={styles.versionDialogContent}>
          <EVBox
            id="sortableVersionHeader"
            className={styles.versionListHeaderContainer}
            onClick={() => setReportsSortOrder(sortReportsOrder === SORT_ORDER.desc ? SORT_ORDER.asc : SORT_ORDER.desc)}
          >
            <EVTypography className={styles.versionListHeader}>{t('report.reportTitle')}</EVTypography>
            <div className={styles.sortIcon}>
              {sortReportsOrder === SORT_ORDER.desc ? <EVKeyboardArrowDown fontSize="small" /> : <EVKeyboardArrowUp fontSize="small" />}
            </div>
          </EVBox>
          <div className={styles.versionListContainer}>
            <EVList className={styles.versionList}>
              {reportListInDialog.map((report) => (
                <EVListItem>
                  <ReportLink reportCreationDate={report.date} handleClick={() => handleShowReport(report.url)} />
                </EVListItem>
              ))}
            </EVList>
          </div>
        </EVDialogContent>
      </EVDialog>
    </EntitledComponent>
  );
};

PreviousReports.propTypes = {
  styles: PropType.shape({
    reportsContainer: PropType.string,
    viewPreviousLink: PropType.string,
    report: PropType.string,
    sortIcon: PropType.string,
    versionListContainer: PropType.string,
    versionList: PropType.string,
    versionListHeader: PropType.string,
    versionDialog: PropType.string,
    versionDialogContent: PropType.string,
    versionListHeaderContainer: PropType.string,
  }).isRequired,
};

export default PreviousReports;
