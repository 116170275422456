import React, { useEffect, useState } from 'react';
import {
  EVBox,
  EVTypography,
  EVSidePanel,
  useTheme,
  EVToolbarPanel,
  EVIconButton,
} from '@eagleview/ev-comp-library';
import { useDispatch, useSelector } from 'react-redux';
import {
  string, func,
} from 'prop-types';
import get from 'lodash/get';
import { useTranslation } from 'react-i18next';
import { ASSESS_DOWNLOAD_RAW_IMAGERY } from 'layout/entitleUser/EntitleUser.constants';
import downloadIcon from 'assets/download.svg';
import PhotosThumbnailGrid from './photos-thumbnails-grid/PhotosThumbnailsGrid';
import useStyles from './PhotosUI.styles';
import * as action from './Photos.actions';

const RightAlignedSidePanel = ({
  orderId, onThumbnailItemClicked, onThumbnailItemHover, hoveredGridItem,
}) => {
  // utility hooks
  const { t } = useTranslation();

  // actions
  const dispatch = useDispatch();
  const fetchThumbnailAction = (payload) => dispatch(action.fetchThumbnailAction(payload));
  const initiateZipDownloadAction = (payload) => dispatch(action.initiateZipDownloadAction(payload));
  const checkZipStatusAction = (payload) => dispatch(action.checkZipStatusAction(payload));
  const clearZipStatusIdAction = () => dispatch(action.clearZipStatusIdAction());
  const classes = useStyles();
  const theme = useTheme();

  // selectors
  const facetOverviewThumbnails = useSelector((state) => get(state, 'photosReducer.thumbnails.facet_overview', []));
  const zipDownloadStatusId = useSelector((state) => get(state, 'photosReducer.zipDownloadStatusId', ''));
  const zipDownloadTriggerSuccess = useSelector((state) => get(state, 'photosReducer.zipDownloadTriggerSuccess', false));
  const isAssessZipDownloadEnabled = useSelector((state) => get(state.entitleUserReducer.featureFlags, ASSESS_DOWNLOAD_RAW_IMAGERY, false));
  const selectedStructureId = useSelector((state) => get(state, 'photosReducer.selectedStructureId', ''));

  // states
  const [downloadInprogress, setDownloadInprogress] = useState(false);

  useEffect(() => {
    clearZipStatusIdAction();
    fetchThumbnailAction({
      orderId,
      structureId: selectedStructureId, // hardcoded for now as multi-structure is not yet supported.
      imageType: 'facet_overview',
    });
  }, [selectedStructureId]);

  useEffect(() => {
    if (zipDownloadStatusId !== '' && zipDownloadTriggerSuccess) {
      setDownloadInprogress(true);
      checkZipStatusAction({
        uuid: zipDownloadStatusId,
      });
    } else {
      setDownloadInprogress(false);
    }
  }, [zipDownloadStatusId]);

  const handleOnItemClicked = (image) => {
    onThumbnailItemClicked(image);
  };

  const initiateZipDownload = () => {
    initiateZipDownloadAction(
      {
        orderId,
        structureId: selectedStructureId,
      },
    );
  };

  return (
    <EVSidePanel
      dockDirection="right"
      visibilityToggleEnabled={false}
      resizeHandleEnabled={false}
      visibility="show"
      width="280px"
      height={`calc(100% - ${theme.evSpacing['ev-spacing--32']} - 2px)`}
      elevation={6}
      className={classes.sidepanel}
    >
      <EVBox className={classes.overViewContentContainer}>
        <EVBox>
          <EVBox className={!isAssessZipDownloadEnabled && classes.cardHeader}>
            {isAssessZipDownloadEnabled && (
            <EVToolbarPanel
              className={classes.toolbarPanel}
              displayType="vertical"
              iconStyles={{ height: 'auto', borderRadius: '4px' }}
              backgroundColor={theme.palette.common.white}
              style={{ borderRadius: '4px' }}
              border="1px solid #ccc"
            >
              <EVIconButton
                id="download-button"
                onClick={initiateZipDownload}
                tooltip={downloadInprogress ? t('download-imagery-in-progress') : t('download-imagery-start')}
                disabled={downloadInprogress}
              >
                <img alt="" src={downloadIcon} />
              </EVIconButton>
            </EVToolbarPanel>
            )}
            <EVBox className={classes.boxHeader}>
              <EVTypography className={classes.headerText}>
                {t('photos-ui.facetOverviewImagery')}
              </EVTypography>
            </EVBox>
          </EVBox>
        </EVBox>
        <EVBox className={classes.cardContent}>
          <PhotosThumbnailGrid
            images={facetOverviewThumbnails}
            onItemClicked={(image) => handleOnItemClicked(image)}
            onItemHovered={(image) => onThumbnailItemHover(image)}
            hoveredGridItem={hoveredGridItem}
          />
        </EVBox>
      </EVBox>
    </EVSidePanel>
  );
};

RightAlignedSidePanel.propTypes = {
  orderId: string,
  onThumbnailItemClicked: func,
  onThumbnailItemHover: func,
  hoveredGridItem: string,
};

RightAlignedSidePanel.defaultProps = {
  orderId: '',
  onThumbnailItemClicked: () => {},
  onThumbnailItemHover: () => {},
  hoveredGridItem: '',
};

export default RightAlignedSidePanel;
