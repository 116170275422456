export const initialValue = {
  show: false,
  description: '',
  top: 0,
  left: 0,
  includeInReport: false,
};
export const CLEAR = 'Clear';
export const ALL = 'All';
export const POSITION = 'Position';
export const SHOW = 'Show';
export const HIDE = 'Hide';
export const WIDTH = 340;
export const HEIGHT = 160;
