import {
  enableWorkflowNavigationAction,
  showWorkflowFacetPolygonAction,
  setWorkflowBaseImgParentAction,
  replaceFacetMetadataAction,
  setWorkflowBaseImgViewAction,
} from './Adjuster.actions';

const AssessDebug = (dispatch) => {
  const replaceFacetMetadata = (facetAliasName, metadata) => {
    if (facetAliasName && metadata) {
      dispatch(replaceFacetMetadataAction({ facetAliasName, metadata }));
    }
  };
  const enableWorkflowNavigation = () => {
    dispatch(enableWorkflowNavigationAction());
    dispatch(showWorkflowFacetPolygonAction());
  };
  const setWorkflowBaseImgParent = () => {
    dispatch(setWorkflowBaseImgParentAction(true));
  };
  const setWorkflowBaseImgView = () => {
    dispatch(setWorkflowBaseImgViewAction(true));
  };
  return {
    replaceFacetMetadata,
    enableWorkflowNavigation,
    setWorkflowBaseImgParent,
    setWorkflowBaseImgView,
  };
};
export default AssessDebug;
