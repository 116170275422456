import { get } from 'lodash';
import omit from 'lodash/omit';
import { isCancelError } from 'utils/utils';
import * as constants from './ManualAtAdjustment.constants';
import { getAddressString, mapCompletedVertices, mapCoordinatePairings } from './ManualAtAdjustment.utils';

export const initialStructureState = {
  images: [],
  obliqueImages: [],
  activeImage: '',
  coordinatePairings: [],
  overviewView: {},
  overviewInitialView: constants.INITIAL_VIEW_OVERVIEW,
  completedVertices: [],
  selectedImagesList: [],
  isMinImageSelected: false,
  facetImageUrl: '',
};

export const initialReportState = {
  view: constants.INITIAL_VIEW_ORTHO,
  initialView: constants.INITIAL_VIEW_ORTHO,
  vertices: [],
  orthoImage: [],
  polygon: [],
  reportFetched: false,
};

export const initialTiePointState = {
  ...initialStructureState,
  ...initialReportState,
};

const initialState = {
  ...initialTiePointState,
  src: undefined,
  dst: undefined,
  activeVertex: undefined,
  loading: {},
  toastSeverity: 'error',
  showToast: undefined,
  showToastPersistently: false,
  toastDuration: 10000,
  hideUI: true,
  taskStateId: '',
  readOnlyMode: false,
  orderAddress: '',
  reports: [],
  structures: [],
  capturedStructures: [],
  capturedStructuresCount: 0,
  facetImageUrl: '',
  selectedStructure: {},
  reportDetails: {},
  structuresDetails: {},
  structureReportMap: {},
  selectedReportId: '',
  showSkydioImage: false,
  allReportsFetched: false,
  skydioImageUrl: '',
};

const manualAtAdjustmentReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case constants.FETCH_IMAGES:
    case constants.FETCH_ORTHO_POLYGON:
    case constants.FETCH_ORTHO_IMAGE:
    case constants.FETCH_ORDER_DETAILS:
    case constants.FETCH_STRUCTURE_DETAILS_AND_IMAGES:
    case constants.DOWNLOAD_FACET_IMAGE:
    case constants.DOWNLOAD_SKYDIO_MOSAIC_IMAGE:
    case constants.SAVE_STRUCTURE_TIE_POINT:
    case constants.RESET_ORDER_TIE_POINTS:
    case constants.SKIP_STRUCTURE:
    case constants.REJECT:
    case constants.COMPLETE:
      return {
        ...state,
        loading: { ...state.loading, [type]: true },
      };
    case constants.FETCH_IMAGES_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, [constants.FETCH_IMAGES]: false },
        structuresDetails: {
          ...state.structuresDetails,
          [payload.structureId]: {
            ...state.structuresDetails[payload.structureId],
            images: !payload.isImageTypeOblique && payload.imagesWithUrl && payload.imagesWithUrl.length > 0 ? payload.imagesWithUrl
              : state.structuresDetails[payload.structureId].images,
            obliqueImages: payload.isImageTypeOblique && payload.imagesWithUrl && payload.imagesWithUrl.length > 0 ? payload.imagesWithUrl
              : state.structuresDetails[payload.structureId].obliqueImages,
            selectedImagesList: state.structuresDetails[payload.structureId].selectedImagesList.length
              ? state.structuresDetails[payload.structureId].selectedImagesList
              : ((payload.imagesWithUrl.length && [payload.imagesWithUrl[0].image_name]) || []),
            loadOblique: !payload.isImageTypeOblique && payload.obliqueAvailable,
          },
        },
      };
    case constants.FETCH_IMAGES_FAILURE:
      if (isCancelError(payload)) return { ...state, loading: { ...state.loading, [constants.FETCH_IMAGES]: false } };
      return {
        ...state,
        loading: { ...state.loading, [constants.FETCH_IMAGES]: false },
        showToast: true,
        toastMessage: constants.SERVER_ERROR_CODES_TO_TOAST_MESSAGE[payload.errorCode] || 'manualAtAdjustment.fetchImagesError',
        errorCode: payload.errorCode,
        toastSeverity: 'error',
      };
    case constants.SET_ACTIVE_IMAGE:
      return payload.structureId
        ? {
          ...state,
          structuresDetails: {
            ...state.structuresDetails,
            [payload.structureId]: {
              ...state.structuresDetails[payload.structureId],
              activeImage: payload.image || state.structuresDetails[payload.structureId].activeImage,
            },
          },
        } : {
          ...state,
          activeImage: payload.image || state.activeImage,
        };
    case constants.ADD_COORD_ONE:
      return {
        ...state,
        dst: payload,
      };
    case constants.ADD_COORD_TWO:
      return {
        ...state,
        src: {
          ...state.src,
          [state.activeImage.image_name]: payload,
        },
      };
    case constants.ADD_ACTIVE_COORD_PAIRINGS:
      return {
        ...state,
        src: undefined,
        dst: undefined,
        coordinatePairings: [...state.coordinatePairings, {
          id: payload.id, index: payload.coordinateIndex, dst: state.dst, src: state.src,
        }],
        vertices: [
          {
            ...state.vertices[0],
            data: state.vertices[0].data.map((x) => {
              switch (true) {
                case state.completedVertices.includes(x.id):
                  return { ...x, style: constants.VERTEX_STYLE.COMPLETED };
                case x.id === state.activeVertex:
                  return { ...x, style: constants.VERTEX_STYLE.COMPLETED };
                default:
                  return { ...x, style: constants.VERTEX_STYLE.UNSELECTED };
              }
            }),
          },
        ],
        completedVertices: [...state.completedVertices, state.activeVertex],
      };
    case constants.CLOSE_ERROR_TOAST:
      return {
        ...state,
        showToast: false,
        showToastPersistently: false,
        toastDuration: 10000,
        errorCode: undefined,
      };
    case constants.FETCH_ORTHO_POLYGON_SUCCESS: {
      const reportDetails = {
        ...state.reportDetails,
        [payload.reportId]: {
          ...state.reportDetails[payload.reportId],
          vertices: payload.vertices,
          polygon: payload.polygon,
          reportFetched: true,
        },
      };
      const allReportsFetched = Object.values(reportDetails).find((report) => !report.reportFetched) === undefined;
      return {
        ...state,
        reportDetails,
        allReportsFetched,
      };
    }
    case constants.FETCH_ORTHO_POLYGON_FAILURE: {
      if (isCancelError(payload)) return { ...state };
      const reportDetails = {
        ...state.reportDetails,
        [payload.reportId]: {
          ...state.reportDetails[payload.reportId],
          reportFetched: true,
        },
      };
      const allReportsFetched = Object.values(reportDetails).find((report) => !report.reportFetched) === undefined;
      return {
        ...state,
        showToast: true,
        toastMessage: 'manualAtAdjustment.fetchReportDetailsFailure',
        errorCode: payload.errorCode,
        toastSeverity: 'error',
        reportDetails,
        allReportsFetched,
      };
    }
    case constants.FETCH_ORTHO_IMAGE_SUCCESS:
      return {
        ...state,
        reportDetails: {
          ...state.reportDetails,
          [payload.reportId]: {
            ...state.reportDetails[payload.reportId],
            orthoImage: [payload.imageData],
          },
        },
      };
    case constants.FETCH_ORTHO_IMAGE_FAILURE:
      if (isCancelError(payload)) return { ...state };
      return {
        ...state,
        showToast: true,
        toastMessage: 'manualAtAdjustment.fetchReportImagesFailure',
        errorCode: payload.errorCode,
        toastSeverity: 'error',
      };
    case constants.FETCH_ORDER_DETAILS_SUCCESS: {
      const structures = get(payload, 'structures', []);
      const capturedStructures = structures.filter((structure) => structure.isCaptured);
      const initialSelectedStructure = capturedStructures.length > 0 ? capturedStructures[0] : {};
      const reports = get(payload, 'reports', []);
      const initialSelectedReport = get(initialSelectedStructure, 'reportID', '');
      const initialReport = initialSelectedReport || (initialSelectedStructure.structureID && !(reports.length > 1) && reports[0]);
      return {
        ...state,
        loading: { ...state.loading, [constants.FETCH_ORDER_DETAILS]: false },
        orderDetails: payload,
        reports,
        structures,
        capturedStructures,
        capturedStructuresCount: capturedStructures.length,
        selectedStructure: initialSelectedStructure,
        orderAddress: getAddressString(payload),
        structuresDetails: capturedStructures.reduce((structuresDetails, structure) => ({
          ...structuresDetails, [structure.structureID]: initialStructureState,
        }), {}),
        reportDetails: reports.reduce((reportDetails, report) => ({ ...reportDetails, [report]: initialReportState }), {}),
        structureReportMap: capturedStructures.reduce((structureReportMap, structure) => {
          const selectedReport = get(structure, 'reportID', '') || (!(reports.length > 1) && reports[0]);
          return selectedReport ? { ...structureReportMap, [structure.structureID]: selectedReport } : { ...structureReportMap };
        }, {}),
        selectedReportId: initialReport || '',
      };
    }
    case constants.FETCH_ORDER_DETAILS_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, [constants.FETCH_ORDER_DETAILS]: false },
        showToast: true,
        toastMessage: 'manualAtAdjustment.fetchOrderDetailsError',
        errorCode: payload.errorCode,
        toastSeverity: 'error',
      };
    case constants.SET_ACTIVE_VERTEX:
      return {
        ...state,
        activeVertex: payload,
        vertices: [
          {
            ...state.vertices[0],
            data: state.vertices[0].data.map((x) => {
              switch (true) {
                case x.id === payload:
                  return { ...x, style: constants.VERTEX_STYLE.SELECTED };
                case state.completedVertices.includes(x.id):
                  return { ...x, style: constants.VERTEX_STYLE.COMPLETED };
                default:
                  return { ...x, style: constants.VERTEX_STYLE.UNSELECTED };
              }
            }),
          },
        ],
      };
    case constants.HANDLE_CHECKOUT_ERROR:
      return {
        ...state,
        showToast: true,
        toastMessage: payload,
        errorCode: null,
        toastSeverity: 'error',
        hideUI: true,
      };
    case constants.SET_OVERVIEW_VIEW:
      return {
        ...state,
        overviewView: {
          ...state.overviewView,
          [state.activeImage.image_name]: payload,
        },
      };
    case constants.SET_OVERVIEW_INITIAL_VIEW:
      return {
        ...state,
        overviewInitialView: payload || state.view,
      };
    case constants.SET_VIEW:
      return payload.reportId ? {
        ...state,
        reportDetails: {
          ...state.reportDetails,
          [payload.reportId]: {
            ...state.reportDetails[payload.reportId],
            view: payload.view || state.reportDetails[payload.reportId].view,
          },
        },
      } : {
        ...state,
        view: payload || state.view,
      };
    case constants.SET_INITIAL_VIEW:
      return payload.reportId ? {
        ...state,
        reportDetails: {
          ...state.reportDetails,
          [payload.reportId]: {
            ...state.reportDetails[payload.reportId],
            initialView: payload.initialView || state.reportDetails[payload.reportId].view,
          },
        },
      } : {
        ...state,
        initialView: payload || state.view,
      };
    case constants.SET_SELECTED_IMAGES_LIST:
      return {
        ...state,
        selectedImagesList: payload,
        overviewView: Object.assign(payload.map((image) => ({ [image]: constants.INITIAL_VIEW_OVERVIEW }))),
      };
    case constants.SET_IS_MIN_IMAGE_SELECTED:
      return {
        ...state,
        isMinImageSelected: payload,
      };
    case constants.UPDATE_TASK_STATE_ID:
      return {
        ...state,
        taskStateId: payload,
      };
    case constants.COMPLETE_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, [constants.COMPLETE]: false },
        showToast: true,
        toastMessage: 'manualAtAdjustment.completeMessage',
        toastSeverity: 'success',
        errorCode: '',
        orderDetails: { ...state.orderDetails, status: constants.STATUS_COMPLETED },
      };
    case constants.COMPLETE_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, [constants.COMPLETE]: false },
        showToast: true,
        toastMessage: 'manualAtAdjustment.completeError',
        errorCode: payload.errorCode,
        toastSeverity: 'error',
      };
    case constants.REJECT_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, [constants.REJECT]: false },
        showToast: true,
        toastMessage: 'manualAtRejectPanel.rejectSuccessMessage',
        toastSeverity: 'success',
        errorCode: '',
      };
    case constants.REJECT_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, [constants.REJECT]: false },
        showToast: true,
        toastMessage: 'manualAtRejectPanel.rejectError',
        errorCode: payload.errorCode,
        toastSeverity: 'error',
      };
    case constants.SHOW_EMPTY_REJECT_NOTES_ERROR:
      return {
        ...state,
        showToast: true,
        toastMessage: 'manualAtRejectPanel.emptyNotesErrorMessage',
        toastSeverity: 'error',
        errorCode: '',
      };
    case constants.FETCH_IMAGES_AND_DETAILS_FAILURE:
      return {
        ...state,
        showToast: true,
        toastMessage: 'manualAtAdjustment.fetchOrderDetailsError',
        errorCode: payload.errorCode,
        toastSeverity: 'error',
      };
    case constants.SET_HIDE_UI:
      return {
        ...state,
        hideUI: payload,
      };
    case constants.SET_INITIAL_IMAGES_DETAILS:
      return {
        ...state,
        structuresDetails: {
          ...state.structuresDetails,
          [payload.structureId]: {
            ...state.structuresDetails[payload.structureId],
            images: payload.images && payload.images.length > 0 ? payload.images : state.structuresDetails[payload.structureId].images,
          },
        },
      };
    case constants.SET_EXPLORE_OBLIQUE_IMAGES: {
      return {
        ...state,
        structuresDetails: {
          ...state.structuresDetails,
          [payload.structureId]: {
            ...state.structuresDetails[payload.structureId],
            obliqueImages: payload.images && payload.images.length > 0 ? payload.images : state.structuresDetails[payload.structureId].obliqueImages,
          },
          loadOblique: false,
        },
      };
    }
    case constants.SHOW_REJECT_REASON_NOT_SELECTED_ERROR:
      return {
        ...state,
        showToast: true,
        toastMessage: 'manualAtRejectPanel.emptyRejectReasonNotSelected',
        errorCode: '',
        toastSeverity: 'error',
      };
    case constants.SET_SRC:
      return {
        ...state,
        src: payload,
      };
    case constants.ADD_COORDINATE_PAIRING:
      return {
        ...state,
        coordinatePairings: [...state.coordinatePairings, payload.coordinatePair],
        completedVertices: [...state.completedVertices, payload.vertexId],
      };
    case constants.REMOVE_COORDINATE_PAIRING:
      return {
        ...state,
        coordinatePairings: [...state.coordinatePairings.filter((pairing) => pairing.id !== payload)],
        completedVertices: [...state.completedVertices.filter((vertex) => vertex !== payload)],
      };
    case constants.SET_SRC_DST:
      return {
        ...state,
        src: payload.src,
        dst: payload.dst,
      };
    case constants.DELETE_OVERVIEW_POINT: {
      const isNotLastSrcPoint = Object.keys(state.src).length > 1;
      return {
        ...state,
        src: isNotLastSrcPoint ? omit({ ...state.src }, state.activeImage.image_name) : undefined,
        dst: isNotLastSrcPoint ? state.dst : undefined,
        vertices: isNotLastSrcPoint ? state.vertices : [
          {
            ...state.vertices[0],
            data: state.vertices[0].data.map((x) => {
              if (x.id === state.activeVertex) {
                return { ...x, style: constants.VERTEX_STYLE.UNSELECTED };
              }
              return x;
            }),
          },
        ],
      };
    }
    case constants.SHOW_NOT_ENTITLED_TO_MANUAL_AT_ERROR:
      return {
        ...state,
        showToast: true,
        toastMessage: 'manualAtAdjustment.notEntitledError',
        toastSeverity: 'error',
        errorCode: '',
        showToastPersistently: true,
      };
    case constants.ENABLE_READ_ONLY_MODE:
      return {
        ...state,
        readOnlyMode: true,
      };
    case constants.SHOW_TOAST_MESSAGE:
      return {
        ...state,
        showToast: true,
        toastMessage: payload.toastMessage,
        toastSeverity: payload.toastSeverity,
        errorCode: payload.errorCode,
        showToastPersistently: payload.showToastPersistently,
        toastDuration: payload.toastDuration,
      };
    case constants.FETCH_STRUCTURE_DETAILS_AND_IMAGES_SUCCESS: {
      const { structureDetails, structureId } = payload;
      const pairing = get(structureDetails, 'pairing', []);
      const selectedImages = get(structureDetails, 'pairing[0].selectedImages', []);
      const reportDetail = state.reportDetails[state.selectedStructure.reportID];
      const vertices = reportDetail ? reportDetail.vertices : [];
      return {
        ...state,
        loading: { ...state.loading, [constants.FETCH_STRUCTURE_DETAILS_AND_IMAGES]: false },
        structuresDetails: {
          ...state.structuresDetails,
          [structureId]: {
            ...state.structuresDetails[structureId],
            details: structureDetails,
            completedVertices: mapCompletedVertices(pairing),
            coordinatePairings: vertices && vertices.length > 0 ? mapCoordinatePairings(pairing, vertices) : [],
            isMinImageSelected: selectedImages && selectedImages.length >= 2,
            selectedImagesList: selectedImages || [],
            overviewView: selectedImages && selectedImages.length > 0
              ? Object.assign(...selectedImages.map((image) => ({ [image]: constants.INITIAL_VIEW_OVERVIEW }))) : {},
          },
        },
      };
    }
    case constants.FETCH_STRUCTURE_DETAILS_AND_IMAGES_FAILURE:
      if (isCancelError(payload)) return { ...state, loading: { ...state.loading, [constants.FETCH_STRUCTURE_DETAILS_AND_IMAGES]: false } };
      return {
        ...state,
        loading: { ...state.loading, [constants.FETCH_STRUCTURE_DETAILS_AND_IMAGES]: false },
        toastMessage: 'manualAtAdjustment.fetchStructureDetailsFailure',
        errorCode: payload.errorCode,
        toastSeverity: 'error',
        showToast: true,
      };
    case constants.DOWNLOAD_FACET_IMAGE_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, [constants.DOWNLOAD_FACET_IMAGE]: false },
        structuresDetails: {
          ...state.structuresDetails,
          [payload.structureId]: {
            ...state.structuresDetails[payload.structureId],
            facetImageUrl: payload.facetImageUrl,
          },
        },
      };
    case constants.DOWNLOAD_FACET_IMAGE_FAILURE:
      if (isCancelError(payload)) return { ...state, loading: { ...state.loading, [constants.DOWNLOAD_FACET_IMAGE]: false } };
      return {
        ...state,
        loading: { ...state.loading, [constants.DOWNLOAD_FACET_IMAGE]: false },
        toastMessage: 'manualAtAdjustment.facetImageDownloadFailure',
        errorCode: payload.errorCode,
        toastSeverity: 'error',
        showToast: true,
      };
    case constants.SET_CURRENT_STRUCTURE:
      return {
        ...state,
        selectedStructure: payload,
        selectedReportId: '',
      };
    case constants.MAP_STRUCTURE_WITH_REPORT:
      return {
        ...state,
        structureReportMap: {
          ...state.structureReportMap,
          [state.selectedStructure.structureID]: payload,
        },
        selectedReportId: payload,
      };
    case constants.SET_SELECTED_REPORT_ID:
      return {
        ...state,
        selectedReportId: payload,
      };
    case constants.SET_REPORT_AND_STRUCTURE_TIE_POINT_DATA: {
      const { selectedReport, structureId } = payload;
      const { reportDetails } = state;
      const { structuresDetails } = state;
      const reportVertices = state.reportDetails[selectedReport].vertices;
      const updatedVertices = reportVertices[0]
        && reportVertices[0].data.map((vertex) => (structuresDetails[structureId].completedVertices.includes(vertex.id)
          ? { ...vertex, style: constants.VERTEX_STYLE.COMPLETED } : { ...vertex }));
      return {
        ...state,
        activeImage: structuresDetails[structureId].activeImage,
        coordinatePairings: structuresDetails[structureId].coordinatePairings || [],
        view: reportDetails[selectedReport].view,
        initialView: reportDetails[selectedReport].initialView,
        overviewView: structuresDetails[structureId].overviewView,
        overviewInitialView: constants.INITIAL_VIEW_OVERVIEW,
        vertices: updatedVertices ? [{ ...reportVertices[0], data: updatedVertices }] : reportVertices,
        orthoImage: reportDetails[selectedReport].orthoImage || [],
        completedVertices: structuresDetails[structureId].completedVertices || [],
        polygon: reportDetails[selectedReport].polygon || [],
        selectedImagesList: structuresDetails[structureId].selectedImagesList || [],
        isMinImageSelected: structuresDetails[structureId].isMinImageSelected || false,
        facetImageUrl: structuresDetails[structureId].facetImageUrl,
        images: structuresDetails[structureId].images,
        obliqueImages: structuresDetails[structureId].obliqueImages,
        skydioImageUrl: state.skydioImageUrl || structuresDetails[structureId].skydioImageUrl || '',
      };
    }
    case constants.CLEAR_REPORT_AND_STRUCTURE_TIE_POINT_DATA:
      return {
        ...state,
        ...initialTiePointState,
        src: undefined,
        dst: undefined,
        activeVertex: undefined,
        skydioImageUrl: '',
      };

    case constants.SAVE_STRUCTURE_TIE_POINT_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, [constants.SAVE_STRUCTURE_TIE_POINT]: false },
        structuresDetails: {
          ...state.structuresDetails,
          [payload.structureId]: {
            ...state.structuresDetails[payload.structureId],
            activeImage: state.activeImage,
            overviewView: state.overviewView,
            overviewInitialView: state.overviewInitialView,
            selectedImagesList: state.selectedImagesList,
            isMinImageSelected: state.isMinImageSelected,
            facetImageUrl: state.facetImageUrl,
            coordinatePairings: state.coordinatePairings,
            completedVertices: state.completedVertices,
            skydioImageUrl: state.skydioImageUrl,
          },
        },
        reportDetails: {
          ...state.reportDetails,
          [payload.reportId]: {
            ...state.reportDetails[payload.reportId],
            view: state.view,
            initialView: state.initialView,
            completedVertices: state.completedVertices,
          },
        },
      };
    case constants.SAVE_STRUCTURE_TIE_POINT_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, [constants.SAVE_STRUCTURE_TIE_POINT]: false },
        toastMessage: 'manualAtAdjustment.saveTiePointFailure',
        errorCode: payload.errorCode,
        toastSeverity: 'error',
        showToast: true,
      };
    case constants.SET_SHOW_SKYDIO_IMAGE:
      return {
        ...state,
        showSkydioImage: payload,
      };
    case constants.DOWNLOAD_SKYDIO_MOSAIC_IMAGE_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, [constants.DOWNLOAD_SKYDIO_MOSAIC_IMAGE]: false },
        skydioImageUrl: payload.skydioImageUrl,
      };
    case constants.DOWNLOAD_SKYDIO_MOSAIC_IMAGE_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, [constants.DOWNLOAD_SKYDIO_MOSAIC_IMAGE]: false },
        toastMessage: 'manualAtAdjustment.skydioImageDownloadFailure',
        errorCode: payload.error.errorCode,
        toastSeverity: 'error',
        showToast: true,
        showSkydioImage: false,
        skydioImageUrl: constants.SKYDIO_IMAGE_DOWNLOAD_ERROR,
      };
    case constants.RESET_ORDER_TIE_POINTS_SUCCESS: {
      const reportDetails = { ...state.reportDetails };
      const structuresDetails = { ...state.structuresDetails };
      const capturedStructureList = payload.structures.filter((structure) => structure.isCaptured);
      const updatedStructuresDetails = capturedStructureList.reduce((updatedDetails, structure) => {
        const structureId = structure.structureID;
        const structureSkydioImageUrl = structuresDetails[structureId] && structuresDetails[structureId].skydioImageUrl;
        if (structuresDetails[structureId]) {
          return {
            ...updatedDetails,
            [structureId]: {
              ...structuresDetails[structureId],
              completedVertices: [],
              coordinatePairings: [],
              isMinImageSelected: false,
              overviewInitialView: constants.INITIAL_VIEW_OVERVIEW,
              overviewView: {},
              selectedImagesList: structuresDetails[structureId].images.length ? [structuresDetails[structureId].images[0].image_name] : [],
              activeImage: structuresDetails[structureId].images.find((img) => img.image_name === structuresDetails[structureId].selectedImagesList[0]),
              details: { ...structuresDetails[structureId].details, pairing: [], isCaptured: true },
              skydioImageUrl: structureSkydioImageUrl
                || (state.selectedStructure.structureID === structureId && state.skydioImageUrl ? state.skydioImageUrl : ''),
            },
          };
        }
        return { ...updatedDetails, [structureId]: initialStructureState };
      }, {});
      const capturedStructures = capturedStructureList.map((structure) => ({
        ...updatedStructuresDetails[structure.structureID], structureID: structure.structureID,
      }));
      const initialSelectedStructure = capturedStructures.length > 0 ? capturedStructures[0] : {};
      const initialReport = initialSelectedStructure.structureID && !(state.reports.length > 1) && state.reports[0];
      return {
        ...state,
        loading: { ...state.loading, [constants.RESET_ORDER_TIE_POINTS]: false },
        ...initialTiePointState,
        src: undefined,
        dst: undefined,
        activeVertex: undefined,
        showSkydioImage: false,
        reportDetails: Object.keys(reportDetails).reduce((updatedRepDetails, rep) => ({
          ...updatedRepDetails,
          [rep]: {
            ...reportDetails[rep],
            view: reportDetails[rep].initialView,
          },
        }), {}),
        selectedStructure: initialSelectedStructure,
        structureReportMap: capturedStructures.reduce((structureReportMap, structure) => {
          const selectedReport = get(structure, 'reportID', '') || (!(state.reports.length > 1) && state.reports[0]);
          return selectedReport ? { ...structureReportMap, [structure.structureID]: selectedReport } : { ...structureReportMap };
        }, {}),
        selectedReportId: initialReport || '',
        structuresDetails: updatedStructuresDetails,
        capturedStructures,
        capturedStructuresCount: capturedStructures.length,
        skydioImageUrl: '',
      };
    }
    case constants.RESET_ORDER_TIE_POINTS_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, [constants.RESET_ORDER_TIE_POINTS]: false },
        toastMessage: 'manualAtAdjustment.resetOrderTiePointsFailure',
        errorCode: payload.errorCode,
        toastSeverity: 'error',
        showToast: true,
      };
    case constants.SKIP_STRUCTURE_SUCCESS: {
      const structureDetails = { ...state.structuresDetails };
      return {
        ...state,
        loading: { ...state.loading, [constants.SKIP_STRUCTURE]: false },
        capturedStructures: [...state.capturedStructures].filter((structure) => structure.structureID !== payload.structureId),
        structures: [...state.structures].map((structure) => (structure.structureID === payload.structureId
          ? { ...structure, isCaptured: false } : { ...structure })),
        structuresDetails: Object.keys(structureDetails).reduce((updatedStructureDetails, structureId) => {
          if (structureId === payload.structureId) {
            return {
              ...updatedStructureDetails,
              [structureId]: {
                ...structureDetails[structureId],
                details: { ...structureDetails[structureId], isCaptured: false },
              },
            };
          }
          return { ...updatedStructureDetails, [structureId]: { ...structureDetails[structureId] } };
        }, {}),
      };
    }
    case constants.SKIP_STRUCTURE_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, [constants.SKIP_STRUCTURE]: false },
        toastMessage: 'manualAtAdjustment.skipStructureFailure',
        errorCode: payload.errorCode,
        toastSeverity: 'error',
        showToast: true,
      };
    case constants.RESET_ORDER:
      return initialState;
    default:
      return state;
  }
};

export default manualAtAdjustmentReducer;
