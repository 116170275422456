import axios from 'axios';
import { getAuthToken, getShareToken } from 'utils/auth.utils';
import { last, get } from 'lodash';
import { ASSESS_IWH_ENTITLEMENTS } from 'layout/entitleUser/EntitleUser.constants';
import { IW_API_ENDPOINT } from './constants';

const INTERCEPTOR_IGNORE_FILE_EXT = ['json', 'img'];

/**
 * Interceptor | TODO - improve, optimize & secure - conditionally add auth headers token.
 */
export default {
  setupInterceptors: (args) => {
    let signOut = true;
    const isSharedURL = window.location.origin === process.env.REACT_APP_ASSESS_SHAREDURL_APP_ORIGIN;
    const IWHHeaderFeatureFlag = get(args, ASSESS_IWH_ENTITLEMENTS, false);
    const moreHeaders = (addHeaders) => (addHeaders ? { 'X-Use-Platform-Entitlements': 'lorem ipsum' } : {});
    axios.interceptors.request.use(
      async (config) => ({
        ...config,
        url: config.url.startsWith('http') ? config.url : process.env.REACT_APP_API_URL + config.url,
        headers: {
          ...config.headers,
          ...(!isSharedURL && { Authorization: !INTERCEPTOR_IGNORE_FILE_EXT.includes(last(config.url.split('.'))) ? `Bearer ${getAuthToken()}` : '' }),
          ...(isSharedURL && { 'x-access-token': `${getShareToken()}` }),
          ...moreHeaders(config.url.includes(IW_API_ENDPOINT) && IWHHeaderFeatureFlag),
        },
      }),
      (error) => Promise.reject(error),
    );
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response && error.response.status === 401) {
          if (signOut) {
            signOut = false;
            window.logOut();
          }
        }
        return Promise.reject(error);
      },
    );
  },
};
