import { makeStyles } from '@eagleview/ev-comp-library';

const useStyles = makeStyles((theme) => ({
  statusBar: {
    height: theme.evSpacing['ev-spacing--32'],
    position: 'absolute',
    bottom: 0,
    right: 0,
    width: (props) => `calc(100% - ${props.sidePanelWidth})`,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: theme.evPalette.salt[100],
    color: theme.evPalette.charcoal[600],
  },
  statusBarIncluded: {
    borderLeft: `1px solid ${theme.evPalette.charcoal[200]}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: theme.evSpacing['ev-spacing--32'],
    padding: `0 ${theme.evSpacing['ev-spacing--16']}`,
    overflow: 'hidden',
  },
  inactiveIcon: {
    color: theme.evPalette.charcoal[600],
  },
  activeIcon: {
    color: theme.evPalette.ocean[500],
  },
  statusBarRight: {
    display: 'flex',
    width: '65%',
    justifyContent: 'right',
  },
  statusBarButton: {
    textTransform: 'unset',
    color: 'unset',
    padding: `0 ${theme.evSpacing['ev-spacing--16']}`,
    height: theme.evSpacing['ev-spacing--32'],
    borderRadius: 0,
    background: (P) => P.active && theme.evPalette.nickel[100],
    borderLeft: `1px solid ${theme.evPalette.charcoal[200]}`,
  },
  statusBarText: {
    margin: `0 ${theme.evSpacing['ev-spacing--8']}`,
  },
  textFieldStyle: {
    width: '96%',
    marginLeft: '2%',
    marginBottom: '5%',
  },
  tagsContainer: {
    paddingTop: theme.evSpacing['ev-spacing--8'],
    width: '350px',
    height: '260px',
  },
  scrollable: {
    height: '132px',
    overflowY: 'auto',
    flex: '1 1 auto',
  },
  tagsTitle: {
    marginLeft: '2%',
    marginBottom: '2%',
  },
  renderNewLines: {
    whiteSpace: 'pre-wrap',
  },
  imageNameStyle: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: theme.evPalette.charcoal[600],
    fontSize: '14px',
    paddingLeft: theme.evSpacing['ev-spacing--16'],
  },
  tabs: {
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
  },
  statusBarConfidenceFactor: {
    borderLeft: `1px solid ${theme.evPalette.charcoal[200]}`,
    margin: `0 ${theme.evSpacing['ev-spacing--12']}`,
  },
  confidenceFactorSelected: {
    color: theme.palette.primary.main,
    background: theme.evPalette.huckleberry[100],
    fontWeight: 'bold',
    fontSize: '11px',
    '&:hover': {
      backgroundColor: theme.evPalette.huckleberry[100],
    },
    height: '18px',
    margin: `0 ${theme.evSpacing['ev-spacing--4']}`,
    paddingBottom: theme.evSpacing['ev-spacing--2'],
  },
  confidenceFactor: {
    fontWeight: 'bold',
    background: 'transparent',
    fontSize: '11px',
    height: '18px',
    margin: theme.evSpacing['ev-spacing--4'],
    paddingBottom: theme.evSpacing['ev-spacing--2'],
  },
  confidenceFactorLabel: {
    margin: `${theme.evSpacing['ev-spacing--4']} ${theme.evSpacing['ev-spacing--8']}`,
  },
  confidenceFactorIcon: {
    marginTop: `${theme.evSpacing['ev-spacing--4']}`,
    marginLeft: theme.evSpacing['ev-spacing--16'],
    height: '24px',
    paddingBottom: theme.evSpacing['ev-spacing--2'],
  },
  qcNotesContainer: {
    maxHeight: '520px',
    padding: theme.spacing(2),
    marginLeft: theme.spacing(1),
    overflow: 'auto',
  },
  qcNotesItemContainer: {
    boxShadow: '0 0 5px',
    padding: theme.spacing(1),
    borderRadius: '4px',
    width: '500px',
    marginBottom: '16px',
  },
  qcNotesHeading: {
    marginLeft: theme.spacing(1),
  },
  qcNotesUserAndDate: {
    fontSize: '14px',
    color: theme.evPalette.nickel[700],
    fontWeight: '400',
    display: 'flex',
  },
  userAndDateSeparator: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  qcNoteCreationDate: {
    fontSize: '18px',
  },
  qcNoteStatus: {
    color: theme.evPalette.charcoal[900],
    fontWeight: '700',
    fontSize: '14px',
  },
  qcNoteReason: {
    color: theme.evPalette.charcoal[900],
    fontWeight: '400',
    fontSize: '14px',
  },
  qcNoteReasonList: {
    color: theme.evPalette.charcoal[900],
    fontWeight: '400',
    fontSize: '14px',
    paddingLeft: theme.spacing(2),
  },
  qcNoteText: {
    margin: '12px 0',
    overflowWrap: 'break-word',
    color: theme.evPalette.charcoal[900],
    fontWeight: '400',
    fontSize: '14px',
  },
  qcNotesStatusBarButton: {
    minWidth: '160px',
  },
}));

export default useStyles;
