import React from 'react';
import { EVNoteIcon, EVTooltip, EVTypography } from '@eagleview/ev-comp-library';
import { useTranslation } from 'react-i18next';
import { TOOLTIP_DELAY } from 'constants.js';
import useStyles from './ReportGenerator.styles';

const ReportGeneratorTitle = () => {
  const { t } = useTranslation();
  const styles = useStyles();
  return (
    <EVTooltip title={t('report.title')} enterDelay={TOOLTIP_DELAY}>
      <div className={styles.titleContainer}>
        <EVNoteIcon fontSize="small" className={styles.noteIcon} />
        <EVTypography variant="h4">{t('report.title')}</EVTypography>
      </div>
    </EVTooltip>
  );
};

export default ReportGeneratorTitle;
