import { bool, func, node } from 'prop-types';
import React from 'react';

const HomeListItemWrapper = (props) => {
  const {
    children, disabled, onClick, ...rest
  } = props;
  // eslint-disable-next-line
  return <div role="link" onClick={!disabled ? onClick : () => {}} {...rest}>{children}</div>; 
};

HomeListItemWrapper.propTypes = {
  disabled: bool.isRequired,
  children: node.isRequired,
  onClick: func.isRequired,
};

export default HomeListItemWrapper;
