import { makeStyles } from '@eagleview/ev-comp-library';

export default makeStyles((theme) => ({
  toolbarButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2),
  },
  toolbarButtonBottom: {
    position: 'absolute',
    right: theme.spacing(2),
    bottom: theme.spacing(4),
  },
  toolbarPanel: {
    border: '0px solid #ccc',
    borderRadius: '4px',
    backgroundColor: theme.palette.common.white,
  },
  toolbarAnnotation: {
    position: 'absolute',
    right: theme.spacing(2),
    width: '50px',
    top: theme.spacing(9),
  },
  deleteBoxAnnotation: {
    marginTop: theme.spacing(0.25),
    marginLeft: theme.spacing(0.25),
    paddingLeft: theme.spacing(0.5),
  },
  createBoxAnnotation: {
    marginLeft: theme.spacing(0.25),
  },
  multiStructureToolBar: {
    position: 'absolute',
    top: theme.spacing(8),
  },
  multiStructureMenu: {
    position: 'absolute',
    top: theme.spacing(8),
  },
}));
