const prefix = 'adjusterReducer';

export const FETCH_ADJUSTER_DETAILS = `${prefix}/FETCH_ADJUSTER_DETAILS`;
export const FETCH_ADJUSTER_DETAILS_SUCCESS = `${prefix}/FETCH_ADJUSTER_DETAILS_SUCCESS`;
export const FETCH_ADJUSTER_DETAILS_FAILURE = `${prefix}/FETCH_ADJUSTER_DETAILS_FAILURE`;

export const FETCH_ADJUSTER_DETAILS_GALLERY = `${prefix}/FETCH_ADJUSTER_DETAILS_GALLERY`;
export const FETCH_ADJUSTER_DETAILS_GALLERY_SUCCESS = `${prefix}/FETCH_ADJUSTER_DETAILS_GALLERY_SUCCESS`;
export const FETCH_ADJUSTER_DETAILS_GALLERY_FAILURE = `${prefix}/FETCH_ADJUSTER_DETAILS_GALLERY_FAILURE`;

export const FETCH_GALLERY_IMAGE_METADATA = `${prefix}/FETCH_GALLERY_IMAGE_METADATA`;
export const FETCH_GALLERY_IMAGE_METADATA_SUCCESS = `${prefix}/FETCH_GALLERY_IMAGE_METADATA_SUCCESS`;
export const FETCH_GALLERY_IMAGE_METADATA_FAILURE = `${prefix}/FETCH_GALLERY_IMAGE_METADATA_FAILURE`;

export const FETCH_FACET_IMAGE_METADATA = `${prefix}/FETCH_FACET_IMAGE_METADATA`;
export const FETCH_SOFT_METAL_IMAGE_METADATA_SUCCESS = `${prefix}/FETCH_SOFT_METAL_IMAGE_METADATA_SUCCESS`;
export const FETCH_ANOMALY_IMAGE_METADATA_SUCCESS = `${prefix}/FETCH_ANOMALY_IMAGE_METADATA_SUCCESS`;
export const FETCH_FACET_IMAGE_METADATA_FAILURE = `${prefix}/FETCH_FACET_IMAGE_METADATA_FAILURE`;

export const FETCH_ADJUSTER_DETAILS_TAGS = `${prefix}/FETCH_ADJUSTER_DETAILS_TAGS`;
export const FETCH_ADJUSTER_DETAILS_TAGS_SUCCESS = `${prefix}/FETCH_ADJUSTER_DETAILS_TAGS_SUCCESS`;
export const FETCH_ADJUSTER_DETAILS_TAGS_FAILURE = `${prefix}/FETCH_ADJUSTER_DETAILS_TAGS_FAILURE`;

export const FETCH_ADJUSTER_DETAILS_NOTES = `${prefix}/FETCH_ADJUSTER_DETAILS_NOTES`;
export const FETCH_ADJUSTER_DETAILS_NOTES_SUCCESS = `${prefix}/FETCH_ADJUSTER_DETAILS_NOTES_SUCCESS`;
export const FETCH_ADJUSTER_DETAILS_NOTES_FAILURE = `${prefix}/FETCH_ADJUSTER_DETAILS_NOTES_FAILURE`;

export const SAVE_ROTATION_FOR_GALLERY_IMAGES = `${prefix}/SAVE_ROTATION_FOR_GALLERY_IMAGES`;
export const SAVE_ROTATION_FOR_GALLERY_IMAGES_SUCCESS = `${prefix}/SAVE_ROTATION_FOR_GALLERY_IMAGES_SUCCESS`;
export const SAVE_ROTATION_FOR_GALLERY_IMAGES_FAILURE = `${prefix}/SAVE_ROTATION_FOR_GALLERY_IMAGES_FAILURE`;

export const SET_APPLIED_GALLERY_IMAGE_FILTERS = `${prefix}/SET_APPLIED_GALLERY_IMAGE_FILTERS`;

export const SAVE_ADJUSTER_DETAILS_TAGS = `${prefix}/SAVE_ADJUSTER_DETAILS_TAGS`;
export const SAVE_ADJUSTER_DETAILS_TAGS_SUCCESS = `${prefix}/SAVE_ADJUSTER_DETAILS_TAGS_SUCCESS`;
export const SAVE_ADJUSTER_DETAILS_TAGS_FAILURE = `${prefix}/SAVE_ADJUSTER_DETAILS_TAGS_FAILURE`;

export const DELETE_ADJUSTER_DETAILS_TAGS = `${prefix}/DELETE_ADJUSTER_DETAILS_TAGS`;
export const DELETE_ADJUSTER_DETAILS_TAGS_SUCCESS = `${prefix}/DELETE_ADJUSTER_DETAILS_TAGS_SUCCESS`;
export const DELETE_ADJUSTER_DETAILS_TAGS_FAILURE = `${prefix}/DELETE_ADJUSTER_DETAILS_TAGS_FAILURE`;

export const SAVE_ADJUSTER_DETAILS_NOTES = `${prefix}/SAVE_ADJUSTER_DETAILS_NOTES`;
export const SAVE_ADJUSTER_DETAILS_NOTES_SUCCESS = `${prefix}/SAVE_ADJUSTER_DETAILS_NOTES_SUCCESS`;
export const SAVE_ADJUSTER_DETAILS_NOTES_FAILURE = `${prefix}/SAVE_ADJUSTER_DETAILS_NOTES_FAILURE`;

export const UPDATE_ADJUSTER_DETAILS_NOTES = `${prefix}/UPDATE_ADJUSTER_DETAILS_NOTES`;
export const UPDATE_ADJUSTER_DETAILS_NOTES_SUCCESS = `${prefix}/UPDATE_ADJUSTER_DETAILS_NOTES_SUCCESS`;
export const UPDATE_ADJUSTER_DETAILS_NOTES_FAILURE = `${prefix}/UPDATE_ADJUSTER_DETAILS_NOTES_FAILURE`;

export const DELETE_ADJUSTER_DETAILS_NOTES = `${prefix}/DELETE_ADJUSTER_DETAILS_NOTES`;
export const DELETE_ADJUSTER_DETAILS_NOTES_SUCCESS = `${prefix}/DELETE_ADJUSTER_DETAILS_NOTES_SUCCESS`;
export const DELETE_ADJUSTER_DETAILS_NOTES_FAILURE = `${prefix}/DELETE_ADJUSTER_DETAILS_NOTES_FAILURE`;

export const FETCH_FACET_NOTES = `${prefix}/FETCH_FACET_NOTES`;
export const FETCH_FACET_NOTES_SUCCESS = `${prefix}/FETCH_FACET_NOTES_SUCCESS`;
export const FETCH_FACET_NOTES_FAILURE = `${prefix}/FETCH_FACET_NOTES_FAILURE`;

export const SAVE_FACET_NOTES = `${prefix}/SAVE_FACET_NOTES`;
export const SAVE_FACET_NOTES_SUCCESS = `${prefix}/SAVE_FACET_NOTES_SUCCESS`;
export const SAVE_FACET_NOTES_FAILURE = `${prefix}/SAVE_FACET_NOTES_FAILURE`;

export const UPDATE_FACET_NOTES = `${prefix}/UPDATE_FACET_NOTES`;
export const UPDATE_FACET_NOTES_SUCCESS = `${prefix}/UPDATE_FACET_NOTES_SUCCESS`;
export const UPDATE_FACET_NOTES_FAILURE = `${prefix}/UPDATE_FACET_NOTES_FAILURE`;

export const DELETE_FACET_NOTES = `${prefix}/DELETE_FACET_NOTES`;
export const DELETE_FACET_NOTES_SUCCESS = `${prefix}/DELETE_FACET_NOTES_SUCCESS`;
export const DELETE_FACET_NOTES_FAILURE = `${prefix}/DELETE_FACET_NOTES_FAILURE`;

export const SAVE_FACET_TEST_SQUARE_NOTES = `${prefix}/SAVE_FACET_TEST_SQUARE_NOTES`;
export const SAVE_FACET_TEST_SQUARE_NOTES_SUCCESS = `${prefix}/SAVE_FACET_TEST_SQUARE_NOTES_SUCCESS`;
export const SAVE_FACET_TEST_SQUARE_NOTES_FAILURE = `${prefix}/SAVE_FACET_TEST_SQUARE_NOTES_FAILURE`;

export const UPDATE_FACET_TEST_SQUARE_NOTES = `${prefix}/UPDATE_FACET_TEST_SQUARE_NOTES`;
export const UPDATE_FACET_TEST_SQUARE_NOTES_SUCCESS = `${prefix}/UPDATE_FACET_TEST_SQUARE_NOTES_SUCCESS`;
export const UPDATE_FACET_TEST_SQUARE_NOTES_FAILURE = `${prefix}/UPDATE_FACET_TEST_SQUARE_NOTES_FAILURE`;

export const FETCH_FACET_TEST_SQUARE_NOTES = `${prefix}/FETCH_FACET_TEST_SQUARE_NOTES`;
export const FETCH_FACET_TEST_SQUARE_NOTES_SUCCESS = `${prefix}/FETCH_FACET_TEST_SQUARE_NOTES_SUCCESS`;
export const FETCH_FACET_TEST_SQUARE_NOTES_FAILURE = `${prefix}/FETCH_FACET_TEST_SQUARE_NOTES_FAILURE`;

export const DELETE_FACET_TEST_SQUARE_NOTES = `${prefix}/DELETE_FACET_TEST_SQUARE_NOTES`;
export const DELETE_FACET_TEST_SQUARE_NOTES_SUCCESS = `${prefix}/DELETE_FACET_TEST_SQUARE_NOTES_SUCCESS`;
export const DELETE_FACET_TEST_SQUARE_NOTES_FAILURE = `${prefix}/DELETE_FACET_TEST_SQUARE_NOTES_FAILURE`;

export const FETCH_ROOF_FACETS = `${prefix}/FETCH_ROOF_FACETS `;
export const FETCH_ROOF_FACETS_SUCCESS = `${prefix}/FETCH_ROOF_FACETS_SUCCESS`;
export const CLEAR_BASE_IMAGE_AND_FACETS = `${prefix}/CLEAR_BASE_IMAGE_AND_FACETS`;
export const FETCH_ROOF_FACETS_FAILURE = `${prefix}/FETCH_ROOF_FACETS_FAILURE`;
export const FETCH_ROOF_FACETS_AND_BASE_IMAGE = `${prefix}/FETCH_ROOF_FACETS_AND_BASE_IMAGE `;

export const FETCH_FACET_BASE_IMAGE = `${prefix}/FETCH_FACET_BASE_IMAGE `;
export const FETCH_FACET_BASE_IMAGE_SUCCESS = `${prefix}/FETCH_FACET_BASE_IMAGE_SUCCESS`;
export const FETCH_FACET_BASE_IMAGE_FAILURE = `${prefix}/FETCH_FACET_BASE_IMAGE_FAILURE`;

export const SET_SELECTED_FACET = `${prefix}/SET_SELECTED_FACET`;

export const SAVE_FACET_REVIEW_DECISION = `${prefix}/SAVE_FACET_REVIEW_DECISION`;
export const SAVE_FACET_REVIEW_DECISION_SUCCESS = `${prefix}/SAVE_FACET_REVIEW_DECISION_SUCCESS`;
export const SAVE_FACET_REVIEW_DECISION_FAILURE = `${prefix}/SAVE_FACET_REVIEW_DECISION_FAILURE`;

export const POST_REPORT = `${prefix}/POST_REPORT`;
export const POST_REPORT_SUCCESS = `${prefix}/POST_REPORT_SUCCESS`;
export const POST_REPORT_FAILURE = `${prefix}/FETCH_CURRENT_CLAIMS_FAILURE`;
export const POLL_REPORT = `${prefix}/POLL_REPORT`;
export const POLL_REPORT_SUCCESS = `${prefix}/POLL_REPORT_SUCCESS`;
export const POLL_REPORT_FAILURE = `${prefix}/POLL_REPORT_FAILURE`;
export const SET_POLL_START_TIME = `${prefix}/SET_POLL_START_TIME`;
export const UNSET_POLL_START_TIME = `${prefix}/UNSET_POLL_START_TIME`;

export const SHOW_REPORT_USING_REPORT_ID = `${prefix}/SHOW_REPORT`;
export const SHOW_REPORT_FAILURE = `${prefix}/SHOW_REPORT_FAILURE`;
export const SHOW_REPORT = `${prefix}/SHOW_REPORT_USING_REPORT_ID`;

export const SET_SELECTED_IMAGE = `${prefix}/SET_SELECTED_IMAGE`;
export const SET_CURRENT_TAB = `${prefix}/SET_CURRENT_TAB`;
export const SET_CURRENT_NOTES_TAB = `${prefix}/SET_CURRENT_NOTES_TAB`;

export const CLEAR_ERRORS = `${prefix}/CLEAR_ERRORS`;
export const CLOSE_ERROR_TOAST = `${prefix}/CLOSE_ERROR_TOAST`;
export const SHOW_ERROR_TOAST = `${prefix}/SHOW_ERROR_TOAST`;

export const INCREMENT_GENERATE_REPORT_DEPENDENTS = `${prefix}/INCREMENT_GENERATE_REPORT_DEPENDENTS`;
export const DECREMENT_GENERATE_REPORT_DEPENDENTS = `${prefix}/DECREMENT_GENERATE_REPORT_DEPENDENTS`;

export const FETCH_IW_IMAGE_METADATA = `${prefix}/FETCH_IW_IMAGE_METADATA`;
export const FETCH_IW_IMAGE_METADATA_SUCCESS = `${prefix}/FETCH_IW_IMAGE_METADATA_SUCCESS`;
export const FETCH_IW_IMAGE_METADATA_FAILURE = `${prefix}/FETCH_IW_IMAGE_METADATA_FAILURE`;

export const FETCH_FACET_IMAGES = `${prefix}/FETCH_FACET_IMAGES`;
export const FETCH_FACET_METADATA = `${prefix}/FETCH_FACET_METADATA`;
export const FETCH_FACET_IMAGES_SUCCESS = `${prefix}/FETCH_FACET_IMAGES_SUCCESS`;
export const FETCH_FACET_IMAGES_FAILURE = `${prefix}/FETCH_FACET_IMAGES_FAILURE`;

export const ADD_USER_ANOMALY = `${prefix}/ADD_USER_ANOMALY`;
export const ADD_USER_ANOMALY_SUCCESS = `${prefix}/ADD_USER_ANOMALY_SUCCESS`;
export const ADD_USER_ANOMALY_FAILURE = `${prefix}/ADD_USER_ANOMALY_FAILURE`;

export const DELETE_USER_ANOMALY = `${prefix}/DELETE_USER_ANOMALY`;
export const DELETE_USER_ANOMALY_SUCCESS = `${prefix}/DELETE_USER_ANOMALY_SUCCESS`;
export const DELETE_USER_ANOMALY_FAILURE = `${prefix}/DELETE_USER_ANOMALY_FAILURE`;

export const UPDATE_USER_DEFINED_ANOMALY_LOCATION = `${prefix}/UPDATE_USER_DEFINED_ANOMALY_LOCATION`;
export const UPDATE_USER_DEFINED_ANOMALY_LOCATION_SUCCESS = `${prefix}/UPDATE_USER_DEFINED_ANOMALY_LOCATION_SUCCESS`;
export const UPDATE_USER_DEFINED_ANOMALY_LOCATION_FAILURE = `${prefix}/UPDATE_USER_DEFINED_ANOMALY_LOCATION_FAILURE`;

export const FETCH_RISK_SHOT = `${prefix}/FETCH_RISK_SHOT`;
export const FETCH_RISK_SHOT_SUCCESS = `${prefix}/FETCH_RISK_SHOT_SUCCESS`;
export const FETCH_RISK_SHOT_FAILURE = `${prefix}/FETCH_RISK_SHOT_FAILURE`;

export const SET_ORDER_ID = `${prefix}/SET_ORDER_ID`;

export const SET_REPORT_ORDER_ID = `${prefix}/SET_REPORT_ORDER_ID`;

export const SAVE_FACET_ANNOTATION = `${prefix}/SAVE_FACET_ANNOTATION`;
export const SAVE_FACET_ANNOTATION_SUCCESS = `${prefix}/SAVE_FACET_ANNOTATION_SUCCESS`;
export const SAVE_FACET_ANNOTATION_FAILURE = `${prefix}/SAVE_FACET_ANNOTATION_FAILURE`;

export const UPDATE_FACET_ANNOTATION = `${prefix}/UPDATE_FACET_ANNOTATION`;
export const UPDATE_FACET_ANNOTATION_SUCCESS = `${prefix}/UPDATE_FACET_ANNOTATION_SUCCESS`;
export const UPDATE_FACET_ANNOTATION_FAILURE = `${prefix}/UPDATE_FACET_ANNOTATION_FAILURE`;

export const UPDATE_FACET_ANNOTATION_INCL_IN_REPORT = `${prefix}/UPDATE_FACET_ANNOTATION_INCL_IN_REPORT`;
export const UPDATE_FACET_ANNOTATION_INCL_IN_REPORT_SUCCESS = `${prefix}/UPDATE_FACET_ANNOTATION_INCL_IN_REPORT_SUCCESS`;
export const UPDATE_FACET_ANNOTATION_INCL_IN_REPORT_FAILURE = `${prefix}/UPDATE_FACET_ANNOTATION_INCL_IN_REPORT_FAILURE`;

export const UPDATE_GALLERY_ANNOTATION_INCL_IN_REPORT = `${prefix}/UPDATE_GALLERY_ANNOTATION_INCL_IN_REPORT`;
export const UPDATE_GALLERY_ANNOTATION_INCL_IN_REPORT_SUCCESS = `${prefix}/UPDATE_GALLERY_ANNOTATION_INCL_IN_REPORT_SUCCESS`;
export const UPDATE_GALLERY_ANNOTATION_INCL_IN_REPORT_FAILURE = `${prefix}/UPDATE_GALLERY_ANNOTATION_INCL_IN_REPORT_FAILURE`;

export const DELETE_FACET_ANNOTATION = `${prefix}/DELETE_FACET_ANNOTATION`;
export const DELETE_FACET_ANNOTATION_SUCCESS = `${prefix}/DELETE_FACET_ANNOTATION_SUCCESS`;
export const DELETE_FACET_ANNOTATION_FAILURE = `${prefix}/DELETE_FACET_ANNOTATION_FAILURE`;

export const GET_ANOMALY_NOTES = `${prefix}/GET_ANOMALY_NOTES`;
export const GET_ANOMALY_NOTES_SUCCESS = `${prefix}/GET_ANOMALY_NOTES_SUCCESS`;
export const GET_ANOMALY_NOTES_FAILURE = `${prefix}/GET_ANOMALY_NOTES_FAILURE`;

export const ADD_ANOMALY_NOTE = `${prefix}/ADD_ANOMALY_NOTE`;
export const ADD_ANOMALY_NOTE_SUCCESS = `${prefix}/ADD_ANOMALY_NOTE_SUCCESS`;
export const ADD_ANOMALY_NOTE_FAILURE = `${prefix}/ADD_ANOMALY_NOTE_FAILURE`;

export const UPDATE_ANOMALY_NOTE = `${prefix}/UPDATE_ANOMALY_NOTE`;
export const UPDATE_ANOMALY_NOTE_SUCCESS = `${prefix}/UPDATE_ANOMALY_NOTE_SUCCESS`;
export const UPDATE_ANOMALY_NOTE_FAILURE = `${prefix}/UPDATE_ANOMALY_NOTE_FAILURE`;

export const UPDATE_DATE_OF_LOSS = `${prefix}/UPDATE_DATE_OF_LOSS`;
export const UPDATE_DATE_OF_LOSS_SUCCESS = `${prefix}/UPDATE_DATE_OF_LOSS_SUCCESS`;
export const UPDATE_DATE_OF_LOSS_FAILURE = `${prefix}/UPDATE_DATE_OF_LOSS_FAILURE`;

export const INCLUDE_ANOMALY_NOTE = `${prefix}/INCLUDE_ANOMALY_NOTE`;
export const INCLUDE_ANOMALY_NOTE_SUCCESS = `${prefix}/INCLUDE_ANOMALY_NOTE_SUCCESS`;
export const INCLUDE_ANOMALY_NOTE_FAILURE = `${prefix}/INCLUDE_ANOMALY_NOTE_FAILURE`;

export const CLEAR_ANNOTATION_TO_EDIT = `${prefix}/CLEAR_ANNOTATION_TO_EDIT`;
export const DISPLAY_FACET_ANNOTATIONS = `${prefix}/DISPLAY_FACET_ANNOTATIONS`;
export const DISPLAY_FACET_ANNOTATIONS_SUCCESS = `${prefix}/DISPLAY_FACET_ANNOTATIONS_SUCCESS`;
export const DISPLAY_FACET_ANNOTATIONS_FAILURE = `${prefix}/DISPLAY_FACET_ANNOTATIONS_FAILURE`;
export const SET_FACET_BOX_ANNOTATIONS = 'SET_FACET_BOX_ANNOTATIONS';

/* confidence factor */
export const SAVE_CONFIDENCE_FACTOR = `${prefix}/SAVE_CONFIDENCE_FACTOR`;
export const SAVE_CONFIDENCE_FACTOR_SUCCESS = `${prefix}/SAVE_CONFIDENCE_FACTOR_SUCCESS`;
export const SAVE_CONFIDENCE_FACTOR_FAILURE = `${prefix}/SAVE_CONFIDENCE_FACTOR_FAILURE`;

/* workflow panel */
export const SET_WORKFLOW_ANOMALY_ID = `${prefix}/SET_WORKFLOW_ANOMALY_ID`;
export const SET_SELECTED_ANOMALY_VIEW = `${prefix}/SET_SELECTED_ANOMALY_VIEW`;

export const SET_ANOMALY_DECISION = `${prefix}/SET_ANOMALY_DECISION`;
export const SAVE_ANOMALY_DECISION = `${prefix}/SAVE_ANOMALY_DECISION`;
export const SAVE_ANOMALY_DECISION_SUCCESS = `${prefix}/SAVE_ANOMALY_DECISION_SUCCESS`;
export const SAVE_ANOMALY_DECISION_FAILURE = `${prefix}/SAVE_ANOMALY_DECISION_FAILURE`;

export const SET_ROOF_DECISION = `${prefix}/SET_ROOF_DECISION`;
export const SAVE_ROOF_DECISION = `${prefix}/SAVE_ROOF_DECISION`;
export const SAVE_ROOF_DECISION_SUCCESS = `${prefix}/SAVE_ROOF_DECISION_SUCCESS`;
export const SAVE_ROOF_DECISION_FAILURE = `${prefix}/SAVE_ROOF_DECISION_FAILURE`;

export const FETCH_GALLERY_IMAGES = `${prefix}/FETCH_RGALLERY_IMAGES`;
export const FETCH_GALLERY_IMAGES_SUCCESS = `${prefix}/FETCH_GALLERY_IMAGES_SUCCESS`;
export const FETCH_GALLERY_IMAGES_FAILURE = `${prefix}/FETCH_GALLERY_IMAGES_FAILURE`;

export const FETCH_ANOMALY_METADATA = `${prefix}/FETCH_ANOMALY_METADATA`;
export const FETCH_ANOMALY_METADATA_SUCCESS = `${prefix}/FETCH_ANOMALY_METADATA_SUCCESS`;
export const FETCH_ANOMALY_METADATA_FAILURE = `${prefix}/FETCH_ANOMALY_METADATA_FAILURE`;

export const SET_FACET_REPORT_INCLUSION = `${prefix}/SET_FACET_REPORT_INCLUSION`;
export const SAVE_FACET_REPORT_INCLUSION = `${prefix}/SAVE_FACET_REPORT_INCLUSION`;
export const SAVE_FACET_REPORT_INCLUSION_SUCCESS = `${prefix}/SAVE_FACET_REPORT_INCLUSION_SUCCESS`;
export const SAVE_FACET_REPORT_INCLUSION_FAILURE = `${prefix}/SAVE_FACET_REPORT_INCLUSION_FAILURE`;

export const FETCH_REPORT_INCLUDED_IMAGES = `${prefix}/FETCH_REPORT_INCLUDED_IMAGES`;
export const FETCH_REPORT_INCLUDED_IMAGES_SUCCESS = `${prefix}/FETCH_REPORT_INCLUDED_IMAGES_SUCCESS`;
export const FETCH_REPORT_INCLUDED_IMAGES_FAILURE = `${prefix}/FETCH_REPORT_INCLUDED_IMAGES_FAILURE`;

export const SAVE_IMAGE_REPORT_INCLUSION = `${prefix}/SAVE_IMAGE_REPORT_INCLUSION`;
export const SAVE_IMAGE_REPORT_INCLUSION_SUCCESS = `${prefix}/SAVE_IMAGE_REPORT_INCLUSION_SUCCESS`;
export const SAVE_IMAGE_REPORT_INCLUSION_FAILURE = `${prefix}/SAVE_IMAGE_REPORT_INCLUSION_FAILURE`;

export const DELETE_IMAGE_REPORT_INCLUSION = `${prefix}/DELETE_IMAGE_REPORT_INCLUSION`;
export const DELETE_IMAGE_REPORT_INCLUSION_SUCCESS = `${prefix}/DELETE_IMAGE_REPORT_INCLUSION_SUCCESS`;
export const DELETE_IMAGE_REPORT_INCLUSION_FAILURE = `${prefix}/DELETE_IMAGE_REPORT_INCLUSION_FAILURE`;

export const FETCH_ROOF_NOTE = `${prefix}/FETCH_ROOF_NOTE`;
export const FETCH_ROOF_NOTE_SUCCESS = `${prefix}/FETCH_ROOF_NOTE_SUCCESS`;
export const FETCH_ROOF_NOTE_FAILURE = `${prefix}/FETCH_ROOF_NOTE_FAILURE`;

export const SAVE_ROOF_NOTE = `${prefix}/SAVE_ROOF_NOTE`;
export const SAVE_ROOF_NOTE_SUCCESS = `${prefix}/SAVE_ROOF_NOTE_SUCCESS`;
export const SAVE_ROOF_NOTE_FAILURE = `${prefix}/SAVE_ROOF_NOTE_FAILURE`;

export const UPDATE_ROOF_NOTE = `${prefix}/UPDATE_ROOF_NOTE`;
export const UPDATE_ROOF_NOTE_SUCCESS = `${prefix}/UPDATE_ROOF_NOTE_SUCCESS`;
export const UPDATE_ROOF_NOTE_FAILURE = `${prefix}/UPDATE_ROOF_NOTE_FAILURE`;

export const DELETE_ROOF_NOTE = `${prefix}/DELETE_ROOF_NOTE`;
export const DELETE_ROOF_NOTE_SUCCESS = `${prefix}/DELETE_ROOF_NOTE_SUCCESS`;
export const DELETE_ROOF_NOTE_FAILURE = `${prefix}/DELETE_ROOF_NOTE_FAILURE`;

export const SAVE_IMAGE_NOTE_REPORT_INCLUSION = `${prefix}/SAVE_IMAGE_NOTE_REPORT_INCLUSION`;
export const SAVE_IMAGE_NOTE_REPORT_INCLUSION_SUCCESS = `${prefix}/SAVE_IMAGE_NOTE_REPORT_INCLUSION_SUCCESS`;
export const SAVE_IMAGE_NOTE_REPORT_INCLUSION_FAILURE = `${prefix}/SAVE_IMAGE_NOTE_REPORT_INCLUSION_FAILURE`;

export const SAVE_FACET_NOTE_REPORT_INCLUSION = `${prefix}/SAVE_FACET_NOTE_REPORT_INCLUSION`;
export const SAVE_FACET_NOTE_REPORT_INCLUSION_SUCCESS = `${prefix}/SAVE_FACET_NOTE_REPORT_INCLUSION_SUCCESS`;
export const SAVE_FACET_NOTE_REPORT_INCLUSION_FAILURE = `${prefix}/SAVE_FACET_NOTE_REPORT_INCLUSION_FAILURE`;

export const SAVE_FACET_TEST_SQUARE_NOTE_REPORT_INCLUSION = `${prefix}/SAVE_FACET_TEST_SQUARE_NOTE_REPORT_INCLUSION`;
export const SAVE_FACET_TEST_SQUARE_NOTE_REPORT_INCLUSION_SUCCESS = `${prefix}/SAVE_FACET_TEST_SQUARE_NOTE_REPORT_INCLUSION_SUCCESS`;
export const SAVE_FACET_TEST_SQUARE_NOTE_REPORT_INCLUSION_FAILURE = `${prefix}/SAVE_FACET_TEST_SQUARE_NOTE_REPORT_INCLUSION_FAILURE`;

export const FETCH_SOFT_METAL_IMAGES = `${prefix}/FETCH_SOFT_METAL_IMAGES`;
export const FETCH_SOFT_METAL_IMAGES_SUCCESS = `${prefix}/FETCH_SOFT_METAL_IMAGES_SUCCESS`;
export const FETCH_SOFT_METAL_IMAGES_FAILURE = `${prefix}/FETCH_SOFT_METAL_IMAGES_FAILURE`;

export const DISPLAY_GALLERY_ANNOTATIONS = `${prefix}/DISPLAY_GALLERY_ANNOTATIONS`;
export const DISPLAY_GALLERY_ANNOTATIONS_SUCCESS = `${prefix}/DISPLAY_GALLERY_ANNOTATIONS_SUCCESS`;
export const DISPLAY_GALLERY_ANNOTATIONS_FAILURE = `${prefix}/DISPLAY_GALLERY_ANNOTATIONS_FAILURE`;

export const SAVE_GALLERY_ANNOTATIONS = `${prefix}/SAVE_GALLERY_ANNOTATIONS`;
export const SAVE_GALLERY_ANNOTATIONS_SUCCESS = `${prefix}/SAVE_GALLERY_ANNOTATIONS_SUCCESS`;
export const SAVE_GALLERY_ANNOTATIONS_FAILURE = `${prefix}/SAVE_GALLERY_ANNOTATIONS_FAILURE`;

export const UPDATE_GALLERY_ANNOTATIONS = `${prefix}/UPDATE_GALLERY_ANNOTATIONS`;
export const UPDATE_GALLERY_ANNOTATIONS_SUCCESS = `${prefix}/UPDATE_GALLERY_ANNOTATIONS_SUCCESS`;
export const UPDATE_GALLERY_ANNOTATIONS_FAILURE = `${prefix}/UPDATE_GALLERY_ANNOTATIONS_FAILURE`;

export const DELETE_GALLERY_ANNOTATIONS = `${prefix}/DELETE_GALLERY_ANNOTATIONS`;
export const DELETE_GALLERY_ANNOTATIONS_SUCCESS = `${prefix}/DELETE_GALLERY_ANNOTATIONS_SUCCESS`;
export const DELETE_GALLERY_ANNOTATIONS_FAILURE = `${prefix}/DELETE_GALLERY_ANNOTATIONS_FAILURE`;

export const TEST_SQUARE_STATUS_UPDATE = `${prefix}/TEST_SQUARE_STATUS_UPDATE`;
export const TEST_SQUARE_STATUS_UPDATE_SUCCESS = `${prefix}/TEST_SQUARE_STATUS_UPDATE_SUCCESS`;
export const TEST_SQUARE_STATUS_UPDATE_FAILURE = `${prefix}/TEST_SQUARE_STATUS_UPDATE_FAILURE`;

export const TEST_SQUARE_LOCATION_UPDATE = `${prefix}/TEST_SQUARE_LOCATION_UPDATE`;
export const TEST_SQUARE_LOCATION_UPDATE_SUCCESS = `${prefix}/TEST_SQUARE_LOCATION_UPDATE_SUCCESS`;
export const TEST_SQUARE_LOCATION_UPDATE_FAILURE = `${prefix}/TEST_SQUARE_LOCATION_UPDATE_FAILURE`;

export const FETCH_FEATURE_PREFERENCE = `${prefix}/FETCH_FEATURE_PREFERENCE`;
export const FETCH_FEATURE_PREFERENCE_SUCCESS = `${prefix}/FETCH_FEATURE_PREFERENCE_SUCCESS`;
export const FETCH_FEATURE_PREFERENCE_FAILURE = `${prefix}/FETCH_FEATURE_PREFERENCE_FAILURE`;

export const SET_CURRENT_VIEW = `${prefix}/SET_CURRENT_VIEW`;

export const SET_FACET_NOTE_REPORT_INCLUSION = `${prefix}/SET_FACET_NOTE_REPORT_INCLUSION`;
export const SET_GALLERY_IMAGE_NOTE_REPORT_INCLUSION = `${prefix}/SET_GALLERY_IMAGE_NOTE_REPORT_INCLUSION`;
export const SET_TEST_SQUARE_NOTE_REPORT_INCLUSION = `${prefix}/SET_TEST_SQUARE_NOTE_REPORT_INCLUSION`;

export const RESET_ADJUSTER = `${prefix}/RESET_ADJUSTER`;

export const SET_USER = `${prefix}/SET_USER`;

export const SET_SIDE_PANEL_WIDTH = `${prefix}/SET_SIDE_PANEL_WIDTH`;

export const COMPLETE_REVIEW = `${prefix}/COMPLETE_REVIEW`;
export const COMPLETE_REVIEW_FAILURE = `${prefix}/COMPLETE_REVIEW_FAILURE`;

export const FETCH_ASSESS_APP_SETTINGS = `${prefix}/FETCH_ASSESS_APP_SETTINGS`;
export const FETCH_ASSESS_APP_SETTINGS_SUCCESS = `${prefix}/FETCH_ASSESS_APP_SETTINGS_SUCCESS`;
export const FETCH_ASSESS_APP_SETTINGS_FAILURE = `${prefix}/FETCH_ASSESS_APP_SETTINGS_FAILURE`;

// Adding Qc Panel
export const TOGGLE_ANOMALY_INCLUDE_IN_REPORT = `${prefix}/TOGGLE_ANOMALY_INCLUDE_IN_REPORT`;
export const TOGGLE_ANOMALY_INCLUDE_IN_REPORT_SUCCESS = `${prefix}/TOGGLE_ANOMALY_INCLUDE_IN_REPORT_SUCCESS`;
export const TOGGLE_ANOMALY_INCLUDE_IN_REPORT_FAILURE = `${prefix}/TOGGLE_ANOMALY_INCLUDE_IN_REPORT_FAILURE`;

export const FETCH_REPORT_VERSIONS = `${prefix}/FETCH_REPORT_VERSIONS`;
export const FETCH_REPORT_VERSIONS_SUCCESS = `${prefix}/FETCH_REPORT_VERSIONS_SUCCESS`;
export const FETCH_REPORT_VERSIONS_FAILURE = `${prefix}/FETCH_REPORT_VERSIONS_FAILURE`;

// Adding Qc Panel
export const QC_PANEL_APPROVE = `${prefix}/QC_PANEL_APPROVE`;
export const QC_PANEL_REJECT = `${prefix}/QC_PANEL_REJECT`;
export const QC_PANEL_RETIEPOINT = `${prefix}/QC_PANEL_RETIEPOINT`;
export const QC_OPS_CHECKOUT_BY_REPORT_ID = `${prefix}/QC_OPS_CHECKOUT_BY_REPORT_ID`;
export const QC_OPS_CHECKOUT_BY_REPORT_ID_SUCCESS = `${prefix}/QC_OPS_CHECKOUT_BY_REPORT_ID_SUCCESS`;
export const QC_OPS_CHECKOUT_BY_REPORT_ID_FAILURE = `${prefix}/QC_OPS_CHECKOUT_BY_REPORT_ID_FAILURE`;
export const QC_OPS_CHECKIN_SIMPLE_FAILURE = `${prefix}/QC_OPS_CHECKIN_SIMPLE_FAILURE`;
export const QC_OPS_REJECT_SIMPLE_NOTES_FAILURE = `${prefix}/QC_OPS_REJECT_SIMPLE_NOTES_FAILURE`;
export const QC_OPS_ASSIGN_SELF = `${prefix}/QC_OPS_ASSIGN_SELF`;
export const QC_PANEL_RESCHEDULE = `${prefix}/QC_PANEL_RESCHEDULE`;
export const FETCH_QC_PANEL_DETAILS = `${prefix}/FETCH_QC_PANEL_DETAILS`;
export const FETCH_QC_PANEL_DETAILS_SUCCESS = `${prefix}/FETCH_QC_PANEL_DETAILS_SUCCESS`;
export const QC_APPROVE_FAILURE = `${prefix}/QC_APPROVE_FAILURE`;
export const QC_REJECT_FAILURE = `${prefix}/QC_REJECT_FAILURE`;
export const QC_RETIEPOINT_FAILURE = `${prefix}/QC_RETIEPOINT_FAILURE`;
export const QC_RESCHEDULE_FAILURE = `${prefix}/QC_RESCHEDULE_FAILURE`;
export const QC_APPROVED_SUCCESS = `${prefix}/QC_APPROVED_SUCCESS`;
export const QC_REJECT_SUCCESS = `${prefix}/QC_REJECT_SUCCESS`;
export const QC_RETIEPOINT_SUCCESS = `${prefix}/QC_RETIEPOINT_SUCCESS`;
export const QC_RESCHEDULE_SUCCESS = `${prefix}/QC_RESCHEDULE_SUCCESS`;
export const GET_QC_DETAILS_FAILED = `${prefix}/GET_QC_DETAILS_FAILED`;
export const HANDLE_QC_NOTE_CHANGE = `${prefix}/HANDLE_QC_NOTE_CHANGE`;
export const HANDLE_QC_REASON_CHANGE = `${prefix}/HANDLE_QC_REASON_CHANGE`;
export const HANDLE_QC_REASONS_CHANGE = `${prefix}/HANDLE_QC_REASONS_CHANGE`;
export const HANDLE_QC_REASONS_EMPTY_REQUEST = `${prefix}/HANDLE_QC_REASONS_EMPTY_REQUEST`;
export const DOWNLOAD_SKYDIO_MOSAIC_IMAGE = `${prefix}/DOWNLOAD_SKYDIO_MOSAIC_IMAGE`;
export const DOWNLOAD_SKYDIO_MOSAIC_IMAGE_SUCCESS = `${prefix}/DOWNLOAD_SKYDIO_MOSAIC_IMAGE_SUCCESS`;
export const DOWNLOAD_SKYDIO_MOSAIC_IMAGE_FAILURE = `${prefix}/DOWNLOAD_SKYDIO_MOSAIC_IMAGE_FAILURE`;
export const SET_SHOW_SKYDIO_MOSAIC_IMAGE_PANEL = `${prefix}/SET_SHOW_SKYDIO_MOSAIC_IMAGE_PANEL`;
export const REJECT_QC_STRUCTURE_API = `${prefix}/REJECT_QC_STRUCTURE_API`;
export const REJECT_QC_STRUCTURE_SAGA_SUCCESS_ACTION = `${prefix}/REJECT_QC_STRUCTURE_SAGA_SUCCESS_ACTION`;
export const REJECT_QC_STRUCTURE_SAGA_FAILURE_ACTION = `${prefix}/REJECT_QC_STRUCTURE_SAGA_FAILURE_ACTION`;
export const UNDO_QC_STRUCTURE_REJECTION_API = `${prefix}/UNDO_QC_STRUCTURE_REJECTION_API`;
export const UNDO_QC_STRUCTURE_REJECTION_SAGA_SUCCESS_ACTION = `${prefix}/UNDO_QC_STRUCTURE_REJECTION_SAGA_SUCCESS_ACTION`;
export const UNDO_QC_STRUCTURE_REJECTION_SAGA_FAILURE_ACTION = `${prefix}/UNDO_QC_STRUCTURE_REJECTION_SAGA_FAILURE_ACTION`;
export const CLEAR_SKYDIO_MOSAIC_IMAGE_URL = `${prefix}/CLEAR_SKYDIO_MOSAIC_IMAGE_URL`;

// export imagery
export const EXPORT_ALL_IMAGES = `${prefix}/EXPORT_ALL_IMAGES`;
export const EXPORT_ALL_IMAGES_FAILURE = `${prefix}/EXPORT_ALL_IMAGES_FAILURE`;

export const EXPORT_REPORT_INCLUDED_IMAGES = `${prefix}/EXPORT_REPORT_INCLUDED_IMAGES`;
export const EXPORT_REPORT_INCLUDED_IMAGES_FAILURE = `${prefix}/EXPORT_REPORT_INCLUDED_IMAGES_FAILURE`;

export const SET_EXPORT_IMAGERY_POLL_START_TIME = `${prefix}/SET_EXPORT_IMAGERY_POLL_START_TIME`;
export const UNSET_EXPORT_IMAGERY_POLL_START_TIME = `${prefix}/UNSET_EXPORT_IMAGERY_POLL_START_TIME`;

export const POLL_EXPORT_IMAGES = `${prefix}/POLL_EXPORT_IMAGES`;
export const POLL_EXPORT_IMAGES_SUCCESS = `${prefix}/POLL_EXPORT_IMAGES_SUCCESS`;
export const POLL_EXPORT_IMAGES_FAILURE = `${prefix}/POLL_EXPORT_IMAGES_FAILURE`;

export const DOWNLOAD_EXPORTED_IMAGES = `${prefix}/DOWNLOAD_EXPORTED_IMAGES`;
export const DOWNLOAD_EXPORTED_IMAGES_SUCCESS = `${prefix}/DOWNLOAD_EXPORTED_IMAGES_SUCCESS`;
export const DOWNLOAD_EXPORTED_IMAGES_FAILURE = `${prefix}/DOWNLOAD_EXPORTED_IMAGES_FAILURE`;

export const EXPORT_IMAGERY = `${prefix}/EXPORT_IMAGERY`;

// workflow map navigation */
export const ENABLE_WORKFLOW_NAVIGATION = `${prefix}/ENABLE_WORKFLOW_NAVIGATION`;
export const DISABLE_WORKFLOW_NAVIGATION = `${prefix}/DISABLE_WORKFLOW_NAVIGATION`;
export const SHOW_WORKFLOW_FACET_POLYGON = `${prefix}/SHOW_WORKFLOW_FACET_POLYGON`;
export const HIDE_WORKFLOW_FACET_POLYGON = `${prefix}/HIDE_WORKFLOW_FACET_POLYGON`;
export const REPLACE_FACET_METADATA = `${prefix}/REPLACE_FACET_METADATA`;
export const SET_WORKFLOW_BASEIMAGE_PARENT = `${prefix}/SET_WORKFLOW_BASEIMAGE_PARENT`;
export const SET_WORKFLOW_VIEW_BASE_IMG = `${prefix}/SET_WORKFLOW_VIEW_BASE_IMG`;

/* accessories */
export const FETCH_ACCESSORIES_MASTER_LIST = `${prefix}/FETCH_ACCESSORIES_MASTER_LIST`;
export const FETCH_ACCESSORIES_MASTER_LIST_SUCCESS = `${prefix}/FETCH_ACCESSORIES_MASTER_LIST_SUCCESS`;
export const FETCH_ACCESSORIES_MASTER_LIST_FAILURE = `${prefix}/FETCH_ACCESSORIES_MASTER_LIST_FAILURE`;
export const FETCH_ACCESSORIES_SUMMARY = `${prefix}/FETCH_ACCESSORIES_SUMMARY`;
export const FETCH_ACCESSORIES_SUMMARY_SUCCESS = `${prefix}/FETCH_ACCESSORIES_SUMMARY_SUCCESS`;
export const FETCH_ACCESSORIES_SUMMARY_FAILURE = `${prefix}/FETCH_ACCESSORIES_SUMMARY_FAILURE`;

/* multi structure */
export const SET_SELECTED_STRUCTURE_ID = `${prefix}/SET_SELECTED_STRUCTURE_ID`;
export const FETCH_MULTISTRUCTURE_DECISION = `${prefix}/FETCH_MULTISTRUCTURE_DECISION`;
export const FETCH_MULTISTRUCTURE_DECISION_SUCCESS = `${prefix}/FETCH_MULTISTRUCTURE_DECISION_SUCCESS`;
export const FETCH_MULTISTRUCTURE_DECISION_FAILURE = `${prefix}/FETCH_MULTISTRUCTURE_DECISION_FAILURE`;
export const UPDATE_STRUCTURE_VISIT_STATUS = `${prefix}/UPDATE_STRUCTURE_VISIT_STATUS`;
export const UPDATE_STRUCTURE_VISIT_STATUS_SUCCESS = `${prefix}/UPDATE_STRUCTURE_VISIT_STATUS_SUCCESS`;
export const UPDATE_STRUCTURE_VISIT_STATUS_FAILURE = `${prefix}/UPDATE_STRUCTURE_VISIT_STATUS_FAILURE`;

export const FETCH_ANOMALY_IMAGES = `${prefix}/FETCH_ANOMALY_IMAGES`;
export const FETCH_ANOMALY_IMAGES_SUCCESS_ACTION = `${prefix}/FETCH_ANOMALY_IMAGES_SUCCESS_ACTION`;
export const FETCH_ANOMALY_IMAGES_FAILURE_ACTION = `${prefix}/FETCH_ANOMALY_IMAGES_FAILURE_ACTION`;

export const SAVE_QC_NOTE = `${prefix}/SAVE_QC_NOTE`;
export const SAVE_QC_NOTE_SUCCESS = `${prefix}/SAVE_QC_NOTE_SUCCESS`;
export const SAVE_QC_NOTE_FAILURE = `${prefix}/SAVE_QC_NOTE_FAILURE`;

export const REPORT_ATTEMPTS = `${prefix}/REPORT_ATTEMPTS`;
export const REPORT_ATTEMPTS_SUCCESS = `${prefix}/REPORT_ATTEMPTS_SUCCESS`;
export const REPORT_ATTEMPTS_FAILURE = `${prefix}/REPORT_ATTEMPTS_FAILURE`;

export const FETCH_REPORT_INCLUDED_SOFT_METAL_IMAGES = `${prefix}/FETCH_REPORT_INCLUDED_SOFT_METAL_IMAGES`;
export const FETCH_REPORT_INCLUDED_SOFT_METAL_IMAGES_SUCCESS = `${prefix}/FETCH_REPORT_INCLUDED_SOFT_METAL_IMAGES_SUCCESS`;
export const FETCH_REPORT_INCLUDED_SOFT_METAL_IMAGES_FAILURE = `${prefix}/FETCH_REPORT_INCLUDED_SOFT_METAL_IMAGES_FAILURE`;

export const SAVE_SOFT_METAL_IMAGE_REPORT_INCLUSION = `${prefix}/SAVE_SOFT_METAL_IMAGE_REPORT_INCLUSION`;
export const SAVE_SOFT_METAL_IMAGE_REPORT_INCLUSION_SUCCESS = `${prefix}/SAVE_SOFT_METAL_IMAGE_REPORT_INCLUSION_SUCCESS`;
export const SAVE_SOFT_METAL_IMAGE_REPORT_INCLUSION_FAILURE = `${prefix}/SAVE_SOFT_METAL_IMAGE_REPORT_INCLUSION_FAILURE`;

export const DELETE_SOFT_METAL_IMAGE_REPORT_INCLUSION = `${prefix}/DELETE_SOFT_METAL_IMAGE_REPORT_INCLUSION`;
export const DELETE_SOFT_METAL_IMAGE_REPORT_INCLUSION_SUCCESS = `${prefix}/DELETE_SOFT_METAL_IMAGE_REPORT_INCLUSION_SUCCESS`;
export const DELETE_SOFT_METAL_IMAGE_REPORT_INCLUSION_FAILURE = `${prefix}/DELETE_SOFT_METAL_IMAGE_REPORT_INCLUSION_FAILURE`;

export const FETCH_REPORT_INCLUDED_SKYDIO_IMAGES = `${prefix}/FETCH_REPORT_INCLUDED_SKYDIO_IMAGES`;
export const FETCH_REPORT_INCLUDED_SKYDIO_IMAGES_SUCCESS = `${prefix}/FETCH_REPORT_INCLUDED_SKYDIO_IMAGES_SUCCESS`;
export const FETCH_REPORT_INCLUDED_SKYDIO_IMAGES_FAILURE = `${prefix}/FETCH_REPORT_INCLUDED_SKYDIO_IMAGES_FAILURE`;

export const SAVE_SKYDIO_IMAGE_REPORT_INCLUSION = `${prefix}/SAVE_SKYDIO_IMAGE_REPORT_INCLUSION`;
export const SAVE_SKYDIO_IMAGE_REPORT_INCLUSION_SUCCESS = `${prefix}/SAVE_SKYDIO_IMAGE_REPORT_INCLUSION_SUCCESS`;
export const SAVE_SKYDIO_IMAGE_REPORT_INCLUSION_FAILURE = `${prefix}/SAVE_SKYDIO_IMAGE_REPORT_INCLUSION_FAILURE`;

export const DELETE_SKYDIO_IMAGE_REPORT_INCLUSION = `${prefix}/DELETE_SKYDIO_IMAGE_REPORT_INCLUSION`;
export const DELETE_SKYDIO_IMAGE_REPORT_INCLUSION_SUCCESS = `${prefix}/DELETE_SKYDIO_IMAGE_REPORT_INCLUSION_SUCCESS`;
export const DELETE_SKYDIO_IMAGE_REPORT_INCLUSION_FAILURE = `${prefix}/DELETE_SKYDIO_IMAGE_REPORT_INCLUSION_FAILURE`;

export const FETCH_PRODUCT_COST = `${prefix}/FETCH_PRODUCT_COST`;
export const FETCH_PRODUCT_COST_SUCCESS = `${prefix}/FETCH_PRODUCT_COST_SUCCESS`;
export const FETCH_PRODUCT_COST_FAILURE = `${prefix}/FETCH_PRODUCT_COST_FAILURE`;
export const CLEAR_REPORT_STATUS = `${prefix}/CLEAR_REPORT_STATUS`;

export const GENERATE_ROOF_REPORT = `${prefix}/GENERATE_ROOF_REPORT`;
export const GENERATE_ROOF_REPORT_SUCCESS = `${prefix}/GENERATE_ROOF_REPORT_SUCCESS`;
export const GENERATE_ROOF_REPORT_FAILURE = `${prefix}/GENERATE_ROOF_REPORT_FAILURE`;

export const REGENERATE_REPORT = `${prefix}/REGENERATE_REPORT`;
export const REGENERATE_REPORT_SUCCESS = `${prefix}/REGENERATE_REPORT_SUCCESS`;
export const REGENERATE_REPORT_FAILURE = `${prefix}/REGENERATE_REPORT_FAILURE`;

export const SET_FACET_SCAN_IMAGE_ASSETS = `${prefix}/SET_FACET_SCAN_IMAGE_ASSETS`;

export const FETCH_FACET_IMAGE_ANOMALIES = `${prefix}/FETCH_FACET_IMAGE_ANOMALIES`;
export const FETCH_FACET_IMAGE_ANOMALIES_SUCCESS = `${prefix}/FETCH_FACET_IMAGE_ANOMALIES_SUCCESS`;
export const FETCH_FACET_IMAGE_ANOMALIES_FAILURE = `${prefix}/FETCH_FACET_IMAGE_ANOMALIES_FAILURE`;

export const FETCH_SHARED_USERS_LIST = `${prefix}/FETCH_SHARED_USERS_LIST`;
export const FETCH_SHARED_USERS_LIST_SUCCESS = `${prefix}/FETCH_SHARED_USERS_LIST_SUCCESS`;
export const FETCH_SHARED_USERS_LIST_FAILURE = `${prefix}/FETCH_SHARED_USERS_LIST_FAILURE`;

export const CREATE_SHARE_LINK = `${prefix}/CREATE_SHARE_LINK`;
export const CREATE_SHARE_LINK_SUCCESS = `${prefix}/CREATE_SHARE_LINK_SUCCESS`;
export const CREATE_SHARE_LINK_FAILURE = `${prefix}/CREATE_SHARE_LINK_FAILURE`;

export const SET_RAW_IMAGE_SELECTED_ANOMALY_ID = `${prefix}/SET_RAW_IMAGE_SELECTED_ANOMALY_ID`;

export const DISABLE_GENERATE_REPORT = `${prefix}/DISABLE_GENERATE_REPORT`;

export const FETCH_ACCESS_TOKEN = `${prefix}/FETCH_ACCESS_TOKEN`;

export const UPDATE_ACCESS_TOKEN = `${prefix}/UPDATE_ACCESS_TOKEN`;

export const UPDATE_ACCESS_TOKEN_COMPLETED = `${prefix}/UPDATE_ACCESS_TOKEN_COMPLETED`;

export const SKYDIO_IMAGE_DOWNLOAD_ERROR = 'SKYDIO_IMAGE_DOWNLOAD_ERROR';

export const ANOMALY_DECISIONS = {
  CONFIRMED: 'CONFIRMED',
  UNCONFIRMED: 'UNCONFIRMED',
  REMOVED: 'REMOVED',
};

export const ROOF_DECISION = {
  UNDECIDED: 'Undecided',
  REPLACE: 'Replace',
  REPAIR: 'Repair',
  DENY: 'Deny',
};

export const CONFIRMABLE_DECISIONS = [ANOMALY_DECISIONS.UNCONFIRMED, ''];

export const WORKFLOW_PANEL = {
  HEIGHT: 164,
  WIDTH: 256,
};

export const FEATURE_PREFERENCES = {
  CONTROL_NUMBER_OF_ANOMALIES_SHOWN: 'CONTROL_NUMBER_OF_ANOMALIES_SHOWN',
};

export const BUCKET = {
  1: 0.2,
  2: 0.35,
  3: 0.45,
  4: 0.6,
  5: 0.8,
};

export const UNSET_ANOMALY_ID = '-1';
export const UNSET_DELETE_INDEX = -1;
export const DEFAULT_MAX_ZOOM = 40;
export const DEFAULT_MIN_ZOOM = 21.1;
export const ROOF_MAX_ZOOM = 35;
export const ROOF_MAX_ZOOM_SHOW_ANOMALIES = 35;
export const MIN_IMAGE_ROW_INDEX = 0;
export const MAX_IMAGE_ROW_INDEX = 3;
export const MAX_IMAGES_BY_ROW = 4;
export const TESTSQUARE_ZOOM_LEVEL_ON_SELECTION = 23.5;

export const REPORT_STATUS_PAYMENT_FAILED = 'PaymentFailed';
export const REPORT_STATUS_IN_PROCESS = 'InProcess';
export const REPORT_STATUS_ERROR = 'Error';
export const REPORT_STATUS_COMPLETED = 'Completed';

export const REGENERATE_REPORT_STATUS_INPROCESS = 'regeneratereportstart';
export const REGENERATE_REPORT_STATUS_SUCCESS = 'regeneratereportcompleted';
export const REGENERATE_REPORT_STATUS_FAILED = 'regeneratereportfailed';
export const REGENERATE_REPORT_STATUS_FAILED_RETRY = 'regenerateeportfailedretry';

export const LEGACY_STATUS_COMPLETED = 'Completed';
export const LEGACY_STATUS_INPROCESS = 'InProcess';

export const QUICK_CAPTURE = 'quickCapture';
export const FULL_SCAN = 'fullScan';
export const ASSESS_MEASURE = 'assessMeasure';

export const KEY_CODE = {
  LEFT: 37,
  RIGHT: 39,
  UP: 38,
  DOWN: 40,
};

export const TEST_SQUARE_ERRORS = {
  62300: 'testSquareNotAvailable',
  62301: 'testSquareNotBigEnough',
};

export const CONFIDENCE_FACTOR_MAP_WIND = {
  Fewer: 'ANOMALY_WIND_LESS',
  Recommended: 'ANOMALY_WIND_RECOMMENDED',
  More: 'ANOMALY_WIND_MORE',
};

export const CONFIDENCE_FACTOR_MAP_HAIL = {
  Fewer: 'ANOMALY_HAIL_LESS',
  Recommended: 'ANOMALY_HAIL_RECOMMENDED',
  More: 'ANOMALY_HAIL_MORE',
};

export const CONFIDENCE_FACTOR_MAP_CREASE = {
  Fewer: 'ANOMALY_CREASE_LESS',
  Recommended: 'ANOMALY_CREASE_RECOMMENDED',
  More: 'ANOMALY_CREASE_MORE',
};

export const CONFIDENCE_FACTOR_MAPPING = {
  wind: CONFIDENCE_FACTOR_MAP_WIND,
  hail: CONFIDENCE_FACTOR_MAP_HAIL,
  crease: CONFIDENCE_FACTOR_MAP_CREASE,
};

export const ANOMALY_TYPE_MAP = {
  wind: 'ANOMALY_WIND_THRESHOLD',
  hail: 'ANOMALY_HAIL_THRESHOLD',
  crease: 'ANOMALY_CREASE_THRESHOLD',
};
export const REPORT_DATE_FORMAT = 'MMMM DD, YYYY, h:mma';

export const statusToTranslation = {
  ReadyForReview: 'adjusterSidePanel.status.readyForReview',
  Completed: 'adjusterSidePanel.status.completed',
  OrderClosed: 'adjusterSidePanel.status.orderClosed',
};

export const orderStatuses = {
  READYFORREVIEW: 'ReadyForReview',
};

export const purchaseStatusTranslation = {
  [REPORT_STATUS_IN_PROCESS]: 'adjuster.tab.processing',
  [REPORT_STATUS_ERROR]: 'adjuster.tab.notAvailable',
  [REPORT_STATUS_PAYMENT_FAILED]: 'adjuster.tab.notAvailable',
};

export const EXPORT_IMAGES = {
  allImages: 'exportAllImages',
  reportIncludedImages: 'exportReportIncludedImages',
};

// Generated report poll delay time in seconds
export const REPORT_POLL_DELAY_TIME = 2;

// Max time for polling report generation in minutes
export const MAX_REPORT_POLL_TIME = 5;
export const POLL_TIME_EXCEEDED = 'pollTimeExceeded';

// Export images poll delay time in seconds
export const EXPORT_IMAGES_POLL_DELAY_TIME = 2;

// Max time for polling exportable images in minutes
export const MAX_EXPORT_IMAGES_POLL_TIME = 5;

export const GALLERY_IMAGE_EXPLORE_OVERVIEW = 'EXPLORE_OVERVIEW';
export const GALLERY_IMAGE_EXPLORE_OBLIQUES = 'EXPLORE_OBLIQUES';

export const GALLERY_IMAGE_CATEGORIES = [
  'Risk',
  GALLERY_IMAGE_EXPLORE_OVERVIEW,
  GALLERY_IMAGE_EXPLORE_OBLIQUES,
  'Front Elevation',
  'Back Elevation',
  'Right Elevation',
  'Left Elevation',
  'Interior Images',
  'Additional Images',
];

export const GALLERY_IMAGE_CATEGORY_MANUAL = 'MANUAL';

export const ASSESS_LITE_ROOF_IMAGE_CATEGORIES = [
  GALLERY_IMAGE_EXPLORE_OVERVIEW,
  GALLERY_IMAGE_CATEGORY_MANUAL,
  GALLERY_IMAGE_EXPLORE_OBLIQUES,
];

export const FIX_HEIGHT = 538;

export const INPUTS = ['input', 'select', 'button', 'textarea'];

export const ROLE_MAP = {
  MANAGE_DECISIONS: 'adjuster',
  MANAGE_QC_DECISIONS: 'qc',
};

export const ROOF_REPORT = 'Roof Report';

export const ORDER_VISITED_PENDO_EVENT = 'ASSESS_ORDER_VISITED';
export const ORDER_COMPLETED_PENDO_EVENT = 'ASSESS_ORDER_COMPLETED';
export const ORDER_FACET_DECISION_PENDO_EVENT = 'ASSESS_FACET_DECISION';
export const ORDER_SUMMARY_REPORT_PENDO_EVENT = 'ASSESS_SUMMARY_REPORT';
export const ORDER_ROOF_DECISION_PENDO_EVENT = 'ASSESS_ROOF_DECISION';

export const MAP_FLIGHT_TYPE = {
  fullScan: 'claim.flightType.fullScan',
  quickCapture: 'claim.flightType.quickCapture',
  assessMeasure: 'claim.flightType.quickCapture',
};

export const MAP_FLIGHT_TYPE_WITH_MARKETING_PRODUCT_NAME = {
  fullScan: 'claim.marketing.productName.fullScan',
  quickCapture: 'claim.marketing.productName.quickCapture',
  assessMeasure: 'claim.marketing.productName.assessMeasure',
};

export const TABS = {
  DETAILS: 0,
  ROOF: 1,
  GALLERY: 2,
  DETAILS_LITE: 3,
  ROOF_LITE: 4,
  PHOTOS_GALLERY: 5,
};

export const REPORT_NOTE_MAX_CHAR_LENGTH = 2500;

export const dateOfLossStatusMsgMap = {
  [REGENERATE_REPORT_STATUS_INPROCESS]: 'adjuster.updateDateOfLossProgressMessage',
  [REGENERATE_REPORT_STATUS_FAILED_RETRY]: 'adjuster.updateDateOfLossProgressMessage',
  [REGENERATE_REPORT_STATUS_FAILED]: 'adjuster.updateDateOfLossFailedMessage',
};
