import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { get } from 'lodash';
import { EVThemeProvider } from '@eagleview/ev-comp-library';
import { saveFeatureFlagsAction } from 'layout/entitleUser/EntitleUser.actions';
import { ASSESS_IWH_ENTITLEMENTS } from 'layout/entitleUser/EntitleUser.constants';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { store } from 'store/ConfigureStore';
import adjusterDebug from 'layout/adjuster/Adjuster.debug';
import { LAUNCH_DARKLY_CLIENT_ID, USER_KEY } from './constants';
import interceptors from './interceptor';
import App from './App';
import './i18n';

window.assess = (elementId, props = {}) => ({
  mount: () => {
    const { featureFlag } = props;
    store.dispatch(saveFeatureFlagsAction(featureFlag));
    if (featureFlag && featureFlag.assessUiDebug) {
      window.assessUiDebug = { adjuster: adjusterDebug(store.dispatch) };
    }
    const IWHHeaderFeatureFlag = get(featureFlag, ASSESS_IWH_ENTITLEMENTS, false);
    interceptors.setupInterceptors({ [ASSESS_IWH_ENTITLEMENTS]: IWHHeaderFeatureFlag });
    const isSharedURL = window.location.origin === process.env.REACT_APP_ASSESS_SHAREDURL_APP_ORIGIN;
    if (isSharedURL) {
      (async () => {
        const LDProvider = await asyncWithLDProvider({
          clientSideID: LAUNCH_DARKLY_CLIENT_ID,
          context: {
            kind: 'user',
            key: USER_KEY,
          },
        });
        ReactDOM.render(
          <Suspense fallback={<div>loading...</div>}>
            <Provider store={store}>
              <EVThemeProvider classPrefix="assess">
                <LDProvider>
                  <App />
                </LDProvider>
              </EVThemeProvider>
            </Provider>
          </Suspense>,
          document.getElementById(elementId),
        );
      })();
    } else {
      ReactDOM.render(
        <Suspense fallback={<div>loading...</div>}>
          <Provider store={store}>
            <EVThemeProvider classPrefix="assess">
              <App />
            </EVThemeProvider>
          </Provider>
        </Suspense>,
        document.getElementById(elementId),
      );
    }
  },
  unmount: () => ReactDOM.unmountComponentAtNode(document.getElementById(elementId)),
});
