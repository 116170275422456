import {
  initialValue, CLEAR, ALL, POSITION, HIDE, SHOW,
} from './AnnotationDescPanel.constants';

export default (state, action) => {
  const { type, ...payload } = action;
  switch (type) {
    case ALL:
      return {
        ...state,
        ...payload,
      };
    case POSITION:
      return {
        ...state,
        top: payload.top,
        left: payload.left,
      };
    case SHOW:
      return {
        ...state,
        show: true,
      };
    case HIDE:
      return {
        ...state,
        show: false,
      };
    case CLEAR:
      return { ...initialValue };
    default:
      return state;
  }
};
