import React from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { EVThemeProvider } from '@eagleview/ev-comp-library';
import '@eagleview/ev-comp-library/dist/lib/main.css';
import { history } from './store/ConfigureStore';
import Container from './components/Container';
import Routes from './routes';

function App() {
  return (
    <EVThemeProvider>
      <ConnectedRouter history={history}>
        <Container>
          <Routes />
        </Container>
      </ConnectedRouter>
    </EVThemeProvider>
  );
}

export default App;
