import {
  CLOSE_ERROR_TOAST,
  FETCH_ORTHO_POLYGON,
  FETCH_ORTHO_POLYGON_SUCCESS,
  FETCH_ORTHO_IMAGE,
  FETCH_ORTHO_IMAGE_SUCCESS,
  SET_VIEW,
  FETCH_ORDER_DETAILS,
  FETCH_ORDER_DETAILS_SUCCESS,
  FETCH_ORDER_DETAILS_FAILURE,
  FETCH_IMAGES_AND_DETAILS_FAILURE,
  INITIAL_VIEW_ORTHO,
  ANNOTATION_TO_EDIT,
  UPDATE_VERTICES_POLYGONS,
} from './DroneToMeasure.constants';

const initialState = {
  loading: {},
  toastSeverity: 'error',
  showToast: undefined,
  view: INITIAL_VIEW_ORTHO,
  initialView: INITIAL_VIEW_ORTHO,
  vertices: [],
  orthoImage: [],
  polygon: [],
  vertexToEdit: {},
  vertexToEditCoords: [],
  polygonsToEdit: [],
};

const droneToMeasureReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_ORTHO_POLYGON:
    case FETCH_ORTHO_IMAGE:
    case FETCH_ORDER_DETAILS:
      return {
        ...state,
        loading: { ...state.loading, [type]: true },
      };
    case CLOSE_ERROR_TOAST:
      return {
        ...state,
        showToast: false,
        showToastPersistently: false,
        errorCode: undefined,
      };
    case FETCH_ORTHO_POLYGON_SUCCESS:
      return {
        ...state,
        vertices: payload.vertices,
        polygon: payload.polygon,
      };
    case FETCH_ORTHO_IMAGE_SUCCESS:
      return {
        ...state,
        orthoImage: [payload],
      };
    case FETCH_ORDER_DETAILS_SUCCESS:
      return {
        ...state,
        orderDetails: payload,
        loading: { ...state.loading, [FETCH_ORDER_DETAILS]: false },
      };
    case FETCH_ORDER_DETAILS_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, [FETCH_ORDER_DETAILS]: false },
        showToast: true,
        toastMessage: 'manualAtAdjustment.fetchOrderDetailsError',
        errorCode: payload.errorCode,
        toastSeverity: 'error',
      };
    case SET_VIEW:
      return {
        ...state,
        view: payload || state.view,
      };
    case FETCH_IMAGES_AND_DETAILS_FAILURE:
      return {
        ...state,
        showToast: true,
        toastMessage: 'manualAtAdjustment.fetchOrderDetailsError',
        errorCode: payload.errorCode,
        toastSeverity: 'error',
      };
    case ANNOTATION_TO_EDIT: {
      const vertex = JSON.stringify(payload.annotation.geometries[0].coordinates);
      return {
        ...state,
        vertexToEdit: payload.annotation,
        vertexToEditCoords: payload.annotation.geometries[0].coordinates.map((coord) => parseFloat(coord.toFixed(13))),
        polygonsToEdit: state.polygon[0].data.filter((poly) => {
          const isVertexInPolygon = poly.geometries[0].coordinates[0].some((coord) => vertex === JSON.stringify(coord));
          return isVertexInPolygon ? poly : false;
        }).map((polygon) => polygon.id),
      };
    }
    case UPDATE_VERTICES_POLYGONS: {
      const payloadCoord = payload.geometries[0].coordinates;
      return {
        ...state,
        vertices: [{
          ...state.vertices[0],
          data: state.vertices[0].data.map((v) => {
            if (v.id === payload.id) {
              const coord = payload.geometries[0].coordinates;
              return {
                ...v,
                geometries: [{
                  ...payload.geometries[0],
                  coordinates: [parseFloat(coord[0].toFixed(13)), parseFloat(coord[1].toFixed(13)), parseFloat(coord[2].toFixed(13))],
                }],
              };
            }
            return v;
          }),
        }],
        polygon: [{
          ...state.polygon[0],
          data: state.polygon[0].data.map((polygon) => {
            if (state.polygonsToEdit.includes(polygon.id)) {
              const updatedCoords = polygon.geometries[0].coordinates[0].map((coord) => {
                if (JSON.stringify(coord) === JSON.stringify(state.vertexToEditCoords)) {
                  return [parseFloat(payloadCoord[0].toFixed(13)), parseFloat(payloadCoord[1].toFixed(13)), parseFloat(payloadCoord[2].toFixed(13))];
                }
                return coord;
              });
              return { ...polygon, geometries: [{ ...polygon.geometries[0], coordinates: [updatedCoords] }] };
            }
            return polygon;
          }),
        }],
        vertexToEdit: {},
        polygonsToEdit: [],
      };
    }
    default:
      return state;
  }
};

export default droneToMeasureReducer;
