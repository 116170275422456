import { makeStyles } from '@eagleview/ev-comp-library';
import { HEAD_CELL_KEY } from '../QcHome.constants';

export default makeStyles((theme) => ({
  tableCell: {
    borderBottom: 'none',
    paddingTop: theme.spacing(1.5),
    paddingBottom: (P) => (P.headCellId === HEAD_CELL_KEY.facetCoverage ? theme.spacing(0) : theme.spacing(1.5)),
  },
}));
