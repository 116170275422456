/* eslint-disable react/jsx-props-no-spreading */
import React, {
  useEffect,
} from 'react';
import { Route } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ToastMessage from 'components/toast-message';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import {
  oneOfType, node, func, string,
} from 'prop-types';
import { isEntitled, isLinkAuthorized } from 'utils/auth.utils';
import { FEATURE_ENTITLEMENTS } from 'layout/entitleUser/EntitleUser.constants';
import * as action from 'layout/entitleUser/EntitleUser.actions';
import * as actionSl from 'layout/validateLink/ValidateLink.actions';
import EntitleUser from 'layout/entitleUser';
import { useTranslation } from 'react-i18next';
import PageNotFound from 'layout/pageNotFound/pageNotFound';
import { ASSESS_SHAREDURL_APP_ORIGIN } from './constants';
import {
  FETCH_VALIDATE_LINK,
} from './layout/validateLink/ValidateLink.constants';
import {
  FETCH_USER_ENTITLEMENTS,
} from './layout/entitleUser/EntitleUser.constants';

const SharedRoute = ({
  component: Component, name, ...rest
}) => {
  // selectors
  const entitlements = useSelector((state) => state.entitleUserReducer.entitlements);
  const tokenValidated = useSelector((state) => state.validateLinkReducer.tokenValidated);
  const validateCode = useSelector((state) => state.validateLinkReducer.validateCode);
  const validateCodefetching = useSelector((state) => state.validateLinkReducer.loading[FETCH_VALIDATE_LINK]);
  const entitlementsLoading = useSelector((state) => state.entitleUserReducer.loading[FETCH_USER_ENTITLEMENTS]);
  const path = window.location.pathname;
  const orderUuid = path.split('/');
  const { flags, ldClient } = rest;
  const isLinkShared = (window.location.origin === ASSESS_SHAREDURL_APP_ORIGIN) || false;

  // actions
  const dispatch = useDispatch();
  const fetchUserEntitlements = () => dispatch(action.fetchUserEntitlements());
  const fetchValidateLink = (payload) => dispatch(actionSl.fetchValidateLink(payload));
  const saveFeatureFlag = (payload) => dispatch(action.saveFeatureFlagsAction(payload));
  // checkToken,

  const isSharedLinkAuthorized = isLinkAuthorized();
  const { t } = useTranslation();

  const isEntitledViewOrders = isEntitled(entitlements, FEATURE_ENTITLEMENTS.VIEW_ASSESS_SELF_ORGANIZATION_ORDERS)
    || isEntitled(entitlements, FEATURE_ENTITLEMENTS.VIEW_ASSESS_ALL_ORGANIZATION_ORDERS)
    || isEntitled(entitlements, FEATURE_ENTITLEMENTS.VIEW_SELF_JOBS);

  useEffect(() => {
    if (ldClient) {
      saveFeatureFlag(flags);
    }
  });

  useEffect(() => {
    if (isLinkShared) {
      fetchValidateLink({ orderUuid: orderUuid[2] });
    }
  }, []);

  if (isLinkShared && tokenValidated && !entitlements) {
    fetchUserEntitlements();
  }

  const renderComponent = (props) => {
    if (!validateCodefetching && !entitlementsLoading) {
      if ((isLinkShared && isSharedLinkAuthorized && entitlements)) {
        if (!window.location.pathname.includes('/order-view') && name === 'order') {
          return null;
        }
        if (entitlements) {
          // eslint-disable-next-line no-nested-ternary
          return entitlements.length > 0 ? (
            isEntitledViewOrders
              ? <Component {...props} />
              : (
                <ToastMessage
                  alertMsg={`${t('adjuster.viewOrdersNotEntitled')}`}
                  severity="error"
                  open
                  onClose={() => {
                    // Empty Block
                  }}
                />
              )
          ) : <EntitleUser />;
        }
        return <PageNotFound validateCode={validateCode} />;
      }
      return <PageNotFound validateCode={validateCode} />;
    }
    return <span>{t('pageNotFound.loading')}</span>;
  };
  return <Route {...rest} render={(props) => renderComponent(props)} />;
};

SharedRoute.propTypes = {
  component: oneOfType([node, func]).isRequired,
  name: string.isRequired,
  path: string.isRequired,
};

export default withLDConsumer()(SharedRoute);
