import React, { useEffect, useRef, useState } from 'react';
import {
  EVTextField,
  EVChip,
  EVBox,
} from '@eagleview/ev-comp-library';
import {
  arrayOf, func, number, string,
} from 'prop-types';
import { useTranslation } from 'react-i18next';

const HELPERTEXT_DEFAULT_KEY = 'default';
const HELPERTEXT_INVALID_KEY = 'invalid';
const HELPERTEXT_ALREADY_ADDED_KEY = 'alreadyAdded';
const HELPERTEXT_LIMIT_REACHED_KEY = 'limitReached';

const MultiEmailInput = ({
  emailIds,
  onEmailsChange,
  placeHolder,
  styles,
  limit,
}) => {
  const { t } = useTranslation();
  const fieldRef = useRef(null);
  const [emails, setEmails] = useState(emailIds);
  const [inputValue, setInputValue] = useState('');
  const [helperTextKey, setHelperTextKey] = useState(HELPERTEXT_DEFAULT_KEY);

  const helperTexts = {
    [HELPERTEXT_DEFAULT_KEY]: t('multiEmailInput.addEmail'),
    [HELPERTEXT_INVALID_KEY]: t('multiEmailInput.invalidEmail'),
    [HELPERTEXT_ALREADY_ADDED_KEY]: t('multiEmailInput.duplicateEmail'),
    [HELPERTEXT_LIMIT_REACHED_KEY]: t('manualAtAdjustment.structureNumber').replace('$num$', limit),
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const handleAddEmail = (email) => {
    if (!validateEmail(email)) {
      setHelperTextKey(HELPERTEXT_INVALID_KEY);
    } else if (emails.includes(email)) {
      setHelperTextKey(HELPERTEXT_ALREADY_ADDED_KEY);
    } else if (emails.length >= limit) {
      setHelperTextKey(HELPERTEXT_LIMIT_REACHED_KEY);
    } else {
      const updatedEmails = [...emails, email];
      setEmails(updatedEmails);
      onEmailsChange(updatedEmails);
      setInputValue('');
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' || e.key === ',' || e.key === ' ') {
      e.preventDefault();
      handleAddEmail(inputValue.trim());
    }
  };

  const handleBlur = () => {
    if (inputValue.trim()) {
      handleAddEmail(inputValue.trim());
      setInputValue('');
    }
  };

  const handleDelete = (emailToDelete) => () => {
    const updatedEmails = emails.filter((email) => email !== emailToDelete);
    setEmails(updatedEmails);
    onEmailsChange(updatedEmails);
  };

  const handdleInputValueChange = (value) => {
    setInputValue(value);
    setHelperTextKey(HELPERTEXT_DEFAULT_KEY);
  };

  useEffect(() => {
    if (fieldRef.current) {
      fieldRef.current.scrollTop = fieldRef.current.scrollHeight;
    }
  }, [inputValue]);

  useEffect(() => {
    if (!emailIds.length) {
      setEmails([]);
    }
  }, [emailIds]);

  return (
    <>
      <EVTextField
        ref={fieldRef}
        className={styles.emailTextField}
        placeholder={placeHolder}
        value={inputValue}
        onChange={(e) => handdleInputValueChange(e.target.value)}
        onKeyDown={handleKeyDown}
        onBlur={handleBlur}
        InputProps={{
          startAdornment: emails.map((email) => (
            <EVChip
              className={styles.emailChips}
              size="small"
              key={email}
              label={email}
              onDelete={handleDelete(email)}
            />
          )),
          className: styles.emailTextFieldInput,
          classes: {
            root: styles.emailTextFieldRoot,
          },
          disableUnderline: true,
        }}
      />
      <EVBox
        display="flex"
        alignItems="baseline"
        justifyContent="space-between"
        mt={0.25}
        pr={1}
        height="32px"
        width="100%"
        color={helperTextKey !== HELPERTEXT_DEFAULT_KEY ? 'red' : ''}
      >
        <span
          className={styles.helperLabel}
        >
          {helperTexts[helperTextKey]}
          .
        </span>
      </EVBox>
    </>
  );
};

MultiEmailInput.defaultProps = {
  emailIds: [],
  placeHolder: 'Enter email',
  styles: {},
  limit: 5,
};

MultiEmailInput.propTypes = {
  emailIds: arrayOf(string),
  placeHolder: string,
  onEmailsChange: func.isRequired,
  styles: {},
  limit: number,
};

export default MultiEmailInput;
