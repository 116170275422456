import { makeStyles } from '@eagleview/ev-comp-library';

export default makeStyles((theme) => ({
  thumbnailGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat( auto-fit, minmax(80px, 80px) )',
    gap: '5px',
    padding: '10px',
  },
  thumbnailItem: {
    boxSizing: 'borderBox',
  },
  thumbnailItemImg: {
    width: '80px',
    height: '80px',
    display: 'block',
    objectFit: 'cover',
    borderRadius: theme.spacing(1),
  },
  thumbnailItemSelected: {
    boxSizing: 'borderBox',
    border: `${theme.spacing(0.5)}px solid ${theme.palette.primary.main}`,
    borderRadius: theme.spacing(1.4),
    margin: `-${theme.spacing(0.5)}px`,
    width: '88px',
  },
}));
