import React, { useState, useEffect } from 'react';
import { isEmpty, get } from 'lodash';
import {
  EVInformationPanel,
  EVDivider,
  EVDialog,
  EVDialogContent,
  EVDialogContentText,
  EVDialogActions,
  EVButton,
  EVTypography,
  EVBox,
  EVTooltip,
} from '@eagleview/ev-comp-library';
import { useDispatch, useSelector } from 'react-redux';
import { string, shape, func } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { FEATURE_ENTITLEMENTS, QC_ENHANCEMENT } from 'layout/entitleUser/EntitleUser.constants';
import { isEntitled } from 'utils/auth.utils';
import { ASSESS_BASE_PATH, REACT_APP_ENV } from 'constants';
import QcPendingPanelTitle from './QcPendingPanelTitle';
import useStyles from './QcPendingPanel.styles';
import { QC_DECISIONS, QC_CONFIRMATION_DIALOG_MSG } from './QCPendingPanel.constants';
import * as action from '../Adjuster.actions';
import { getQCStartElapsedTime, getQCDateAndTime, prepareQCTime } from './QCPending.utils';
import QcNotes from './qc-notes/QcNotes';
import QcTable from './qc-table/QcTable';
import QcStructureReject from './qc-structure-reject/QcStructureReject';
import { SKYDIO_IMAGE_DOWNLOAD_ERROR } from '../Adjuster.constants';

const QcPendingPanel = (props) => {
  const {
    chartData, qcPanelType, coveragePercentage, orderId, QcClaimID, qcStartTime, qcEndTime, setFacetWithInitialAnomaly,
  } = props;

  const infoPanelWidth = 220;
  const headerInputProps = {
    tooltipPlacement: 'top-start',
  };

  // utility hooks
  const { t } = useTranslation();
  const classes = useStyles(infoPanelWidth);

  // dispatch actions
  const dispatch = useDispatch();
  const approve = (payload) => dispatch(action.postQcPanelApproveAction(payload));
  // const reject = (payload) => dispatch(action.postQcPanelRejectAction(payload));
  const reschedule = (payload) => dispatch(action.postQcPanelRescheduleAction(payload));
  const retiepoint = (payload) => dispatch(action.postQcPanelRetiepointAction(payload));
  const escalate = (payload) => dispatch(action.postQcPanelRejectAction(payload));
  const checkoutByReportId = (payload) => dispatch(action.qcOpsCheckoutByReportId(payload));
  const downloadSkydioMosaicImage = (payload) => dispatch(action.downloadSkydioMosaicImage(payload));
  const setShowSkydioMosaicImagePanel = (payload) => dispatch(action.setShowSkydioMosaicImagePanel(payload));

  // Selector with derived value
  const entitlements = useSelector((state) => state.entitleUserReducer.entitlements);
  const taskStateIdForOpsPrime = useSelector((state) => state.adjusterReducer.qcOpsPrimeReport.ID);
  const hideQcPanel = useSelector((state) => state.adjusterReducer.hideQcPanel);
  const userID = useSelector((state) => state.entitleUserReducer.userID);
  const qcNotes = useSelector((state) => state.adjusterReducer.qcNotes);
  const qcReason = useSelector((state) => state.adjusterReducer.qcReason);
  const qcReasons = useSelector((state) => state.adjusterReducer.qcReasons);
  const isQcD2m = useSelector((state) => state.adjusterReducer.isQcD2m);
  const showSkydioMosaicImagePanel = useSelector((state) => state.adjusterReducer.showSkydioMosaicImagePanel);
  const skydioMosaicPresent = useSelector((state) => state.adjusterReducer.skydioMosaicPresent);
  const skydioMosaicImageUrl = useSelector((state) => state.adjusterReducer.skydioMosaicImageUrl);
  const selectedStructureId = useSelector((state) => state.adjusterReducer.selectedStructureId);
  const enableQcEnhancement = useSelector((state) => get(state.entitleUserReducer.featureFlags, QC_ENHANCEMENT, false));
  const multiStructure = useSelector((state) => state.adjusterReducer.multiStructure);

  // local state
  const [qcDecisionDialogOpen, setQcDecisionDialogOpen] = useState(false);
  const [qcDecision, setQcDecision] = useState(qcPanelType);

  const isOpsUser = isEntitled(entitlements, FEATURE_ENTITLEMENTS.MANAGE_PENDING_QC_VIA_OPS_PRIME);
  const entitledToManageManualAtViaOpsPrime = isEntitled(entitlements, FEATURE_ENTITLEMENTS.MANAGE_MANUAL_AT_VIA_OPS_PRIME);
  const entitledToManageManualAtEscalationViaOpsPrime = isEntitled(entitlements, FEATURE_ENTITLEMENTS.MANAGE_MANUAL_AT_ESCALATION_VIA_OPS_PRIME);
  const shouldBeSelfAssigned = entitledToManageManualAtViaOpsPrime || entitledToManageManualAtEscalationViaOpsPrime;

  // on mount when qc is pending for an ops user
  useEffect(() => {
    if (isOpsUser) {
      checkoutByReportId(orderId);
    }
  }, []);

  const handleApprove = () => approve({
    orderId,
    ID: QcClaimID,
    action: 'APPROVE',
    isOpsUser,
    taskStateIdForOpsPrime,
    userID,
    isQcD2m,
  });

  // const handleReject = () => reject({ orderId, ID: QcClaimID, action: 'REJECT', isOpsUser });

  const handleReschedule = () => reschedule({
    orderId,
    ID: QcClaimID,
    action: 'RESCHEDULE',
    reason: qcReason,
    note: qcNotes.get(orderId),
    userID,
    isQcD2m,
  });

  const handleRetiepoint = () => retiepoint({
    orderId,
    ID: QcClaimID,
    action: 'RETIEPOINT',
    isOpsUser,
    taskStateIdForOpsPrime,
    rejectReasonId: 44,
    reason: qcReason,
    note: qcNotes.get(orderId),
    userID,
    isQcD2m,
  });

  const handleEscalate = () => escalate({
    orderId,
    ID: QcClaimID,
    action: 'REJECT',
    isOpsUser,
    taskStateIdForOpsPrime,
    rejectReasonId: 89,
    assignPreferredUser: false,
    reason: qcReason,
    note: qcNotes.get(orderId),
    userID,
    isQcD2m,
  });

  const handleEscalateNew = () => escalate({
    orderId,
    ID: QcClaimID,
    action: 'REJECT',
    isOpsUser,
    taskStateIdForOpsPrime,
    rejectReasonId: 89,
    assignPreferredUser: false,
    reason: qcReasons.join(),
    note: qcNotes.get(orderId),
    userID,
    isQcD2m,
  });

  const handleEscalateTechnical = () => escalate({
    orderId,
    ID: QcClaimID,
    action: 'ESCALATE_TECHNICAL',
    isOpsUser,
    taskStateIdForOpsPrime,
    rejectReasonId: 89,
    assignPreferredUser: false,
    reason: qcReasons.join(),
    note: qcNotes.get(orderId),
    userID,
    isQcD2m,
  });

  const handleDroneIconClick = () => {
    if (!skydioMosaicImageUrl) {
      downloadSkydioMosaicImage({ orderId, structureId: selectedStructureId });
    }
    setShowSkydioMosaicImagePanel(true);
  };

  const handleQcDecision = (decision) => {
    setQcDecision(decision);
    setQcDecisionDialogOpen(true);
  };

  const handleQcDecisionConfirm = () => {
    setQcDecisionDialogOpen(false);
    if (qcDecision !== QC_DECISIONS.PENDING) {
      if (qcDecision === QC_DECISIONS.APPROVE) {
        handleApprove();
      } else if (qcDecision === QC_DECISIONS.REJECT && enableQcEnhancement === true) {
        handleEscalateNew();
      } else if (qcDecision === QC_DECISIONS.REJECT && enableQcEnhancement !== true) {
        handleEscalate();
      } else if (qcDecision === QC_DECISIONS.RESCHEDULE) {
        handleReschedule();
      } else if (qcDecision === QC_DECISIONS.RETIEPOINT) {
        handleRetiepoint();
      } else if (qcDecision === QC_DECISIONS.ESCALATE_TECHNICAL) {
        handleEscalateTechnical();
      }
    }
  };

  const prepareQcTimeString = (seconds) => {
    const time = prepareQCTime(seconds);

    const space = ' ';
    const dDisplay = time.days > 0 ? time.days + (time.days === 1 ? space + t('qcPanel.day') + space : space + t('qcPanel.days') + space) : '';
    const hDisplay = time.hours > 0 ? time.hours + (time.hours === 1 ? space + t('qcPanel.hour') + space : space + t('qcPanel.hours')) + space : '';
    const mDisplay = time.mins > 0 ? time.mins + (time.mins === 1 ? space + t('qcPanel.minute') + space : space + t('qcPanel.minutes')) + space : '';
    const displayStr = time.days > 0 ? dDisplay : hDisplay + mDisplay;
    return displayStr;
  };

  const handleDecisionDialogClose = () => {
    setQcDecision(qcPanelType);
    setQcDecisionDialogOpen(false);
  };

  const handleViewManualTiePoint = () => {
    // below condition added to support smooth development in local environment
    const appPath = !isEmpty(REACT_APP_ENV) ? ASSESS_BASE_PATH : '';
    window.open(`${window.location.origin}${appPath}/manual-at-adjustment/v2/${orderId}`, '_blank');
  };

  const handleAcceptButtonEnable = () => {
    const structures = get(multiStructure, 'structures', []);
    if (structures.length === 0) { // if structures are not available
      return false;
    }

    let enableAccept = true;
    structures.every((structure) => {
      if (structures.length > 1 && (get(structure, 'isCaptured', true) && get(structure, 'meta.visited.qc', false) === false)) {
        enableAccept = false;
        return false; // break
      }
      return true; // continue
    });
    return enableAccept;
  };

  const getTooltipMessage = () => {
    if (skydioMosaicImageUrl === SKYDIO_IMAGE_DOWNLOAD_ERROR) return t('manualAtAdjustment.skydioImageDownloadError');
    return skydioMosaicPresent ? t('qcPanel.viewSkydio') : t('qcPanel.skydioUnavailable');
  };

  return (!hideQcPanel && (
    <>
      <EVInformationPanel
        titleComponent={<QcPendingPanelTitle title={qcPanelType} />}
        enableExpandCollapseOnUI
        enableCloseOnUI={false}
        headerInputProps={headerInputProps}
        width={300}
        height="auto"
        titleHeight="50px"
        className={classes.infoPanel}
        initializePanelExpanded={false}
        dragEnabled
        draggableProps={{ bounds: 'parent' }}
      >
        <div className={classes.content}>
          {
            enableQcEnhancement === true ? (
              <>
                <EVBox className={classes.enhancedbuttonsContainer}>
                  <EVTypography className={classes.propertyHeading}>
                    {t('adjuster.qcPendingPanelPropertyHeading')}
                  </EVTypography>
                  <div className={classes.enhancedheadingButtons}>
                    {
                      qcPanelType === 'QC_PENDING'
                        ? (
                          <>
                            <QcNotes
                              actionEvent={handleQcDecision}
                              orderId={orderId}
                              isOpsUser={isOpsUser}
                              shouldBeSelfAssigned={shouldBeSelfAssigned}
                            />
                            <EVButton
                              color="primary"
                              variant="contained"
                              className={classes.qcAcceptPropertyButton}
                              onClick={() => handleQcDecision(QC_DECISIONS.APPROVE)}
                              disabled={!handleAcceptButtonEnable()}
                            >
                              {t('adjuster.qcPendingPanelAcceptProperty')}
                            </EVButton>
                          </>
                        )
                        : null
                    }
                    {
                      (qcPanelType === QC_DECISIONS.REJECT || qcPanelType === QC_DECISIONS.ESCALATE_TECHNICAL) && isOpsUser === false && (
                        <>
                          <QcNotes
                            actionEvent={handleQcDecision}
                            orderId={orderId}
                            isOpsUser={isOpsUser}
                            shouldBeSelfAssigned={shouldBeSelfAssigned}
                          />
                          <EVButton
                            color="primary"
                            variant="contained"
                            className={classes.qcAcceptPropertyButton}
                            onClick={() => handleQcDecision(QC_DECISIONS.APPROVE)}
                            disabled={!handleAcceptButtonEnable()}
                          >
                            {t('adjuster.qcPendingPanelAcceptProperty')}
                          </EVButton>
                        </>
                      )
                    }
                  </div>
                </EVBox>
              </>
            )
              : (
                <>
                  <EVBox className={classes.buttonsContainer}>
                    <EVTypography className={classes.orderHeading}>
                      {t('adjuster.qcPendingPanelOrderHeading')}
                    </EVTypography>
                    <div className={classes.headingButtons}>
                      {
                        qcPanelType === 'QC_PENDING'
                          ? (
                            <>
                              <QcNotes
                                actionEvent={handleQcDecision}
                                orderId={orderId}
                                isOpsUser={isOpsUser}
                                shouldBeSelfAssigned={shouldBeSelfAssigned}
                              />
                              <EVButton
                                color="primary"
                                variant="contained"
                                className={classes.qcAcceptButton}
                                onClick={() => handleQcDecision(QC_DECISIONS.APPROVE)}
                                disabled={!handleAcceptButtonEnable()}
                              >
                                {t('adjuster.qcPendingPanelAccept')}
                              </EVButton>
                            </>
                          )
                          : null
                      }
                      {
                        qcPanelType === 'QC_COMPLETED_REJECTED'
                          ? (
                            <>
                              {isOpsUser === false && (
                                <>
                                  <QcNotes
                                    actionEvent={handleQcDecision}
                                    orderId={orderId}
                                    isOpsUser={isOpsUser}
                                    shouldBeSelfAssigned={shouldBeSelfAssigned}
                                  />
                                  <EVButton
                                    color="primary"
                                    variant="contained"
                                    className={classes.qcAcceptButton}
                                    onClick={() => handleQcDecision(QC_DECISIONS.APPROVE)}
                                    disabled={!handleAcceptButtonEnable()}
                                  >
                                    {t('adjuster.qcPendingPanelAccept')}
                                  </EVButton>
                                </>
                              )}
                            </>
                          )
                          : null
                      }
                    </div>
                  </EVBox>
                </>
              )
          }

          <EVDivider />

          <div className={classes.structureLevel}>
            <EVTypography className={classes.structureLevelHeading}>
              {selectedStructureId}
            </EVTypography>
            {
              selectedStructureId !== 'structure1' && <QcStructureReject orderId={orderId} qcPanelType={qcPanelType} />
            }
          </div>
          {
            get(
              get(multiStructure, 'structures', []).find((str) => get(str, 'structureID', '') === selectedStructureId),
              'isCaptured',
              false,
            ) === true
            && (
              <>
                <div className={classes.tableParent}>
                  <QcTable
                    tableData={chartData}
                    setFacetWithInitialAnomaly={setFacetWithInitialAnomaly}
                  />
                </div>

                <div>
                  <p className={classes.text}>
                    {`${t('qcPanel.CoveragePercentage')}`}
                    {coveragePercentage}
                    %
                  </p>
                </div>
              </>
            )
          }
          {
            qcPanelType === 'QC_PENDING'
              ? (
                <>
                  <EVTypography className={classes.statusText}>
                    {`${t('qcPanel.pendingMsg')} `}
                    {getQCStartElapsedTime(qcStartTime)}
                  </EVTypography>
                  <EVTypography className={classes.statusText}>
                    {getQCDateAndTime(qcStartTime)}
                    {` ${t('qcPanel.utc')} `}
                  </EVTypography>
                </>
              )
              : (
                <>
                  <EVTypography className={classes.statusText}>
                    {`${t('qcPanel.completedMsg')} `}
                    {prepareQcTimeString(qcEndTime - qcStartTime)}
                  </EVTypography>
                  <EVTypography className={classes.statusText}>
                    {getQCDateAndTime(qcStartTime)}
                    {' - '}
                    {getQCDateAndTime(qcEndTime)}
                  </EVTypography>
                  <EVTypography className={classes.statusText}>
                    {` ${t('qcPanel.utc')} `}
                  </EVTypography>
                </>
              )
          }
          <div className={classes.footer}>
            {
              get(
                get(multiStructure, 'structures', []).find((str) => get(str, 'structureID', '') === selectedStructureId),
                'isCaptured',
                false,
              ) === true
              && (
                <EVTooltip title={getTooltipMessage()} placement="top">
                  <span>
                    <EVButton
                      color="primary"
                      variant="contained"
                      onClick={handleDroneIconClick}
                      disabled={!skydioMosaicPresent || showSkydioMosaicImagePanel || skydioMosaicImageUrl === SKYDIO_IMAGE_DOWNLOAD_ERROR}
                      className={classes.viewFacetsButton}
                    >
                      {t('qcPanel.viewFacets')}
                    </EVButton>
                  </span>
                </EVTooltip>
              )
            }
            {
              isQcD2m === false
                ? (
                  <EVButton
                    color="primary"
                    variant="contained"
                    onClick={handleViewManualTiePoint}
                    className={classes.viewTiePointButton}
                  >
                    {t('qcPanel.viewtiepoints')}
                  </EVButton>
                )
                : null
            }
          </div>
        </div>
      </EVInformationPanel>

      <EVDialog id="decisionDialog" open={qcDecisionDialogOpen} onClose={handleDecisionDialogClose}>
        <EVDialogContent>
          <EVDialogContentText>{t(QC_CONFIRMATION_DIALOG_MSG[qcDecision])}</EVDialogContentText>
        </EVDialogContent>
        <EVDialogActions>
          <EVButton id="cancel" onClick={handleDecisionDialogClose}>{t('cancel')}</EVButton>
          <EVButton id="confirm" onClick={handleQcDecisionConfirm} color="primary">{t('confirm')}</EVButton>
        </EVDialogActions>
      </EVDialog>
    </>
  ));
};

QcPendingPanel.propTypes = {
  chartData: shape([]).isRequired,
  coveragePercentage: string.isRequired,
  qcPanelType: string.isRequired,
  orderId: string.isRequired,
  QcClaimID: string.isRequired,
  qcStartTime: string.isRequired,
  qcEndTime: string.isRequired,
  setFacetWithInitialAnomaly: func.isRequired,
};

export default QcPendingPanel;
