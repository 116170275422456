import { makeStyles } from '@eagleview/ev-comp-library';

export default makeStyles((theme) => ({
  wrapper: {
    background: theme.evPalette.charcoal[100],
    opacity: 0.8,
    borderRadius: '5px',
  },
  heading: {
    textTransform: 'none',
  },
}));
