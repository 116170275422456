import moment from 'moment';
import { QC_PENDING_DATE_FORMAT } from './QCPendingPanel.constants';

export const getQCStartElapsedTime = (startDate) => {
  const space = ' ';
  return startDate ? moment.unix(startDate).utc().fromNow() + space : '';
};

export const prepareQCTime = (seconds) => {
  const sec = Number(seconds);
  const d = Math.floor(sec / 86400);
  const h = Math.floor((sec % 86400) / 3600);
  const m = Math.floor((sec % 3600) / 60);

  const time = {
    days: d,
    hours: h,
    mins: m,
  };
  return time;
};

export const getQCDateAndTime = (date) => (date ? moment.unix(date).utc().format(QC_PENDING_DATE_FORMAT) : '');
