import {
  FETCH_USER_ENTITLEMENTS,
  FETCH_USER_ENTITLEMENTS_SUCCESS,
  FETCH_USER_ENTITLEMENTS_FAILURE,
  SAVE_FEATURE_FLAGS,
} from './EntitleUser.constants';

export const fetchUserEntitlements = () => ({
  type: FETCH_USER_ENTITLEMENTS,
});

export const fetchUserEntitlementsSuccessAction = (payload) => ({
  type: FETCH_USER_ENTITLEMENTS_SUCCESS,
  payload,
});

export const fetchUserEntitlementsFailureAction = (payload) => ({
  type: FETCH_USER_ENTITLEMENTS_FAILURE,
  payload,
});

export const saveFeatureFlagsAction = (payload) => ({
  type: SAVE_FEATURE_FLAGS,
  payload,
});
