import axios from 'axios';
import {
  ASSESS_SERVICE_API_ENDPOINT,
  SHARE_LINK_ENDPOINT,
} from 'constants.js';
import * as util from 'utils/utils';

export const generateOtp = async (payload) => {
  const { uuid } = payload;
  try {
    const { data } = await axios.get(
      `${ASSESS_SERVICE_API_ENDPOINT}/${uuid}/otp`,
    );
    return { ...payload, data };
  } catch (error) {
    return util.handleHttpError(error);
  }
};

export const verifyOtp = async (payload) => {
  const { uuid, otp } = payload;
  try {
    const { data } = await axios.post(
      `${SHARE_LINK_ENDPOINT}/gateway/validate_otp`,
      { id: uuid, otp },
    );
    return { ...payload, data };
  } catch (error) {
    return util.handleHttpError(error);
  }
};
