import React from 'react';
import PNFImg from 'assets/PageNotFound.png';
import {
  EVBox,
} from '@eagleview/ev-comp-library';
import { number } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { checkCode } from 'utils/utils';
import useStyles from './PageNotFound.styles';
import {
  PAGE_NOT_FOUND_ERROR_CODE, LINK_EXPIRY_ERROR_CODE,
} from './constants';

const PageNotFound = ({ validateCode }) => {
  const { t } = useTranslation();
  const styles = useStyles();

  return (
    <div className={styles.containerStyle}>
      <EVBox className={styles.pageNotFound}>
        <img src={PNFImg} alt="img" className={styles.imageCss} />
        <EVBox>
          {(validateCode === PAGE_NOT_FOUND_ERROR_CODE || checkCode(validateCode))
            ? (
              <div>
                <EVBox className={styles.errCode}>
                  {t('pageNotFound.pageNotFound')}
                </EVBox>
                {(validateCode === LINK_EXPIRY_ERROR_CODE)
                  ? (
                    <EVBox className={styles.errMsg}>
                      {t('pageNotFound.linkExpired')}
                    </EVBox>
                  )
                  : (
                    <EVBox className={styles.errMsg}>
                      {t('pageNotFound.pageNotFoundMsg')}
                    </EVBox>
                  )}
              </div>
            )
            : (
              <div>
                <EVBox className={styles.errCode}>
                  {t('pageNotFound.internalServerError')}
                </EVBox>
                <EVBox className={styles.errMsg}>
                  {t('pageNotFound.internalServerErrorMsg')}
                </EVBox>
              </div>
            )}
        </EVBox>
      </EVBox>
    </div>
  );
};

PageNotFound.propTypes = {
  validateCode: number.isRequired,
};
export default PageNotFound;
