import axios from 'axios';
import * as util from 'utils/utils';
import {
  ASSESS_SERVICE_API_ENDPOINT,
  ASSESS_APPLICATION_SETTINGS_ENDPOINT,
  ASSESS_ORDER_MANAGER_API_ENDPOINT,
  ENTITLEMENT_SERVICE_API_ENDPOINT,
  ORDER_ORCHESTRATOR_API_ENDPOINT,
} from 'constants.js';

export const loadQcEscalatedClaims = async () => {
  try {
    const { data } = await axios.get(`${ASSESS_SERVICE_API_ENDPOINT}/qc/orders/status/escalated/all`);
    return data;
  } catch (error) {
    return util.handleHttpError(error);
  }
};

export const loadQcPendingClaims = async () => {
  try {
    const { data } = await axios.get(`${ASSESS_SERVICE_API_ENDPOINT}/qc/orders/status/current/all`);
    return data;
  } catch (error) {
    return util.handleHttpError(error);
  }
};

export const loadQcManualAtClaims = async () => {
  try {
    const { data } = await axios.get(`${ASSESS_SERVICE_API_ENDPOINT}/manualATJob/status/open`);
    return data;
  } catch (error) {
    return util.handleHttpError(error);
  }
};

export const fetchOpenOrders = async () => {
  try {
    const { data } = await axios.get(`${ASSESS_SERVICE_API_ENDPOINT}/orders/all/status/current`);
    return data;
  } catch (error) {
    return util.handleHttpError(error);
  }
};

export const fetchCompletedOrders = async () => {
  try {
    const { data } = await axios.get(`${ASSESS_SERVICE_API_ENDPOINT}/orders/all/status/past`);
    return data;
  } catch (error) {
    return util.handleHttpError(error);
  }
};

export const resetOrderToReadyForReview = async ({ orderId, retainData }) => {
  try {
    const { data } = await axios.put(`${ASSESS_ORDER_MANAGER_API_ENDPOINT}/orders/${orderId}/state/readytoreview?retainData=${retainData}`);
    return data;
  } catch (error) {
    return util.handleHttpError(error);
  }
};

export const fetchAssessAppSettingsApi = async () => {
  try {
    const { data: response } = await axios.get(
      `${ASSESS_APPLICATION_SETTINGS_ENDPOINT}`,
    );
    return response;
  } catch (error) {
    return util.handleHttpError(error);
  }
};

export const fetchInternalSchedulerEntitlements = async () => {
  try {
    const { data } = await axios.get(`${ENTITLEMENT_SERVICE_API_ENDPOINT}?appCode=internal_scheduler`);
    return data;
  } catch (error) {
    return util.handleHttpError(error);
  }
};

export const fetchOrganizations = async (searchTerm = '', cancelToken) => {
  try {
    const { data } = await axios.get(`${ASSESS_SERVICE_API_ENDPOINT}/organizations?searchText=${searchTerm}`, { cancelToken });
    return data;
  } catch (error) {
    return util.handleHttpError(error);
  }
};

export const duplicateOrder = async (orderDuplicationDetails) => {
  try {
    const { data } = await axios.post(`${ORDER_ORCHESTRATOR_API_ENDPOINT}/order`, orderDuplicationDetails);
    return data;
  } catch (error) {
    return util.handleHttpError(error);
  }
};

export const reflyRequired = async ({ orderId, notes }) => {
  try {
    const { data } = await axios.post(`${ASSESS_SERVICE_API_ENDPOINT}/orders/${orderId}/reflyRequired`, { notes });
    return data;
  } catch (error) {
    return util.handleHttpError(error);
  }
};
