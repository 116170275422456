import React, { Component } from 'react';
import { node, func, string } from 'prop-types';
import ToastMessage from 'components/toast-message';
import { errorHandlerService, logger } from 'utils/utils';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, showToast: false };
    this.clearError = this.clearError.bind(this);
  }

  static getDerivedStateFromError() {
    return { hasError: true, showToast: true };
  }

  componentDidCatch(error, errorInfo) {
    const { componentName } = this.props;
    errorHandlerService(error, errorInfo, componentName);
    logger('warn', error, errorInfo);
  }

  clearError() {
    this.setState((prevState) => ({ ...prevState, hasError: false }));
  }

  render() {
    const { state, props } = this;
    const { hasError, showToast } = state;
    const { fallback, children, alertMsg } = props;
    if (hasError) {
      const fallBackNode = fallback({ clearError: this.clearError });
      if (React.isValidElement(fallBackNode)) {
        return fallBackNode;
      }
      return (
        <ToastMessage
          alertMsg={alertMsg}
          severity="error"
          open={showToast}
          onClose={() => this.setState((prevState) => ({ ...prevState, showToast: false }))}
          autoHideDuration={null}
        />
      );
    }
    return children;
  }
}

ErrorBoundary.propTypes = {
  children: node.isRequired,
  componentName: string.isRequired,
  fallback: func,
  alertMsg: string,
};

ErrorBoundary.defaultProps = {
  fallback: () => {
    // This is an empty block
  },
  alertMsg: 'Something went wrong',
};

export default ErrorBoundary;
