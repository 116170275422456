import { call, put, takeLatest } from 'redux-saga/effects';
import get from 'lodash/get';
import * as util from 'utils/utils';
import * as action from './EntitleUser.actions';
import * as api from './EntitleUser.api';
import { FETCH_USER_ENTITLEMENTS } from './EntitleUser.constants';

export function* fetchUserEntitlementSaga() {
  try {
    const response = yield call(api.fetchUserEntitlements);
    const entitlements = util.filterSharedUrlEntitlements(get(response, 'entitlements.assess'));
    const userID = get(response, 'userName');
    yield put(action.fetchUserEntitlementsSuccessAction({ entitlements, userID }));
  } catch (error) {
    yield put(action.fetchUserEntitlementsFailureAction(util.parseServerError(error)));
  }
}

export default function* entitleUserSaga() {
  yield takeLatest(FETCH_USER_ENTITLEMENTS, fetchUserEntitlementSaga);
}
