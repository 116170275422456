import React, { useEffect, useState } from 'react';
import { EVReactVirtualized, EVTableCell, EVTableSortLabel } from '@eagleview/ev-comp-library';
import clsx from 'clsx';
import {
  orderBy as sort, get, toLower, isString,
} from 'lodash';
import {
  arrayOf, number, shape, string,
} from 'prop-types';
import { useTranslation } from 'react-i18next';
import { SORT_DIRECTION } from 'layout/homeLayout/qcHome/QcHome.constants';
import QcHomeTableCell from '../../qc-home-table-cell/QcHomeTableCell';
import useStyles from './QcHomeVirtualizedTable.style';
import { getSortByDataKey } from '../../QcHome.utils';

const {
  Column, Table, CellMeasurerCache, CellMeasurer,
} = EVReactVirtualized;

/**
 * QcHomeVirtualizedTable represents virtualized table for displaying QC claims by QcHomeTable component
 */
const QcHomeVirtualizedTable = (props) => {
  // utility hooks
  const styles = useStyles();
  const { t } = useTranslation();

  const {
    columns,
    data,
    tableHeight,
    tableWidth,
    headerHeight,
  } = props;

  // local state
  const [sortedList, setSortedList] = useState(data);
  const [sortBy, setSortBy] = useState('');
  const [sortDirection, setSortDirection] = useState('');

  const sortList = () => {
    const sb = getSortByDataKey(sortBy);
    const sortFunc = (row) => {
      const value = get(row, sb);
      return isString(value) ? toLower(value) : value;
    };
    return sort(data, sortFunc, sortDirection);
  };

  const handleSort = (property) => {
    const isAsc = sortBy === property && sortDirection === SORT_DIRECTION.ASC;
    setSortDirection(isAsc ? SORT_DIRECTION.DESC : SORT_DIRECTION.ASC);
    setSortBy(property);
  };

  useEffect(() => {
    if (sortBy !== '') {
      setSortedList(sortList());
    }
  }, [sortBy, sortDirection]);

  const cache = new CellMeasurerCache({
    fixedWidth: true,
    minHeight: 50,
  });

  useEffect(() => {
    setSortedList(data);
  }, [data]);

  /**
   * Returns the styles to be applied to a row
   * @param {Number} index -  Index of the row
   */
  const getRowClassName = (index) => clsx(styles.flexContainer, index < 0 ? styles.tableHeader : styles.tableRow);

  /**
   * Defines how a cell is to be rendered
   * @param {Object} cellProperties -  Properties of a table cell
   * @returns {Component} - Table cell to be rendered
   */
  const cellRenderer = (cellProperties) => {
    const {
      cellData, rowData, dataKey, parent, rowIndex, columnIndex,
    } = cellProperties;
    return (
      <CellMeasurer
        cache={cache}
        columnIndex={columnIndex}
        key={dataKey}
        parent={parent}
        rowIndex={rowIndex}
      >
        <QcHomeTableCell
          className={styles.tableCell}
          key={`${cellData},${dataKey}`}
          headCellId={dataKey}
          row={rowData}
        />
      </CellMeasurer>
    );
  };

  /**
   * Defines how a header cell is to be rendered
   * @param {Object} headerProperties -  Properties of table header
   * @returns {Component} - Table header cell to be rendered
   */
  const headerRenderer = (headerProperties) => {
    const { label, dataKey } = headerProperties;
    return (
      <EVTableCell
        key={dataKey}
        component="div"
        className={clsx(styles.flexContainer, styles.tableCell)}
        variant="head"
        align="left"
      >
        {
          label !== ''
          && (
          <EVTableSortLabel
            id={`header-${dataKey}`}
            active={sortBy === dataKey}
            direction={sortBy === dataKey ? sortDirection : SORT_DIRECTION.ASC}
            onClick={() => handleSort(dataKey)}
          >
            {t(label)}
          </EVTableSortLabel>
          )
        }
      </EVTableCell>
    );
  };

  return (
    <Table
      height={tableHeight}
      width={tableWidth}
      headerHeight={headerHeight}
      className={styles.table}
      rowClassName={({ index }) => getRowClassName(index)}
      deferredMeasurementCache={cache}
      rowHeight={cache.rowHeight}
      rowCount={sortedList.length}
      rowGetter={({ index }) => sortedList[index]}
      id="table"
    >
      {columns.map((column, index) => {
        const {
          dataKey, width, minWidth, label,
        } = column;
        return (
          <Column
            key={dataKey}
            headerRenderer={(headerProps) => headerRenderer({
              ...headerProps,
              columnIndex: index,
            })}
            className={styles.flexContainer}
            cellRenderer={cellRenderer}
            dataKey={dataKey}
            width={width}
            minWidth={minWidth}
            label={label}
          />
        );
      })}
    </Table>
  );
};

QcHomeVirtualizedTable.propTypes = {
  /**
   * @property {Array} columns - Columns and properties of columns for the table
   */
  columns: arrayOf(shape({
    width: number,
    minWidth: number,
    label: string,
    dataKey: string,
  })).isRequired,
  /**
   * @property {Number} headerHeight - Height of the table header
   */
  headerHeight: number,
  /**
   * @property {Array} data - Data to be displayed by the table
   */
  data: arrayOf(shape({
    claimId: string,
    address: string,
    claimOwner: string,
    status: string,
  })).isRequired,
  /**
   * @property {Number} height - Height of the AutoSizer component
   */
  tableHeight: number.isRequired,
  /**
   * @property {Number} tableHeight - Width of the AutoSizer component
   */
  tableWidth: number.isRequired,
};

QcHomeVirtualizedTable.defaultProps = {
  headerHeight: 60,
};

export default QcHomeVirtualizedTable;
