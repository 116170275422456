import { makeStyles } from '@eagleview/ev-comp-library';

export default makeStyles((theme) => ({
  roofItemsDialogTitle: {
    fontSize: '18px',
    fontWeight: '600',
  },
  roofItemsDialogSubTitle: {
    color: theme.palette.grey[600],
    fontSize: '14px',
    fontWeight: '600',
  },
  galleryThumbnailListItem: {
    display: 'inline-block',
    width: 'calc(25% - 4px)',
    margin: '0 2px',
    padding: 0,
    position: 'relative',
  },
  galleryImageThumbnail: {
    width: '70px',
    height: '70px',
    borderRadius: '5px',
    cursor: 'pointer',
  },
  galleryImageThumbnailActive: {
    border: `${theme.evSpacing['ev-spacing--4']} solid ${theme.evPalette.huckleberry[500]}`,
    borderRadius: theme.evSpacing['ev-spacing--4'],
    padding: '2px',
  },
  gallerySelectedImageContainer: {
    backgroundColor: theme.palette.common.black,
    '&:hover': {
      '& $galleryArrowButton': {
        display: 'block',
      },
    },
  },
  galleryArrowButton: {
    display: 'none',
    transform: 'translateY(-50%)',
    color: theme.palette.common.white,
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.6)',
    },
  },
  paperStyle: {
    height: '100%',
    width: '100%',
    maxWidth: '100%',
  },
  toolbarPanel: {
    backgroundColor: theme.palette.common.white,
    border: '1px solid #ccc',
    borderRadius: '5px',
  },
  toolbarPanelSpacing: {
    marginTop: '10px',
    marginBottom: '15px',
  },
  imagesContainer: {
    flexGrow: 1,
  },
  thumbnailOverlay: {
    position: 'absolute',
    bottom: theme.typography.pxToRem(5),
    left: 0,
    background: 'rgba(0,0,0,0.5)',
    width: '98%',
    padding: theme.evSpacing['ev-spacing--4'],
    display: 'flex',
    borderRadius: `0px 0px ${theme.spacing(1)}px ${theme.spacing(1)}px`,
    height: '40%',
  },
  thumbnailOverlaySelected: {
    borderRadius: `0px 0px ${theme.spacing(0.8)} ${theme.spacing(0.8)}`,
  },
  tagIconStyle: {
    color: theme.evPalette.salt[100],
    display: 'block',
    width: theme.typography.pxToRem(26),
    height: 'auto',
    padding: theme.spacing(0.125),
    marginLeft: 'auto',
  },
  iconOpacity: {
    opacity: 0,
  },
  reportSwitch: {
    border: 'unset',
    paddingRight: 'unset',
    marginLeft: 'auto',
  },
}));
