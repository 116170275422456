import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  EVMenu,
  EVMenuItem,
  EVBox,
  EVTypography,
  EVNoImageryIcon,
  EVTooltip,
} from '@eagleview/ev-comp-library';
import { get } from 'lodash';
import clsx from 'clsx';
import {
  func, shape, string, arrayOf,
} from 'prop-types';
import { useTranslation } from 'react-i18next';
import styles from './MultiStructureMenuStyle';
import * as action from '../../Adjuster.actions';

const MultiStructureMenu = (props) => {
  const { t } = useTranslation();
  const classes = styles();
  const {
    menuOpenEl, setMenuOpen, structures, isVisitedOrNotCaptured, markVisited,
  } = props;

  // selectors
  const selectedStructureId = useSelector((state) => state.adjusterReducer.selectedStructureId);

  // actions
  const dispatch = useDispatch();
  const setSelectedStructureId = (payload) => dispatch(action.setSelectedStructureId(payload));
  const clearRoofFacetAndBaseImage = () => dispatch(action.clearRoofFacetAndBaseImage());

  const changeStructure = (structure) => {
    setSelectedStructureId(get(structure, 'structureID', ''));
    const isCaptured = get(structure, 'isCaptured', true);
    if (!isCaptured) {
      clearRoofFacetAndBaseImage();
    }
    setMenuOpen(null);
    if (!isVisitedOrNotCaptured(structure)) {
      markVisited(get(structure, 'structureID', ''));
    }
  };
  const isActiveStructure = (structureId) => (selectedStructureId === structureId);
  return (
    <EVMenu
      anchorEl={menuOpenEl}
      keepMounted
      open={!!menuOpenEl}
      onClose={() => setMenuOpen(null)}
      getContentAnchorEl={null}
      anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      className={classes.menu}
      PaperProps={{
        style: {
          minWidth: 162,
        },
      }}
    >
      {
      structures.map((structure) => (
        <EVTooltip title={t('multiStructureMenu.disabledStructureTooltip')} disableHoverListener={structure.isCaptured} arrow>
          <EVMenuItem
            className={classes.menuItem}
            key={get(structure, 'structureID', '')}
            onClick={() => (structure.isCaptured || structure.isGroundCaptured) && changeStructure(structure)}
          >
            {isActiveStructure(get(structure, 'structureID', '')) && <EVBox className={classes.indicator} />}
            <EVTypography className={
            clsx({
              [classes.menuItem]: true,
              [classes.selectedMenu]: get(structure, 'structureID', ''),
              [classes.disabledMenu]: !(structure.isCaptured || structure.isGroundCaptured),
            })
            }
            >
              {get(structure, 'structureName', '')}
            </EVTypography>
            {!(structure.isCaptured || structure.isGroundCaptured) ? <EVNoImageryIcon color="disabled" className={classes.noImagery} />
              : !isVisitedOrNotCaptured(structure) && <EVBox className={classes.redDot} />}
          </EVMenuItem>
        </EVTooltip>
      ))
      }
    </EVMenu>
  );
};

MultiStructureMenu.propTypes = {
  menuOpenEl: shape({}),
  setMenuOpen: func,
  structures: arrayOf(
    shape({
      structureID: string,
    }),
  ),
  isVisitedOrNotCaptured: func,
  markVisited: func,
};

MultiStructureMenu.defaultProps = {
  menuOpenEl: null,
  setMenuOpen: () => {
    // This is an empty block
  },
  structures: [{}],
  isVisitedOrNotCaptured: () => {
    // This is an empty block
  },
  markVisited: () => {
    // This is an empty block
  },
};

export default MultiStructureMenu;
