import { makeStyles } from '@eagleview/ev-comp-library';

export default makeStyles((theme) => ({
  orderToolbar: {
    position: 'absolute',
    zIndex: 1,
    right: theme.spacing(2),
    top: `calc(${theme.evSpacing['ev-spacing--4']} + 32px)`,
  },
  circularProgressBar: {
    color: theme.evPalette.charcoal[300],
  },
}));
