import React, { useState, useEffect } from 'react';
import {
  EVBox,
  EVTypography,
  EVCard,
  EVRadio,
  EVCheckOutlinedIcon,
} from '@eagleview/ev-comp-library';
import { bool } from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import PendingImage from 'assets/riskImage.png';
import ManualAtHeaderBar from '../manual-at-header-bar/ManualAtHeaderBar';
import useStyles from './ManualAtReportStructureMapScreen.styles';
import * as action from '../ManualAtAdjustment.actions';
import { MAIN_STRUCTURE } from '../ManualAtAdjustment.constants';

const ManualAtReportStructureMapScreen = ({ disableRadio }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  // local state
  const [selectedReport, setSelectedReport] = useState('');

  // redux actions
  const dispatch = useDispatch();
  const mapStructureWithReport = (payload) => dispatch(action.mapStructureWithReportAction(payload));

  // selectors
  const reports = useSelector((state) => get(state, 'manualAtMultiStructureReducer.reports', []));
  const structures = useSelector((state) => get(state, 'manualAtMultiStructureReducer.structures', []));
  const selectedStructure = useSelector((state) => get(state, 'manualAtMultiStructureReducer.selectedStructure', {}));
  const reportDetails = useSelector((state) => get(state, 'manualAtMultiStructureReducer.reportDetails', {}));
  const structuresDetails = useSelector((state) => get(state, 'manualAtMultiStructureReducer.structuresDetails', {}));
  const capturedStructuresCount = useSelector((state) => get(state, 'manualAtMultiStructureReducer.capturedStructuresCount', 0));

  const facetImageUrl = selectedStructure.structureID && structuresDetails[selectedStructure.structureID]
    && structuresDetails[selectedStructure.structureID].facetImageUrl;

  useEffect(() => {
    if (reports.length === 1) setSelectedReport(reports[0]);
  }, [reports]);
  const structureNumber = (selectedStructure.structureID
    && structures.findIndex((structure) => structure.structureID === selectedStructure.structureID) + 1) || '';

  const handleSelectReport = (e, val) => {
    setSelectedReport(val);
  };

  const handleNextButtonClick = () => {
    mapStructureWithReport(selectedReport);
  };

  return (
    <>
      <EVBox px={3} className={classes.container}>
        <ManualAtHeaderBar
          header={t('manualAtAdjustment.reportSelection')}
          subheader={t('manualAtAdjustment.structureNumber').replace('$num$', structureNumber).replace('$count$', capturedStructuresCount)}
          disableNextButton={!selectedReport}
          handleNextClick={handleNextButtonClick}
        />
        <EVBox className={classes.reportStructureContainer}>
          <EVBox className={classes.reportsContainer}>
            <EVTypography className={classes.reportStructureText}>{t('manualAtAdjustment.reports').replace('$count$', reports.length)}</EVTypography>
            <EVBox className={classes.reportsListContainer}>
              {reports.map((report, i) => {
                const imageReady = !!(reportDetails[report].orthoImage && reportDetails[report].orthoImage[0] && reportDetails[report].orthoImage[0].url);
                return (
                  <EVCard key={`report-${report}`} className={classes.reportCard}>
                    <EVBox className={classes.cardHeader}>
                      <EVRadio
                        id={`report-${report}`}
                        color="primary"
                        checked={report === selectedReport}
                        onChange={(e) => handleSelectReport(e, report)}
                        value={selectedReport}
                        disabled={!imageReady || disableRadio}
                      />
                      <EVTypography className={classes.reportHeaderText}>{t('manualAtAdjustment.report').replace('$num$', i + 1)}</EVTypography>
                    </EVBox>
                    <EVBox className={classes.imageContainer} onClick={(e) => imageReady && selectedReport !== report && handleSelectReport(e, report)}>
                      <img
                        className={clsx(classes.image, imageReady && classes.cursorPointer)}
                        src={(imageReady && reportDetails[report].orthoImage[0].url) || PendingImage}
                        alt={t('manualAtAdjustment.report').replace('$num$', i + 1)}
                        data-testid="reportImage"
                      />
                      {report === selectedReport && (
                      <EVBox className={classes.overlay}>
                        <EVCheckOutlinedIcon className={classes.checkIcon} />
                      </EVBox>
                      )}
                    </EVBox>
                  </EVCard>
                );
              })}
            </EVBox>
          </EVBox>
          <EVBox className={classes.structureContainer}>
            <EVTypography className={classes.reportStructureText}>
              {selectedStructure.structureID === MAIN_STRUCTURE
                ? t('manualAtAdjustment.mainStructure')
                : t('manualAtAdjustment.structure')
                  .replace('$num$', selectedStructure.structureID ? selectedStructure.structureID.split('structure')[1] : '')}
            </EVTypography>
            <EVCard>
              <EVBox className={classes.structureImageContainer}>
                <img
                  className={classes.image}
                  src={facetImageUrl || PendingImage}
                  alt={t('manualAtAdjustment.structure').replace('$num$', structureNumber)}
                  data-testid="structureImage"
                />
              </EVBox>
            </EVCard>
          </EVBox>
        </EVBox>
      </EVBox>
    </>
  );
};

ManualAtReportStructureMapScreen.propTypes = {
  disableRadio: bool,

};

ManualAtReportStructureMapScreen.defaultProps = {
  disableRadio: false,
};

export default ManualAtReportStructureMapScreen;
