import {
  call, put, takeLatest,
} from 'redux-saga/effects';
import * as util from 'utils/utils';
import * as action from './DroneToMeasure.actions';
import * as api from './DroneToMeasure.api';
import * as utils from './DroneToMeasure.utils';
import * as constants from './DroneToMeasure.constants';

export function* fetchOrthoPolygonSaga({ payload }) {
  try {
    const response = yield call(api.fetchOrthoPolygon, payload);
    const vertices = utils.featureCollectionToPoints(response);
    yield put(
      action.fetchOrthoPolygonSuccessAction({
        vertices,
        polygon: utils.featureCollectionToPolygon(response),
      }),
    );
    yield put(action.setViewAction(utils.getCenterOfAllVertices(response)));
  } catch (error) {
    yield put(action.fetchOrthoPolygonFailureAction(util.parseServerError(error)));
  }
}

export function* fetchOrthoImageSaga({ payload }) {
  try {
    const orthoImage = yield call(api.fetchOrthoImage, payload.orderId, payload.imageName);
    yield put(action.fetchOrthoImageSuccessAction(utils.imageAsset(payload.meta, orthoImage)));
  } catch (error) {
    yield put(action.fetchOrthoImageFailureAction(util.parseServerError(error)));
  }
}

export function* fetchImagesAndDetailsSaga({ payload }) {
  try {
    const response = yield call(api.fetchImageDetailsApi, payload);
    const { imageName, meta } = response.images[0];
    yield put(action.fetchOrthoPolygonAction(payload.reportId));
    yield put(action.fetchOrthoImageAction({ ...payload, imageName, meta }));
  } catch (error) {
    yield put(action.fetchImagesAndDetailsFailureAction(util.parseServerError(error)));
  }
}

export function* fetchOrderDetailsSaga({ payload }) {
  try {
    const details = yield call(api.fetchOrderDetails, payload);
    yield put(action.fetchOrderDetailsSuccessAction(details));
    yield put(action.fetchImagesAndDetailsAction({ ...payload, reportId: details.reportID }));
  } catch (error) {
    yield put(action.fetchOrderDetailsFailureAction(util.parseServerError(error)));
  }
}

export default function* manualAtAdjustmentSaga() {
  yield takeLatest(constants.FETCH_ORTHO_POLYGON, fetchOrthoPolygonSaga);
  yield takeLatest(constants.FETCH_ORTHO_IMAGE, fetchOrthoImageSaga);
  yield takeLatest(constants.FETCH_ORDER_DETAILS, fetchOrderDetailsSaga);
  yield takeLatest(constants.FETCH_IMAGES_AND_DETAILS, fetchImagesAndDetailsSaga);
}
