import React, {
  createRef, useRef, useState, useEffect,
} from 'react';
import {
  EVBox,
  EVButton,
  EVFilterVerticalIcon,
  EVChip,
  EVDialog,
  EVDialogActions,
  EVDialogContent,
  EVDialogTitle,
  EVListItem,
  EVCheckbox,
  EVInputLabel,
  EVSelect,
  EVMenuItem,
  EVExpandMore,
  EVDivider,
  EVTypography,
} from '@eagleview/ev-comp-library';
import get from 'lodash/get';
import { useTranslation } from 'react-i18next';
import { FIX_HEIGHT, FETCH_ADJUSTER_DETAILS_GALLERY } from 'layout/adjuster/Adjuster.constants';
import { useSelector, useDispatch } from 'react-redux';
import * as action from 'layout/adjuster/Adjuster.actions';
import flatten from 'lodash/flatten';
import uniq from 'lodash/uniq';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import clsx from 'clsx';
import * as utils from 'layout/adjuster/Adjuster.utils';
import GalleryImageComponent from './GalleryImageComponent';
import useStyles from '../AdjusterSidePanel.styles';

const getDimensions = (ele) => {
  const { height } = ele.getBoundingClientRect();
  const { offsetTop } = ele;
  const offsetBottom = offsetTop + height;

  return {
    height,
    offsetTop,
    offsetBottom,
  };
};

const scrollTo = (ele) => {
  setTimeout(() => {
    ele.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  }, 0);
};

const SidePanelGallery = () => {
  const chipRef = useRef(null);
  const chipHeight = get(chipRef, 'current', {});
  let chipMaxHeight = 0;
  if (chipHeight && typeof chipHeight.getBoundingClientRect === 'function') {
    const chipMax = chipHeight.getBoundingClientRect();
    chipMaxHeight = get(chipMax, 'height', 0);
  }
  // utility hooks
  const styles = useStyles({
    totalHeight: FIX_HEIGHT + chipMaxHeight,
  });
  const { t } = useTranslation();

  // selectors
  const reportIncludedImages = useSelector((state) => state.adjusterReducer.reportIncludedImages);
  const customTags = useSelector((state) => state.adjusterReducer.customTags);
  const customTagsWithoutImage = useSelector((state) => uniq(flatten(get(state, 'adjusterReducer.customTags', []).map((image) => image.tags))));
  const notes = useSelector((state) => state.adjusterReducer.notes);
  const selectedImage = useSelector((state) => state.adjusterReducer.selectedImage);
  const currentTabGalleryImages = useSelector((state) => state.adjusterReducer.currentTabGalleryImages);
  const loading = useSelector((state) => state.adjusterReducer.loading);
  const filteredGalleryImagesWithIcons = useSelector((state) => state.adjusterReducer.filteredGalleryImages.map((image) => ({
    ...image,
    tagsIncluded: customTags.some((tag) => tag.imageID === image.urn),
    notesIncluded: notes.some((note) => note.imageID === image.urn),
    reportIncluded: reportIncludedImages.some((included) => included === image.urn),
  })));
  const appliedFilters = useSelector((state) => state.adjusterReducer.appliedGalleryImageFilters);
  const galleryImages = useSelector((state) => state.adjusterReducer.galleryImages);
  const currentTab = useSelector((state) => state.adjusterReducer.currentTab);
  const originalFalconTags = useSelector(
    (state) => (state.adjusterReducer.isAssessLite
      ? utils.filterFalconTagsByTab(state.adjusterReducer.falconTags, state.adjusterReducer.currentTab)
      : state.adjusterReducer.falconTags),
  );

  // actions
  const dispatch = useDispatch();
  const setSelectedImage = (payload) => dispatch(action.setSelectedImageAction(payload));
  const setAppliedFilters = (payload) => dispatch(action.setAppliedGalleryImageFilters(payload));

  // derived values
  const parsedFilters = !isEmpty(customTagsWithoutImage) ? Object.assign(...customTagsWithoutImage.map((tag) => ({ [tag]: appliedFilters[tag] }))) : {};

  // local state
  const [dialogOpen, setDialogOpen] = useState(false);
  const [filters, setFilters] = useState({});
  const [selectValue, setSelectValue] = useState(null);
  const [falconTags, setFalconTags] = useState([]);

  const headerRef = useRef(null);
  const scrollRef = useRef(null);

  const elementsRef = useRef([]);

  const scrollCheck = () => {
    const { height: headerHeight } = getDimensions(headerRef.current);
    const scrollPosition = scrollRef.current.scrollTop + headerHeight + elementsRef.current[0].current.offsetTop;
    if (scrollRef.current.clientHeight + scrollRef.current.scrollTop >= scrollRef.current.scrollHeight) {
      setSelectValue(falconTags[falconTags.length - 1]);
    } else {
      const selectedIndex = elementsRef.current.findIndex((ref) => {
        const ele = ref.current;
        let offsetBottom; let offsetTop;
        if (ele) {
          ({ offsetBottom, offsetTop } = getDimensions(ele));
        }
        return scrollPosition > offsetTop && scrollPosition < offsetBottom;
      });
      if (falconTags[selectedIndex]) setSelectValue(falconTags[selectedIndex]);
    }
  };

  useEffect(() => {
    if (dialogOpen) {
      setFilters(appliedFilters);
    }
    // eslint-disable-next-line
  }, [dialogOpen]);

  useEffect(() => {
    setFalconTags([]);
  }, [currentTab]);

  useEffect(() => {
    if (!isEmpty(falconTags)) { return; }
    if (!isEmpty(filteredGalleryImagesWithIcons) && !isEmpty(originalFalconTags)) {
      // exclude all tags that have empty images
      const filteredTags = isEmpty(filteredGalleryImagesWithIcons) ? originalFalconTags : originalFalconTags.filter(
        (tag) => filteredGalleryImagesWithIcons.filter((image) => image.tags.includes(tag)).length !== 0,
      );
      elementsRef.current = filteredTags.map(() => createRef());
      setFalconTags(filteredTags);
      setSelectValue(filteredTags[0]);
    }
  }, [filteredGalleryImagesWithIcons, originalFalconTags]);

  const handleFilterSubmit = () => {
    setDialogOpen(false);
    if (!isEqual(appliedFilters, filters)) setAppliedFilters(filters);
  };

  const handleClickTag = (value) => {
    setFilters({ ...filters, [value]: !filters[value] });
  };

  // on mount
  useEffect(() => {
    if (galleryImages) {
      setAppliedFilters(parsedFilters);
    }
  }, [galleryImages]);

  const handleFilterCancel = () => {
    setDialogOpen(false);
    setFilters({});
  };
  const handleChange = (event) => {
    setSelectValue(event.target.value);
    const scrollToDiv = falconTags.indexOf(event.target.value);
    scrollTo(elementsRef.current[scrollToDiv].current);
  };
  const removeChipFilter = (tag) => setAppliedFilters({ ...appliedFilters, [tag]: !appliedFilters[tag] });
  const showUtc = !loading[FETCH_ADJUSTER_DETAILS_GALLERY] && currentTabGalleryImages.length === 0;

  return (
    <EVBox>
      { !showUtc && (
        <>
          <EVBox id="galleryList">
            <EVBox className={styles.stickyHeader}>
              <EVBox ref={headerRef} className={styles.filtersBox}>
                <EVBox className={styles.filterButtonContainer}>
                  <EVBox>
                    <EVInputLabel className={styles.categories}>Categories</EVInputLabel>
                    <EVSelect
                      value={selectValue}
                      size="small"
                      className={styles.select}
                      onChange={handleChange}
                      disableUnderline
                      MenuProps={{
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'left',
                        },
                        transformOrigin: {
                          vertical: 'top',
                          horizontal: 'left',
                        },
                        getContentAnchorEl: null,
                      }}
                      IconComponent={EVExpandMore}
                      inputProps={{
                        classes: {
                          root: styles.statusSelect,
                          icon: styles.iconStatus,
                        },
                      }}
                    >
                      {falconTags.length > 0 && (falconTags.map((selectListItem) => (
                        <EVMenuItem className={styles.menuItem} value={selectListItem}>
                          {selectListItem}
                        </EVMenuItem>
                      )))}
                    </EVSelect>
                  </EVBox>
                  <EVDivider className={styles.Divider} orientation="vertical" flexItem />
                  <EVButton
                    disabled={isEmpty(customTagsWithoutImage)}
                    className={clsx(styles.filterButton, dialogOpen && styles.filterButtonDialogOpen)}
                    onClick={() => setDialogOpen(true)}
                  >
                    <EVFilterVerticalIcon className={clsx(styles.filterButtonIcon, dialogOpen && styles.filterButtonIconDialogOpen)} />
                  </EVButton>
                </EVBox>
              </EVBox>
              <EVBox ref={chipRef} className={styles.chipContainer}>
                {customTagsWithoutImage.map(
                  (tag) => appliedFilters[tag] && (
                  <EVChip
                    className={styles.chip}
                    key={tag}
                    label={tag}
                    onDelete={() => removeChipFilter(tag)}
                  />
                  ),
                )}
              </EVBox>
            </EVBox>
            <EVBox>
              <EVBox ref={scrollRef} className={styles.scrollableDiv} onScroll={() => { scrollCheck(); }}>
                {falconTags.map((categoryTag, index) => (
                  <GalleryImageComponent
                    categoryTag={categoryTag}
                    ref={elementsRef.current[index]}
                    selectedImage={selectedImage}
                    setSelectedImage={setSelectedImage}
                    filteredGalleryImagesWithIcons={filteredGalleryImagesWithIcons}
                  />
                ))}
                <div className={styles.emptyDivHeight} />
              </EVBox>
            </EVBox>
          </EVBox>
          <EVDialog open={dialogOpen}>
            <EVDialogTitle>{t('adjuster.galleryFilter')}</EVDialogTitle>
            <EVDialogContent className={styles.dialogBox}>
              {customTagsWithoutImage.map((tag) => (
                <EVListItem className={styles.checkboxItem} key={tag} onClick={() => handleClickTag(tag)}>
                  <EVCheckbox color="primary" value={tag} checked={filters[tag]} className={styles.checkbox} />
                  {tag}
                </EVListItem>
              ))}
            </EVDialogContent>
            <EVDialogActions>
              <EVButton value="cancel" onClick={handleFilterCancel}>
                {t('cancel')}
              </EVButton>
              <EVButton color="primary" onClick={handleFilterSubmit}>
                {t('done')}
              </EVButton>
            </EVDialogActions>
          </EVDialog>
        </>
      )}
      {showUtc && (
      <EVTypography className={styles.utc}>
        {t('utc.sidepanel.gallery')}
      </EVTypography>
      )}
    </EVBox>
  );
};

export default SidePanelGallery;
