import React from 'react';
import {
  EVToolbarPanel,
  EVIconButton,
  EVDeleteIcon,
  EVAddBoxOutlined,
  EVRotateRight,
  EVCropFree,
  useTheme,
} from '@eagleview/ev-comp-library';
import { bool, func, string } from 'prop-types';
import { ZoomInIcon, ZoomOutIcon } from '../manual-at-adjustment-icons';
import useStyles from './ManualAtAdjustmentMapviewerToolbar.styles';

const noop = () => {
  // This is an empty block
};

const ManualAtAdjustmentMapviewerToolbar = ({
  fitImage,
  rotateImage,
  zoomInClick,
  zoomOutClick,
  addPoint,
  deletePoint,
  showAddPoint,
  showDelete,
  addButtonToolTip,
  addButtonActivate,
}) => {
  const theme = useTheme();
  const { wrapper, deleteIcon } = useStyles();

  return (
    <div className={wrapper}>
      {showDelete && (
        <EVToolbarPanel
          displayType="vertical"
          iconStyles={{ height: 'auto', borderRadius: '4px' }}
          backgroundColor={theme.palette.common.white}
          style={{ borderRadius: '4px', marginTop: '5px' }}
          border="1px solid #ccc"
        >
          <EVIconButton onClick={deletePoint} id="manualAtAdjustmentDelete" size="small" edge="end" data-testid="deletePointBtn">
            <EVDeleteIcon className={deleteIcon} />
          </EVIconButton>
        </EVToolbarPanel>
      )}

      <EVToolbarPanel
        displayType="vertical"
        iconStyles={{ height: 'auto', borderRadius: '4px' }}
        backgroundColor={theme.palette.common.white}
        style={{ borderRadius: '4px', marginTop: '5px' }}
        border="1px solid #ccc"
      >
        {showAddPoint && (
          <EVIconButton
            onClick={addPoint}
            id="manualAtAdjustmentAddPoint"
            size="small"
            tooltip={addButtonToolTip}
            data-testid="addPointBtn"
            highlight={addButtonActivate}
          >
            <EVAddBoxOutlined />
          </EVIconButton>
        )}
        <EVIconButton onClick={fitImage} id="manualAtAdjustmentOrthoFitImage" size="small" data-testid="fitImageBtn">
          <EVCropFree />
        </EVIconButton>
        <EVIconButton onClick={rotateImage} id="manualAtAdjustmentOrthoRotateImage" size="small" data-testid="rotateImageBtn">
          <EVRotateRight />
        </EVIconButton>
      </EVToolbarPanel>

      <EVToolbarPanel
        displayType="vertical"
        iconStyles={{ height: 'auto', borderRadius: '4px' }}
        backgroundColor={theme.palette.common.white}
        style={{ borderRadius: '4px', marginTop: '5px' }}
        border="1px solid #ccc"
      >
        <EVIconButton onClick={zoomInClick} id="manualAtAdjustmentOrthoZoomIn" size="small" data-testid="zoomInBtn">
          <ZoomInIcon />
        </EVIconButton>
        <EVIconButton onClick={zoomOutClick} id="manualAtAdjustmentOrthoZoomOut" size="small" data-testid="zoomOutBtn">
          <ZoomOutIcon />
        </EVIconButton>
      </EVToolbarPanel>
    </div>
  );
};

ManualAtAdjustmentMapviewerToolbar.propTypes = {
  fitImage: func.isRequired,
  rotateImage: func.isRequired,
  zoomInClick: func.isRequired,
  zoomOutClick: func.isRequired,
  addPoint: func,
  deletePoint: func,
  showAddPoint: bool,
  showDelete: bool,
  addButtonToolTip: string,
  addButtonActivate: bool,
};

ManualAtAdjustmentMapviewerToolbar.defaultProps = {
  addPoint: noop,
  deletePoint: noop,
  showAddPoint: false,
  showDelete: false,
  addButtonToolTip: undefined,
  addButtonActivate: false,
};

export default ManualAtAdjustmentMapviewerToolbar;
