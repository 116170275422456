import {
  FETCH_CURRENT_CLAIMS,
  FETCH_CURRENT_CLAIMS_FAILURE,
  FETCH_CURRENT_CLAIMS_SUCCESS,
  FETCH_PAST_CLAIMS,
  FETCH_PAST_CLAIMS_SUCCESS,
  FETCH_PAST_CLAIMS_FAILURE,
  CLOSE_ERROR_TOAST,
  SET_TAB,
  SET_SAVED_TAB,
  SET_SEARCH_QUERY,
  SET_CURRENT_USER,
  SET_CHECKED_ASSIGNMENT_FILTER_OPTIONS,
  SET_SHOW_ASSIGNMENT_PANEL,
  SET_CLAIM_UNDER_ASSIGNMENT,
  FETCH_ADJUSTERS,
  FETCH_ADJUSTERS_SUCCESS,
  FETCH_ADJUSTERS_FAILURE,
  ASSIGN_ADJUSTER,
  ASSIGN_ADJUSTER_SUCCESS,
  ASSIGN_ADJUSTER_FAILURE,
  SET_SHOW_TRACK_PANEL,
  SET_CLAIM_BEING_TRACKED,
  GET_APPOINTMENT_DETAILS,
  GET_APPOINTMENT_DETAILS_SUCCESS,
  GET_APPOINTMENT_DETAILS_FAILURE,
  CLEAR_APPOINTMENT_DETAILS,
  RESCHEDULE_APPOINTMENT,
  RESCHEDULE_APPOINTMENT_SUCCESS,
  RESCHEDULE_APPOINTMENT_FAILURE,
  CLEAR_RESCHEDULE_APPOINTMENT_RESPONSE,
} from './Home.constants';

export const fetchCurrentClaimsAction = () => ({
  type: FETCH_CURRENT_CLAIMS,
});

export const fetchCurrentClaimsSuccessAction = (payload) => ({
  type: FETCH_CURRENT_CLAIMS_SUCCESS,
  payload,
});

export const fetchCurrentClaimsFailureAction = (payload) => ({
  type: FETCH_CURRENT_CLAIMS_FAILURE,
  payload,
});

export const fetchPastClaimsAction = () => ({
  type: FETCH_PAST_CLAIMS,
});

export const fetchPastClaimsSuccessAction = (payload) => ({
  type: FETCH_PAST_CLAIMS_SUCCESS,
  payload,
});

export const fetchPastClaimsFailureAction = (payload) => ({
  type: FETCH_PAST_CLAIMS_FAILURE,
  payload,
});

export const closeErrorToast = () => ({
  type: CLOSE_ERROR_TOAST,
});

export const setTabAction = (payload) => ({
  type: SET_TAB,
  payload,
});

export const setSavedTabAction = (payload) => ({
  type: SET_SAVED_TAB,
  payload,
});

export const setSearchQueryAction = (payload) => ({
  type: SET_SEARCH_QUERY,
  payload,
});

export const setCheckedAssignmentFilterOptionsAction = (payload) => ({
  type: SET_CHECKED_ASSIGNMENT_FILTER_OPTIONS,
  payload,
});

export const setCurrentUserAction = (payload) => ({
  type: SET_CURRENT_USER,
  payload,
});

export const setShowAssignmentPanelAction = (payload) => ({
  type: SET_SHOW_ASSIGNMENT_PANEL,
  payload,
});

export const setClaimUnderAssignmentAction = (payload) => ({
  type: SET_CLAIM_UNDER_ASSIGNMENT,
  payload,
});

export const fetchAdjustersAction = (payload) => ({
  type: FETCH_ADJUSTERS,
  payload,
});

export const fetchAdjustersSuccessAction = (payload) => ({
  type: FETCH_ADJUSTERS_SUCCESS,
  payload,
});

export const fetchAdjustersFailureAction = (payload) => ({
  type: FETCH_ADJUSTERS_FAILURE,
  payload,
});

export const assignAdjusterAction = (payload) => ({
  type: ASSIGN_ADJUSTER,
  payload,
});

export const assignAdjusterSuccessAction = (payload) => ({
  type: ASSIGN_ADJUSTER_SUCCESS,
  payload,
});

export const assignAdjusterFailureAction = (payload) => ({
  type: ASSIGN_ADJUSTER_FAILURE,
  payload,
});

export const setShowTrackPanelAction = (payload) => ({
  type: SET_SHOW_TRACK_PANEL,
  payload,
});

export const setClaimBeingTrackedAction = (payload) => ({
  type: SET_CLAIM_BEING_TRACKED,
  payload,
});

export const getAppointmentDetails = (payload) => ({
  type: GET_APPOINTMENT_DETAILS,
  payload,
});
export const getAppointmentDetailsSuccess = (payload) => ({
  type: GET_APPOINTMENT_DETAILS_SUCCESS,
  payload,
});
export const getAppointmentDetailsFailure = (payload) => ({
  type: GET_APPOINTMENT_DETAILS_FAILURE,
  payload,
});

export const clearAppointmentDetails = () => ({
  type: CLEAR_APPOINTMENT_DETAILS,
});

export const rescheduleAppointment = (payload) => ({
  type: RESCHEDULE_APPOINTMENT,
  payload,
});
export const rescheduleAppointmentSuccess = (payload) => ({
  type: RESCHEDULE_APPOINTMENT_SUCCESS,
  payload,
});
export const rescheduleAppointmentFailure = (payload) => ({
  type: RESCHEDULE_APPOINTMENT_FAILURE,
  payload,
});
export const clearRescheduleAppointmentResp = () => ({
  type: CLEAR_RESCHEDULE_APPOINTMENT_RESPONSE,
});
