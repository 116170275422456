import React from 'react';
import {
  EVPaperClipIcon, EVSwitch, EVTypography, EVBox,
} from '@eagleview/ev-comp-library';
import { bool, func } from 'prop-types';
import { useTranslation } from 'react-i18next';
import useSoftMetalStyles from 'layout/adjuster/facet-images-dialog/FacetImagesDialog.styles';
import clsx from 'clsx';
import useStyles from '../Statusbar.styles';

const ReportSwitch = ({
  included, setIncluded, disabled, isSoftMetal,
}) => {
  const { t } = useTranslation();
  const styles = useStyles();
  const softMetalStyles = useSoftMetalStyles();
  return (
    <EVBox className={clsx({ [styles.statusBarIncluded]: true, [softMetalStyles.reportSwitch]: isSoftMetal })}>
      <EVPaperClipIcon className={included ? styles.activeIcon : styles.inactiveIcon} />
      <EVTypography variant="body2">{included ? t('included') : t('notIncluded')}</EVTypography>
      <EVSwitch color="primary" disabled={disabled} checked={included} onChange={() => setIncluded(!included)} />
    </EVBox>
  );
};

ReportSwitch.propTypes = {
  included: bool.isRequired,
  setIncluded: func.isRequired,
  disabled: bool,
  isSoftMetal: bool,
};

ReportSwitch.defaultProps = {
  disabled: false,
  isSoftMetal: false,
};

export default ReportSwitch;
