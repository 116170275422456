import React, { useEffect } from 'react';
import { bool } from 'prop-types';
import { FEATURE_ENTITLEMENTS } from 'layout/entitleUser/EntitleUser.constants';
import { isEntitled } from 'utils/auth.utils';
import { useSelector } from 'react-redux';
import Home from './home/Home';
import QcHome from './qcHome/QcHome';

/**
 * Base Home layout
 */
const HomeLayout = ({ hideNavbar }) => {
  const entitlements = useSelector((state) => state.entitleUserReducer.entitlements);

  useEffect(() => {
    if (window.ev_vp && window.ev_vp.toggleSidebarHide && hideNavbar) {
      window.ev_vp.toggleSidebarHide();
    } else if (window.ev_vp && window.ev_vp.toggleSidebarShow && !hideNavbar) {
      window.ev_vp.toggleSidebarShow();
    }
  }, []);

  const showQcHome = isEntitled(entitlements, FEATURE_ENTITLEMENTS.VIEW_ASSESS_ALL_ORGANIZATION_ORDERS)
                      || (
                        isEntitled(entitlements, FEATURE_ENTITLEMENTS.VIEW_SELF_JOBS)
                        && !isEntitled(entitlements, FEATURE_ENTITLEMENTS.MANAGE_DECISIONS)
                      );
  const showHome = !showQcHome && isEntitled(entitlements, FEATURE_ENTITLEMENTS.VIEW_ASSESS_SELF_ORGANIZATION_ORDERS);

  return (
    <>
      {showHome && <Home />}
      {showQcHome && <QcHome />}
    </>
  );
};

HomeLayout.propTypes = {
  hideNavbar: bool,
};

HomeLayout.defaultProps = {
  hideNavbar: false,
};

export default HomeLayout;
