import { makeStyles } from '@eagleview/ev-comp-library';

export default makeStyles((theme) => ({
  indicator: {
    width: theme.spacing(0.75),
    height: theme.spacing(4.5),
    left: 0,
    position: 'absolute',
    backgroundColor: theme.evPalette.ocean[500],
    '.MuiMenuItem-root': {
      paddingLeft: '0px',
    },
  },
  menuItem: {
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: '27px',
    color: theme.evPalette.charcoal[900],
  },
  selectedMenu: {
    fontWeight: 600,
  },
  redDot: {
    position: 'relative',
    width: '5px',
    height: '5px',
    borderRadius: '50%',
    backgroundColor: theme.evPalette.rose[600],
    marginLeft: theme.spacing(1),
  },
  disabledMenu: {
    color: theme.evPalette.charcoal[500],
  },
  noImagery: {
    marginLeft: theme.spacing(2.25),
  },
}));
