import React, { useState } from 'react';
import { EVTab, EVTabs } from '@eagleview/ev-comp-library';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { useParams, useLocation } from 'react-router-dom';
import { bool, string } from 'prop-types';
import useStyles from './PhotosUITabs.style';
import * as action from '../adjuster/Adjuster.actions';

export const PhotosUITab = {
  GALLERY: 'GALLERY',
  MOSAIC: 'MOSAIC',
};

const PhotosUITabs = ({ tab, disableMosaic }) => {
  // utility hooks
  const styles = useStyles();
  const { id: orderId } = useParams();

  // actions
  const dispatch = useDispatch();
  const location = useLocation();
  const resetAdjusterAction = () => dispatch(action.resetAdjusterAction());

  const [currentTap, setCurrentTap] = useState(tab);

  const titleMapping = {
    [PhotosUITab.GALLERY]: 'Photos',
    [PhotosUITab.MOSAIC]: 'Mosaic',
  };

  const onChange = (val) => {
    let path = '';
    switch (val) {
      case PhotosUITab.GALLERY:
        path = `/photos-view/${orderId}`;
        break;
      case PhotosUITab.MOSAIC:
        path = `/adjuster-review/${orderId}`;
        break;
      default:
        break;
    }
    if (location.pathname !== path) {
      setCurrentTap(val);
      resetAdjusterAction();
      dispatch(push(path));
    }
  };

  return (
    <EVTabs
      className={styles.wrapper}
      value={currentTap}
      textColor="primary"
      indicatorColor="primary"
      onChange={(_, val) => onChange(val)}
    >
      <EVTab
        value={PhotosUITab.GALLERY}
        className={styles.heading}
        label={titleMapping[PhotosUITab.GALLERY]}
      />
      <EVTab
        value={PhotosUITab.MOSAIC}
        className={styles.heading}
        label={titleMapping[PhotosUITab.MOSAIC]}
        disabled={disableMosaic}
      />
    </EVTabs>
  );
};

PhotosUITabs.propTypes = {
  tab: string,
  disableMosaic: bool,
};

PhotosUITabs.defaultProps = {
  tab: PhotosUITab.GALLERY,
  disableMosaic: false,
};

export default PhotosUITabs;
