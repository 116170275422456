import { makeStyles } from '@eagleview/ev-comp-library';

export default makeStyles((theme) => ({
  autocomplete: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: `${theme.typography.pxToRem(9)} ${theme.typography.pxToRem(12)} ${theme.typography.pxToRem(8)}`,
    position: 'static',
    width: theme.typography.pxToRem(253),
    height: theme.typography.pxToRem(44),
    left: theme.typography.pxToRem(0),
    top: theme.typography.pxToRem(0),
    background: theme.evPalette.salt[100],
    borderRadius: `${theme.typography.pxToRem(4)} ${theme.typography.pxToRem(0)} ${theme.typography.pxToRem(0)} ${theme.typography.pxToRem(4)}`,
    flex: 'none',
    order: 0,
    flexGrow: 0,
    boxShadow: ({ editAllowed }) => (editAllowed ? `inset -1px 0px 0px ${theme.evPalette.black_ripple}` : 'none'),
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    padding: theme.typography.pxToRem(0),
    top: ({ accessoryPanelLocation: { top } }) => top || 0,
    // The value 340 (297 + 16 + 26 + 1) below takes into account:
    // the width of the autocomplete container (297),
    // separation between annotation and autocomplete container (16),
    // width of annotation (26) and buffer(1).
    left: ({ accessoryPanelLocation: { left }, imagePreviewRef }) => (
      imagePreviewRef.current && (left > (imagePreviewRef.current.offsetWidth - 340)) ? left - 340 : left) || 0,
    position: 'absolute',
    width: ({ editAllowed }) => (editAllowed ? theme.typography.pxToRem(297) : theme.typography.pxToRem(265)),
    height: theme.typography.pxToRem(44),
    background: theme.evPalette.salt[100],
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.14), 0px 3px 4px rgba(0, 0, 0, 0.12), 0px 1px 5px rgba(0, 0, 0, 0.2)',
    borderRadius: theme.typography.pxToRem(4),
    zIndex: 4,
  },
  dropdown: {
    width: `${theme.typography.pxToRem(251)} !important`,
    marginTop: theme.typography.pxToRem(4),
    marginBottom: theme.typography.pxToRem(4),
  },
}));
