import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import qcHomeReducer from 'layout/homeLayout/qcHome/QcHome.reducer';
import homeReducer from 'layout/homeLayout/home/Home.reducer';
import adjusterReducer from 'layout/adjuster/Adjuster.reducer';
import entitleUserReducer from 'layout/entitleUser/EntitleUser.reducer';
import manualAtAdjustmentReducerV2 from 'layout/manual-at-adjustment-v2/ManualAtAdjustment.reducer';
import manualAtMultiStructureReducer from 'layout/manual-at-multi-structure/ManualAtAdjustment.reducer';
import droneToMeasureReducer from 'layout/drone-to-measure/DroneToMeasure.reducer';
import validateLinkReducer from 'layout/validateLink/ValidateLink.reducer';
import photosReducer from 'layout/photos/Photos.reducer';
import shareOTPLoginReducer from 'layout/share-otp-login/ShareOTPLoginPage.reducer';

const createRootReducer = (history) => combineReducers({
  router: connectRouter(history),
  homeReducer,
  qcHomeReducer,
  adjusterReducer,
  entitleUserReducer,
  manualAtAdjustmentReducerV2,
  manualAtMultiStructureReducer,
  droneToMeasureReducer,
  validateLinkReducer,
  photosReducer,
  shareOTPLoginReducer,
});

export default createRootReducer;
