import { makeStyles } from '@eagleview/ev-comp-library';

export default makeStyles((theme) => ({
  panel: {
    position: 'absolute',
    right: theme.spacing(10),
    top: theme.spacing(2),
    zIndex: 4,
  },
  container: {
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    padding: `${theme.spacing(2)}px 0px`,
  },
  subContainer: {
    padding: `0px ${theme.spacing(2)}px`,
  },
  divider: {
    backgroundColor: theme.evPalette.charcoal[200],
  },
  select: {
    width: '170px',
    color: theme.palette.common.black,
  },
  selectBar: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  selectBarItem: {
    color: theme.palette.common.black,
  },
  heightBar: {
    minHeight: '48px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  iconButtons: {
    minWidth: '20px',
    padding: theme.spacing(1),
    margin: 0,
  },
  activeIconHover: {
    '&:hover': {
      stroke: `${theme.evPalette.ocean[600]} !important`,
      backgroundColor: theme.evPalette.charcoal[100],
    },
  },
  anomalyNotesButtonSelected: {
    backgroundColor: theme.evPalette.charcoal[100],
  },
  anomalyNotesButtonHighlighted: {
    color: theme.evPalette.ocean[600],
  },
  decisionTitle: {
    fontSize: theme.typography.pxToRem(12),
    fontWeight: '600',
    color: theme.evPalette.charcoal[900],
    lineHeight: theme.typography.pxToRem(14),
    marginBottom: theme.spacing(1),
  },
  annomalyTypeText: {
    color: theme.evPalette.charcoal[900],
    fontWeight: theme.typography.fontWeightBold,
  },
  anomalyMapContainer: {
    position: 'relative',
    height: '164px',
    width: '256px',
    borderRadius: '4px',
    overflow: 'hidden',
    marginBottom: '16px',
    backgroundColor: theme.palette.common.black,
  },
  anomalyMapContainerEmptyState: {
    position: 'relative',
    height: '0',
  },
  showDecisionButtons: {
    display: 'block',
  },
  hideDecisionButtons: {
    display: 'none',
  },
  anomalyCountFont: {
    fontWeight: theme.typography.fontWeightBold,
  },
  menuItemButton: {
    padding: 0,
    margin: 0,
    width: '24px',
  },
  menuItemIcon: {
    marginLeft: '10px',
    color: theme.evPalette.charcoal[600],
  },
  buttonConfirm: {
    textTransform: 'none',
    marginLeft: '10px',
  },
  buttonRemove: {
    textTransform: 'none',
    marginLeft: '10px',
    color: theme.evPalette.ocean[600],
  },
  buttonSoftMetals: {
    textTransform: 'none',
    marginTop: theme.spacing(1),
    color: theme.evPalette.ocean[600],
  },
  flexEnd: {
    justifyContent: 'flex-end',
  },
  remainingSpace: {
    flex: 1,
  },
  titleContainer: {
    display: 'flex',
  },
  decidedBgColor: {
    background: theme.evPalette.huckleberry[500],
  },
  undecidedBgColor: {
    background: theme.evPalette.charcoal[500],
  },
  titleFacetName: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(1),
    padding: `0px ${theme.spacing(0.6)}px ${theme.spacing(0.1)}px ${theme.spacing(0.6)}px`,
    minWidth: theme.spacing(3),
    textAlign: 'center',
    borderRadius: theme.spacing(0.5),
    color: theme.palette.common.white,
  },
  titleFacetAreaStyle: {
    paddingTop: theme.spacing(0.5),
    textTransform: 'none',
  },
  disable: {
    pointerEvents: 'none',
    opacity: 0.7,
  },
  cursorNotAllowed: {
    cursor: 'not-allowed',
  },
  checkboxContainer: {
    paddingTop: theme.spacing(1.25),
    minHeight: '48px',
  },
  checkbox: {
    marginLeft: '-10px',
  },
  checkboxLabel: {
    marginTop: theme.spacing(1.25),
    position: 'absolute',
  },
  hideWorkflowPanel: {
    display: 'none',
  },
  facetDecisionLabel: {
    marginTop: '5px',
  },
  expansionButton: {
    position: 'absolute',
    top: 0,
    right: 0,

  },
  expansionIcon: {
    color: theme.palette.common.white,
    fontSize: 40,
  },
  markDamage: {
    position: 'absolute',
    right: theme.spacing(2.25),
  },
  addAnomaly: {
    textTransform: 'none',
    marginLeft: '10px',
  },
  addAnomalySelected: {
    backgroundColor: theme.evPalette.ocean[100],
  },
}));
