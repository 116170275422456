import { makeStyles } from '@eagleview/ev-comp-library';

export default makeStyles((theme) => ({
  tabHeading: {
    textTransform: 'none',
  },
  homeTabs: {
    background: theme.evPalette.charcoal[100],
  },
}));
