import { makeStyles } from '@eagleview/ev-comp-library';

export default makeStyles((theme) => ({
  titleContainer: {
    display: 'flex',
    paddingLeft: theme.spacing(1),
  },
  titleIcon: {
    color: theme.evPalette.charcoal[600],
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  noteIcon: {
    color: theme.evPalette.charcoal[600],
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  infoPanel: {
    zIndex: 8,
    position: 'absolute',
    left: (P) => `calc(50% - ${P.panelWidth / 2}px)`,
    top: (P) => `calc(50% - ${P.panelHeight / 2}px)`,
    boxShadow: theme.shadows[4],
  },
  panelContainer: {
    margin: `0px ${theme.spacing(2.5)}px`,
    display: 'flex',
    flexDirection: 'column',
  },
  imageColumn: {
    display: 'flex',
    borderBottom: `1px solid ${theme.evPalette.charcoal[300]}`,
  },
  roofImg: {
    width: '170px',
    height: '130px',
    marginTop: `${theme.spacing(1)}px`,
  },
  subTitle: {
    marginTop: theme.spacing(1),
    fontSize: theme.typography.pxToRem(14),
    fontWeight: '600',
    color: theme.evPalette.charcoal[900],
    lineHeight: theme.typography.pxToRem(19),
  },
  notesColumn: {
    paddingLeft: theme.spacing(2),
    width: 'calc(100% - 170px)',
  },
  exportButton: {
    textTransform: 'none',
    float: 'right',
  },
  footer: {
    margin: `${theme.spacing(2)}px 0px`,
  },
  backdrop: {
    zIndex: 7,
    backgroundColor: 'transparent',
  },
  emailTextField: {
    width: '100%',
    overflow: 'scroll',
    /* Hide scrollbar for Chrome, Safari, and Edge */
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    /* Hide scrollbar for Firefox */
    scrollbarWidth: 'none', /* Firefox */
    '-ms-overflow-style': 'none', /* Internet Explorer 10+ */
    '& input': {
      width: '50%',
      display: 'inline',
      padding: theme.spacing(1),
      fontSize: theme.typography.pxToRem(12),
    },
    border: '1px solid rgba(0, 0, 0, 0.23)',
    borderRadius: '4px',
    '&:hover': {
      borderColor: 'rgba(0, 0, 0, 0.87)',
    },
    '&:focus-within': {
      borderColor: '#3f51b5', // This is the default primary color of Material-UI, adjust as necessary
      boxShadow: '0 0 0 2px rgba(63, 81, 181, 0.2)',
    },
  },
  emailTextFieldRoot: {
    height: '64px',
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    '& ::after': {
      borderBottom: 'none',
    },
    '& ::before': {
      borderBottom: 'none',
    },
  },
  emailTextFieldInput: {
    display: 'inline',
    padding: theme.spacing(1),
  },
  helperLabel: {
    alignItems: 'center',
    fontSize: theme.typography.pxToRem(10),
    float: 'right',
  },
  notesTextField: {
    '& textarea': {
      fontSize: theme.typography.pxToRem(12),
    },
  },
  sharedUsersListContainer: {
    marginTop: theme.spacing(1),
    height: '90px',
    overflow: 'auto',
  },
  sharedEmailId: {
    fontSize: theme.typography.pxToRem(12),
  },
  usersListContainer: {
    marginTop: theme.spacing(1),
    height: '130px',
    overflow: 'auto',
    border: `1px solid ${theme.evPalette.charcoal[300]}`,
    borderRadius: '4px',
    backgroundColor: theme.evPalette.charcoal[100],
  },
  emailChips: {
    margin: theme.spacing(0.5),
  },
}));
