import React from 'react';
import {
  EVTableCell,
} from '@eagleview/ev-comp-library';
import { useSelector } from 'react-redux';
import { string, shape } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { get } from 'lodash';
import useStyles from './QcHomeTableCell.style';
import { HEAD_CELL_KEY, TABS, DUPLICATE_INPROGRESS } from '../QcHome.constants';
import QcContextualMenu from '../qc-contextual-menu/QcContextualMenu';

/**
 * QcHomeTableCell represents one cell in HomeTable
 */
const QcHomeTableCell = ({ headCellId, row }) => {
  // utility hooks
  const styles = useStyles({ headCellId });
  const { t } = useTranslation();

  // selectors
  const currentTab = useSelector((state) => state.qcHomeReducer.currentTab);

  /**
   * Renders cell based on headCellId
   */
  const renderCell = () => {
    const testId = `${headCellId}-${get(row, 'orderId', '')}`;
    const defaultCell = (
      <EVTableCell component="div" className={styles.tableCell} data-testid={testId}>
        {row[headCellId]}
      </EVTableCell>
    );
    switch (headCellId) {
      case HEAD_CELL_KEY.menu:
        return (
          <EVTableCell component="div" className={styles.tableCell} data-testid={testId}>
            <QcContextualMenu rowData={row} />
          </EVTableCell>
        );
      case HEAD_CELL_KEY.pendingFrom:
        if (currentTab === TABS.OPEN_ORDERS && row[headCellId] === DUPLICATE_INPROGRESS.text) {
          return (
            <EVTableCell component="div" className={styles.tableCell} data-testid={testId}>
              {t(row[headCellId])}
            </EVTableCell>
          );
        }
        return defaultCell;
      default:
        return defaultCell;
    }
  };

  return renderCell();
};

QcHomeTableCell.propTypes = {
  /**
   * Represents header of that cell
   */
  headCellId: string.isRequired,
  /**
   * Represents row in QC claims table
   */
  row: shape({}).isRequired,
};

export default QcHomeTableCell;
