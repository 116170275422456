import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  EVTypography,
  EVTextField,
  EVButton,
  EVDialogTitle,
  EVDialogActions,
  EVDialog,
} from '@eagleview/ev-comp-library';
import { get } from 'lodash';
import { setShowReFlyNeededDialog, reflyRequiredAction } from '../QcHome.actions';
import useStyles from './ReflyNeededDialog.styles';

/**
 * Component used to track the order status
 */
const ReflyNeededDialog = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  // state
  const [notes, setNotes] = useState('');
  // selectors
  const showReflyNeededDialog = useSelector((state) => get(state.qcHomeReducer, 'showReflyNeededDialog', false));
  const orderToBeReflown = useSelector((state) => get(state.qcHomeReducer, 'orderToBeReflown', {}));

  // actions
  const dispatch = useDispatch();
  const setDialog = (payload) => dispatch(setShowReFlyNeededDialog(payload));
  const reflyOrder = (payload) => dispatch(reflyRequiredAction(payload));

  const handleCloseReflyNeededDialog = () => {
    setDialog({ orderToBeReflown: {}, showDialog: false });
    setNotes('');
  };

  const submit = () => {
    reflyOrder({
      orderId: orderToBeReflown.orderId,
      notes,
    });
    handleCloseReflyNeededDialog();
  };

  return (
    <EVDialog
      open={showReflyNeededDialog}
      onClose={handleCloseReflyNeededDialog}
      fullWidth
      classes={{ paper: classes.paper }}
    >
      <EVDialogTitle className={classes.title}>
        {t('qcHome.reflyNeededTitle')}
      </EVDialogTitle>
      <EVTypography className={classes.notesHeading} variant="h6">{t('qcHome.reflyNeededNotesFieldTitle')}</EVTypography>
      <EVTextField
        required
        multiline
        rows={5}
        data-testid="refly-notes"
        className={classes.notesEditField}
        value={notes}
        onChange={(e) => setNotes(e.target.value)}
        variant="outlined"
      />
      <EVTypography className={classes.required}>{t('qcHome.reflyNeededNotesRequired')}</EVTypography>
      <EVDialogActions>
        <EVButton variant="text" color="primary" onClick={handleCloseReflyNeededDialog}>
          {t('cancel')}
        </EVButton>
        <EVButton
          data-testid="submit-refly"
          variant="contained"
          color="primary"
          disabled={!notes.length}
          onClick={() => submit()}
          className={classes.confirmationButton}
        >
          {t('qcHome.reflyNeededSubmit')}
        </EVButton>
      </EVDialogActions>
    </EVDialog>
  );
};

export default ReflyNeededDialog;
