import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  EVToolbarPanel,
  EVIconButton,
  EVRoofOutline,
  EVRoofOutlineCrossedOut,
  EVAdd,
  EVRemove,
  EVDeleteIcon,
  EVMenu,
  EVMenuIcon,
  EVMenuItem,
  EVCropSquareOutlinedIcon,
  useTheme,
  EVBadge,
  EVMultiStructureIcon,
} from '@eagleview/ev-comp-library';
import {
  bool, func, shape, string,
} from 'prop-types';
import { useTranslation } from 'react-i18next';
import EntitledComponent from 'components/EntitledComponent';
import { FEATURE_ENTITLEMENTS, ASSESS_DIGITAL_DELIVERY } from 'layout/entitleUser/EntitleUser.constants';
import { isEntitled } from 'utils/auth.utils';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import { isSharedUrl } from 'utils/utils';
import { ROLE_MAP } from 'layout/adjuster/Adjuster.constants';
import useStyles from './AdjusterToolbar.styles';
import MultiStructureMenu from './multistructure-menu';
import * as action from '../Adjuster.actions';
import ConfirmDialog from '../confirm-complete-dialog/ConfirmDialog';

const AdjusterToolbar = ({
  planeConstants,
  hideAllButBaseImage,
  toggleHideAllButBaseImage,
  zoomInClick,
  zoomOutClick,
  zoomInDisabled,
  zoomOutDisabled,
  completeAndExit,
  exitOnly,
  exportImagery,
  shareReport,
  generateReport,
  isAnnotationSelected,
  handleBoxAnnotationToolbarClick,
  mapViewerCriticalAssetsReady,
  handleAnnotationDelete,
  showBoxDelete,
  orderId,
  isCaptured,
}) => {
  // actions
  const dispatch = useDispatch();
  const updateStructureVisitStatus = (payload) => dispatch(action.updateStructureVisitStatus(payload));

  // utility hooks
  const theme = useTheme();
  const { t } = useTranslation();
  const styles = useStyles();

  // selectors
  const entitlements = useSelector((state) => state.entitleUserReducer.entitlements);
  const selectedFacetObj = useSelector((state) => state.adjusterReducer.selectedFacet);
  const orderCompleted = useSelector((state) => state.adjusterReducer.orderCompleted);
  const { isMultiStructure = false, structures: structuresList = [] } = useSelector((state) => state.adjusterReducer.multiStructure);

  // local state
  const [menuOpen, setMenuOpen] = useState();
  const [multiStructureMenuOpen, setMultiStructureMenuOpen] = useState();
  const [unvisitedStructureCount, setUnvisitedStructureCount] = useState(0);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

  const isDigitalDeliveryEnabled = useSelector((state) => get(
    state.entitleUserReducer.featureFlags,
    ASSESS_DIGITAL_DELIVERY,
    false,
  ));

  const showShareAndGenerateReportMenuItems = isEntitled(entitlements, FEATURE_ENTITLEMENTS.VIEW_DECISIONS)
    || isEntitled(entitlements, FEATURE_ENTITLEMENTS.VIEW_NOTES) || isEntitled(entitlements, FEATURE_ENTITLEMENTS.VIEW_REPORTS);

  const showExportImagery = (isEntitled(entitlements, FEATURE_ENTITLEMENTS.MANAGE_REPORTS)
    || isEntitled(entitlements, FEATURE_ENTITLEMENTS.EXPORT_IMAGERY_INTERNAL));

  const showMultiStructureMenu = isMultiStructure && structuresList.length > 1
    && (isEntitled(entitlements, FEATURE_ENTITLEMENTS.VIEW_DECISIONS)
    || isEntitled(entitlements, FEATURE_ENTITLEMENTS.MANAGE_DECISIONS)
    || isEntitled(entitlements, FEATURE_ENTITLEMENTS.VIEW_ASSESS_ALL_ORGANIZATION_ORDERS));

  // eslint-disable-next-line no-nested-ternary
  const role = isEntitled(entitlements, FEATURE_ENTITLEMENTS.MANAGE_DECISIONS) ? ROLE_MAP.MANAGE_DECISIONS
    : isEntitled(entitlements, FEATURE_ENTITLEMENTS.MANAGE_QC_DECISIONS) ? ROLE_MAP.MANAGE_QC_DECISIONS : '';

  const handleMenu = (event) => {
    // eslint-disable-next-line no-extra-boolean-cast
    if (!!menuOpen) {
      setMenuOpen(null);
    } else {
      setMenuOpen(event.currentTarget);
    }
  };

  const handleMultiStructureMenu = (event) => {
    if (menuOpen) {
      setMultiStructureMenuOpen(null);
    } else {
      setMultiStructureMenuOpen(event.currentTarget);
    }
  };

  const exportImageryClick = () => {
    exportImagery();
    setMenuOpen(null);
  };

  const generateReportClick = () => {
    generateReport();
    setMenuOpen(null);
  };

  const shareReportClick = () => {
    shareReport();
    setMenuOpen(null);
  };

  // If the structure is not captured, no need to check visited status, simply mark as visited.
  const isVisitedOrNotCaptured = (structure) => (!(get(structure, 'isCaptured', true) || get(structure, 'isGroundCaptured', true))
                  || get(structure, `meta.visited.${role}`, false));

  const updateCount = () => {
    let count = 0;
    structuresList.forEach((structure) => {
      if (!isVisitedOrNotCaptured(structure)) {
        count += 1;
      }
    });
    setUnvisitedStructureCount(count);
  };

  const markVisited = (structureId) => {
    updateStructureVisitStatus({
      orderID: orderId,
      structureID: structureId,
      userRole: role,
      data: {
        visited: true,
      },
    });
  };

  useEffect(() => {
    if (structuresList.length > 0 && showMultiStructureMenu) {
      updateCount();
    }
  }, [JSON.stringify(structuresList)]);

  useEffect(() => {
    if (structuresList.length > 0 && showMultiStructureMenu) {
      const mainStructure = structuresList[0];
      if (!isVisitedOrNotCaptured(mainStructure)) {
        markVisited(get(mainStructure, 'structureID'));
      }
    }
  }, [structuresList.length]);

  return (
    <div>
      <div className={styles.toolbarButton}>
        <EVToolbarPanel
          displayType="vertical"
          iconStyles={{ height: 'auto', borderRadius: '4px' }}
          backgroundColor={theme.palette.common.white}
          style={{ borderRadius: '4px' }}
          border="1px solid #ccc"
        >
          <EVIconButton
            id="applicationMenu"
            onClick={handleMenu}
            tooltip={t('adjusterToolbarTooltip.menu')}
          >
            <EVMenuIcon />
          </EVIconButton>
        </EVToolbarPanel>
        {showMultiStructureMenu && !isEmpty(structuresList)
          && isEmpty(selectedFacetObj) && (
            <>
              <EVToolbarPanel
                displayType="vertical"
                iconStyles={{ height: 'auto', borderRadius: '4px' }}
                backgroundColor={theme.palette.common.white}
                style={{ borderRadius: '4px' }}
                border="1px solid #ccc"
                className={styles.multiStructureToolBar}
              >
                <EVBadge badgeContent={unvisitedStructureCount} color="error">
                  <EVIconButton
                    id="applicationMenu"
                    onClick={handleMultiStructureMenu}
                    tooltip={t('adjusterToolbarTooltip.menu')}
                  >
                    <EVMultiStructureIcon color={multiStructureMenuOpen ? 'primary' : 'inherit'} />
                  </EVIconButton>
                </EVBadge>
              </EVToolbarPanel>
              <MultiStructureMenu
                menuOpenEl={multiStructureMenuOpen}
                structures={structuresList}
                setMenuOpen={setMultiStructureMenuOpen}
                isVisitedOrNotCaptured={isVisitedOrNotCaptured}
                markVisited={markVisited}
              />
            </>
        )}
        <EVMenu
          anchorEl={menuOpen}
          keepMounted
          open={!!menuOpen}
          onClose={() => setMenuOpen(null)}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <EntitledComponent entitlement={FEATURE_ENTITLEMENTS.MANAGE_DECISIONS}>
            {(mapViewerCriticalAssetsReady || !isCaptured) && !orderCompleted && (
              <EVMenuItem
                id="completeAndExit"
                onClick={() => setConfirmDialogOpen(true)}
                disabled={unvisitedStructureCount > 0}
              >
                {t('adjusterMenu.completeAndExit')}
              </EVMenuItem>
            )}
          </EntitledComponent>
          <EVMenuItem id="exitOnly" onClick={exitOnly}>{t('adjusterMenu.exitOnly')}</EVMenuItem>
          {isDigitalDeliveryEnabled && showShareAndGenerateReportMenuItems && !isSharedUrl() && (
            <EVMenuItem id="shareReportMenuItem" onClick={shareReportClick}>{t('adjusterMenu.shareReport')}</EVMenuItem>
          )}
          {(mapViewerCriticalAssetsReady || !isCaptured) && showShareAndGenerateReportMenuItems && (
            <EVMenuItem id="generateReportMenuItem" onClick={generateReportClick}>{t('adjusterMenu.generateReport')}</EVMenuItem>
          )}
          {showExportImagery && (mapViewerCriticalAssetsReady || !isCaptured) && (
            <EVMenuItem id="exportImageryMenuItem" onClick={exportImageryClick}>{t('adjusterMenu.exportImagery')}</EVMenuItem>
          )}

        </EVMenu>
      </div>
      {mapViewerCriticalAssetsReady && isCaptured && !isEmpty(selectedFacetObj) && !orderCompleted && (
        <EntitledComponent entitlement={FEATURE_ENTITLEMENTS.MANAGE_DECISIONS}>
          <div className={styles.toolbarAnnotation}>
            <EVToolbarPanel
              id="annotation-controls"
              displayType="vertical"
              iconStyles={{ height: 'auto', borderRadius: '4px' }}
              className={styles.toolbarPanel}
            >
              {planeConstants && (
                <EVIconButton
                  id="boxAnnotationIconButton"
                  data-testid="boxAnnotationIconButton"
                  highlight={isAnnotationSelected}
                  tooltip={t('adjusterToolbarTooltip.boxAnnotation')}
                  onClick={() => handleBoxAnnotationToolbarClick()}
                  disabled={showBoxDelete}
                >
                  <EVCropSquareOutlinedIcon className={styles.createBoxAnnotation} />
                </EVIconButton>
              )}
              {showBoxDelete && (
                <EVIconButton
                  id="deleteBoxAnnotation"
                  color="secondary"
                  data-testid="deleteBoxAnnotation"
                  tooltip={t('adjusterToolbarTooltip.deleteBoxAnnotation')}
                  onClick={handleAnnotationDelete}
                >
                  <EVDeleteIcon className={styles.deleteBoxAnnotation} />
                </EVIconButton>
              )}
            </EVToolbarPanel>
          </div>
        </EntitledComponent>
      )}
      {mapViewerCriticalAssetsReady && isCaptured && (
        <div className={styles.toolbarButtonBottom}>
          <EVToolbarPanel
            displayType="vertical"
            iconStyles={{ height: 'auto', borderRadius: '4px' }}
            backgroundColor={theme.palette.common.white}
            style={{ borderRadius: '4px', marginBottom: '5px' }}
            border="1px solid #ccc"
          >
            <EVIconButton
              id="toggleHeatmap"
              onClick={toggleHideAllButBaseImage}
              highlight={!hideAllButBaseImage}
              tooltip={t('adjusterToolbarTooltip.heatmap')}
            >
              {!hideAllButBaseImage ? <EVRoofOutline /> : <EVRoofOutlineCrossedOut />}
            </EVIconButton>
          </EVToolbarPanel>
          <EVToolbarPanel
            displayType="vertical"
            iconStyles={{ height: 'auto', borderRadius: '4px' }}
            backgroundColor={theme.palette.common.white}
            style={{ borderRadius: '4px' }}
            border="1px solid #ccc"
          >
            <EVIconButton key="1" tooltip={t('adjusterToolbarTooltip.zoomIn')} id="zoomInButton" onClick={zoomInClick} disabled={zoomInDisabled}>
              <EVAdd />
            </EVIconButton>
            <EVIconButton key="2" tooltip={t('adjusterToolbarTooltip.zoomOut')} id="zoomOutButton" onClick={zoomOutClick} disabled={zoomOutDisabled}>
              <EVRemove />
            </EVIconButton>
          </EVToolbarPanel>
        </div>
      )}
      <ConfirmDialog
        open={confirmDialogOpen}
        handleClose={() => setConfirmDialogOpen(false)}
        handleSaveAndExit={() => {
          completeAndExit();
          setConfirmDialogOpen(false);
        }}
      />
    </div>
  );
};

AdjusterToolbar.propTypes = {
  planeConstants: shape({}).isRequired,
  hideAllButBaseImage: bool.isRequired,
  toggleHideAllButBaseImage: func.isRequired,
  zoomInClick: func.isRequired,
  zoomOutClick: func.isRequired,
  zoomInDisabled: bool.isRequired,
  zoomOutDisabled: bool.isRequired,
  isAnnotationSelected: bool.isRequired,
  handleBoxAnnotationToolbarClick: func.isRequired,
  completeAndExit: func.isRequired,
  exitOnly: func.isRequired,
  exportImagery: func.isRequired,
  shareReport: func.isRequired,
  generateReport: func.isRequired,
  mapViewerCriticalAssetsReady: bool.isRequired,
  handleAnnotationDelete: func.isRequired,
  showBoxDelete: bool.isRequired,
  orderId: string.isRequired,
  isCaptured: bool.isRequired,
};

export default AdjusterToolbar;
