import React, { useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { shape } from 'prop-types';
import {
  EVBox,
  EVTypography,
  EVDialog,
  EVExpandMore,
  EVExpandLess,
} from '@eagleview/ev-comp-library';
import { COLLAPSED_HISTORY_COUNT, TRANSLATION_MAPPING, ESTIMATED_DELIVERY_MAP } from '../Home.constants';
import * as actions from '../Home.actions';
import ClaimStatusStepper from './claim-status-stepper/ClaimStatusStepper';
import useStyles from './HomeClaimTrackPanel.styles';

/**
 * Component used to track the order status
 */
const HomeClaimTrackPanel = ({ claimData }) => {
  const { t } = useTranslation();
  const styles = useStyles();

  // selectors
  const showClaimTrackPanel = useSelector((state) => state.homeReducer.showClaimTrackPanel);

  // local state
  const [expandHistory, setExpandHistory] = useState(false);

  // actions
  const dispatch = useDispatch();
  const setShowTrackPanel = (payload) => dispatch(actions.setShowTrackPanelAction(payload));

  const disableHistoryExpansion = claimData.orderHistory.length <= COLLAPSED_HISTORY_COUNT;
  const historyData = !expandHistory || disableHistoryExpansion ? claimData.orderHistory.slice(0, COLLAPSED_HISTORY_COUNT) : claimData.orderHistory;

  const handleExpandCollapse = () => !disableHistoryExpansion && setExpandHistory(!expandHistory);

  return (
    <EVDialog open={showClaimTrackPanel} onClose={() => setShowTrackPanel(false)} data-testid="track-panel">
      <EVBox className={styles.container} display="flex" flexDirection="column">
        <EVBox px={3} py={3}>
          <EVTypography className={styles.content} variant="body2">{claimData.claimId}</EVTypography>
          <EVBox pt={1}>
            <EVTypography className={styles.label} variant="body2">{t('home.address')}</EVTypography>
            <EVTypography className={styles.content} variant="body2">{claimData.address}</EVTypography>
            <EVTypography className={styles.content} variant="body2">{claimData.address2}</EVTypography>
          </EVBox>
          {claimData.estimatedDeliveryDate && (
            <EVBox pt={3}>
              <EVTypography className={styles.label} variant="body2">{t('home.estimatedDelivery')}</EVTypography>
              <EVTypography
                className={clsx(styles.estimatedDelivery,
                  Object.keys(ESTIMATED_DELIVERY_MAP).includes(claimData.estimatedDeliveryDate) ? styles.notScheduled : styles.estimatedDeliveryDate)}
              >
                {Object.keys(ESTIMATED_DELIVERY_MAP).includes(claimData.estimatedDeliveryDate)
                  ? t(ESTIMATED_DELIVERY_MAP[claimData.estimatedDeliveryDate]) : claimData.estimatedDeliveryDate }
              </EVTypography>
            </EVBox>
          )}
        </EVBox>
        <EVBox pt={4.25}>
          <ClaimStatusStepper activeStep={claimData.status} />
        </EVBox>
        { historyData.length > 0
        && (
        <EVBox px={3} pt={7}>
          <EVTypography className={styles.trackDetailsHeader}>{t('home.progress')}</EVTypography>
          {historyData.map((history) => (
            <EVBox pt={3} key={history.reachedAt} data-testid="history-data">
              <EVTypography className={styles.label} variant="body2">{history.reachedAt}</EVTypography>
              <EVTypography className={styles.historyContent} variant="body2">{t(TRANSLATION_MAPPING[history.Status].logStatus)}</EVTypography>
              {history.appointmentDate && (
              <EVTypography className={styles.historyContent} variant="body2">
                {`${t(TRANSLATION_MAPPING[history.Status].additionalInfo)}: ${history.appointmentDate}`}
              </EVTypography>
              )}
            </EVBox>
          ))}
        </EVBox>
        )}
      </EVBox>
      <EVBox px={3} pt={3.25} pb={3}>
        <EVBox className={styles.buttonContainer} display="flex" onClick={handleExpandCollapse}>
          <EVTypography
            className={clsx(styles.label, disableHistoryExpansion ? styles.disabledButton : styles.enabledButton)}
            variant="body2"
          >
            {expandHistory ? t('home.collapse') : t('home.expand')}
          </EVTypography>
          {expandHistory
            ? <EVExpandLess className={clsx(styles.icon, styles.enabledButton)} />
            : <EVExpandMore className={clsx(styles.icon, disableHistoryExpansion ? styles.disabledButton : styles.enabledButton)} />}
        </EVBox>
      </EVBox>
    </EVDialog>
  );
};

HomeClaimTrackPanel.propTypes = {
  claimData: shape().isRequired,
};

export default HomeClaimTrackPanel;
