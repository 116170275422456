import { DEFAULT_DATE_FORMAT } from 'constants.js';
import get from 'lodash/get';
import moment from 'moment';
import { QC_CLAIMS_LIST_DATE_FORMAT, DUPLICATE_INPROGRESS, ASSESS_WORKFLOW_STATUS_MAP } from './QcHome.constants';

export const getUTCDateTime = (date) => (date ? moment.unix(date).utc().format(QC_CLAIMS_LIST_DATE_FORMAT) : '');

export const mapQcClaims = (payload) => {
  if (payload) {
    return payload.map((order) => {
      const overallCoverage = get(order, 'overallCoverage', false);
      const startTime = get(order, 'startTime', false);
      return {
        orderId: get(order, 'orderID', ''),
        claimNo: get(order, 'referenceID', ''),
        address: get(order, 'address', ''),
        overallCoverage: overallCoverage && overallCoverage.concat('%'),
        overallCoverageSortKey: overallCoverage ? parseFloat(overallCoverage) : 0.0,
        facetCoverage: get(order, 'claimsDetails.facet_coverage', ''),
        startTime: startTime ? getUTCDateTime(startTime) : '',
        startTimeTS: startTime ? parseInt(startTime, 10) : 0,
        organizationName: get(order, 'organizationName', ''),
        organizationId: get(order, 'organizationID', ''),
        status: get(order, 'status', ''),
        canOpenAsQCUser: get(order, 'manualQCEnabled', false),
        canOpenAsMeasurementTech: get(order, 'manualATEnabled', false),
        canOpenAsAdjusterReview: get(order, 'adjusterReviewEnabled', false),
        canResetJobToReview: get(order, 'resetJobEnabled', false),
        workflowStatus: get(order, 'workFlowStatus', ''),
        isD2m: get(order, 'isD2m', false),
        manualATEnabled: get(order, 'manualATEnabled', false),
        manualQCEnabled: get(order, 'manualQCEnabled', false),
        adjusterReviewEnabled: get(order, 'adjusterReviewEnabled', false),
        isMultiStructure: get(order, 'isMultiStructure', false),
        isOrderPlacedWithMeasurementFeeProduct: get(order, 'isOrderPlacedWithMeasurementFeeProduct', false),
        imageryUploadComplete: get(order, 'imageryUploadComplete', false),
      };
    });
  }
  return [];
};

export const mapEscalatedQcClaims = (payload) => {
  if (payload) {
    return payload.map((order) => {
      const overallCoverage = get(order, 'overallCoverage', false);
      const startTime = get(order, 'startTime', false);
      const workflowStatus = get(order, 'workFlowStatus', '');
      const getWorkflowStatus = () => {
        if (workflowStatus !== '') {
          return ASSESS_WORKFLOW_STATUS_MAP[workflowStatus];
        }
        return '';
      };
      return {
        orderId: get(order, 'orderID', ''),
        claimNo: get(order, 'referenceID', ''),
        address: get(order, 'address', ''),
        overallCoverage: overallCoverage && overallCoverage.concat('%'),
        overallCoverageSortKey: overallCoverage ? parseFloat(overallCoverage) : 0.0,
        facetCoverage: get(order, 'claimsDetails.facet_coverage', ''),
        startTime: startTime ? getUTCDateTime(startTime) : '',
        startTimeTS: startTime ? parseInt(startTime, 10) : 0,
        organizationName: get(order, 'organizationName', ''),
        organizationId: get(order, 'organizationID', ''),
        status: get(order, 'status', ''),
        canOpenAsQCUser: get(order, 'manualQCEnabled', false),
        canOpenAsMeasurementTech: get(order, 'manualATEnabled', false),
        canOpenAsAdjusterReview: get(order, 'adjusterReviewEnabled', false),
        canResetJobToReview: get(order, 'resetJobEnabled', false),
        workflowStatus: getWorkflowStatus(),
        isD2m: get(order, 'isD2m', false),
        manualATEnabled: get(order, 'manualATEnabled', false),
        manualQCEnabled: get(order, 'manualQCEnabled', false),
        adjusterReviewEnabled: get(order, 'adjusterReviewEnabled', false),
        isMultiStructure: get(order, 'isMultiStructure', false),
        isOrderPlacedWithMeasurementFeeProduct: get(order, 'isOrderPlacedWithMeasurementFeeProduct', false),
        imageryUploadComplete: get(order, 'imageryUploadComplete', false),
      };
    });
  }
  return [];
};

export const mapManualAtClaims = (payload) => payload.map((data) => {
  const pendingAdjustmentFrom = get(data, 'pendingAdjustmentFrom', false);
  return {
    orderId: get(data, 'orderID', ''),
    claimNo: get(data, 'claimNumber', ''),
    address: [
      get(data, 'address.StreetAddress1', false),
      get(data, 'address.City', false),
      get(data, 'address.State', false),
      get(data, 'address.Zip', false),
    ]
      .filter(Boolean)
      .join(' '),
    assignedTo: get(data, 'adjustedBy', ''),
    pendingAdjustmentFrom: pendingAdjustmentFrom ? moment.unix(get(data, 'pendingAdjustmentFrom')).format(DEFAULT_DATE_FORMAT) : '',
    pendingAdjustmentFromTS: pendingAdjustmentFrom ? parseInt(pendingAdjustmentFrom, 10) : 0,
    organizationName: get(data, 'organizationName', ''),
    organizationId: get(data, 'organizationID', ''),
    reportId: get(data, 'reportID', ''),
    status: get(data, 'status', ''),
    canOpenAsQCUser: get(data, 'manualQCEnabled', false),
    canOpenAsMeasurementTech: get(data, 'manualATEnabled', false),
    canOpenAsAdjusterReview: get(data, 'adjusterReviewEnabled', false),
    canResetJobToReview: get(data, 'resetJobEnabled', false),
    workflowStatus: get(data, 'workFlowStatus', ''),
    isD2m: get(data, 'isD2M', false),
    manualATEnabled: get(data, 'manualATEnabled', false),
    manualQCEnabled: get(data, 'manualQCEnabled', false),
    adjusterReviewEnabled: get(data, 'adjusterReviewEnabled', false),
    isMultiStructure: get(data, 'isMultiStructure', false),
    isOrderPlacedWithMeasurementFeeProduct: get(data, 'isOrderPlacedWithMeasurementFeeProduct', false),
    imageryUploadComplete: get(data, 'imageryUploadComplete', false),
  };
});

export const mapOpenOrCompletedOrders = (payload) => payload.map((order) => {
  const pendingFrom = get(order, 'DateCreated', false);
  const completedOn = get(order, 'DateUpdated', false);
  const duplicateOrderStatus = get(order, 'duplicateOrderStatus', '');
  const getPendingFrom = () => {
    if (duplicateOrderStatus === DUPLICATE_INPROGRESS.status) {
      return DUPLICATE_INPROGRESS.text;
    }
    if (pendingFrom) {
      return moment.unix(pendingFrom).format(DEFAULT_DATE_FORMAT);
    }
    return '';
  };

  return {
    orderId: get(order, 'OrderID', ''),
    claimNo: get(order, 'ReferenceID', ''),
    address: [
      get(order, 'Address.StreetAddress1', false),
      get(order, 'Address.StreetAddress2', false),
      get(order, 'Address.City', false),
      get(order, 'Address.State', false),
      get(order, 'Address.Zip', false),
    ].filter(Boolean).join(' '),
    status: get(order, 'Status', ''),
    pendingFrom: getPendingFrom(),
    pendingFromTS: pendingFrom ? parseInt(pendingFrom, 10) : 0,
    completedOn: completedOn ? moment.unix(completedOn).format(DEFAULT_DATE_FORMAT) : '',
    completedOnTS: completedOn ? parseInt(completedOn, 10) : 0,
    organizationName: get(order, 'OrganizationName', ''),
    organizationId: get(order, 'OrganizationID', ''),
    canOpenAsQCUser: get(order, 'manualQCEnabled', false),
    canOpenAsMeasurementTech: get(order, 'manualATEnabled', false),
    canOpenAsAdjusterReview: get(order, 'adjusterReviewEnabled', false),
    canResetJobToReview: get(order, 'resetJobEnabled', false),
    workflowStatus: get(order, 'workFlowStatus', ''),
    isD2m: get(order, 'isD2M', false),
    manualATEnabled: get(order, 'manualATEnabled', false),
    manualQCEnabled: get(order, 'manualQCEnabled', false),
    adjusterReviewEnabled: get(order, 'adjusterReviewEnabled', false),
    isMultiStructure: get(order, 'isMultiStructureOrder', false),
    isAssessLite: get(order, 'isAssessLite', false),
    isOrderPlacedWithMeasurementFeeProduct: get(order, 'isOrderPlacedWithMeasurementFeeProduct', false),
    affiliatedOrganizationName: order.AffiliatedOrgsInfo ? order.AffiliatedOrgsInfo.map((org) => org.Name).join(', ') : 'N/A',
    imageryUploadComplete: get(order, 'imageryUploadComplete', false),
  };
});

export const getSortByDataKey = (datakey) => {
  switch (datakey) {
    case 'startTime': return 'startTimeTS';
    case 'pendingAdjustmentFrom': return 'pendingAdjustmentFromTS';
    case 'pendingFrom': return 'pendingFromTS';
    case 'completedOn': return 'completedOnTS';
    case 'overallCoverage': return 'overallCoverageSortKey';
    default: return datakey;
  }
};

export const matchErrorCodesToMsgs = (errorCode, errorCodeMap, defaultErrorMsg) => {
  let errorMsg = errorCodeMap[errorCode];
  if (!errorMsg) {
    errorMsg = defaultErrorMsg;
  }
  return errorMsg;
};
