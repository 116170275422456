import { makeStyles } from '@eagleview/ev-comp-library';

export default makeStyles((theme) => ({
  image: {
    width: '100%',
    height: '100%',
  },
  closeIconButton: {
    padding: theme.spacing(0.5),
    position: 'absolute',
    marginTop: theme.spacing(1),
    right: theme.spacing(1),
    backgroundColor: theme.evPalette.charcoal[900],
    opacity: 0.6,
    color: theme.evPalette.salt[100],
    '&:hover': {
      backgroundColor: theme.evPalette.charcoal[900],
      opacity: 1,
    },
  },
  panel: {
    position: 'absolute',
    border: `solid 2px ${theme.evPalette.salt[100]}`,
    zIndex: 8,
  },
}));
