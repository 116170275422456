import React from 'react';
import {
  EVBox,
  EVTypography,
  EVButton,
  EVDialog,
  EVDialogContent,
  EVIconButton,
  EVClear,
  EVTooltip,
} from '@eagleview/ev-comp-library';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { get } from 'lodash';
import { bool, func, string } from 'prop-types';
import PendingImage from 'assets/riskImage.png';
import { setShowSkydioImageAction, downloadSkydioMosaicImageAction } from '../ManualAtAdjustment.actions';
import useStyles from './ManualAtHeaderBar.styles';
import { SKYDIO_IMAGE_DOWNLOAD_ERROR } from '../ManualAtAdjustment.constants';

const ManualAtHeaderBar = ({
  header, subheader, showNextButton, disableNextButton, handleNextClick, showCompleteButton, disableCompleteButton, handleCompleteButtonClick,
  showFacetImageButton, orderId,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  // actions
  const dispatch = useDispatch();
  const setShowSkydioImage = (payload) => dispatch(setShowSkydioImageAction(payload));
  const downloadSkydioMosaicImage = (payload) => dispatch(downloadSkydioMosaicImageAction(payload));

  // selectors
  const structuresDetails = useSelector((state) => get(state, 'manualAtMultiStructureReducer.structuresDetails', []));
  const selectedStructure = useSelector((state) => get(state, 'manualAtMultiStructureReducer.selectedStructure', {}));
  const structures = useSelector((state) => get(state, 'manualAtMultiStructureReducer.structures', []));
  const capturedStructures = useSelector((state) => get(state, 'manualAtMultiStructureReducer.capturedStructures', []));
  const showSkydioImage = useSelector((state) => get(state, 'manualAtMultiStructureReducer.showSkydioImage', false));
  const skydioImageUrl = useSelector((state) => get(state, 'manualAtMultiStructureReducer.skydioImageUrl', ''));

  const selectedStructureId = get(selectedStructure, 'structureID', '');
  const structureNumber = (selectedStructureId
    && structures.findIndex((structure) => structure.structureID === selectedStructureId) + 1) || '';
  const skydioMosaicPresent = get(structuresDetails[selectedStructureId], 'details.meta.SkydioMosaicPresent', false);

  const getTooltipMessage = () => {
    if (!skydioMosaicPresent) return t('manualAtAdjustment.skydioUnavailable');
    if (skydioImageUrl === SKYDIO_IMAGE_DOWNLOAD_ERROR) return t('manualAtAdjustment.skydioImageDownloadError');
    return '';
  };

  const handleFacetImageButtonClick = () => {
    setShowSkydioImage(true);
    if (!skydioImageUrl) {
      downloadSkydioMosaicImage({ orderId, structureId: selectedStructureId });
    }
  };

  return (
    <>
      <EVBox className={classes.headerContainer}>
        <EVBox>
          <EVTypography className={classes.header}>{header}</EVTypography>
          <EVTypography className={classes.subheader}>{subheader}</EVTypography>
        </EVBox>
        <EVBox className={classes.buttonContainer}>
          {showFacetImageButton && (
            <EVTooltip title={getTooltipMessage()} placement="top">
              <span>
                <EVButton
                  className={clsx(classes.button, classes.facetImageButton)}
                  variant="outlined"
                  disabled={!skydioMosaicPresent || skydioImageUrl === SKYDIO_IMAGE_DOWNLOAD_ERROR}
                  data-testid="headerBarFacetImageBtn"
                  onClick={handleFacetImageButtonClick}
                >
                  {t('manualAtAdjustment.facetImage')}
                </EVButton>
              </span>
            </EVTooltip>
          )}
          {showNextButton && (
          <EVButton
            className={classes.button}
            color="primary"
            variant="contained"
            disabled={disableNextButton}
            data-testid="headerBarNextBtn"
            onClick={handleNextClick}
          >
            {t('manualAtAdjustment.next')}
          </EVButton>
          )}
          {showCompleteButton && (
          <EVButton
            className={clsx(classes.button, classes.completeButton)}
            variant="contained"
            disabled={disableCompleteButton}
            data-testid="headerBarCompleteBtn"
            onClick={handleCompleteButtonClick}
          >
            {t('manualAtAdjustment.complete')}
          </EVButton>
          )}
        </EVBox>
      </EVBox>
      <EVDialog fullWidth maxWidth="md" open={showSkydioImage} data-testid="facetImageDialog">
        <EVBox className={classes.dialogHeader}>
          <EVBox>
            <EVTypography className={classes.header}>{t('manualAtAdjustment.facetImage')}</EVTypography>
            <EVTypography className={classes.subheader}>
              {t('manualAtAdjustment.structureNumber').replace('$num$', structureNumber).replace('$count$', capturedStructures.length)}
            </EVTypography>
          </EVBox>
          <EVIconButton className={classes.closeIconButton} onClick={() => setShowSkydioImage(false)} data-testid="closeFacetImageDialogIcon">
            <EVClear fontSize="small" />
          </EVIconButton>
        </EVBox>
        <EVDialogContent className={classes.dialogContent}>
          <img
            src={skydioImageUrl && skydioImageUrl !== SKYDIO_IMAGE_DOWNLOAD_ERROR ? skydioImageUrl : PendingImage}
            alt={t('manualAtAdjustment.skydioImageAltText')}
            draggable="false"
            className={classes.image}
            data-testid="facetCoverageMaskImage"
          />
        </EVDialogContent>
      </EVDialog>
    </>
  );
};

ManualAtHeaderBar.propTypes = {
  header: string.isRequired,
  subheader: string.isRequired,
  showNextButton: bool,
  disableNextButton: bool,
  handleNextClick: func,
  showCompleteButton: bool,
  disableCompleteButton: bool,
  handleCompleteButtonClick: func,
  showFacetImageButton: bool,
  orderId: string,
};

ManualAtHeaderBar.defaultProps = {
  showNextButton: true,
  disableNextButton: false,
  handleNextClick: () => {
    // This is an empty block
  },
  showCompleteButton: false,
  disableCompleteButton: false,
  handleCompleteButtonClick: () => {
    // This is an empty block
  },
  showFacetImageButton: false,
  orderId: '',
};

export default ManualAtHeaderBar;
