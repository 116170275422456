import {
  call, put, takeLatest, cancelled,
} from 'redux-saga/effects';
import axios from 'axios';
import * as util from 'utils/utils';
import * as action from './Home.actions';
import * as api from './Home.api';
import {
  FETCH_CURRENT_CLAIMS, FETCH_PAST_CLAIMS, FETCH_ADJUSTERS, ASSIGN_ADJUSTER,
  GET_APPOINTMENT_DETAILS, RESCHEDULE_APPOINTMENT,
} from './Home.constants';
import { getClaimOwner } from './Home.utils';

export function* loadCurrentClaimsSaga() {
  try {
    const response = yield call(api.loadCurrentClaims);
    yield put(action.fetchCurrentClaimsSuccessAction(response));
  } catch (error) {
    yield put(action.fetchCurrentClaimsFailureAction(util.parseServerError(error)));
  }
}

export function* loadPastClaimsSaga() {
  try {
    const response = yield call(api.loadPastClaims);
    yield put(action.fetchPastClaimsSuccessAction(response));
  } catch (error) {
    yield put(action.fetchPastClaimsFailureAction(util.parseServerError(error)));
  }
}

export function* fetchAdjustersSaga({ payload }) {
  const cancelSource = axios.CancelToken.source();
  try {
    const response = yield call(api.fetchAdjusters, payload, cancelSource.token);
    yield put(action.fetchAdjustersSuccessAction(response.adjusters));
  } catch (error) {
    yield put(action.fetchAdjustersFailureAction(util.parseServerError(error)));
  } finally {
    if (yield cancelled()) {
      yield call(cancelSource.cancel);
    }
  }
}

export function* assignAdjusterSaga({ payload }) {
  try {
    const response = yield call(api.assignAdjuster, payload);
    yield put(action.assignAdjusterSuccessAction({ orderId: payload.orderId, assignedTo: getClaimOwner(response) }));
  } catch (error) {
    yield put(action.assignAdjusterFailureAction(util.parseServerError(error)));
  }
}

export function* getAppointmentDetailsSaga({ payload }) {
  try {
    const response = yield call(api.getAppointmentDetails, payload);
    yield put(action.getAppointmentDetailsSuccess(response));
  } catch (error) {
    yield put(action.getAppointmentDetailsFailure(util.parseServerError(error)));
  }
}

export function* rescheduleAppointmentSaga({ payload }) {
  try {
    const response = yield call(api.rescheduleAppointment, payload);
    yield put(action.rescheduleAppointmentSuccess(response));
  } catch (error) {
    yield put(action.rescheduleAppointmentFailure(util.parseServerError(error)));
  }
}

export default function* homeSaga() {
  yield takeLatest(FETCH_CURRENT_CLAIMS, loadCurrentClaimsSaga);
  yield takeLatest(FETCH_PAST_CLAIMS, loadPastClaimsSaga);
  yield takeLatest(FETCH_ADJUSTERS, fetchAdjustersSaga);
  yield takeLatest(ASSIGN_ADJUSTER, assignAdjusterSaga);
  yield takeLatest(GET_APPOINTMENT_DETAILS, getAppointmentDetailsSaga);
  yield takeLatest(RESCHEDULE_APPOINTMENT, rescheduleAppointmentSaga);
}
