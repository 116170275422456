import get from 'lodash/get';

// adds up the count for each type of accessory and returns the count of total accessories present for an order
export const getAccessoriesCount = (accessoryData) => {
  const accessoryList = Object.values(accessoryData).reduce((accessories, accessoriesByType) => {
    accessories.push(...accessoriesByType);
    return accessories;
  }, []);
  const finalCount = accessoryList.reduce((totalCount, accessoryItem) => {
    const count = get(accessoryItem, 'AccessoryCount');
    return totalCount + count;
  }, 0);
  return finalCount;
};
