import {
  ADD_ACTIVE_COORD_PAIRINGS,
  ADD_COORD_ONE,
  ADD_COORD_TWO,
  FETCH_IMAGES,
  FETCH_IMAGES_FAILURE,
  FETCH_IMAGES_SUCCESS,
  SET_ACTIVE_IMAGE,
  CLOSE_ERROR_TOAST,
  FETCH_ORTHO_IMAGE,
  FETCH_ORTHO_IMAGE_SUCCESS,
  FETCH_ORTHO_IMAGE_FAILURE,
  FETCH_ORTHO_POLYGON,
  FETCH_ORTHO_POLYGON_SUCCESS,
  FETCH_ORTHO_POLYGON_FAILURE,
  SET_ACTIVE_VERTEX,
  SET_VIEW,
  SET_INITIAL_VIEW,
  FETCH_ORDER_DETAILS,
  FETCH_ORDER_DETAILS_SUCCESS,
  FETCH_ORDER_DETAILS_FAILURE,
  HANDLE_CHECKOUT_ERROR,
  SET_OVERVIEW_VIEW,
  SET_OVERVIEW_INITIAL_VIEW,
  SET_SELECTED_IMAGES_LIST,
  SET_IS_MIN_IMAGE_SELECTED,
  UPDATE_TASK_STATE_ID,
  COMPLETE,
  COMPLETE_FAILURE,
  COMPLETE_SUCCESS,
  REJECT,
  REJECT_SUCCESS,
  REJECT_FAILURE,
  SHOW_EMPTY_REJECT_NOTES_ERROR,
  RESET_ACTIONS_PERFORMED,
  FETCH_IMAGES_AND_DETAILS,
  FETCH_IMAGES_AND_DETAILS_FAILURE,
  SET_HIDE_UI,
  SET_INITIAL_IMAGES_DETAILS,
  SHOW_REJECT_REASON_NOT_SELECTED_ERROR,
  REMOVE_COORDINATE_PAIRING,
  SET_SRC,
  SET_SRC_DST,
  DELETE_OVERVIEW_POINT,
  ADD_COORDINATE_PAIRING,
  SHOW_NOT_ENTITLED_TO_MANUAL_AT_ERROR,
  ENABLE_READ_ONLY_MODE,
  SHOW_TOAST_MESSAGE,
} from './ManualAtAdjustment.constants';

export const fetchImagesAction = (payload) => ({
  type: FETCH_IMAGES,
  payload,
});
export const fetchImagesSuccessAction = (payload) => ({
  type: FETCH_IMAGES_SUCCESS,
  payload,
});
export const fetchImagesFailureAction = (payload) => ({
  type: FETCH_IMAGES_FAILURE,
  payload,
});

export const fetchOrthoImageAction = (payload) => ({
  type: FETCH_ORTHO_IMAGE,
  payload,
});
export const fetchOrthoImageSuccessAction = (payload) => ({
  type: FETCH_ORTHO_IMAGE_SUCCESS,
  payload,
});
export const fetchOrthoImageFailureAction = (payload) => ({
  type: FETCH_ORTHO_IMAGE_FAILURE,
  payload,
});

export const fetchOrthoPolygonAction = (payload) => ({
  type: FETCH_ORTHO_POLYGON,
  payload,
});
export const fetchOrthoPolygonSuccessAction = (payload) => ({
  type: FETCH_ORTHO_POLYGON_SUCCESS,
  payload,
});
export const fetchOrthoPolygonFailureAction = (payload) => ({
  type: FETCH_ORTHO_POLYGON_FAILURE,
  payload,
});

export const setActiveImageAction = (payload) => ({
  type: SET_ACTIVE_IMAGE,
  payload,
});

export const addCoordOneAction = (payload) => ({
  type: ADD_COORD_ONE,
  payload,
});

export const addCoordTwoAction = (payload) => ({
  type: ADD_COORD_TWO,
  payload,
});

export const addActiveCoordPairingsAction = (payload) => ({
  type: ADD_ACTIVE_COORD_PAIRINGS,
  payload,
});

export const closeErrorToast = () => ({
  type: CLOSE_ERROR_TOAST,
});

export const setActiveVertexAction = (payload) => ({
  type: SET_ACTIVE_VERTEX,
  payload,
});

export const setViewAction = (payload) => ({
  type: SET_VIEW,
  payload,
});

export const setInitialViewAction = (payload) => ({
  type: SET_INITIAL_VIEW,
  payload,
});

export const fetchOrderDetailsAction = (payload) => ({
  type: FETCH_ORDER_DETAILS,
  payload,
});

export const fetchOrderDetailsSuccessAction = (payload) => ({
  type: FETCH_ORDER_DETAILS_SUCCESS,
  payload,
});

export const fetchOrderDetailsFailureAction = (payload) => ({
  type: FETCH_ORDER_DETAILS_FAILURE,
  payload,
});

export const handleCheckoutErrorAction = (payload) => ({
  type: HANDLE_CHECKOUT_ERROR,
  payload,
});

export const setOverviewViewAction = (payload) => ({
  type: SET_OVERVIEW_VIEW,
  payload,
});

export const setOverviewViewInitialAction = (payload) => ({
  type: SET_OVERVIEW_INITIAL_VIEW,
  payload,
});

export const setSelectedImagesListAction = (payload) => ({
  type: SET_SELECTED_IMAGES_LIST,
  payload,
});

export const setIsMinImageSelectedAction = (payload) => ({
  type: SET_IS_MIN_IMAGE_SELECTED,
  payload,
});

export const updateTaskStateIdAction = (payload) => ({
  type: UPDATE_TASK_STATE_ID,
  payload,
});

export const completeAction = (payload) => ({
  type: COMPLETE,
  payload,
});

export const completeFailureAction = (payload) => ({
  type: COMPLETE_FAILURE,
  payload,
});

export const completeSuccessAction = () => ({
  type: COMPLETE_SUCCESS,
});

export const rejectManualATOrderAction = (payload) => ({
  type: REJECT,
  payload,
});

export const rejectManualATOrderSuccessAction = () => ({
  type: REJECT_SUCCESS,
});

export const rejectManualATOrderFailureAction = (payload) => ({
  type: REJECT_FAILURE,
  payload,
});

export const showEmptyRejectNotesError = () => ({
  type: SHOW_EMPTY_REJECT_NOTES_ERROR,
});
export const resetActionsPerformedAction = () => ({
  type: RESET_ACTIONS_PERFORMED,
});

export const fetchImagesAndDetailsAction = (payload) => ({
  type: FETCH_IMAGES_AND_DETAILS,
  payload,
});

export const fetchImagesAndDetailsFailureAction = (payload) => ({
  type: FETCH_IMAGES_AND_DETAILS_FAILURE,
  payload,
});

export const setHideUiAction = (payload) => ({
  type: SET_HIDE_UI,
  payload,
});

export const setInitialImagesDetails = (payload) => ({
  type: SET_INITIAL_IMAGES_DETAILS,
  payload,
});

export const showRejectReasonNotSelectedError = () => ({
  type: SHOW_REJECT_REASON_NOT_SELECTED_ERROR,
});

export const addCoordinatePairingAction = (payload) => ({
  type: ADD_COORDINATE_PAIRING,
  payload,
});

export const removeCoordinatePairingAction = (payload) => ({
  type: REMOVE_COORDINATE_PAIRING,
  payload,
});

export const setSrcAction = (payload) => ({
  type: SET_SRC,
  payload,
});

export const setSrcDstAction = (payload) => ({
  type: SET_SRC_DST,
  payload,
});

export const deleteOverviewPointAction = (payload) => ({
  type: DELETE_OVERVIEW_POINT,
  payload,
});

export const showNotEntitledToManualATError = () => ({
  type: SHOW_NOT_ENTITLED_TO_MANUAL_AT_ERROR,
});

export const enableReadOnlyMode = () => ({
  type: ENABLE_READ_ONLY_MODE,
});

export const showToastMessageAction = (payload) => ({
  type: SHOW_TOAST_MESSAGE,
  payload,
});
