import React from 'react';
import { EVStepConnector } from '@eagleview/ev-comp-library';
import useStyles from './ClaimStatusStepper.styles';

/**
 * Customized connector used to connect each step in a stepper
 */
const CustomConnector = (props) => {
  const classes = useStyles();
  return (
    <EVStepConnector
      {...props}
      classes={{
        alternativeLabel: classes.alternativeLabel,
        line: classes.line,
        active: classes.connectorActive,
        completed: classes.connectorCompleted,
      }}
    />
  );
};

export default CustomConnector;
