import React, { useState } from 'react';
import {
  EVBox,
  EVTypography,
  EVButton,
  EVDialog,
  EVDialogTitle,
  EVDialogContent,
  EVDialogContentText,
  EVDialogActions,
  EVDivider,
} from '@eagleview/ev-comp-library';
import { useSelector, useDispatch } from 'react-redux';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { bool, shape, string } from 'prop-types';
import ErrorBoundary from 'components/ErrorBoundary';
import {
  RESET_DIALOG, SKIP_AND_NEXT_DIALOG, SKIP_AND_COMPLETE_DIALOG, STATUS_COMPLETED, MANUAL_AT,
} from '../ManualAtAdjustment.constants';
import { resetOrderTiePointsAction, skipStructureAction } from '../ManualAtAdjustment.actions';
import RejectPanel from '../manual-at-adjustment-reject-panel/RejectPanel';
import useStyles from './ManualAtTopPanel.styles';

const ManualAtTopPanel = ({
  showResetButton, showEscalateButton, disableEscalateButton, orderId, showSkipStructureButton, disableSkipStructureButton, nextStructure, isLastStructure,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  // local state
  const [showRejectPanel, setShowRejectPanel] = useState(false);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [dialogContent, setDialogContent] = useState({});

  // selectors
  const orderAddress = useSelector((state) => get(state, 'manualAtMultiStructureReducer.orderAddress', ''));
  const selectedStructure = useSelector((state) => get(state, 'manualAtMultiStructureReducer.selectedStructure', {}));
  const taskStateId = useSelector((state) => get(state, 'manualAtMultiStructureReducer.taskStateId', ''));

  // actions
  const dispatch = useDispatch();
  const resetOrderTiePoints = (payload) => dispatch(resetOrderTiePointsAction(payload));
  const skipStructure = (payload) => dispatch(skipStructureAction(payload));

  const selectedStructureId = selectedStructure.structureID || '';

  const handleResetCancel = () => {
    setShowConfirmationDialog(false);
  };

  const handleResetConfirm = () => {
    resetOrderTiePoints(orderId);
    setShowConfirmationDialog(false);
  };

  const handleCancelSkip = () => {
    setShowConfirmationDialog(false);
  };

  const handleNextStructure = () => {
    skipStructure({
      orderId,
      structureId: selectedStructureId,
      requestBody: {
        state: MANUAL_AT,
      },
      nextStructure,
    });
    setShowConfirmationDialog(false);
  };

  const handleCompleteStructure = () => {
    skipStructure({
      orderId,
      structureId: selectedStructureId,
      requestBody: {
        state: MANUAL_AT,
      },
      isLastStructure,
      status: STATUS_COMPLETED,
      taskStateId,
    });
    setShowConfirmationDialog(false);
  };

  const getConfirmationDialogContent = (dialogType) => {
    switch (dialogType) {
      case RESET_DIALOG:
        return {
          dialogContentText: 'manualAtAdjustment.resetConfirmationMessage',
          confirmButtonText: 'continue',
          confirmButtonClickHandler: handleResetConfirm,
          cancelButtonText: 'cancel',
          cancelButtonClickHandler: handleResetCancel,
        };
      case SKIP_AND_NEXT_DIALOG:
        return {
          dialogTitle: 'manualAtAdjustment.skipAndNextTitle',
          dialogContentText: 'manualAtAdjustment.skipStructureConfirmationMessage',
          confirmButtonText: 'manualAtAdjustment.nextStructure',
          confirmButtonClickHandler: handleNextStructure,
          cancelButtonText: 'manualAtAdjustment.cancelSkip',
          cancelButtonClickHandler: handleCancelSkip,
        };
      case SKIP_AND_COMPLETE_DIALOG:
        return {
          type: SKIP_AND_COMPLETE_DIALOG,
          dialogTitle: 'manualAtAdjustment.skipAndCompleteTitle',
          dialogContentText: 'manualAtAdjustment.skipStructureConfirmationMessage',
          confirmButtonText: 'manualAtAdjustment.completeProperty',
          confirmButtonClickHandler: handleCompleteStructure,
          cancelButtonText: 'manualAtAdjustment.cancelSkip',
          cancelButtonClickHandler: handleCancelSkip,
        };
      default:
        return {};
    }
  };

  const handleReset = () => {
    setDialogContent(getConfirmationDialogContent(RESET_DIALOG));
    setShowConfirmationDialog(true);
  };

  const handleSkipStructure = () => {
    if (isLastStructure) {
      setDialogContent(getConfirmationDialogContent(SKIP_AND_COMPLETE_DIALOG));
    } else {
      setDialogContent(getConfirmationDialogContent(SKIP_AND_NEXT_DIALOG));
    }
    setShowConfirmationDialog(true);
  };

  return (
    <>
      <EVBox className={classes.topPanel}>
        <EVTypography className={classes.addressText}>{orderAddress}</EVTypography>
        <EVBox className={classes.buttonContainer}>
          {showResetButton && (
            <EVButton className={classes.button} variant="outlined" onClick={handleReset} data-testid="topPanelResetBtn">
              {t('manualAtAdjustment.reset')}
            </EVButton>
          )}
          {showEscalateButton && (
            <EVButton
              className={classes.button}
              variant="outlined"
              disabled={disableEscalateButton}
              onClick={() => setShowRejectPanel(true)}
              data-testid="topPanelEscalateBtn"
            >
              {t('manualAtAdjustment.escalate')}
            </EVButton>
          )}
          {showSkipStructureButton && (showResetButton || showEscalateButton) && (
            <EVDivider className={classes.divider} orientation="vertical" variant="middle" flexItem data-testid="topPanelBtnDivider" />
          )}
          {showSkipStructureButton && (
            <EVButton
              className={classes.button}
              variant="outlined"
              disabled={disableSkipStructureButton}
              onClick={handleSkipStructure}
              data-testid="topPanelSkipStructureBtn"
            >
              {t('manualAtAdjustment.skipStructure')}
            </EVButton>
          )}
        </EVBox>
      </EVBox>
      <ErrorBoundary
        componentName="Manual AT: Reject Panel"
        alertMsg={t('manualAtAdjustment.rejectPanelCrash')}
      >
        <RejectPanel closeRejectPanel={() => setShowRejectPanel(false)} openRejectPanel={showRejectPanel}> </RejectPanel>
      </ErrorBoundary>
      <EVDialog open={showConfirmationDialog} onClose={dialogContent.cancelButtonClickHandler} data-testid="confirmationDialog">
        {dialogContent.dialogTitle && <EVDialogTitle>{t(dialogContent.dialogTitle)}</EVDialogTitle>}
        <EVDialogContent>
          <EVDialogContentText>{t(dialogContent.dialogContentText)}</EVDialogContentText>
        </EVDialogContent>
        <EVDialogActions>
          <EVButton className={classes.button} onClick={dialogContent.cancelButtonClickHandler}>{t(dialogContent.cancelButtonText)}</EVButton>
          <EVButton
            className={clsx(classes.button, dialogContent.type === SKIP_AND_COMPLETE_DIALOG ? classes.completeButton : classes.primaryButton)}
            onClick={dialogContent.confirmButtonClickHandler}
            variant="contained"
          >
            {t(dialogContent.confirmButtonText)}
          </EVButton>
        </EVDialogActions>
      </EVDialog>
    </>
  );
};

ManualAtTopPanel.propTypes = {
  showResetButton: bool,
  showEscalateButton: bool,
  disableEscalateButton: bool,
  orderId: string,
  showSkipStructureButton: bool,
  disableSkipStructureButton: bool,
  nextStructure: shape(),
  isLastStructure: bool,
};

ManualAtTopPanel.defaultProps = {
  showResetButton: true,
  showEscalateButton: true,
  disableEscalateButton: false,
  orderId: '',
  showSkipStructureButton: true,
  disableSkipStructureButton: true,
  nextStructure: {},
  isLastStructure: false,
};

export default ManualAtTopPanel;
