import { makeStyles } from '@eagleview/ev-comp-library';

export default makeStyles((theme) => ({
  container: {
    maxHeight: theme.spacing(78.5),
    overflowY: 'scroll',
  },
  label: {
    color: theme.evPalette.nickel[700],
    fontWeight: theme.typography.fontWeightMedium,
    lineHeight: '19.07px',
  },
  content: {
    color: theme.evPalette.charcoal[900],
    fontWeight: theme.typography.fontWeightMedium,
    lineHeight: '19.07px',
  },
  historyContent: {
    color: theme.evPalette.charcoal[900],
    fontWeight: theme.typography.h3.fontWeight,
    lineHeight: '19.07px',
  },
  estimatedDelivery: {
    fontSize: theme.typography.body.fontSize,
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: '21.8px',
  },
  estimatedDeliveryDate: {
    color: theme.evPalette.huckleberry[500],
  },
  notScheduled: {
    color: theme.evPalette.charcoal[500],
  },
  trackDetailsHeader: {
    color: theme.evPalette.charcoal[700],
    fontWeight: theme.typography.label.fontWeight,
    fontSize: theme.typography.label.fontSize,
    lineHeight: '19.07px',
  },
  buttonContainer: {
    cursor: 'pointer',
    maxWidth: theme.spacing(10),
  },
  disabledButton: {
    color: theme.evPalette.charcoal[500],
  },
  enabledButton: {
    color: theme.evPalette.ocean[600],
  },
  icon: {
    marginLeft: theme.spacing(0.5),
  },
}));
