import axios from 'axios';
import * as util from 'utils/utils';
import {
  ASSESS_ORDER_MANAGER_API_ENDPOINT,
  OPS_PRIME_API_ENDPOINT,
  ASSESS_ORDER_MANAGER_V2_API_ENDPOINT,
} from 'constants.js';
import { STATUS_COMPLETED } from 'layout/manual-at-adjustment-v2/ManualAtAdjustment.constants';

export const fetchImagesApi = async (orderId, structureId, cancelToken, imageType = 'EXPLORE_OVERVIEW') => {
  try {
    const { data } = await axios.get(
      `${ASSESS_ORDER_MANAGER_V2_API_ENDPOINT}/manualat/orders/${orderId}/structures/${structureId}/overview-images/${imageType}`, { cancelToken },
    );
    return data;
  } catch (error) {
    if (axios.isCancel(error)) return util.cancelError();
    return util.handleHttpError(error);
  }
};

export const fetchOrthoImageMeta = async (reportId, cancelToken) => {
  try {
    const { data } = await axios.get(
      `${ASSESS_ORDER_MANAGER_API_ENDPOINT}/manualat/reports/${reportId}/ev-ortho/imeta`, { cancelToken },
    );
    return data;
  } catch (error) {
    if (axios.isCancel(error)) return util.cancelError();
    return util.handleHttpError(error);
  }
};

export const fetchOrthoImage = async (reportId, cancelToken) => {
  try {
    const { data } = await axios.get(
      `${ASSESS_ORDER_MANAGER_API_ENDPOINT}/manualat/reports/${reportId}/download-ortho`, { responseType: 'blob', cancelToken },
    );
    return data;
  } catch (error) {
    if (axios.isCancel(error)) return util.cancelError();
    return util.handleHttpError(error);
  }
};

export const fetchOrthoPolygon = async (reportId, cancelToken) => {
  try {
    const { data } = await axios.get(
      `${ASSESS_ORDER_MANAGER_API_ENDPOINT}/manualat/reports/${reportId}/roof-report`, { cancelToken },
    );
    return data;
  } catch (error) {
    if (axios.isCancel(error)) return util.cancelError();
    return util.handleHttpError(error);
  }
};

export const completeOrRejectApi = async ({ status, orderId, note = '' }) => {
  try {
    const { data: response } = await axios.put(
      `${ASSESS_ORDER_MANAGER_V2_API_ENDPOINT}/manualat/orders/${orderId}/status`,
      { status, ...(status !== STATUS_COMPLETED && { note }) },
    );
    return response;
  } catch (error) {
    return util.handleHttpError(error);
  }
};

export const saveStructureTiePointApi = async ({ data, orderId, structureId }) => {
  try {
    const { data: response } = await axios.put(
      `${ASSESS_ORDER_MANAGER_V2_API_ENDPOINT}/manualat/orders/${orderId}/structures/${structureId}`,
      data,
    );
    return response;
  } catch (error) {
    return util.handleHttpError(error);
  }
};

export const fetchOrderDetails = async (orderId) => {
  try {
    const { data: response } = await axios.get(`${ASSESS_ORDER_MANAGER_V2_API_ENDPOINT}/manualat/orders/${orderId}`);
    return response;
  } catch (error) {
    return util.handleHttpError(error);
  }
};

// legacy reportId === current orderId
export const checkoutByReportId = async (orderId) => {
  try {
    const { data } = await axios.get(`${OPS_PRIME_API_ENDPOINT}/CheckOutByReportId?ReportId=${orderId}`);
    return data;
  } catch (error) {
    if (error.response && error.response.data && error.response.data.errorCode) {
      return util.handleHttpError(error);
    }
    if (error.response) {
      throw new util.ServerError(0, error.response.data, error.response.status);
    } else {
      throw new Error();
    }
  }
};

export const fetchRoofImageApi = async (payload, cancelToken) => {
  const { orderId, structureId, imageName } = payload;
  try {
    const { data } = await axios.get(
      `${ASSESS_ORDER_MANAGER_V2_API_ENDPOINT}/manualat/orders/${orderId}/structures/${structureId}/download-overview/image/${imageName}`,
      { responseType: 'blob', cancelToken },
    );
    return { ...payload, data };
  } catch (error) {
    if (axios.isCancel(error)) return util.cancelError();
    return util.handleHttpError(error);
  }
};

export const checkInSimple = async (taskStateId) => {
  try {
    const { data: response } = await axios.get(
      `${OPS_PRIME_API_ENDPOINT}/CheckInSimple?TaskStateId=${taskStateId}`,
    );
    return response;
  } catch (error) {
    return util.handleHttpError(error);
  }
};

export const rejectSimpleNotes = async ({ taskStateId, rejectReasonId, body }) => {
  try {
    const { data: response } = await axios.post(
      `${OPS_PRIME_API_ENDPOINT}/RejectSimpleNotes?TaskStateId=${taskStateId}&RejectReasonId=${rejectReasonId}&AssignPreferredUser=false`,
      body,
    );
    return response;
  } catch (error) {
    return util.handleHttpError(error);
  }
};

export const downloadFacetImage = async (orderId, structureId, cancelToken) => {
  try {
    const { data } = await axios.get(
      `${ASSESS_ORDER_MANAGER_API_ENDPOINT}/orders/${orderId}/structures/${structureId}/selection-image`, { responseType: 'blob', cancelToken },
    );
    return data;
  } catch (error) {
    if (axios.isCancel(error)) return util.cancelError();
    return util.handleHttpError(error);
  }
};

export const fetchStructureDetailsApi = async (orderId, structureId, cancelToken) => {
  try {
    const { data } = await axios.get(`${ASSESS_ORDER_MANAGER_V2_API_ENDPOINT}/manualat/orders/${orderId}/structures/${structureId}`, { cancelToken });
    return data;
  } catch (error) {
    if (axios.isCancel(error)) return util.cancelError();
    return util.handleHttpError(error);
  }
};

export const downloadSkydioMosaicImageApi = async (orderId, structureId) => {
  try {
    const { data } = await axios.get(
      `${ASSESS_ORDER_MANAGER_API_ENDPOINT}/orders/${orderId}/structures/${structureId}/skydio-mosaic`, { responseType: 'blob' },
    );
    return data;
  } catch (error) {
    return util.handleHttpError(error);
  }
};

export const resetOrderTiePointsApi = async (orderId) => {
  try {
    const { data } = await axios.delete(`${ASSESS_ORDER_MANAGER_V2_API_ENDPOINT}/manualat/orders/${orderId}/tiepoints`);
    return data;
  } catch (error) {
    return util.handleHttpError(error);
  }
};

export const skipStructureApi = async (orderId, structureId, requestBody) => {
  try {
    const { data } = await axios.patch(
      `${ASSESS_ORDER_MANAGER_API_ENDPOINT}/orders/${orderId}/structures/${structureId}/meta/type/roof`,
      requestBody,
    );
    return data;
  } catch (error) {
    return util.handleHttpError(error);
  }
};
