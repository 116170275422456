import axios from 'axios';
import * as util from 'utils/utils';
import {
  ASSESS_ORDER_MANAGER_API_ENDPOINT,
  ASSESS_SERVICE_API_ENDPOINT,
  IW_API_ENDPOINT,
} from 'constants.js';

export const fetchImageDetailsApi = async ({ orderId }) => {
  try {
    const { data } = await axios.get(`${ASSESS_SERVICE_API_ENDPOINT}/orders/${orderId}/images?imageType=baseImage`);
    return data;
  } catch (error) {
    return util.handleHttpError(error);
  }
};

export const fetchOrthoImageMeta = async (urn) => {
  try {
    const { data } = await axios.get(`${IW_API_ENDPOINT}/images/${urn}`);
    return data;
  } catch (error) {
    return util.handleHttpError(error);
  }
};

export const fetchOrthoImage = async (orderId, imageName) => {
  try {
    const { data } = await axios.get(
      `${ASSESS_ORDER_MANAGER_API_ENDPOINT}/manualat/orders/${orderId}/download-overview/image/${imageName}`, { responseType: 'blob' },
    );
    return data;
  } catch (error) {
    return util.handleHttpError(error);
  }
};

export const fetchOrthoPolygon = async (reportId) => {
  try {
    const { data } = await axios.get(
      `${ASSESS_ORDER_MANAGER_API_ENDPOINT}/manualat/reports/${reportId}/roof-report`,
    );
    return data;
  } catch (error) {
    return util.handleHttpError(error);
  }
};

export const fetchOrderDetails = async ({ orderId }) => {
  try {
    const { data: response } = await axios.get(`${ASSESS_ORDER_MANAGER_API_ENDPOINT}/manualat/orders/${orderId}`);
    return response;
  } catch (error) {
    return util.handleHttpError(error);
  }
};
