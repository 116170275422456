import {
  FETCH_VALIDATE_LINK,
  FETCH_VALIDATE_LINK_SUCCESS,
  FETCH_VALIDATE_LINK_FAILURE,
} from './ValidateLink.constants';

const initialState = {
  loading: {},
  token: '',
  tokenValidated: false,
  validateCode: 0,
};

const homeReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_VALIDATE_LINK:
      return {
        ...state,
        loading: { ...state.loading, [type]: true },
      };
    case FETCH_VALIDATE_LINK_SUCCESS:
      return {
        ...state,
        token: payload.token || '',
        validateCode: 200,
        loading: { ...state.loading, [FETCH_VALIDATE_LINK]: false },
        tokenValidated: true,
      };
    case FETCH_VALIDATE_LINK_FAILURE:
      return {
        ...state,
        token: '',
        loading: { ...state.loading, [FETCH_VALIDATE_LINK]: false },
        validateCode: payload.errorCode,
      };
    default:
      return state;
  }
};

export default homeReducer;
