import React from 'react';
import {
  EVButton, EVTextField, EVBox, EVTypography, EVSwitch,
} from '@eagleview/ev-comp-library';
import PropTypes from 'prop-types';
import * as authUtils from 'utils/auth.utils';
import { useSelector } from 'react-redux';
import { FEATURE_ENTITLEMENTS } from 'layout/entitleUser/EntitleUser.constants';
import { useTranslation } from 'react-i18next';
import useStyles from './AnnotationDescPanel.styles';

const AnnotationDescPanel = ({
  data, updateDescription, updateInclInReport, annotationDescription, setAnnotationDescription, setDescriptionInclude,
}) => {
  // selectors
  const entitlements = useSelector((state) => state.entitleUserReducer.entitlements);

  const editAllowed = authUtils.isEntitled(entitlements, FEATURE_ENTITLEMENTS.MANAGE_DECISIONS);
  const {
    top, includeInReport, left, annotation,
  } = data;

  const classes = useStyles({
    top, left, editAllowed, description: annotationDescription,
  });
  const { t } = useTranslation();
  const handleDescriptionChange = (value) => {
    if (value.length <= 100) {
      setAnnotationDescription(value);
    }
  };

  return (
    <EVBox className={classes.container}>
      <EVBox className={classes.header}>
        <EVBox className={classes.switchContainer}>
          <EVSwitch
            inputProps={{ 'data-testid': 'include-in-report' }}
            color="primary"
            disabled={!editAllowed}
            checked={includeInReport}
            onChange={() => {
              setDescriptionInclude(!includeInReport);
              updateInclInReport({ annotation, includeInReport: !includeInReport });
            }}
          />
          <EVTypography variant="body2">{includeInReport ? t('included') : t('notIncluded')}</EVTypography>
        </EVBox>
        {editAllowed && (
        <EVButton
          data-testid="save-btn"
          className={classes.saveBtn}
          color="primary"
          disabled={false}
          onClick={() => { updateDescription({ annotation, description: annotationDescription }); }}
        >
          {t('annotatioonDescPanel.save')}
        </EVButton>
        )}
      </EVBox>
      <EVTextField
        data-testid="annotation-description"
        multiline
        rows={3}
        className={classes.notesEditField}
        disabled={!editAllowed}
        InputProps={{
          classes: {
            root: classes.notesEditFieldInput,
          },
        }}
        value={annotationDescription}
        placeholder={t('annotatioonDescPanel.descPlaceholder')}
        onChange={(e) => { handleDescriptionChange(e.target.value); }}
        variant="outlined"
        fullWidth
      />
    </EVBox>
  );
};

AnnotationDescPanel.propTypes = {
  data: PropTypes.shape({
    top: PropTypes.number,
    left: PropTypes.number,
    includeInReport: PropTypes.bool,
    annotation: PropTypes.shape({}),
  }).isRequired,
  updateDescription: PropTypes.func.isRequired,
  updateInclInReport: PropTypes.func.isRequired,
  annotationDescription: PropTypes.string.isRequired,
  setAnnotationDescription: PropTypes.func.isRequired,
  setDescriptionInclude: PropTypes.func.isRequired,
};

export default AnnotationDescPanel;
