export const QC_STATUS_MAP = {
  QC_PENDING: 'qcPanel.pending',
  QC_COMPLETED_APPROVED: 'qcPanel.approved',
  QC_NOT_NEEDED: 'qcPanel.notapplicable',
  QC_COMPLETED_REJECTED: 'qcPanel.rejected',
  QC_COMPLETED_RESCHEDULED: 'qcPanel.rescheduled',
  QC_COMPLETED_RETIEPOINT: 'qcPanel.retiepoint',
  QC_COMPLETED_ESCALATED_TECHNICAL: 'qcPanel.escalatedTechnical',
};
export const ENH_QC_STATUS_MAP = {
  QC_PENDING: '',
  QC_COMPLETED_APPROVED: '',
  QC_NOT_NEEDED: '',
  QC_COMPLETED_REJECTED: 'qcPanel.enhanced.escalated',
  QC_COMPLETED_RESCHEDULED: '',
  QC_COMPLETED_RETIEPOINT: '',
  QC_COMPLETED_ESCALATED_TECHNICAL: 'qcPanel.enhanced.escalatedTechnical',
};
export const QC_PANEL_MAP = {
  QC_PENDING: 'qcPanel.pending',
  QC_COMPLETED_APPROVED: 'qcPanel.approved',
  QC_NOT_NEEDED: 'qcPanel.notapplicable',
  QC_COMPLETED_REJECTED: 'qcPanel.pending',
  QC_COMPLETED_RESCHEDULED: 'qcPanel.rescheduled',
  QC_COMPLETED_RETIEPOINT: 'qcPanel.retiepoint',
  QC_COMPLETED_ESCALATED_TECHNICAL: 'qcPanel.pending',
};

export const QC_PENDING_DATE_FORMAT = 'MMM Do, YYYY HH:mm';
export const QC_DECISIONS = {
  PENDING: 'QC_PENDING',
  APPROVE: 'QC_COMPLETED_APPROVED',
  REJECT: 'QC_COMPLETED_REJECTED',
  RESCHEDULE: 'QC_COMPLETED_RESCHEDULED',
  RETIEPOINT: 'QC_COMPLETED_RETIEPOINT',
  ESCALATE_TECHNICAL: 'QC_COMPLETED_ESCALATED_TECHNICAL',
};

export const QC_CONFIRMATION_DIALOG_MSG = {
  QC_COMPLETED_APPROVED: 'qcPanel.confirmApprove',
  QC_COMPLETED_REJECTED: 'qcPanel.confirmReject',
  QC_COMPLETED_RESCHEDULED: 'qcPanel.confirmReschedule',
  QC_COMPLETED_RETIEPOINT: 'qcPanel.confirmRetiepoint',
  QC_COMPLETED_ESCALATED_TECHNICAL: 'qcPanel.confirmEscalateTechnical',
};
