import { makeStyles } from '@eagleview/ev-comp-library';

export default makeStyles((theme) => ({
  panel: {
    margin: 'auto',
    zIndex: 8,
    position: 'absolute',
    top: (P) => `calc(50% - ${P.infoPanelHeight / 2}px)`,
    left: (P) => `calc(50% - ${P.infoPanelWidth / 2}px)`,
    boxShadow: theme.shadows[4],
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
  },
  select: {
    minWidth: 200,
    maxHeight: '34px',
  },
  label: {
    paddingBottom: theme.spacing(1),
    fontSize: theme.typography.pxToRem(12),
    fontWeight: '600',
    color: theme.evPalette.nickel[700],
    lineHeight: theme.typography.pxToRem(18),
  },
  button: {
    width: theme.spacing(22),
    textTransform: 'none',
  },
  reportsContainer: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    borderBottom: `1px solid ${theme.evPalette.charcoal[200]}`,
    borderTop: `1px solid ${theme.evPalette.charcoal[200]}`,
  },
  report: {
    display: 'flex',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  decisionPanel: {
    maxHeight: 'calc(100vh - 302px)',
    overflow: 'scroll',
  },
  roofImgPanel: {
    marginLeft: '16px',
  },
  roofImg: {
    width: '170px',
    marginTop: `${theme.spacing(1)}px`,
  },
  decisionMultiStructure: {
    width: 'calc(100% - 170px)',
  },
  decisionMultiStructurePanel: {
    display: 'flex',
    borderBottom: `1px solid ${theme.evPalette.charcoal[300]}`,
  },
  structureName: {
    marginTop: theme.spacing(1),
    fontSize: theme.typography.pxToRem(14),
    fontWeight: '600',
    color: theme.evPalette.charcoal[900],
    lineHeight: theme.typography.pxToRem(19),
  },
  viewPreviousLink: {
    cursor: 'pointer',
    marginBottom: theme.spacing(2),
  },
  reportFooter: {
    paddingTop: theme.spacing(1),
    borderTop: `1px solid ${theme.evPalette.charcoal[300]}`,
    padding: theme.spacing(2),
    textAlign: 'end',
  },
  reportLink: {
    cursor: 'pointer',
    paddingLeft: '10px',
    color: theme.evPalette.ocean[600],
  },
  titleContainer: {
    display: 'flex',
    paddingLeft: theme.spacing(1),
  },
  noteIcon: {
    color: theme.evPalette.charcoal[600],
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  textField: {
    '& textarea': {
      fontSize: '14px',
      color: theme.evPalette.charcoal[900],
    },
  },
  formControl: {
    flex: '1 1 auto',
    paddingRight: theme.spacing(15),
  },
  versionDialog: {
    marginLeft: (P) => (P.sidePanelWidth !== '5px' ? '22%' : 0),
  },
  versionDialogContent: {
    marginBottom: theme.spacing(3),
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    padding: theme.spacing(0),
    paddingBottom: theme.spacing(0),
    border: `1px solid ${theme.evPalette.charcoal[300]}`,
  },
  versionList: {
    width: theme.spacing(50),
  },
  versionListHeaderContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: `1px solid ${theme.evPalette.charcoal[300]}`,
    backgroundColor: theme.evPalette.charcoal[200],
    cursor: 'pointer',
  },
  versionListHeader: {
    margin: `auto ${theme.spacing(1.5)}px`,
  },
  versionListContainer: {
    overflowY: 'auto',
    maxHeight: theme.spacing(52.25),
  },
  sortIcon: {
    margin: theme.spacing(0.5),
    height: theme.spacing(2.5),
  },
  backdrop: {
    zIndex: 7,
    backgroundColor: 'transparent',
  },
  helperText: {
    color: 'red',
  },
}));
