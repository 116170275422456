import React from 'react';
import { makeStyles } from '@eagleview/ev-comp-library';
import { node } from 'prop-types';

const useStyles = makeStyles({
  container: {
    height: '100%',
    overflow: 'hidden',
    position: 'relative',
  },
});

/**
 * Provides a full page container and a relative anchor
 * @param {node} children
 */
const Container = ({ children }) => {
  const styles = useStyles();
  return <div className={styles.container}>{children}</div>;
};

Container.propTypes = {
  children: node.isRequired,
};

export default Container;
