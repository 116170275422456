import { makeStyles } from '@eagleview/ev-comp-library';

export default makeStyles(() => ({
  root: {
    position: 'relative',
  },
  image: {
    width: '100%',
    height: '100vh',
    zIndex: 1,
  },
  form: {
    position: 'absolute',
    top: '36%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
}));
