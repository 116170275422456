import React, { forwardRef } from 'react';
import PropTypes, {
  func, string,
} from 'prop-types';
import {
  EVBox,
  EVTypography,
  EVTagIcon,
  EVNoteIcon,
  EVPaperClipIcon,
} from '@eagleview/ev-comp-library';
import { useSelector } from 'react-redux';
import { ASSESS_IMAGES_API_ENDPOINT } from 'constants.js';
import * as sharedUtil from 'utils/utils';
import clsx from 'clsx';
import UnableToCaptureComponent from './UnableToCaptureComponent';
import useStyles from '../AdjusterSidePanel.styles';

const GalleryImageComponent = forwardRef(({
  categoryTag, selectedImage, setSelectedImage, filteredGalleryImagesWithIcons,
}, ref) => {
  // utility hooks
  const authToken = useSelector((state) => state.adjusterReducer.accessToken);
  const styles = useStyles();

  return (
    <EVBox ref={ref} className={styles.categoryComponentBox}>
      <EVTypography className={styles.categoryComponentTitle}>{categoryTag}</EVTypography>
      <EVBox className={styles.galleryTab}>
        {selectedImage && filteredGalleryImagesWithIcons.filter((image) => image.tags.includes(categoryTag)
                && (image.utcTags === undefined
                  || image.utcTags.length === 0)).map((image) => {
          const imageIsSelected = selectedImage === image.urn;
          const hasEitherTagsNotesIncluded = image.tagsIncluded || image.notesIncluded || image.reportIncluded;
          const imageOrientation = image.orientation ? image.orientation : 360;
          return (
            <EVBox
              onClick={() => setSelectedImage(image.urn)}
              className={styles.thumbnailWrapper}
              key={image.urn}
            >
              <img
                style={{ transform: `rotate(${360 - imageOrientation}deg)` }}
                className={clsx(styles.thumbnailStyle, imageIsSelected && styles.thumbnailSelected)}
                src={`${ASSESS_IMAGES_API_ENDPOINT}/${image.urn}/thumb?width=100&height=100&${sharedUtil.getAuthParam(authToken)}`}
                alt={image.urn}
              />
              {hasEitherTagsNotesIncluded ? (
                <EVBox className={clsx(styles.thumbnailOverlay, imageIsSelected && styles.thumbnailOverlaySelected)}>
                  <EVTagIcon className={clsx(styles.tagIconStyle, !image.tagsIncluded && styles.iconOpacity)} />
                  <EVNoteIcon className={clsx(styles.tagIconStyle, !image.notesIncluded && styles.iconOpacity)} />
                  <EVPaperClipIcon className={clsx(styles.tagIconStyle, !image.reportIncluded && styles.iconOpacity)} />
                </EVBox>
              ) : null}
            </EVBox>
          );
        })}
      </EVBox>
      {filteredGalleryImagesWithIcons.filter((image) => image.tags.includes(categoryTag)
              && image.utcTags !== undefined
              && Array.isArray(image.utcTags)
              && image.utcTags.length > 0).map((image) => {
        const imageIsSelected = selectedImage === image.urn;
        const imageOrientation = image.orientation ? image.orientation : 360;
        const hasEitherTagsNotesIncluded = image.tagsIncluded || image.notesIncluded || image.reportIncluded;
        return (
          <UnableToCaptureComponent
            image={image}
            setSelectedImage={setSelectedImage}
            imageIsSelected={imageIsSelected}
            imageOrientation={imageOrientation}
            hasEitherTagsNotesIncluded={hasEitherTagsNotesIncluded}
          />
        );
      })}
    </EVBox>
  );
});

GalleryImageComponent.propTypes = {
  categoryTag: string.isRequired,
  selectedImage: string.isRequired,
  filteredGalleryImagesWithIcons: PropTypes.instanceOf(Array).isRequired,
  setSelectedImage: func.isRequired,
};

export default GalleryImageComponent;
