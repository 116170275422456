import * as types from './ManualAtAdjustment.constants';

export const fetchImagesAction = (payload) => ({
  type: types.FETCH_IMAGES,
  payload,
});
export const fetchImagesSuccessAction = (payload) => ({
  type: types.FETCH_IMAGES_SUCCESS,
  payload,
});
export const fetchImagesFailureAction = (payload) => ({
  type: types.FETCH_IMAGES_FAILURE,
  payload,
});

export const fetchOrthoImageAction = (payload) => ({
  type: types.FETCH_ORTHO_IMAGE,
  payload,
});

export const fetchOrthoImageSuccessAction = (payload) => ({
  type: types.FETCH_ORTHO_IMAGE_SUCCESS,
  payload,
});
export const fetchOrthoImageFailureAction = (payload) => ({
  type: types.FETCH_ORTHO_IMAGE_FAILURE,
  payload,
});

export const fetchOrthoPolygonAction = (payload) => ({
  type: types.FETCH_ORTHO_POLYGON,
  payload,
});
export const fetchOrthoPolygonSuccessAction = (payload) => ({
  type: types.FETCH_ORTHO_POLYGON_SUCCESS,
  payload,
});
export const fetchOrthoPolygonFailureAction = (payload) => ({
  type: types.FETCH_ORTHO_POLYGON_FAILURE,
  payload,
});

export const setActiveImageAction = (payload) => ({
  type: types.SET_ACTIVE_IMAGE,
  payload,
});

export const addCoordOneAction = (payload) => ({
  type: types.ADD_COORD_ONE,
  payload,
});

export const addCoordTwoAction = (payload) => ({
  type: types.ADD_COORD_TWO,
  payload,
});

export const addActiveCoordPairingsAction = (payload) => ({
  type: types.ADD_ACTIVE_COORD_PAIRINGS,
  payload,
});

export const closeErrorToast = () => ({
  type: types.CLOSE_ERROR_TOAST,
});

export const setActiveVertexAction = (payload) => ({
  type: types.SET_ACTIVE_VERTEX,
  payload,
});

export const setViewAction = (payload) => ({
  type: types.SET_VIEW,
  payload,
});

export const setInitialViewAction = (payload) => ({
  type: types.SET_INITIAL_VIEW,
  payload,
});

export const fetchOrderDetailsAction = (payload) => ({
  type: types.FETCH_ORDER_DETAILS,
  payload,
});

export const fetchOrderDetailsSuccessAction = (payload) => ({
  type: types.FETCH_ORDER_DETAILS_SUCCESS,
  payload,
});

export const fetchOrderDetailsFailureAction = (payload) => ({
  type: types.FETCH_ORDER_DETAILS_FAILURE,
  payload,
});

export const handleCheckoutErrorAction = (payload) => ({
  type: types.HANDLE_CHECKOUT_ERROR,
  payload,
});

export const setOverviewViewAction = (payload) => ({
  type: types.SET_OVERVIEW_VIEW,
  payload,
});

export const setOverviewViewInitialAction = (payload) => ({
  type: types.SET_OVERVIEW_INITIAL_VIEW,
  payload,
});

export const setSelectedImagesListAction = (payload) => ({
  type: types.SET_SELECTED_IMAGES_LIST,
  payload,
});

export const setIsMinImageSelectedAction = (payload) => ({
  type: types.SET_IS_MIN_IMAGE_SELECTED,
  payload,
});

export const updateTaskStateIdAction = (payload) => ({
  type: types.UPDATE_TASK_STATE_ID,
  payload,
});

export const completeAction = (payload) => ({
  type: types.COMPLETE,
  payload,
});

export const completeFailureAction = (payload) => ({
  type: types.COMPLETE_FAILURE,
  payload,
});

export const completeSuccessAction = () => ({
  type: types.COMPLETE_SUCCESS,
});

export const rejectManualATOrderAction = (payload) => ({
  type: types.REJECT,
  payload,
});

export const rejectManualATOrderSuccessAction = () => ({
  type: types.REJECT_SUCCESS,
});

export const rejectManualATOrderFailureAction = (payload) => ({
  type: types.REJECT_FAILURE,
  payload,
});

export const showEmptyRejectNotesError = () => ({
  type: types.SHOW_EMPTY_REJECT_NOTES_ERROR,
});

export const fetchImagesAndDetailsAction = (payload) => ({
  type: types.FETCH_IMAGES_AND_DETAILS,
  payload,
});

export const fetchImagesAndDetailsFailureAction = (payload) => ({
  type: types.FETCH_IMAGES_AND_DETAILS_FAILURE,
  payload,
});

export const setHideUiAction = (payload) => ({
  type: types.SET_HIDE_UI,
  payload,
});

export const setInitialImagesDetails = (payload) => ({
  type: types.SET_INITIAL_IMAGES_DETAILS,
  payload,
});

export const showRejectReasonNotSelectedError = () => ({
  type: types.SHOW_REJECT_REASON_NOT_SELECTED_ERROR,
});

export const addCoordinatePairingAction = (payload) => ({
  type: types.ADD_COORDINATE_PAIRING,
  payload,
});

export const setExploreObliqueImages = (payload) => ({
  type: types.SET_EXPLORE_OBLIQUE_IMAGES,
  payload,
});

export const removeCoordinatePairingAction = (payload) => ({
  type: types.REMOVE_COORDINATE_PAIRING,
  payload,
});

export const setSrcAction = (payload) => ({
  type: types.SET_SRC,
  payload,
});

export const setSrcDstAction = (payload) => ({
  type: types.SET_SRC_DST,
  payload,
});

export const deleteOverviewPointAction = (payload) => ({
  type: types.DELETE_OVERVIEW_POINT,
  payload,
});

export const showNotEntitledToManualATError = () => ({
  type: types.SHOW_NOT_ENTITLED_TO_MANUAL_AT_ERROR,
});

export const enableReadOnlyMode = () => ({
  type: types.ENABLE_READ_ONLY_MODE,
});

export const showToastMessageAction = (payload) => ({
  type: types.SHOW_TOAST_MESSAGE,
  payload,
});

export const fetchStructureDetailsAndImages = (payload) => ({
  type: types.FETCH_STRUCTURE_DETAILS_AND_IMAGES,
  payload,
});

export const fetchStructureDetailsAndImagesSuccess = (payload) => ({
  type: types.FETCH_STRUCTURE_DETAILS_AND_IMAGES_SUCCESS,
  payload,
});

export const fetchStructureDetailsAndImagesFailure = (payload) => ({
  type: types.FETCH_STRUCTURE_DETAILS_AND_IMAGES_FAILURE,
  payload,
});

export const downloadFacetImage = (payload) => ({
  type: types.DOWNLOAD_FACET_IMAGE,
  payload,
});

export const downloadFacetImageSuccessAction = (payload) => ({
  type: types.DOWNLOAD_FACET_IMAGE_SUCCESS,
  payload,
});

export const downloadFacetImageFailedAction = (payload) => ({
  type: types.DOWNLOAD_FACET_IMAGE_FAILURE,
  payload,
});

export const setCurrentStructureAction = (payload) => ({
  type: types.SET_CURRENT_STRUCTURE,
  payload,
});

export const mapStructureWithReportAction = (payload) => ({
  type: types.MAP_STRUCTURE_WITH_REPORT,
  payload,
});

export const setSelectedReportIdAction = (payload) => ({
  type: types.SET_SELECTED_REPORT_ID,
  payload,
});

export const setReportAndStructureTiePointData = (payload) => ({
  type: types.SET_REPORT_AND_STRUCTURE_TIE_POINT_DATA,
  payload,
});

export const clearReportAndStructureTiePointData = (payload) => ({
  type: types.CLEAR_REPORT_AND_STRUCTURE_TIE_POINT_DATA,
  payload,
});

export const saveStructureTiePoint = (payload) => ({
  type: types.SAVE_STRUCTURE_TIE_POINT,
  payload,
});

export const saveStructureTiePointSuccessAction = (payload) => ({
  type: types.SAVE_STRUCTURE_TIE_POINT_SUCCESS,
  payload,
});

export const saveStructureTiePointFailedAction = (payload) => ({
  type: types.SAVE_STRUCTURE_TIE_POINT_FAILURE,
  payload,
});

export const setShowSkydioImageAction = (payload) => ({
  type: types.SET_SHOW_SKYDIO_IMAGE,
  payload,
});

export const downloadSkydioMosaicImageAction = (payload) => ({
  type: types.DOWNLOAD_SKYDIO_MOSAIC_IMAGE,
  payload,
});

export const downloadSkydioMosaicImageSuccessAction = (payload) => ({
  type: types.DOWNLOAD_SKYDIO_MOSAIC_IMAGE_SUCCESS,
  payload,
});

export const downloadSkydioMosaicImageFailureAction = (payload) => ({
  type: types.DOWNLOAD_SKYDIO_MOSAIC_IMAGE_FAILURE,
  payload,
});

export const resetOrderTiePointsAction = (payload) => ({
  type: types.RESET_ORDER_TIE_POINTS,
  payload,
});

export const resetOrderTiePointsSuccessAction = (payload) => ({
  type: types.RESET_ORDER_TIE_POINTS_SUCCESS,
  payload,
});

export const resetOrderTiePointsFailureAction = (payload) => ({
  type: types.RESET_ORDER_TIE_POINTS_FAILURE,
  payload,
});

export const skipStructureAction = (payload) => ({
  type: types.SKIP_STRUCTURE,
  payload,
});

export const skipStructureSuccessAction = (payload) => ({
  type: types.SKIP_STRUCTURE_SUCCESS,
  payload,
});

export const skipStructureFailureAction = (payload) => ({
  type: types.SKIP_STRUCTURE_FAILURE,
  payload,
});

export const resetOrderAction = () => ({
  type: types.RESET_ORDER,
});
