import axios from 'axios';
import * as util from 'utils/utils';
import { ENTITLEMENT_SERVICE_API_ENDPOINT } from 'constants.js';

export const fetchUserEntitlements = async () => {
  try {
    const { data } = await axios.get(`${ENTITLEMENT_SERVICE_API_ENDPOINT}?appCode=assess`);
    return data;
  } catch (error) {
    return util.handleHttpError(error);
  }
};
