import {
  FETCH_QC_PENDING_CLAIMS,
  FETCH_QC_PENDING_CLAIMS_SUCCESS,
  FETCH_QC_PENDING_CLAIMS_FAILURE,
  CLOSE_QC_ERROR_TOAST,
  SET_CURRENT_TAB,
  FETCH_MANUAL_AT_CLAIMS,
  FETCH_MANUAL_AT_CLAIMS_SUCCESS,
  FETCH_MANUAL_AT_CLAIMS_FAILURE,
  FETCH_OPEN_ORDERS,
  FETCH_OPEN_ORDERS_SUCCESS,
  FETCH_OPEN_ORDERS_FAILURE,
  FETCH_COMPLETED_ORDERS,
  FETCH_COMPLETED_ORDERS_SUCCESS,
  FETCH_COMPLETED_ORDERS_FAILURE,
  UPDATE_SEARCH_TERM,
  RESET_ORDER_TO_READY_FOR_REVIEW_SUCCESS,
  RESET_ORDER_TO_READY_FOR_REVIEW_FAILURE,
  FETCH_ASSESS_APP_SETTINGS_FAILURE,
  FETCH_ASSESS_APP_SETTINGS_SUCCESS,
  FETCH_ASSESS_APP_SETTINGS,
  TOAST_DURATION,
  TOAST_SEVERITY,
  FETCH_INTERNAL_SCHEDULER_ENTITLEMENTS,
  FETCH_INTERNAL_SCHEDULER_ENTITLEMENTS_SUCCESS,
  FETCH_INTERNAL_SCHEDULER_ENTITLEMENTS_FAILURE,
  SET_SHOW_ORDER_DUPLICATION_DIALOG,
  SHOW_ERROR_TOAST,
  FETCH_ORGANIZATIONS,
  FETCH_ORGANIZATIONS_SUCCESS,
  FETCH_ORGANIZATIONS_FAILURE,
  DUPLICATE_ORDER,
  DUPLICATE_ORDER_SUCCESS,
  DUPLICATE_ORDER_FAILURE,
  SET_SHOW_DUPLICATION_CONFIRMATION,
  RESET_ORGANIZATION_LIST,
  DUPLICATE_ERROR_MAP,
  FETCH_QC_ESCALATED_CLAIMS,
  FETCH_QC_ESCALATED_CLAIMS_SUCCESS,
  FETCH_QC_ESCALATED_CLAIMS_FAILURE,
  SET_SELECTED_ROW_DATA,
  SET_SHOW_REFLY_NEEDED_DIALOG,
  REFLY_RQUIRED,
  REFLY_RQUIRED_SUCCESS,
  REFLY_RQUIRED_FAILURE,
} from './QcHome.constants';
import {
  mapQcClaims, mapManualAtClaims, mapOpenOrCompletedOrders, matchErrorCodesToMsgs, mapEscalatedQcClaims,
} from './QcHome.utils';

const initialState = {
  loading: {},
  qcEscalatedClaims: [],
  qcPendingClaims: [],
  error: {},
  showErrorToast: false,
  errorToastMessage: '',
  errorCode: 0,
  toastDuration: TOAST_DURATION.SHORT,
  toastSeverity: TOAST_SEVERITY.ERROR,
  currentTab: false,
  manualAtClaims: [],
  openOrders: [],
  completedOrders: [],
  searchTerm: '',
  assessAppSettings: {},
  internalSchedulerEntitlements: [],
  showOrderDuplicationDialog: false,
  orderToBeDuplicatedDetails: {},
  showReflyNeededDialog: false,
  orderToBeReflown: {},
  orderDuplicationOrgList: [],
  showDuplicationConfirmationDialog: false,
  duplicateOrderId: '',
  selectedRowData: null,
};

const qcHomeReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_QC_ESCALATED_CLAIMS:
    case FETCH_QC_PENDING_CLAIMS:
    case FETCH_MANUAL_AT_CLAIMS:
    case FETCH_OPEN_ORDERS:
    case FETCH_COMPLETED_ORDERS:
    case FETCH_ASSESS_APP_SETTINGS:
    case FETCH_INTERNAL_SCHEDULER_ENTITLEMENTS:
    case FETCH_ORGANIZATIONS:
    case DUPLICATE_ORDER:
    case REFLY_RQUIRED:
      return {
        ...state,
        loading: { ...state.loading, [type]: true },
      };
    case FETCH_QC_ESCALATED_CLAIMS_SUCCESS:
      return {
        ...state,
        qcEscalatedClaims: mapEscalatedQcClaims(payload),
        loading: { ...state.loading, [FETCH_QC_ESCALATED_CLAIMS]: false },
      };
    case FETCH_QC_ESCALATED_CLAIMS_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, [FETCH_QC_ESCALATED_CLAIMS]: false },
        showErrorToast: true,
        errorToastMessage: 'home.claimsListingError',
        errorCode: payload.errorCode,
        toastDuration: TOAST_DURATION.SHORT,
        toastSeverity: TOAST_SEVERITY.ERROR,
      };
    case FETCH_QC_PENDING_CLAIMS_SUCCESS:
      return {
        ...state,
        qcPendingClaims: mapQcClaims(payload),
        loading: { ...state.loading, [FETCH_QC_PENDING_CLAIMS]: false },
      };
    case FETCH_QC_PENDING_CLAIMS_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, [FETCH_QC_PENDING_CLAIMS]: false },
        showErrorToast: true,
        errorToastMessage: 'home.claimsListingError',
        errorCode: payload.errorCode,
        toastDuration: TOAST_DURATION.SHORT,
        toastSeverity: TOAST_SEVERITY.ERROR,
      };
    case FETCH_MANUAL_AT_CLAIMS_SUCCESS:
      return {
        ...state,
        manualAtClaims: mapManualAtClaims(payload),
        loading: { ...state.loading, [FETCH_MANUAL_AT_CLAIMS]: false },
      };
    case FETCH_MANUAL_AT_CLAIMS_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, [FETCH_MANUAL_AT_CLAIMS]: false },
        showErrorToast: true,
        errorToastMessage: 'home.claimsListingError',
        errorCode: payload.errorCode,
        toastDuration: TOAST_DURATION.SHORT,
        toastSeverity: TOAST_SEVERITY.ERROR,
      };
    case FETCH_OPEN_ORDERS_SUCCESS:
      return {
        ...state,
        openOrders: mapOpenOrCompletedOrders(payload),
        loading: { ...state.loading, [FETCH_OPEN_ORDERS]: false },
      };
    case FETCH_OPEN_ORDERS_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, [FETCH_OPEN_ORDERS]: false },
        showErrorToast: true,
        errorToastMessage: 'home.claimsListingError',
        errorCode: payload.errorCode,
        toastDuration: TOAST_DURATION.SHORT,
        toastSeverity: TOAST_SEVERITY.ERROR,
      };
    case FETCH_COMPLETED_ORDERS_SUCCESS:
      return {
        ...state,
        completedOrders: mapOpenOrCompletedOrders(payload),
        loading: { ...state.loading, [FETCH_COMPLETED_ORDERS]: false },
      };
    case FETCH_COMPLETED_ORDERS_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, [FETCH_COMPLETED_ORDERS]: false },
        showErrorToast: true,
        errorToastMessage: 'home.claimsListingError',
        errorCode: payload.errorCode,
        toastDuration: TOAST_DURATION.SHORT,
        toastSeverity: TOAST_SEVERITY.ERROR,
      };
    case CLOSE_QC_ERROR_TOAST: {
      return {
        ...state,
        showErrorToast: false,
      };
    }
    case SET_CURRENT_TAB: {
      return {
        ...state,
        currentTab: payload,
      };
    }
    case UPDATE_SEARCH_TERM: {
      return {
        ...state,
        searchTerm: payload,
      };
    }
    case RESET_ORDER_TO_READY_FOR_REVIEW_SUCCESS:
      return {
        ...state,
        showErrorToast: true,
        errorToastMessage: payload.retainData ? 'qcHome.resetJobRetainDataSuccessToastMsg' : 'qcHome.resetJobSuccessToastMsg',
        errorCode: payload.errorCode,
        toastDuration: TOAST_DURATION.LONG,
        toastSeverity: TOAST_SEVERITY.INFO,
      };
    case RESET_ORDER_TO_READY_FOR_REVIEW_FAILURE:
      return {
        ...state,
        showErrorToast: true,
        errorToastMessage: 'qcHome.resetJobError',
        errorCode: payload.errorCode,
        toastDuration: TOAST_DURATION.SHORT,
        toastSeverity: TOAST_SEVERITY.ERROR,
      };
    /* App settings */
    case FETCH_ASSESS_APP_SETTINGS_SUCCESS:
      return {
        ...state,
        assessAppSettings: payload,
        loading: { ...state.loading, [FETCH_ASSESS_APP_SETTINGS]: false },
      };
    case FETCH_ASSESS_APP_SETTINGS_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, [FETCH_ASSESS_APP_SETTINGS]: false },
        showErrorToast: true,
        errorToastMessage: 'adjuster.assessAppSettingsError',
        errorCode: payload.errorCode,
        toastDuration: TOAST_DURATION.SHORT,
        toastSeverity: TOAST_SEVERITY.ERROR,
      };

    case FETCH_INTERNAL_SCHEDULER_ENTITLEMENTS_SUCCESS:
      return {
        ...state,
        internalSchedulerEntitlements: payload.internalSchedulerEntitlements || [],
        loading: { ...state.loading, [FETCH_INTERNAL_SCHEDULER_ENTITLEMENTS]: false },
      };
    case FETCH_INTERNAL_SCHEDULER_ENTITLEMENTS_FAILURE:
      return {
        ...state,
        internalSchedulerEntitlements: [],
        loading: { ...state.loading, [FETCH_INTERNAL_SCHEDULER_ENTITLEMENTS]: false },
      };
    case SET_SHOW_ORDER_DUPLICATION_DIALOG:
      return {
        ...state,
        showOrderDuplicationDialog: payload.showDialog,
        orderToBeDuplicatedDetails: payload.orderToBeDuplicatedDetails,
      };
    case SET_SHOW_REFLY_NEEDED_DIALOG:
      return {
        ...state,
        showReflyNeededDialog: payload.showDialog,
        orderToBeReflown: payload.orderToBeReflown,
      };
    case SHOW_ERROR_TOAST:
      return {
        ...state,
        showErrorToast: true,
        errorToastMessage: payload,
        toastDuration: TOAST_DURATION.SHORT,
        toastSeverity: TOAST_SEVERITY.ERROR,
      };
    case FETCH_ORGANIZATIONS_SUCCESS:
      return {
        ...state,
        orderDuplicationOrgList: payload,
        loading: { ...state.loading, [FETCH_ORGANIZATIONS]: false },
      };
    case FETCH_ORGANIZATIONS_FAILURE:
      return {
        ...state,
        orderDuplicationOrgList: [],
        loading: { ...state.loading, [FETCH_ORGANIZATIONS]: false },
      };
    case DUPLICATE_ORDER_SUCCESS:
      return {
        ...state,
        duplicateOrderId: payload.duplicateOrderId,
        showDuplicationConfirmationDialog: true,
        loading: { ...state.loading, [DUPLICATE_ORDER]: false },
      };
    case DUPLICATE_ORDER_FAILURE:
      return {
        ...state,
        showErrorToast: true,
        errorToastMessage: matchErrorCodesToMsgs(payload.errorCode, DUPLICATE_ERROR_MAP, 'qcHome.orderDuplication.duplicateError'),
        errorCode: payload.errorCode,
        toastDuration: TOAST_DURATION.SHORT,
        toastSeverity: TOAST_SEVERITY.ERROR,
        loading: { ...state.loading, [DUPLICATE_ORDER]: false },
      };
    case REFLY_RQUIRED_SUCCESS:
      return {
        ...state,
        showErrorToast: true,
        errorToastMessage: 'qcHome.reflyNeeded.successToast',
        toastDuration: TOAST_DURATION.SHORT,
        toastSeverity: TOAST_SEVERITY.SUCCESS,
        errorCode: '',
        loading: { ...state.loading, [REFLY_RQUIRED]: false },
      };
    case REFLY_RQUIRED_FAILURE:
      return {
        ...state,
        showErrorToast: true,
        errorToastMessage: 'qcHome.reflyNeeded.errorToast',
        errorCode: payload.errorCode,
        toastDuration: TOAST_DURATION.SHORT,
        toastSeverity: TOAST_SEVERITY.ERROR,
        loading: { ...state.loading, [REFLY_RQUIRED]: false },
      };
    case SET_SHOW_DUPLICATION_CONFIRMATION:
      return {
        ...state,
        showDuplicationConfirmationDialog: payload,
      };
    case RESET_ORGANIZATION_LIST:
      return {
        ...state,
        orderDuplicationOrgList: [],
      };
    case SET_SELECTED_ROW_DATA:
      return {
        ...state,
        selectedRowData: payload,
      };
    default:
      return state;
  }
};

export default qcHomeReducer;
