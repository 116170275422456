import get from 'lodash/get';
import flatten from 'lodash/flatten';
import uniqWith from 'lodash/uniqWith';
import isEqual from 'lodash/isEqual';
import findIndex from 'lodash/findIndex';
import * as turf from '@turf/turf';
import { AssetFactory } from '@eagleview/mapviewer';
import { VERTEX_STYLE } from './ManualAtAdjustment.constants';

const featureCollectionToArrayOfPoints = (features) => (
  features ? uniqWith(flatten(features.map(({ geometry }) => get(geometry, 'coordinates[0]'))), isEqual) : []
);

export const featureCollectionToPoints = ({ features }) => {
  const featuresAsFlatArray = featureCollectionToArrayOfPoints(features);
  return [
    AssetFactory.getAsset({
      assetType: 'annotation',
      uuid: 'vertices',
      data: featuresAsFlatArray.map((x, i) => {
        const coordsFixedTo13 = [parseFloat(x[0].toFixed(13)), parseFloat(x[1].toFixed(13)), parseFloat(x[2].toFixed(13))];
        const id = JSON.stringify(coordsFixedTo13);
        return {
          id,
          geometries: [
            {
              type: 'Point',
              coordinates: coordsFixedTo13,
            },
          ],
          properties: {
            annotationType: 'marker',
            showLabel: true,
            name: i,
          },
          style: VERTEX_STYLE.UNSELECTED,
        };
      }),
    }),
  ];
};

export const featureCollectionToPolygon = (featureCollection) => [
  AssetFactory.getAsset({
    assetType: 'annotation',
    uuid: 'polygon',
    data: featureCollection.features.map((feature) => ({
      geometries: [feature.geometry],
      style: {
        strokeColor: '#FFFFFF',
        fillColor: '#fff',
        strokeWidth: 2,
        strokeOpacity: 0.2,
        fillOpacity: 0.3,
      },
    })),
  }),
];

export const getCenterOfAllVertices = ({ features }) => {
  const featuresAsFlatArray = featureCollectionToArrayOfPoints(features);
  const centerPoint = turf.center(
    turf.featureCollection(featuresAsFlatArray.map((x) => turf.point(x))),
  );
  const lon = get(centerPoint, 'geometry.coordinates[0]');
  const lat = get(centerPoint, 'geometry.coordinates[1]');
  if (lon && lat) return { lonLat: { lon, lat }, zoom: 20 };
  return false;
};

export const orthoImageToAsset = (response, blob) => ({
  assetType: 'image',
  uuid: 'orthoimage',
  corners: {
    ul: {
      lon: get(response, 'Clip.UL[0]'),
      lat: get(response, 'Clip.UL[1]'),
    },
    ur: {
      lon: get(response, 'Clip.UR[0]'),
      lat: get(response, 'Clip.UR[1]'),
    },
    br: {
      lon: get(response, 'Clip.LR[0]'),
      lat: get(response, 'Clip.LR[1]'),
    },
    bl: {
      lon: get(response, 'Clip.LL[0]'),
      lat: get(response, 'Clip.LL[1]'),
    },
  },

  url: window.URL.createObjectURL(new Blob([blob])),
});

export const mapCoordinatePairings = (pairings, vertices) => pairings.reduce((coordinatePairings, pair) => {
  const roofCoordinates = get(pair, 'roofPointCoordinates.geometry.coordinates');
  const imagePoints = get(pair, 'imagePoints');
  if (roofCoordinates && imagePoints) {
    coordinatePairings.push({
      id: JSON.stringify(roofCoordinates),
      index: findIndex(vertices[0].data, (vertex) => vertex.id === JSON.stringify(roofCoordinates)),
      dst: { x: roofCoordinates[0], y: roofCoordinates[1], z: roofCoordinates[2] },
      src: Object.assign({}, ...imagePoints.map((image) => {
        const { geometry, pixelCoords } = image;
        return {
          [image.image]: {
            lonLat: { lon: geometry.coordinates[0], lat: geometry.coordinates[1] },
            pixel: { x: pixelCoords[0], y: pixelCoords[1] },
          },
        };
      })),
    });
  }
  return coordinatePairings;
}, []);

export const mapCompletedVertices = (pairings) => pairings.reduce((completedVertices, pair) => {
  const roofCoordinates = get(pair, 'roofPointCoordinates.geometry.coordinates');
  if (roofCoordinates) completedVertices.push(JSON.stringify(roofCoordinates));
  return completedVertices;
}, []);

export const getXYZFromFeature = (feature) => {
  const coordinates = get(feature, 'geometry.coordinates');
  return { x: coordinates[0], y: coordinates[1], z: coordinates[2] };
};

export const getCoordsOnImage = (image, lon, lat) => {
  const max = Math.max(image.width, image.height);
  const maxTiles = Math.ceil(max / 256);
  const maxZoom = Math.log2(maxTiles);
  const mapViewerCanvasSideLength = 2 ** maxZoom * 256;
  const XfromLng = (lng) => (180.0 + lng) / 360;
  // eslint-disable-next-line
  const YfromLat = (lat) => (180.0 - (180.0 / Math.PI) * Math.log(Math.tan(Math.PI / 4.0 + (lat * Math.PI) / 360.0)))
    / 360.0;
  const x = XfromLng(lon) * mapViewerCanvasSideLength;
  const y = YfromLat(lat) * mapViewerCanvasSideLength;
  return { pixel: { x, y }, lonLat: { lon, lat } };
};

export const getAddressString = (payload) => {
  const zip = get(payload, 'address.zip', false);
  const address = [
    get(payload, 'address.streetAddress1', false),
    get(payload, 'address.city', false),
    get(payload, 'address.state', false),
  ].filter(Boolean).join(', ');
  return zip ? address.concat(' ', zip) : address;
};
