import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import get from 'lodash/get';
import {
  EVBox,
  EVTypography,
  EVIconButton,
  EVClear,
  EVTextField,
  EVSearch,
  EVSelect,
  EVMenuItem,
  EVButton,
  EVCard,
  EVBackdrop,
} from '@eagleview/ev-comp-library';
import { DUPLICATE_MULTI_STRUCTURE_ORDER } from 'layout/entitleUser/EntitleUser.constants';
import { WORKFLOW_STATUS_MAP, DUPLICATE_ORDER } from '../QcHome.constants';
import * as actions from '../QcHome.actions';
import useStyles from './OrderDuplicationDialog.styles';

/**
 * Component used to track the order status
 */
const OrderDuplicationDialog = () => {
  const { t } = useTranslation();
  const styles = useStyles();

  const initialDuplicateClaimDetails = {
    claimNo: '',
    toOrgId: '',
    workflowStatus: '',
  };

  // selectors
  const orderToBeDuplicatedDetails = useSelector((state) => state.qcHomeReducer.orderToBeDuplicatedDetails);
  const orderDuplicationOrgList = useSelector((state) => state.qcHomeReducer.orderDuplicationOrgList);
  const loading = useSelector((state) => get(state, 'qcHomeReducer.loading', {}));
  const duplicateMultiStructureOrder = useSelector((state) => get(state.entitleUserReducer.featureFlags, DUPLICATE_MULTI_STRUCTURE_ORDER, false));

  // local state
  const [duplicateClaimDetails, setDuplicateClaimDetails] = useState(initialDuplicateClaimDetails);

  // actions
  const dispatch = useDispatch();
  const setShowOrderDuplicationDialog = (payload) => dispatch(actions.setShowOrderDuplicationDialog(payload));
  const fetchOrganizations = (payload) => dispatch(actions.fetchOrganizationsAction(payload));
  const resetOrganizationList = () => dispatch(actions.resetOrganizationListAction());
  const duplicateOrder = (payload) => dispatch(actions.duplicateOrderAction(payload));

  const searchInputProps = {
    autoFocus: false,
    variant: 'outlined',
    maxLength: 50,
  };

  //  Check if DUPLICATE_MULTI_STRUCTURE_ORDER feature flag is enabled for multi structure orders
  const enableWorkflowState = orderToBeDuplicatedDetails.isMultiStructure ? duplicateMultiStructureOrder : true;

  const workflowStates = [
    {
      ...WORKFLOW_STATUS_MAP.TRIAGE_COMPLETED,
      show: enableWorkflowState,
    },
    {
      ...WORKFLOW_STATUS_MAP.MANUAL_AT_PENDING,
      show: orderToBeDuplicatedDetails.manualATEnabled,
    },
    {
      ...WORKFLOW_STATUS_MAP.MANUAL_QC_PENDING,
      show: orderToBeDuplicatedDetails.manualQCEnabled && enableWorkflowState,
    },
    {
      ...WORKFLOW_STATUS_MAP.READY_FOR_REVIEW,
      show: orderToBeDuplicatedDetails.adjusterReviewEnabled && enableWorkflowState,
    },
  ];

  useEffect(() => {
    if (orderToBeDuplicatedDetails.isMultiStructure && !enableWorkflowState) {
      setDuplicateClaimDetails({ ...duplicateClaimDetails, workflowStatus: WORKFLOW_STATUS_MAP.MANUAL_AT_PENDING.value });
    } else if (orderToBeDuplicatedDetails.adjusterReviewEnabled) {
      setDuplicateClaimDetails({ ...duplicateClaimDetails, workflowStatus: WORKFLOW_STATUS_MAP.READY_FOR_REVIEW.value });
    } else if (orderToBeDuplicatedDetails.manualQCEnabled) {
      setDuplicateClaimDetails({ ...duplicateClaimDetails, workflowStatus: WORKFLOW_STATUS_MAP.MANUAL_QC_PENDING.value });
    } else if (orderToBeDuplicatedDetails.manualATEnabled) {
      setDuplicateClaimDetails({ ...duplicateClaimDetails, workflowStatus: WORKFLOW_STATUS_MAP.MANUAL_AT_PENDING.value });
    } else {
      setDuplicateClaimDetails({ ...duplicateClaimDetails, workflowStatus: WORKFLOW_STATUS_MAP.TRIAGE_COMPLETED.value });
    }
  }, [orderToBeDuplicatedDetails]);

  const handleCloseOrderDuplicationDialog = () => {
    resetOrganizationList();
    setShowOrderDuplicationDialog({ orderToBeDuplicatedDetails: {}, showDialog: false });
  };

  const handleClaimNoChange = (e) => {
    setDuplicateClaimDetails({ ...duplicateClaimDetails, claimNo: e.target.value });
  };

  const handleSearchTermChange = (searchTerm) => {
    fetchOrganizations(searchTerm);
  };

  const handleOrgSelected = (org) => {
    setDuplicateClaimDetails({ ...duplicateClaimDetails, toOrgId: org.values.id });
  };

  const handleWorkflowStatusChange = (e) => {
    setDuplicateClaimDetails({ ...duplicateClaimDetails, workflowStatus: e.target.value });
  };

  const handleClearSearchTerm = () => {
    setDuplicateClaimDetails({ ...duplicateClaimDetails, toOrgId: '' });
    resetOrganizationList();
  };

  const handleOrderDuplication = () => {
    duplicateOrder({
      fromOrder: orderToBeDuplicatedDetails.orderId,
      fromOrg: orderToBeDuplicatedDetails.organizationId,
      toOrg: duplicateClaimDetails.toOrgId,
      toState: duplicateClaimDetails.workflowStatus,
      claimNo: duplicateClaimDetails.claimNo,
      meta: {},
    });
  };

  const SearchMessage = () => <EVTypography>{t('qcHome.orderDuplication.resultNotFound')}</EVTypography>;

  return (
    <>
      <EVCard className={styles.infoPanel} data-testid="order-duplication-panel">
        <EVBox display="flex" flexDirection="column">
          <EVBox display="flex" justifyContent="space-between" className={styles.header}>
            <EVTypography className={styles.titleText} variant="h4">{t('qcHome.createDuplicateOrder')}</EVTypography>
            <EVIconButton className={styles.closeIconButton} onClick={handleCloseOrderDuplicationDialog}>
              <EVClear classes={{ root: styles.closeIcon }} viewBox="0 0 23.25 23.25" data-testid="duplication-panel-close-icon" />
            </EVIconButton>
          </EVBox>
          <EVBox px={3} pb={1}>
            <EVBox className={styles.selectOrderMsgContainer}>
              <EVTypography className={styles.selectOrderMsg} variant="body2">{t('qcHome.orderDuplication.selectOrder')}</EVTypography>
            </EVBox>
            <EVBox pt={4} pb={1}>
              <EVTypography className={styles.claimNo}>{orderToBeDuplicatedDetails.claimNo}</EVTypography>
              <EVTypography className={styles.orderDetails} variant="h4">{orderToBeDuplicatedDetails.organizationName}</EVTypography>
              <EVTypography className={styles.orderDetails} variant="h4">{orderToBeDuplicatedDetails.workflowStatus}</EVTypography>
            </EVBox>
            <EVBox pt={2} display="flex" flexDirection="column">
              <EVBox mb={1} display="flex" flexDirection="column">
                <EVTypography className={styles.label} variant="caption">{t('qcHome.orderDuplication.claimNumber')}</EVTypography>
                <EVTextField
                  id="order-duplication-claim-no"
                  variant="outlined"
                  value={duplicateClaimDetails.claimNo}
                  onChange={handleClaimNoChange}
                  className={styles.textField}
                  data-testid="order-duplication-claim-no"
                />
              </EVBox>
              <EVBox mb={1} display="flex" flexDirection="column">
                <EVTypography className={styles.label} variant="caption">{t('qcHome.orderDuplication.organization')}</EVTypography>
                <EVSearch
                  id="order-duplication-org-search"
                  className={styles.orgSearchField}
                  classes={{ inputRoot: styles.searchInputRoot }}
                  template="organization"
                  searchInputProps={searchInputProps}
                  data={orderDuplicationOrgList}
                  suggestThreshold={3}
                  onResultSelected={handleOrgSelected}
                  noAutoSuggestResultMessage={<SearchMessage />}
                  onSearchTermChanged={handleSearchTermChange}
                  onSearchTermCleared={handleClearSearchTerm}
                  data-testid="order-duplication-org-search"
                />
              </EVBox>
              <EVBox mb={1} display="flex" flexDirection="column">
                <EVTypography className={styles.label} variant="caption">{t('qcHome.orderDuplication.workflowState')}</EVTypography>
                <EVSelect
                  value={duplicateClaimDetails.workflowStatus}
                  className={styles.select}
                  variant="outlined"
                  onChange={handleWorkflowStatusChange}
                  data-testid="order-duplication-wf-status"
                >
                  {workflowStates.map((workflowState) => workflowState.show
                  && <EVMenuItem key={workflowState.value} value={workflowState.value}>{t(workflowState.title)}</EVMenuItem>)}
                </EVSelect>
              </EVBox>
            </EVBox>
          </EVBox>
        </EVBox>
        <EVBox className={styles.footer}>
          <EVBox display="flex" className={styles.buttonBox}>
            <EVButton color="primary" className={clsx(styles.button, styles.cancelButton)} onClick={handleCloseOrderDuplicationDialog}>
              {t('cancel')}
            </EVButton>
            <EVButton
              color="primary"
              className={styles.button}
              variant="contained"
              onClick={handleOrderDuplication}
              disabled={!duplicateClaimDetails.toOrgId || !duplicateClaimDetails.workflowStatus || loading[DUPLICATE_ORDER]}
              data-testid="duplicate-button"
            >
              {t('qcHome.orderDuplication.duplicate')}
            </EVButton>
          </EVBox>
        </EVBox>
      </EVCard>
      <EVBackdrop className={styles.backdrop} open />
    </>
  );
};

export default OrderDuplicationDialog;
