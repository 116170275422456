import React from 'react';
import {
  EVBox,
  EVMenu,
  EVMenuItem,
  EVTypography,
  EVTooltip,
} from '@eagleview/ev-comp-library';
import { get } from 'lodash';
import clsx from 'clsx';
import {
  func, shape, string, arrayOf,
  bool,
} from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import useStyles from './PhotosMultiStructureMenu.styles';
import * as action from '../Photos.actions';

const PhotosMultiStructureMenu = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    menuOpenEl, setMenuOpen, structures,
  } = props;

  // selectors
  const selectedStructureId = useSelector((state) => state.photosReducer.selectedStructureId);

  // actions
  const dispatch = useDispatch();
  const setSelectedStructureId = (payload) => dispatch(action.setSelectedStructureId(payload));

  const isActiveStructure = (structureId) => (selectedStructureId === structureId);

  const changeStructure = (structure) => {
    setSelectedStructureId(get(structure, 'structureID', ''));
    setMenuOpen(null);
  };

  return (
    <EVBox className={classes.multiStructureMenu}>
      <EVMenu
        anchorEl={menuOpenEl}
        keepMounted
        open={!!menuOpenEl}
        onClose={() => setMenuOpen(null)}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        className={classes.menu}
        PaperProps={{
          style: {
            minWidth: 162,
          },
        }}
      >
        {
      structures.map((structure) => (
        <EVTooltip title={t('multiStructureMenu.disabledStructureTooltip')} disableHoverListener={structure.isCaptured} arrow>
          <EVMenuItem
            className={classes.menuItem}
            key={get(structure, 'structureID', '')}
            onClick={() => (structure.isCaptured || structure.isGroundCaptured) && changeStructure(structure)}
          >
            {isActiveStructure(get(structure, 'structureID', '')) && <EVBox className={classes.indicator} />}
            <EVTypography className={clsx({
              [classes.menuItem]: true,
              [classes.selectedMenu]: get(structure, 'structureID', ''),
              [classes.disabledMenu]: !(structure.isCaptured || structure.isGroundCaptured),
            })}
            >
              {get(structure, 'structureName', structure.structureID)}
            </EVTypography>
          </EVMenuItem>
        </EVTooltip>
      ))
      }
      </EVMenu>
    </EVBox>
  );
};

PhotosMultiStructureMenu.propTypes = {
  menuOpenEl: bool,
  setMenuOpen: func,
  structures: arrayOf(
    shape({
      structureID: string,
    }),
  ),
};

PhotosMultiStructureMenu.defaultProps = {
  menuOpenEl: false,
  setMenuOpen: () => {
    // This is an empty block
  },
  structures: [{}],
};

export default PhotosMultiStructureMenu;
