import { makeStyles } from '@eagleview/ev-comp-library';

export default makeStyles((theme) => ({
  crosshair: {
    '& canvas': {
      cursor: 'crosshair',
    },
    cursor: 'crosshair',
  },
  paper: {
    overflowY: 'scroll',
    backgroundColor: theme.evPalette.charcoal[100],
  },
  orthoImage: {
    left: '25%',
  },
  title: {
    marginBottom: '10px',
  },
}));
