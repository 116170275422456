import { useState, useRef, useEffect } from 'react';

export default () => {
  const [size, setSize] = useState({ width: 0, height: 0 });
  const elRef = useRef();
  const observer = useRef(
    new ResizeObserver((entries) => {
      const { width, height } = entries[0].contentRect;
      setSize({ width, height });
    }),
  );

  useEffect(() => {
    if (elRef.current) {
      observer.current.observe(elRef.current.mapViewer.mapContainer);
    }

    return () => {
      if (elRef.current) {
        observer.current.unobserve(elRef.current.mapViewer.mapContainer);
      }
    };
  }, [elRef.current, observer]);

  return { elRef, size };
};
