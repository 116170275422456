import { makeStyles } from '@eagleview/ev-comp-library';

export default makeStyles((theme) => ({
  infoPanel: {
    margin: 'auto',
    top: theme.spacing(2),
    zIndex: 5,
    position: 'absolute',
    right: '100px',
  },
  content: {
    clear: 'both',
    marginBottom: theme.spacing(1),
  },
  titleContainer: {
    display: 'flex',
    padding: '0px 0px 0px 10px',
  },
  footer: {
    padding: `${theme.spacing(1)}px`,
    display: 'flex',
    justifyContent: 'space-evenly',
  },
  tableParent: {
    margin: 'auto',
    textAlign: 'right',
    height: theme.spacing(28),
    width: '100%',
    marginBottom: theme.spacing(2.5),
  },
  text: {
    text: 'wrap',
    padding: '10px',
    fontSize: '0.85rem',
    textAlign: 'center',
  },
  statusText: {
    text: 'wrap',
    padding: `${theme.spacing(0)}px ${theme.spacing(1.25)}px ${theme.spacing(0.6)}px ${theme.spacing(1.25)}px`,
    fontSize: '0.85rem',
    textAlign: 'center',
  },
  infoPanelFontSize: {
    fontSize: '0.90rem',
  },
  infoPanelTypeFontSize: {
    marginLeft: theme.spacing(1),
    fontSize: '0.85rem',
    color: theme.evPalette.iceberg[500],
  },
  buttonsContainer: {
    display: 'flex',
    padding: `${theme.spacing(1.5)}px ${theme.spacing(0.5)}px`,
  },
  enhancedbuttonsContainer: {
    display: 'inline',
    padding: `${theme.spacing(1.5)}px ${theme.spacing(0.5)}px`,
  },
  headingButtons: {
    width: '50%',
    display: 'flex',
    margin: '0 0 0 30%',
    justifyContent: 'space-between',
  },
  enhancedheadingButtons: {
    display: 'flex',
    justifyContent: 'space-evenly',
  },
  qcAcceptButton: {
    fontSize: '13px',
    maxWidth: '50px',
    maxHeight: '30px',
    boxShadow: 'none',
    '&:hover': {
      background: theme.evPalette.ocean[600],
      boxShadow: 'none',
      cursor: 'pointer',
    },
  },
  qcAcceptPropertyButton: {
    fontSize: '12px',
    boxShadow: 'none',
    '&:hover': {
      background: theme.evPalette.ocean[600],
      boxShadow: 'none',
      cursor: 'pointer',
    },
  },
  orderHeading: {
    fontWeight: 'bold',
    margin: 'auto 0',
    padding: `0 0 0 ${theme.spacing(1)}px`,
  },
  propertyHeading: {
    fontWeight: 'bold',
    margin: 'auto 0',
    padding: `0 0 ${theme.spacing(1)}px ${theme.spacing(1.5)}px`,
  },
  viewFacetsButton: {
    background: 'transparent',
    boxShadow: 'none',
    color: theme.evPalette.ocean[500],
    maxHeight: '35px',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    fontSize: '13px',
    '&:hover': {
      background: 'transparent',
      boxShadow: 'none',
      cursor: 'pointer',
    },
  },
  viewTiePointButton: {
    background: 'transparent',
    boxShadow: 'none',
    color: theme.evPalette.ocean[500],
    maxWidth: '51%',
    maxHeight: '35px',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    fontSize: '13px',
    '&:hover': {
      background: 'transparent',
      boxShadow: 'none',
      cursor: 'pointer',
    },
  },
  actionButtons: {
    width: '26%',
    maxHeight: '32px',
    fontSize: '11px',
    padding: theme.spacing(0.5),
  },
  structureLevel: {
    padding: `${theme.spacing(1.5)}px`,
    display: 'flex',
    justifyContent: 'space-between',
  },
  structureLevelHeading: {
    fontWeight: 'bold',
    margin: 'auto 0',
  },
}));
