import React from 'react';
import { EVTableCell } from '@eagleview/ev-comp-library';
import { string, shape } from 'prop-types';
import { get } from 'lodash';
import useStyles from './QcHomeManualAtTableCell.style';
import { HEAD_CELL_KEY } from '../../../QcHome.constants';
import QcContextualMenu from '../../../qc-contextual-menu/QcContextualMenu';
/**
 * QcHomeTableCell represents one cell in HomeTable
 */
const QcHomeTableCell = ({ headCellId, row }) => {
  // utility hooks
  const styles = useStyles();

  /**
   * Renders cell based on headCellId
   */
  const renderCell = () => {
    const testId = `${headCellId}-${get(row, 'orderId', '')}`;
    switch (headCellId) {
      case HEAD_CELL_KEY.menu:
        return (
          <EVTableCell component="div" className={styles.tableCell} data-testid={testId}>
            <QcContextualMenu rowData={row} />
          </EVTableCell>
        );
      default:
        return (
          <EVTableCell component="div" className={styles.tableCell} data-testid={testId}>
            {row[headCellId]}
          </EVTableCell>
        );
    }
  };

  return (
    renderCell()
  );
};

QcHomeTableCell.propTypes = {
  /**
   * Represents header of that cell
   */
  headCellId: string.isRequired,
  /**
   * Represents row in QC claims table
   */
  row: shape({}).isRequired,
};

export default QcHomeTableCell;
