import { makeStyles } from '@eagleview/ev-comp-library';

export default makeStyles((theme) => ({
  wrapper: {
    position: 'absolute',
    right: theme.spacing(0.5),
    bottom: theme.spacing(0.5),
  },
  deleteIcon: {
    marginLeft: theme.spacing(0.375),
  },
}));
