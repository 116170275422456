import { makeStyles } from '@eagleview/ev-comp-library';

export default makeStyles((theme) => ({
  alternativeLabel: {
    top: 10,
    left: '-50%',
    right: '50%',
  },
  connectorActive: {
    '& $line': {
      borderColor: theme.evPalette.lime[600],
    },
  },
  connectorCompleted: {
    '& $line': {
      borderColor: theme.evPalette.lime[600],
    },
  },
  line: {
    borderColor: theme.evPalette.charcoal[300],
    borderTopWidth: 3,
    borderRadius: 1,
  },
  stepIconRoot: {
    color: theme.evPalette.charcoal[300],
    display: 'flex',
    height: 22,
    alignItems: 'center',
  },
  stepIconActive: {
    color: theme.evPalette.lime[600],
  },
  circle: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    borderRadius: '50%',
    backgroundColor: theme.evPalette.charcoal[300],
  },
  stepIconCompleted: {
    color: theme.evPalette.lime[600],
    zIndex: 1,
    fontSize: theme.typography.h5,
  },
  stepperRoot: {
    width: '100%',
    padding: theme.spacing(0),
  },
  stepper: {
    padding: theme.spacing(0),
  },
  step: {
    padding: theme.spacing(0),
    width: theme.spacing(12),
  },
  stepLabel: {
    color: theme.evPalette.charcoal[600],
  },
  stepLabelActive: {
    fontWeight: theme.typography.fontWeightBold,
    color: theme.evPalette.lime[600],
  },
  stepLabelComplete: {
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.evPalette.lime[600],
  },
}));
