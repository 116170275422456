import {
  FETCH_USER_ENTITLEMENTS,
  FETCH_USER_ENTITLEMENTS_SUCCESS,
  FETCH_USER_ENTITLEMENTS_FAILURE,
  SAVE_FEATURE_FLAGS,
} from './EntitleUser.constants';

const initialState = {
  loading: {},
  entitlements: undefined,
  userID: '',
  featureFlags: {},
};

const homeReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_USER_ENTITLEMENTS:
      return {
        ...state,
        loading: { ...state.loading, [type]: true },
      };
    case FETCH_USER_ENTITLEMENTS_SUCCESS:
      return {
        ...state,
        entitlements: payload.entitlements || [],
        userID: payload.userID || '',
        loading: { ...state.loading, [FETCH_USER_ENTITLEMENTS]: false },
      };
    case FETCH_USER_ENTITLEMENTS_FAILURE:
      return {
        ...state,
        entitlements: [],
        loading: { ...state.loading, [FETCH_USER_ENTITLEMENTS]: false },
      };
    case SAVE_FEATURE_FLAGS:
      return {
        ...state,
        featureFlags: payload,
      };
    default:
      return state;
  }
};

export default homeReducer;
