import React, { useState, useEffect } from 'react';
import {
  useTheme,
  EVBox,
  EVIconButton,
  EVClear,
  EVChevronLeft,
  EVTypography,
  EVCircularProgress,
  EVCompass,
} from '@eagleview/ev-comp-library';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import { isEmpty } from 'lodash';
import debounce from 'lodash/debounce';
import { useTranslation } from 'react-i18next';
import PhotosMapViewer from '../photos-mapviewer';
import useStyles from './PhotosFacetOverviewModal.styles';
import PhotosToolbar from '../photos-toolbar';
import PhotosThumbnailGrid from '../photos-thumbnails-grid';
import * as action from '../Photos.actions';
import { FETCH_FULL_IMAGE, FETCH_PHOTO_ASSETS } from '../photos.constant';

const PhotosFacetOverviewModalContent = ({
  imageData,
  orderId,
  imageName,
  handleClose,
  facetOverviewImageCorners,
  setImageNameToDisplay,
  facetOverviewImageName,
  rotationAngle,
}) => {
  // utility hooks
  const theme = useTheme();
  const styles = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const fetchThumbnailAction = (payload) => dispatch(action.fetchThumbnailAction(payload));

  // local state
  const [zoom, setZoom] = useState(0);
  const [zoomChanged, setZoomChanged] = useState(null);
  const [hoveredFacetOverviewImageFromGrid, setHoveredFacetOverviewImageFromGrid] = useState('');
  const [hoveredFacetOverviewImage, setHoveredFacetOverviewImage] = useState('');
  const [facetScanImageSelected, setFacetScanImageSelected] = useState(false);
  const [rotation, setRotation] = useState(rotationAngle);

  // selectors
  const facetOverviewThumbnails = useSelector((state) => get(state, 'photosReducer.thumbnails.facet_scan'));
  const facetCameraPositions = useSelector((state) => get(state, 'photosReducer.cameraPositions.facet_scans', []));
  const selectedStructureId = useSelector((state) => get(state, 'photosReducer.selectedStructureId', ''));

  const loading = useSelector((state) => get(state, 'photosReducer.loading'));

  useEffect(() => {
    // fetch thumbnails
    fetchThumbnailAction({
      orderId,
      structureId: selectedStructureId,
      imageType: 'facet_scan',
      facetOverviewImageName: !isEmpty(imageName) && imageName.split('.')[0],
    });
  }, [selectedStructureId]);

  useEffect(() => {
    setRotation(rotationAngle);
  }, [rotationAngle]);

  const setZoomLevel = (z) => {
    setZoom(z);
  };

  const handleZoomInButton = () => {
    const newZoom = zoom + 1;
    setZoomChanged(newZoom);
  };

  const handleZoomOutButton = () => {
    const newZoom = zoom - 1;
    setZoomChanged(newZoom);
  };

  const resetImageName = (img) => {
    setFacetScanImageSelected(true);
    setImageNameToDisplay(img);
  };

  const handleAnnotationSelection = (annotationObj) => {
    const eventType = get(annotationObj, 'eventType', '');
    const img = get(annotationObj, 'selectedImageName', '');
    if (eventType === 'hover') {
      setHoveredFacetOverviewImage(img);
    } else if (eventType === 'click' && !isEmpty(img)) {
      // to display facet scans selected from modal content
      resetImageName(img);
    }
  };

  const onThumbnailItemHover = (image) => {
    if (!isEmpty(image)) {
      setHoveredFacetOverviewImageFromGrid(image.imageName);
    } else {
      setHoveredFacetOverviewImageFromGrid('');
    }
  };

  const handleBackClick = () => {
    setFacetScanImageSelected(false);
    setImageNameToDisplay(facetOverviewImageName);
  };

  const debounceSetRotation = debounce(setRotation, 10);

  return (
    <EVBox
      sx={{
        display: 'flex',
        height: '100%',
        padding: `0 0 ${theme.spacing(2)}px 0`,
      }}
    >
      <EVBox position="relative" flex="1 1 auto" className={styles.container}>
        {!loading[FETCH_PHOTO_ASSETS] && !loading[FETCH_FULL_IMAGE] ? (
          <>
            <PhotosToolbar
              handleZoomInButton={handleZoomInButton}
              handleZoomOutButton={handleZoomOutButton}
            />
            <PhotosMapViewer
              imageCorners={facetOverviewImageCorners}
              image={imageData}
              cameraPositions={facetCameraPositions}
              setZoomLevel={setZoomLevel}
              zoomChanged={zoomChanged}
              setSelectedAnnotation={handleAnnotationSelection}
              cameraIconHoveredFromGrid={hoveredFacetOverviewImageFromGrid}
              rotation={rotation}
            />
            <EVBox className={styles.compassStyle}>
              <EVCompass
                updatedAngle={rotationAngle}
                onAngleUpdate={debounceSetRotation}
                compassWidth="70"
                knobSize={20}
                knobRadius={10}
                showNorthIndicator
              />
            </EVBox>
          </>
        ) : (
          <EVBox display="flex" justifyContent="center" alignItems="center" height="100%">
            <EVCircularProgress className={styles.circularProgressBar} />
          </EVBox>
        )}
      </EVBox>
      <EVBox display="flex" flex="0 0 324px" overflow="hidden" flexDirection="column">
        <EVBox sx={{
          p: 1,
          m: 1,
          bgcolor: 'background.paper',
          borderRadius: 1,
        }}
        >
          {' '}
          { facetScanImageSelected
          && (
          <EVIconButton data-testid="back-button-icon" onClick={handleBackClick} className={styles.backButton}>
            <EVChevronLeft />
            <EVTypography variant="h4">
              {t('back')}
            </EVTypography>
          </EVIconButton>
          )}
          <EVIconButton data-testid="modal-close-icon" onClick={handleClose} className={styles.closeButton}>
            <EVClear />
          </EVIconButton>
          <EVTypography variant="h4" className={styles.facetScanText}>
            {t('photos-ui.facetScanImagery')}
          </EVTypography>
          <EVBox className={styles.thumbnails}>
            <PhotosThumbnailGrid
              images={facetOverviewThumbnails}
              onItemHovered={onThumbnailItemHover}
              hoveredGridItem={hoveredFacetOverviewImage}
              onItemClicked={(data) => resetImageName(data.imageName)}
              selectedGridItem={imageName}
            />
          </EVBox>
        </EVBox>
      </EVBox>
    </EVBox>
  );
};

PhotosFacetOverviewModalContent.propTypes = {
  imageData: PropTypes.number,
  orderId: PropTypes.string.isRequired,
  imageName: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  facetOverviewImageCorners: PropTypes.shape({
    ul: PropTypes.shape({
      lat: PropTypes.number.isRequired,
      lon: PropTypes.number.isRequired,
    }).isRequired,
    ur: PropTypes.shape({
      lat: PropTypes.number.isRequired,
      lon: PropTypes.number.isRequired,
    }).isRequired,
    br: PropTypes.shape({
      lat: PropTypes.number.isRequired,
      lon: PropTypes.number.isRequired,
    }).isRequired,
    bl: PropTypes.shape({
      lat: PropTypes.number.isRequired,
      lon: PropTypes.number.isRequired,
    }).isRequired,
  }).isRequired,
  setImageNameToDisplay: PropTypes.func,
  facetOverviewImageName: PropTypes.string.isRequired,
  rotationAngle: PropTypes.number.isRequired,
};

PhotosFacetOverviewModalContent.defaultProps = {
  imageData: {},
  setImageNameToDisplay: () => {},
};

export default PhotosFacetOverviewModalContent;
