import { fork, all } from 'redux-saga/effects';
import homeSaga from 'layout/homeLayout/home/Home.saga';
import adjusterSaga from 'layout/adjuster/Adjuster.saga';
import entitleUserSaga from 'layout/entitleUser/EntitleUser.saga';
import qcHomeSaga from 'layout/homeLayout/qcHome/QcHome.saga';
import manualAtAdjustmentSagaV2 from 'layout/manual-at-adjustment-v2/ManualAtAdjustment.sagas';
import manualAtMultiStructureSaga from 'layout/manual-at-multi-structure/ManualAtAdjustment.sagas';
import droneToMeasureSaga from 'layout/drone-to-measure/DroneToMeasure.sagas';
import validateLinkSaga from 'layout/validateLink/ValidateLink.saga';
import PhotosSaga from 'layout/photos/Photos.saga';
import ShareOTPLoginSaga from 'layout/share-otp-login/ShareOTPLoginPage.saga';

export default function* rootSaga() {
  yield all([
    fork(homeSaga),
    fork(adjusterSaga),
    fork(entitleUserSaga),
    fork(qcHomeSaga),
    fork(manualAtAdjustmentSagaV2),
    fork(manualAtMultiStructureSaga),
    fork(droneToMeasureSaga),
    fork(validateLinkSaga),
    fork(PhotosSaga),
    fork(ShareOTPLoginSaga),
  ]);
}
