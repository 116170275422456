import { makeStyles } from '@eagleview/ev-comp-library';

export default makeStyles((theme) => ({
  container: {
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: '19px',
    marginTop: theme.spacing(2),
  },
  radioContainer: {
    display: 'flex',
  },
  title: {
    paddingLeft: '30px',
    variant: 'h4',
  },
  radioLabel: {
    marginTop: theme.spacing(1),
  },
  notesContainer: {
    paddingRight: theme.spacing(1),
  },
  notesTitle: {
    paddingLeft: '11px',
    paddingTop: theme.spacing(2),
  },
  notesField: {
    paddingLeft: '11px',
    paddingTop: theme.spacing(1),
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(1),
  },
}));
