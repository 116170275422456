import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { func, shape } from 'prop-types';
import { useTranslation } from 'react-i18next';
import get from 'lodash/get';
import {
  EVTableContainer,
  EVTable,
  EVTableHead,
  EVTableRow,
  EVTableCell,
  EVTableBody,
  EVCircularIndeterminate,
  EVButton,
} from '@eagleview/ev-comp-library';
import useStyles from './QcTable.styles';

const headCells = [
  {
    id: 'facetName',
    numeric: false,
    disablePadding: true,
    label: 'Facet',
  },
  {
    id: 'facetCoverage',
    numeric: false,
    disablePadding: false,
    label: 'Coverage',
  },
  {
    id: 'facetArea',
    numeric: true,
    disablePadding: false,
    label: 'Area',
  },
];

const QcTable = ({
  tableData, setFacetWithInitialAnomaly,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  // component state initialization
  const [qcFacets, setQcFacets] = useState([]);

  // selectors
  const facetsInfo = useSelector((state) => state.adjusterReducer.facets);

  const convertFacetInfoToMap = () => {
    const facetMap = new Map();
    tableData.forEach((fct) => {
      facetMap.set(fct.facet, fct);
    });
    return facetMap;
  };

  const combineFacetDataToCorrectFormat = () => {
    const facetInfoQC = convertFacetInfoToMap();
    return facetsInfo.map((fct) => {
      const facet = facetInfoQC.get(fct.facetAliasName);
      return ({
        facetName: get(fct, 'facetAliasName', ''),
        coverage: get(facet, 'coverage', ''),
        area: get(fct, 'facetOutline.area', ''),
        facetId: get(fct, 'facetId', ''),
        isFacetUnderHang: get(fct, 'underHang', false),
      });
    });
  };

  const getTableHeaderCellType = (cellType) => {
    switch (cellType) {
      case 'Facet':
        return 'qcPanel.facetNameTableCell';
      case 'Coverage':
        return 'qcPanel.facetCoverageTableCell';
      case 'Area':
        return 'qcPanel.facetAreaTableCell';
      default:
        return '';
    }
  };

  useEffect(() => {
    if (facetsInfo.length > 0 && tableData.length > 0) {
      setQcFacets(combineFacetDataToCorrectFormat());
    }
  }, [tableData, facetsInfo]);

  return (
    <>
      {(
        qcFacets.length === 0 && (
          <EVCircularIndeterminate
            className={classes.qcCoverageTableLoading}
          />
        )
      )}
      {
        qcFacets.length > 0 && (
          <EVTableContainer className={classes.qcTableContainer}>
            <EVTable stickyHeader>
              <EVTableHead className={classes.qcTableHeader}>
                <EVTableRow>
                  {
                    headCells.map((headCell) => (
                      <EVTableCell
                        className={headCell.id === 'facetCoverage' ? classes.tableHeaderTextRightAlign : classes.tableHeaderText}
                        key={headCell.id}
                        align="left"
                      >
                        {t(getTableHeaderCellType(headCell.label))}
                      </EVTableCell>
                    ))
                  }
                </EVTableRow>
              </EVTableHead>
              <EVTableBody className={classes.qcTableBody}>
                {
                  qcFacets.map((fct) => (
                    <EVTableRow key={fct.facet}>
                      <EVTableCell
                        component="th"
                        scope="row"
                        className={classes.facetNameCell}
                        disabled={fct.isFacetUnderHang}
                      >
                        <EVButton
                          color="primary"
                          variant="contained"
                          onClick={() => setFacetWithInitialAnomaly(fct.facetId)}
                          disabled={fct.isFacetUnderHang}
                          className={classes.facetNameButton}
                        >
                          {fct.facetName}
                        </EVButton>
                      </EVTableCell>
                      <EVTableCell component="th" scope="row" className={classes.facetCoverageCell}>
                        {`${Number(fct.coverage).toFixed(2)} %`}
                      </EVTableCell>
                      <EVTableCell component="th" scope="row" className={classes.facetAreaCell}>
                        {`${Number(fct.area).toFixed(0)} sq.ft`}
                      </EVTableCell>
                    </EVTableRow>
                  ))
                }
              </EVTableBody>
            </EVTable>
          </EVTableContainer>
        )
      }
    </>
  );
};

QcTable.propTypes = {
  tableData: shape([]).isRequired,
  setFacetWithInitialAnomaly: func.isRequired,
};

export default QcTable;
