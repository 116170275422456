import React from 'react';
import isEmpty from 'lodash/isEmpty';
import {
  EVInformationPanel, EVBox, EVTypography,
} from '@eagleview/ev-comp-library';
import { object } from 'prop-types';
import { useTranslation } from 'react-i18next';
import useStyles from './AccessoriesSummaryPanel.styles';
import { getAccessoriesCount } from './AccessoriesSummaryPanel.utils';

export const AccessoriesSummaryPanel = ({
  accessoryData,
}) => {
  // utility hooks
  const styles = useStyles();
  const { t } = useTranslation();

  const totalCount = getAccessoriesCount(accessoryData);

  // eslint-disable-next-line arrow-body-style
  const renderAccessoriesData = () => {
    return Object.keys(accessoryData).map((key) => {
      const accessories = accessoryData[key];
      return (
        <>
          <EVBox className={styles.container}>
            <EVBox className={styles.header}>{key}</EVBox>
            <EVBox>
              {
                accessories.map((accessory, idx) => {
                  const {
                    AccessoryName: name = '',
                    AccessoryCount: count = 0,
                    AccessoryID: id = idx,
                  } = accessory;

                  return (
                    <EVBox className={styles.accessoryItem} key={id}>
                      {name}
                      &emsp;
                      <span className={styles.count}>{count}</span>
                    </EVBox>
                  );
                })
              }
            </EVBox>
          </EVBox>
        </>
      );
    });
  };

  return (
    <EVInformationPanel
      draggableProps={{ bounds: 'parent' }}
      enableCloseOnUI={false}
      className={styles.panel}
      dragEnabled
      width="288px"
      height="auto"
      titleComponent={(
        <EVTypography className={styles.panelHeader}>
          {`${t('accessoriesPanel.header')} (${totalCount})`}
        </EVTypography>
      )}
      headerInputProps={{}}
    >
      <EVBox>
        {!isEmpty(accessoryData) ? renderAccessoriesData() : (
          <EVBox className={styles.noDataFound}>
            {t('accessoriesPanel.noAccessories')}
          </EVBox>
        )}
      </EVBox>
    </EVInformationPanel>
  );
};

AccessoriesSummaryPanel.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  accessoryData: object.isRequired,
};

export default AccessoriesSummaryPanel;
