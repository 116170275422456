import { makeStyles } from '@eagleview/ev-comp-library';

export default makeStyles((theme) => ({
  infoPanel: {
    zIndex: 8,
    position: 'absolute',
    left: `calc(50% - ${theme.spacing(62) / 2}px)`,
    top: `calc(50% - ${theme.spacing(46.375) / 2}px)`,
    boxShadow: theme.shadows[4],
  },
  header: {
    width: theme.spacing(57),
    height: theme.spacing(5.5),
    margin: `${theme.spacing(1)}px ${theme.spacing(2)}px ${theme.spacing(1)}px ${theme.spacing(3)}px`,
  },
  cardContent: {
    padding: `${theme.spacing(1)}px ${theme.spacing(0)}px`,
  },
  panelContainer: {
    marginLeft: theme.spacing(3),
    marginBottom: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
  },
  footer: {
    padding: `${theme.spacing(2.125)}px ${theme.spacing(2)}px ${theme.spacing(0)}px ${theme.spacing(2)}px`,
    height: theme.spacing(5.5),
    borderTop: `1px solid ${theme.evPalette.charcoal[200]}`,
  },
  backdrop: {
    zIndex: 7,
    backgroundColor: theme.evPalette.scrim,
  },
  titleText: {
    marginTop: theme.spacing(1),
    fontSize: theme.typography.body1.fontSize,
    fontWeight: theme.typography.fontWeightBold,
    color: theme.evPalette.iceberg[500],
    lineHeight: '27px',
    height: theme.spacing(4),
  },
  closeIcon: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  closeIconButton: {
    float: 'right',
    padding: theme.spacing(1),
    height: theme.spacing(4.5),
    width: theme.spacing(4.5),
    marginTop: theme.spacing(1),
  },
  contentLabel: {
    lineHeight: '27px',
    color: theme.evPalette.nickel[700],
  },
  contentDataClaimId: {
    marginTop: theme.spacing(4),
    fontSize: theme.typography.h4.fontSize,
    fontWeight: theme.typography.fontWeightBold,
    color: theme.evPalette.charcoal[900],
    lineHeight: '24px',
  },
  contentDataClaimStatus: {
    marginTop: theme.spacing(0.5),
    color: theme.evPalette.nickel[700],
    lineHeight: '24px',
  },
  assignmentSearchField: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1.5),
  },
  button: {
    textTransform: 'none',
    height: theme.spacing(4),
  },
  cancelButton: {
    marginRight: theme.spacing(2),
  },
  buttonBox: {
    float: 'right',
  },
}));
