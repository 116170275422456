import { FEATURE_ENTITLEMENTS } from 'layout/entitleUser/EntitleUser.constants';

const prefix = 'manualAtAdjustmentReducerV2';

export const FETCH_IMAGES = `${prefix}/FETCH_IMAGES`;
export const FETCH_IMAGES_SUCCESS = `${prefix}/FETCH_IMAGES_SUCCESS`;
export const FETCH_IMAGES_FAILURE = `${prefix}/FETCH_IMAGES_FAILURE`;

export const FETCH_ORTHO_IMAGE = `${prefix}/FETCH_ORTHO_IMAGE`;
export const FETCH_ORTHO_IMAGE_SUCCESS = `${prefix}/FETCH_ORTHO_IMAGE_SUCCESS`;
export const FETCH_ORTHO_IMAGE_FAILURE = `${prefix}/FETCH_ORTHO_IMAGE_FAILURE`;

export const FETCH_ORTHO_POLYGON = `${prefix}/FETCH_ORTHO_POLYGON`;
export const FETCH_ORTHO_POLYGON_SUCCESS = `${prefix}/FETCH_ORTHO_POLYGON_SUCCESS`;
export const FETCH_ORTHO_POLYGON_FAILURE = `${prefix}/FETCH_ORTHO_POLYGON_FAILURE`;

export const FETCH_ORDER_DETAILS = `${prefix}/FETCH_ORDER_DETAILS`;
export const FETCH_ORDER_DETAILS_SUCCESS = `${prefix}/FETCH_ORDER_DETAILS_SUCCESS`;
export const FETCH_ORDER_DETAILS_FAILURE = `${prefix}/FETCH_ORDER_DETAILS_FAILURE`;

export const SET_ACTIVE_IMAGE = `${prefix}/SET_ACTIVE_IMAGE`;
export const ADD_ACTIVE_COORD_PAIRINGS = `${prefix}/ADD_ACTIVE_COORD_PAIRINGS`;
export const ADD_COORD_ONE = `${prefix}/ADD_COORD_ONE`;
export const ADD_COORD_TWO = `${prefix}/ADD_COORD_TWO`;
export const RESET_ACTIONS_PERFORMED = `${prefix}/RESET_ACTIONS_PERFORMED`;
export const CLOSE_ERROR_TOAST = `${prefix}/CLOSE_ERROR_TOAST`;
export const SET_ACTIVE_VERTEX = `${prefix}/SET_ACTIVE_VERTEX`;
export const SET_VIEW = `${prefix}/SET_VIEW`;
export const SET_INITIAL_VIEW = `${prefix}/SET_INITIAL_VIEW`;

export const FETCH_IMAGES_AND_DETAILS = `${prefix}/FETCH_IMAGES_AND_DETAILS`;
export const FETCH_IMAGES_AND_DETAILS_FAILURE = `${prefix}/FETCH_IMAGES_AND_DETAILS_FAILURE`;

export const SET_OVERVIEW_VIEW = `${prefix}/SET_OVERVIEW_VIEW`;
export const SET_OVERVIEW_INITIAL_VIEW = `${prefix}/SET_OVERVIEW_INITIAL_VIEW`;

export const SET_SELECTED_IMAGES_LIST = `${prefix}/SET_SELECTED_IMAGES_LIST`;
export const SET_IS_MIN_IMAGE_SELECTED = `${prefix}/SET_IS_MIN_IMAGE_SELECTED`;

export const HANDLE_CHECKOUT_ERROR = `${prefix}/HANDLE_CHECKOUT_ERROR`;

export const UPDATE_TASK_STATE_ID = `${prefix}/UPDATE_TASK_STATE_ID`;
export const SET_HIDE_UI = `${prefix}/SET_HIDE_UI`;

export const COMPLETE = `${prefix}/COMPLETE`;
export const COMPLETE_SUCCESS = `${prefix}/COMPLETE_SUCCESS`;
export const COMPLETE_FAILURE = `${prefix}/COMPLETE_FAILURE`;

export const REJECT = `${prefix}/REJECT`;
export const REJECT_SUCCESS = `${prefix}/REJECT_SUCCESS`;
export const REJECT_FAILURE = `${prefix}/REJECT_FAILURE`;
export const SHOW_EMPTY_REJECT_NOTES_ERROR = `${prefix}/SHOW_EMPTY_REJECT_NOTES_ERROR`;
export const SHOW_REJECT_REASON_NOT_SELECTED_ERROR = `${prefix}/SHOW_REJECT_REASON_NOT_SELECTED_ERROR`;
export const SHOW_NOT_ENTITLED_TO_MANUAL_AT_ERROR = `${prefix}/SHOW_NOT_ENTITLED_TO_MANUAL_AT_ERROR`;
export const SET_INITIAL_IMAGES_DETAILS = `${prefix}/SET_INITIAL_IMAGES_DETAILS`;

export const SET_SRC = `${prefix}/SET_SRC`;
export const ADD_COORDINATE_PAIRING = `${prefix}/ADD_COORDINATE_PAIRING`;
export const REMOVE_COORDINATE_PAIRING = `${prefix}/REMOVE_COORDINATE_PAIRING`;
export const SET_SRC_DST = `${prefix}/SET_SRC_DST`;
export const DELETE_OVERVIEW_POINT = `${prefix}/DELETE_OVERVIEW_POINT`;

export const ENABLE_READ_ONLY_MODE = `${prefix}/ENABLE_READ_ONLY_MODE`;

export const SHOW_TOAST_MESSAGE = `${prefix}/SHOW_TOAST_MESSAGE`;

// Manual adjustment statuses
export const STATUS_COMPLETED = 'COMPLETED';
export const STATUS_PENDING = 'PENDING';

// Manual AT Reject reasons
export const REJECTED_WRONG_HOUSE = 'REJECTED_WRONG_HOUSE';
export const REJECTED_NO_USABLE_POINTS = 'REJECTED_NO_USABLE_POINTS';
export const REJECTED_OTHER = 'REJECTED_OTHER';
export const REJECTED_REQUEUE = 'REJECTED_REQUEUE';
export const REJECTED_REMEASURE = 'REJECTED_REMEASURE';
export const REJECTED_NEEDS_REVIEW = 'REJECTED_NEEDS_REVIEW';

// Order Statuses
export const ORDER_STATUS_MANUAL_ADJUSTMENT_INITIATED = 'manualadjustmentinitiated';
export const ORDER_STATUS_ASSESS_ESCALATION = 'assessescalation';
export const ORDER_STATUS_UNDER_REVIEW = 'underreview';

export const VERTEX_STYLE = {
  UNSELECTED: {
    fillColor: '#000000',
    fillOpacity: 0.5,
  },
  SELECTED: {
    fillColor: '#3ba0ff',
    fillOpactiy: 1,
  },
  COMPLETED: {
    fillColor: '#6cff43',
    fillOpacity: 1,
  },
};

export const rejectReasonToTranslation = {
  [REJECTED_WRONG_HOUSE]: 'manualAtRejectPanel.wrongHouse',
  [REJECTED_NO_USABLE_POINTS]: 'manualAtRejectPanel.noUsablePoints',
  [REJECTED_OTHER]: 'manualAtRejectPanel.other',
  [REJECTED_REQUEUE]: 'manualAtRejectPanel.requeue',
  [REJECTED_REMEASURE]: 'manualAtRejectPanel.remeasure',
  [REJECTED_NEEDS_REVIEW]: 'manualAtRejectPanel.needsReview',
};

export const rejectReasonToId = {
  [REJECTED_WRONG_HOUSE]: 87,
  [REJECTED_NO_USABLE_POINTS]: 88,
  [REJECTED_OTHER]: 25,
  [REJECTED_REQUEUE]: 44,
  [REJECTED_NEEDS_REVIEW]: 89,
};

export const roles = [
  FEATURE_ENTITLEMENTS.MANAGE_MANUAL_AT_VIA_OPS_PRIME,
  FEATURE_ENTITLEMENTS.MANAGE_MANUAL_AT_ESCALATION_VIA_OPS_PRIME,
  FEATURE_ENTITLEMENTS.MANAGE_MANUAL_AT_TIE_POINTS,
];

export const rolesEntitledToCallOpsPrime = [
  FEATURE_ENTITLEMENTS.MANAGE_MANUAL_AT_VIA_OPS_PRIME,
  FEATURE_ENTITLEMENTS.MANAGE_MANUAL_AT_ESCALATION_VIA_OPS_PRIME,
];

export const INITIAL_VIEW_ORTHO = {
  lonLat: { lat: 32.5429065494186, lon: -96.88171383777551 },
  zoom: 20,
  rotation: -0,
};

export const INITIAL_VIEW_OVERVIEW = {
  lonLat: { lat: 0, lon: 0 },
  zoom: 1,
  rotation: -0,
};

export const NUM_OF_RECOMMENDED_PAIRINGS = 7;
export const NUM_OF_MINIMUM_PAIRINGS = 4;

// to get the list of key values check https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/key/Key_Values
export const KEYBOARD_SHORTCUTS = {
  addPoint: ['Shift', 'A'],
};

export const SERVER_ERROR_CODES_TO_TOAST_MESSAGE = {
  63217: 'manualAtAdjustment.fetchOverviewImagesMetaError',
};
