import React, { useEffect } from 'react';
import {
  useTheme,
  EVBox,
  EVIconButton,
  EVClear,
  EVTypography,
  EVCircularProgress,
} from '@eagleview/ev-comp-library';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import useStyles from './PhotosMissingSummaryJsonModal.styles';
import PhotosThumbnailGrid from '../photos-thumbnails-grid';
import PhotosImageViewer from '../photos-imageViewer';
import * as action from '../Photos.actions';
import { FETCH_FULL_IMAGE } from '../photos.constant';

const PhotosMissingSummaryJsonModalContent = ({
  orderId, imageData, handleClose, setImageFromModal,
}) => {
  // utility hooks
  const theme = useTheme();
  const styles = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const fetchFullImage = (payload) => dispatch(action.fetchFullImageAction(payload));

  // selectors
  const facetOverviewImage = useSelector((state) => get(state, 'photosReducer.fullImages.facetOverview'));
  const allThumbnail = useSelector((state) => get(state, 'photosReducer.thumbnails.all', []));
  const loading = useSelector((state) => get(state, 'photosReducer.loading', false));
  const selectedStructureId = useSelector((state) => get(state, 'photosReducer.selectedStructureId', ''));

  useEffect(() => {
    // fetch image full resolution
    if (!isEmpty(imageData.imageName)) {
      fetchFullImage({
        orderId,
        structureId: selectedStructureId,
        imageName: imageData.imageName,
        imageType: 'facetOverview',
      });
    }
  }, [imageData]);

  return (
    <EVBox
      sx={{
        display: 'flex',
        height: '100%',
        padding: `0 0 ${theme.spacing(2)}px 0`,
      }}
    >
      <EVBox position="relative" flex="1 1 auto" className={styles.container}>
        {
          loading[FETCH_FULL_IMAGE] ? (
            <EVBox
              width="100%"
              height="100%"
              color="common.white"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <EVCircularProgress className={styles.circularProgressBar} />
            </EVBox>
          ) : <PhotosImageViewer image={facetOverviewImage} />
          }
      </EVBox>
      <EVBox display="flex" flex="0 0 324px" overflow="hidden" flexDirection="column">
        <EVBox sx={{
          p: 1,
          m: 1,
          bgcolor: 'background.paper',
          borderRadius: 1,
        }}
        >
          <EVIconButton
            data-testid="modal-close-icon"
            onClick={handleClose}
            className={styles.closeButton}
          >
            <EVClear />
          </EVIconButton>
          <EVTypography variant="h4" className={styles.facetScanText}>
            {t('photos-ui.missingSummaryJson.capturedImagery')}
          </EVTypography>
          <EVBox className={styles.thumbnails}>
            <PhotosThumbnailGrid
              images={allThumbnail}
              onItemClicked={setImageFromModal}
              selectedGridItem={imageData.imageName}
            />
          </EVBox>
        </EVBox>
      </EVBox>
    </EVBox>
  );
};

PhotosMissingSummaryJsonModalContent.propTypes = {
  orderId: PropTypes.string,
  imageData: PropTypes.shape({
    imageBytes: PropTypes.string,
    imageName: PropTypes.string,
    imagePath: PropTypes.string,
  }),
  handleClose: PropTypes.func,
  setImageFromModal: PropTypes.func,
};

PhotosMissingSummaryJsonModalContent.defaultProps = {
  orderId: '',
  imageData: {
    imageBytes: '',
    imageName: '',
    imagePath: '',
  },
  handleClose: () => {},
  setImageFromModal: () => {},
};

export default PhotosMissingSummaryJsonModalContent;
