import get from 'lodash/get';
import flatten from 'lodash/flatten';
import uniqWith from 'lodash/uniqWith';
import isEqual from 'lodash/isEqual';
import { v4 as uuidv4 } from 'uuid';
import * as turf from '@turf/turf';
import { AssetFactory } from '@eagleview/mapviewer';

const featureCollectionToArrayOfPoints = (features) => (
  features ? uniqWith(flatten(features.map(({ geometry }) => get(geometry, 'coordinates[0]'))), isEqual) : []
);

export const featureCollectionToPoints = ({ features }) => {
  const featuresAsFlatArray = featureCollectionToArrayOfPoints(features);
  return [
    {
      assetType: 'annotation',
      uuid: 'vertices',
      data: featuresAsFlatArray.map((x, i) => {
        const coordsFixedTo13 = [parseFloat(x[0].toFixed(13)), parseFloat(x[1].toFixed(13)), parseFloat(x[2].toFixed(13))];
        return {
          id: uuidv4(),
          geometries: [
            {
              type: 'Point',
              coordinates: coordsFixedTo13,
            },
          ],
          properties: {
            annotationType: 'point',
            showLabel: true,
            name: i,
          },
          style: {
            fillColor: '#000000',
            fillOpacity: 0.5,
          },
        };
      }),
    },
  ];
};

export const featureCollectionToPolygon = (featureCollection) => [
  AssetFactory.getAsset({
    assetType: 'annotation',
    uuid: 'polygon',
    data: featureCollection.features.map((feature) => {
      const coordinates = feature.geometry.coordinates[0];
      const updatedCoords = coordinates.map((coordinate) => coordinate.map((c) => parseFloat(c.toFixed(13))));
      return {
        geometries: [{ ...feature.geometry, coordinates: [updatedCoords] }],
        style: {
          strokeColor: '#FFFFFF',
          fillColor: '#fff',
          strokeWidth: 2,
          strokeOpacity: 0.2,
          fillOpacity: 0.3,
        },
        properties: feature.properties,
      };
    }),
  }),
];

export const getCenterOfAllVertices = ({ features }) => {
  const featuresAsFlatArray = featureCollectionToArrayOfPoints(features);
  const centerPoint = turf.center(
    turf.featureCollection(featuresAsFlatArray.map((x) => turf.point(x))),
  );
  const lon = get(centerPoint, 'geometry.coordinates[0]');
  const lat = get(centerPoint, 'geometry.coordinates[1]');
  if (lon && lat) return { lonLat: { lon, lat }, zoom: 20 };
  return false;
};

export const imageAsset = (response, blob) => {
  const cornerCoords = response.geometry.coordinates[0];
  return {
    assetType: 'image',
    uuid: 'orthoimage',
    url: window.URL.createObjectURL(new Blob([blob])),
    corners: {
      ul: {
        lon: cornerCoords[0][0],
        lat: cornerCoords[0][1],
      },
      ur: {
        lon: cornerCoords[1][0],
        lat: cornerCoords[1][1],
      },
      br: {
        lon: cornerCoords[2][0],
        lat: cornerCoords[2][1],
      },
      bl: {
        lon: cornerCoords[3][0],
        lat: cornerCoords[3][1],
      },
    },
  };
};
