import {
  GENERATE_OTP,
  GENERATE_OTP_SUCCESS,
  GENERATE_OTP_FAILURE,
  VERIFY_OTP,
  VERIFY_OTP_SUCCESS,
  VERIFY_OTP_FAILURE,
  RESEND_OTP,
} from './ShareOTPLoginPage.constant';

export const generateOTPAction = (payload) => ({
  type: GENERATE_OTP,
  payload,
});
export const generateOTPSuccessAction = (payload) => ({
  type: GENERATE_OTP_SUCCESS,
  payload,
});
export const generateOTPFailureAction = (payload) => ({
  type: GENERATE_OTP_FAILURE,
  payload,
});

export const verifyOTPAction = (payload) => ({
  type: VERIFY_OTP,
  payload,
});

export const verifyOTPSuccessAction = (payload) => ({
  type: VERIFY_OTP_SUCCESS,
  payload,
});

export const verifyOTPFailureAction = (payload) => ({
  type: VERIFY_OTP_FAILURE,
  payload,
});

export const resendOTPAction = () => ({
  type: RESEND_OTP,
});
