import React from 'react';
import { EVChip } from '@eagleview/ev-comp-library';
import { string } from 'prop-types';
import useStyles from './HomeStatusChip.styles';

const HomeStatusChip = ({ status, label }) => {
  const styles = useStyles({ status });
  return <EVChip size="small" className={styles.tableChip} label={label} />;
};

HomeStatusChip.propTypes = {
  status: string.isRequired,
  label: string.isRequired,
};

export default HomeStatusChip;
