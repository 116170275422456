import React, { useEffect } from 'react';
import ShareOTPLoginPageBackGround from 'assets/share-otp-background.png';
import { EVBox } from '@eagleview/ev-comp-library';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Adjuster from 'layout/adjuster/Adjuster';
import OTPForm from './otp-form';
import * as action from './ShareOTPLoginPage.actions';
import useStyles from './ShareOTPLoginPage.styles';

const ShareOTPLoginPage = (props) => {
  const { id: uuid } = useParams();
  const classes = useStyles();

  // actions
  const dispatch = useDispatch();
  const generateOtp = (payload) => dispatch(action.generateOTPAction(payload));
  const verifyOtp = (payload) => dispatch(action.verifyOTPAction(payload));
  const resendOtpAction = () => dispatch(action.resendOTPAction());

  // selectors
  const isOTPValidated = useSelector((state) => state.shareOTPLoginReducer.optVerified);

  useEffect(() => {
    // generate a new otp
    generateOtp({
      uuid,
    });
  }, []);

  const onSubmitOtp = (otp) => {
    // validate otp
    verifyOtp({
      uuid,
      otp,
    });
  };

  const onResendOtp = (id) => {
    generateOtp({
      uuid: id,
    });
    resendOtpAction();
  };

  return (
    <EVBox component="span">
      {isOTPValidated
        ? <Adjuster {...props} />
        : (
          <EVBox className={classes.root}>
            <img
              className={classes.image}
              alt="Share OTP Login Page"
              src={ShareOTPLoginPageBackGround}
            />
            <EVBox className={classes.form}>
              <OTPForm
                uuid={uuid}
                onSubmitOtp={onSubmitOtp}
                onGenerateOtp={(id) => onResendOtp(id)}
              />
            </EVBox>
          </EVBox>
        )}
    </EVBox>
  );
};

export default ShareOTPLoginPage;
