import React from 'react';
import {
  string, shape, bool, func, number,
} from 'prop-types';
import { EVSnackbar, EVAlert } from '@eagleview/ev-comp-library';

const ToastMessage = (props) => {
  const {
    alertMsg, severity, position, open, onClose, autoHideDuration,
  } = props;

  return (
    <EVSnackbar
      anchorOrigin={position}
      open={open}
      onClose={onClose}
      autoHideDuration={autoHideDuration}
      style={{
        position: 'absolute',
      }}
    >
      <EVAlert severity={severity}>
        {/* eslint-disable-next-line */}
        <div dangerouslySetInnerHTML={{ __html: alertMsg }} />
      </EVAlert>
    </EVSnackbar>
  );
};

ToastMessage.propTypes = {
  alertMsg: string.isRequired,
  severity: string.isRequired,
  position: shape({
    vertical: string,
    horizontal: string,
  }),
  open: bool.isRequired,
  onClose: func.isRequired,
  autoHideDuration: number,
};

ToastMessage.defaultProps = {
  position: {
    vertical: 'top',
    horizontal: 'center',
  },
  autoHideDuration: 10000,
};

export default ToastMessage;
