import {
  FETCH_VALIDATE_LINK,
  FETCH_VALIDATE_LINK_SUCCESS,
  FETCH_VALIDATE_LINK_FAILURE,
} from './ValidateLink.constants';

export const fetchValidateLink = (payload) => ({
  type: FETCH_VALIDATE_LINK,
  payload,
});

export const fetchValidateLinkSuccessAction = (payload) => ({
  type: FETCH_VALIDATE_LINK_SUCCESS,
  payload,
});

export const fetchValidateLinkFailureAction = (payload) => ({
  type: FETCH_VALIDATE_LINK_FAILURE,
  payload,
});
