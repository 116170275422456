import { call, put, takeLatest } from 'redux-saga/effects';
import get from 'lodash/get';
import * as util from 'utils/utils';
import { setShareToken } from 'utils/auth.utils';
import * as action from './ValidateLink.actions';
import * as api from './ValidateLink.api';
import { FETCH_VALIDATE_LINK } from './ValidateLink.constants';

export function* fetchValidateLinkSaga(payload) {
  try {
    const response = yield call(api.fetchValidateLink, payload.payload);
    const token = get(response, 'uuid');
    setShareToken(token);
    yield put(action.fetchValidateLinkSuccessAction({ token }));
  } catch (error) {
    yield put(action.fetchValidateLinkFailureAction(util.parseServerError(error)));
  }
}

export default function* validateLinkSaga() {
  yield takeLatest(FETCH_VALIDATE_LINK, fetchValidateLinkSaga);
}
