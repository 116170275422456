import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { useDispatch } from 'react-redux';
import { EVBox } from '@eagleview/ev-comp-library';
import PhotosGenericDialog from '../photos-generic-dialog/PhotosGenericDialog';
import * as action from '../Photos.actions';
import PhotosMissingSummaryJsonModalContent from './PhotosMissingSummaryJsonModalContent';

const PhotosMissingSummaryJsonModal = ({
  orderId, allImages, selectedImage, setSelectedImage,
}) => {
  // local state
  const [dialogOpen, setDialogOpen] = useState(false);

  // actions
  const dispatch = useDispatch();
  const resetFacetOverview = () => dispatch(action.resetFacetOverviewAction());

  useEffect(() => {
    if (!isEmpty(allImages)) {
      setDialogOpen(true);
    } else {
      setDialogOpen(false);
    }
  }, [selectedImage]);

  const handleDialogClose = () => {
    setDialogOpen(false);
    resetFacetOverview();
    setSelectedImage({});
  };

  return (
    <EVBox>
      <PhotosGenericDialog
        open={dialogOpen}
        handleClose={() => handleDialogClose()}
        imageName={selectedImage.imageName}
      >
        <PhotosMissingSummaryJsonModalContent
          orderId={orderId}
          imageData={selectedImage}
          handleClose={() => handleDialogClose()}
          setImageFromModal={setSelectedImage}
        />
      </PhotosGenericDialog>
    </EVBox>
  );
};
PhotosMissingSummaryJsonModal.propTypes = {
  orderId: String,
  allImages: Array,
  selectedImage: Object,
  setSelectedImage: Function,
};

PhotosMissingSummaryJsonModal.defaultProps = {
  orderId: '',
  allImages: [],
  selectedImage: {},
  setSelectedImage: () => {},
};

export default PhotosMissingSummaryJsonModal;
