import React from 'react';
import { EVTab, EVTabs } from '@eagleview/ev-comp-library';
import { useTranslation } from 'react-i18next';
import { isEntitled } from 'utils/auth.utils';
import { FEATURE_ENTITLEMENTS, QC_ENHANCEMENT } from 'layout/entitleUser/EntitleUser.constants';
import { useDispatch, useSelector } from 'react-redux';
import { TABS } from 'layout/homeLayout/qcHome/QcHome.constants';
import get from 'lodash/get';
import useStyles from './QcHomeTabs.style';
import { setCurrentTab } from '../QcHome.actions';

/**
 * QcHomeTabs is component for switching between QcHomeTable tabs
 */
const QcHomeTabs = () => {
  // utility hooks
  const styles = useStyles();
  const { t } = useTranslation();

  // selectors
  const entitlements = useSelector((state) => state.entitleUserReducer.entitlements);
  const currentTab = useSelector((state) => state.qcHomeReducer.currentTab);
  const enableQcEnhancement = useSelector((state) => get(state.entitleUserReducer.featureFlags, QC_ENHANCEMENT, false));

  // actions
  const dispatch = useDispatch();
  const setTab = (payload) => dispatch(setCurrentTab(payload));

  // derived values
  const renderQcEscalated = enableQcEnhancement && isEntitled(entitlements, FEATURE_ENTITLEMENTS.MANAGE_QC_DECISIONS);
  const renderQcPending = isEntitled(entitlements, FEATURE_ENTITLEMENTS.MANAGE_QC_DECISIONS);
  const renderPendingAdjustment = isEntitled(entitlements, FEATURE_ENTITLEMENTS.MANAGE_MANUAL_AT_TIE_POINTS);

  return (
    <EVTabs
      className={styles.homeTabs}
      value={currentTab}
      textColor="primary"
      indicatorColor="primary"
      onChange={(_, val) => setTab(val)}
    >
      {
        renderQcEscalated
        && (
        <EVTab
          value={TABS.QC_ESCALATED}
          className={styles.tabHeading}
          label={t('qcHome.qcEscalatedClaims')}
          data-testid="qcHome.qcEscalatedClaims"
        />
        )
      }
      {
        renderQcPending
        && (
        <EVTab
          value={TABS.QC_PENDING}
          className={styles.tabHeading}
          label={t('qcHome.qcPendingClaims')}
          data-testid="qcHome.qcPendingClaims"
        />
        )
      }
      {
        renderPendingAdjustment
        && (
        <EVTab
          value={TABS.PENDING_ADJUSTMENT}
          className={styles.tabHeading}
          label={t('qcHome.pendingAdjustment')}
          data-testid="qcHome.pendingAdjustment"
        />
        )
      }
      <EVTab
        value={TABS.OPEN_ORDERS}
        className={styles.tabHeading}
        label={t('qcHome.openOrders')}
        data-testid="qcHome.openOrders"
      />
      <EVTab
        value={TABS.COMPLETED_ORDERS}
        className={styles.tabHeading}
        label={t('qcHome.completedOrders')}
        data-testid="qcHome.completedOrders"
      />
    </EVTabs>
  );
};

export default QcHomeTabs;
