import { makeStyles } from '@eagleview/ev-comp-library';
import { claimStatus } from '../Home.constants';

const chipColor = (status, theme) => {
  switch (status) {
    case claimStatus.READYFORREVIEW:
      return theme.evPalette.lime[100];
    default:
      return theme.evPalette.huckleberry[100];
  }
};

const chipTextColor = (status, theme) => {
  switch (status) {
    case claimStatus.READYFORREVIEW:
      return theme.evPalette.lime[700];
    default:
      return theme.evPalette.huckleberry[700];
  }
};

export default makeStyles((theme) => ({
  tableChip: {
    background: (P) => chipColor(P.status, theme),
    color: (P) => chipTextColor(P.status, theme),
    fontSize: theme.spacing(1.5),
  },
}));
