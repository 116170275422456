import { makeStyles } from '@eagleview/ev-comp-library';

const useStyles = makeStyles((theme) => ({
  topPanel: {
    minHeight: theme.spacing(7.5),
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: theme.evPalette.salt[100],
    color: theme.evPalette.charcoal[800],
    paddingLeft: theme.spacing(3),
  },
  addressText: {
    fontWeight: theme.typography.fontWeightBold,
    color: theme.evPalette.charcoal[700],
  },
  buttonContainer: {
    display: 'flex',
    float: 'right',
    justifyContent: 'space-between',
    marginRight: theme.spacing(2),
  },
  button: {
    textTransform: 'none',
    color: theme.palette.primary.main,
    marginRight: theme.spacing(1),
  },
  divider: {
    color: theme.evPalette.charcoal[400],
    marginLeft: theme.spacing(1),
  },
  completeButton: {
    color: theme.evPalette.salt[100],
    backgroundColor: theme.evPalette.lime[600],
    '&:hover': {
      backgroundColor: theme.evPalette.lime[800],
    },
  },
  primaryButton: {
    color: theme.evPalette.salt[100],
    backgroundColor: theme.evPalette.ocean[600],
    '&:hover': {
      backgroundColor: theme.evPalette.ocean[800],
    },
  },
}));

export default useStyles;
