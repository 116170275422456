/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ToastMessage from 'components/toast-message';
import {
  oneOfType, node, func, string,
} from 'prop-types';
import { isAuthorized, isEntitled } from 'utils/auth.utils';
import { FEATURE_ENTITLEMENTS } from 'layout/entitleUser/EntitleUser.constants';
import * as action from 'layout/entitleUser/EntitleUser.actions';
import EntitleUser from 'layout/entitleUser';
import { useTranslation } from 'react-i18next';

const PrivateRoute = ({ component: Component, name, ...rest }) => {
  // selectors
  const entitlements = useSelector((state) => state.entitleUserReducer.entitlements);

  // actions
  const dispatch = useDispatch();
  const fetchUserEntitlements = () => dispatch(action.fetchUserEntitlements());

  const isUserAuthorized = isAuthorized();
  const { t } = useTranslation();
  const isEntitledViewOrders = isEntitled(entitlements, FEATURE_ENTITLEMENTS.VIEW_ASSESS_SELF_ORGANIZATION_ORDERS)
    || isEntitled(entitlements, FEATURE_ENTITLEMENTS.VIEW_ASSESS_ALL_ORGANIZATION_ORDERS)
    || isEntitled(entitlements, FEATURE_ENTITLEMENTS.VIEW_SELF_JOBS);

  if (isUserAuthorized && !entitlements) {
    fetchUserEntitlements();
  }

  const renderComponent = (props) => {
    if (isUserAuthorized) {
      if (!window.location.pathname.includes('/adjuster-review') && name === 'adjuster') {
        return null;
      }
      if (!window.location.pathname.includes('/manual-at-adjustment/v2') && name === 'manual-at-adjustment') {
        return null;
      }
      if (entitlements) {
        // eslint-disable-next-line no-nested-ternary
        return entitlements.length > 0 ? (
          isEntitledViewOrders
            ? <Component {...props} />
            : (
              <ToastMessage
                alertMsg={`${t('adjuster.viewOrdersNotEntitled')}`}
                severity="error"
                open
                onClose={() => {
                  // Empty Block
                }}
              />
            )
        ) : <EntitleUser />;
      }
      return null;
    }
    return (
      <Redirect to={{
        pathname: '/authorize/callback',
        subRoute: window.location.pathname,
      }}
      />
    );
  };

  return <Route {...rest} render={(props) => renderComponent(props)} />;
};

PrivateRoute.propTypes = {
  component: oneOfType([node, func]).isRequired,
  name: string.isRequired,
};

export default PrivateRoute;
