import { makeStyles } from '@eagleview/ev-comp-library';

export default makeStyles((theme) => ({
  title: {
    fontSize: '18px',
    color: theme.evPalette.charcoal[900],
    fontweight: 600,
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1),
  },
  notesHeading: {
    fontSize: '16px',
    color: theme.evPalette.charcoal[900],
    marginLeft: theme.spacing(3),
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  notesEditField: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  paper: {
    padding: theme.spacing(2),
  },
  required: {
    fontSize: '10px',
    color: theme.evPalette.mango[700],
    marginLeft: theme.spacing(3),
  },
  confirmationButton: {
    background: theme.evPalette.raspberry[500],
    boxShadow: 'none',
    '&:hover': {
      background: theme.evPalette.raspberry[500],
      cursor: 'pointer',
    },
  },
}));
