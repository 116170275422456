import React from 'react';
import {
  EVDialog,
  EVDialogContent,
} from '@eagleview/ev-comp-library';
import PropTypes, { bool, func } from 'prop-types';
import { get, isEqual } from 'lodash';
import useStyles from './PhotosGenericDialog.styles';

const areEqual = (prevProps, nextProps) => {
  const prevChildren = get(prevProps, 'children', {});
  const nextChildren = get(nextProps, 'children', {});
  return isEqual(prevChildren, nextChildren);
};

// eslint-disable-next-line react/prop-types
const DialogContent = React.memo(({ children }) => (
  <EVDialogContent>
    {children}
  </EVDialogContent>
), areEqual);

DialogContent.displayName = 'DialogContent';

export { DialogContent };

const PhotosDialog = ({
  open, handleClose, children,
}) => {
  // utility hooks
  const styles = useStyles();

  return (
    <EVDialog
      open={open}
      onClose={handleClose}
      PaperProps={{ className: styles.paperStyle }}
    >
      <DialogContent>
        {children}
      </DialogContent>
    </EVDialog>
  );
};

PhotosDialog.propTypes = {
  children: PropTypes.node,
  open: bool.isRequired,
  handleClose: func.isRequired,
};

PhotosDialog.defaultProps = {
  children: 'This is the default content for the dialog.',
};

export default PhotosDialog;
