import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import {
  EVBox,
  EVButton,
  EVChevronLeft,
  EVChevronRight,
  EVDialog,
  EVDialogActions,
  EVDialogContent,
  EVDialogContentText,
  EVFormControl,
  EVIconButton,
  EVInformationPanel,
  EVMenu,
  EVMenuItem,
  EVPaperClipIcon,
  EVSelect,
  EVSwitch,
  EVTypography,
  EVVisibility,
  EVVisibilityOff,
  EVVisibilityOptions,
  EVCheckbox,
  EVTooltip,
  useTheme,
  EVExpand,
  EVDivider,
  EVClear,
  EVNoteIcon,
} from '@eagleview/ev-comp-library';
import EVMapViewer from '@eagleview/mapviewer-react';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import { bool, func } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ASSESS_IMAGES_API_ENDPOINT, REACT_APP_ENV } from 'constants.js';
import { isEntitled } from 'utils/auth.utils';
import ErrorBoundary from 'components/ErrorBoundary';
import {
  FEATURE_ENTITLEMENTS, ENABLE_FACET_RAW_IMAGES, ANOMALY_DESC, ASSESS_RAW_IMAGERY_ENTITLEMENTS,
} from 'layout/entitleUser/EntitleUser.constants';
import { logger, debugMode } from 'utils/utils';
import * as sharedUtil from 'utils/utils';
import * as action from '../Adjuster.actions';
import {
  ANOMALY_DECISIONS,
  FETCH_ANOMALY_METADATA,
  UNSET_ANOMALY_ID,
  UNSET_DELETE_INDEX,
  CONFIRMABLE_DECISIONS,
  TEST_SQUARE_ERRORS,
  ORDER_FACET_DECISION_PENDO_EVENT,
  GET_ANOMALY_NOTES,
} from '../Adjuster.constants';
import * as util from '../Adjuster.utils';
import useStyles from './WorkflowPanel.styles';
import WorkflowPanelTitle from './WorkflowPanelTitle';
import * as selectors from './WorkflowPanel.selectors';
import FacetImagesDialog from '../facet-images-dialog/FacetImagesDialog';
import FacetImagesRawImageryDialog from '../facet-images-raw-imagery-dialog/FacetImagesRawImageryDialog';

export const WorkflowPanel = ({
  showRemovedAnomalies,
  toggleRemovedAnomalies,
  showPotentialAnomalies,
  togglePotentialAnomalies,
  enableAnomalyDrawing,
  initiateAnomalyDrawing,
  switchAnomaly,
  displayTestSquare,
  hideWorkflowPanel,
  onMapViewerError,
  setLocationAndZoom,
  showMapviewerError,
  interactionStarted,
  isFacetClickDisplay,
  setIsFacetClickDisplay,
  updateInteractionObject,
  setSelectedArea,
  markAreaDamage,
  openAnomalyDescription,
  showAnomalyDescription,
  isAnomalyDescriptionPresent,
}) => {
  // utility hooks
  const styles = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();

  // selectors
  const selectedAnomalyView = useSelector(selectors.selectedAnomalyViewSelector);
  const selectedFacetObj = useSelector(selectors.selectedFacetObjSelector);
  const error = useSelector(selectors.errorSelector);
  const orderId = useSelector(selectors.orderIdSelector);
  const roofId = useSelector(selectors.roofIdSelector);
  const orderCompleted = useSelector((state) => state.adjusterReducer.orderCompleted);
  const selectedAnomalyId = useSelector(selectors.selectedAnomalyIdSelector);
  const selectedFacetId = useSelector(selectors.selectedFacetSelector);
  const selectedAnomalyMetadata = useSelector(selectors.selectedAnomalyMetadataSelector);
  const facetImagesPresent = useSelector(selectors.selectedFacetImagesPresentSelector);
  const facetHasAnomalies = useSelector(selectors.facetHasAnomaliesSelector);
  const selectedAnomalyObj = useSelector(selectors.selectedAnomalyObjSelector);
  const clickedFacetScanObj = useSelector(selectors.clickedFacetScanSelector);
  const selectedAnomalyIsUserAdded = useSelector(selectors.selectedAnomalyIsUserAddedSelector);
  const selectedAnomalyStatus = useSelector(selectors.selectedAnomalyStatusSelector);
  const selectedAnomalyImg = useSelector(selectors.selectedAnomalyImgSelector);
  const softMetalImages = useSelector(selectors.softMetalImagesSelector);
  const entitlements = useSelector((state) => state.entitleUserReducer.entitlements);
  const testSquare = useSelector((state) => state.adjusterReducer.testSquareData);
  const facetAnomaliesSubsetIds = useSelector((state) => state.adjusterReducer.facetAnomaliesSubset);
  const testSquareError = useSelector((state) => state.adjusterReducer.testSquareError);
  const facetTestSquareDisabledMap = useSelector((state) => state.adjusterReducer.facetTestSquareDisabledMap);
  const featureFlags = useSelector((state) => state.adjusterReducer.featureFlags);
  const navigationControl = useSelector((state) => state.adjusterReducer.workflowPanelNavigationControl);
  const displayFacetPolygon = useSelector((state) => state.adjusterReducer.workflowPanelDisplayFacetPolygon);
  const setBaseImgParent = useSelector((state) => state.adjusterReducer.workflowPanelSetBaseImgParent);
  const baseImage = useSelector((state) => state.adjusterReducer.baseImage);
  const selectedStructureId = useSelector((state) => state.adjusterReducer.selectedStructureId);
  const isFacetRawImagesEnabled = useSelector((state) => get(state.entitleUserReducer.featureFlags, ENABLE_FACET_RAW_IMAGES, false));
  const isFacetRawImageryWorkflowEnabled = useSelector((state) => get(state.entitleUserReducer.featureFlags, ASSESS_RAW_IMAGERY_ENTITLEMENTS, false));
  const anomalyImages = useSelector(selectors.anomalyImagesSelector);
  const showAnomalyNotesButton = useSelector((state) => get(state.entitleUserReducer.featureFlags, ANOMALY_DESC, false));
  const loading = useSelector((state) => state.adjusterReducer.loading);
  const authToken = useSelector((state) => state.adjusterReducer.accessToken);

  const isTestSquareFeatureAvailable = featureFlags.includes('TEST_SQUARE_SUPPORTED');
  const isFacetTestsquareDisabled = facetTestSquareDisabledMap[selectedFacetId];

  // actions
  const dispatch = useDispatch();
  const saveDecision = (payload) => dispatch(action.saveFacetReviewDecisionAction(payload));
  const saveAnomalyDecision = (payload) => dispatch(action.saveAnomalyDecisionAction(payload));
  const setAnomalyDecision = (payload) => dispatch(action.setAnomalyDecisionAction(payload));
  const setAnomalyId = (payload) => dispatch(action.setAnomalyIdAction(payload));
  const fetchAnomalyMetadata = (payload) => dispatch(action.fetchAnomalyMetadataAction(payload));
  const deleteUserAnomaly = (payload) => dispatch(action.deleteUserAnomalyAction(payload));
  const setSelectedAnomalyView = (payload) => dispatch(action.setSelectedAnomalyViewAction(payload));
  const fetchSoftMetalImages = (payload) => dispatch(action.fetchSoftMetalImagesAction(payload));
  const showCustomErrorToast = (payload) => dispatch(action.showErrorToastAction(payload));
  const testSquareStatusUpdate = (payload) => dispatch(action.testSquareStatusUpdateAction(payload));
  const toggleAnomalyIncludeInReport = (payload) => dispatch(action.toggleAnomalyIncludeInReportAction(payload));
  const fetchAnomalyImages = (payload) => dispatch(action.fetchAnomalyImagesAction(payload));

  // local state
  const [anchorEl, setAnchorEl] = useState(null);
  const [anomalyImage, setAnomalyImage] = useState([]);
  const [deleteUserAnomalyDialogOpen, setDeleteUserAnomalyDialogOpen] = useState(false);
  const [deleteAnomalyIndex, setDeleteAnomalyIndex] = useState(UNSET_DELETE_INDEX);
  const [facetImageGalleryDialogOpen, setfacetImageGalleryDialogOpen] = useState(false);
  const [isAnomalyImages, setIsAnomalyImages] = useState(false);
  const [isMapLoaded, setIsMapLoaded] = useState(false);
  const [currentView, setCurrentView] = useState({
    lonLat: { lat: 0, lon: 0 },
    zoom: 1,
    rotation: 0,
  });

  // derived values
  const selectedAnomalyIsConfirmed = selectedAnomalyStatus === ANOMALY_DECISIONS.CONFIRMED;
  const selectedAnomalyIsRemoved = selectedAnomalyStatus === ANOMALY_DECISIONS.REMOVED;

  const getUpdatedFacetObject = () => {
    if (isEmpty(selectedFacetObj)) return {};
    const facetAnomalies = get(selectedFacetObj, 'anomalyList', []);
    return isFacetTestsquareDisabled ? { ...selectedFacetObj }
      : {
        ...selectedFacetObj,
        anomalyList: facetAnomalies.filter((anom) => facetAnomaliesSubsetIds.includes(get(anom, 'anomalyId', ''))),
      };
  };
  const anomaliesBreakdown = util.getAnomaliesBreakdown(get(getUpdatedFacetObject(), 'anomalyList', []));

  // TODO: double check if UNCONFIRMED is a valid anomaly status
  const selectedAnomalyIsConfirmable = CONFIRMABLE_DECISIONS.includes(selectedAnomalyStatus);

  useEffect(() => {
    if (showPotentialAnomalies && (deleteAnomalyIndex !== UNSET_DELETE_INDEX)) {
      setDeleteAnomalyIndex(UNSET_DELETE_INDEX);
    }
    if (selectedFacetId && selectedFacetId !== '') {
      fetchSoftMetalImages({ facetAliasName: selectedFacetObj.facetAliasName, orderId, structureId: selectedStructureId });
    }
  }, [selectedFacetId]);

  useEffect(() => {
    if (!isEmpty(selectedAnomalyView)) {
      setCurrentView(selectedAnomalyView);
      if (!isFacetClickDisplay && isFacetRawImagesEnabled) {
        fetchAnomalyImages({
          facetAliasName: selectedFacetObj.facetAliasName,
          orderId,
          structureId: selectedStructureId,
          lat: selectedAnomalyView.lonLat.lat,
          long: selectedAnomalyView.lonLat.lon,
        });
      }
    }
  }, [selectedAnomalyView]);

  useEffect(() => {
    if (isFacetClickDisplay && !isEmpty(anomalyImages)) {
      fetchAnomalyMetadata(get(anomalyImages[0], 'urn', ''));
    }
  }, [anomalyImages]);

  useEffect(() => {
    if (!selectedFacetObj || !facetHasAnomalies || isFacetClickDisplay) return;
    if (selectedAnomalyImg && selectedAnomalyImg.imageUrn) {
      fetchAnomalyMetadata(selectedAnomalyImg.imageUrn);
    }
  }, [selectedAnomalyId, isFacetClickDisplay]);

  const getAnomalyAsset = (img, authtoken) => {
    try {
      let parentAsset = null;
      if (setBaseImgParent && baseImage) {
        parentAsset = util.getMapviewerAsset({
          assetType: 'iwimage',
          tileUrls: [`${ASSESS_IMAGES_API_ENDPOINT}/${baseImage.image_urn}/tiles/{z}/{x}/{y}?format=IMAGE_FORMAT_JPEG_PNG&`
            + `${sharedUtil.getAuthParam(authtoken)}`],
          metadata: baseImage,
        }, showMapviewerError);
      }
      const asset = util.getMapviewerAsset({
        assetType: 'iwimage',
        tileUrls: [
          `${ASSESS_IMAGES_API_ENDPOINT}/${img.image_urn}/tiles/{z}/{x}/{y}?format=IMAGE_FORMAT_JPEG_PNG&${sharedUtil.getAuthParam(authtoken)}`,
        ],
        metadata: img,
        parentAsset,
      }, showMapviewerError);
      return asset || {};
    } catch (e) {
      logger('error', 'Error occurred in workflow panel: ', get(e, 'message', e));
      throw e;
    }
  };

  useEffect(() => {
    if (!isEmpty(selectedAnomalyMetadata) && isMapLoaded) {
      try {
        const anomalyAsset = getAnomalyAsset(selectedAnomalyMetadata, authToken);
        setAnomalyImage(anomalyAsset);
        const selectedObj = isFacetClickDisplay ? clickedFacetScanObj : selectedAnomalyObj;
        setSelectedAnomalyView({ ...selectedObj, parentAsset: anomalyAsset });
      } catch (e) {
        setTimeout(() => showCustomErrorToast({ message: 'adjuster.mapViewerError', toastAutoHideDuration: null }), 0);
      }
    }
    // eslint-disable-next-line
  }, [selectedAnomalyMetadata, isMapLoaded, authToken]);

  const handleClick = (event) => setAnchorEl(event.currentTarget);

  const handleClose = () => setAnchorEl(null);

  const selectFacetDecision = ({ target: { value: newDecision } }) => {
    if (isEntitled(entitlements, FEATURE_ENTITLEMENTS.MANAGE_DECISIONS) && !orderCompleted) {
      util.publishPendoEvent(ORDER_FACET_DECISION_PENDO_EVENT, { orderId });
      saveDecision({
        orderId,
        facetId: selectedFacetObj.facetId,
        data: {
          decision: newDecision,
          facetAliasName: selectedFacetObj.facetAliasName,
          roofId,
          includeInReport: isEntitled(entitlements, FEATURE_ENTITLEMENTS.MANAGE_REPORTS)
            ? !(newDecision === 'UNDECIDED') : selectedFacetObj.includeInReport,
        },
      });
    }
  };

  const setPreviousAnomaly = () => {
    const anomalyId = selectedAnomalyId === UNSET_ANOMALY_ID ? null : selectedAnomalyId;
    const updatedFacet = getUpdatedFacetObject();
    const anomaly = updatedFacet.anomalyList.find((a) => a && a.anomalyId === anomalyId);
    if (showPotentialAnomalies || anomaly.decision === ANOMALY_DECISIONS.CONFIRMED) {
      const prvAnomalyId = anomaly.decision === ANOMALY_DECISIONS.CONFIRMED
        ? util.getPreviousConfirmedAnomalyId(
          updatedFacet,
          anomalyId,
          deleteAnomalyIndex,
        )
        : util.getPreviousUnconfirmedAnomalyId(
          updatedFacet,
          anomalyId,
          deleteAnomalyIndex,
        );
      if (prvAnomalyId !== UNSET_ANOMALY_ID) {
        setIsFacetClickDisplay(false);
        setDeleteAnomalyIndex(UNSET_DELETE_INDEX);
        setAnomalyId(prvAnomalyId);
        switchAnomaly(prvAnomalyId);
      }
    } else {
      setAnomalyId(UNSET_ANOMALY_ID);
    }
  };

  const setNextAnomaly = (forceToUnconfirmed = false) => {
    const anomalyId = selectedAnomalyId === UNSET_ANOMALY_ID ? null : selectedAnomalyId;
    const updatedFacet = getUpdatedFacetObject();
    const anomaly = updatedFacet.anomalyList.find((a) => a && a.anomalyId === anomalyId);
    if (showPotentialAnomalies || anomaly.decision === ANOMALY_DECISIONS.CONFIRMED) {
      const fromIndex = deleteAnomalyIndex > 0 ? deleteAnomalyIndex - 1 : 0;
      const nextAnomalyId = anomaly.decision === ANOMALY_DECISIONS.CONFIRMED && !forceToUnconfirmed
        ? util.getNextBestConfirmedAnomalyId(
          updatedFacet,
          anomalyId,
          fromIndex,
        )
        : util.getNextBestUnconfirmedAnomalyId(
          updatedFacet,
          anomalyId,
          fromIndex,
        );
      if (nextAnomalyId !== UNSET_ANOMALY_ID) {
        setIsFacetClickDisplay(false);
        setDeleteAnomalyIndex(UNSET_DELETE_INDEX);
        setAnomalyId(nextAnomalyId);
        switchAnomaly(nextAnomalyId);
      }
    } else {
      setAnomalyId(UNSET_ANOMALY_ID);
    }
  };

  const confirmOrRemoveAnomalyDecision = (isRemove) => {
    if (!orderId || !selectedAnomalyId || selectedAnomalyId === UNSET_ANOMALY_ID) return;
    const anomaly = selectedFacetObj.anomalyList.find((a) => a.anomalyId === selectedAnomalyId);
    if (anomaly) {
      const orgDecision = anomaly.decision;
      anomaly.decision = isRemove ? ANOMALY_DECISIONS.REMOVED : ANOMALY_DECISIONS.CONFIRMED;
      anomaly.includeInReport = isEntitled(entitlements, FEATURE_ENTITLEMENTS.MANAGE_REPORTS) ? !isRemove : anomaly.includeInReport;
      setAnomalyDecision(anomaly);
      const anomalyId = util.getBestUnConfirmedAnomalyId(getUpdatedFacetObject(), selectedAnomalyId);
      if (anomalyId !== UNSET_ANOMALY_ID) {
        setNextAnomaly(!isRemove);
      }
      saveAnomalyDecision({ orderId, anomalyData: anomaly, orgDecision });
    }
    if (!selectedFacetObj.includeInReport && !isRemove) {
      saveDecision({
        orderId,
        facetId: selectedFacetObj.facetId,
        data: {
          decision: selectedFacetObj.decision,
          facetAliasName: selectedFacetObj.facetAliasName,
          roofId,
          includeInReport: true,
        },
      });
    }
  };

  const handleDeleteUserAnomalyOpen = () => setDeleteUserAnomalyDialogOpen(true);
  const handleDeleteUserAnomalyClose = () => setDeleteUserAnomalyDialogOpen(false);

  const handleDeleteUserAnomalyConfirm = () => {
    if (!orderId || !selectedAnomalyId || selectedAnomalyId === UNSET_ANOMALY_ID) return;
    const index = selectedFacetObj.anomalyList.findIndex(
      (a) => a && a.anomalyId === selectedAnomalyId,
    );
    setDeleteAnomalyIndex(index);
    setAnomalyId(UNSET_ANOMALY_ID);
    deleteUserAnomaly({ orderId, facetId: selectedFacetObj.facetId, anomalyId: selectedAnomalyId });
    setDeleteUserAnomalyDialogOpen(false);
  };

  const setPotentialAnomalies = () => {
    if (showPotentialAnomalies && selectedAnomalyIsConfirmable) {
      setAnomalyId(UNSET_ANOMALY_ID);
    }
    togglePotentialAnomalies(!showPotentialAnomalies);
  };

  const setRemovedAnomalies = () => {
    if (showRemovedAnomalies && selectedAnomalyIsRemoved) {
      setAnomalyId(UNSET_ANOMALY_ID);
    }
    toggleRemovedAnomalies(!showRemovedAnomalies);
  };

  const testSquareHandleChange = (event) => {
    const { checked } = event.target;
    // disable test square toggling for completed orders
    if (orderCompleted) return;
    toggleRemovedAnomalies(false);
    togglePotentialAnomalies(true);
    const data = {
      isTestsquareDisabled: !isFacetTestsquareDisabled,
    };
    if (checked) {
      const anomalyId = util.getInitialAnomalyId({
        ...selectedFacetObj,
        anomalyList: selectedFacetObj.anomalyList
          .filter((anomaly) => (get(selectedFacetObj, 'testsquare.anomalies', []) || []).includes(anomaly.anomalyId)),
      });
      if (!isEmpty(testSquare)) {
        displayTestSquare(true);
      }
      if (!isFacetClickDisplay) {
        setAnomalyId(anomalyId);
      }
      setLocationAndZoom(get(selectedFacetObj, 'testsquare.centroid.coordinates', {}));
    } else if (!isEmpty(selectedAnomalyId)) {
      if (!isFacetClickDisplay) {
        const anomalyId = util.getInitialAnomalyId(selectedFacetObj);
        setAnomalyId(anomalyId);
        switchAnomaly(anomalyId);
      }
      if (!enableAnomalyDrawing) {
        updateInteractionObject({
          type: 'tracking',
          enable: true,
        });
      }
    }
    testSquareStatusUpdate({
      orderId, facetId: selectedFacetObj.facetId, data,
    });
  };

  const assets = [];
  if (!isEmpty(anomalyImage) && !isEmpty(selectedAnomalyView)) {
    assets.push(anomalyImage);
  }
  if (displayFacetPolygon && !isEmpty(selectedFacetObj)) {
    assets.push({
      assetType: 'annotation',
      data: [{
        geometries: [selectedFacetObj.facetOutline.value],
        style: {
          strokeColor: 'white',
          strokeWidth: 2,
          fillOpacity: 0,
          fillColor: theme.evPalette.amber[100],
        },
      }],
    });
  }

  const openAnomalyImagesDialog = () => {
    setfacetImageGalleryDialogOpen(true);
    setIsAnomalyImages(true);
  };

  const closeAnomalyImagesDialog = () => {
    setfacetImageGalleryDialogOpen(false);
    setIsAnomalyImages(false);
  };

  const getAnomalyType = () => {
    if (isFacetClickDisplay) {
      return 'workflowpanel.area';
    }
    if (!selectedAnomalyIsUserAdded && !selectedAnomalyIsConfirmed) {
      return 'workflowpanel.anomaly';
    }
    if (selectedAnomalyIsUserAdded || selectedAnomalyIsConfirmed) {
      return 'workflowpanel.damage';
    }
    return '';
  };

  const closeMapViewerDisplay = () => {
    setAnomalyId(UNSET_ANOMALY_ID);
    setSelectedArea({});
    setIsFacetClickDisplay(false);
  };

  const handleOnMapReady = () => {
    setIsMapLoaded(true);
  };

  const displayMapviewer = (facetHasAnomalies && selectedAnomalyId !== UNSET_ANOMALY_ID) || isFacetClickDisplay;
  return (
    !isEmpty(selectedFacetObj) && (
      <EVInformationPanel
        draggableProps={{ bounds: 'parent' }}
        titleComponent={(
          <WorkflowPanelTitle
            facetName={selectedFacetObj.facetAliasName}
            facetDecision={selectedFacetObj.decision}
            area={Math.round(selectedFacetObj.facetOutline.area * 10) / 10}
          />
        )}
        enableCloseOnUI={false}
        className={clsx({ [styles.panel]: true, [styles.cursorNotAllowed]: !facetImagesPresent, [styles.hideWorkflowPanel]: hideWorkflowPanel })}
        dragEnabled
        width="288px"
        height="auto"
      >
        <EVBox
          className={clsx({ [styles.container]: true, [styles.disable]: !facetImagesPresent })}
        >
          <EVBox className={styles.subContainer}>
            <EVTypography className={styles.decisionTitle}>
              {t('workflowpanel.decision')}
            </EVTypography>
            <EVFormControl
              className={styles.selectBar}
              variant="outlined"
              size="small"
            >
              {(isEntitled(entitlements, FEATURE_ENTITLEMENTS.MANAGE_DECISIONS) && !orderCompleted) ? (
                <EVSelect
                  id="facetDecisionSelect"
                  onChange={selectFacetDecision}
                  className={styles.select}
                  value={selectedFacetObj.decision}
                >
                  <EVMenuItem className={styles.selectBarItem} value="UNDECIDED">
                    {t('decision.undecided')}
                  </EVMenuItem>
                  <EVMenuItem className={styles.selectBarItem} value="REPAIR">
                    {t('decision.repair')}
                  </EVMenuItem>
                  <EVMenuItem className={styles.selectBarItem} value="REPLACE">
                    {t('decision.replace')}
                  </EVMenuItem>
                  <EVMenuItem className={styles.selectBarItem} value="NO DAMAGE">
                    {t('decision.nodamage')}
                  </EVMenuItem>
                </EVSelect>
              ) : (
                <EVBox className={styles.facetDecisionLabel} id="facetDecisionLabel">
                  <EVTypography>
                    {selectedFacetObj.decision}
                  </EVTypography>
                </EVBox>
              )}
              <EVTooltip title={isEmpty(softMetalImages) ? t('workflowpanelTooltip.noSoftMetals') : ''} placement="top">
                <EVBox>
                  <EVButton
                    className={styles.buttonSoftMetals}
                    variant="outlined"
                    onClick={() => setfacetImageGalleryDialogOpen(true)}
                    disabled={isEmpty(softMetalImages)}
                  >
                    {t('workflowpanel.softMetals')}
                  </EVButton>
                </EVBox>
              </EVTooltip>
            </EVFormControl>
            {isEntitled(entitlements, FEATURE_ENTITLEMENTS.MANAGE_DECISIONS) && (
            <EVBox className={styles.checkboxContainer} display={isTestSquareFeatureAvailable ? 'block' : 'none'}>
              <EVTooltip title={testSquareError !== null ? t(`workflowpanel.${get(TEST_SQUARE_ERRORS, testSquareError)}`) : ''} placement="top-end" arrow>
                <span>
                  <EVCheckbox
                    checked={!isFacetTestsquareDisabled}
                    className={styles.checkbox}
                    onChange={testSquareHandleChange}
                    color="primary"
                    disabled={testSquareError !== null || orderCompleted}
                  />
                  <span className={styles.checkboxLabel}>{t('workflowpanel.testSquareCheckBox')}</span>
                </span>
              </EVTooltip>
            </EVBox>
            )}
          </EVBox>
          <EVDivider className={styles.divider} />
          <EVBox className={styles.subContainer}>
            <EVBox className={styles.heightBar}>
              <EVTypography className={styles.anomalyCountFont}>
                {`${get(anomaliesBreakdown, 'totalConfirmed', []).length} ${t('workflowpanel.damages')}`}
              </EVTypography>
              {isEntitled(entitlements, FEATURE_ENTITLEMENTS.MANAGE_DECISIONS) && !orderCompleted && (
              <EVButton
                id="addAnomaly"
                className={clsx(
                  isFacetClickDisplay ? styles.buttonRemove : styles.addAnomaly,
                  {
                    [styles.addAnomalySelected]: enableAnomalyDrawing,
                  },
                )}
                disabled={!facetImagesPresent || !interactionStarted}
                variant="outlined"
                {...(!isFacetClickDisplay ? { color: 'primary' } : {})}
                disableElevation
                onClick={() => {
                  if (isFacetClickDisplay) {
                    closeMapViewerDisplay();
                  }
                  initiateAnomalyDrawing(!enableAnomalyDrawing);
                }}
              >
                  {t('workflowpanel.addDamage')}
              </EVButton>
              )}
            </EVBox>
            <EVBox className={styles.heightBar}>
              <EVTypography>
                {`${get(anomaliesBreakdown, 'potentialCV', []).length} ${t('workflowpanel.anomalies')}`}
              </EVTypography>
              <EVIconButton
                id="toggleAnomalies"
                className={styles.menuItemButton}
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleClick}
              >
                <EVVisibilityOptions />
              </EVIconButton>
              <EVMenu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <EVMenuItem onClick={setPotentialAnomalies} id="setPotentialAnomalies">
                  {`${get(anomaliesBreakdown, 'potentialCV', []).length} ${t('workflowpanel.potential')}`}
                  {showPotentialAnomalies ? (
                    <EVVisibility className={styles.menuItemIcon} />
                  ) : (
                    <EVVisibilityOff className={styles.menuItemIcon} />
                  )}
                </EVMenuItem>
                <EVMenuItem onClick={setRemovedAnomalies} id="setRemovedAnomalies">
                  {`${get(anomaliesBreakdown, 'removed', []).length} ${t('workflowpanel.removed')}`}
                  {showRemovedAnomalies ? (
                    <EVVisibility className={styles.menuItemIcon} />
                  ) : (
                    <EVVisibilityOff className={styles.menuItemIcon} />
                  )}
                </EVMenuItem>
              </EVMenu>
            </EVBox>
          </EVBox>
          { displayMapviewer && (
          <>
            <EVDivider className={styles.divider} />
            <EVBox className={styles.subContainer}>
              <EVBox className={clsx([styles.heightBar, styles.flexEnd])}>
                <EVTypography className={clsx([styles.remainingSpace], styles.annomalyTypeText)}>
                  {t(getAnomalyType())}
                </EVTypography>
                {!isFacetClickDisplay && (
                <>
                  <EVButton className={styles.iconButtons} id="previousUnconfirmedAnomaly">
                    <EVChevronLeft
                      onClick={setPreviousAnomaly}
                    />
                  </EVButton>
                  <EVButton className={styles.iconButtons} id="nextUnconfirmedAnomaly">
                    <EVChevronRight
                      onClick={() => setNextAnomaly()}
                    />
                  </EVButton>
                </>
                )}
                {showAnomalyNotesButton && (
                <EVIconButton
                  className={clsx({ [styles.iconButtons]: true },
                    { [styles.anomalyNotesButtonSelected]: showAnomalyDescription },
                    { [styles.anomalyNotesButtonHighlighted]: isAnomalyDescriptionPresent })}
                  onClick={() => openAnomalyDescription()}
                  disabled={selectedAnomalyId === UNSET_ANOMALY_ID || isEmpty(selectedAnomalyId) || loading[GET_ANOMALY_NOTES]}
                >
                  <EVNoteIcon />
                </EVIconButton>
                )}
                <EVIconButton className={styles.iconButtons}>
                  <EVClear onClick={() => closeMapViewerDisplay()} />
                </EVIconButton>
              </EVBox>
              <EVBox className={styles.anomalyMapContainer} id="workflowMapViewer">
                <ErrorBoundary
                  componentName="WorkflowPanel: MapViewer"
                  alertMsg={t('workFlowPanel.mapViewerCrash')}
                >
                  <EVMapViewer
                    debug={debugMode}
                    view={currentView}
                    assets={assets}
                    baseMap="blank"
                    navigationControl={navigationControl}
                    initialConfig={{
                      style: { backgroundColor: '#000' },
                      envName: REACT_APP_ENV,
                    }}
                    onMapReady={handleOnMapReady}
                    onError={(e) => onMapViewerError(e, 'workflow panel')}
                  />
                  {isFacetRawImagesEnabled
                    ? (
                      <EVIconButton id="rawImagesButton" onClick={openAnomalyImagesDialog} className={styles.expansionButton}>
                        <EVExpand className={styles.expansionIcon} />
                      </EVIconButton>
                    ) : null}

                </ErrorBoundary>
              </EVBox>
              <EVBox className={styles.heightBar}>
                {selectedAnomalyId === UNSET_ANOMALY_ID
                && isFacetClickDisplay
                && isEntitled(entitlements, FEATURE_ENTITLEMENTS.MANAGE_DECISIONS)
                && !orderCompleted
                && (
                <EVBox className={styles.markDamage}>
                  <EVButton
                    id="confirmAnomalyDecision"
                    className={styles.buttonConfirm}
                    color="primary"
                    variant="contained"
                    disableElevation
                    onClick={() => markAreaDamage()}
                  >
                    {t('workflowpanel.markDamage')}
                  </EVButton>
                </EVBox>
                )}
                <EVBox display="flex" justifyContent="center" alignItems="center">
                  {selectedAnomalyId !== UNSET_ANOMALY_ID
                  && (selectedAnomalyIsUserAdded
                    || (!selectedAnomalyIsUserAdded && selectedAnomalyIsConfirmed)) && (
                      <>
                        <EVPaperClipIcon color="primary" />
                        <EVSwitch
                          id="includeAnomalyInReport"
                          color="primary"
                          checked={get(selectedAnomalyObj, 'data[0].properties.includeInReport')}
                          disabled={!isEntitled(entitlements, FEATURE_ENTITLEMENTS.MANAGE_REPORTS) || orderCompleted}
                          onChange={() => toggleAnomalyIncludeInReport({
                            orderID: orderId,
                            anomalyID: selectedAnomalyId,
                            data: {
                              includeInReport: !get(selectedAnomalyObj, 'data[0].properties.includeInReport'),
                            },
                          })}
                        />
                      </>
                  )}
                </EVBox>
                {selectedAnomalyId !== UNSET_ANOMALY_ID && (
                  (isEntitled(entitlements, FEATURE_ENTITLEMENTS.MANAGE_DECISIONS) && !orderCompleted) ? (
                    <EVBox>
                      {selectedAnomalyIsUserAdded && (
                      <EVButton
                        id="deleteAnomalyUserAdded"
                        className={styles.buttonRemove}
                        variant="outlined"
                        disabled={error[FETCH_ANOMALY_METADATA]}
                        onClick={handleDeleteUserAnomalyOpen}
                      >
                        {t('workflowpanel.remove')}
                      </EVButton>
                      )}
                      {!selectedAnomalyIsUserAdded && !selectedAnomalyIsRemoved && (
                      <EVButton
                        id="removeAnomalyDecision"
                        className={styles.buttonRemove}
                        variant="outlined"
                        disabled={error[FETCH_ANOMALY_METADATA]}
                        onClick={() => confirmOrRemoveAnomalyDecision(true)}
                      >
                        {selectedAnomalyIsConfirmed ? t('workflowpanel.remove') : t('workflowpanel.hide')}
                      </EVButton>
                      )}
                      {!selectedAnomalyIsUserAdded && selectedAnomalyIsRemoved && (
                      <EVButton
                        id="reinstateAnomalyDecision"
                        variant="outlined"
                        className={styles.buttonRemove}
                        disabled={error[FETCH_ANOMALY_METADATA]}
                        onClick={() => confirmOrRemoveAnomalyDecision(false)}
                      >
                        {t('workflowpanel.reinstate')}
                      </EVButton>
                      )}
                      {((!selectedAnomalyIsUserAdded && selectedAnomalyIsConfirmable) || isFacetClickDisplay)
                      && (
                      <EVButton
                        id="confirmAnomalyDecision"
                        className={styles.buttonConfirm}
                        color="primary"
                        disabled={error[FETCH_ANOMALY_METADATA]}
                        variant="contained"
                        disableElevation
                        onClick={() => confirmOrRemoveAnomalyDecision(false)}
                      >
                        {t('workflowpanel.markDamage')}
                      </EVButton>
                      )}
                    </EVBox>
                  ) : (
                    <EVBox>
                      <EVTypography>
                        {(selectedAnomalyIsConfirmed && t('workflowPanel.confirmed'))
                        || (selectedAnomalyIsRemoved && t('workflowPanel.removed'))}
                      </EVTypography>
                    </EVBox>
                  )
                )}
              </EVBox>
            </EVBox>
          </>
          )}
        </EVBox>
        <EVDialog open={deleteUserAnomalyDialogOpen} onClose={handleDeleteUserAnomalyClose}>
          <EVDialogContent>
            <EVDialogContentText>{t('workflowpanel.userAnomalyDeletConfirm')}</EVDialogContentText>
          </EVDialogContent>
          <EVDialogActions>
            <EVButton onClick={handleDeleteUserAnomalyClose}>{t('cancel')}</EVButton>
            <EVButton onClick={handleDeleteUserAnomalyConfirm} color="primary" autoFocus>
              {t('delete')}
            </EVButton>
          </EVDialogActions>
        </EVDialog>
        <ErrorBoundary
          componentName="WorkflowPanel: FacetImagesDialog"
          alertMsg={t('workFlowPanel.softMetalDialogCrash')}
        >
          {isAnomalyImages && isFacetRawImageryWorkflowEnabled
            ? (
              <FacetImagesRawImageryDialog
                open={facetImageGalleryDialogOpen}
                handleClose={closeAnomalyImagesDialog}
                facetAliasName={selectedFacetObj.facetAliasName}
                facetId={selectedFacetObj.facetId}
                facetDecision={selectedFacetObj.decision}
                facetIncludeInReport={selectedFacetObj.includeInReport}
              />
            ) : (
              <FacetImagesDialog
                open={facetImageGalleryDialogOpen}
                handleClose={closeAnomalyImagesDialog}
                facetAliasName={selectedFacetObj.facetAliasName}
                isAnomalyImages={isAnomalyImages}
                facetId={selectedFacetObj.facetId}
                facetDecision={selectedFacetObj.decision}
                facetIncludeInReport={selectedFacetObj.includeInReport}
              />
            ) }
        </ErrorBoundary>
      </EVInformationPanel>
    )
  );
};

WorkflowPanel.propTypes = {
  showRemovedAnomalies: bool.isRequired,
  toggleRemovedAnomalies: func.isRequired,
  showPotentialAnomalies: bool.isRequired,
  togglePotentialAnomalies: func.isRequired,
  enableAnomalyDrawing: bool.isRequired,
  initiateAnomalyDrawing: func,
  switchAnomaly: func,
  displayTestSquare: func,
  hideWorkflowPanel: bool.isRequired,
  onMapViewerError: func,
  setLocationAndZoom: func,
  showMapviewerError: func,
  interactionStarted: bool.isRequired,
  isFacetClickDisplay: bool,
  setIsFacetClickDisplay: func,
  updateInteractionObject: func.isRequired,
  setSelectedArea: func.isRequired,
  markAreaDamage: func.isRequired,
  openAnomalyDescription: func.isRequired,
  showAnomalyDescription: bool.isRequired,
  isAnomalyDescriptionPresent: bool.isRequired,
};

WorkflowPanel.defaultProps = {
  initiateAnomalyDrawing: () => {
    // This is an empty block
  },
  switchAnomaly: () => {
    // This is an empty block
  },
  displayTestSquare: () => {
    // This is an empty block
  },
  onMapViewerError: () => {
    // This is an empty block
  },
  setLocationAndZoom: () => {
    // This is an empty block
  },
  showMapviewerError: () => {
    // This is an empty block
  },
  isFacetClickDisplay: false,
  setIsFacetClickDisplay: () => {
    // This is an empty block
  },
};

export default WorkflowPanel;
