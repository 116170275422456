import get from 'lodash/get';

const SHARE_TOKEN = 'share_token';
const ONE_DAY_IN_SECONDS = 24 * 60 * 60;

/**
 * Store access-token in local storage.
 * @param {String} token
 */
export const setAuthToken = (token) => {
  localStorage.setItem('token', JSON.stringify(token));
};

/**
 * Return access-token from local storage.
 */
export const getAuthToken = () => {
  const token = JSON.parse(localStorage.getItem('token'));
  return get(token, 'access_token', '');
};

/**
 * Extract access-token from URL & store in local storage.
 */
export const getTokenFromUrl = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const token = urlSearchParams.get('access_token');
  if (token) {
    setAuthToken({ access_token: token });
  }
  return getAuthToken();
};

/**
 * Get user details from local storage.
 */
export const getUser = () => {
  const encodedUser = localStorage.getItem('ev_ud');
  if (encodedUser) {
    const user = btoa(atob(encodedUser)) === encodedUser ? atob(encodedUser) : null;
    try {
      return JSON.parse(user);
    } catch (e) {
      return {};
    }
  }
  return {};
};

export const isAuthorized = () => (getTokenFromUrl() !== '' || !!localStorage.getItem('token'));

/**
 * Checks if the featureEntitlement is present in the entitlementsList
 * @param {Array} entitlementsList - List of entitlements
 * @param {string} featureEntitlement - Entitlement related to the feature
 */
export const isEntitled = (entitlementsList = [], featureEntitlement = '') => entitlementsList.includes(featureEntitlement);

export const getSharedUuid = () => window.location.pathname.split('/')[2];

export const getUuidsFromLS = () => {
  if (localStorage.getItem(SHARE_TOKEN)) {
    return JSON.parse(localStorage.getItem(SHARE_TOKEN));
  }
  return {};
};

export const isLinkAuthorized = () => {
  const uuid = getSharedUuid();
  const uuids = getUuidsFromLS();
  return !!uuids[uuid];
};

export const isStale = (t) => ((Date.now() - t) > 1000 * ONE_DAY_IN_SECONDS);

export const getShareToken = () => {
  const uuids = getUuidsFromLS();
  const uuid = getSharedUuid();
  if (uuids[uuid]) {
    return uuids[uuid].v;
  }
  return '';
};

export const setShareToken = (token) => {
  const nuuids = {};
  const uuids = getUuidsFromLS() || {};
  const uuid = getSharedUuid();
  const uuidList = Object.keys(uuids);

  for (let i = 0; i < uuidList.length; i += 1) {
    if (!isStale(uuids[uuidList[i]].t)) {
      nuuids[uuidList[i]] = uuids[uuidList[i]];
    }
  }

  nuuids[uuid] = { v: token, t: Date.now() };
  localStorage.setItem(SHARE_TOKEN, JSON.stringify(nuuids));
};
