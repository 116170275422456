import React, { useState } from 'react';
import {
  EVBox,
  EVButton,
  EVClickAwayListener,
  EVDialog,
  EVDialogActions,
  EVDialogContent,
  EVDialogContentText,
  EVNoteIcon,
  EVNotesHeader,
  EVNotesItem,
  EVNotesPanel,
  EVPaper,
  EVPopper,
  EVTagIcon,
  EVTagPanel,
  EVTagPanelContent,
  EVTagPanelInput,
  EVTypography,
  EVTabs,
  EVTab,
} from '@eagleview/ev-comp-library';
import flatten from 'lodash/flatten';
import get from 'lodash/get';
import uniq from 'lodash/uniq';
import isEmpty from 'lodash/isEmpty';
import partition from 'lodash/partition';
import { useTranslation } from 'react-i18next';
import toLower from 'lodash/toLower';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import EntitledComponent from 'components/EntitledComponent';
import { FEATURE_ENTITLEMENTS, QC_ENHANCEMENT } from 'layout/entitleUser/EntitleUser.constants';
import { FEATURE_PREFERENCES } from 'layout/adjuster/Adjuster.constants';
import { isEntitled } from 'utils/auth.utils';
import { errorHandlerService } from 'utils/utils';
import ConfidenceFactorSelector from './ConfidenceFactor';
import * as action from '../Adjuster.actions';
import ReportSwitch from './ReportSwitch';
import useStyles from './Statusbar.styles';
import {
  newNoteTemplate, mapNotes, newQcNoteTemplate, convertTimesToISOFormat, convertTimesToClientTimeZone,
} from './Statusbar.utils';
import StatusbarButton from './StatusbarButton';
import HeatMap from './StatusbarHeatMap';
import StatusbarText from './StatusbarText';
import { DELETE_ADJUSTER_DETAILS_TAGS, TABS } from '../Adjuster.constants';

const Statusbar = () => {
  // actions
  const dispatch = useDispatch();
  const saveImageNoteReportInclusion = (payload) => dispatch(action.saveImageNoteReportInclusionAction(payload));
  const saveFacetNoteReportInclusion = (payload) => dispatch(action.saveFacetNoteReportInclusionAction(payload));
  const saveFacetTestSquareNoteReportInclusion = (payload) => dispatch(action.saveFacetTestSquareNoteReportInclusionAction(payload));
  const saveAdjusterDetailsTags = (payload) => dispatch(action.saveAdjusterDetailsTagsAction(payload));
  const deleteAdjusterDetailsTags = (payload) => dispatch(action.deleteAdjusterDetailsTagsAction(payload));
  const setAppliedFilters = (payload) => dispatch(action.setAppliedGalleryImageFilters(payload));
  const saveImageReportInclusion = (payload) => dispatch(action.saveImageReportInclusionAction(payload));
  const deleteImageReportInclusion = (payload) => dispatch(action.deleteImageReportInclusionAction(payload));
  const saveAdjusterDetailsNotes = (payload) => dispatch(action.saveAdjusterDetailsNotesAction(payload));
  const updateAdjusterDetailsNotes = (payload) => dispatch(action.updateAdjusterDetailsNotesAction(payload));
  const deleteAdjusterDetailsNotes = (payload) => dispatch(action.deleteAdjusterDetailsNotesAction(payload));
  const saveFacetNotes = (payload) => dispatch(action.saveFacetNotesAction(payload));
  const updateFacetNotes = (payload) => dispatch(action.updateFacetNotesAction(payload));
  const deleteFacetNotes = (payload) => dispatch(action.deleteFacetNotesAction(payload));
  const saveFacetTestSquareNotes = (payload) => dispatch(action.saveFacetTestSquareNotesAction(payload));
  const updateFacetTestSquareNotes = (payload) => dispatch(action.updateFacetTestSquareNotesAction(payload));
  const deleteFacetTestSquareNotes = (payload) => dispatch(action.deleteFacetTestSquareNotesAction(payload));
  const saveDecision = (payload) => dispatch(action.saveFacetReviewDecisionAction(payload));
  const setCurrentNotesTabAction = (payload) => dispatch(action.setCurrentNotesTabAction(payload));
  const saveConfidenceFactorAction = (payload) => dispatch(action.saveConfidenceFactorAction(payload));
  const setFacetNoteReportInclusion = (payload) => dispatch(action.setFacetNoteReportInclusionAction(payload));
  const setGalleryImageNoteReportInclusion = (payload) => dispatch(action.setGalleryImageNoteReportInclusionAction(payload));
  const setTestSquareNoteReportInclusion = (payload) => dispatch(action.setTestSquareNoteReportInclusionAction(payload));
  const saveQcNote = (payload) => dispatch(action.saveQcNoteAction(payload));
  const showCustomErrorToast = (payload) => dispatch(action.showErrorToastAction(payload));
  // selectors
  const sidePanelWidth = useSelector((state) => state.adjusterReducer.sidePanelWidth);
  const selectedImage = useSelector((state) => state.adjusterReducer.selectedImage);
  const currentTab = useSelector((state) => state.adjusterReducer.currentTab);
  const currentNotesTab = useSelector((state) => state.adjusterReducer.currentNotesTab);
  const selectedFacet = useSelector((state) => state.adjusterReducer.selectedFacet);
  const user = useSelector((state) => state.adjusterReducer.user);
  const tags = useSelector((state) => state.adjusterReducer.tags);
  const notes = useSelector((state) => state.adjusterReducer.notes);
  const facetNotes = useSelector((state) => state.adjusterReducer.facetNotes);
  const facetTestSquareNotes = useSelector((state) => state.adjusterReducer.facetTestSquareNotes);
  const roofId = useSelector((state) => state.adjusterReducer.roofId);
  const galleryImages = useSelector((state) => state.adjusterReducer.galleryImages);
  const reportIncludedImages = useSelector((state) => state.adjusterReducer.reportIncludedImages);
  const anomalies = useSelector((state) => state.adjusterReducer.anomalies);
  const facets = useSelector((state) => state.adjusterReducer.facets);
  const falconTags = useSelector((state) => state.adjusterReducer.falconTags);
  const loading = useSelector((state) => state.adjusterReducer.loading);
  const entitlements = useSelector((state) => state.entitleUserReducer.entitlements);
  const featurePreferences = useSelector((state) => state.adjusterReducer.featurePreferences);
  const selectedConfidenceFactor = useSelector((state) => state.adjusterReducer.selectedConfidenceFactor);
  const orderCompleted = useSelector((state) => state.adjusterReducer.orderCompleted);
  const galleryAnnotations = useSelector((state) => state.adjusterReducer.gallaryAnnotations);
  const qcNotesList = useSelector((state) => state.adjusterReducer.QcNotesList);
  const claimOrderId = useSelector((state) => state.adjusterReducer.orderId);
  const isAssessLite = useSelector((state) => state.adjusterReducer.isAssessLite);
  const enableQcEnhancement = useSelector((state) => get(state.entitleUserReducer.featureFlags, QC_ENHANCEMENT, false));
  const customTagsWithoutImage = useSelector((state) => uniq(flatten(get(state, 'adjusterReducer.customTags', []).map((image) => image.tags))));
  const appliedFilters = useSelector((state) => state.adjusterReducer.appliedGalleryImageFilters);

  // derived values
  const selectedImageNotes = notes.filter((item) => item.imageID === selectedImage);
  const filteredSelectedImageNotes = get(selectedImageNotes, '[0].notes', []);
  const parsedNotesForImage = filteredSelectedImageNotes.map(mapNotes);
  const parsedFilters = !isEmpty(customTagsWithoutImage) ? Object.assign(...customTagsWithoutImage.map((tag) => ({ [tag]: appliedFilters[tag] }))) : {};

  const selectedFacetNotes = facetNotes.filter((item) => item.facetID === selectedFacet);
  const filteredSelectedFacetNotes = get(selectedFacetNotes, '[0].notes', []);
  const parsedNotesForFacet = filteredSelectedFacetNotes.map(mapNotes);
  const selectedImageTags = tags.filter((item) => item.imageID === selectedImage);
  const selectedTags = get(selectedImageTags, '[0].tags', []);
  const allTags = uniq(flatten(tags.map((image) => image.tags)));
  const parsedTagsForImage = allTags.map((tag) => ({
    tag,
    associationCount: selectedTags.includes(tag) ? 1 : 0,
    disassociationDisabled: (isEntitled(entitlements, FEATURE_ENTITLEMENTS.MANAGE_TAGS) && !orderCompleted)
      ? (loading[DELETE_ADJUSTER_DETAILS_TAGS] || falconTags.includes(tag)) : true,
  }));
  const orderedParsedTagsForImage = flatten(partition(parsedTagsForImage, 'disassociationDisabled'));
  const selectedImageObj = galleryImages.find((image) => image.urn === selectedImage);
  const selectedImageName = get(selectedImageObj, 'name', '');
  const selectedFacetObj = facets.find((facet) => facet.facetId === selectedFacet) || false;
  const isImageIncluded = reportIncludedImages.some((x) => x === selectedImage);
  const totalAnomalies = anomalies.filter((anomaly) => !anomaly.data[0].properties.isUserAdded)
    .length;
  const filteredTestSquareNotes = get(facetTestSquareNotes, '[0].notes', []);
  const parsedNotesForFacetTestSquare = filteredTestSquareNotes.map(mapNotes);
  const isGalleryNotesTab = currentTab === TABS.GALLERY || isAssessLite;
  const isNotesTab = currentTab !== TABS.GALLERY && currentNotesTab === 0 && !isAssessLite;
  const isTestSquareNotesTab = currentTab !== TABS.GALLERY && currentNotesTab === 1 && !isAssessLite;
  const testSquareId = get(selectedFacetObj, 'testsquare.testSquareId', '');
  const selectedFacetTestSquareNotes = facetTestSquareNotes && facetTestSquareNotes.filter((item) => item.testSquareID === testSquareId);
  const parsedTestSquareNotesForFacet = get(selectedFacetTestSquareNotes, '[0].notes', []);
  const isTagEntitled = isEntitled(entitlements, FEATURE_ENTITLEMENTS.VIEW_ASSESS_SELF_ORGANIZATION_ORDERS)
    || isEntitled(entitlements, FEATURE_ENTITLEMENTS.VIEW_ASSESS_ALL_ORGANIZATION_ORDERS);
  const hasGalleryAnnotations = !isEmpty(galleryAnnotations);

  // utility hooks
  const { t } = useTranslation();
  const { id: orderId } = useParams();
  const styles = useStyles({ sidePanelWidth });

  // local state
  const [tagsOpen, toggleTagsOpen] = useState(false);
  const [refTags, setRefTags] = useState(null);
  const [notesOpen, toggleNotesOpen] = useState(false);
  const [qcNotesOpen, toggleQcNotesOpen] = useState(false);
  const [refNotes, setRefNotes] = useState(null);
  const [qcNotesRef, setQcNotesRef] = useState(null);
  const [editing, setEditing] = useState();
  const [newNote, setNewNote] = useState();
  const [newQcNote, setNewQcNote] = useState();
  const [deleteCandidate, setDeleteCandidate] = useState();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [reset, setReset] = useState(0);
  const [noteCurrentInclusion, setNoteCurrentInclusion] = useState(null);
  const handleClickAwayTags = () => {
    setRefTags(null);
    toggleTagsOpen(false);
  };

  const onTagAssociated = (tag) => {
    if (isEntitled(entitlements, FEATURE_ENTITLEMENTS.MANAGE_TAGS) && !orderCompleted) {
      saveAdjusterDetailsTags({
        data: { tags: [tag] },
        image: selectedImage,
        orderId,
      });
    }
  };

  const onTagAdded = (tag) => {
    if (tag.trim() === '') return;
    const tagExist = selectedTags.some((currentTag) => currentTag === tag);
    if (isEntitled(entitlements, FEATURE_ENTITLEMENTS.MANAGE_TAGS) && !orderCompleted && !tagExist) {
      saveAdjusterDetailsTags({
        data: { tags: [tag] },
        image: selectedImage,
        orderId,
      });
    }
  };

  const onTagDisassociated = (tag) => {
    if (isEntitled(entitlements, FEATURE_ENTITLEMENTS.MANAGE_TAGS) && !orderCompleted) {
      deleteAdjusterDetailsTags({
        data: { tags: [tag] },
        image: selectedImage,
        orderId,
      });
      setAppliedFilters({ ...parsedFilters, [tag]: false });
    }
  };

  const handleTagsClick = (e) => {
    setRefTags(refTags ? null : e.currentTarget);
    toggleTagsOpen(!tagsOpen);
  };

  const handleNotesClick = (e) => {
    setRefNotes(refNotes ? null : e.currentTarget);
    setCurrentNotesTabAction(0);
    toggleNotesOpen(!notesOpen);
  };

  const handleClickAwayNotes = () => {
    setRefNotes(null);
    setCurrentNotesTabAction(0);
    toggleNotesOpen(false);
  };

  const handleEditing = (data) => {
    setEditing(data);
    setNoteCurrentInclusion(data.selected);
  };

  const handleSaveEdit = () => {
    try {
      if (isGalleryNotesTab) {
        updateAdjusterDetailsNotes({
          data: {
            note: editing.note,
            includeInReport: editing.selected,
          },
          orderId,
          image: selectedImage,
          noteId: editing.noteId,
          noteInclusionBeforeUpdate: noteCurrentInclusion,
        });
      }
      if (isNotesTab) {
        updateFacetNotes({
          data: {
            note: editing.note,
            includeInReport: editing.selected,
          },
          orderId,
          facet: selectedFacet,
          noteId: editing.noteId,
          noteInclusionBeforeUpdate: noteCurrentInclusion,
        });
      }
      if (isTestSquareNotesTab) {
        updateFacetTestSquareNotes({
          data: {
            note: editing.note,
            includeInReport: editing.selected,
          },
          orderId,
          testSquareId,
          noteId: editing.noteId,
          noteInclusionBeforeUpdate: noteCurrentInclusion,
        });
      }
      setEditing(null);
      setNoteCurrentInclusion(null);
    } catch (err) {
      setTimeout(() => {
        errorHandlerService(err, err.message || '', 'statusbar');
        showCustomErrorToast({ message: 'statusbar.somethingWentWrong' });
      }, 0);
    }
  };

  const handleCancelEdit = () => {
    if (isGalleryNotesTab) {
      setGalleryImageNoteReportInclusion({ imageUrn: selectedImage, noteId: editing.noteId, includeInReport: noteCurrentInclusion });
    }
    if (isNotesTab) {
      setFacetNoteReportInclusion({ facetId: selectedFacet, noteId: editing.noteId, includeInReport: noteCurrentInclusion });
    }
    if (isTestSquareNotesTab) {
      setTestSquareNoteReportInclusion({ testSquareId, noteId: editing.noteId, includeInReport: noteCurrentInclusion });
    }
    setEditing(null);
    setNoteCurrentInclusion(null);
  };
  const handleOnChangeNew = (e) => {
    const note = get(e, 'target.value');
    if (note.length > 500) return;
    setNewNote({ ...newNote, note });
  };
  const handleOnChangeNewQcnote = (e) => {
    const note = get(e, 'target.value');
    if (note.length > 500) return;
    setNewQcNote({ ...newQcNote, note });
  };
  const handleAddNew = () => setNewNote(newNoteTemplate(isEntitled(entitlements, FEATURE_ENTITLEMENTS.MANAGE_REPORTS)));
  const handleAddNewQcnote = () => setNewQcNote(newQcNoteTemplate());
  const handleSaveNew = () => {
    try {
      if (isGalleryNotesTab) {
        saveAdjusterDetailsNotes({
          data: {
            note: newNote.note,
            includeInReport: newNote.selected,
          },
          image: selectedImage,
          orderId,
        });
      }
      if (isNotesTab) {
        saveFacetNotes({
          data: {
            note: newNote.note,
            includeInReport: newNote.selected,
          },
          facet: selectedFacet,
          orderId,
        });
      }
      if (isTestSquareNotesTab) {
        saveFacetTestSquareNotes({
          data: {
            note: newNote.note,
            includeInReport: newNote.selected,
          },
          testSquareId,
          orderId,
        });
      }
      setNewNote(null);
    } catch (err) {
      setTimeout(() => {
        errorHandlerService(err, err.message || '', 'statusbar');
        showCustomErrorToast({ message: 'statusbar.somethingWentWrong' });
      }, 0);
    }
  };
  const handleSaveNewQcnote = () => {
    try {
      saveQcNote({
        data: {
          note: newQcNote.note,
          created_at: convertTimesToISOFormat(Date.parse(newQcNote.dateUpdated)),
          created_by: newQcNote.author.userName,
        },
        orderId,
      });
      setNewQcNote(null);
    } catch (err) {
      setTimeout(() => {
        errorHandlerService(err, err.message || '', 'statusbar');
        showCustomErrorToast({ message: 'statusbar.somethingWentWrong' });
      }, 0);
    }
    setNewNote(null);
  };

  const handleCancelNew = () => setNewNote(null);
  const handleCancelNewQcnote = () => setNewQcNote(null);

  const handleToggleSelectedNew = () => setNewNote({ ...newNote, selected: !newNote.selected });

  const handleToggleSelected = (noteId) => {
    if (isGalleryNotesTab) {
      const noteToEdit = parsedNotesForImage.find((item) => item.noteId === noteId);
      if (editing && editing.noteId === noteId) {
        setEditing({ ...editing, selected: !noteToEdit.selected });
        setGalleryImageNoteReportInclusion({ imageUrn: selectedImage, noteId, includeInReport: !noteToEdit.selected });
      } else {
        saveImageNoteReportInclusion({
          data: {
            includeInReport: !noteToEdit.selected,
          },
          orderId,
          imageUrn: selectedImage,
          noteId,
        });
      }
    }
    if (isNotesTab) {
      const noteToEdit = parsedNotesForFacet.find((item) => item.noteId === noteId);
      if (editing && editing.noteId === noteId) {
        setEditing({ ...editing, selected: !noteToEdit.selected });
        setFacetNoteReportInclusion({ facetId: selectedFacet, noteId, includeInReport: !noteToEdit.selected });
      } else {
        saveFacetNoteReportInclusion({
          data: {
            includeInReport: !noteToEdit.selected,
          },
          orderId,
          facetId: selectedFacet,
          noteId,
        });
      }
    }
    if (isTestSquareNotesTab) {
      const noteToEdit = parsedNotesForFacetTestSquare.find((item) => item.noteId === noteId);
      if (editing && editing.noteId === noteId) {
        setEditing({ ...editing, selected: !noteToEdit.selected });
        setTestSquareNoteReportInclusion({ testSquareId, noteId, includeInReport: !noteToEdit.selected });
      } else {
        saveFacetTestSquareNoteReportInclusion({
          data: {
            includeInReport: !noteToEdit.selected,
          },
          orderId,
          testSquareId,
          noteId,
        });
      }
    }
  };

  const handleDeleteNote = (id) => {
    setDeleteCandidate(id);
    setDeleteDialogOpen(true);
  };

  const handleEditOnChange = (e) => {
    const note = get(e, 'target.value');
    if (note.length > 500) return;
    setEditing({ ...editing, note });
  };

  const handleDeleteNoteCancel = () => setDeleteDialogOpen(false);

  const handleDeleteNoteConfirm = () => {
    setDeleteDialogOpen(false);
    if (isGalleryNotesTab) {
      deleteAdjusterDetailsNotes({
        orderId,
        image: selectedImage,
        noteId: deleteCandidate,
      });
    }
    if (isNotesTab) {
      deleteFacetNotes({
        orderId,
        facet: selectedFacet,
        noteId: deleteCandidate,
      });
    }
    if (isTestSquareNotesTab) {
      deleteFacetTestSquareNotes({
        orderId,
        testSquareId,
        noteId: deleteCandidate,
      });
    }
    setDeleteCandidate(null);
  };

  const handleImageReportInclusion = (includeInReport) => {
    if (isEntitled(entitlements, FEATURE_ENTITLEMENTS.MANAGE_REPORTS) && !orderCompleted) {
      if (includeInReport) {
        saveImageReportInclusion({ orderId, data: { referenceIds: [selectedImage] } });
      } else {
        deleteImageReportInclusion({ orderId, data: { referenceIds: [selectedImage] } });
      }
    }
  };

  const saveFacetReportInclusion = (includeInReport) => (isEntitled(entitlements, FEATURE_ENTITLEMENTS.MANAGE_REPORTS) && !orderCompleted)
    && saveDecision({
      orderId,
      facetId: selectedFacetObj.facetId,
      data: {
        decision: selectedFacetObj.decision,
        facetAliasName: selectedFacetObj.facetAliasName,
        roofId,
        includeInReport,
      },
    });

  const setCurrentNotesTab = (e, id) => {
    setCurrentNotesTabAction(id);
  };

  const handleConfidenceSelector = (confidenceFactor) => {
    const payload = {
      orderId,
      confidenceFactor,
    };
    saveConfidenceFactorAction(payload);
    setReset(reset ? 0 : 1);
  };

  const handleNotesClickForQc = (e) => {
    setQcNotesRef(qcNotesRef ? null : e.currentTarget);
    toggleQcNotesOpen(!qcNotesOpen);
  };

  const handleClickAwayQcNotes = () => {
    setQcNotesRef(null);
    toggleQcNotesOpen(false);
  };

  return (
    <>
      <EVBox className={styles.statusBar}>
        {
          qcNotesList.get(claimOrderId) && qcNotesList.get(claimOrderId).length > 0 && !enableQcEnhancement && (
            <div className={styles.qcNotesStatusBarButton}>
              <StatusbarButton
                id="statusBarButtonForQc"
                active={qcNotesOpen}
                text={
                  qcNotesList.get(claimOrderId) && qcNotesList.get(claimOrderId).length > 0
                    ? `${qcNotesList.get(claimOrderId).length} ${t('statusbar.notes')}`
                    : ''
                }
                onClick={handleNotesClickForQc}
                icon={(
                  <EVNoteIcon />
                )}
              />
            </div>
          )
        }
        {isGalleryNotesTab && selectedImage ? (
          <>
            <EVTypography className={styles.imageNameStyle} component="span">
              {selectedImageName}
            </EVTypography>
            <EVBox className={styles.statusBarRight}>
              {isTagEntitled && (
                <StatusbarButton
                  active={tagsOpen}
                  text={
                    !isEmpty(selectedTags)
                      ? `${selectedTags.length} ${t('statusbar.tags')}`
                      : t('statusbar.noTags')
                  }
                  onClick={handleTagsClick}
                  icon={(
                    <EVTagIcon
                      className={!isEmpty(selectedTags) ? styles.activeIcon : styles.inactiveIcon}
                    />
                  )}
                />
              )}
              {(isEntitled(entitlements, FEATURE_ENTITLEMENTS.VIEW_NOTES)
                || isEntitled(entitlements, FEATURE_ENTITLEMENTS.VIEW_ASSESS_ALL_ORGANIZATION_ORDERS))
                && (
                  <StatusbarButton
                    id="StatusbarButton"
                    active={notesOpen}
                    text={
                      !isEmpty(parsedNotesForImage)
                        ? `${parsedNotesForImage.length} ${t('statusbar.notes')}`
                        : t('statusbar.noNotes')
                    }
                    onClick={handleNotesClick}
                    icon={(
                      <EVNoteIcon
                        className={
                          !isEmpty(parsedNotesForImage) ? styles.activeIcon : styles.inactiveIcon
                        }
                      />
                    )}
                  />
                )}
              <EntitledComponent entitlement={FEATURE_ENTITLEMENTS.VIEW_REPORTS}>
                <ReportSwitch
                  included={isImageIncluded}
                  setIncluded={handleImageReportInclusion}
                  disabled={!isEntitled(entitlements, FEATURE_ENTITLEMENTS.MANAGE_REPORTS) || orderCompleted || hasGalleryAnnotations}
                />
              </EntitledComponent>
            </EVBox>
          </>
        ) : (
          <EVBox
            p={2}
            width="100%"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <EVBox display="flex" justifyContent="center" alignItems="center">
              {isEmpty(selectedFacet) && totalAnomalies !== 0 && (
                <>
                  <StatusbarText text="statusbar.totalAnomalies" />
                  <StatusbarText text={totalAnomalies} variant="black" />
                </>
              )}
              {!isEmpty(selectedFacet) && (
                <StatusbarText text={selectedFacetObj.facetAliasName} variant="black" />
              )}
            </EVBox>
            <EVBox display="flex" justifyContent="center" alignItems="center">
              {!isAssessLite && !selectedFacet ? (
                <>
                  <StatusbarText text="statusbar.heatMap" />
                  <StatusbarText text="statusbar.less" variant="black" />
                  <HeatMap values={[20, 35, 45, 60, 80]} />
                  <StatusbarText text="statusbar.more" variant="black" />
                </>
              ) : (
                <>
                  {!isAssessLite && !isEntitled(featurePreferences, FEATURE_PREFERENCES.CONTROL_NUMBER_OF_ANOMALIES_SHOWN)
                    && (
                      <EVBox className={styles.statusBarConfidenceFactor} display="flex" justifyContent="center" alignItems="center">
                        <ConfidenceFactorSelector
                          updateStatus={reset}
                          selectedConfidenceFactor={selectedConfidenceFactor}
                          handleConfidenceSelector={handleConfidenceSelector}
                        />
                      </EVBox>
                    )}
                  {(isEntitled(entitlements, FEATURE_ENTITLEMENTS.VIEW_NOTES)
                    || isEntitled(entitlements, FEATURE_ENTITLEMENTS.VIEW_ASSESS_ALL_ORGANIZATION_ORDERS))
                    && (
                      <StatusbarButton
                        active={notesOpen}
                        text={
                          isEmpty(parsedNotesForFacet) && isEmpty(parsedTestSquareNotesForFacet)
                            ? t('statusbar.noNotes')
                            : `${parsedNotesForFacet.length + parsedTestSquareNotesForFacet.length} ${t('statusbar.notes')}`
                        }
                        onClick={handleNotesClick}
                        icon={(
                          <EVNoteIcon
                            className={
                              !isEmpty(parsedNotesForFacet) ? styles.activeIcon : styles.inactiveIcon
                            }
                          />
                        )}
                      />
                    )}
                  <EntitledComponent entitlement={FEATURE_ENTITLEMENTS.VIEW_REPORTS}>
                    <ReportSwitch
                      included={selectedFacetObj && selectedFacetObj.includeInReport}
                      setIncluded={saveFacetReportInclusion}
                      disabled={!isEntitled(entitlements, FEATURE_ENTITLEMENTS.MANAGE_REPORTS) || orderCompleted}
                    />
                  </EntitledComponent>
                </>
              )}
            </EVBox>
          </EVBox>
        )}
        {enableQcEnhancement && (isEntitled(entitlements, FEATURE_ENTITLEMENTS.MANAGE_QC_DECISIONS)
          || isEntitled(entitlements, FEATURE_ENTITLEMENTS.MANAGE_PENDING_QC_VIA_OPS_PRIME)) && (
            <div className={styles.qcNotesStatusBarButton}>
              <StatusbarButton
                id="statusBarButtonForQc"
                active={qcNotesOpen}
                text={
                  !isEmpty(qcNotesList.get(claimOrderId))
                    ? `${qcNotesList.get(claimOrderId).length} ${t('statusbar.notes')}`
                    : t('statusbar.noNotes')
                }
                onClick={handleNotesClickForQc}
                icon={(
                  <EVNoteIcon />
                )}
              />
            </div>
        )}
      </EVBox>
      {
        !enableQcEnhancement ? (
          <EVPopper
            open={qcNotesOpen}
            disablePortal
            placement="top-start"
            style={{ zIndex: 5 }}
            anchorEl={qcNotesRef}
          >
            <EVClickAwayListener mouseEvent="onClick" onClickAway={handleClickAwayQcNotes}>
              <EVPaper elevation={3} className={styles.qcNotesContainer}>
                {
                  qcNotesList.get(claimOrderId) && qcNotesList.get(claimOrderId).map((item) => (
                    <div className={styles.qcNotesItemContainer}>
                      <div className={styles.qcNotesUserAndDate}>
                        <EVTypography variant="h3">
                          {item.created_by.split('@')[0].replace(/\./g, ' ')}
                        </EVTypography>
                        <EVTypography component="span" className={styles.userAndDateSeparator}>
                          {'   -   '}
                        </EVTypography>
                        <EVTypography variant="h4" className={styles.qcNoteCreationDate}>
                          {convertTimesToClientTimeZone(item.created_at)}
                        </EVTypography>
                      </div>
                      <EVTypography variant="h4" className={styles.qcNoteText}>
                        {item.note}
                      </EVTypography>

                    </div>
                  ))
                }
              </EVPaper>
            </EVClickAwayListener>
          </EVPopper>
        ) : (
          <EVPopper
            disablePortal
            placement="top-end"
            style={{ zIndex: 5 }}
            open={qcNotesOpen}
            anchorEl={qcNotesRef}
          >
            <EVClickAwayListener mouseEvent="onClick" onClickAway={handleClickAwayQcNotes}>
              <EVPaper elevation={3}>
                <EVNotesHeader>
                  <EVTypography variant="h6" className={styles.qcNotesHeading}>Property Log</EVTypography>
                </EVNotesHeader>
                <EVNotesPanel
                  elevation={0}
                  width="400px"
                  height="500px"
                  className={styles.renderNewLines}
                  handleOnChangeNew={handleOnChangeNewQcnote}
                  handleAddNew={handleAddNewQcnote}
                  handleSaveNew={handleSaveNewQcnote}
                  handleCancelNew={handleCancelNewQcnote}
                  newNote={newQcNote}
                  hideActionBar={orderCompleted}
                >
                  {
                    qcNotesList.get(claimOrderId) && qcNotesList.get(claimOrderId).map((qcnote) => (
                      <div className={styles.qcNotesContainer}>
                        {qcnote.status && (
                          <EVTypography className={styles.qcNoteStatus}>{qcnote.status}</EVTypography>
                        )}
                        {qcnote.reason && (
                          <div>
                            <EVTypography className={styles.qcNoteReason}>Reasons</EVTypography>
                            {qcnote.reason.split(',').map((qcreason) => (
                              <li className={styles.qcNoteReasonList}>{qcreason}</li>
                            ))}
                          </div>
                        )}
                        {qcnote.note && (
                          <EVTypography className={styles.qcNoteText}>{qcnote.note}</EVTypography>
                        )}
                        <div className={styles.qcNotesUserAndDate}>
                          <EVTypography>
                            {qcnote.created_by.split('@')[0].replace(/\./g, ' ')}
                          </EVTypography>
                          <EVTypography component="span">
                            {'   -   '}
                          </EVTypography>
                          <EVTypography>
                            {convertTimesToClientTimeZone(qcnote.created_at)}
                          </EVTypography>
                        </div>
                      </div>
                    ))
                  }
                </EVNotesPanel>
              </EVPaper>
            </EVClickAwayListener>
          </EVPopper>
        )
      }
      <EVPopper
        disablePortal
        placement="top-end"
        style={{ zIndex: 5 }}
        open={notesOpen}
        anchorEl={refNotes}
      >
        <EVClickAwayListener mouseEvent={deleteDialogOpen ? false : 'onClick'} onClickAway={handleClickAwayNotes}>
          <EVPaper elevation={3}>
            <EVNotesHeader>
              <EVTypography variant="h6">{t('statusbar.notes')}</EVTypography>
            </EVNotesHeader>
            {(currentTab !== TABS.GALLERY && !isAssessLite) && (
              <EVTabs
                value={currentNotesTab}
                onChange={setCurrentNotesTab}
                textColor="primary"
                indicatorColor="primary"
                className={styles.tabs}
              >
                <EVTab className={styles.tabBar} label={`Facet ${selectedFacetObj.facetAliasName}`} />
                <EVTab
                  className={styles.tabBar}
                  label={t('statusbar.notesTestSquare')}
                  disabled={!testSquareId || selectedFacetObj.isTestsquareDisabled}
                />
              </EVTabs>
            )}
            <EVNotesPanel
              elevation={0}
              width="400px"
              height="500px"
              className={styles.renderNewLines}
              editing={editing}
              handleSaveEdit={handleSaveEdit}
              handleCancelEdit={handleCancelEdit}
              handleOnChangeNew={handleOnChangeNew}
              handleAddNew={handleAddNew}
              handleSaveNew={handleSaveNew}
              handleCancelNew={handleCancelNew}
              handleToggleSelectedNew={handleToggleSelectedNew}
              newNoteSelectable={isEntitled(entitlements, FEATURE_ENTITLEMENTS.VIEW_REPORTS)}
              newNoteSelectableText="Include note in report"
              newNoteDisableSelectableCheckbox={!isEntitled(entitlements, FEATURE_ENTITLEMENTS.MANAGE_REPORTS) || orderCompleted}
              newNote={newNote}
              hideActionBar={!isEntitled(entitlements, FEATURE_ENTITLEMENTS.MANAGE_NOTES) || orderCompleted}
            >
              {
                isGalleryNotesTab && parsedNotesForImage.map((note) => (
                  <EVNotesItem
                    selectable={isEntitled(entitlements, FEATURE_ENTITLEMENTS.VIEW_REPORTS)}
                    selected
                    selectableText="Include note in report"
                    handleToggleSelected={handleToggleSelected}
                    handleDeleteNote={handleDeleteNote}
                    setEditing={handleEditing}
                    handleEditOnChange={handleEditOnChange}
                    key={note.noteId}
                    note={note}
                    editing={newNote || (note.author && toLower(note.author.userName) !== user.userName) ? true : editing}
                    hideMoreOptionsIcon={!isEntitled(entitlements, FEATURE_ENTITLEMENTS.MANAGE_NOTES) || orderCompleted}
                    disableSelectableCheckbox={!isEntitled(entitlements, FEATURE_ENTITLEMENTS.MANAGE_REPORTS) || orderCompleted
                      || (editing && editing.noteId !== note.noteId)}
                  />
                ))
              }
              {
                isNotesTab && (!isEmpty(parsedNotesForFacet)) && parsedNotesForFacet.map((note) => (
                  <EVNotesItem
                    selectable={isEntitled(entitlements, FEATURE_ENTITLEMENTS.VIEW_REPORTS)}
                    selected
                    selectableText="Include note in report"
                    handleToggleSelected={handleToggleSelected}
                    handleDeleteNote={handleDeleteNote}
                    setEditing={handleEditing}
                    handleEditOnChange={handleEditOnChange}
                    key={note.noteId}
                    note={note}
                    editing={newNote || (note.author && toLower(note.author.userName) !== user.userName) ? true : editing}
                    hideMoreOptionsIcon={!isEntitled(entitlements, FEATURE_ENTITLEMENTS.MANAGE_NOTES) || orderCompleted}
                    disableSelectableCheckbox={!isEntitled(entitlements, FEATURE_ENTITLEMENTS.MANAGE_REPORTS) || orderCompleted
                      || (editing && editing.noteId !== note.noteId)}
                  />
                ))
              }
              {
                isTestSquareNotesTab && (!isEmpty(parsedNotesForFacetTestSquare))
                && parsedNotesForFacetTestSquare.map((note) => (
                  <EVNotesItem
                    selectable={isEntitled(entitlements, FEATURE_ENTITLEMENTS.VIEW_REPORTS)}
                    selected
                    selectableText="Include note in report"
                    handleToggleSelected={handleToggleSelected}
                    handleDeleteNote={handleDeleteNote}
                    setEditing={handleEditing}
                    handleEditOnChange={handleEditOnChange}
                    key={note.noteId}
                    note={note}
                    editing={newNote || (note.author && toLower(note.author.userName) !== user.userName) ? true : editing}
                    hideMoreOptionsIcon={!isEntitled(entitlements, FEATURE_ENTITLEMENTS.MANAGE_NOTES) || orderCompleted}
                    disableSelectableCheckbox={!isEntitled(entitlements, FEATURE_ENTITLEMENTS.MANAGE_REPORTS) || orderCompleted
                      || (editing && editing.noteId !== note.noteId)}
                  />
                ))
              }
            </EVNotesPanel>
          </EVPaper>
        </EVClickAwayListener>
      </EVPopper>
      <EVPopper
        disablePortal
        placement="top-end"
        style={{ zIndex: 5 }}
        open={tagsOpen}
        anchorEl={refTags}
      >
        <EVClickAwayListener mouseEvent="onClick" onClickAway={handleClickAwayTags}>
          <EVPaper className={styles.tagsContainer} elevation={3}>
            <EVTypography variant="h6" className={styles.tagsTitle}>
              {t('statusbar.tags')}
            </EVTypography>
            <EVTagPanel>
              <EntitledComponent entitlement={FEATURE_ENTITLEMENTS.MANAGE_TAGS}>
                {!orderCompleted && <EVTagPanelInput className={styles.textFieldStyle} onTagAdded={onTagAdded} maxCharacterLimit={20} />}
              </EntitledComponent>
              <EVTagPanelContent
                className={styles.scrollable}
                associatedTags={orderedParsedTagsForImage}
                onTagAssociated={onTagAssociated}
                onTagDisassociated={onTagDisassociated}
                fullSelectionCount={1}
              />
            </EVTagPanel>
          </EVPaper>
        </EVClickAwayListener>
      </EVPopper>
      <EVDialog open={deleteDialogOpen} onClose={handleDeleteNoteCancel}>
        <EVDialogContent>
          <EVDialogContentText>{t('statusbar.notes.dialog')}</EVDialogContentText>
        </EVDialogContent>
        <EVDialogActions>
          <EVButton onClick={handleDeleteNoteCancel}>{t('cancel')}</EVButton>
          <EVButton onClick={handleDeleteNoteConfirm} color="primary" autoFocus>
            {t('delete')}
          </EVButton>
        </EVDialogActions>
      </EVDialog>
    </>
  );
};

export default Statusbar;
