import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { EVImagePreviewer, EVBox } from '@eagleview/ev-comp-library';
import { isEmpty } from 'lodash';

const PhotosImageViewer = ({
  image,
}) => {
  // local state
  const [imageUrl, setImageUrl] = useState('');

  useEffect(() => {
    if (!isEmpty(image)) {
      const url = window.URL.createObjectURL(new Blob([image.data]));
      setImageUrl(url);
    }
  }, [image]);

  return (
    <EVBox style={{ width: '100%', height: '100%' }}>
      {!isEmpty(imageUrl) && (
      <EVImagePreviewer
        disableFitToWindow
        disableFullSize
        disableRotate
        url={imageUrl}
        background="#000"
      />
      )}
    </EVBox>

  );
};

PhotosImageViewer.propTypes = {
  image: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default PhotosImageViewer;
