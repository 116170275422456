import React from 'react';
import {
  EVNoteIcon, EVLink,
} from '@eagleview/ev-comp-library';
import { func, string } from 'prop-types';
import useStyles from '../ReportGenerator.styles';

/**
 * Report link component with note icon and a link for the report
 */
const ReportLink = ({ reportCreationDate, handleClick }) => {
  const styles = useStyles();
  return (
    <>
      <EVNoteIcon color="primary" />
      <EVLink className={styles.reportLink} onClick={handleClick}>
        {reportCreationDate}
      </EVLink>
    </>
  );
};

ReportLink.propTypes = {
  reportCreationDate: string.isRequired,
  handleClick: func.isRequired,
};

export default ReportLink;
