import React from 'react';
import {
  EVBox,
  EVTypography,
  EVButton,
  EVLink,
} from '@eagleview/ev-comp-library';
import { useSelector, useDispatch } from 'react-redux';
import { func, string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash/isEmpty';
import * as action from '../Adjuster.actions';
import useStyles from './PurchaseReport.styles';
import {
  FETCH_PRODUCT_COST,
  GENERATE_ROOF_REPORT,
  REPORT_STATUS_PAYMENT_FAILED,
  REPORT_STATUS_IN_PROCESS,
  REPORT_STATUS_ERROR,
} from '../Adjuster.constants';
import { SUPPORT_LINK } from '../../../constants';

const PurchaseReportDialog = ({ closePanel, roofReportStatus }) => {
  const { t } = useTranslation();
  // selectors
  const orderId = useSelector((state) => state.adjusterReducer.orderId);
  const flightType = useSelector((state) => state.adjusterReducer.flightType);
  const loading = useSelector((state) => state.adjusterReducer.loading);
  const roofRopertCost = useSelector((state) => state.adjusterReducer.roofRopertCost);

  // actions
  const dispatch = useDispatch();
  const generateRoofReport = (payload) => dispatch(action.generateRoofReportAction(payload));
  const clearErrorFromState = () => dispatch(action.clearErrorsAction());
  const clearRoofMeasurementReportStatus = () => dispatch(action.clearRoofMeasurementReportStatus());

  // local state
  const styles = useStyles();

  let submitBtnLabel; let deliveryTime; let dialogTitle;
  if (flightType === 'fullScan') {
    submitBtnLabel = t('purchaseReportBtn.order');
    deliveryTime = t('purchaseReport.order.delivryTime');
    dialogTitle = t('adjuster.tab.orderRoofReport');
  } else {
    submitBtnLabel = t('purchaseReportBtn.generate');
    deliveryTime = t('purchaseReport.generate.delivryTime');
    dialogTitle = t('adjuster.tab.generateRoofReport');
  }

  // submit method
  const submit = () => {
    generateRoofReport({ orderId });
  };

  // close
  const close = () => {
    closePanel();
    clearErrorFromState();
    if (roofReportStatus === REPORT_STATUS_PAYMENT_FAILED) {
      clearRoofMeasurementReportStatus();
    }
  };

  const statusTitleTextMap = {
    [REPORT_STATUS_PAYMENT_FAILED]: 'adjuster.purchaseReport.paymentFailed',
    [REPORT_STATUS_IN_PROCESS]: 'adjuster.purchaseReport.orderSubmitted',
    [REPORT_STATUS_ERROR]: 'adjuster.purchaseReport.reportUnavailable',
  };

  const statusMsgTextMap = {
    [REPORT_STATUS_PAYMENT_FAILED]: 'adjuster.purchaseReport.paymentFailedMsg',
    [REPORT_STATUS_IN_PROCESS]: 'adjuster.purchaseReport.reportInProcess',
    [REPORT_STATUS_ERROR]: 'adjuster.purchaseReport.unableToProcessText',
  };

  return (
    <EVBox pt={4} className={styles.bodyContainer}>
      {
          isEmpty(roofReportStatus) ? (
            <>
              <EVTypography className={styles.titleContainer} variant="h3">{dialogTitle}</EVTypography>
              <EVBox pt={4} pb={1}>
                <EVTypography className={styles.purchaseMsg}>{t('adjuster.purchaseReport.descriptionText')}</EVTypography>
              </EVBox>
              <EVBox pt={4} pb={2}>
                <EVTypography className={styles.purchaseMsg}>
                  <strong>{t('adjuster.purchaseReport.amountLabel')}</strong>
                  {loading[FETCH_PRODUCT_COST] ? '...' : ` : ${roofRopertCost}`}
                </EVTypography>
                <EVTypography className={styles.purchaseMsg}>
                  <strong>{t('adjuster.purchaseReport.deliveryTimeLabel')}</strong>
                  {` : ${deliveryTime}`}
                </EVTypography>
              </EVBox>
              <EVBox className={styles.modalFooter}>
                <EVButton value="cancel" onClick={close}>
                  {t('Cancel')}
                </EVButton>
                <EVButton
                  id="purchaseReportOrderBtn"
                  onClick={submit}
                  className={styles.button}
                  color="primary"
                  variant="contained"
                  disabled={loading[FETCH_PRODUCT_COST] || loading[GENERATE_ROOF_REPORT]}
                >
                  {loading[FETCH_PRODUCT_COST] || loading[GENERATE_ROOF_REPORT] ? t('Submitting...') : submitBtnLabel}
                </EVButton>
              </EVBox>
            </>
          ) : (
            <div id={roofReportStatus}>
              <EVTypography className={styles.titleContainer} variant="h3">
                {t(statusTitleTextMap[roofReportStatus])}
              </EVTypography>
              <EVBox pt={4} pb={1}>
                <EVTypography className={styles.purchaseMsg}>
                  {t(statusMsgTextMap[roofReportStatus])}
                </EVTypography>
              </EVBox>
              <EVBox pt={2} pb={2}>
                <EVTypography className={styles.purchaseMsg}>
                  {t('adjuster.purchaseReport.msgContactUs')}
                  {' '}
                  <EVLink className={styles.contactUs} onClick={() => window.open(SUPPORT_LINK, '_blank')}>
                    {t('adjuster.purchaseReport.msgContactUsLinkTxt')}
                  </EVLink>
                  .
                </EVTypography>
              </EVBox>
              <EVBox className={styles.modalFooter}>
                <EVButton
                  id="purchaseReportOkBtn"
                  onClick={close}
                  className={styles.button}
                  color="primary"
                  variant="contained"
                >
                  { t('OK') }
                </EVButton>
              </EVBox>
            </div>
          )
        }
    </EVBox>
  );
};

PurchaseReportDialog.propTypes = {
  closePanel: func.isRequired,
  roofReportStatus: string.isRequired,
};

export default PurchaseReportDialog;
