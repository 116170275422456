import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  EVReactVirtualized,
  EVDialog,
  EVDialogContent,
  EVDialogContentText,
  EVDialogActions,
  EVButton,
} from '@eagleview/ev-comp-library';
import {
  QC_PENDING_HEAD_CELLS,
  QC_MANUAL_AT_TABLE_HEAD_CELLS,
  TABS,
  OPEN_ORDERS_HEAD_CELLS,
  COMPLETED_ORDERS_HEAD_CELLS,
  MIN_SEARCH_TERM_LENGTH,
  SEARCH_COLUMNS,
} from 'layout/homeLayout/qcHome/QcHome.constants';
import OrderDuplicationDialog from 'layout/homeLayout/qcHome/order-duplication-dialog/OrderDuplicationDialog';
import { get } from 'lodash';
import { ASSESS_INTERNAL_REFLY } from 'layout/entitleUser/EntitleUser.constants';
import useStyles from './QcHomeTable.style';
import * as actions from '../QcHome.actions';
import QcHomeVirtualizedTable from './qc-home-virtual-table/QcHomeVirtualizedTable';
import QcHomeManualAtTable from './qc-home-manual-at-table/QcHomeManualAtTable';
import { QC_ESCALATED_HEAD_CELLS } from '../QcHome.constants';
import ReflyNeededDialog from '../refly-needed-dialog/ReflyNeededDialog';

/**
 * QcHomeTable represents table for displaying QC claims
 */
const QcHomeTable = () => {
  const styles = useStyles();
  const { t } = useTranslation();

  // selectors
  const qcPendingClaims = useSelector((state) => state.qcHomeReducer.qcPendingClaims);
  const manualAtClaims = useSelector((state) => state.qcHomeReducer.manualAtClaims);
  const qcEscalatedClaims = useSelector((state) => state.qcHomeReducer.qcEscalatedClaims);
  const openOrders = useSelector((state) => state.qcHomeReducer.openOrders);
  const completedOrders = useSelector((state) => state.qcHomeReducer.completedOrders);
  const currentTab = useSelector((state) => state.qcHomeReducer.currentTab);
  const searchTerm = useSelector((state) => state.qcHomeReducer.searchTerm);
  const showOrderDuplicationDialog = useSelector((state) => state.qcHomeReducer.showOrderDuplicationDialog);
  const duplicateOrderId = useSelector((state) => state.qcHomeReducer.duplicateOrderId);
  const showDuplicationConfirmationDialog = useSelector((state) => state.qcHomeReducer.showDuplicationConfirmationDialog);
  const isReflyNeededEnabled = useSelector((state) => get(
    state.entitleUserReducer.featureFlags,
    ASSESS_INTERNAL_REFLY,
    false,
  ));

  // local state
  const [filteredQcEscalatedClaims, setFilteredQcEscalatedClaims] = useState([]);
  const [filteredQcPendingClaims, setFilteredQcPendingClaims] = useState([]);
  const [filteredManualAtClaims, setFilteredManualAtClaims] = useState([]);
  const [filteredOpenOrders, setFilteredOpenOrders] = useState([]);
  const [filteredCompletedOrders, setFilteredCompletedOrders] = useState([]);

  // actions
  const dispatch = useDispatch();
  const resetOrganizationList = () => dispatch(actions.resetOrganizationListAction());
  const setShowDuplicationConfirmationDialog = (payload) => dispatch(actions.setShowDuplicationConfirmationDialog(payload));
  const setShowOrderDuplicationDialog = (payload) => dispatch(actions.setShowOrderDuplicationDialog(payload));

  // derived values
  const { AutoSizer } = EVReactVirtualized;

  const currentTabHeadCells = {
    [TABS.QC_ESCALATED]: QC_ESCALATED_HEAD_CELLS,
    [TABS.QC_PENDING]: QC_PENDING_HEAD_CELLS,
    [TABS.OPEN_ORDERS]: OPEN_ORDERS_HEAD_CELLS,
    [TABS.COMPLETED_ORDERS]: COMPLETED_ORDERS_HEAD_CELLS,
  };

  const filterData = (data = []) => data.filter((d) => SEARCH_COLUMNS.some((col) => d[col].toUpperCase().includes(searchTerm.toUpperCase())));

  useEffect(() => {
    if (searchTerm === '' || searchTerm.length >= MIN_SEARCH_TERM_LENGTH) {
      if (currentTab === TABS.QC_ESCALATED) {
        setFilteredQcEscalatedClaims(filterData(qcEscalatedClaims));
      } else if (currentTab === TABS.QC_PENDING) {
        setFilteredQcPendingClaims(filterData(qcPendingClaims));
      } else if (currentTab === TABS.PENDING_ADJUSTMENT) {
        setFilteredManualAtClaims(filterData(manualAtClaims));
      } else if (currentTab === TABS.OPEN_ORDERS) {
        setFilteredOpenOrders(filterData(openOrders));
      } else if (currentTab === TABS.COMPLETED_ORDERS) {
        setFilteredCompletedOrders(filterData(completedOrders));
      }
    }
  }, [currentTab, searchTerm, qcPendingClaims, manualAtClaims, openOrders, completedOrders]);

  const currentTabData = {
    [TABS.QC_ESCALATED]: filteredQcEscalatedClaims,
    [TABS.QC_PENDING]: filteredQcPendingClaims,
    [TABS.OPEN_ORDERS]: filteredOpenOrders,
    [TABS.COMPLETED_ORDERS]: filteredCompletedOrders,
  };

  const handleCloseOrderDuplicationDialog = () => {
    resetOrganizationList();
    setShowOrderDuplicationDialog({ orderToBeDuplicatedDetails: {}, showDialog: false });
  };

  const duplicateOrderConfirm = () => {
    setShowDuplicationConfirmationDialog(false);
    handleCloseOrderDuplicationDialog();
  };

  return (
    currentTab && (
      <>
        <AutoSizer>
          {({ height, width }) => (currentTab === TABS.PENDING_ADJUSTMENT ? (
            <QcHomeManualAtTable
              data={filteredManualAtClaims}
              tableHeight={height || 100}
              tableWidth={width || 100}
              columns={QC_MANUAL_AT_TABLE_HEAD_CELLS}
            />
          ) : (
            <QcHomeVirtualizedTable
              data={currentTabData[currentTab]}
              tableHeight={height || 100}
              tableWidth={width || 100}
              columns={currentTabHeadCells[currentTab]}
            />
          ))}
        </AutoSizer>
        {showOrderDuplicationDialog && <OrderDuplicationDialog />}
        {isReflyNeededEnabled && <ReflyNeededDialog />}
        <EVDialog
          open={showDuplicationConfirmationDialog}
          data-testid="duplication-confirmation-dialog"
        >
          <EVDialogContent>
            <EVDialogContentText>{t('qcHome.orderDuplication.duplicationConfirmation').replace('$orderId$', duplicateOrderId)}</EVDialogContentText>
          </EVDialogContent>
          <EVDialogActions>
            <EVButton
              className={styles.button}
              color="primary"
              onClick={duplicateOrderConfirm}
              data-testid="confirm-okay"
            >
              {t('okay')}
            </EVButton>
          </EVDialogActions>
        </EVDialog>
      </>
    )
  );
};

export default QcHomeTable;
