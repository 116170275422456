import React from 'react';

import {
  EVBox,
  EVTypography,
} from '@eagleview/ev-comp-library';
import useStyles from './PhotosSidePanel.styles';

const SidePanelGallery = () => {
  // utility hooks
  const styles = useStyles();

  return (
    <EVBox className={styles.detailsTab}>
      <EVTypography>
        Photos SidePanel Gallery -- Todo
      </EVTypography>
    </EVBox>
  );
};

export default SidePanelGallery;
