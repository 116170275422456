import React from 'react';
import {
  EVTypography, EVLink,
} from '@eagleview/ev-comp-library';
import { bool, func, number } from 'prop-types';
import { useTranslation } from 'react-i18next';
import utcRoofImg from 'assets/no-roof.svg';
import utcGalleryImg from 'assets/no-gallery.svg';
import useStyles from './utc.styles';
import { TABS } from '../Adjuster.constants';

const Utc = ({
  setCurrentTabAction, currentTab, isAssessLite,
}) => {
  // utility hooks
  const styles = useStyles();
  const { t } = useTranslation();
  const isCurrentTabGallery = currentTab === TABS.GALLERY;

  const img = isCurrentTabGallery ? utcGalleryImg : utcRoofImg;
  const description = isCurrentTabGallery ? 'utc.noGalleryImg' : 'utc.noDroneImg';
  const buttonText = isCurrentTabGallery ? 'utc.roof' : 'utc.gallery';
  // eslint-disable-next-line no-nested-ternary
  const click = () => setCurrentTabAction(isCurrentTabGallery ? (isAssessLite ? 4 : 1) : 2);

  return (
    <div className={styles.utcContainer}>
      <div className={styles.utcContent}>
        <img alt="" src={img} className={styles.utcRoofImg} />
        <EVTypography data-testid="utcDesc" className={styles.utcDesc}>
          {t(description)}
        </EVTypography>
        <EVTypography className={styles.utcHint}>
          {t('utc.hint')}
          <EVLink color="primary" component="button" className={styles.utcBtn} onClick={click}>
            {t(buttonText)}
          </EVLink>
        </EVTypography>
      </div>
    </div>
  );
};

Utc.defaultProps = {
  isAssessLite: false,
};

Utc.propTypes = {
  setCurrentTabAction: func.isRequired,
  isAssessLite: bool,
  currentTab: number.isRequired,
};

export default Utc;
