import { makeStyles } from '@eagleview/ev-comp-library';

export default makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
  },
  rejectStructureButton: {
    background: 'transparent',
    boxShadow: 'none',
    color: theme.evPalette.ocean[500],
    textTransform: 'none',
    maxHeight: '30px',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    fontSize: '13px',
    '&:hover': {
      background: 'transparent',
      boxShadow: 'none',
      cursor: 'pointer',
    },
  },
  rejectedStructureStatusAndButton: {
    display: 'flex',
    justifyContent: 'space-between',
    minWidth: '55%',
  },
  rejectedStructureStatusStr: {
    margin: 'auto 0',
    color: theme.evPalette.raspberry[500],
    fontSize: '14px',
  },
  undoStructureRejectionButton: {
    background: 'transparent',
    boxShadow: 'none',
    color: theme.evPalette.ocean[500],
    textTransform: 'none',
    maxHeight: '30px',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    fontSize: '13px',
    '&:hover': {
      background: 'transparent',
      boxShadow: 'none',
      cursor: 'pointer',
    },
  },
  rejectStructureMessage: {
    fontSize: '1rem',
    margin: `${theme.spacing(1)}px 0`,
    fontWeight: '400',
  },
}));
