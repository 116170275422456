import { makeStyles } from '@eagleview/ev-comp-library';

const useStyles = makeStyles((theme) => ({
  header: {
    fontSize: theme.typography.h3.fontSize,
    fontWeight: theme.typography.fontWeightRegular,
    color: theme.evPalette.charcoal[900],
  },
  subheader: {
    color: theme.evPalette.nickel[700],
  },
  headerContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
  },
  buttonContainer: {
    display: 'flex',
  },
  button: {
    textTransform: 'none',
    height: theme.spacing(4.5),
  },
  facetImageButton: {
    color: theme.palette.primary.main,
    marginRight: theme.spacing(1),
  },
  completeButton: {
    color: theme.evPalette.salt[100],
    backgroundColor: theme.evPalette.lime[600],
    '&:hover': {
      backgroundColor: theme.evPalette.lime[800],
    },
  },
  dialogHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(0)}px`,
  },
  dialogContent: {
    padding: theme.spacing(3),
  },
  image: {
    width: '100%',
    height: '100%',
  },
}));

export default useStyles;
