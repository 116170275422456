/**
 * source code was taken from https://github.com/arthurtyukayev/use-keyboard-shortcut and was modified
 * reference link for initial source code: https://www.fullstacklabs.co/blog/keyboard-shortcuts-with-react-hooks
* */

import { useEffect, useCallback, useReducer } from 'react';
import keysReducer from './useKeyboardShortcut.reducer';

const blacklistedTargets = ['INPUT', 'TEXTAREA'];

const useKeyboardShortcut = (shortcutKeys, callback, options) => {
  if (!Array.isArray(shortcutKeys)) {
    throw new Error(
      'The first parameter to `useKeyboardShortcut` must be an ordered array of `KeyboardEvent.key` strings.',
    );
  }

  if (!shortcutKeys.length) {
    throw new Error(
      'The first parameter to `useKeyboardShortcut` must contain at least one `KeyboardEvent.key` string.',
    );
  }

  if (!callback || typeof callback !== 'function') {
    throw new Error(
      'The second parameter to `useKeyboardShortcut` must be a function that will be invoked when the keys are pressed.',
    );
  }

  const { inOrder } = options || {};
  const initialKeyMapping = shortcutKeys.reduce((currentKeys, key) => {
    // eslint-disable-next-line no-param-reassign
    currentKeys[key.toLowerCase()] = false;
    return currentKeys;
  }, {});

  const lowerCaseKeys = shortcutKeys.map((k) => k.toLowerCase());

  const initialState = {
    mapping: initialKeyMapping,
    received: [],
  };

  const [keys, setKeys] = useReducer(keysReducer, initialState);

  const keydownListener = useCallback(
    (assignedKey) => (keydownEvent) => {
      const loweredKey = assignedKey.toLowerCase();

      // to make sure that this KeyboardEvent is not a repeating event,
      // which means that this is the first key down event of this key
      // if it's repeating, that means that the key is being held down and has already been processed
      if (keydownEvent.repeat) return;

      // to make sure that DOM element isn't an input or textarea
      // to avoid triggering keyboard shortcuts when the user is typing
      if (blacklistedTargets.includes(keydownEvent.target.tagName)) return;

      if (loweredKey !== keydownEvent.key.toLowerCase()) return;
      if (keys.mapping[loweredKey] === undefined) return;

      setKeys({ type: 'set-key-down', key: loweredKey });
    },
    [keys],
  );

  const keyupListener = useCallback(
    (assignedKey) => (keyupEvent) => {
      const raisedKey = assignedKey.toLowerCase();

      // to make sure that DOM element isn't an input or textarea
      // to avoid triggering keyboard shortcuts when the user is typing
      if (blacklistedTargets.includes(keyupEvent.target.tagName)) return;

      if (keyupEvent.key.toLowerCase() !== raisedKey) return;
      if (keys.mapping[raisedKey] === undefined) return;

      setKeys({ type: 'set-key-up', key: raisedKey });
    },
    [keys],
  );

  useEffect(() => {
    if (Object.values(keys.mapping).filter((value) => value).length === shortcutKeys.length) {
      if (inOrder) {
        if (JSON.stringify(lowerCaseKeys) === JSON.stringify(keys.received)) {
          callback();
          setKeys({ type: 'reset-keys', data: initialState });
        }
      } else {
        callback();
        setKeys({ type: 'reset-keys', data: initialState });
      }
    } else {
      setKeys({ type: null });
    }
  }, [callback, keys]);

  useEffect(() => {
    shortcutKeys.forEach((k) => window.addEventListener('keydown', keydownListener(k)));
    return () => shortcutKeys.forEach((k) => window.removeEventListener('keydown', keydownListener(k)));
  }, []);

  useEffect(() => {
    shortcutKeys.forEach((k) => window.addEventListener('keyup', keyupListener(k)));
    return () => shortcutKeys.forEach((k) => window.removeEventListener('keyup', keyupListener(k)));
  }, []);

  // returned for unit test cases
  return { keys };
};

export default useKeyboardShortcut;
