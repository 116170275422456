import React from 'react';
import {
  EVTypography,
  EVChip,
  EVBox,
  EVSvgIcon,
} from '@eagleview/ev-comp-library';
import { func, string, number } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ReactComponent as selectedImg } from 'assets/confidence-factor-icon.svg';
import useStyles from './Statusbar.styles';

const ConfidenceFactorSelector = ({
  handleConfidenceSelector, updateStatus, selectedConfidenceFactor,
}) => {
  const styles = useStyles();
  const { t } = useTranslation();

  const confidenceSelectors = [
    { name: t('statusbar.selector.fewer') },
    { name: t('statusbar.selector.recomended') },
    { name: t('statusbar.selector.more') },
  ];

  return (
    <>
      <EVBox>
        <EVSvgIcon component={selectedImg} className={styles.confidenceFactorIcon} />
      </EVBox>
      <EVBox>
        <EVTypography variant="body2" className={styles.confidenceFactorLabel}>
          {t('statusbar.selector.label')}
        </EVTypography>
      </EVBox>
      <EVBox key={updateStatus}>
        { confidenceSelectors.map((item) => (
          <EVChip
            id={item.name}
            key={item.name}
            label={item.name.toUpperCase()}
            className={selectedConfidenceFactor === item.name ? styles.confidenceFactorSelected : styles.confidenceFactor}
            onClick={() => { handleConfidenceSelector(item.name); }}
          />
        ))}
      </EVBox>
    </>
  );
};

ConfidenceFactorSelector.propTypes = {
  selectedConfidenceFactor: string.isRequired,
  updateStatus: number.isRequired,
  handleConfidenceSelector: func.isRequired,
};

export default ConfidenceFactorSelector;
