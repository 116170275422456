import axios from 'axios';
import * as util from 'utils/utils';
import { ASSESS_SERVICE_API_ENDPOINT, ASSESS_ORDER_MANAGER_API_ENDPOINT } from 'constants.js';

export const loadCurrentClaims = async () => {
  try {
    const { data } = await axios.get(`${ASSESS_SERVICE_API_ENDPOINT}/orders/status/current`);
    return data;
  } catch (error) {
    return util.handleHttpError(error);
  }
};

export const loadPastClaims = async () => {
  try {
    const { data } = await axios.get(`${ASSESS_SERVICE_API_ENDPOINT}/orders/status/past`);
    return data;
  } catch (error) {
    return util.handleHttpError(error);
  }
};

export const fetchAdjusters = async (searchTerm = '', cancelToken) => {
  try {
    const { data } = await axios.get(`${ASSESS_ORDER_MANAGER_API_ENDPOINT}/adjusters?adjuster_filter=${searchTerm}`, { cancelToken });
    return data;
  } catch (error) {
    return util.handleHttpError(error);
  }
};

export const assignAdjuster = async ({ orderId, adjuster }) => {
  try {
    const { data } = await axios.put(`${ASSESS_ORDER_MANAGER_API_ENDPOINT}/orders/${orderId}/assignment`, adjuster);
    return data;
  } catch (error) {
    return util.handleHttpError(error);
  }
};

export const getAppointmentDetails = async ({ orderId }) => {
  try {
    const { data } = await axios.get(`${ASSESS_SERVICE_API_ENDPOINT}/orders/${orderId}/appointment`);
    return data;
  } catch (error) {
    return util.handleHttpError(error);
  }
};

export const rescheduleAppointment = async ({ orderId, body }) => {
  try {
    const { data } = await axios.post(`${ASSESS_SERVICE_API_ENDPOINT}/orders/${orderId}/reschedule`, body);
    return data;
  } catch (error) {
    return util.handleHttpError(error);
  }
};
