import React from 'react';
import { useSelector } from 'react-redux';
import { func, bool } from 'prop-types';
import {
  EVList, EVListItem, EVTypography, EVChip, EVBox, EVCropFree, EVGrid, EVTooltip,
} from '@eagleview/ev-comp-library';
import orderBy from 'lodash/orderBy';
import get from 'lodash/get';
import clsx from 'clsx';
import EntitledComponent from 'components/EntitledComponent';
import { FEATURE_ENTITLEMENTS } from 'layout/entitleUser/EntitleUser.constants';
import * as util from 'layout/adjuster/Adjuster.utils';
import { isEntitled } from 'utils/auth.utils';
import { useTranslation } from 'react-i18next';
import useStyles from './SidePanelRoof.styles';
import useMainStyles from '../AdjusterSidePanel.styles';

const SidePanelRoof = ({ setFacetWithInitialAnomaly, isCaptured }) => {
  // utility hooks
  const styles = useStyles();
  const mainStyles = useMainStyles();
  const { t } = useTranslation();

  // selectors
  const facets = useSelector((state) => state.adjusterReducer.facets);
  const selectedFacet = useSelector((state) => state.adjusterReducer.selectedFacet);
  const anomalies = useSelector((state) => state.adjusterReducer.anomalies);
  const facetTestSquareDisabledMap = useSelector((state) => state.adjusterReducer.facetTestSquareDisabledMap);
  const entitlements = useSelector((state) => state.entitleUserReducer.entitlements);

  // Derived values
  const anomalyIdsMap = anomalies.map((anomaly) => get(anomaly, 'data.0.properties.anomalyId'));
  const colorByStatus = {
    UNDECIDED: styles.undecidedChip,
    REPLACE: styles.decidedChip,
    REPAIR: styles.decidedChip,
    'NO DAMAGE': styles.decidedChip,
  };
  const facetsWithFilteredAnomalies = facets.map((facet) => {
    const testsquareAnomalies = get(facet, 'testsquare.anomalies', []) || [];
    const filteredAnomalies = facetTestSquareDisabledMap[facet.facetId]
      ? anomalyIdsMap : testsquareAnomalies.filter((anomaly) => anomalyIdsMap.includes(anomaly));
    return {
      ...facet,
      anomalyList: facet.anomalyList.filter((anomaly) => (filteredAnomalies.includes(get(anomaly, 'anomalyId')))),
    };
  });

  return (
    <EVBox id="roof" className={mainStyles.tab}>
      {isCaptured && (
        <EVList className={styles.roofList}>
          {orderBy(facetsWithFilteredAnomalies, ['anomalyList'], ['desc']).map((facet) => (
            <EVTooltip title={t('RoofSidePanel.facetToolTip')} disableHoverListener={!facet.underHang}>
              <EVListItem
                disabled={facet.underHang}
                onClick={() => (!facet.underHang && setFacetWithInitialAnomaly(facet.facetId))}
                key={facet.facetId}
                className={clsx(styles.roofDetails, facet.facetId === selectedFacet && styles.selected)}
                divider
              >
                <EVGrid container spacing={1}>
                  <EVGrid item xs={1} className={styles.roofListItemCenterAligned}>
                    <EVTypography variant="h3">{facet.facetAliasName}</EVTypography>
                  </EVGrid>
                  <EVGrid item xs={2} className={styles.roofListItemCenterAligned}>
                    {!facetTestSquareDisabledMap[facet.facetId] && isEntitled(entitlements, FEATURE_ENTITLEMENTS.VIEW_DECISIONS) && (
                    <EVBox>
                      <EVCropFree />
                    </EVBox>
                    )}
                  </EVGrid>
                  <EVGrid item xs={4} className={styles.roofListItemRightAligned}>
                    <EVTypography>
                      {`${Number(facet.facetOutline.area).toFixed(0)} sq.ft`}
                    </EVTypography>
                  </EVGrid>
                  <EVGrid item xs={3} className={styles.roofListItemCenterAligned}>
                    <EntitledComponent entitlement={FEATURE_ENTITLEMENTS.VIEW_DECISIONS}>
                      <EVBox>
                        <EVChip
                          className={clsx(colorByStatus[facet.decision], styles.chipStyle)}
                          label={facet.decision}
                        />
                      </EVBox>
                    </EntitledComponent>
                  </EVGrid>
                  <EVGrid item xs={2}>
                    <EVBox className={styles.anomaliesCounter}>
                      <EVTypography className={styles.userDefinedCount}>
                        {get(util.getAnomaliesBreakdown(get(facet, 'anomalyList', [])), 'totalConfirmed', []).length}
                      </EVTypography>
                      {' | '}
                      <EVTypography className={styles.totalCount}>
                        {get(util.getAnomaliesBreakdown(get(facet, 'anomalyList', [])), 'potentialCV', []).length}
                      </EVTypography>
                    </EVBox>
                  </EVGrid>
                </EVGrid>
              </EVListItem>
            </EVTooltip>
          ))}
        </EVList>
      )}
      {!isCaptured && (
        <EVTypography className={styles.utc}>
          {t('utc.sidepanel.roof')}
        </EVTypography>
      )}
    </EVBox>
  );
};

SidePanelRoof.propTypes = {
  isCaptured: bool,
  setFacetWithInitialAnomaly: func,
};

SidePanelRoof.defaultProps = {
  isCaptured: true,
  setFacetWithInitialAnomaly: () => {
    // This is an empty block
  },
};

export default SidePanelRoof;
