import { makeStyles } from '@eagleview/ev-comp-library';

export default makeStyles((theme) => ({
  zoomToolBar: {
    position: 'absolute',
    display: 'flex',
    right: theme.spacing(2),
    zIndex: 1,
    flexDirection: 'row-reverse',
    bottom: `calc(${theme.evSpacing['ev-spacing--32']} + 32px)`,
  },
}));
