import { logger } from 'utils/utils';
import MAPVIEWER_VERSION from './mapviewer.version.json';
/**
 * This file contains application constants, all the constants should be exported.
 * Use UPPER_CASE_SNAKE_CASE for constant name.
 */

export const ASSESS_DECISION_API_ENDPOINT = `${process.env.REACT_APP_ASSESS_DECISION_API_URL}`;
export const ASSESS_SERVICE_API_ENDPOINT = `${process.env.REACT_APP_ASSESS_SERVICE_API_URL}`;
export const ASSESS_SERVICE_V2_API_ENDPOINT = `${process.env.REACT_APP_ASSESS_SERVICE_V2_API_URL}`;
export const ASSESS_PHOTOGRAMMETRY_UTIL_API_ENDPOINT = `${process.env.REACT_APP_ASSESS_PHOTOGRAMMETRY_UTIL_SERVICE}`;
export const ASSESS_ORDER_MANAGER_API_ENDPOINT = `${process.env.REACT_APP_ORDER_MANAGER_API_URL}`;
export const ASSESS_ORDER_MANAGER_V2_API_ENDPOINT = `${process.env.REACT_APP_ORDER_MANAGER_V2_API_URL}`;
export const IW_API_ENDPOINT = `${process.env.REACT_APP_IW_API_URL}`;
export const ASSESS_IMAGES_API_ENDPOINT = `${process.env.REACT_APP_ASSESS_IMAGES_API_URL}`;
export const ASSESS_APPLICATION_SETTINGS_ENDPOINT = `${process.env.REACT_APP_APPLICATION_SETTINGS}/applications/assess/settings/default/values`;
export const FLUID_API_ENDPOINT = `${process.env.REACT_APP_FLUID_API_URL}`;
export const REACT_APP_ENV = `${process.env.REACT_APP_ENV}`;
export const OPS_PRIME_API_ENDPOINT = `${process.env.REACT_APP_OPS_PRIME_API_URL}`;
export const VALIDATE_SHARELINK_ENDPOINT = `${process.env.REACT_APP_SHARE_SERVICE}/gateway/v1/validate`;
export const ASSESS_SERVICE_INTERNAL_API_ENDPOINT = `${process.env.REACT_APP_ASSESS_SERVICE_INTERNAL_API_URL}`;
export const SHARE_LINK_ENDPOINT = `${process.env.REACT_APP_SHARE_SERVICE}`;
// eslint-disable-next-line max-len
export const ENTITLEMENT_SERVICE_API_ENDPOINT = `${process.env.REACT_APP_ASSESS_ENTITLEMENTS}/entitlements`;
export const INTERNAL_SCHEDULER_REDIRECT_URL = `${process.env.REACT_APP_INTERNAL_SCHEDULER_REDIRECT_URL}`;
export const ORDER_ORCHESTRATOR_API_ENDPOINT = `${process.env.REACT_APP_ORDER_ORCHESTRATOR_API_URL}`;
export const ROOF_TO_VIEWPORT_RATIO = `${process.env.REACT_APP_ROOF_TO_VIEWPORT_RATIO}`;
export const ASSESS_SHAREDURL_APP_ORIGIN = `${process.env.REACT_APP_ASSESS_SHAREDURL_APP_ORIGIN}`;
export const ASSESS_BASE_PATH = '/assess';
export const DEFAULT_DATE_FORMAT = 'MMMM DD, YYYY';
export const DEFAULT_DATE_TIME_FORMAT = 'MMMM DD, YYYY HH:mm:ss';
export const TOOLTIP_DELAY = 500;
export const ZOOM_WITH_EASE_DURATION = 1200;
export const SORT_ORDER = {
  asc: 'asc',
  desc: 'desc',
};
export const USER_KEY = 'shared-user';
// This is not related to any release or anything
// this is just for dev to identify if the changes are deployed or not.
export const VERSION = 'master';
export const SUPPORT_LINK = 'https://www.eagleview.com/support/';
export const LAUNCH_DARKLY_CLIENT_ID = `${process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_ID}`;
logger('debug', 'Assess Version: ', VERSION);
logger('debug', 'Mapviewer Version', MAPVIEWER_VERSION);
