import React from 'react';
import {
  EVClear,
  EVIconButton,
  useTheme,
} from '@eagleview/ev-comp-library';
import { Rnd } from 'react-rnd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import PendingImage from 'assets/riskImage.png';
import * as action from '../../Adjuster.actions';
import useStyles from './QCSkydioMosaicPanel.styles';

const QCSkydioMosaicPanel = () => {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();

  // selectors
  const skydioMosaicImageUrl = useSelector((state) => state.adjusterReducer.skydioMosaicImageUrl);

  // actions
  const dispatch = useDispatch();
  const setShowSkydioMosaicImagePanel = (payload) => dispatch(action.setShowSkydioMosaicImagePanel(payload));

  const panelDefaultWidth = theme.spacing(63.375);
  const panelDefaultHeight = theme.spacing(47.5);
  const panelLeft = window.innerWidth - panelDefaultWidth - 100;

  return (
    <Rnd
      id="skydioMosaicPanel"
      className={classes.panel}
      style={{ float: 'right', right: theme.spacing(12.5) }}
      default={{
        x: panelLeft,
        y: 10,
        width: panelDefaultWidth,
        height: panelDefaultHeight,
      }}
      minWidth={panelDefaultWidth / 2}
      minHeight={panelDefaultHeight / 2}
      bounds="parent"
      lockAspectRatio
    >
      <EVIconButton className={classes.closeIconButton} onClick={() => setShowSkydioMosaicImagePanel(false)} data-testid="closeImagePanelIcon">
        <EVClear fontSize="small" />
      </EVIconButton>
      <img src={skydioMosaicImageUrl || PendingImage} alt={t('qcPanel.skydioImageAltText')} draggable="false" className={classes.image} />
    </Rnd>
  );
};

export default QCSkydioMosaicPanel;
