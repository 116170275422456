import { makeStyles } from '@eagleview/ev-comp-library';

export default makeStyles((theme) => ({
  flexContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  tableCell: {
    borderBottom: 'none',
  },
  tableHeader: {
    '& :focus': {
      outline: 0,
    },
    borderBottom: `1px solid ${theme.evPalette.charcoal[200]}`,
  },
  table: {
    '& .ReactVirtualized__Table__row': {
      border: '1px solid transparent',
      borderBottom: `1px solid ${theme.evPalette.charcoal[200]}`,
    },
    '& :focus': {
      outline: 0,
    },
  },
  tableRow: {
    '&:nth-child(odd)': {
      background: theme.evPalette.charcoal[100],
      zIndex: 0,
    },
    zIndex: 1,
    '& :focus': {
      outline: 0,
    },
  },
}));
