import React from 'react';
import get from 'lodash/get';
import { EVTooltip, EVTypography } from '@eagleview/ev-comp-library';
import { useTranslation } from 'react-i18next';
import { TOOLTIP_DELAY } from 'constants.js';
import { string } from 'prop-types';
import { useSelector } from 'react-redux';
import { QC_ENHANCEMENT } from 'layout/entitleUser/EntitleUser.constants';
import useStyles from './QcPendingPanel.styles';
import { QC_STATUS_MAP, ENH_QC_STATUS_MAP, QC_PANEL_MAP } from './QCPendingPanel.constants';

const QcPendingPanelTitle = (props) => {
  const { t } = useTranslation();
  const styles = useStyles();
  const { title } = props;
  const enableQcEnhancement = useSelector((state) => get(state.entitleUserReducer.featureFlags, QC_ENHANCEMENT, false));
  return (
    <>
      {
          enableQcEnhancement === true ? (
            <EVTooltip title={t(`${ENH_QC_STATUS_MAP[title]}`)} enterDelay={TOOLTIP_DELAY}>
              <div className={styles.titleContainer}>
                <EVTypography variant="h3" className={styles.infoPanelFontSize}>{t(`${QC_PANEL_MAP[title] || ''}`)}</EVTypography>
                <EVTypography variant="h3" className={styles.infoPanelTypeFontSize}>{t(`${ENH_QC_STATUS_MAP[title] || ''}`)}</EVTypography>
              </div>
            </EVTooltip>
          ) : (
            <EVTooltip title={t(`${QC_STATUS_MAP[title]}`)} enterDelay={TOOLTIP_DELAY}>
              <div className={styles.titleContainer}>
                <EVTypography variant="h3" className={styles.infoPanelFontSize}>{t(`${QC_STATUS_MAP[title] || ''}`)}</EVTypography>
              </div>
            </EVTooltip>
          )
        }
    </>
  );
};

QcPendingPanelTitle.propTypes = {
  title: string.isRequired,
};
export default QcPendingPanelTitle;
