import omit from 'lodash/omit';
import {
  VERTEX_STYLE,
  ADD_ACTIVE_COORD_PAIRINGS,
  ADD_COORD_ONE,
  ADD_COORD_TWO,
  FETCH_IMAGES,
  FETCH_IMAGES_FAILURE,
  FETCH_IMAGES_SUCCESS,
  SET_ACTIVE_IMAGE,
  CLOSE_ERROR_TOAST,
  FETCH_ORTHO_POLYGON,
  FETCH_ORTHO_POLYGON_SUCCESS,
  FETCH_ORTHO_IMAGE,
  FETCH_ORTHO_IMAGE_SUCCESS,
  SET_ACTIVE_VERTEX,
  SET_VIEW,
  SET_INITIAL_VIEW,
  FETCH_ORDER_DETAILS,
  FETCH_ORDER_DETAILS_SUCCESS,
  FETCH_ORDER_DETAILS_FAILURE,
  HANDLE_CHECKOUT_ERROR,
  SET_OVERVIEW_VIEW,
  SET_OVERVIEW_INITIAL_VIEW,
  SET_SELECTED_IMAGES_LIST,
  SET_IS_MIN_IMAGE_SELECTED,
  UPDATE_TASK_STATE_ID,
  COMPLETE,
  COMPLETE_SUCCESS,
  COMPLETE_FAILURE,
  REJECT,
  REJECT_SUCCESS,
  REJECT_FAILURE,
  SHOW_EMPTY_REJECT_NOTES_ERROR,
  RESET_ACTIONS_PERFORMED,
  FETCH_IMAGES_AND_DETAILS_FAILURE,
  SET_HIDE_UI,
  SET_INITIAL_IMAGES_DETAILS,
  INITIAL_VIEW_ORTHO,
  INITIAL_VIEW_OVERVIEW,
  SHOW_REJECT_REASON_NOT_SELECTED_ERROR,
  SET_SRC,
  ADD_COORDINATE_PAIRING,
  REMOVE_COORDINATE_PAIRING,
  SET_SRC_DST,
  DELETE_OVERVIEW_POINT,
  SHOW_NOT_ENTITLED_TO_MANUAL_AT_ERROR,
  STATUS_COMPLETED,
  ENABLE_READ_ONLY_MODE,
  SHOW_TOAST_MESSAGE,
  SERVER_ERROR_CODES_TO_TOAST_MESSAGE,
} from './ManualAtAdjustment.constants';

const initialState = {
  images: [],
  activeImage: '',
  src: undefined,
  dst: undefined,
  coordinatePairings: [],
  loading: {},
  toastSeverity: 'error',
  showToast: undefined,
  showToastPersistently: false,
  toastDuration: 10000,
  view: INITIAL_VIEW_ORTHO,
  initialView: INITIAL_VIEW_ORTHO,
  overviewView: {},
  overviewInitialView: INITIAL_VIEW_OVERVIEW,
  vertices: [],
  activeVertex: undefined,
  orthoImage: [],
  completedVertices: [],
  polygon: [],
  hideUI: true,
  selectedImagesList: [],
  isMinImageSelected: false,
  taskStateId: '',
  readOnlyMode: false,
};

const manualAtAdjustmentReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_IMAGES:
    case FETCH_ORTHO_POLYGON:
    case FETCH_ORTHO_IMAGE:
    case FETCH_ORDER_DETAILS:
    case REJECT:
    case COMPLETE:
      return {
        ...state,
        loading: { ...state.loading, [type]: true },
      };
    case FETCH_IMAGES_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, [FETCH_IMAGES]: false },
        images: payload && payload.length > 0 ? payload : state.images,
        selectedImagesList: state.selectedImagesList.length ? state.selectedImagesList : ((payload.length && [payload[0].image_name]) || []),
      };
    case FETCH_IMAGES_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, [FETCH_IMAGES]: false },
        showToast: true,
        toastMessage: SERVER_ERROR_CODES_TO_TOAST_MESSAGE[payload.errorCode] || 'manualAtAdjustment.fetchImagesError',
        errorCode: payload.errorCode,
        toastSeverity: 'error',
      };
    case SET_ACTIVE_IMAGE:
      return {
        ...state,
        activeImage: payload || state.activeImage,
      };
    case ADD_COORD_ONE:
      return {
        ...state,
        dst: payload,
      };
    case ADD_COORD_TWO:
      return {
        ...state,
        src: {
          ...state.src,
          [state.activeImage.image_name]: payload,
        },
      };
    case ADD_ACTIVE_COORD_PAIRINGS:
      return {
        ...state,
        src: undefined,
        dst: undefined,
        coordinatePairings: [...state.coordinatePairings, {
          id: payload.id, index: payload.coordinateIndex, dst: state.dst, src: state.src,
        }],
        vertices: [
          {
            ...state.vertices[0],
            data: state.vertices[0].data.map((x) => {
              switch (true) {
                case state.completedVertices.includes(x.id):
                  return { ...x, style: VERTEX_STYLE.COMPLETED };
                case x.id === state.activeVertex:
                  return { ...x, style: VERTEX_STYLE.COMPLETED };
                default:
                  return { ...x, style: VERTEX_STYLE.UNSELECTED };
              }
            }),
          },
        ],
        completedVertices: [...state.completedVertices, state.activeVertex],
      };
    case RESET_ACTIONS_PERFORMED:
      return {
        ...state,
        src: undefined,
        dst: undefined,
        coordinatePairings: [],
        completedVertices: [],
        activeVertex: undefined,
        overviewView: {},
        activeImage: '',
        vertices: [
          {
            ...state.vertices[0],
            data: state.vertices[0].data.map((x) => ({ ...x, style: VERTEX_STYLE.UNSELECTED })),
          },
        ],
        selectedImagesList: state.images.length ? [state.images[0].image_name] : [],
        isMinImageSelected: false,
      };
    case CLOSE_ERROR_TOAST:
      return {
        ...state,
        showToast: false,
        showToastPersistently: false,
        toastDuration: 10000,
        errorCode: undefined,
      };
    case FETCH_ORTHO_POLYGON_SUCCESS:
      return {
        ...state,
        vertices: payload.vertices,
        polygon: payload.polygon,
        coordinatePairings: payload.coordinatePairings,
        completedVertices: payload.completedVertices,
      };
    case FETCH_ORTHO_IMAGE_SUCCESS:
      return {
        ...state,
        orthoImage: [payload],
      };
    case FETCH_ORDER_DETAILS_SUCCESS:
      return {
        ...state,
        orderDetails: payload,
        overviewView: payload.selectedImages && payload.selectedImages.length > 0
          ? Object.assign(...payload.selectedImages.map((image) => ({ [image]: INITIAL_VIEW_OVERVIEW }))) : {},
        selectedImagesList: payload.selectedImages || [],
        isMinImageSelected: payload.selectedImages && payload.selectedImages.length >= 2,
        loading: { ...state.loading, [FETCH_ORDER_DETAILS]: false },
      };
    case FETCH_ORDER_DETAILS_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, [FETCH_ORDER_DETAILS]: false },
        showToast: true,
        toastMessage: 'manualAtAdjustment.fetchOrderDetailsError',
        errorCode: payload.errorCode,
        toastSeverity: 'error',
      };
    case SET_ACTIVE_VERTEX:
      return {
        ...state,
        activeVertex: payload,
        vertices: [
          {
            ...state.vertices[0],
            data: state.vertices[0].data.map((x) => {
              switch (true) {
                case x.id === payload:
                  return { ...x, style: VERTEX_STYLE.SELECTED };
                case state.completedVertices.includes(x.id):
                  return { ...x, style: VERTEX_STYLE.COMPLETED };
                default:
                  return { ...x, style: VERTEX_STYLE.UNSELECTED };
              }
            }),
          },
        ],
      };
    case HANDLE_CHECKOUT_ERROR:
      return {
        ...state,
        showToast: true,
        toastMessage: payload,
        errorCode: null,
        toastSeverity: 'error',
        hideUI: true,
      };
    case SET_OVERVIEW_VIEW:
      return {
        ...state,
        overviewView: {
          ...state.overviewView,
          [state.activeImage.image_name]: payload,
        },
      };
    case SET_OVERVIEW_INITIAL_VIEW:
      return {
        ...state,
        overviewInitialView: payload || state.view,
      };
    case SET_VIEW:
      return {
        ...state,
        view: payload || state.view,
      };
    case SET_INITIAL_VIEW:
      return {
        ...state,
        initialView: payload || state.view,
      };
    case SET_SELECTED_IMAGES_LIST:
      return {
        ...state,
        selectedImagesList: payload,
        overviewView: Object.assign(payload.map((image) => ({ [image]: INITIAL_VIEW_OVERVIEW }))),
      };
    case SET_IS_MIN_IMAGE_SELECTED:
      return {
        ...state,
        isMinImageSelected: payload,
      };
    case UPDATE_TASK_STATE_ID:
      return {
        ...state,
        taskStateId: payload,
      };
    case COMPLETE_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, [COMPLETE]: false },
        showToast: true,
        toastMessage: 'manualAtAdjustment.completeMessage',
        toastSeverity: 'success',
        errorCode: '',
        orderDetails: { ...state.orderDetails, status: STATUS_COMPLETED },
      };
    case COMPLETE_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, [COMPLETE]: false },
        showToast: true,
        toastMessage: 'manualAtAdjustment.completeError',
        errorCode: payload.errorCode,
        toastSeverity: 'error',
      };
    case REJECT_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, [REJECT]: false },
        showToast: true,
        toastMessage: 'manualAtRejectPanel.rejectSuccessMessage',
        toastSeverity: 'success',
        errorCode: '',
      };
    case REJECT_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, [REJECT]: false },
        showToast: true,
        toastMessage: 'manualAtRejectPanel.rejectError',
        errorCode: payload.errorCode,
        toastSeverity: 'error',
      };
    case SHOW_EMPTY_REJECT_NOTES_ERROR:
      return {
        ...state,
        showToast: true,
        toastMessage: 'manualAtRejectPanel.emptyNotesErrorMessage',
        toastSeverity: 'error',
        errorCode: '',
      };
    case FETCH_IMAGES_AND_DETAILS_FAILURE:
      return {
        ...state,
        showToast: true,
        toastMessage: 'manualAtAdjustment.fetchOrderDetailsError',
        errorCode: payload.errorCode,
        toastSeverity: 'error',
      };
    case SET_HIDE_UI:
      return {
        ...state,
        hideUI: payload,
      };
    case SET_INITIAL_IMAGES_DETAILS:
      return {
        ...state,
        images: payload && payload.length > 0 ? payload : state.images,
      };
    case SHOW_REJECT_REASON_NOT_SELECTED_ERROR:
      return {
        ...state,
        showToast: true,
        toastMessage: 'manualAtRejectPanel.emptyRejectReasonNotSelected',
        errorCode: '',
        toastSeverity: 'error',
      };
    case SET_SRC:
      return {
        ...state,
        src: payload,
      };
    case ADD_COORDINATE_PAIRING:
      return {
        ...state,
        coordinatePairings: [...state.coordinatePairings, payload.coordinatePair],
        completedVertices: [...state.completedVertices, payload.vertexId],
      };
    case REMOVE_COORDINATE_PAIRING:
      return {
        ...state,
        coordinatePairings: [...state.coordinatePairings.filter((pairing) => pairing.id !== payload)],
        completedVertices: [...state.completedVertices.filter((vertex) => vertex !== payload)],
      };
    case SET_SRC_DST:
      return {
        ...state,
        src: payload.src,
        dst: payload.dst,
      };
    case DELETE_OVERVIEW_POINT: {
      const isNotLastSrcPoint = Object.keys(state.src).length > 1;
      return {
        ...state,
        src: isNotLastSrcPoint ? omit({ ...state.src }, state.activeImage.image_name) : undefined,
        dst: isNotLastSrcPoint ? state.dst : undefined,
        vertices: isNotLastSrcPoint ? state.vertices : [
          {
            ...state.vertices[0],
            data: state.vertices[0].data.map((x) => {
              if (x.id === state.activeVertex) {
                return { ...x, style: VERTEX_STYLE.UNSELECTED };
              }
              return x;
            }),
          },
        ],
      };
    }
    case SHOW_NOT_ENTITLED_TO_MANUAL_AT_ERROR:
      return {
        ...state,
        showToast: true,
        toastMessage: 'manualAtAdjustment.notEntitledError',
        toastSeverity: 'error',
        errorCode: '',
        showToastPersistently: true,
      };
    case ENABLE_READ_ONLY_MODE:
      return {
        ...state,
        readOnlyMode: true,
      };
    case SHOW_TOAST_MESSAGE:
      return {
        ...state,
        showToast: true,
        toastMessage: payload.toastMessage,
        toastSeverity: payload.toastSeverity,
        errorCode: payload.errorCode,
        showToastPersistently: payload.showToastPersistently,
        toastDuration: payload.toastDuration,
      };
    default:
      return state;
  }
};

export default manualAtAdjustmentReducer;
