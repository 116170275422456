import React, {
  useEffect, useState, useRef, useReducer,
} from 'react';
import { bool } from 'prop-types';
import {
  EVBox, EVCompass, useTheme, EVCircularProgress, EVBackdrop,
} from '@eagleview/ev-comp-library';
import EVMapViewer, {
  INTERACTION_STATES,
} from '@eagleview/mapviewer-react';
import * as turf from '@turf/turf';
import ToastMessage from 'components/toast-message';
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import groupBy from 'lodash/groupBy';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { push } from 'connected-react-router';
import { v4 as uuidv4 } from 'uuid';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';
import * as authUtils from 'utils/auth.utils';
import { ASSESS_IMAGES_API_ENDPOINT, REACT_APP_ENV } from 'constants.js';
import selectedImg from 'assets/anomaly-selected.svg';
import hiddenImg from 'assets/hidden.svg';
import userAnomalyImg from 'assets/manual-count.svg';
import userAnomalySelectedImg from 'assets/manual-selected.svg';
import opportunityImg from 'assets/opportunity.svg';
import validatedImg from 'assets/validated.svg';
import disabledValidatedImg from 'assets/disabled-validated.svg';
import disabledOpportunityImg from 'assets/disabled-opportunity.svg';
import disabledUserDefinedImg from 'assets/disabled-user-defined.svg';
import ErrorBoundary from 'components/ErrorBoundary';
import EntitledComponent from 'components/EntitledComponent';
import {
  FEATURE_ENTITLEMENTS, ENABLE_FACET_RAW_IMAGES, GROUND_ACCESSORY,
  ANNOTATION_DESC, ASSESS_PHOTO_VIEWER, ASSESS_DIGITAL_DELIVERY,
} from 'layout/entitleUser/EntitleUser.constants';
import * as sharedUtil from 'utils/utils';
import { errorHandlerService, logger, debugMode } from 'utils/utils';
import { isEntitled } from 'utils/auth.utils';
import { claimStatus } from 'layout/homeLayout/home/Home.constants';
import { isNull } from 'lodash';
import notesIcon from 'assets/notesIcon.svg';
import * as workflowPanelSelector from 'layout/adjuster/workflow-panel/WorkflowPanel.selectors';
import useResizeObserver from 'utils/useResizeObserver';
import PhotosUITabs, { PhotosUITab } from 'layout/photos/PhotosUITabs';
import AdjusterSidePanel from './adjuster-sidepanel/AdjusterSidePanel';
import AdjusterToolbar from './adjuster-toolbar';
import * as action from './Adjuster.actions';
import {
  ANOMALY_DECISIONS,
  BUCKET,
  ROOF_MAX_ZOOM,
  DEFAULT_MIN_ZOOM,
  ROOF_MAX_ZOOM_SHOW_ANOMALIES,
  UNSET_ANOMALY_ID,
  TESTSQUARE_ZOOM_LEVEL_ON_SELECTION,
  EXPORT_IMAGERY,
  ORDER_VISITED_PENDO_EVENT,
  ORDER_COMPLETED_PENDO_EVENT,
  TABS,
  FETCH_ADJUSTER_DETAILS_GALLERY,
  GET_ANOMALY_NOTES,
  UPDATE_ACCESS_TOKEN,
} from './Adjuster.constants';
import useStyles from './Adjuster.styles';
import * as util from './Adjuster.utils';
import ImagePreview from './image-preview/ImagePreview';
import ReportGenerator from './reports/ReportGenerator';
import Statusbar from './statusbar';
import WorkflowPanel from './workflow-panel';
import QcPendingPanel from './QcPendingPanel/QcPendingPanel';
import QCSkydioMosaicPanel from './QcPendingPanel/qc-skydio-mosaic-panel/QCSkydioMosaicPanel';
import ExportImageryPanel from './export-imagery/ExportImageryPanel';
import Utc from './utc/utc';
import AnnotationDescPanel, {
  annotationDescPanelConstants,
  annotationDescPanelReducer,
  isCompleteVisibleH,
  isCompleteVisibleV,
} from './annotation-description';
import AnomalyDescPanel from './anomaly-description/AnomalyDescPanel';
import ShareReportPanel from './share-report-panel/ShareReportPanel';

/**
 * Represents container component for adjuster
 */
const Adjuster = ({ hideNavbar }) => {
  // selectors
  const currentView = useSelector((state) => state.adjusterReducer.currentView);
  const roofBoundingBox = useSelector((state) => state.adjusterReducer.roofBoundingBox);
  const facetPolygons = useSelector((state) => state.adjusterReducer.facetPolygons);
  const labelAssets = useSelector((state) => state.adjusterReducer.labelAssets);
  const showErrorToast = useSelector((state) => state.adjusterReducer.showErrorToast);
  const errorToastMessage = useSelector((state) => state.adjusterReducer.errorToastMessage);
  const errorCode = useSelector((state) => state.adjusterReducer.errorCode);
  const buckets = useSelector((state) => state.adjusterReducer.buckets);
  const anomalies = useSelector((state) => state.adjusterReducer.anomalies);
  const selectedFacet = useSelector((state) => state.adjusterReducer.selectedFacet);
  const selectedAnomalyId = useSelector((state) => state.adjusterReducer.selectedAnomalyId);
  const baseImage = useSelector((state) => state.adjusterReducer.baseImage);
  const facets = useSelector((state) => state.adjusterReducer.facets);
  const roofId = useSelector((state) => state.adjusterReducer.roofId);
  const roofPolygon = useSelector((state) => state.adjusterReducer.roofPolygon);
  const sidePanelWidth = useSelector((state) => state.adjusterReducer.sidePanelWidth);
  const currentTab = useSelector((state) => state.adjusterReducer.currentTab);
  const facetImages = useSelector((state) => state.adjusterReducer.facetImages);
  const loading = useSelector((state) => state.adjusterReducer.loading);
  const chartData = useSelector((state) => state.adjusterReducer.chartData);
  const coveragePercentage = useSelector((state) => state.adjusterReducer.coveragePercentage);
  const qcPanelType = useSelector((state) => state.adjusterReducer.qcPanelType);
  const QcClaimID = useSelector((state) => state.adjusterReducer.QcClaimID);
  const qcStartTime = useSelector((state) => state.adjusterReducer.qcStartTime);
  const qcEndTime = useSelector((state) => state.adjusterReducer.qcEndTime);
  const severity = useSelector((state) => state.adjusterReducer.severity);
  const toastAutoHideDuration = useSelector((state) => state.adjusterReducer.toastAutoHideDuration);
  const entitlements = useSelector((state) => state.entitleUserReducer.entitlements);
  const testSquare = useSelector((state) => state.adjusterReducer.testSquareData);
  const facetAnomaliesSubsetIds = useSelector((state) => state.adjusterReducer.facetAnomaliesSubset);
  const orderCompleted = useSelector((state) => state.adjusterReducer.orderCompleted);
  const facetBoxAnnotations = useSelector((state) => state.adjusterReducer.facetBoxAnnotations);
  const showSkydioMosaicImagePanel = useSelector((state) => state.adjusterReducer.showSkydioMosaicImagePanel);
  const selectedStructureId = useSelector((state) => state.adjusterReducer.selectedStructureId);
  const isAssessPhotoViewerEnabled = useSelector((state) => get(state.entitleUserReducer.featureFlags, ASSESS_PHOTO_VIEWER, false));
  const { structures = [] } = useSelector((state) => state.adjusterReducer.multiStructure);
  const isFacetRawImagesEnabled = useSelector((state) => get(state.entitleUserReducer.featureFlags, ENABLE_FACET_RAW_IMAGES, false));
  const orderStatus = useSelector((state) => state.adjusterReducer.orderStatus);
  const enableGroundAccessory = useSelector((state) => get(state.entitleUserReducer.featureFlags, GROUND_ACCESSORY, false));
  const isAssessLite = useSelector((state) => state.adjusterReducer.isAssessLite);
  const filteredGalleryImages = useSelector((state) => state.adjusterReducer.filteredGalleryImages);
  const galleryImages = useSelector((state) => state.adjusterReducer.galleryImages);
  const createdAnnotationToEdit = useSelector((state) => state.adjusterReducer.createdAnnotationToEdit);
  const enableAnnotationDescription = useSelector((state) => get(state.entitleUserReducer.featureFlags, ANNOTATION_DESC, false));
  const anomalyNote = useSelector((state) => state.adjusterReducer.anomalyNotes)[0];
  const selectedAnomalyIsUserAdded = useSelector(workflowPanelSelector.selectedAnomalyIsUserAddedSelector);
  const selectedAnomalyStatus = useSelector(workflowPanelSelector.selectedAnomalyStatusSelector);

  // Selector with derived value
  const facetTestSquareDisabledMap = useSelector((state) => state.adjusterReducer.facetTestSquareDisabledMap);
  const testSquareLocationApiFail = useSelector((state) => state.adjusterReducer.testSquareLocationApiFail);
  const updateAnomalyLocationApiFail = useSelector((state) => state.adjusterReducer.updateAnomalyLocationApiFail);
  const facetScanImageAssets = useSelector((state) => state.adjusterReducer.facetScanImageAssets);
  const authToken = useSelector((state) => state.adjusterReducer.accessToken);
  const isFacetTestsquareDisabled = facetTestSquareDisabledMap[selectedFacet];
  const selectedStructure = structures.find((structure) => structure.structureID === selectedStructureId);
  const isCaptured = get(selectedStructure, 'isCaptured', true);
  const isGroundCaptured = get(selectedStructure, 'isGroundCaptured', true);
  const isGalleryImages = loading[FETCH_ADJUSTER_DETAILS_GALLERY] || (galleryImages && galleryImages.length > 0 && filteredGalleryImages.length > 0);
  const selectedAnomalyIsConfirmed = selectedAnomalyStatus === ANOMALY_DECISIONS.CONFIRMED;
  const isDigitalDeliveryEnabled = useSelector((state) => get(
    state.entitleUserReducer.featureFlags,
    ASSESS_DIGITAL_DELIVERY,
    false,
  ));

  // utility hooks
  const theme = useTheme();
  const { t } = useTranslation();
  const styles = useStyles({ sidePanelWidth });
  const { id: orderId } = useParams();

  // actions
  const dispatch = useDispatch();
  const fetchAdjusterDetails = (payload) => dispatch(action.fetchAdjusterDetailsAction(payload));
  const fetchAdjusterDetailsTags = (payload) => dispatch(action.fetchAdjusterDetailsTagsAction(payload));
  const fetchAdjusterDetailsNotes = (payload) => dispatch(action.fetchAdjusterDetailsNotesAction(payload));
  const fetchFacetNotes = (payload) => dispatch(action.fetchFacetNotesAction(payload));
  const fetchRoofFacetsDetailsAndBaseImage = (payload) => dispatch(action.fetchRoofFacetsDetailsAndBaseImageAction(payload));
  const fetchFacetTestSquareNotes = (payload) => dispatch(action.fetchFacetTestSquareNotesAction(payload));
  const fetchRiskShot = (payload) => dispatch(action.fetchRiskShotAction(payload));
  const fetchAdjusterDetailsGallery = (payload) => dispatch(action.fetchAdjusterDetailsGalleryAction(payload));
  const fetchImagesIncludedInReport = (payload) => dispatch(action.fetchImagesIncludedInReportAction(payload));
  const setSelectedAnomalyView = (payload) => dispatch(action.setSelectedAnomalyViewAction(payload));
  const showCustomErrorToast = (payload) => dispatch(action.showErrorToastAction(payload));
  const setCurrentView = (payload) => dispatch(action.setCurrentViewAction(payload));
  const setSelectedFacet = (payload) => dispatch(action.setSelectedFacetAction(payload));
  const addUserAnomaly = (payload) => dispatch(action.addUserAnomalyAction(payload));
  const setOrderId = (payload) => dispatch(action.setOrderId(payload));
  const setUserAction = (payload) => dispatch(action.setUserAction(payload));
  const handleToastClose = () => dispatch(action.closeErrorToast());
  const fetchFacetImages = (payload) => dispatch(action.fetchFacetImagesAction(payload));
  const setAnomalyId = (payload) => dispatch(action.setAnomalyIdAction(payload));
  const fetchFacetImagesFailure = () => dispatch(action.fetchFacetImagesFailureAction());
  const completeReview = (payload) => dispatch(action.completeReviewAction(payload));
  const fetchQcPanelDetails = (payload) => dispatch(action.fetchQcPanelDetails(payload));
  const testSquareLocationUpdate = (payload) => dispatch(action.testSquareLocationUpdateAction(payload));
  const fetchAssessAppSettings = () => dispatch(action.fetchAssessAppSettingsAction());
  const goToHome = () => dispatch(push('/'));
  const fetchReportVersions = (payload) => dispatch(action.fetchReportVersionsAction(payload));
  const fetchFeaturePreference = () => dispatch(action.fetchFeaturePreferenceAction());
  const saveFacetAnnotation = (payload) => dispatch(action.saveFacetAnnotationAction(payload));
  const displayFacetAnnotation = (payload) => dispatch(action.displayFacetAnnotationAction(payload));
  const setFacetBoxAnnotations = (payload) => dispatch(action.setFacetBoxAnnotations(payload));
  const updateFacetAnnotation = (payload) => dispatch(action.updateFacetAnnotationAction(payload));
  const updateFacetAnnotationInclInReport = (payload) => dispatch(action.updateFacetAnnotationInclInReportAction(payload));
  const deleteFacetAnnotation = (payload) => dispatch(action.deleteFacetAnnotationAction(payload));
  const setSelectedStructureId = (payload) => dispatch(action.setSelectedStructureId(payload));
  const fetchMultiStructureDecisions = (payload) => dispatch(action.fetchMultiStructureDecisionsAction(payload));
  const setCurrentTabAction = (payload) => dispatch(action.setCurrentTabAction(payload));
  const clearSkydioMosaicImageUrl = () => dispatch(action.clearSkydioMosaicImageUrl());
  const fetchAnomalyImages = (payload) => dispatch(action.fetchAnomalyImagesAction(payload));
  const fetchAccessorySummary = (payload) => dispatch(action.fetchAccessorySummaryAction(payload));
  const clearAnnotationToEdit = () => dispatch(action.clearAnnotationToEdit());
  const resetAdjusterAction = () => dispatch(action.resetAdjusterAction());
  const saveFacetReportInclusion = (payload) => dispatch(action.saveFacetReviewDecisionAction(payload));
  const fetchAnomalyNoteDetails = (payload) => dispatch(action.getAnomalyNotesAction(payload));
  const toggleAnomalyIncludeInReport = (payload) => dispatch(action.toggleAnomalyIncludeInReportAction(payload));
  const setFacetScanImageAssets = (payload) => dispatch(action.setFacetScanImageAssetsAction(payload));
  const fetchToken = (payload) => dispatch(action.fetchAccessToken(payload));
  const refreshToken = (payload) => dispatch(action.updateAccessToken(payload));
  const refreshTokenCompleted = () => dispatch(action.updateAccessTokenCompletedAction());

  // local state
  const [anomalyAsset, setAnomalyAsset] = useState([]);
  const [boxAnnotationAsset, setBoxAnnotationAsset] = useState([]);
  const [markerAsset, setMarkerAsset] = useState([]);
  const [overheadImageAsset, setOverheadImageAsset] = useState({});
  const [hideAllButBaseImage, toggleHideAllButBaseImage] = useState(false);
  const [showRemovedAnomalies, toggleRemovedAnomalies] = useState(false);
  const [showPotentialAnomalies, togglePotentialAnomalies] = useState(true);
  const [enableAnomalyDrawing, toggleAnomalyDrawing] = useState(false);
  const [facetMask, setFacetMask] = useState([]);
  const [localCurrentView, setLocalCurrentView] = useState({});
  const [clickedAnnotation, setClickedAnnotation] = useState({});
  const [initialZoomLevel, setInitialZoomLevel] = useState();
  const [viewCornerCoordinates, setViewCornerCoordinates] = useState();
  const [testSquareAsset, setTestSquareAsset] = useState([]);
  const [testSquareInitialGeometry, setTestSquareInitialGeometry] = useState([]);
  const [showExportImageryPanel, setShowExportImageryPanel] = useState(false);
  const [showSharereportPanel, setShowSharereportPanel] = useState(false);
  const [showReportPanel, setShowReportPanel] = useState(false);
  const [isAnnotationSelected, setIsAnnotationSelected] = useState(false);
  const [interactionStarted, setInteractionStarted] = useState(true);
  const [isMapReady, setIsMapReady] = useState(false);
  const [facetPlaneConstants, setFacetPlaneConstants] = useState(undefined);
  const [showBoxDelete, setShowBoxDelete] = useState(false);
  const [isFacetClickAction, setIsFacetClickAction] = useState(false);
  const [bounds, setBounds] = useState();
  const [selectedArea, setSelectedArea] = useState({});
  const [annotationDescription, _setAnnotationDescription] = useState('');
  const [descriptionInclude, _setDescriptionInclude] = useState(false);
  const [annotationDescPanelData, annotationDescPanelDispatch] = useReducer(annotationDescPanelReducer, annotationDescPanelConstants.initialValue);
  const [showAnomalyDescription, setShowAnomalyDescription] = useState(false);
  const [anomalyCoordinates, setAnomalyCoordinates] = useState({});
  const [isAnomalyDescriptionPresent, setIsAnomalyDescriptionPresent] = useState(false);

  // ref's
  const wrapperRef = useRef();
  const selectedBoxAnnotation = useRef();
  const selectedAnomalyRef = useRef();
  const annotationDescriptionRef = useRef(annotationDescription);
  const descriptionIncludeRef = useRef(descriptionInclude);
  const isFacetTestsquareDisabledRef = useRef(isFacetTestsquareDisabled);
  const { elRef: mapViewerRef, size: mapSize } = useResizeObserver();
  const { elRef: galleryMapviewerRef, size: galleryMapSize } = useResizeObserver();

  logger('debug', 'Adjuster envName:', REACT_APP_ENV);

  // declarations
  let handleAnomalyPointClick;

  const showMapviewerError = () => showCustomErrorToast({ message: 'adjuster.mapViewerError', toastAutoHideDuration: null });

  const setAnnotationDescription = (desc) => {
    annotationDescriptionRef.current = desc;
    _setAnnotationDescription(desc);
  };

  const setDescriptionInclude = (val) => {
    descriptionIncludeRef.current = val;
    _setDescriptionInclude(val);
  };

  const handleMapClick = (e) => {
    if (e.state === 'start') {
      setInteractionStarted(true);
    }
    const eventType = get(e, 'event.type', false);
    const asset = get(e, 'event.data.asset', false);
    if ((eventType === 'click' && !asset) || e.state === 'abort') return;
    setClickedAnnotation(e);
  };

  const structureDependantAdjusterCalls = (structureId = '', authtoken) => {
    if (!isAssessLite && isCaptured) {
      fetchRoofFacetsDetailsAndBaseImage({ orderId, structureId, authtoken });
      if (authUtils.isEntitled(entitlements, FEATURE_ENTITLEMENTS.MANAGE_QC_DECISIONS)
        || authUtils.isEntitled(entitlements, FEATURE_ENTITLEMENTS.MANAGE_PENDING_QC_VIA_OPS_PRIME)) {
        clearSkydioMosaicImageUrl();
        fetchQcPanelDetails({ orderId, structureId });
      }
    }
    fetchAdjusterDetailsGallery({ orderId, structureId });
    if (enableGroundAccessory) {
      if (isEntitled(entitlements, FEATURE_ENTITLEMENTS.VIEW_DECISIONS)
        || isEntitled(entitlements, FEATURE_ENTITLEMENTS.MANAGE_DECISIONS)
        || isEntitled(entitlements, FEATURE_ENTITLEMENTS.VIEW_ASSESS_ALL_ORGANIZATION_ORDERS)) {
        fetchAccessorySummary({ orderId, selectedStructureId });
      }
    }

    if (isCaptured || isGroundCaptured) {
      fetchRiskShot({ orderId, structureId });
    }
  };

  useEffect(() => {
    if (sharedUtil.isSharedUrl()) return;
    const initAuthDetails = localStorage.getItem('token');
    const { access_token: initAuthToken } = JSON.parse(initAuthDetails);
    fetchToken(initAuthToken);
  }, []);

  useEffect(() => {
    if (sharedUtil.isSharedUrl()) return;
    const authDetails = localStorage.getItem('token');
    const { access_token: accessToken, expiry_time: expiryTimeStr } = JSON.parse(authDetails);
    // Parse expiry_time to Date object
    const expiryTime = new Date(expiryTimeStr);
    // Get current time
    const currentTime = new Date();
    // Calculate difference in milliseconds and subtract 2 minutes (120,000 ms)
    const checkTime = expiryTime.getTime() - currentTime.getTime() - 120000;
    let intervalId;

    const timeoutId = setTimeout(() => {
      intervalId = setInterval(() => {
        const newAuthDetails = JSON.parse(localStorage.getItem('token'));
        const newAccessToken = newAuthDetails.access_token;

        if (newAccessToken !== accessToken) {
          refreshToken(newAccessToken);
          clearInterval(intervalId);
        }
      }, 5000); // Check every 5 seconds
    }, checkTime);

    /* eslint-disable consistent-return */
    return () => {
      clearTimeout(timeoutId);
      if (intervalId) clearInterval(intervalId);
    };
  }, [authToken]);

  useEffect(() => {
    if (selectedStructureId) {
      setFacetMask([]);
      setSelectedFacet({ facetId: '', selectedAnomalyId: UNSET_ANOMALY_ID, testSquare: {} });
      setSelectedAnomalyView({});
      setFacetScanImageAssets([]);
      setClickedAnnotation({});
      structureDependantAdjusterCalls(selectedStructureId, authToken);
      setBounds();
      setLocalCurrentView({});
    }
  }, [selectedStructureId, authToken]);

  useEffect(() => {
    fetchAssessAppSettings();
    setOrderId(orderId);
    setUserAction(authUtils.getUser());
    fetchAdjusterDetails({ orderId });
    fetchAdjusterDetailsTags({ orderId });
    fetchFeaturePreference();
    if (authUtils.isEntitled(entitlements, FEATURE_ENTITLEMENTS.VIEW_NOTES)
      || authUtils.isEntitled(entitlements, FEATURE_ENTITLEMENTS.VIEW_ASSESS_ALL_ORGANIZATION_ORDERS)) {
      fetchAdjusterDetailsNotes(orderId);
      fetchFacetNotes(orderId);
    }
    if (authUtils.isEntitled(entitlements, FEATURE_ENTITLEMENTS.VIEW_REPORTS)) {
      fetchReportVersions(orderId);
      fetchImagesIncludedInReport(orderId);
    }

    if (window.ev_vp && window.ev_vp.toggleSidebarHide && hideNavbar) {
      window.ev_vp.toggleSidebarHide();
    } else if (window.ev_vp && window.ev_vp.toggleSidebarShow && !hideNavbar) {
      window.ev_vp.toggleSidebarShow();
    }

    return () => {
      resetAdjusterAction();
    };
  }, []);

  useEffect(() => {
    if (isNull(isAssessLite)) return;
    setSelectedStructureId('structure1');
    fetchMultiStructureDecisions({ orderId });
  }, [isAssessLite]);

  // pendo custom event - order visted
  useEffect(() => {
    if (orderStatus === claimStatus.READYFORREVIEW) {
      util.publishPendoEvent(ORDER_VISITED_PENDO_EVENT, { orderId });
    }
  }, [orderStatus]);

  const [interactionObject, setInteractionObject] = useState({});

  const anomalyFilterCheck = (anomaly, decision) => {
    let isCheck = false;
    try {
      const anomalyDecision = decision === 'removed' ? ANOMALY_DECISIONS.REMOVED : '';
      if (!isFacetTestsquareDisabled) {
        const anomalyIncludes = facetAnomaliesSubsetIds.includes(anomaly.data[0].properties.anomalyId);
        isCheck = !anomalyIncludes || (anomalyIncludes && anomaly.data[0].properties.status !== anomalyDecision);
      } else {
        isCheck = anomaly.data[0].properties.status !== anomalyDecision;
      }
      return isCheck;
    } catch (err) {
      setTimeout(() => {
        errorHandlerService(err, err.message || '', 'Adjuster');
        showCustomErrorToast({ message: 'adjuster.somethingWentWrong' });
      }, 0);
      return isCheck;
    }
  };

  const removedAnomaliesFilter = (anomaly) => {
    if (showRemovedAnomalies) return true;
    return anomalyFilterCheck(anomaly, 'removed');
  };

  const potentialAnomaliesFilter = (anomaly) => {
    if (showPotentialAnomalies) return true;
    return anomalyFilterCheck(anomaly, 'potential');
  };

  const getFacetTestSquareNotes = () => {
    try {
      const currentFacet = facets.find((facet) => facet.facetId === selectedFacet);
      const testSquareId = get(currentFacet, 'testsquare.testSquareId', '');
      if (testSquareId) {
        fetchFacetTestSquareNotes({ orderId, testSquareId });
      }
    } catch (err) {
      setTimeout(() => {
        errorHandlerService(err, err.message || '', 'Adjuster');
        showCustomErrorToast({ message: 'adjuster.somethingWentWrong' });
      }, 0);
    }
  };

  const resetBoxAnnotationPosition = (boxAnnotationId) => {
    const annotationAsset = boxAnnotationAsset.map((asset) => {
      const assetAnnotationId = get(asset, 'data[0].properties.annotationId', '');
      if (assetAnnotationId === boxAnnotationId) {
        const prevGeometries = cloneDeep(facetBoxAnnotations.filter((annotation) => annotation.annotationId === boxAnnotationId)[0]);
        return util.getMapviewerAsset({
          assetType: 'annotation',
          data: [{
            geometries: [prevGeometries.geometries[0]],
            properties: {
              annotationType: 'box',
              assetType: 'boxAnnotation',
              annotationId: boxAnnotationId,
            },
          }],
          parentAsset: facetScanImageAssets.length > 0 ? facetScanImageAssets[0] : null,
          style: {
            point: {
              fillColor: '#fff',
              strokeColor: '#2274F7',
              strokeWidth: 5,
            },
            polygon: {
              strokeColor: '#00A3FF',
              strokeWidth: 5,
              fillOpacity: 0,
            },
          },
        });
      }
      return asset;
    });
    setBoxAnnotationAsset(annotationAsset);
  };

  const resetTestSquareLocation = () => {
    setTestSquareAsset([
      {
        ...testSquareAsset[0],
        data: [{
          ...testSquareAsset[0].data[0],
          geometries: [
            {
              type: 'Polygon',
              coordinates: [testSquare.geometry.coordinates[0]],
            },
          ],
          properties: {
            ...testSquareAsset[0].data[0].properties,
            customId: uuidv4(),
          },
          style: {
            ...testSquareAsset[0].data[0].style,
            strokeOpacity: 0.5,
          },
        }],
      },
    ]);
  };

  const updateInteractionObject = (interaction, mapReadyParam = false) => {
    try {
      if (isEmpty(interaction) || typeof (interaction) !== 'object' || !get(interaction, 'type', null)) {
        throw new Error('Interaction object is not set properly');
      }
      let newInteractionObj = {
        enable: mapReadyParam || isMapReady,
      };
      if (interaction.enable === false) {
        newInteractionObj = {
          ...interactionObject,
          enable: false,
        };
      } else if (interaction.type === 'tracking') {
        newInteractionObj = {
          ...newInteractionObj,
          type: 'tracking',
          mode: { hover: true, click: true },
          handler: handleMapClick,
        };
      } else {
        newInteractionObj = {
          ...interaction,
          ...newInteractionObj,
        };
      }
      setInteractionObject(newInteractionObj);
    } catch (err) {
      setTimeout(() => {
        errorHandlerService(err, err.message || '', 'Adjuster');
        showCustomErrorToast({ message: 'adjuster.somethingWentWrong' });
      }, 0);
    }
  };

  const showExportImagery = (isEntitled(entitlements, FEATURE_ENTITLEMENTS.MANAGE_REPORTS)
    || isEntitled(entitlements, FEATURE_ENTITLEMENTS.EXPORT_IMAGERY_INTERNAL));

  const exportImagery = () => {
    setShowExportImageryPanel(true);
  };

  const shareReport = () => {
    setShowSharereportPanel(true);
  };

  const generateReportMenuClick = () => {
    setShowReportPanel(true);
  };

  // derived values
  const MAPVIEWER_MAX_ZOOM = ROOF_MAX_ZOOM;
  const MAPVIEWER_MIN_ZOOM = useSelector((state) => {
    const groups = get(state, 'adjusterReducer.assessAppSettings.groups', []);
    const zoomGroup = groups.find((group) => group.groupName === 'ZOOM_LEVEL');
    return parseInt(get(zoomGroup, 'settings.MIN_ZOOM_LEVEL', DEFAULT_MIN_ZOOM), 10);
  });
  const testSquareElevation = useSelector((state) => {
    const groups = get(state, 'adjusterReducer.assessAppSettings.groups', []);
    const assessFeatureGroup = groups.find((group) => group.groupName === 'ASSESS_FEATURE_FLAGS');
    return get(assessFeatureGroup, 'settings.TEST_SQUARE_ELEVATION', true);
  });
  const mapViewerCriticalAssetsReady = !isEmpty(currentView) && !isEmpty(overheadImageAsset);
  const filteredAnomalies = anomalies
    .filter(potentialAnomaliesFilter)
    .filter(removedAnomaliesFilter);
  const hideWorkflowPanel = isAssessLite
    || localCurrentView.zoom > ROOF_MAX_ZOOM_SHOW_ANOMALIES
    || hideAllButBaseImage
    || currentTab === TABS.GALLERY
    || !isCaptured;
  const zoomInDisabled = localCurrentView.zoom >= MAPVIEWER_MAX_ZOOM;
  const zoomOutDisabled = localCurrentView.zoom <= Math.max(MAPVIEWER_MIN_ZOOM, parseFloat(initialZoomLevel));

  const resetInteractionToTracking = () => {
    updateInteractionObject({
      type: 'tracking',
    });
    setShowBoxDelete(false);
  };

  useEffect(() => {
    const currentFacet = facets.find((facet) => facet.facetId === selectedFacet);
    const planeConstants = get(currentFacet, 'properties.plane', undefined);
    if (!isAssessLite) {
      if (currentTab === TABS.GALLERY) {
        setIsMapReady(false);
        if (interactionObject.type === 'editing') {
          const type = get(interactionObject, 'annotation.properties.assetType', '');
          switch (type) {
            case 'testSquare':
              resetTestSquareLocation();
              break;
            case 'boxAnnotation': {
              const boxAnnotationId = get(interactionObject, 'annotation.properties.annotationId', '');
              resetBoxAnnotationPosition(boxAnnotationId);
              break;
            }
            default: break;
          }
        }
        setFacetPlaneConstants(undefined);
      } else {
        setFacetPlaneConstants(planeConstants);
      }
    }
    resetInteractionToTracking();
  }, [currentTab]);

  useEffect(() => {
    if (!isEmpty(selectedFacet)
      && (authUtils.isEntitled(entitlements, FEATURE_ENTITLEMENTS.VIEW_NOTES)
        || authUtils.isEntitled(entitlements, FEATURE_ENTITLEMENTS.VIEW_ASSESS_ALL_ORGANIZATION_ORDERS))) {
      getFacetTestSquareNotes();
      displayFacetAnnotation({ orderId, facetId: selectedFacet });
    } else setFacetBoxAnnotations([]);
    setIsAnnotationSelected(false);
    setSelectedArea({});
  }, [selectedFacet]);

  const getMarkerFromAsset = (annotationId) => {
    const markers = get(markerAsset, '0.data', []);
    return markers.find((tMarker) => tMarker.id === annotationId);
  };

  const hideMarker = (annotationId) => {
    const marker = getMarkerFromAsset(annotationId);
    if (marker && marker.markerReference) {
      marker.markerReference.getElement().style.display = 'none';
    }
  };

  const showMarker = (annotationId) => {
    const marker = getMarkerFromAsset(annotationId);
    if (marker && marker.markerReference) {
      marker.markerReference.getElement().style.display = 'block';
    }
  };

  const addMarker = ({ id: annotationId }, coordinates) => {
    const el = document.createElement('div');
    el.setAttribute('id', annotationId);
    el.className = annotationId;
    const width = 20;
    const height = 25;
    el.style.backgroundImage = `url(${notesIcon})`;
    el.style.width = `${width}px`;
    el.style.height = `${height}px`;
    el.style.backgroundSize = '100%';
    const marker = {
      id: annotationId,
      markerOptions: {
        element: el,
        offset: [12, 10],
      },
      coordinates,
    };
    return marker;
  };

  useEffect(() => {
    // on box edit, if includeInReport is toggle'd stop re-creating box annotation assets
    if (interactionObject.type === 'editing'
      && facetBoxAnnotations.length === boxAnnotationAsset.length
      && util.getIntersection(
        facetBoxAnnotations,
        boxAnnotationAsset, 'annotationId', 'data[0].properties.annotationId',
      ).length === facetBoxAnnotations.length) {
      return;
    }
    const markerAssetData = [];
    const annotationAsset = facetBoxAnnotations.map((annotationObject) => {
      const boxAnnotationGeometries = cloneDeep(get(annotationObject, 'geometries', []));
      const data = {
        id: annotationObject.annotationId,
        geometries: boxAnnotationGeometries,
        properties: {
          annotationType: 'box',
          assetType: 'boxAnnotation',
          annotationId: annotationObject.annotationId,
          comment: annotationObject.comment,
          includeInReport: annotationObject.includeInReport,
        },
      };
      const mapviewerAsset = util.getMapviewerAsset({
        assetType: 'annotation',
        uuid: annotationObject.annotationId,
        data: [data],
        parentAsset: facetScanImageAssets.length > 0 ? facetScanImageAssets[0] : null,
        style: {
          point: {
            fillColor: '#fff',
            strokeColor: '#2274F7',
            strokeWidth: 5,
          },
          polygon: {
            strokeColor: '#00A3FF',
            strokeWidth: 5,
            fillOpacity: 0,
          },
        },
      }, showMapviewerError);
      if (!isEmpty(get(annotationObject, 'comment'))
        && enableAnnotationDescription
      ) {
        let marker = getMarkerFromAsset(annotationObject.annotationId);
        const marketCoord = util.getBoxAnnotationDescIconCoordinates(data, facetScanImageAssets[0]);
        if (marker && marker.markerReference) {
          marker.markerReference.setLngLat(marketCoord);
        } else {
          marker = addMarker(data, marketCoord);
        }
        markerAssetData.push(marker);
      }
      return mapviewerAsset;
    });
    const newMarkerAsset = util.getMapviewerAsset({
      assetType: 'marker',
      uuid: 'assess-marker',
      data: markerAssetData,
    }, showMapviewerError);
    setMarkerAsset([newMarkerAsset]);
    setBoxAnnotationAsset(annotationAsset);
    resetInteractionToTracking();
    setClickedAnnotation({});
  }, [facetBoxAnnotations]);

  const setFacetImages = (selectedFacetImages, authtoken) => {
    setFacetScanImageAssets(
      selectedFacetImages.map((image) => util.getMapviewerAsset({
        assetType: 'iwimage',
        tileUrls: [
          `${ASSESS_IMAGES_API_ENDPOINT}/${image.image_urn}/tiles/{z}/{x}/{y}?format=IMAGE_FORMAT_JPEG_PNG&`
          + `${sharedUtil.getAuthParam(authtoken)}`,
        ],
        metadata: image,
        parentAsset: overheadImageAsset,
      }, showMapviewerError)),
    );
  };

  const setFacetAssets = (facetId, authtoken) => {
    const selectedFacetImage = facetImages.find((facet) => facet.facetId === facetId);
    const facetImagesData = get(selectedFacetImage, 'data', []);

    const isFacetScanImage = get(selectedFacetImage, 'isFacetScanImages', false);
    if (isFacetScanImage && !isEmpty(facetImagesData)) {
      setFacetImages(facetImagesData, authtoken);
    } else if (!isEmpty(facetImagesData) && overheadImageAsset) {
      // Pick only first mosaic if there are multiple mosaics available.
      setFacetImages(facetImagesData.slice(0, 1), authtoken);
    } else if (selectedFacet && loading.facetImages[selectedFacet] === false) {
      setFacetScanImageAssets([]);
      setTimeout(() => {
        fetchFacetImagesFailure();
      }, 0);
    }
  };

  const clickedOnRoof = (lonLat) => {
    let isClicked = false;
    if (lonLat) {
      if (Array.isArray(roofPolygon)) {
        isClicked = roofPolygon
          .map((roof) => util.polygonContainsPoint(roof, [lonLat.lon, lonLat.lat]))
          .some((elem) => elem === true);
      }
    } else { isClicked = true; }
    return isClicked;
  };

  const getAnomalyStyleBackground = (anomaly) => {
    let style = opportunityImg;
    try {
      const anomalyId = get(anomaly, 'data[0].properties.anomalyId');
      const status = get(anomaly, 'data[0].properties.status');
      const userAddedAnomaly = get(anomaly, 'data[0].properties.isUserAdded', false);
      if (!(isFacetTestsquareDisabled || facetAnomaliesSubsetIds.includes(anomalyId))) {
        if (userAddedAnomaly) {
          style = disabledUserDefinedImg;
        } else {
          switch (status) {
            case 'CONFIRMED':
              style = disabledValidatedImg;
              break;
            case 'HIDDEN':
              style = hiddenImg;
              break;
            case 'REMOVED':
              style = hiddenImg;
              break;
            case 'UNCONFIRMED':
              style = disabledOpportunityImg;
              break;
            default:
              style = disabledOpportunityImg;
              break;
          }
        }
      } else if (anomalyId === selectedAnomalyId) {
        style = userAddedAnomaly ? userAnomalySelectedImg : selectedImg;
      } else if (userAddedAnomaly) {
        style = userAnomalyImg;
      } else {
        switch (status) {
          case 'CONFIRMED':
            style = validatedImg;
            break;
          case 'HIDDEN':
            style = hiddenImg;
            break;
          case 'REMOVED':
            style = hiddenImg;
            break;
          case 'UNCONFIRMED':
            style = opportunityImg;
            break;
          default:
            style = opportunityImg;
            break;
        }
      }
      return style;
    } catch (err) {
      setTimeout(() => {
        errorHandlerService(err, err.message || '', 'Adjuster');
        showCustomErrorToast({ message: 'adjuster.somethingWentWrong' });
      }, 0);
      return style;
    }
  };

  const getAnomalyAsset = () => {
    let assets = [];
    try {
      const selectedFacetImages = facetImages.find((x) => x.facetId === selectedFacet);
      const isTiles = get(selectedFacetImages, 'isFacetScanImages', false);
      const selectedFacetFilteredAnomalies = filteredAnomalies.filter(
        (x) => x.data[0].properties.facetId === selectedFacet,
      );
      const facetScanImageAssetsLoaded = (facetScanImageAssets.length === selectedFacetImages.data.length);
      const selectedFacetImage = facetImages.find((facet) => facet.facetId === selectedFacet);
      const facetMosaicAssetLoaded = facetScanImageAssets.length === 1
        && get(selectedFacetImage, 'data.0.image_urn', 'lhs') === get(facetScanImageAssets, '0.metadata.image_urn', 'rhs');
      if (isTiles && facetScanImageAssetsLoaded) {
        const groupedAnomaliesObj = groupBy(selectedFacetFilteredAnomalies, (x) => get(x, 'data[0].properties.imageUrn'));
        assets = Object.keys(groupedAnomaliesObj).map((imageUrn) => {
          const tileAsset = facetScanImageAssets.find(
            (asset) => asset.metadata.image_urn === imageUrn,
          );
          return util.getMapviewerAsset({
            assetType: 'annotation',
            uuid: imageUrn,
            beforeAsset: !isFacetTestsquareDisabled ? testSquareAsset : [],
            parentAsset: tileAsset, // corresponding tile
            data: [
              ...groupedAnomaliesObj[imageUrn].map((x) => {
                const coord = get(x, 'data[0].geometries[0].coordinates[0]', []);
                const zVal = coord.reduce((z, xyz) => z + parseFloat(xyz[2] || 0), 0) / coord.length;
                return {
                  id: get(x, 'data[0].properties.anomalyId'),
                  geometries: [
                    {
                      type: 'Point',
                      coordinates: [
                        ...get(
                          util.getCentroidForGeometry(get(x, 'data[0].geometries[0]', [])),
                          'geometry.coordinates',
                          [],
                        ),
                        zVal,
                      ],
                    },
                  ],
                  properties: {
                    ...x.data[0].properties,
                    annotationType: 'label',
                  },
                  style: {
                    text: {
                      backgroundImage: getAnomalyStyleBackground(x),
                    },
                  },
                };
              }),
            ],
          }, showMapviewerError);
        }).filter((asset) => asset != null);
      } else if (facetMosaicAssetLoaded) {
        const asset = util.getMapviewerAsset({
          assetType: 'annotation',
          uuid: selectedFacet,
          beforeAsset: !isFacetTestsquareDisabled ? testSquareAsset : [],
          parentAsset: facetScanImageAssets[0], // mosaic
          data: [
            ...selectedFacetFilteredAnomalies.map((x) => {
              const coord = get(x, 'data[0].geometries[0].coordinates[0]', []);
              const zVal = coord.reduce((z, xyz) => z + parseFloat(xyz[2] || 0), 0) / coord.length;
              return {
                id: get(x, 'data[0].properties.anomalyId'),
                geometries: [
                  {
                    type: 'Point',
                    coordinates: [
                      ...get(
                        util.getCentroidForGeometry(get(x, 'data[0].geometries[0]', [])),
                        'geometry.coordinates',
                        [],
                      ),
                      zVal],
                  },
                ],
                properties: {
                  ...x.data[0].properties,
                  annotationType: 'label',
                },
                style: {
                  text: {
                    backgroundImage: getAnomalyStyleBackground(x),
                  },
                },
              };
            }),
          ],
        }, showMapviewerError);
        if (asset) assets = [asset];
      }
      return assets;
    } catch (err) {
      setTimeout(() => {
        errorHandlerService(err, err.message || '', 'Adjuster');
        showCustomErrorToast({ message: 'adjuster.somethingWentWrong' });
      }, 0);
      return assets;
    }
  };

  const setAnomalyAssetOnZoomChange = (newZoomLevel) => {
    try {
      const isEmptyAnomaly = isEmpty(anomalyAsset);
      if (selectedFacet) {
        if (newZoomLevel <= ROOF_MAX_ZOOM_SHOW_ANOMALIES && isEmptyAnomaly) {
          const selectedFacetImages = facetImages.find((x) => x.facetId === selectedFacet);
          if (!isEmpty(selectedFacetImages)) {
            setAnomalyAsset(getAnomalyAsset());
          }
        } else if (newZoomLevel > ROOF_MAX_ZOOM_SHOW_ANOMALIES && !isEmptyAnomaly) {
          setAnomalyAsset([]);
        }
      }
    } catch (err) {
      setTimeout(() => {
        errorHandlerService(err, err.message || '', 'Adjuster');
        showCustomErrorToast({ message: 'adjuster.somethingWentWrong' });
      }, 0);
    }
  };

  const drawingAsset = util.getMapviewerAsset({
    assetType: 'annotation',
    data: [],
    style: {},
  }, showMapviewerError) || [];

  const zoomWithEaseTo = (zoom) => {
    mapViewerRef.current.easeTo({
      zoom: Math.min(Math.max(zoom, MAPVIEWER_MIN_ZOOM), MAPVIEWER_MAX_ZOOM),
      duration: 1200,
      essential: true,
      animate: true,
    });
  };

  const zoomInClick = () => {
    const newZoomLevel = localCurrentView.zoom + 1;
    setAnomalyAssetOnZoomChange(newZoomLevel);
    switch (true) {
      case newZoomLevel <= MAPVIEWER_MAX_ZOOM:
        zoomWithEaseTo(localCurrentView.zoom + 1);
        break;
      case newZoomLevel > MAPVIEWER_MAX_ZOOM: {
        if (localCurrentView.zoom !== MAPVIEWER_MAX_ZOOM) {
          zoomWithEaseTo(MAPVIEWER_MAX_ZOOM);
          break;
        }
        break;
      }
      default:
    }
  };

  const displayTestSquare = (showTS) => {
    if (showTS) {
      try {
        const coordinates = testSquareElevation ? testSquare.geometry.coordinates[0] : testSquare.geometry.coordinates[0].map((xyz) => [xyz[0], xyz[1]]);
        const asset = util.getMapviewerAsset({
          assetType: 'annotation',
          parentAsset: facetScanImageAssets[0],
          data: [
            {
              geometries: [
                {
                  type: 'Polygon',
                  coordinates: !isEmpty(testSquare) ? [coordinates] : [],
                },
              ],
              properties: {
                annotationType: 'box',
                cursorStyle: 'pointer',
                assetType: 'testSquare',
                testSquareId: testSquare.testSquareId,
              },
              style: {
                strokeColor: '#FFFFFF',
                strokeWidth: 7,
                strokeOpacity: 0.3,
                fillOpacity: 0,
              },
            },
          ],
        }, showMapviewerError);
        setTestSquareAsset(asset ? [asset] : []);
      } catch (err) {
        setTimeout(() => {
          errorHandlerService(err, err.message || '', 'Adjuster');
          showCustomErrorToast({ message: 'adjuster.somethingWentWrong' });
        }, 0);
      }
    } else {
      setTestSquareAsset([]);
    }
  };

  const zoomOutClick = () => {
    const newZoomLevel = localCurrentView.zoom - 1;
    setAnomalyAssetOnZoomChange(newZoomLevel);
    try {
      if (parseFloat(newZoomLevel.toFixed(4)) <= parseFloat(initialZoomLevel.toFixed(4))) {
        zoomWithEaseTo(Math.max(parseFloat(initialZoomLevel), MAPVIEWER_MIN_ZOOM));
      } else {
        zoomWithEaseTo(localCurrentView.zoom - 1);
      }
    } catch (err) {
      setTimeout(() => {
        errorHandlerService(err, err.message || '', 'Adjuster');
        showCustomErrorToast({ message: 'adjuster.somethingWentWrong' });
      }, 0);
    }
  };

  const switchAnomaly = (anomalyId) => {
    try {
      const selectedAnomaly = filteredAnomalies.find(
        (anomaly) => anomaly.data[0].properties.anomalyId === anomalyId,
      );
      if (isEmpty(selectedAnomaly)) return;
      const centroid = util.getCentroidForGeometry(get(selectedAnomaly, 'data[0].geometries[0]', {}));
      const fallBackCoords = get(localCurrentView, 'lonLat', get(currentView, 'lonLat', {}));
      // start calculation of whether next view is within bounds
      if (isEmpty(viewCornerCoordinates)) return;
      const viewPolygon = util.getPolygonFromCorners(viewCornerCoordinates);
      const viewBoundsPolygon = util.getPolygonFromCorners(bounds);
      const viewCenter = turf.centroid(viewPolygon);
      const from = turf.point([
        viewCenter.geometry.coordinates[0],
        viewCenter.geometry.coordinates[1],
      ]);
      const to = turf.point([centroid.geometry.coordinates[0], centroid.geometry.coordinates[1]]);
      const bearing = turf.rhumbBearing(from, to);
      const distance = turf.rhumbDistance(from, to);
      const nextViewPolygon = turf.transformTranslate(viewPolygon, distance, bearing);
      const nextViewIsWithinBounds = turf.booleanWithin(nextViewPolygon, viewBoundsPolygon);
      // end calculation of whether next view is within bounds
      if (anomalyId) {
        setCurrentView({
          ...localCurrentView,
          // if next view isn't within bounds, zoom an arbitrary amount to avoid illegal view positions
          zoom: nextViewIsWithinBounds ? localCurrentView.zoom : localCurrentView.zoom + 1,
          lonLat: {
            lon: get(centroid, 'geometry.coordinates[0]', fallBackCoords.lon),
            lat: get(centroid, 'geometry.coordinates[1]', fallBackCoords.lat),
            elevation: get(
              selectedAnomaly,
              'data[0].geometries[0].coordinates[0][0][2]',
              fallBackCoords.elevation,
            ),
          },
        });
      }
    } catch (err) {
      setTimeout(() => {
        errorHandlerService(err, err.message || '', 'Adjuster');
        showCustomErrorToast({ message: 'adjuster.somethingWentWrong' });
      }, 0);
    }
  };

  const initiateAnomalyDrawing = (anomalyDrawing, drawBoxObject = {}) => {
    try {
      if (anomalyDrawing && isEmpty(drawBoxObject)) {
        updateInteractionObject({
          type: 'drawPoint',
          handler: handleAnomalyPointClick,
          asset: drawingAsset,
        });
      } else if (!isEmpty(drawBoxObject)) {
        updateInteractionObject(drawBoxObject);
      } else {
        resetInteractionToTracking();
      }
      setInteractionStarted(false);
      toggleAnomalyDrawing(anomalyDrawing);
      if (isAnnotationSelected) setIsAnnotationSelected(false);
      const updatedFacetMask = cloneDeep(facetMask[0]);
      updatedFacetMask.data[0].properties.cursorStyle = anomalyDrawing ? 'no-drop' : 'pointer';
      setFacetMask([updatedFacetMask]);
      if (!isEmpty(testSquareAsset)) {
        setTestSquareAsset([{
          ...testSquareAsset[0],
          data: [
            {
              ...get(testSquareAsset[0], 'data.0', {}),
              properties: {
                ...get(testSquareAsset[0], 'data.0.properties', {}),
                cursorStyle: anomalyDrawing ? 'crosshair' : 'pointer',
                customCursor: anomalyDrawing && styles.addAnomalies,
              },
            },
          ],
        }]);
        setAnomalyAsset([{
          ...anomalyAsset[0],
          data: anomalyAsset[0].data.map((asset) => ({
            ...asset,
            properties: {
              ...asset.properties,
              cursorStyle: anomalyDrawing && !facetAnomaliesSubsetIds.includes(asset.properties.anomalyId) ? 'no-drop' : '',
            },
          })),
        }]);
      }
    } catch (err) {
      setTimeout(() => {
        errorHandlerService(err, err.message || '', 'Adjuster');
        showCustomErrorToast({ message: 'adjuster.somethingWentWrong' });
      }, 0);
    }
  };

  const handleMoveTestSquare = (e) => {
    try {
      if (!e.annotation) {
        return;
      }
      const selectedFacetObj = facetPolygons.data.find(
        (facet) => get(facet, 'properties.facetId') === selectedFacet,
      );
      const { geometries } = e.annotation;
      let planeEquationCorrectedGeometry;
      if (e.state === 'completed' || (e.state === 'in progress' && e.dragEnd)) {
        const planeConstants = get(testSquare, 'properties.plane', false);
        if (planeConstants) {
          const {
            a, b, c, d,
          } = planeConstants;
          let coordinates = get(geometries, '0.coordinates.0', []);
          coordinates = coordinates.map((xyz) => {
            const z = (-(a * xyz[0] + b * xyz[1] + d)) / c;
            return [xyz[0], xyz[1], z];
          });
          planeEquationCorrectedGeometry = [{ type: 'Polygon', coordinates: [coordinates] }];
        } else {
          planeEquationCorrectedGeometry = geometries;
        }
      }
      if (e.state === 'completed') {
        setTestSquareAsset([
          {
            ...testSquareAsset[0],
            data: [{
              ...testSquareAsset[0].data[0],
              geometries: testSquareElevation ? planeEquationCorrectedGeometry : geometries,
              style: {
                ...testSquareAsset[0].data[0].style,
                strokeOpacity: 0.3,
              },
            }],
          },
        ]);
        resetInteractionToTracking();
        const geometry = planeEquationCorrectedGeometry[0];
        const { testSquareId } = e.annotation.properties;
        if (!isEqual(testSquareInitialGeometry, geometry)) {
          testSquareLocationUpdate({
            orderId, facetId: selectedFacet, testSquareId, data: { geometry },
          });
        }
      }
      if ((e.state === 'in progress' && e.dragEnd)) {
        const geometry = planeEquationCorrectedGeometry[0];
        const newGeometries = testSquareElevation ? planeEquationCorrectedGeometry : geometries;
        const facetGeometry = get(selectedFacetObj, 'geometries[0]', {});
        const isTestSquareCompletelyOutsideFacet = util.isAnnotationCompletelyOutside(geometry, facetGeometry);
        if (isTestSquareCompletelyOutsideFacet) {
          resetTestSquareLocation();
        } else {
          setTestSquareAsset([
            {
              ...testSquareAsset[0],
              data: [{
                ...testSquareAsset[0].data[0],
                geometries: newGeometries,
                properties: {
                  ...testSquareAsset[0].data[0].properties,
                  customId: uuidv4(),
                },
                style: {
                  ...testSquareAsset[0].data[0].style,
                  strokeOpacity: 0.5,
                },
              }],
            },
          ]);
        }
      }
    } catch (err) {
      setTimeout(() => {
        errorHandlerService(err, err.message || '', 'Adjuster');
        showCustomErrorToast({ message: 'adjuster.somethingWentWrong' });
      }, 0);
    }
  };

  const onMapViewerError = (error, errorLocation) => {
    logger('error', `Error occurred in ${errorLocation}: `, get(error, 'message', error));
    setTimeout(() => showCustomErrorToast({ message: 'adjuster.mapViewerError', toastAutoHideDuration: null }), 0);
  };

  const setTestSquareHoverStyle = (onHover) => {
    if (!isEmpty(testSquareAsset)) {
      setTestSquareAsset([{
        ...testSquareAsset[0],
        data: [
          {
            ...get(testSquareAsset[0], 'data.0', {}),
            style: {
              strokeColor: '#FFFFFF',
              strokeWidth: 7,
              strokeOpacity: onHover ? 0.4 : 0.3,
              fillOpacity: 0,
            },
          },
        ],
      }]);
    }
  };

  handleAnomalyPointClick = (e) => {
    try {
      if (e.state === 'start') {
        setInteractionStarted(true);
      }
      if (e.state === INTERACTION_STATES.completed) {
        // send enable:false for drawPoint interaction to update the interactionObject,
        // since the interaction for type:drawPoint is completed on a click on the map.
        updateInteractionObject({
          type: 'drawPoint',
          enable: false,
        });
        const asset = get(e, 'event.data.asset', false);
        const geometries = get(e, 'geometry.geometries', false);

        if (asset && asset.assetType === 'iwimage' && geometries) {
          initiateAnomalyDrawing(false);
          // disable adding anomalies outside of facet bounds
          const clickPos = [get(e, 'event.data.lonLat.lon'), get(e, 'event.data.lonLat.lat')];
          const selectedFacetPolygon = facetPolygons.data.find(
            (facet) => get(facet, 'properties.facetId') === selectedFacet,
          );
          const selectedFacetCoordinates = get(
            selectedFacetPolygon,
            'geometries[0].coordinates',
          );
          const testSquareCoordinates = get(
            testSquareAsset[0],
            'data[0].geometries[0].coordinates',
            [],
          );
          const isInsideSelectedFacet = util.polygonContainsPoint(selectedFacetCoordinates, clickPos);
          const isInsideTestSquare = !isEmpty(testSquareCoordinates) ? util.polygonContainsPoint(testSquareCoordinates, clickPos) : true;
          if (!isInsideSelectedFacet) {
            setTimeout(() => showCustomErrorToast({ message: 'adjuster.outsideOfBounds' }), 0);
            setTimeout(() => initiateAnomalyDrawing(true), 0);
          } else if (!isFacetTestsquareDisabledRef.current && !isInsideTestSquare) {
            setTimeout(() => showCustomErrorToast({ message: 'adjuster.outsideOfTestsquare' }), 0);
            setTimeout(() => initiateAnomalyDrawing(true), 0);
          } else {
            // require high resoulution image for the anomaly on the given location
            const filteredImages = facetImages.find((facet) => facet.facetId === selectedFacet);
            if (
              !get(filteredImages, 'data', []).some(
                (image) => image.image_urn === asset.metadata.image_urn,
              )
            ) {
              setTimeout(() => showCustomErrorToast({ message: 'addAnomaly.outsideFacetImage' }), 0);
              setTimeout(() => initiateAnomalyDrawing(true), 0);
            } else {
              const geometry = geometries[0];
              const imageUrn = asset.metadata.image_urn;
              const convertedAnomaly = util.convertCoordinatesToXYs(asset, geometry);

              if (!isEmpty(convertedAnomaly)) {
                addUserAnomaly({
                  long: geometry.coordinates[0],
                  lat: geometry.coordinates[1],
                  structureID: selectedStructureId,
                  orderId,
                  anomalyData: {
                    decision: 'CONFIRMED',
                    imageURN: imageUrn,
                    facetAliasName: facets.find((facet) => facet.facetId === selectedFacet)
                      .facetAliasName,
                    facetId: selectedFacet,
                    geometry: {
                      coordinates: [convertedAnomaly.anomalyCoords],
                      type: 'Polygon',
                    },
                    isUserAdded: true,
                    properties: {
                      pixel_coords: [convertedAnomaly.anomalyXYs.map((anomalyXY) => [anomalyXY.x, anomalyXY.y])],
                    },
                    roofId,
                  },
                  decision: facets.find((facet) => facet.facetId === selectedFacet).decision,
                  includeInReport: facets.find((facet) => facet.facetId === selectedFacet).includeInReport,
                });
              }
              resetInteractionToTracking();
            }
          }
        }
      }
    } catch (err) {
      setTimeout(() => {
        errorHandlerService(err, err.message || '', 'Adjuster');
        showCustomErrorToast({ message: 'adjuster.somethingWentWrong' });
      }, 0);
    }
  };

  const handleCreateBoxAnnotation = (e) => {
    try {
      if (e.state === INTERACTION_STATES.completed) {
        const annotationGeometry = get(e, 'geometry.geometries[0]', {});
        const asset = get(e, 'event.data.asset', false);
        const selectedFacetObj = facetPolygons.data.find(
          (facet) => get(facet, 'properties.facetId') === selectedFacet,
        );
        if (!isEmpty(annotationGeometry)) {
          const facetGeometry = get(selectedFacetObj, 'geometries[0]', {});
          const isBoxCompletelyOutsideFacet = util.isAnnotationCompletelyOutside(annotationGeometry, facetGeometry);
          if (isBoxCompletelyOutsideFacet) {
            setTimeout(() => showCustomErrorToast({ message: 'adjuster.outsideOfBounds' }), 0);
          } else {
            const boxAnnotationGeometries = [e.geometry.geometries[0]];
            const annotationAsset = !isEmpty(boxAnnotationAsset) ? [...boxAnnotationAsset] : [];
            annotationAsset.push(util.getMapviewerAsset({
              assetType: 'annotation',
              data: [{
                geometries: boxAnnotationGeometries,
              }],
              parentAsset: facetScanImageAssets.length > 0 ? facetScanImageAssets[0] : null,
              style: {
                polygon: {
                  strokeColor: '#00A3FF',
                  strokeWidth: 5,
                  fillOpacity: 0,
                },
              },
            }, showMapviewerError));
            setBoxAnnotationAsset(annotationAsset);
            // Forming pixel coordinates from coordinates and saving them.
            const boxAnnotationCoordinates = get(boxAnnotationGeometries, '0.coordinates.0', []);
            const pixelCoords = util.getPolygonPixelCoords(boxAnnotationCoordinates, asset);
            saveFacetAnnotation({
              orderId,
              facetId: selectedFacet,
              data: {
                annotations: [
                  {
                    geometry: {
                      type: 'Polygon',
                      coordinates: [boxAnnotationCoordinates],
                    },
                    properties: {
                      pixelCoords,
                    },
                    comment: '',
                    includeInReport: true,
                  },
                ],
              },
            });
          }
        }
        setIsAnnotationSelected(false);
        resetInteractionToTracking();
      }
    } catch (err) {
      setTimeout(() => {
        errorHandlerService(err, err.message || '', 'Adjuster');
        showCustomErrorToast({ message: 'adjuster.somethingWentWrong' });
      }, 0);
    }
  };

  const handleBoxAnnotationToolbarClick = () => {
    try {
      const drawBoxInteractionObj = {
        type: 'drawBox',
        handler: handleCreateBoxAnnotation,
        asset: {
          assetType: 'annotation',
          data: [],
          style: {
            polygon: {
              strokeColor: '#00A3FF',
              strokeWidth: 5,
              fillOpacity: 0,
            },
          },
        },
      };
      if (!isAnnotationSelected && enableAnomalyDrawing) {
        initiateAnomalyDrawing(!enableAnomalyDrawing, drawBoxInteractionObj);
        setInteractionStarted(true);
      } else {
        updateInteractionObject(drawBoxInteractionObj);
      }
      setIsAnnotationSelected(!isAnnotationSelected);
    } catch (err) {
      setTimeout(() => {
        errorHandlerService(err, err.message || '', 'Adjuster');
        showCustomErrorToast({ message: 'adjuster.somethingWentWrong' });
      }, 0);
    }
  };

  const handleAnnotationDelete = () => {
    const selectedAnnotation = get(interactionObject, 'annotation', '');
    const annotationType = get(selectedAnnotation, 'properties.assetType', '');
    const annotationId = get(selectedAnnotation, 'properties.annotationId', '');
    if (!isEmpty(annotationId) && !isEmpty(annotationType) && annotationType !== 'testSquare') {
      deleteFacetAnnotation({ orderId, facetId: selectedFacet, annotationId });
      setShowBoxDelete(false);
      if (annotationDescPanelData.show) {
        annotationDescPanelDispatch({
          type: annotationDescPanelConstants.CLEAR,
        });
      }
      const markers = get(markerAsset, '0.data', []);
      if (markers.length > 0) {
        setMarkerAsset([{
          ...markerAsset[0],
          data: markers.filter((marker) => (marker.id !== annotationId)),
        }]);
      }
    }
  };

  const onMapViewUpdated = ({ bound, ...newView }) => {
    setViewCornerCoordinates(bound);
    setLocalCurrentView({ ...newView });
    setAnomalyAssetOnZoomChange(newView.zoom);
    refreshTokenCompleted();
  };

  const setRotation = (rotation) => {
    setCurrentView({ ...localCurrentView, rotation, pitch: 5 });
  };

  const toggleAssetsOnBaseImage = () => {
    toggleHideAllButBaseImage(!hideAllButBaseImage);
    resetInteractionToTracking();
  };

  const debounceSetRotation = debounce(setRotation, 50);

  const handleBoundsSet = ({ view }) => {
    if (!initialZoomLevel) {
      setInitialZoomLevel(view.zoom);
    }
    setLocalCurrentView(view);
    setCurrentView(view);
  };

  const handleOnMapReady = () => {
    setIsMapReady(true);
    updateInteractionObject(
      { type: 'tracking' },
      true,
    );
  };
  const resizeMap = () => {
    const mapRef = (isAssessLite || currentTab === TABS.GALLERY) ? galleryMapviewerRef : mapViewerRef;
    if (mapRef.current && mapRef.current.resizeMap) {
      const zoom = mapRef.current.resizeMap();
      if (!isAssessLite && currentTab !== TABS.GALLERY) { setInitialZoomLevel(parseFloat(zoom.toFixed(4))); }
    }
  };

  // Takes coordinates as an array
  const setLocationAndZoom = (coordinates) => {
    if (isEmpty(coordinates)) return;
    setCurrentView({
      ...currentView,
      lonLat: {
        lon: get(coordinates, '0', get(currentView, 'lonLat.lon')),
        lat: get(coordinates, '1', get(currentView, 'lonLat.lat')),
        elevation: get(localCurrentView, 'lonLat.elevation'),
      },
      zoom: TESTSQUARE_ZOOM_LEVEL_ON_SELECTION,
    });
  };

  const moveAnomalyDescPenal = (geometries) => {
    if (isEmpty(geometries) || !mapViewerRef.current || !wrapperRef.current) return;
    const translatedCoordinates = util.getMercatorCoordinatesForPoint(geometries, facetScanImageAssets[0]);
    const position = mapViewerRef.current.project(get(translatedCoordinates, 'coordinates', []));
    const isDescPanelVisibleH = isCompleteVisibleH(position.x + 8, wrapperRef);
    const isDescPanelVisibleV = isCompleteVisibleV(position.y + 8, wrapperRef);
    let top = position.y + (isDescPanelVisibleV ? 8 : (-annotationDescPanelConstants.HEIGHT));
    let left = position.x + (isDescPanelVisibleH ? 8 : (-annotationDescPanelConstants.WIDTH));
    const { offsetWidth, offsetHeight } = wrapperRef.current;
    if (top < 0) top = 0;
    if (left < 0) left = 0;
    if (top > (offsetHeight - annotationDescPanelConstants.HEIGHT)) top = offsetHeight - annotationDescPanelConstants.HEIGHT;
    if (left > (offsetWidth - annotationDescPanelConstants.WIDTH)) left = offsetWidth - annotationDescPanelConstants.WIDTH;
    setAnomalyCoordinates({
      top,
      left,
    });
  };

  const setSelectedAnomalyRefWithPolygon = (initialAnomalyId) => {
    const selectedAnomaly = anomalies.find(
      (anomaly) => anomaly.data[0].properties.anomalyId === initialAnomalyId,
    );
    if (selectedAnomaly != null) {
      const coord = get(selectedAnomaly, 'data[0].geometries[0].coordinates[0]', []);
      const zVal = coord.reduce((z, xyz) => z + parseFloat(xyz[2] || 0), 0) / coord.length;
      const { geometry } = util.getCentroidForGeometry(
        get(selectedAnomaly, 'data[0].geometries[0]', {}),
      );
      geometry.coordinates.push(zVal);
      selectedAnomalyRef.current = {
        geometries: [geometry],
      };
    }
  };

  const setFacetWithInitialAnomaly = (facetId) => {
    try {
      if (enableAnomalyDrawing) {
        initiateAnomalyDrawing(false);
      }
      const selectedFacetObject = facets.find((f) => f.facetId === facetId);
      const anomalyIdsMap = anomalies.map((anomaly) => get(anomaly, 'data.0.properties.anomalyId'));
      const facetWithFilteredAnomalies = {
        ...selectedFacetObject,
        anomalyList: selectedFacetObject.anomalyList.filter((anomaly) => anomalyIdsMap.includes(get(anomaly, 'anomalyId'))),
      };
      const facetHasAnomalies = !isEmpty(get(facetWithFilteredAnomalies, 'anomalyList', []));

      let initialAnomaly = '';
      const testSquareData = facetWithFilteredAnomalies.testsquare ? facetWithFilteredAnomalies.testsquare : null;
      const testSquareError = util.fetchTestSquareErrorCode(facetWithFilteredAnomalies);
      const facetTestSquareDisabled = facetTestSquareDisabledMap[facetId];
      if (!facetHasAnomalies || !showPotentialAnomalies) {
        initialAnomaly = UNSET_ANOMALY_ID;
      }
      let facetObj = facetWithFilteredAnomalies;
      const testSquareAnomalies = get(facetWithFilteredAnomalies, 'testsquare.anomalies', []) || [];
      if (!facetTestSquareDisabled) {
        setLocationAndZoom(get(facetWithFilteredAnomalies, 'testsquare.centroid.coordinates', {}));
        if (testSquareAnomalies.length) {
          const filteredFacetTestsquareAnomalies = facetWithFilteredAnomalies.anomalyList
            .filter((anomaly) => testSquareAnomalies.includes(anomaly.anomalyId));
          facetObj = {
            ...facetWithFilteredAnomalies,
            anomalyList: filteredFacetTestsquareAnomalies,
          };
        } else {
          initialAnomaly = UNSET_ANOMALY_ID;
        }
      }
      initialAnomaly = !isEmpty(initialAnomaly) ? initialAnomaly : util.getInitialAnomalyId(facetObj);
      if (facetTestSquareDisabled) {
        if (!isEmpty(initialAnomaly) && initialAnomaly !== UNSET_ANOMALY_ID) {
          switchAnomaly(initialAnomaly);
        } else {
          setLocationAndZoom(get(facetWithFilteredAnomalies, 'facetOutline.centroid.coordinates', {}));
        }
      }
      setSelectedFacet({
        facetId,
        selectedAnomalyId: initialAnomaly,
        testSquare: {
          data: testSquareData,
          testSquareError,
        },
      });
    } catch (err) {
      setTimeout(() => {
        errorHandlerService(err, err.message || '', 'Adjuster');
        showCustomErrorToast({ message: 'adjuster.somethingWentWrong' });
      }, 0);
    }
  };

  const onTestSquareClick = (tsAnnotation) => {
    if (authUtils.isEntitled(entitlements, FEATURE_ENTITLEMENTS.MANAGE_DECISIONS)) {
      setTestSquareAsset([
        {
          ...testSquareAsset[0],
          data: [{
            ...testSquareAsset[0].data[0],
            style: {
              ...testSquareAsset[0].data[0].style,
              strokeOpacity: 0.5,
            },
          }],
        },
      ]);
      updateInteractionObject({
        type: 'editing',
        handler: handleMoveTestSquare,
        assetId: tsAnnotation.annotation.assetId,
        annotation: tsAnnotation.annotation.annotation,
        editStyle: {
          polygon: {
            strokeColor: '#FFFFFF',
            strokeWidth: 7,
          },
        },
        mode: { move: true, edit: false, rotate: false },
      });
    }
  };

  const handleUpdateBoxAnnotationInclInReport = ({ annotation, includeInReport }) => {
    const boxAnnotationId = get(annotation, 'properties.annotationId', '');
    const annotationGeometry = get(annotation, 'geometries[0]', {});
    const boxAnnotationCoordinates = get([annotationGeometry], '0.coordinates.0', []);
    updateFacetAnnotationInclInReport({
      orderId,
      facetId: selectedFacet,
      annotationId: boxAnnotationId,
      data: {
        annotation:
        {
          geometry: {
            type: 'Polygon',
            coordinates: [boxAnnotationCoordinates],
          },
          includeInReport,
        },
      },
    });
    annotationDescPanelDispatch({
      type: annotationDescPanelConstants.ALL,
      includeInReport,
    });
  };

  const handleUpdateDescription = ({ annotation, description }) => {
    const boxAnnotationId = get(annotation, 'properties.annotationId', '');
    const annotationGeometry = get(annotation, 'geometries[0]', {});
    const boxAnnotationCoordinates = get([annotationGeometry], '0.coordinates.0', []);
    const annotationPixelCoords = util.getPolygonPixelCoords(boxAnnotationCoordinates, facetScanImageAssets[0]);
    const selectedFacetObj = facets.find((facet) => facet.facetId === selectedFacet) || false;
    updateFacetAnnotation({
      orderId,
      facetId: selectedFacet,
      annotationId: boxAnnotationId,
      data: {
        annotation:
        {
          geometry: {
            type: 'Polygon',
            coordinates: [boxAnnotationCoordinates],
          },
          properties: {
            pixelCoords: annotationPixelCoords,
          },
          ...(description && { comment: description }),
        },
      },
    });
    if (!isEmpty(selectedFacetObj)) {
      const {
        includeInReport, facetId, decision, facetAliasName,
      } = selectedFacetObj;
      if (!includeInReport && annotationDescPanelData.includeInReport) {
        saveFacetReportInclusion({
          orderId,
          facetId,
          data: {
            decision,
            facetAliasName,
            roofId,
            includeInReport: true,
          },
        });
      }
    }
    setTimeout(() => {
      annotationDescPanelDispatch({
        type: annotationDescPanelConstants.CLEAR,
      });
      showMarker(boxAnnotationId);
      clearAnnotationToEdit();
      resetInteractionToTracking();
    }, 0);
  };

  const includeFacetAndDamageInReport = () => {
    setIsAnomalyDescriptionPresent(true);
    const selectedFacetObj = facets.find((facet) => facet.facetId === selectedFacet) || false;
    const {
      includeInReport, facetId, decision, facetAliasName,
    } = selectedFacetObj;
    if (!includeInReport) {
      saveFacetReportInclusion({
        orderId,
        facetId,
        data: {
          decision,
          facetAliasName,
          roofId,
          includeInReport: true,
        },
      });
    }
    if (selectedAnomalyIsUserAdded
      || (!selectedAnomalyIsUserAdded && selectedAnomalyIsConfirmed)) {
      toggleAnomalyIncludeInReport({
        orderID: orderId,
        anomalyID: selectedAnomalyId,
        data: {
          includeInReport: true,
        },
      });
    }
  };

  const moveAnnotationDescPanel = (geometries) => {
    if (!geometries || !mapViewerRef.current || !wrapperRef.current) return;
    const translatedCoordinates = util.getMercatorCoordinatesForPolygon(geometries, facetScanImageAssets[0]);
    const rotation = get(mapViewerRef.current, 'localState.view.rotation', currentView.rotation);
    const topRightCoordinate = util.getRightMostCoordinate(get(translatedCoordinates, 'coordinates[0]', []), rotation);
    let position = mapViewerRef.current.project(topRightCoordinate);
    const isDescPanelVisibleH = isCompleteVisibleH(position.x + 20, wrapperRef);
    if (!isDescPanelVisibleH) {
      const topLeftCoordinate = util.getLeftMostCoordinate(get(translatedCoordinates, 'coordinates[0]', []));
      position = mapViewerRef.current.project(topLeftCoordinate);
    }
    const isDescPanelVisibleV = isCompleteVisibleV(position.y + 20, wrapperRef);
    let top = position.y + (isDescPanelVisibleV ? 20 : (-annotationDescPanelConstants.HEIGHT));
    let left = position.x + (isDescPanelVisibleH ? 20 : (-annotationDescPanelConstants.WIDTH));
    const { offsetWidth, offsetHeight } = wrapperRef.current;
    if (top < 0) top = 0;
    if (left < 0) left = 0;
    if (top > (offsetHeight - annotationDescPanelConstants.HEIGHT)) top = offsetHeight - annotationDescPanelConstants.HEIGHT;
    if (left > (offsetWidth - annotationDescPanelConstants.WIDTH)) left = offsetWidth - annotationDescPanelConstants.WIDTH;
    annotationDescPanelDispatch({
      type: annotationDescPanelConstants.POSITION,
      top,
      left,
    });
  };

  const handleMoveBoxAnnotation = (e) => {
    try {
      if (!e.annotation) {
        return;
      }
      const annotationGeometry = get(e, 'annotation.geometries[0]', {});
      const boxAnnotationCoordinates = get([annotationGeometry], '0.coordinates.0', []);
      const clickedAnnotationAsset = get(clickedAnnotation, 'event.data.asset', {});
      const boxAnnotationId = get(e, 'annotation.properties.annotationId', '');
      const description = get(e, 'annotation.properties.comment', '');
      const selectedFacetObj = facetPolygons.data.find(
        (facet) => get(facet, 'properties.facetId') === selectedFacet,
      );
      const selectedFacetObject = facets.find((facet) => facet.facetId === selectedFacet) || false;
      if (e.state === INTERACTION_STATES.completed) {
        const prevBoxAnnotationCoords = facetBoxAnnotations.find((annotation) => annotation.annotationId === boxAnnotationId)
          .geometries[0].coordinates[0];
        if (
          !isEqual(boxAnnotationCoordinates, prevBoxAnnotationCoords)
          || annotationDescriptionRef.current !== description
        ) {
          const annotationPixelCoords = util.getPolygonPixelCoords(boxAnnotationCoordinates, clickedAnnotationAsset);
          updateFacetAnnotation({
            orderId,
            facetId: selectedFacet,
            annotationId: boxAnnotationId,
            data: {
              annotation:
              {
                geometry: {
                  type: 'Polygon',
                  coordinates: [boxAnnotationCoordinates],
                },
                properties: {
                  pixelCoords: annotationPixelCoords,
                },
                comment: annotationDescriptionRef.current,
              },
            },
          });
        }
        if (!isEmpty(selectedFacetObject)) {
          const {
            includeInReport, facetId, decision, facetAliasName,
          } = selectedFacetObject;
          if (!includeInReport && descriptionIncludeRef.current) {
            saveFacetReportInclusion({
              orderId,
              facetId,
              data: {
                decision,
                facetAliasName,
                roofId,
                includeInReport: true,
              },
            });
          }
        }
        showMarker(boxAnnotationId);
        annotationDescPanelDispatch({
          type: annotationDescPanelConstants.CLEAR,
        });
        clearAnnotationToEdit();
        resetInteractionToTracking();
      }
      if (e.state === 'in progress' && e.dragEnd) {
        const facetGeometry = get(selectedFacetObj, 'geometries[0]', {});
        const isBoxCompletelyOutsideFacet = util.isAnnotationCompletelyOutside(annotationGeometry, facetGeometry);
        if (isBoxCompletelyOutsideFacet) {
          setTimeout(() => showCustomErrorToast({ message: 'adjuster.outsideOfBounds' }), 0);
          resetBoxAnnotationPosition(boxAnnotationId);
          annotationDescPanelDispatch({
            type: annotationDescPanelConstants.CLEAR,
          });
          clearAnnotationToEdit();
          resetInteractionToTracking();
          return;
        }
        moveAnnotationDescPanel(get(e, 'annotation.geometries[0]', []));
      }
    } catch (err) {
      setTimeout(() => {
        errorHandlerService(err, err.message || '', 'Adjuster');
        showCustomErrorToast({ message: 'adjuster.somethingWentWrong' });
      }, 0);
    }
  };

  useEffect(() => {
    if (annotationDescPanelData.show && selectedBoxAnnotation.current) {
      moveAnnotationDescPanel(get(selectedBoxAnnotation.current, 'geometries[0]', []));
    }
    if (showAnomalyDescription && !isEmpty(selectedAnomalyRef.current)) {
      moveAnomalyDescPenal(get(selectedAnomalyRef.current, 'geometries[0]', {}));
    }
  }, [localCurrentView]);

  const onBoxAnnotationClick = (boxAnnotation) => {
    try {
      const assetId = get(boxAnnotation, 'annotation.assetId');
      const annotation = get(boxAnnotation, 'annotation.annotation');
      const annotationId = get(boxAnnotation, 'annotation.annotation.properties.annotationId', '');
      const { includeInReport = false, comment = '' } = facetBoxAnnotations.find(
        (ann) => get(ann, 'annotationId') === annotationId,
      );
      selectedBoxAnnotation.current = annotation;
      if (isEntitled(entitlements, FEATURE_ENTITLEMENTS.MANAGE_DECISIONS)) {
        updateInteractionObject({
          type: 'editing',
          assetId,
          annotation,
          mode: { move: true, edit: true, rotate: true },
          handler: handleMoveBoxAnnotation,
        });
        setShowBoxDelete(true);
      }
      moveAnnotationDescPanel(get(annotation, 'geometries[0]', []));
      annotationDescPanelDispatch({
        annotation,
        type: annotationDescPanelConstants.ALL,
        show: true,
        includeInReport,
      });
      setAnnotationDescription(comment);
      setDescriptionInclude(true);
      hideMarker(annotation.id);
    } catch (err) {
      setTimeout(() => {
        errorHandlerService(err, err.message || '', 'Adjuster');
        showCustomErrorToast({ message: 'adjuster.somethingWentWrong' });
      }, 0);
    }
  };

  useEffect(() => {
    if (!mapViewerRef.current || !roofBoundingBox) return;
    const { mapContainer } = mapViewerRef.current;
    const { clientWidth, clientHeight } = mapContainer;
    setBounds(util.getBounds(roofBoundingBox.coordinates[0], clientWidth, clientHeight));
  }, [roofBoundingBox, mapViewerRef.current]);

  useEffect(() => {
    if (showErrorToast && annotationDescPanelData.show) {
      annotationDescPanelDispatch({
        type: annotationDescPanelConstants.CLEAR,
      });
      clearAnnotationToEdit();
    }
  }, [showErrorToast]);

  useEffect(() => {
    if (loading[GET_ANOMALY_NOTES] === false && !isEmpty(facetScanImageAssets)) {
      moveAnomalyDescPenal(get(selectedAnomalyRef.current, 'geometries[0]', {}));
      setIsAnomalyDescriptionPresent(!isEmpty(get(anomalyNote, 'note', '')));
    }
  }, [anomalyNote, loading[GET_ANOMALY_NOTES]]);

  useEffect(() => {
    try {
      if (testSquareLocationApiFail !== undefined) {
        setTestSquareAsset([
          {
            ...testSquareAsset[0],
            data: [{
              ...testSquareAsset[0].data[0],
              geometries: [
                {
                  type: 'Polygon',
                  coordinates: [testSquare.geometry.coordinates[0]],
                },
              ],
              properties: {
                ...testSquareAsset[0].data[0].properties,
                customId: uuidv4(),
              },
              style: {
                ...testSquareAsset[0].data[0].style,
                strokeOpacity: 0.5,
              },
            }],
          },
        ]);
      }
    } catch (err) {
      setTimeout(() => {
        errorHandlerService(err, err.message || '', 'Adjuster');
        showCustomErrorToast({ message: 'adjuster.somethingWentWrong' });
      }, 0);
    }
  }, [testSquareLocationApiFail]);

  useEffect(() => {
    try {
      if (updateAnomalyLocationApiFail === undefined) return;
      // api failure while updating location on an anomaly, setting it back to initial pos
      const prevAnomalyAssets = getAnomalyAsset();
      prevAnomalyAssets[0].uuid = uuidv4();
      setAnomalyAsset(prevAnomalyAssets);
    } catch (err) {
      setTimeout(() => {
        errorHandlerService(err, err.message || '', 'Adjuster');
        showCustomErrorToast({ message: 'adjuster.somethingWentWrong' });
      }, 0);
    }
  }, [updateAnomalyLocationApiFail]);

  useEffect(() => {
    try {
      setFacetAssets(selectedFacet, authToken);
      if (selectedFacet) {
        const currentFacet = facets.find((facet) => facet.facetId === selectedFacet);
        const planeConstants = get(currentFacet, 'properties.plane', undefined);
        setFacetPlaneConstants(planeConstants);
        const facetPolygon = facetPolygons.data.find(
          (facet) => get(facet, 'properties.facetId') === selectedFacet,
        );
        const facetPolygonCoordinates = get(facetPolygon, 'geometries[0].coordinates', []);
        const maskAssetObject = {
          assetType: 'annotation',
          data: [
            {
              geometries: [
                {
                  type: 'Polygon',
                  coordinates: [
                    [
                      [currentView.lonLat.lon - 1, currentView.lonLat.lat + 1],
                      [currentView.lonLat.lon + 1, currentView.lonLat.lat + 1],
                      [currentView.lonLat.lon + 1, currentView.lonLat.lat - 1],
                      [currentView.lonLat.lon - 1, currentView.lonLat.lat - 1],
                      [currentView.lonLat.lon - 1, currentView.lonLat.lat + 1],
                    ],
                    facetPolygonCoordinates[0],
                  ],
                },
              ],
              properties: {
                trackEnabled: false,
                cursorStyle: 'pointer',
              },
            },
          ],
          style: {
            polygon: {
              strokeColor: '#000',
              fillColor: '#000',
              fillOpacity: 0.8,
            },
          },
        };
        resetInteractionToTracking();
        /**
         * if there are facets on top of a facet, we are expecting the data to have
         * multiple sets of polygon coordinates, in this case we are creating multiple
         * mask(polygon) annotation.
         */
        if (facetPolygonCoordinates.length > 1) {
          facetPolygonCoordinates.slice(1, facetPolygonCoordinates.length).forEach((facetPolygonCoordinate) => {
            maskAssetObject.data.push(
              {
                geometries: [
                  {
                    type: 'Polygon',
                    coordinates: [
                      facetPolygonCoordinate,
                    ],
                  },
                ],
                properties: {
                  trackEnabled: false,
                  cursorStyle: 'pointer',
                },
              },
            );
          });
        }
        const newMask = util.getMapviewerAsset(
          maskAssetObject, showMapviewerError,
        );
        setFacetMask(newMask ? [newMask] : []);
      } else {
        displayTestSquare(false);
      }
      setAnomalyAsset([]);
    } catch (err) {
      setTimeout(() => {
        errorHandlerService(err, err.message || '', 'Adjuster');
        showCustomErrorToast({ message: 'adjuster.somethingWentWrong' });
      }, 0);
    }
    // eslint-disable-next-line
  }, [selectedFacet,authToken]);

  useEffect(() => {
    try {
      if (
        !isEmpty(facetScanImageAssets)
        && selectedFacet
        && !isEmpty(filteredAnomalies)
      ) {
        setAnomalyAsset(getAnomalyAsset());
      } else {
        setAnomalyAsset([]);
      }
    } catch (err) {
      setTimeout(() => {
        errorHandlerService(err, err.message || '', 'Adjuster');
        showCustomErrorToast({ message: 'adjuster.somethingWentWrong' });
      }, 0);
    } finally {
      isFacetTestsquareDisabledRef.current = isFacetTestsquareDisabled;
    }
    // eslint-disable-next-line
  }, [
    facetImages,
    facetScanImageAssets,
    selectedAnomalyId,
    showRemovedAnomalies,
    showPotentialAnomalies,
    anomalies,
    isFacetTestsquareDisabled,
    facetAnomaliesSubsetIds,
  ]);

  useEffect(() => {
    try {
      if (!isEmpty(facets) && isEmpty(facetImages)) {
        const facetIds = facets.reduce((facetObj, { facetId, facetAliasName }) => ({ ...facetObj, [facetAliasName]: facetId }), {});
        fetchFacetImages({
          orderId,
          facetIds,
          structureId: selectedStructureId,
        });
      }
    } catch (err) {
      setTimeout(() => {
        errorHandlerService(err, err.message || '', 'Adjuster');
        showCustomErrorToast({ message: 'adjuster.somethingWentWrong' });
      }, 0);
    }
  }, [facets]);

  useEffect(() => {
    if (selectedFacet && facetScanImageAssets && facetScanImageAssets.length > 0) {
      if (!isEmpty(testSquare) && !isFacetTestsquareDisabled && authUtils.isEntitled(entitlements, FEATURE_ENTITLEMENTS.VIEW_DECISIONS)) {
        displayTestSquare(true);
      } else {
        displayTestSquare(false);
      }
    }
  }, [facetScanImageAssets]);

  useEffect(() => {
    try {
      if (
        loading.facetImages
        && loading.facetImages[selectedFacet] === false
        && isEmpty(facetScanImageAssets)
      ) {
        setFacetAssets(selectedFacet, authToken);
      }
    } catch (err) {
      setTimeout(() => {
        errorHandlerService(err, err.message || '', 'Adjuster');
        showCustomErrorToast({ message: 'adjuster.somethingWentWrong' });
      }, 0);
    }
    // eslint-disable-next-line
  }, [loading.facetImages, authToken]);

  useEffect(() => {
    if (clickedAnnotation.state !== INTERACTION_STATES.tracked) return;
    try {
      const eventType = get(clickedAnnotation, 'event.type', false);
      const assetType = get(clickedAnnotation, 'annotation.annotation.properties.assetType');
      setTestSquareInitialGeometry(get(testSquareAsset[0], 'data[0].geometries[0]', []));
      if (eventType === 'hover') {
        if (assetType === 'testSquare') {
          setTestSquareHoverStyle(true);
        } else {
          setTestSquareHoverStyle(false);
        }
        return;
      }

      const clickedTestSquareId = get(clickedAnnotation, 'annotation.annotation.properties.testSquareId', '');
      if (testSquare && clickedTestSquareId === testSquare.testSquareId && !orderCompleted) {
        onTestSquareClick(clickedAnnotation);
        return;
      }

      if (assetType === 'boxAnnotation' && !orderCompleted) {
        if (!isEntitled(entitlements, FEATURE_ENTITLEMENTS.MANAGE_DECISIONS)
        && annotationDescPanelData.show
        && annotationDescPanelData.annotation !== clickedAnnotation.annotation) {
          annotationDescPanelDispatch({
            type: annotationDescPanelConstants.CLEAR,
          });
          showMarker(annotationDescPanelData.annotation.id);
          clearAnnotationToEdit();
          resetInteractionToTracking();
          selectedBoxAnnotation.current = null;
        }
        onBoxAnnotationClick(clickedAnnotation);
        return;
      }
      setIsFacetClickAction(false);
      if (!isEmpty(facetMask) && !clickedOnRoof(get(clickedAnnotation, 'event.data.lonLat', false))) {
        setFacetMask([]);
        setSelectedFacet({ facetId: '', selectedAnomalyId: UNSET_ANOMALY_ID, testSquare: {} });
        setSelectedAnomalyView({});
        setFacetScanImageAssets([]);
        setClickedAnnotation({});
      } else {
        const facetId = get(clickedAnnotation, 'annotation.annotation.properties.facetId', false);
        const clickedAnomalyId = get(
          clickedAnnotation.annotation,
          'annotation.properties.anomalyId',
          false,
        );
        if (clickedAnomalyId) {
          // setting up coordinates for anomalyDescriptionPanel
          const selectedAnomaly = get(clickedAnnotation, 'annotation.annotation', {});
          if (isEmpty(selectedAnomaly)) return;
          selectedAnomalyRef.current = selectedAnomaly;
          if (annotationDescPanelData.show && !isEntitled(entitlements, FEATURE_ENTITLEMENTS.MANAGE_DECISIONS)) {
            annotationDescPanelDispatch({
              type: annotationDescPanelConstants.CLEAR,
            });
            showMarker(annotationDescPanelData.annotation.id);
            clearAnnotationToEdit();
            resetInteractionToTracking();
            selectedBoxAnnotation.current = null;
          }
          if (!(isFacetTestsquareDisabled || facetAnomaliesSubsetIds.includes(clickedAnomalyId))) {
            return;
          }
          setAnomalyId(clickedAnomalyId);
        } else if (facetId && facetId !== selectedFacet) {
          setFacetWithInitialAnomaly(facetId);
        } else if (isFacetRawImagesEnabled) {
          setIsFacetClickAction(true);
          const latlong = get(clickedAnnotation, 'event.data.lonLat', {});
          const facetAliasName = get(clickedAnnotation, 'annotation.annotation.properties.facetAliasName', '');
          fetchAnomalyImages({
            facetAliasName,
            orderId,
            structureId: selectedStructureId,
            lat: latlong.lat,
            long: latlong.lon,
          });
          setAnomalyId(UNSET_ANOMALY_ID);
          if (annotationDescPanelData.show && !isEntitled(entitlements, FEATURE_ENTITLEMENTS.MANAGE_DECISIONS)) {
            annotationDescPanelDispatch({
              type: annotationDescPanelConstants.CLEAR,
            });
            showMarker(annotationDescPanelData.annotation.id);
            clearAnnotationToEdit();
            resetInteractionToTracking();
            selectedBoxAnnotation.current = null;
          }
        }
        const area = get(clickedAnnotation, 'event.data', {});
        setSelectedArea(area);
      }
    } catch (err) {
      setTimeout(() => {
        errorHandlerService(err, err.message || '', 'Adjuster');
        showCustomErrorToast({ message: 'adjuster.somethingWentWrong' });
      }, 0);
    }
    // eslint-disable-next-line
  }, [clickedAnnotation]);

  useEffect(() => {
    if (baseImage) {
      const asset = util.getMapviewerAsset({
        assetType: 'iwimage',
        tileUrls: [
          `${ASSESS_IMAGES_API_ENDPOINT}/${baseImage.image_urn}/tiles/{z}/{x}/{y}?format=IMAGE_FORMAT_JPEG_PNG&`
          + `${sharedUtil.getAuthParam(authToken)}`,
        ],
        metadata: baseImage,
      }, showMapviewerError);
      if (asset) {
        setOverheadImageAsset(asset);
      }
    }
  }, [baseImage, authToken]);

  useEffect(() => {
    resizeMap();
  }, [window.innerWidth, mapSize.width, mapSize.height, galleryMapSize.width, galleryMapSize.height]);

  useEffect(() => {
    if (annotationDescPanelData.show) {
      annotationDescPanelDispatch({
        type: annotationDescPanelConstants.CLEAR,
      });
      clearAnnotationToEdit();
    }
    if (isFacetClickAction) {
      setIsFacetClickAction(false);
    }
  }, [currentTab, selectedFacet]);

  useEffect(() => {
    const { facetId, annotationId } = createdAnnotationToEdit;
    if (!enableAnnotationDescription || !boxAnnotationAsset || !facetId || facetId !== selectedFacet || !facetScanImageAssets) return;
    const assetToEdit = boxAnnotationAsset.find((asset) => get(asset, 'data[0].properties.annotationId', '') === annotationId);
    if (!assetToEdit) return;
    const event = {
      annotation: {
        assetId: assetToEdit.uuid,
        annotation: assetToEdit.data[0],
      },
      state: 'tracked',
      errorMessage: null,
      event: {
        type: 'click',
        data: {
          asset: facetScanImageAssets[0],
          lonLat: {},
        },
      },
    };
    setClickedAnnotation(event);
  }, [boxAnnotationAsset]);

  useEffect(() => {
    if (!isEmpty(selectedAnomalyId) && selectedAnomalyId !== UNSET_ANOMALY_ID) {
      setSelectedAnomalyRefWithPolygon(selectedAnomalyId);
      fetchAnomalyNoteDetails({
        orderId,
        anomalyId: selectedAnomalyId,
      });
    }
  }, [selectedAnomalyId]);

  const markAreaDamage = () => {
    const asset = get(selectedArea, 'asset', false);
    const imageUrn = asset.metadata.image_urn;
    const lonLat = get(selectedArea, 'lonLat', false);
    const coordinates = [lonLat.lon, lonLat.lat, lonLat.elevation];
    const geometry = {
      type: 'Point',
      coordinates,
    };
    const convertedAnomaly = util.convertCoordinatesToXYs(asset, geometry);
    addUserAnomaly({
      long: geometry.coordinates[0],
      lat: geometry.coordinates[1],
      structureID: selectedStructureId,
      orderId,
      anomalyData: {
        decision: 'CONFIRMED',
        facetAliasName: facets.find((facet) => facet.facetId === selectedFacet)
          .facetAliasName,
        facetId: selectedFacet,
        geometry: {
          coordinates: [convertedAnomaly.anomalyCoords],
          type: 'Polygon',
        },
        imageURN: imageUrn,
        isUserAdded: true,
        properties: {
          pixel_coords: [convertedAnomaly.anomalyXYs.map((anomalyXY) => [anomalyXY.x, anomalyXY.y])],
        },
        roofId,
      },
      decision: facets.find((facet) => facet.facetId === selectedFacet).decision,
      includeInReport: facets.find((facet) => facet.facetId === selectedFacet).includeInReport,
    });
    setIsFacetClickAction(false);
  };

  // pendo custom event - complete order
  const handleCompleteAndExit = (oId) => {
    completeReview(oId);
    util.publishPendoEvent(ORDER_COMPLETED_PENDO_EVENT, { orderId: oId });
  };

  const getFacetCursorStyle = (facet) => {
    const facetId = get(facet, ['properties.facetId', '']);
    if (enableAnomalyDrawing && isFacetTestsquareDisabled) {
      return styles.addAnomalies;
    }
    if (enableAnomalyDrawing && !isEmpty(testSquare) && !isFacetTestsquareDisabled) {
      return 'no-drop';
    }
    if (facetId !== selectedFacet) {
      return 'pointer';
    }
    if (enableAnomalyDrawing) {
      return 'crosshair';
    }
    return '';
  };

  let facetPolygonAsset = [];
  if (facetPolygons && facetPolygons.data && facetPolygons.data.length > 0) {
    facetPolygonAsset = [{
      ...facetPolygons,
      data: facetPolygons.data.map((annotation) => ({
        ...annotation,
        properties: {
          ...annotation.properties,
          cursorStyle: getFacetCursorStyle(annotation),
          customCursor: enableAnomalyDrawing && isFacetTestsquareDisabled && getFacetCursorStyle(annotation),
        },
        style: {
          strokeColor: 'white',
          strokeWidth: !selectedFacet ? 2 : 0,
          fillOpacity:
            selectedFacet
              ? 0
              : BUCKET[buckets[annotation.properties.facetAliasName]],
          fillColor: theme.evPalette.amber[100],
        },
      })),
    }];
  }

  const openAnomalyDescription = () => {
    fetchAnomalyNoteDetails({
      orderId,
      anomalyId: selectedAnomalyId,
    });
    setShowAnomalyDescription(true);
  };

  const renderSwitcher = () => (
    !sharedUtil.isSharedUrl()
      ? (
        <EVBox className={styles.viewSwitcherStyle}>
          <PhotosUITabs tab={PhotosUITab.MOSAIC} />
        </EVBox>
      )
      : <></>
  );

  return (
    <>
      <ErrorBoundary
        componentName="Adjuster: SidePanel"
        alertMsg={t('adjuster.sidePanelCrash')}
      >
        <AdjusterSidePanel
          setResize={resizeMap}
          setFacetWithInitialAnomaly={setFacetWithInitialAnomaly}
        />
      </ErrorBoundary>
      <div className={styles.wrapper} id="adjusterMapViewer" ref={wrapperRef}>
        {!isAssessLite && isCaptured && mapViewerCriticalAssetsReady && currentTab !== TABS.GALLERY && (
          <>
            <ErrorBoundary
              componentName="Adjuster: MapViewer"
              alertMsg={t('adjuster.mapViewerCrash')}
            >
              <EVMapViewer
                debug={debugMode}
                ref={mapViewerRef}
                view={currentView}
                planeConstants={facetPlaneConstants}
                onViewUpdated={onMapViewUpdated}
                enableFlyTo
                flyToControl={{
                  speed: 0.5,
                }}
                baseMap="blank"
                bound={bounds}
                fitToBounds
                navigationControl={{
                  disablePitchAndRotation: true,
                }}
                initialConfig={{
                  style: { backgroundColor: '#000' },
                  envName: REACT_APP_ENV,
                }}
                maxZoom={MAPVIEWER_MAX_ZOOM}
                assets={
                  hideAllButBaseImage
                    ? [overheadImageAsset]
                    : [
                      overheadImageAsset,
                      ...facetScanImageAssets,
                      ...facetPolygonAsset,
                      ...facetMask,
                      ...!isFacetTestsquareDisabled ? testSquareAsset : [],
                      ...anomalyAsset,
                      ...boxAnnotationAsset,
                      ...markerAsset,
                      {
                        ...labelAssets,
                        data: labelAssets.data.filter((x) => x.properties.facetId !== selectedFacet),
                        parentAsset: overheadImageAsset,
                      },
                    ]
                }
                interaction={interactionObject}
                onMapReady={handleOnMapReady}
                onBoundsSet={handleBoundsSet}
                onError={(e) => onMapViewerError(e, 'adjuster screen')}
              />
            </ErrorBoundary>
            <EVBox className={styles.compassStyle}>
              <EVCompass
                updatedAngle="0"
                onAngleUpdate={debounceSetRotation}
                compassWidth="70"
                knobSize={20}
                knobRadius={10}
                showNorthIndicator
              />
            </EVBox>
            {enableAnnotationDescription && !isEmpty(annotationDescPanelData) && annotationDescPanelData.show && (
              <AnnotationDescPanel
                data={annotationDescPanelData}
                updateDescription={handleUpdateDescription}
                updateInclInReport={handleUpdateBoxAnnotationInclInReport}
                annotationDescription={annotationDescription}
                setAnnotationDescription={setAnnotationDescription}
                setDescriptionInclude={setDescriptionInclude}
              />
            )}

            {showAnomalyDescription && (
              <AnomalyDescPanel
                coordinates={anomalyCoordinates}
                selectedAnomalyId={selectedAnomalyId}
                setShowAnomalyDescription={setShowAnomalyDescription}
                includeFacetAndDamageInReport={includeFacetAndDamageInReport}
              />
            )}
            {isAssessPhotoViewerEnabled && renderSwitcher()}
          </>
        )}
        {currentTab !== TABS.GALLERY && (
          <ErrorBoundary
            componentName="Adjuster: Toolbar"
            alertMsg={t('adjuster.adjusterToolbarCrash')}
          >
            <AdjusterToolbar
              hideAllButBaseImage={hideAllButBaseImage}
              planeConstants={facetPlaneConstants}
              toggleHideAllButBaseImage={toggleAssetsOnBaseImage}
              zoomInClick={zoomInClick}
              zoomOutClick={zoomOutClick}
              zoomInDisabled={zoomInDisabled}
              zoomOutDisabled={zoomOutDisabled}
              isAnnotationSelected={isAnnotationSelected}
              handleBoxAnnotationToolbarClick={handleBoxAnnotationToolbarClick}
              completeAndExit={() => handleCompleteAndExit(orderId)}
              exitOnly={goToHome}
              exportImagery={exportImagery}
              shareReport={shareReport}
              generateReport={generateReportMenuClick}
              mapViewerCriticalAssetsReady={mapViewerCriticalAssetsReady}
              handleAnnotationDelete={handleAnnotationDelete}
              showBoxDelete={showBoxDelete}
              orderId={orderId}
              isCaptured={isCaptured}
            />
          </ErrorBoundary>
        )}
        {((!isAssessLite && currentTab !== TABS.GALLERY && !isCaptured)) && (
          <Utc currentTab={currentTab} setCurrentTabAction={setCurrentTabAction} />
        )}
        {(isAssessLite || currentTab === TABS.GALLERY) && (
          <ErrorBoundary
            componentName="Adjuster: ImagePreview"
            alertMsg={t('adjuster.imagePreviewCrash')}
          >
            <ImagePreview ref={galleryMapviewerRef} showMapviewerError={showMapviewerError} />
          </ErrorBoundary>
        )}
      </div>
      {showReportPanel && (
        <ErrorBoundary
          componentName="Adjuster: ReportGenerator"
          alertMsg={t('adjuster.reportGeneratorCrash')}
        >
          <ReportGenerator closeReportPanel={() => setShowReportPanel(false)} />
        </ErrorBoundary>
      )}
      {showExportImageryPanel && (
        <ErrorBoundary
          componentName="Adjuster: ExportImageryPanel"
          alertMsg={t('adjuster.exportImageryPanelCrash')}
        >
          {showExportImagery && (<ExportImageryPanel closeExportImageryPanel={() => setShowExportImageryPanel(false)} />)}
        </ErrorBoundary>
      )}
      {isDigitalDeliveryEnabled && (
        <ErrorBoundary
          componentName="Adjuster: ShareReportPanel"
          alertMsg={t('adjuster.shareReportPanelCrash')}
        >
          {showSharereportPanel && (<ShareReportPanel closePanel={() => setShowSharereportPanel(false)} />)}
        </ErrorBoundary>
      )}
      {!isAssessLite && (
        <ErrorBoundary
          componentName="Adjuster: QCPendingPanel"
          alertMsg={t('adjuster.qcPendingPanelCrash')}
        >
          <EntitledComponent entitlement={FEATURE_ENTITLEMENTS.MANAGE_QC_DECISIONS}>
            <QcPendingPanel
              chartData={chartData}
              qcPanelType={qcPanelType}
              coveragePercentage={coveragePercentage}
              orderId={orderId}
              QcClaimID={QcClaimID}
              qcStartTime={qcStartTime}
              qcEndTime={qcEndTime}
              setFacetWithInitialAnomaly={setFacetWithInitialAnomaly}
            />
          </EntitledComponent>
        </ErrorBoundary>
      )}
      {showSkydioMosaicImagePanel && (
        <ErrorBoundary
          componentName="Adjuster: QCSkydioMosaicImagePanel"
          alertMsg={t('adjuster.qcSkydioMosaicImagePanelCrash')}
        >
          <QCSkydioMosaicPanel />
        </ErrorBoundary>
      )}
      <ErrorBoundary
        componentName="Adjuster: WorkFlowPanel"
        alertMsg={t('adjuster.workflowPanelCrash')}
      >
        <EntitledComponent entitlement={FEATURE_ENTITLEMENTS.VIEW_DECISIONS}>
          <WorkflowPanel
            showRemovedAnomalies={showRemovedAnomalies}
            toggleRemovedAnomalies={toggleRemovedAnomalies}
            showPotentialAnomalies={showPotentialAnomalies}
            togglePotentialAnomalies={togglePotentialAnomalies}
            enableAnomalyDrawing={enableAnomalyDrawing}
            initiateAnomalyDrawing={initiateAnomalyDrawing}
            switchAnomaly={switchAnomaly}
            displayTestSquare={displayTestSquare}
            hideWorkflowPanel={hideWorkflowPanel}
            handleMapError={onMapViewerError}
            setLocationAndZoom={setLocationAndZoom}
            showMapviewerError={showMapviewerError}
            interactionStarted={interactionStarted}
            isFacetClickDisplay={isFacetClickAction}
            setIsFacetClickDisplay={setIsFacetClickAction}
            updateInteractionObject={updateInteractionObject}
            setSelectedArea={setSelectedArea}
            markAreaDamage={markAreaDamage}
            openAnomalyDescription={openAnomalyDescription}
            showAnomalyDescription={showAnomalyDescription}
            isAnomalyDescriptionPresent={isAnomalyDescriptionPresent}
          />
        </EntitledComponent>
      </ErrorBoundary>
      {((isAssessLite && isGalleryImages) || (currentTab !== TABS.GALLERY && isCaptured) || (currentTab === TABS.GALLERY && isGalleryImages)) && (
        <ErrorBoundary
          componentName="Adjuster: Statusbar"
          alertMsg={t('adjuster.statusbarCrash')}
        >
          {(!isAssessLite || (isAssessLite && filteredGalleryImages.length > 0)) && <Statusbar />}
        </ErrorBoundary>
      )}
      <EVBackdrop className={styles.backdrop} open={loading[UPDATE_ACCESS_TOKEN] || false}>
        <EVCircularProgress className={styles.circularProgressBar} />
      </EVBackdrop>
      <ToastMessage
        alertMsg={`${t(errorToastMessage)} ${(severity === 'error' && errorCode) ? `${t('adjuster.referCode')} ${errorCode}` : ''
        }`}
        severity={severity}
        open={showErrorToast}
        onClose={handleToastClose}
        autoHideDuration={toastAutoHideDuration}
      />
      <EVBackdrop className={styles.backdrop} open={loading[EXPORT_IMAGERY] || false}>
        <EVCircularProgress className={styles.circularProgressBar} />
      </EVBackdrop>
    </>
  );
};

Adjuster.propTypes = {
  hideNavbar: bool,
};

Adjuster.defaultProps = {
  hideNavbar: false,
};

export default Adjuster;
