import { makeStyles } from '@eagleview/ev-comp-library';

export default makeStyles((theme) => ({
  backgroundColor: theme.palette.common.black,
  '&:hover': {
    '& $galleryArrowButton': {
      display: 'block',
    },
  },
  facetScanText: {
    color: theme.evPalette.charcoal[700],
    fontSize: theme.typography.pxToRem(18),
    fontWeight: '700',
    lineHeight: theme.typography.pxToRem(18),
  },
  closeButton: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
    color: theme.evPalette.charcoal[600],
  },
  thumbnails: {
    marginTop: theme.spacing(2),
    overflowY: 'scroll',
    height: `calc(100vh - ${theme.spacing(19)}px)`,
  },

}));
