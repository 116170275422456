import React from 'react';
import clsx from 'clsx';
import { bool } from 'prop-types';
import { EVCircledCheck } from '@eagleview/ev-comp-library';
import useStyles from './ClaimStatusStepper.styles';

/**
 * Customized icon to be used for each step in stepper
 */
const CustomStepIcon = (props) => {
  const classes = useStyles();
  const { active, completed } = props;

  return (
    <div className={clsx(classes.stepIconRoot, { [classes.stepIconActive]: active })}>
      {completed || active
        ? <EVCircledCheck className={classes.stepIconCompleted} data-testid="circled-check-icon" />
        : <div className={classes.circle} data-testid="circular-div-step" />}
    </div>
  );
};

CustomStepIcon.propTypes = {
  active: bool.isRequired,
  completed: bool.isRequired,
};

export default CustomStepIcon;
