import { createSelector } from 'reselect';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import * as util from '../Adjuster.utils';

export const selectedAnomalyViewSelector = (state) => state.adjusterReducer.selectedAnomalyView;
export const selectedFacetSelector = (state) => state.adjusterReducer.selectedFacet;
export const facetsSelector = (state) => state.adjusterReducer.facets;
export const facetImagesSelector = (state) => state.adjusterReducer.facetImages;
export const errorSelector = (state) => state.adjusterReducer.error;
export const orderIdSelector = (state) => state.adjusterReducer.orderId;
export const roofIdSelector = (state) => state.adjusterReducer.roofId;
export const anomaliesSelector = (state) => state.adjusterReducer.anomalies;
export const selectedAnomalyIdSelector = (state) => state.adjusterReducer.selectedAnomalyId;
export const selectedAnomalyMetadataSelector = (state) => state.adjusterReducer.selectedAnomalyMetadata;
export const softMetalImagesSelector = (state) => state.adjusterReducer.softMetalImages;
export const anomalyImagesSelector = (state) => state.adjusterReducer.anomalyImages;

export const clickedFacetScanSelector = createSelector(
  (state) => state.adjusterReducer.facetScanMetadata,
  (facetScanMetadata) => util.getClickedFacetAssets(facetScanMetadata),
);

export const selectedAnomalyObjSelector = createSelector(
  (state) => state.adjusterReducer.anomalies,
  (state) => state.adjusterReducer.selectedAnomalyId,
  (anomalies, selectedAnomalyId) => anomalies.find((x) => get(x, 'data[0].properties.anomalyId') === selectedAnomalyId),
);

export const selectedFacetObjSelector = createSelector(
  facetsSelector,
  selectedFacetSelector,
  anomaliesSelector,
  (facets, selectedFacet, anomalies) => util.getFacetWithFilteredAnomalies(facets, selectedFacet, anomalies),
);

export const selectedFacetImagesSelector = createSelector(
  facetImagesSelector,
  selectedFacetObjSelector,
  (facetImages, selectedFacetObj) => facetImages.find((x) => x.facetId === selectedFacetObj.facetId),
);

export const selectedFacetImagesPresentSelector = createSelector(
  selectedFacetImagesSelector,
  (selectedFacetImages) => !isEmpty(get(selectedFacetImages, 'data', [])),
);

export const facetHasAnomaliesSelector = createSelector(
  selectedFacetObjSelector,
  (selectedFacetObj) => !isEmpty(get(selectedFacetObj, 'anomalyList', [])),
);

export const selectedAnomalyIsUserAddedSelector = createSelector(
  selectedAnomalyObjSelector,
  (selectedAnomalyObj) => get(selectedAnomalyObj, 'data[0].properties.isUserAdded', false),
);

export const selectedAnomalyStatusSelector = createSelector(
  selectedAnomalyObjSelector,
  (selectedAnomalyObj) => get(selectedAnomalyObj, 'data[0].properties.status', false),
);

export const selectedAnomalyImgSelector = createSelector(
  selectedFacetObjSelector,
  selectedAnomalyIdSelector,
  (selectedFacetObj, selectedAnomalyId) => get(selectedFacetObj, 'anomalyList', []).find((x) => x.anomalyId === selectedAnomalyId) || '',
);
