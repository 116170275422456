import React from 'react';
import { EVTab, EVTabs } from '@eagleview/ev-comp-library';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import * as action from '../Home.actions';
import useStyles from './HomeTabs.style';

/**
 * HomeTabs is component for switching between HomeTable tabs
 */
const HomeTabs = () => {
  // utility hooks
  const styles = useStyles();
  const { t } = useTranslation();

  // actions
  const dispatch = useDispatch();
  const handleChange = (_, id) => dispatch(action.setTabAction(id));

  // selectors
  const tab = useSelector((state) => state.homeReducer.tab);
  const searchQuery = useSelector((state) => state.homeReducer.searchQuery);

  return (
    <EVTabs
      value={tab}
      textColor="primary"
      indicatorColor="primary"
      onChange={handleChange}
    >
      <EVTab className={styles.tabHeading} label={t('home.queue')} />
      <EVTab className={styles.tabHeading} label={t('home.ordered')} />
      <EVTab className={styles.tabHeading} label={t('home.archive')} />
      {!isEmpty(searchQuery) && <EVTab className={styles.tabHeading} label={t('home.search')} />}
    </EVTabs>
  );
};

export default HomeTabs;
