import React, { useState, useEffect, useRef } from 'react';
import {
  EVTypography,
  EVTextField,
  EVSelect,
  EVMenuItem,
  EVFormControl,
  EVBox,
} from '@eagleview/ev-comp-library';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { isEntitled } from 'utils/auth.utils';
import { FEATURE_ENTITLEMENTS } from 'layout/entitleUser/EntitleUser.constants';
import EntitledComponent from 'components/EntitledComponent';
import PropType from 'prop-types';
import get from 'lodash/get';
import * as action from '../Adjuster.actions';
import {
  POST_REPORT, FETCH_ROOF_NOTE, ORDER_ROOF_DECISION_PENDO_EVENT, REPORT_NOTE_MAX_CHAR_LENGTH,
} from '../Adjuster.constants';
import * as utils from '../Adjuster.utils';

const ReportDecision = ({
  orderId, roofId, decisions, notes, styles,
}) => {
  // selectors
  const loading = useSelector((state) => state.adjusterReducer.loading);
  const entitlements = useSelector((state) => state.entitleUserReducer.entitlements);
  const orderCompleted = useSelector((state) => state.adjusterReducer.orderCompleted);

  // actions
  const dispatch = useDispatch();
  const setRoofDecision = (payload) => dispatch(action.saveRoofDecisionAction(payload));
  const saveRoofNote = (payload) => dispatch(action.saveRoofNoteAction(payload));
  const updateRoofNote = (payload) => dispatch(action.updateRoofNoteAction(payload));
  const deleteRoofNote = (payload) => dispatch(action.deleteRoofNoteAction(payload));

  // local state
  const [note, setNote] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  // utility hooks
  const { t } = useTranslation();

  const roofDecision = get(decisions, roofId, '');
  const roofNote = get(notes, roofId, undefined);
  const timerRef = useRef(null);
  const charactersLeft = REPORT_NOTE_MAX_CHAR_LENGTH - note.length;
  // const isMaxNoteLimitReached = noteLength > REPORT_NOTE_MAX_CHAR_LENGTH;

  const handleSelect = (event) => {
    if ((isEntitled(entitlements, FEATURE_ENTITLEMENTS.MANAGE_DECISIONS)
    || isEntitled(entitlements, FEATURE_ENTITLEMENTS.GENERATE_REPORTS_INTERNAL)) && !orderCompleted) {
      setRoofDecision({ orderId, roofId, data: { decision: event.target.value } });
    }
    utils.publishPendoEvent(ORDER_ROOF_DECISION_PENDO_EVENT, { orderId });
  };
  const handleInput = (event) => {
    const textNote = event.target.value;
    if (textNote.length <= REPORT_NOTE_MAX_CHAR_LENGTH) {
      setNote(textNote);
      setErrorMessage('');
    } else {
      // Truncate the input text to the maximum character limit
      const truncatedText = textNote.slice(0, REPORT_NOTE_MAX_CHAR_LENGTH);
      setNote(truncatedText);
      setErrorMessage('Max character limit reached');
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
      timerRef.current = setTimeout(() => {
        setErrorMessage('');
      }, 5000);
    }
  };

  const handleOnBlur = () => {
    const data = { note, includeInReport: true };
    if (!roofNote && !note) return;
    if (!roofNote) {
      saveRoofNote({ roofId, orderId, data });
    } else if (note.length > 0) {
      updateRoofNote({
        roofId,
        orderId,
        data,
        noteId: roofNote.noteID,
      });
    } else {
      deleteRoofNote({ roofId, orderId, noteId: roofNote.noteID });
    }
  };

  useEffect(() => {
    if (roofNote) {
      setNote(roofNote.note);
    }
  }, [roofNote]);

  return (
    <div>
      <EntitledComponent entitlement={FEATURE_ENTITLEMENTS.VIEW_DECISIONS}>
        <EVBox className={styles.container}>
          <EVTypography className={styles.label}>{t('report.decision')}</EVTypography>
          <EVFormControl
            className={styles.formControl}
            variant="outlined"
            size="small"
            disabled={!isEntitled(entitlements, FEATURE_ENTITLEMENTS.MANAGE_DECISIONS) || orderCompleted || loading[POST_REPORT]}
          >
            <EVSelect
              id="roofDecisionSelect"
              className={styles.select}
              value={roofDecision}
              onChange={handleSelect}
            >
              <EVMenuItem value="Undecided">{t('reportPanel.undecided')}</EVMenuItem>
              <EVMenuItem value="Replace">{t('reportPanel.replaceRoof')}</EVMenuItem>
              <EVMenuItem value="Repair">{t('reportPanel.repairRoof')}</EVMenuItem>
              <EVMenuItem value="Deny">{t('reportPanel.noDamage')}</EVMenuItem>
            </EVSelect>
          </EVFormControl>
        </EVBox>
      </EntitledComponent>
      <EntitledComponent entitlement={FEATURE_ENTITLEMENTS.VIEW_NOTES}>
        <EVBox className={styles.container}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <EVTypography className={styles.label}>{t('report.summary')}</EVTypography>
            <EVTypography style={{ color: 'gray', fontSize: '12px' }}>
              Character Limit:
              {' '}
              {charactersLeft}
            </EVTypography>
          </div>
          <EVTextField
            id="outlined-multiline-flexible"
            rows={5}
            multiline
            variant="outlined"
            placeholder={t('report.summary.placeholder')}
            value={note}
            helperText={errorMessage}
            onChange={handleInput}
            onBlur={() => isEntitled(entitlements, FEATURE_ENTITLEMENTS.MANAGE_NOTES) && !orderCompleted && handleOnBlur()}
            disabled={!isEntitled(entitlements, FEATURE_ENTITLEMENTS.MANAGE_NOTES) || orderCompleted || loading[FETCH_ROOF_NOTE]}
            className={styles.textField}
            FormHelperTextProps={{ classes: { contained: styles.helperText } }}
          />
        </EVBox>
      </EntitledComponent>
    </div>
  );
};

ReportDecision.propTypes = {
  orderId: PropType.string.isRequired,
  roofId: PropType.string.isRequired,
  notes: PropType.shape({}).isRequired,
  decisions: PropType.shape({}).isRequired,
  styles: PropType.shape({
    formControl: PropType.string,
    select: PropType.string,
    container: PropType.string,
    label: PropType.string,
    textField: PropType.string,
    helperText: PropType.string,
  }).isRequired,
};

export default ReportDecision;
