import React, { useState, useEffect } from 'react';
import EagleViewLogo from 'assets/eagleview-logo.png';
import {
  EVBox,
  EVPaper,
  EVDivider,
  EVTypography,
  EVTextField,
  EVButton,
} from '@eagleview/ev-comp-library';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  GENERATE_OTP,
  VERIFY_OTP,
  OTP_RETRY_LIMIT,
  RESEND_OTP_COUNTER,
} from '../ShareOTPLoginPage.constant';
import useStyles from './OTPForm.styles';

const OTPForm = ({ uuid, onSubmitOtp, onGenerateOtp }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  // local state
  const [otp, setOtp] = useState('');
  const [timer, setTimer] = useState(RESEND_OTP_COUNTER);
  const [isValidOtpInput, setIsValidOtpInput] = useState(true);

  // selectors
  const availableAttempts = useSelector((state) => state.shareOTPLoginReducer.availableAttempts);
  const isOptVerified = useSelector((state) => state.shareOTPLoginReducer.optVerified);
  const nextAvailableLoginTime = useSelector((state) => state.shareOTPLoginReducer.nextAvailableLoginTime);
  const currentTime = moment();
  const nextTime = moment(nextAvailableLoginTime);
  const remainingTime = nextTime.diff(currentTime);
  const duration = moment.duration(remainingTime);
  const hours = Math.floor(duration.asHours());
  const minutes = Math.floor(duration.asMinutes()) % 60;
  const remainingTimeFormatted = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
  const {
    [GENERATE_OTP]: generateOtpLoading,
  } = useSelector(
    (state) => state.shareOTPLoginReducer.loading,
  );

  const {
    [VERIFY_OTP]: validateOtpLoading,
  } = useSelector(
    (state) => state.shareOTPLoginReducer.loading,
  );

  const {
    [GENERATE_OTP]: generateApiSuccess,
  } = useSelector(
    (state) => state.shareOTPLoginReducer.apiSuccess,
  );

  const {
    [VERIFY_OTP]: validateApiSuccess,
  } = useSelector(
    (state) => state.shareOTPLoginReducer.apiSuccess,
  );

  const isInvalidOtp = !validateOtpLoading && validateApiSuccess && !isOptVerified;

  useEffect(() => {
    const countdown = setInterval(() => {
      setTimer((prevTimer) => (prevTimer > 0 ? prevTimer - 1 : 0));
    }, 1000);

    // cleanup function to clear the interval when the component unmounts
    return () => clearInterval(countdown);
  }, []);

  const handleSubmitOtp = () => {
    onSubmitOtp(otp);
  };

  const handleOtpChange = (e) => {
    const otpValue = e.target.value;
    // eslint-disable-next-line no-restricted-globals
    if (isNaN(otpValue)) {
      setIsValidOtpInput(false);
      return;
    }
    setIsValidOtpInput(true);
    setOtp(otpValue);
  };

  const handleResendOptButton = () => {
    if (timer > 0) return;
    setTimer(RESEND_OTP_COUNTER);
    onGenerateOtp(uuid);
  };

  return (
    <EVBox>
      <EVPaper className={classes.root}>
        <img
          className={classes.eagleViewLogo}
          alt="EagleView Logo"
          src={EagleViewLogo}
        />
        <EVDivider />
        {!generateOtpLoading && generateApiSuccess && availableAttempts > 0
          ? (
            <EVBox>
              <EVBox className={classes.signInBox}>
                <EVTypography
                  className={classes.signInText}
                  align="center"
                >
                  {t('shareLogin.signIn')}
                </EVTypography>
                {isInvalidOtp && (
                <EVTypography
                  className={classes.invalidOtpText}
                  align="center"
                >
                  {t('shareLogin.invalidOptRetries')}
                  {' '}
                  {availableAttempts}
                  /
                  {OTP_RETRY_LIMIT}
                </EVTypography>
                )}
                {!isValidOtpInput && (
                <EVTypography
                  className={classes.invalidOtpText}
                  align="center"
                >
                  {t('shareLogin.notANumber')}
                </EVTypography>
                )}
              </EVBox>
              <EVBox className={classes.otpInputContainer}>
                <EVTextField
                  InputProps={{
                    className: classes.adjustHeight,
                    classes: {
                      root: classes.otpInputRoot,
                    },
                    disableUnderline: true,
                  }}
                  // eslint-disable-next-line react/jsx-no-duplicate-props
                  inputProps={{
                    'data-testid': 'otpInput-text',
                  }}
                  data-testid="otpInput"
                  placeholder="Enter OTP"
                  size="small"
                  variant="standard"
                  onChange={(e) => handleOtpChange(e)}
                />
              </EVBox>
              <EVBox className={classes.otpDescriptionContainer}>
                <EVTypography
                  className={classes.otpDescriptionText}
                  align="center"
                >
                  {t('shareLogin.enterOtpDesc')}
                </EVTypography>
              </EVBox>
              <EVBox
                className={classes.validateButtonContainer}
              >
                <EVButton
                  data-testid="validateButton"
                  className={classes.validateButton}
                  variant="contained"
                  color="primary"
                  fullWidth
                  disabled={!isValidOtpInput || otp.length < 1 || validateOtpLoading}
                  size="large"
                  onClick={() => handleSubmitOtp()}
                >
                  {t('shareLogin.validateButton')}
                </EVButton>
              </EVBox>
              <EVBox
                className={classes.resendOptContainer}
              >
                <EVTypography
                  className={classes.resendOtpDesc}
                  align="center"
                >
                  <EVBox
                    component="span"
                    data-testid="resendOtp"
                    className={timer > 0 ? classes.disabledResendText : classes.resendText}
                    onClick={handleResendOptButton}
                  >
                    {t('shareLogin.resendOtp')}
                  </EVBox>
                  <EVBox component="span" data-testid="resendOtpTimer">
                    {' in '}
                    { timer}
                    {' '}
                    seconds
                  </EVBox>
                </EVTypography>
              </EVBox>
            </EVBox>
          )
          : (
            <EVBox>
              {!generateOtpLoading && availableAttempts === 0 && generateApiSuccess && (
              <EVBox className={classes.lockedDueToInvalidAttempts}>
                <EVTypography
                  className={classes.invalidOtpText}
                  align="center"
                >
                  {t('shareLogin.lockedDueToInvalidAttemptsMsg1')}
                  {' '}
                  {OTP_RETRY_LIMIT}
                  {t('shareLogin.lockedDueToInvalidAttemptsMsg2')}
                  {' '}
                  {remainingTimeFormatted}
                  {' '}
                  hours.
                </EVTypography>
              </EVBox>
              )}
              {
            generateOtpLoading && (
            <EVBox className={classes.lockedDueToInvalidAttempts}>
              <EVTypography
                className={classes.resendOtpDesc}
                align="center"
              >
                {t('shareLogin.generatingOtp')}
              </EVTypography>
            </EVBox>
            )
          }
              {
            !generateOtpLoading && !generateApiSuccess
             && (
             <EVBox className={classes.lockedDueToInvalidAttempts}>
               <EVTypography
                 className={classes.invalidOtpText}
                 align="center"
               >
                 {t('shareLogin.ErrorGeneratingOTP')}
               </EVTypography>
             </EVBox>
             )
          }
              <EVBox className={classes.customerService}>
                <EVTypography
                  className={classes.customerServiceText}
                  align="center"
                >
                  {t('shareLogin.contactCustomerService')}
                </EVTypography>
                <EVTypography
                  className={classes.customerServiceEmailText}
                  align="center"
                >
                  {t('shareLogin.customerServiceEmail')}
                </EVTypography>

              </EVBox>
            </EVBox>
          )}
      </EVPaper>
    </EVBox>
  );
};

OTPForm.propTypes = {
  uuid: PropTypes.string.isRequired,
  onSubmitOtp: PropTypes.func.isRequired,
  onGenerateOtp: PropTypes.func.isRequired,
};

export default OTPForm;
