import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { string, func } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import { EVBox } from '@eagleview/ev-comp-library';
import PhotosGenericDialog from '../photos-generic-dialog/PhotosGenericDialog';
import * as action from '../Photos.actions';
import PhotosFacetOverviewModalContent from './PhotosFacetOverviewModalContent';

const PhotosFacetOverviewModal = ({ facetOverviewImageName, orderId, clearSelectedFacetOverviewImage }) => {
  // local state
  const [dialogOpen, setDialogOpen] = useState(false);
  const [imageName, setImageName] = useState(facetOverviewImageName);

  // actions
  const dispatch = useDispatch();
  const fetchFullImage = (payload) => dispatch(action.fetchFullImageAction(payload));
  const resetFacetOverview = () => dispatch(action.resetFacetOverviewAction());
  const fetchPhotoAssets = (payload) => dispatch(action.fetchPhotoAssetsAction(payload));

  // selectors
  const facetOverviewImage = useSelector((state) => get(state, 'photosReducer.fullImages.facetOverview', {}));
  const facetOverviewImageMetadata = useSelector((state) => get(state, 'photosReducer.facetOverviewMeta', {}));
  const selectedStructureId = useSelector((state) => get(state, 'photosReducer.selectedStructureId', ''));

  const imageCorners = get(facetOverviewImageMetadata, 'corners', {});
  const rotationAngleMetadata = get(facetOverviewImageMetadata, 'rotation_angle', 0);
  // update the angle of -180 to 180 scale to 0 to 360 scale and rotate the image accordingly
  const rotationAngle = 360 - (rotationAngleMetadata < 0 ? rotationAngleMetadata + 360 : rotationAngleMetadata);

  useEffect(() => {
    if (!isEmpty(imageName)) {
      setDialogOpen(true);
    } else {
      setDialogOpen(false);
    }

    fetchFullImage({
      orderId,
      imageName,
      structureId: selectedStructureId,
      imageType: 'facetOverview',
    });

    fetchPhotoAssets({
      orderId,
      structureId: selectedStructureId,
      imageName: imageName.split('.')[0],
      requestType: 'facet_scans',
    });
  }, [imageName, selectedStructureId]);

  const handleDialogClose = () => {
    clearSelectedFacetOverviewImage();
    setDialogOpen(false);
    resetFacetOverview();
  };

  const setImageNameHandler = (name) => {
    setImageName(name);
  };

  return (
    <EVBox>
      <PhotosGenericDialog
        open={dialogOpen}
        handleClose={() => handleDialogClose()}
        imageName={facetOverviewImageName}
      >
        {!isEmpty(facetOverviewImageMetadata) && (
        <PhotosFacetOverviewModalContent
          orderId={orderId}
          imageData={facetOverviewImage}
          imageName={imageName}
          handleClose={() => handleDialogClose()}
          facetOverviewImageCorners={imageCorners}
          setImageNameToDisplay={setImageNameHandler}
          facetOverviewImageName={facetOverviewImageName}
          rotationAngle={rotationAngle}
        />
        )}
      </PhotosGenericDialog>
    </EVBox>
  );
};
PhotosFacetOverviewModal.propTypes = {
  facetOverviewImageName: string,
  orderId: string,
  clearSelectedFacetOverviewImage: func,
};

PhotosFacetOverviewModal.defaultProps = {
  facetOverviewImageName: '',
  orderId: '',
  clearSelectedFacetOverviewImage: () => {},
};

export default PhotosFacetOverviewModal;
