const keysReducer = (state, action) => {
  switch (action.type) {
    case 'set-key-down':
      return {
        ...state,
        mapping: {
          ...state.mapping,
          [action.key]: true,
        },
        received: [...state.received, action.key],
      };
    case 'set-key-up':
      return {
        ...state,
        mapping: {
          ...state.mapping,
          [action.key]: false,
        },
        received: state.received.filter((k) => k !== action.key),
      };
    case 'reset-keys':
      return { ...action.data };
    default:
      return state;
  }
};

export default keysReducer;
