import {
  string, node,
} from 'prop-types';
import { useSelector } from 'react-redux';
import { isEntitled } from 'utils/auth.utils';

const EntitledComponent = ({ children, entitlement }) => {
  const entitlements = useSelector((state) => state.entitleUserReducer.entitlements);

  return isEntitled(entitlements, entitlement) ? children : null;
};

EntitledComponent.propTypes = {
  children: node.isRequired,
  entitlement: string.isRequired,
};

export default EntitledComponent;
