import React, { useState, useEffect } from 'react';
import {
  EVInformationPanel,
  EVBackdrop,
} from '@eagleview/ev-comp-library';
import { useSelector } from 'react-redux';
import { func } from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import useStyles from './ReportGenerator.styles';
import ReportGeneratorTitle from './ReportGeneratorTitle';
import PreviousReports from './PreviousReports';
import ReportFooter from './ReportFooter';
import ReportDecisionMultiStructure from './ReportDecisionMultiStructure';

const ReportGenerator = ({ closeReportPanel }) => {
  // selectors
  const orderId = useSelector((state) => state.adjusterReducer.orderId);
  const sidePanelWidth = useSelector((state) => state.adjusterReducer.sidePanelWidth);
  const reportList = useSelector((state) => state.adjusterReducer.reportList);
  const selectedStructureId = useSelector((state) => state.adjusterReducer.selectedStructureId);
  const notes = useSelector((state) => state.adjusterReducer.roofNote);
  const riskShot = useSelector((state) => state.adjusterReducer.riskShot);
  const decisions = useSelector((state) => state.adjusterReducer.roofDecision);
  const structureRoofMapping = useSelector((state) => state.adjusterReducer.structureRoofMapping);
  const { structures = [] } = useSelector((state) => state.adjusterReducer.multiStructure);

  // local state
  const [infoPanelHeight, setInfoPanelHeight] = useState(0);

  const infoPanelWidth = isEmpty(selectedStructureId) ? 480 : 575;
  const styles = useStyles({ infoPanelWidth, sidePanelWidth, infoPanelHeight });
  const ref = React.createRef();

  useEffect(() => {
    const height = ref && ref.current.offsetHeight;
    setInfoPanelHeight(height);
  }, [reportList]);

  return (
    <>
      <EVInformationPanel
        ref={ref}
        titleComponent={<ReportGeneratorTitle />}
        className={styles.panel}
        enableExpandCollapseOnUI={false}
        height="auto"
        width={infoPanelWidth}
        onCloseInfoPanel={closeReportPanel}
        headerInputProps={{}}
      >
        <div className={styles.decisionPanel}>
          <ReportDecisionMultiStructure
            orderId={orderId}
            structures={structures}
            decisions={decisions}
            structureRoofMapping={structureRoofMapping}
            notes={notes}
            riskShot={riskShot}
            styles={styles}
          />
        </div>
        <PreviousReports styles={styles} />
        <ReportFooter orderId={orderId} styles={styles} />
      </EVInformationPanel>
      <EVBackdrop className={styles.backdrop} open onClick={closeReportPanel} />
    </>
  );
};

ReportGenerator.propTypes = {
  closeReportPanel: func.isRequired,
};

export default ReportGenerator;
