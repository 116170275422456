/* eslint-disable no-console */
import get from 'lodash/get';
import qs from 'query-string';
import { CANCEL_ERROR } from 'layout/manual-at-multi-structure/ManualAtAdjustment.constants';
import { getAuthToken, getShareToken } from './auth.utils';
import {
  LINK_EXPIRY_ERROR_CODE, ACCESS_ERROR_CODE, INVALID_UUID_ERROR_CODE,
} from '../layout/pageNotFound/constants';

/**
 * Error object for handling Server errors - status, errorCode, errorDescription
 */
export class ServerError extends Error {
  constructor(errorCode, errorDescription, status) {
    super(errorDescription);
    this.status = status || 500;
    this.errorCode = errorCode || 0;
  }
}

/**
 * Parse ServerError | TODO - handle different scenarios.
 * @param {ServerError} error
 */
export const parseServerError = (error) => ({
  type: 500,
  message: get(error, 'message', 'An unknown error has occurred.'),
  errorCode: get(error, 'errorCode', 0),
  ...(get(error, 'name', '') === CANCEL_ERROR && { name: CANCEL_ERROR }),
});

/**
 * Helper for handling http errors.
 * @param {HttpError} error
 */
export const handleHttpError = (error) => {
  if (error.response && error.response.data && error.response.data.errorCode) {
    throw new ServerError(error.response.data.errorCode, error.response.data.errorDescription, error.response.status);
  }
  throw new Error();
};

export const cancelError = () => {
  const error = new Error();
  error.name = CANCEL_ERROR;
  throw error;
};

export const isCancelError = (error) => !!(error.name === CANCEL_ERROR);

export const parseError = (error) => ({
  type: get(error, 'response.status', 500),
  message: get(error, 'response.statusText', 'An unknown error has occurred.'),
});

export const debugMode = window.location.search.includes('debug');
export const disableAutoFit = window.location.search.includes('disableAutoFit');

export const logger = (type, ...log) => {
  if (!debugMode) {
    return;
  }
  switch (type) {
    case 'warn':
      console.warn(...log);
      break;
    case 'debug': {
      console.debug(...log);
      break;
    }
    default:
      console.error(...log);
  }
};

export const errorHandlerService = (error, errorInfo, errorComponent) => {
  logger('warn', error);
  if (window.ev_vp && window.ev_vp.Logger) {
    const stackTrace = error && error.stack ? error.stack : JSON.stringify(error);
    window.ev_vp.Logger.error(
      `${error.name}: ${error.message}`,
      {
        stackTrace,
        errorInfo,
        errorComponent,
      },
    );
  }
};

export const isSharedUrl = () => window.location.origin === process.env.REACT_APP_ASSESS_SHAREDURL_APP_ORIGIN;

export const authParam = () => {
  if (isSharedUrl()) {
    return qs.stringify({ x_access_token: getShareToken() });
  }
  return qs.stringify({ access_token: getAuthToken() });
};

export const getAuthParam = (token) => {
  if (isSharedUrl()) {
    return qs.stringify({ x_access_token: getShareToken() });
  }
  return qs.stringify({ access_token: token });
};

export const filterSharedUrlEntitlements = (entitlements) => {
  if (isSharedUrl()) {
    const allowedEntitlements = process.env.REACT_APP_SHAREDURL_ENTITLEMENTS.split(':');
    return entitlements.filter((element) => allowedEntitlements.includes(element));
  }
  return entitlements;
};

export const checkCode = (validateCode) => {
  if (validateCode === INVALID_UUID_ERROR_CODE || validateCode === LINK_EXPIRY_ERROR_CODE || validateCode === ACCESS_ERROR_CODE) {
    return true;
  }
  return false;
};

export function downloadFile(url) {
  const link = document.createElement('a');
  link.href = url;
  link.target = '_blank';
  link.download = 'file.zip';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
