import React from 'react';
import {
  EVBox,
  EVCircularProgress,
  EVToolbarPanel,
  EVIconButton,
  EVMultiStructureIcon,
  EVBadge,
  useTheme,
} from '@eagleview/ev-comp-library';
import { useSelector } from 'react-redux';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import PhotosImageViewer from '../photos-imageViewer/PhotosImageViewer';
import useStyles from './PhotosGallery.styles';
import { FETCH_FULL_IMAGE } from '../photos.constant';

const PhotosGallery = ({ image }) => {
  // selectors
  const sidePanelWidth = useSelector((state) => state.adjusterReducer.sidePanelWidth);
  const loading = useSelector((state) => get(state, 'photosReducer.loading', false));

  // hooks
  const styles = useStyles({ sidePanelWidth });
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <>
      {
        loading[FETCH_FULL_IMAGE] ? (
          <EVBox
            data-testid="photosGalleryLoader"
            width="100%"
            height="100%"
            color="common.white"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <EVCircularProgress
              className={styles.circularProgressBar}
            />
          </EVBox>
        ) : <PhotosImageViewer image={image} />
        }
      <EVBox className={styles.orderToolbar}>
        <EVToolbarPanel
          displayType="vertical"
          iconStyles={{ height: 'auto', borderRadius: '4px' }}
          backgroundColor={theme.palette.common.white}
          style={{ borderRadius: '4px' }}
          border="1px solid #ccc"
        >
          <EVBadge badgeContent={0} color="error">
            <EVIconButton
              id="multiStructureMenu"
              // onClick={handleMultiStructureMenu}
              tooltip={t('photos-ui.selectStructure')}
            >
              <EVMultiStructureIcon color="inherit" />
            </EVIconButton>
          </EVBadge>
        </EVToolbarPanel>
      </EVBox>
    </>
  );
};

PhotosGallery.propTypes = {
  image: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default PhotosGallery;
