import React, { useEffect, useState } from 'react';
import {
  EVButton, EVTextField, EVBox, EVSwitch, EVTypography, EVBackdrop,
} from '@eagleview/ev-comp-library';
import PropTypes from 'prop-types';
import * as authUtils from 'utils/auth.utils';
import { useSelector, useDispatch } from 'react-redux';
import { FEATURE_ENTITLEMENTS } from 'layout/entitleUser/EntitleUser.constants';
import { useTranslation } from 'react-i18next';
import * as action from 'layout/adjuster/Adjuster.actions';
import * as selectors from 'layout/adjuster/workflow-panel/WorkflowPanel.selectors';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import useStyles from './AnomalyDescPanel.styles';
import { GET_ANOMALY_NOTES, INCLUDE_ANOMALY_NOTE } from '../Adjuster.constants';

const AnomalyDescPanel = ({
  coordinates, selectedAnomalyId, setShowAnomalyDescription, includeFacetAndDamageInReport,
}) => {
  const { t } = useTranslation();

  // actions
  const dispatch = useDispatch();
  const updateAnomalyNoteDetails = (payload) => dispatch(action.updateAnomalyNoteAction(payload));
  const addAnomalyNoteDetails = (payload) => dispatch(action.addAnomalyNoteAction(payload));
  const includeAnomalyNoteDetails = (payload) => dispatch(action.includeAnomalyNoteAction(payload));

  // selectors
  const entitlements = useSelector((state) => state.entitleUserReducer.entitlements);
  const anomalyNote = useSelector((state) => state.adjusterReducer.anomalyNotes)[0];
  const loading = useSelector((state) => state.adjusterReducer.loading);
  const orderId = useSelector(selectors.orderIdSelector);

  // local state
  const [anomalyNoteDescription, setAnomalyNoteDescription] = useState('');
  const [anomalyNoteInclude, setAnomalyNoteInclude] = useState(false);

  const editAllowed = authUtils.isEntitled(entitlements, FEATURE_ENTITLEMENTS.MANAGE_DECISIONS);
  const top = get(coordinates, 'top', 0);
  const left = get(coordinates, 'left', 0);
  const noteId = get(anomalyNote, 'noteID', '');
  const fetchingNotes = loading[GET_ANOMALY_NOTES];
  const includingNotes = loading[INCLUDE_ANOMALY_NOTE];

  const classes = useStyles({ top, left });

  useEffect(() => {
    setAnomalyNoteDescription(get(anomalyNote, 'note', ''));
    setAnomalyNoteInclude(get(anomalyNote, 'includeInReport', false));
  }, [anomalyNote]);

  const handleDescriptionChange = (value) => {
    if (value.length <= 100) {
      setAnomalyNoteDescription(value);
    }
  };

  const handleAnomalyNoteSubmit = () => {
    if (!editAllowed) {
      setShowAnomalyDescription(false);
      return;
    }
    if (noteId !== '') {
      updateAnomalyNoteDetails({
        orderId,
        anomalyId: selectedAnomalyId,
        noteId,
        data: {
          note: anomalyNoteDescription,
          includeInReport: anomalyNoteInclude,
        },
      });
      if (!isEmpty(anomalyNoteDescription) && anomalyNoteInclude) {
        includeFacetAndDamageInReport();
      }
    } else if (!isEmpty(anomalyNoteDescription)) {
      addAnomalyNoteDetails({
        orderId,
        anomalyId: selectedAnomalyId,
        data: {
          note: anomalyNoteDescription,
          includeInReport: anomalyNoteInclude,
        },
      });
      includeFacetAndDamageInReport();
    }
    setShowAnomalyDescription(false);
  };

  const handleIncludeToggle = () => {
    if (noteId) {
      setAnomalyNoteInclude(!anomalyNoteInclude);
      includeAnomalyNoteDetails(
        {
          orderId,
          anomalyId: selectedAnomalyId,
          noteId,
          data: {
            includeInReport: !anomalyNoteInclude,
          },
        },
      );
    }
  };

  return (
    <>
      <EVBackdrop className={classes.descPanelBackDrop} open onDoubleClick={() => { handleAnomalyNoteSubmit(); }} />
      <EVBox className={classes.container}>
        <EVBox className={classes.header}>
          <EVBox className={classes.switchContainer}>
            <EVSwitch
              color="primary"
              disabled={!editAllowed || fetchingNotes || includingNotes || isEmpty(noteId)}
              checked={anomalyNoteInclude}
              onChange={handleIncludeToggle}
            />
            <EVTypography variant="body2">{anomalyNoteInclude ? t('included') : t('notIncluded')}</EVTypography>
          </EVBox>
          {editAllowed && (
          <EVButton
            className={classes.saveBtn}
            data-testid="confirmBtn"
            color="primary"
            disabled={fetchingNotes || includingNotes || (isEmpty(noteId) && isEmpty(anomalyNoteDescription))}
            onClick={() => { handleAnomalyNoteSubmit(); }}
          >
            {t('annotatioonDescPanel.save')}
          </EVButton>
          )}
        </EVBox>
        <EVTextField
          multiline
          rows={3}
          className={classes.notesEditField}
          disabled={!editAllowed || fetchingNotes}
          data-testid="input-box"
          inputProps={{
            classes: {
              root: classes.notesEditFieldInput,
            },
            'data-testid': 'input-box-text',
          }}
          value={anomalyNoteDescription || ''}
          placeholder={editAllowed ? t('anomalyDescPanel.descPlaceholderEditAllowed')
            : t('anomalyDescPanel.descPlaceholderEditNotAllowed')}
          onChange={(e) => { handleDescriptionChange(e.target.value); }}
          variant="outlined"
          fullWidth
        />
      </EVBox>
    </>
  );
};

AnomalyDescPanel.propTypes = {
  coordinates: PropTypes.shape({
    top: PropTypes.number,
    left: PropTypes.number,
  }).isRequired,
  selectedAnomalyId: PropTypes.string.isRequired,
  setShowAnomalyDescription: PropTypes.func.isRequired,
  includeFacetAndDamageInReport: PropTypes.func.isRequired,
};

export default AnomalyDescPanel;
