import React from 'react';
import { EVCropOriginalOutlinedIcon, EVTooltip, EVTypography } from '@eagleview/ev-comp-library';
import { useTranslation } from 'react-i18next';
import { TOOLTIP_DELAY } from 'constants.js';
import useStyles from './ExportImageryPanel.styles';

const ExportImageryPanelTitle = () => {
  const { t } = useTranslation();
  const styles = useStyles();
  return (
    <EVTooltip title={t('exportImagery.title')} enterDelay={TOOLTIP_DELAY}>
      <div className={styles.titleContainer}>
        <EVCropOriginalOutlinedIcon fontSize="small" data-testid="export-imagery-icon" className={styles.titleIcon} />
        <EVTypography variant="h4" data-testid="export-imagery-title">{t('exportImagery.title')}</EVTypography>
      </div>
    </EVTooltip>
  );
};

export default ExportImageryPanelTitle;
