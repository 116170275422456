import React from 'react';
import { EVBox, useTheme, EVTypography } from '@eagleview/ev-comp-library';
import { useTranslation } from 'react-i18next';
import { string, number, oneOfType } from 'prop-types';

const StatusbarText = ({ text, variant }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  switch (variant) {
    case 'grey':
      return (
        <EVBox component="span" display="flex" mr={theme.evSpacing['ev-spacing--8']}>
          <EVBox color={theme.evPalette.charcoal[400]} clone>
            <EVTypography variant="overline">{t(text)}</EVTypography>
          </EVBox>
        </EVBox>
      );
    case 'black':
      return (
        <EVBox color={theme.palette.common.black} clone>
          <EVTypography component="span" variant="body2">
            {t(text)}
          </EVTypography>
        </EVBox>
      );
    default:
      return null;
  }
};

StatusbarText.propTypes = {
  text: oneOfType([number, string]).isRequired,
  variant: string,
};

StatusbarText.defaultProps = {
  variant: 'grey',
};

export default StatusbarText;
