import { makeStyles } from '@eagleview/ev-comp-library';

export default makeStyles((theme) => ({
  toolbarButton: {
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  accessoryToolbarButton: {
    top: theme.spacing(10),
  },
  accessoryToolbarButtonMultiStructure: {
    top: theme.spacing(18),
  },
  toolbarWrapper: {
    position: 'absolute',
    bottom: theme.spacing(4),
    right: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  toolbarPanel: {
    border: '1px solid #ccc',
    borderRadius: '5px',
    backgroundColor: theme.palette.common.white,
  },
  toolbarPanelSpacing: {
    marginTop: '10px',
  },
  buttonWrapper: {
    position: 'absolute',
    right: theme.spacing(9.5),
    top: theme.spacing(2),
  },
  deleteButton: {
    backgroundColor: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.common.white,
    },
  },
  multiStructureToolBar: {
    position: 'absolute',
    top: theme.spacing(8),
  },
  multiStructureMenu: {
    position: 'absolute',
    top: theme.spacing(8),
  },
}));
