import { makeStyles } from '@eagleview/ev-comp-library';

export default makeStyles((theme) => ({
  container: {
    display: ({ editAllowed, description }) => ((editAllowed || description) ? 'flex' : 'none'),
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: theme.typography.pxToRem(0),
    top: ({ top }) => top || 0,
    left: ({ left }) => left || 0,
    position: 'absolute',
    width: theme.typography.pxToRem(320),
    background: theme.evPalette.salt[100],
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.14), 0px 3px 4px rgba(0, 0, 0, 0.12), 0px 1px 5px rgba(0, 0, 0, 0.2)',
    borderRadius: theme.typography.pxToRem(4),
    zIndex: 6,
  },
  header: {
    width: '100%',
    height: '44px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    boxShadow: 'inset 0px -1px 0px #DEDFE2',
    borderRadius: '6px 6px 0px 0px',
  },
  switchContainer: {
    width: '150px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  saveBtn: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: '500',
    color: theme.evPalette.ocean[600],
    lineHeight: theme.typography.pxToRem(19),
    textTransform: 'capitalize',
  },
  notesEditField: {
    width: '100%',
    alignItems: 'center',
    fontSize: theme.typography.pxToRem(14),
    fontWeight: '400',
    lineHeight: theme.typography.pxToRem(19),
  },
  notesEditFieldInput: {
    borderRadius: 0,
  },
}));
