import React, { useState } from 'react';
import {
  EVButton,
  EVInformationPanel,
  EVInputLabel,
  EVBox,
  EVBackdrop,
  useTheme,
} from '@eagleview/ev-comp-library';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { func } from 'prop-types';
import { EXPORT_IMAGES } from 'layout/adjuster/Adjuster.constants';
import { isEntitled } from 'utils/auth.utils';
import { FEATURE_ENTITLEMENTS } from 'layout/entitleUser/EntitleUser.constants';
import * as action from 'layout/adjuster/Adjuster.actions';
import useStyles from './ExportImageryPanel.styles';
import ExportImageryPanelTitle from './ExportImageryPanelTitle';
import ExportRadio from './ExportRadio/ExportRadio';

/**
 * Component used to select the type of imagery to be exported and export them
 */
const ExportImageryPanel = ({ closeExportImageryPanel }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const exportPanelHeight = theme.spacing(33.75);
  const exportPanelWidth = theme.spacing(63);
  const styles = useStyles({ exportPanelHeight, exportPanelWidth });

  // selectors
  const orderId = useSelector((state) => state.adjusterReducer.orderId);
  const entitlements = useSelector((state) => state.entitleUserReducer.entitlements);

  // local state
  const [radioSelected, setRadioSelected] = useState(EXPORT_IMAGES.reportIncludedImages);

  // actions
  const dispatch = useDispatch();
  const exportAllImages = (payload) => dispatch(action.exportAllImagesAction(payload));
  const exportReportIncludedImages = (payload) => dispatch(action.exportReportIncludedImagesAction(payload));

  const handleRadioSelectChange = (event) => {
    setRadioSelected(event.target.value);
  };

  const exportImagery = () => {
    if (isEntitled(entitlements, FEATURE_ENTITLEMENTS.MANAGE_REPORTS) || isEntitled(entitlements, FEATURE_ENTITLEMENTS.EXPORT_IMAGERY_INTERNAL)) {
      if (radioSelected === EXPORT_IMAGES.reportIncludedImages) {
        exportReportIncludedImages(orderId);
      } else if (radioSelected === EXPORT_IMAGES.allImages) {
        exportAllImages(orderId);
      }
    }
    closeExportImageryPanel();
  };

  return (
    <>
      <EVInformationPanel
        className={styles.infoPanel}
        titleComponent={<ExportImageryPanelTitle />}
        enableExpandCollapseOnUI={false}
        onCloseInfoPanel={closeExportImageryPanel}
        width={exportPanelWidth}
        height={exportPanelHeight}
        headerInputProps={{}}
      >
        <EVBox className={styles.panelContainer}>
          <EVInputLabel className={styles.radioHeader}>{t('exportImagery.exportOptions')}</EVInputLabel>
          <ExportRadio
            checked={radioSelected === EXPORT_IMAGES.reportIncludedImages}
            handleRadioSelectChange={handleRadioSelectChange}
            radioValue={EXPORT_IMAGES.reportIncludedImages}
            radioLabel={t('exportImagery.exportReportIncludedImages')}
          />
          <ExportRadio
            checked={radioSelected === EXPORT_IMAGES.allImages}
            handleRadioSelectChange={handleRadioSelectChange}
            radioValue={EXPORT_IMAGES.allImages}
            radioLabel={t('exportImagery.exportAllImages')}
          />
          <EVBox className={styles.footer}>
            <EVButton id="exportImagery" className={styles.exportButton} color="primary" variant="contained" onClick={exportImagery}>
              {t('exportImagery.export')}
            </EVButton>
          </EVBox>
        </EVBox>
      </EVInformationPanel>
      <EVBackdrop className={styles.backdrop} open onClick={closeExportImageryPanel} />
    </>
  );
};

ExportImageryPanel.propTypes = {
  closeExportImageryPanel: func.isRequired,
};

export default ExportImageryPanel;
