import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import {
  EVIconButton,
  EVToolbarPanel,
  EVMenuIcon,
  EVAdd,
  EVRemove,
  EVFullSize,
  EVCropFree,
  EVRotateRight,
  EVSvgIcon,
  EVCropSquareOutlinedIcon,
  EVDeleteIcon,
  EVMenu,
  EVMenuItem,
  EVAccessoryTool,
  useTheme,
  EVBadge,
  EVCompass,
  EVMultiStructureIcon,
} from '@eagleview/ev-comp-library';
import {
  func, bool, string, number,
} from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import EntitledComponent from 'components/EntitledComponent';
import { isSharedUrl } from 'utils/utils';
import { FEATURE_ENTITLEMENTS, ASSESS_DIGITAL_DELIVERY } from 'layout/entitleUser/EntitleUser.constants';
import { isEntitled } from 'utils/auth.utils';
import { ROLE_MAP } from 'layout/adjuster/Adjuster.constants';
import get from 'lodash/get';
import useStyles from './ImagePreviewerToolbar.styles';
import MultiStructureMenu from '../adjuster-toolbar/multistructure-menu';
import * as action from '../Adjuster.actions';
import { mapRotationToCompassAngle } from '../Adjuster.utils';
import ConfirmDialog from '../confirm-complete-dialog/ConfirmDialog';

const noop = () => {
  // This is an empty block
};
const ImagePreviewerToolbar = (props) => {
  // actions
  const dispatch = useDispatch();
  const updateStructureVisitStatus = (payload) => dispatch(action.updateStructureVisitStatus(payload));

  // selectors
  const entitlements = useSelector((state) => state.entitleUserReducer.entitlements);
  const orderCompleted = useSelector((state) => state.adjusterReducer.orderCompleted);
  const { isMultiStructure = false, structures: structuresList = [] } = useSelector((state) => state.adjusterReducer.multiStructure);
  const isDigitalDeliveryEnabled = useSelector((state) => get(
    state.entitleUserReducer.featureFlags,
    ASSESS_DIGITAL_DELIVERY,
    false,
  ));

  // local state
  const [menuOpen, setMenuOpen] = useState();
  const [toggleAnnotationType, setToggleAnnotationType] = useState('');
  const [multiStructureMenuOpen, setMultiStructureMenuOpen] = useState();
  const [unvisitedStructureCount, setUnvisitedStructureCount] = useState(0);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

  // utility hooks
  const theme = useTheme();
  const { t } = useTranslation();
  const classes = useStyles();

  const {
    completeAndExit,
    exitOnly,
    exportImagery,
    generateReport,
    shareReport,
    handleZoomIn,
    handleZoomOut,
    handleFullSize,
    handleRotate,
    handleFitToWindow,
    handleDrawAnnotation,
    disableZoomIn,
    disableZoomOut,
    disableRotate,
    disableFullSize,
    disableFitToWindow,
    selectedAnnotationType,
    setAnnotationSelected,
    handleDelete,
    showDelete,
    showAccessory,
    resetToTrackMode,
    resetUnsavedAssets,
    disableAccessory,
    orderId,
    isGroundCaptured,
    imageOrientation,
    showCompass,
    handleRotateCompass,
  } = props;

  const showShareAndGenerateReportMenuItems = isEntitled(entitlements, FEATURE_ENTITLEMENTS.VIEW_DECISIONS)
    || isEntitled(entitlements, FEATURE_ENTITLEMENTS.VIEW_NOTES) || isEntitled(entitlements, FEATURE_ENTITLEMENTS.VIEW_REPORTS);
  // eslint-disable-next-line no-nested-ternary
  const role = isEntitled(entitlements, FEATURE_ENTITLEMENTS.MANAGE_DECISIONS) ? ROLE_MAP.MANAGE_DECISIONS
    : isEntitled(entitlements, FEATURE_ENTITLEMENTS.MANAGE_QC_DECISIONS) ? ROLE_MAP.MANAGE_QC_DECISIONS : '';

  const showMultiStructureMenu = isMultiStructure && structuresList.length > 1
    && (isEntitled(entitlements, FEATURE_ENTITLEMENTS.VIEW_DECISIONS)
    || isEntitled(entitlements, FEATURE_ENTITLEMENTS.MANAGE_DECISIONS)
    || isEntitled(entitlements, FEATURE_ENTITLEMENTS.VIEW_ASSESS_ALL_ORGANIZATION_ORDERS));

  const handleMenu = (event) => {
    // eslint-disable-next-line no-extra-boolean-cast
    if (!!menuOpen) {
      setMenuOpen(null);
    } else {
      setMenuOpen(event.currentTarget);
    }
  };

  // If the structure is not captured, no need to check visited status, simply mark as visited.
  const isVisitedOrNotCaptured = (structure) => (!(get(structure, 'isCaptured', true) || get(structure, 'isGroundCaptured', true))
                  || get(structure, `meta.visited.${role}`, false));

  const updateCount = () => {
    let count = 0;
    structuresList.forEach((structure) => {
      if (!isVisitedOrNotCaptured(structure)) {
        count += 1;
      }
    });
    setUnvisitedStructureCount(count);
  };

  useEffect(() => {
    if (!!toggleAnnotationType && toggleAnnotationType === selectedAnnotationType) {
      resetToTrackMode();
      setToggleAnnotationType('');
    }
  }, [toggleAnnotationType]);

  const exportImageryClick = () => {
    exportImagery();
    setMenuOpen(null);
  };

  const generateReportClick = () => {
    generateReport();
    setMenuOpen(null);
  };

  const shareReportClick = () => {
    shareReport();
    setMenuOpen(null);
  };

  const markVisited = (structureId) => {
    updateStructureVisitStatus({
      orderID: orderId,
      structureID: structureId,
      userRole: role,
      data: {
        visited: true,
      },
    });
  };

  const handleMultiStructureMenu = (event) => {
    if (menuOpen) {
      setMultiStructureMenuOpen(null);
    } else {
      setMultiStructureMenuOpen(event.currentTarget);
    }
  };

  useEffect(() => {
    if (structuresList.length > 0 && showMultiStructureMenu) {
      updateCount();
    }
  }, [JSON.stringify(structuresList)]);

  const boxAnnotationIcon = { name: t('imagepreview.toolbar.boxAnnotation'), icon: EVCropSquareOutlinedIcon };

  const tagAccessory = () => {
    if (selectedAnnotationType !== t('imagepreview.toolbar.tagAccessory')) {
      setAnnotationSelected('Point');
      handleDrawAnnotation(t('imagepreview.toolbar.tagAccessory'), 'drawPoint');
    } else {
      resetUnsavedAssets();
    }
    setToggleAnnotationType(selectedAnnotationType);
  };

  const annotationAction = (annotation) => {
    if (annotation === t('imagepreview.toolbar.boxAnnotation')) {
      setAnnotationSelected('Box');
      handleDrawAnnotation(annotation, 'drawBox');
      setToggleAnnotationType(selectedAnnotationType);
    }
  };

  return (
    <div>
      <div className={classes.toolbarButton}>
        <EVToolbarPanel
          id="galleryMenyToolbar"
          displayType="vertical"
          iconStyles={{ height: 'auto', borderRadius: '4px' }}
          className={classes.toolbarPanel}
        >
          <EVIconButton
            id="galleryMenu"
            onClick={handleMenu}
            tooltip={t('galleryToolbarTooltip.menu')}
          >
            <EVMenuIcon />
          </EVIconButton>
        </EVToolbarPanel>

        {showMultiStructureMenu && !isEmpty(structuresList)
          && (
            <>
              <EVToolbarPanel
                displayType="vertical"
                iconStyles={{ height: 'auto', borderRadius: '4px' }}
                backgroundColor={theme.palette.common.white}
                style={{ borderRadius: '4px' }}
                border="1px solid #ccc"
                className={classes.multiStructureToolBar}
              >
                <EVBadge badgeContent={unvisitedStructureCount} color="error">
                  <EVIconButton
                    id="applicationMenu"
                    onClick={handleMultiStructureMenu}
                    tooltip={t('adjusterToolbarTooltip.menu')}
                  >
                    <EVMultiStructureIcon color={multiStructureMenuOpen ? 'primary' : 'inherit'} />
                  </EVIconButton>
                </EVBadge>
              </EVToolbarPanel>
              <MultiStructureMenu
                menuOpenEl={multiStructureMenuOpen}
                structures={structuresList}
                setMenuOpen={setMultiStructureMenuOpen}
                isVisitedOrNotCaptured={isVisitedOrNotCaptured}
                markVisited={markVisited}
              />
            </>
          )}
        <EVMenu
          anchorEl={menuOpen}
          keepMounted
          open={!!menuOpen}
          onClose={() => setMenuOpen(null)}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          {!orderCompleted && (
            <EntitledComponent entitlement={FEATURE_ENTITLEMENTS.MANAGE_DECISIONS}>
              <EVMenuItem
                id="galleryCompleteAndExit"
                onClick={() => setConfirmDialogOpen(true)}
                disabled={unvisitedStructureCount > 0}
              >
                {t('galleryMenu.completeAndExit')}

              </EVMenuItem>
            </EntitledComponent>
          )}
          <EVMenuItem id="galleryExitOnly" onClick={exitOnly}>{t('galleryMenu.exitOnly')}</EVMenuItem>
          {isDigitalDeliveryEnabled && showShareAndGenerateReportMenuItems && !isSharedUrl() && (
            <EVMenuItem id="shareReportMenuItem" onClick={shareReportClick}>{t('galleryMenu.shareReport')}</EVMenuItem>
          )}
          { showShareAndGenerateReportMenuItems && (
            <EVMenuItem id="galleryGenerateReportMenuItem" onClick={generateReportClick}>{t('galleryMenu.generateReport')}</EVMenuItem>
          )}
          <EntitledComponent entitlement={FEATURE_ENTITLEMENTS.MANAGE_REPORTS}>
            <EVMenuItem id="galleryExportImageryMenuItem" onClick={exportImageryClick}>{t('galleryMenu.exportImagery')}</EVMenuItem>
          </EntitledComponent>
        </EVMenu>
      </div>
      <EntitledComponent entitlement={FEATURE_ENTITLEMENTS.MANAGE_DECISIONS}>
        {isGroundCaptured && showAccessory && (
        <div className={clsx(
          classes.toolbarButton,
          showMultiStructureMenu ? classes.accessoryToolbarButtonMultiStructure : classes.accessoryToolbarButton,
        )}
        >
          <EVToolbarPanel
            id="accessoryToolbar"
            displayType="vertical"
            iconStyles={{ height: 'auto', borderRadius: '4px' }}
            className={classes.toolbarPanel}
          >
            <EVIconButton
              id="accessory"
              key={t('imagepreview.toolbar.tagAccessory')}
              onClick={() => tagAccessory()}
              highlight={selectedAnnotationType === t('imagepreview.toolbar.tagAccessory')}
              disabled={disableAccessory}
              tooltip={t('imagepreview.toolbar.tagAccessory')}
            >
              <EVAccessoryTool viewBox="-2 -2 24 24" />
            </EVIconButton>
          </EVToolbarPanel>
        </div>
        )}
      </EntitledComponent>
      <div className={classes.toolbarWrapper}>
        { showCompass && (
        <div>
          <EVCompass
            updatedAngle={mapRotationToCompassAngle(imageOrientation)}
            onAngleUpdate={handleRotateCompass}
            compassWidth="70"
            knobSize={20}
            knobRadius={10}
            showNorthIndicator
          />
        </div>
        )}
        <div className={classes.toolbarPanelSpacing}>
          <EntitledComponent entitlement={FEATURE_ENTITLEMENTS.MANAGE_DECISIONS}>
            {isGroundCaptured && !orderCompleted && (
              <EVToolbarPanel
                id="annotation-controls"
                displayType="vertical"
                iconStyles={{ height: 'auto', borderRadius: '4px' }}
                className={classes.toolbarPanel}
              >
                { !showDelete && (
                  <EVIconButton
                    id={`${boxAnnotationIcon.name.replace(/\s+/g, '')}IconButton`}
                    key={boxAnnotationIcon.name}
                    tooltip={boxAnnotationIcon.name}
                    highlight={selectedAnnotationType === boxAnnotationIcon.name}
                    onClick={() => annotationAction(boxAnnotationIcon.name)}
                  >
                    <EVSvgIcon viewBox="0 0 24 24" component={boxAnnotationIcon.icon} />
                  </EVIconButton>
                )}

                {showDelete && (
                <EVIconButton
                  id="deleteBoxAnnotation"
                  color="secondary"
                  data-testid="deleteBoxAnnotation"
                  tooltip={t('adjusterToolbarTooltip.deleteBoxAnnotation')}
                  onClick={!orderCompleted && handleDelete}
                >
                  <EVDeleteIcon />
                </EVIconButton>
                )}
              </EVToolbarPanel>
            )}
          </EntitledComponent>
        </div>
        { isGroundCaptured && (
        <>
          <div className={classes.toolbarPanelSpacing}>
            <EVToolbarPanel
              id="resize-controls"
              displayType="vertical"
              iconStyles={{ height: 'auto', borderRadius: 0 }}
              className={classes.toolbarPanel}
            >
              {!disableFullSize && <EVIconButton key="1" tooltip="Full Size" onClick={handleFullSize}><EVFullSize /></EVIconButton> }
              {!disableFitToWindow && <EVIconButton key="2" tooltip="Fit to Window" onClick={handleFitToWindow}><EVCropFree /></EVIconButton> }
              {!disableRotate
            && (
              <EntitledComponent entitlement={FEATURE_ENTITLEMENTS.MANAGE_DECISIONS}>
                {!orderCompleted && (
                  <EVIconButton key="3" tooltip="Rotate" onClick={handleRotate}>
                    <EVRotateRight />
                  </EVIconButton>
                )}
              </EntitledComponent>
            )}
            </EVToolbarPanel>
          </div>
          <div className={classes.toolbarPanelSpacing}>
            <EVToolbarPanel
              id="zoom-controls"
              displayType="vertical"
              iconStyles={{ height: 'auto', borderRadius: 0 }}
              className={classes.toolbarPanel}
            >
              {!disableZoomIn && <EVIconButton key="1" tooltip="Zoom In" onClick={handleZoomIn}><EVAdd /></EVIconButton> }
              {!disableZoomOut && <EVIconButton key="2" tooltip="Zoom out" onClick={handleZoomOut}><EVRemove /></EVIconButton> }
            </EVToolbarPanel>
          </div>
        </>
        )}
      </div>
      <ConfirmDialog
        open={confirmDialogOpen}
        handleClose={() => setConfirmDialogOpen(false)}
        handleSaveAndExit={() => {
          completeAndExit();
          setConfirmDialogOpen(false);
        }}
      />
    </div>
  );
};

ImagePreviewerToolbar.propTypes = {
  /**
   * Callback on clicking Complete & Exit menu item
   */
  completeAndExit: func.isRequired,
  /**
   * Callback on clicking Exit Only menu item
   */
  exitOnly: func.isRequired,
  /**
   * Callback on clicking Export Imagery menu item
   */
  exportImagery: func.isRequired,
  /**
   * Callback on clicking Generate Report menu item
   */
  generateReport: func.isRequired,
  /**
   * Callback on clicking Share Report menu item
   */
  shareReport: func.isRequired,
  /**
   * Callback on clicking Zoom In button, sets zoom value
   */
  handleZoomIn: func,
  /**
   * Callback on clicking Zoom Out button, sets zoom value
   */
  handleZoomOut: func,
  /**
   * Callback on clicking Fit To Window button, fits image to window
   */
  handleFitToWindow: func,
  /**
   * Callback on clicking Rotate button, sets degree roatation
   */
  handleRotate: func,
  /**
   * Callback on clicking Full Size button, sets image to its full size
   */
  handleFullSize: func,
  /**
   * Callback on clicking draw annotation (box and arrow)
   */
  handleDrawAnnotation: func,
  /**
   * set annotation type selected from toolbar
   */
  setAnnotationSelected: func,
  /**
   * Option to remove the zoom in button from the toolbar
   */
  disableZoomIn: bool,
  /**
   * Option to remove the zoom out button from the toolbar
   */
  disableZoomOut: bool,
  /**
   * Option to remove the rotate button from the toolbar
   */
  disableRotate: bool,
  /**
   * Option to remove the full size button from the toolbar
   */
  disableFullSize: bool,
  /**
   * Option to remove the fit to window button from the toolbar
   */
  disableFitToWindow: bool,
  /**
   * Option to highlight annotation toolbar
   */
  selectedAnnotationType: string,
  /**
   * Callback on clicking delete button
   */
  handleDelete: func,
  /**
   * Callback when annotation tool is deselected
   */
  resetToTrackMode: func,
  /**
   * flag to hide delete button
   */
  showDelete: bool,
  /**
   * flag to hide accessory button
   */
  showAccessory: bool,
  /**
   * Callback to reset the accessories that are not saved
   */
  resetUnsavedAssets: func,
  /**
   * flag to hide accessory panel
   */
  disableAccessory: bool,
  orderId: string.isRequired,
  isGroundCaptured: bool.isRequired,
  imageOrientation: number,
  showCompass: bool,
  handleRotateCompass: func,
};

ImagePreviewerToolbar.defaultProps = {
  handleZoomIn: noop,
  handleZoomOut: noop,
  handleFitToWindow: noop,
  handleFullSize: noop,
  handleRotate: noop,
  handleRotateCompass: noop,
  handleDrawAnnotation: noop,
  setAnnotationSelected: noop,
  disableZoomIn: false,
  disableZoomOut: false,
  disableRotate: false,
  disableFullSize: false,
  disableFitToWindow: false,
  selectedAnnotationType: '',
  handleDelete: noop,
  showDelete: false,
  showAccessory: false,
  resetToTrackMode: noop,
  resetUnsavedAssets: noop,
  disableAccessory: false,
  imageOrientation: 0,
  showCompass: false,
};
export default ImagePreviewerToolbar;
