import {
  all, call, put, takeLatest, select,
} from 'redux-saga/effects';
import get from 'lodash/get';
import * as util from 'utils/utils';
import { isEntitled } from 'utils/auth.utils';
import { FEATURE_ENTITLEMENTS } from 'layout/entitleUser/EntitleUser.constants';
import * as action from './ManualAtAdjustment.actions';
import * as api from './ManualAtAdjustment.api';
import * as utils from './ManualAtAdjustment.utils';
import * as constants from './ManualAtAdjustment.constants';
import * as adjusterUtil from '../adjuster/Adjuster.utils';

const lngFromMercatorX = (x) => x * 360 - 180;
const latFromMercatorY = (y) => {
  const y2 = 180 - y * 360;
  return (360 / Math.PI) * Math.atan(Math.exp(y2 * (Math.PI / 180))) - 90;
};

const getCornersFromImage = (width, height) => {
  const max = Math.max(width, height);
  const maxTiles = Math.ceil(max / 256);
  const maxZoom = Math.log2(maxTiles);
  const maxPixel = 2 ** maxZoom * 256;
  const brLon = lngFromMercatorX(width / maxPixel);
  const brLat = latFromMercatorY(height / maxPixel);
  return {
    ul: { lon: -180, lat: 85 },
    ur: { lon: brLon, lat: 85 },
    br: { lon: brLon, lat: brLat },
    bl: { lon: -180, lat: brLat },
  };
};

export const mapImagesPayload = (images, blobs) => images.map((image) => {
  const currBlob = blobs.find((blob) => blob.imageName === image.image_name);
  const corners = getCornersFromImage(4056, 3040);
  const polygon = adjusterUtil.getPolygonFromCorners(corners);
  if (currBlob.error) return false;
  return {
    ...image,
    url: window.URL.createObjectURL(new Blob([currBlob.data])),
    width: 4056,
    height: 3040,
    corners,
    polygon,
    available: true,
  };
}).filter(Boolean);

export function* fetchImagesSaga({ payload }) {
  try {
    const images = yield call(api.fetchImagesApi, payload);
    yield put(action.setInitialImagesDetails(images));
    const blobs = yield all(images.map((image) => call(api.fetchRoofImageApi, { orderId: payload, imageName: image.image_name })));
    const imagesWithUrl = yield call(mapImagesPayload, images, blobs);
    const selectedImagesList = yield select((state) => state.manualAtAdjustmentReducerV2.selectedImagesList);
    yield put(action.fetchImagesSuccessAction(imagesWithUrl));
    if (selectedImagesList.length > 0) {
      yield put(action.setActiveImageAction(imagesWithUrl.find((img) => img.image_name === selectedImagesList[0])));
    }
  } catch (error) {
    yield put(action.fetchImagesFailureAction(util.parseServerError(error)));
  }
}

export function* fetchOrthoPolygonSaga({ payload }) {
  try {
    const response = yield call(api.fetchOrthoPolygon, payload);
    const pairings = yield select((state) => get(state, 'manualAtAdjustmentReducerV2.orderDetails.pairing', []));
    const vertices = utils.featureCollectionToPoints(response, pairings);
    const completedVertices = utils.mapCompletedVertices(pairings);
    yield put(
      action.fetchOrthoPolygonSuccessAction({
        vertices,
        polygon: utils.featureCollectionToPolygon(response),
        coordinatePairings: utils.mapCoordinatePairings(pairings, vertices),
        completedVertices,
      }),
    );
    yield put(action.setViewAction(utils.getCenterOfAllVertices(response)));
    yield put(action.setInitialViewAction(utils.getCenterOfAllVertices(response)));
  } catch (error) {
    yield put(action.fetchOrthoPolygonFailureAction(util.parseServerError(error)));
  }
}

export function* fetchOrthoImageSaga({ payload }) {
  try {
    const response = yield call(api.fetchOrthoImageMeta, payload);
    const orthoImage = yield call(api.fetchOrthoImage, payload);

    yield put(action.fetchOrthoImageSuccessAction(utils.orthoImageToAsset(response, orthoImage)));
  } catch (error) {
    yield put(action.fetchOrthoImageFailureAction(util.parseServerError(error)));
  }
}

export function* fetchImagesAndDetailsSaga({ payload }) {
  try {
    yield put(action.setHideUiAction(false));
    yield put(action.fetchImagesAction(payload.orderId));
    yield put(action.fetchOrthoPolygonAction(payload.reportId));
    yield put(action.fetchOrthoImageAction(payload.reportId));
  } catch (error) {
    yield put(action.fetchImagesAndDetailsFailureAction(util.parseServerError(error)));
  }
}

export function* fetchOrderDetailsSaga({ payload }) {
  try {
    // don't put effort in reducing duplication as this is going to be removed when we stop supporting all three params
    const entitlements = yield select((state) => state.entitleUserReducer.entitlements);
    const isEntitledWithViaOpsPrime = isEntitled(entitlements, FEATURE_ENTITLEMENTS.MANAGE_MANUAL_AT_VIA_OPS_PRIME)
        || isEntitled(entitlements, FEATURE_ENTITLEMENTS.MANAGE_MANUAL_AT_ESCALATION_VIA_OPS_PRIME);
    const details = yield call(api.fetchOrderDetails, payload.orderId);
    yield put(action.fetchOrderDetailsSuccessAction(details));
    try {
      if (isEntitledWithViaOpsPrime) {
        const checkout = yield call(api.checkoutByReportId, details.orderID);
        yield put(action.updateTaskStateIdAction(checkout.ID));
        if (!checkout.Active) {
          yield put(action.enableReadOnlyMode());
        }
        yield put(action.fetchImagesAndDetailsAction({ orderId: payload.orderId, reportId: details.reportID }));
      } else {
        yield put(action.fetchImagesAndDetailsAction({ orderId: payload.orderId, reportId: details.reportID }));
      }
    } catch (err) {
      if (err.status) {
        yield put(action.enableReadOnlyMode());
        yield put(action.fetchImagesAndDetailsAction({ orderId: payload.orderId, reportId: details.reportID }));
      } else {
        yield put(action.handleCheckoutErrorAction('manualAtAdjustment.checkoutOpsPrimeErrorFailed'));
      }
    }
  } catch (error) {
    yield put(action.fetchOrderDetailsFailureAction(util.parseServerError(error)));
  }
}

export function* completeSaga({ payload }) {
  try {
    const entitlements = yield select((state) => state.entitleUserReducer.entitlements);
    yield call(api.saveApi, { orderId: payload.orderId, data: payload.omBody });
    if (constants.rolesEntitledToCallOpsPrime.some((role) => entitlements.includes(role))) {
      yield call(api.checkInSimple, payload.taskStateId);
    }
  } catch (error) {
    yield put(action.completeFailureAction(util.parseServerError(error)));
    return;
  }
  yield put(action.completeSuccessAction());
}

export function* rejectManualATOrderSaga({ payload }) {
  try {
    const entitlements = yield select((state) => state.entitleUserReducer.entitlements);
    yield call(api.saveApi, { orderId: payload.orderId, data: payload.omBody });
    if (constants.rolesEntitledToCallOpsPrime.some((role) => entitlements.includes(role))) {
      if (payload.omBody.status === constants.REJECTED_REMEASURE) {
        yield call(api.checkInSimple, payload.taskStateId);
      } else {
        yield call(api.rejectSimpleNotes, { taskStateId: payload.taskStateId, rejectReasonId: payload.rejectReasonId, body: payload.opBody });
      }
    }
    yield put(action.rejectManualATOrderSuccessAction());
    yield window.location.reload();
  } catch (error) {
    yield put(action.rejectManualATOrderFailureAction(util.parseServerError(error)));
  }
}

export default function* manualAtAdjustmentSaga() {
  yield takeLatest(constants.FETCH_IMAGES, fetchImagesSaga);
  yield takeLatest(constants.FETCH_ORTHO_POLYGON, fetchOrthoPolygonSaga);
  yield takeLatest(constants.FETCH_ORTHO_IMAGE, fetchOrthoImageSaga);
  yield takeLatest(constants.FETCH_ORDER_DETAILS, fetchOrderDetailsSaga);
  yield takeLatest(constants.REJECT, rejectManualATOrderSaga);
  yield takeLatest(constants.COMPLETE, completeSaga);
  yield takeLatest(constants.FETCH_IMAGES_AND_DETAILS, fetchImagesAndDetailsSaga);
}
