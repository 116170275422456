import React, { useState } from 'react';
import moment from 'moment';
import { DEFAULT_DATE_TIME_FORMAT } from 'constants.js';
import {
  EVBox,
  EVNoteIcon,
  EVPopper,
  EVNotesPanel,
  EVNotesItem,
  EVClickAwayListener,
  EVPaper,
  EVTypography,
} from '@eagleview/ev-comp-library';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import useStyles from './StatusBar.styles';
import StatusBarButton from './StatusBarButton';
import {
  rejectReasonToTranslation,
  STATUS_COMPLETED,
  STATUS_PENDING,
  NUM_OF_RECOMMENDED_PAIRINGS,
  NUM_OF_MINIMUM_PAIRINGS,
} from '../ManualAtAdjustment.constants';

const StatusBar = () => {
  const [refNotes, setRefNotes] = useState(null);
  const [notesOpen, setNotesOpen] = useState(false);
  const styles = useStyles();

  const { t } = useTranslation();

  // selectors
  const notesHistory = useSelector((state) => get(state, 'manualAtMultiStructureReducer.orderDetails.manualAtHistory', []));
  const coordinatePairings = useSelector((state) => get(state, 'manualAtMultiStructureReducer.coordinatePairings'));

  // utils
  const formatNotes = (item) => ({
    noteId: item.id,
    note: `${t('manualAtAdjustment.rejectedDueTo')} ${t(rejectReasonToTranslation[item.status])} ${t('manualAtAdjustment.withComment')} - ${item.note}`,
    dateUpdated: moment.unix(item.createdAt).format(DEFAULT_DATE_TIME_FORMAT),
    author: item.author,
  });
  const filteredNotes = notesHistory.filter((note) => note.status !== STATUS_COMPLETED && note.status !== STATUS_PENDING);
  const formattedNotes = filteredNotes.map(formatNotes);

  const handleNotesButton = (e) => {
    setRefNotes(e.currentTarget);
    if (formattedNotes.length) { setNotesOpen(!notesOpen); }
  };

  const handleClickAway = () => {
    setNotesOpen(false);
  };

  return (
    <>
      <EVBox className={styles.statusBar} data-testid="status-bar">
        <EVPopper open={notesOpen} placement="top-end" anchorEl={refNotes} style={{ zIndex: 5 }}>
          <EVClickAwayListener onClickAway={handleClickAway}>
            <EVPaper elevation={3}>
              <EVNotesPanel
                width="480px"
                height="600px"
                hideActionBar
              >
                {formattedNotes.map((note) => (
                  <EVNotesItem
                    key={note.noteId}
                    note={note}
                    hideMoreOptionsIcon
                  />
                ))}
              </EVNotesPanel>
            </EVPaper>
          </EVClickAwayListener>
        </EVPopper>
        <EVTypography variant="caption" data-testid="pairings-status">
          {/* eslint-disable-next-line */}
          {`${t('manualAtStatusBar.Pairings')} - ${t('manualAtStatusBar.Recommended')}: ${NUM_OF_RECOMMENDED_PAIRINGS} | ${t('manualAtStatusBar.Minimum')}: ${NUM_OF_MINIMUM_PAIRINGS} | ${t('manualAtStatusBar.Completed')}: ${coordinatePairings.length}`}
        </EVTypography>
        <StatusBarButton
          testid="notes-button"
          active={notesOpen}
          text={`${formattedNotes.length ? `${formattedNotes.length} ${t('statusbar.notes')}` : t('statusbar.noNotes')}`}
          icon={(
            <EVNoteIcon
              className={
                formattedNotes.length ? styles.activeIcon : styles.inactiveIcon
              }
            />
          )}
          onClick={handleNotesButton}
        />
      </EVBox>
    </>
  );
};

export default StatusBar;
