import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import { createLogger } from 'redux-logger';
import * as ReselectTools from 'reselect-tools';
import { debugMode } from 'utils/utils';

import * as workflowPanelSelectors from 'layout/adjuster/workflow-panel/WorkflowPanel.selectors';
import rootSaga from './RootSaga';
import createRootReducer from './RootReducer';

const sagaMiddleware = createSagaMiddleware();
export const history = createBrowserHistory({
  basename: process.env.REACT_APP_BASENAME,
});

export default function configureStore(preloadedState) {
  const middleware = [routerMiddleware(history), sagaMiddleware];
  if (debugMode) {
    middleware.push(createLogger({
      collapsed: true,
    }));
  }
  const store = createStore(
    createRootReducer(history),
    preloadedState,
    composeWithDevTools(applyMiddleware(...middleware)),
  );
  sagaMiddleware.run(rootSaga);
  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    ReselectTools.getStateWith(() => store.getState());
    ReselectTools.registerSelectors({
      ...workflowPanelSelectors,
    });
  }

  return store;
}

export const store = configureStore();
