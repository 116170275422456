import { makeStyles } from '@eagleview/ev-comp-library';

export default makeStyles((theme) => ({
  root: {
    width: '360px',
    height: '420px',
  },
  eagleViewLogo: {
    width: '100%',
    paddingLeft: '22%',
    paddingRight: '22%',
    paddingTop: '10%',
    paddingBottom: '8%',
  },
  signInBox: {
    padding: `0 ${theme.spacing(6)}px`,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  signInText: {
    fontSize: '14px',
    fontWeight: 'bold',
    color: theme.palette.grey[600],
    marginBottom: theme.spacing(1.2),
  },
  invalidOtpText: {
    fontSize: '12px',
    fontWeight: '400',
    color: 'red',
  },
  otpInputContainer: {
    display: 'flex',
    justifyContent: 'center',
    padding: `0 ${theme.spacing(3)}px`,
  },
  otpInputRoot: {
    border: `1px solid ${theme.palette.grey[400]}`,
    paddingLeft: theme.spacing(1.5),
  },
  adjustHeight: {
    height: '42px',
  },
  validateButtonContainer: {
    padding: `0 ${theme.spacing(6)}px`,
    marginTop: theme.spacing(1),
  },
  otpDescriptionContainer: {
    padding: `0 ${theme.spacing(6)}px`,
    marginTop: theme.spacing(3),
  },
  otpDescriptionText: {
    fontSize: '12px',
    fontWeight: '500',
    color: theme.palette.grey[600],
  },
  resendOtpDesc: {
    fontSize: '14px',
    fontWeight: '500',
    color: theme.palette.grey[600],
  },
  resendText: {
    fontSize: '14px',
    fontWeight: '500',
    color: 'blue',
    cursor: 'pointer',
    textDecoration: 'underline',
  },
  disabledResendText: {
    fontSize: '14px',
    fontWeight: '500',
    color: theme.palette.grey[600],
    cursor: 'not-allowed',
    textDecoration: 'underline',
  },
  validateButton: {
    height: '42px',
    fontSize: '14px',
    fontWeight: '500',
    textTransform: 'none',
  },
  resendOptContainer: {
    display: 'flex',
    padding: `0 ${theme.spacing(6)}px`,
    marginTop: theme.spacing(3),
  },
  lockedDueToInvalidAttempts: {
    marginTop: theme.spacing(6),
    padding: `0 ${theme.spacing(6)}px`,
  },
  customerService: {
    marginTop: theme.spacing(8),
    padding: `0 ${theme.spacing(6)}px`,
  },
  customerServiceText: {
    fontSize: '16px',
    fontWeight: '500',
    color: theme.palette.grey[600],
  },
  customerServiceEmailText: {
    fontSize: '16px',
    fontWeight: '500',
    color: theme.palette.grey[600],
    marginTop: theme.spacing(1),
    textDecoration: 'underline',
    cursor: 'pointer',
  },
}));
