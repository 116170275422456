import { makeStyles } from '@eagleview/ev-comp-library';

const useStyles = makeStyles((theme) => ({
  pageNotFound: {
    height: theme.typography.pxToRem(269),
    width: theme.typography.pxToRem(382),
  },
  containerStyle: {
    height: '100%',
    background: theme.evPalette.charcoal[200],
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'overlay',
    paddingRight: theme.typography.pxToRem(25),
    paddingBottom: theme.typography.pxToRem(100),
  },
  imageCss: {
    marginBottom: theme.typography.pxToRem(48),
  },
  errCode: {
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(24),
    lineHeight: theme.typography.pxToRem(33),
    textAlign: 'center',
    textTransform: 'capitalize',
    color: theme.evPalette.charcoal[700],
    marginBottom: theme.typography.pxToRem(5),
  },
  errMsg: {
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(19),
    textAlign: 'center',
    color: theme.evPalette.nickel[700],
  },
}));
export default useStyles;
