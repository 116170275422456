import { makeStyles } from '@eagleview/ev-comp-library';

export default makeStyles((theme) => ({
  infoPanel: {
    zIndex: 8,
    position: 'absolute',
    left: `calc(50% - ${theme.spacing(62) / 2}px)`,
    top: `calc(50% - ${theme.spacing(75) / 2}px)`,
    boxShadow: theme.shadows[4],
  },
  header: {
    width: theme.spacing(57),
    height: theme.spacing(5.5),
    margin: `${theme.spacing(1)}px ${theme.spacing(2)}px ${theme.spacing(1)}px ${theme.spacing(3)}px`,
  },
  titleText: {
    color: theme.evPalette.ocean[500],
    lineHeight: '27px',
    height: theme.spacing(4),
  },
  closeIconButton: {
    float: 'right',
    padding: theme.spacing(1),
    height: theme.spacing(4.5),
    width: theme.spacing(4.5),
  },
  closeIcon: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    padding: theme.spacing(0.25),
  },
  selectOrderMsgContainer: {
    maxWidth: theme.spacing(51.5),
  },
  selectOrderMsg: {
    color: theme.evPalette.nickel[700],
    lineHeight: '27px',
  },
  claimNo: {
    fontWeight: theme.typography.fontWeightBold,
    color: theme.evPalette.charcoal[900],
  },
  orderDetails: {
    color: theme.evPalette.nickel[700],
    lineHeight: '24px',
  },
  label: {
    color: theme.evPalette.nickel[700],
    lineHeight: '24px',
  },
  textField: {
    marginTop: theme.spacing(0.5),
  },
  orgSearchField: {
    marginTop: theme.spacing(0.5),
    width: '100%',
  },
  searchInputRoot: {
    '&:first-child': {
      paddingRight: theme.spacing(10),
      paddingLeft: theme.spacing(1),
    },
  },
  footer: {
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(2)}px ${theme.spacing(3)}px`,
    borderTop: `1px solid ${theme.evPalette.charcoal[200]}`,
    height: theme.spacing(8),
  },
  button: {
    textTransform: 'none',
    height: theme.spacing(4),
  },
  cancelButton: {
    marginRight: theme.spacing(2),
  },
  buttonBox: {
    float: 'right',
  },
  backdrop: {
    zIndex: 7,
    backgroundColor: theme.evPalette.scrim,
  },
}));
