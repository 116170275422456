import flatten from 'lodash/flatten';
import uniq from 'lodash/uniq';
import {
  Util, AssetFactory, GeoJSONUtil, LonLat, ImageryWarehouseImageXYCoordinate,
} from '@eagleview/mapviewer';

import get from 'lodash/get';
import sum from 'lodash/sum';
import range from 'lodash/range';
import mapValues from 'lodash/mapValues';
import toLower from 'lodash/toLower';
import moment from 'moment';
import * as turf from '@turf/turf';
import proj4 from 'proj4';
import isEmpty from 'lodash/isEmpty';
import isArray from 'lodash/isArray';
import { logger, getAuthParam, disableAutoFit } from 'utils/utils';
import {
  REPORT_DATE_FORMAT, GALLERY_IMAGE_CATEGORIES, GALLERY_IMAGE_CATEGORY_MANUAL, REGENERATE_REPORT_STATUS_FAILED, REGENERATE_REPORT_STATUS_FAILED_RETRY,
} from 'layout/adjuster/Adjuster.constants';
import {
  ASSESS_IMAGES_API_ENDPOINT, DEFAULT_DATE_FORMAT, REACT_APP_ENV, ROOF_TO_VIEWPORT_RATIO,
} from 'constants.js';
import { claimStatus } from 'layout/homeLayout/home/Home.constants';
import validateGeojson from 'geojson-validation';
import Immutable from 'immutable';
import intersection from 'lodash/intersection';
import intersectionWith from 'lodash/intersectionWith';
import isEqual from 'lodash/isEqual';
import bgImageUndecided from './bg-label.svg';
import bgImageDecided from './bg-label-selected.svg';
import {
  ANOMALY_DECISIONS,
  CONFIRMABLE_DECISIONS,
  DEFAULT_MAX_ZOOM,
  UNSET_ANOMALY_ID,
  ANOMALY_TYPE_MAP,
  CONFIDENCE_FACTOR_MAPPING,
  GALLERY_IMAGE_EXPLORE_OVERVIEW,
  TABS,
  ASSESS_LITE_ROOF_IMAGE_CATEGORIES,
  QUICK_CAPTURE,
  ASSESS_MEASURE,
} from './Adjuster.constants';

proj4.defs([
  ['EPSG:4326', '+title=WGS 84 (long/lat) +proj=longlat +ellps=WGS84 +datum=WGS84 +units=degrees'],
  [
    'EPSG:3857',
    '+proj=merc +a=6378137 +b=6378137 +lat_ts=0.0 +lon_0=0.0 +x_0=0.0 +y_0=0 +k=1.0 +units=m +nadgrids=@null +wktext  +no_defs',
  ],
  ['EPSG:4978', '+proj=geocent +datum=WGS84 +units=m +no_defs'],
]);

export const getMapviewerAsset = (options, errorCallback) => {
  let asset = null;
  try {
    asset = AssetFactory.getAsset(options);
  } catch (e) {
    logger('error', 'Error occurred in getMapviewerAsset: ', get(e, 'message', e));
    if (errorCallback) { setTimeout(errorCallback, 0); }
  }
  return asset;
};

export const filterTagsFromImages = (images) => {
  if (isEmpty(images)) return [];
  return uniq(flatten(images.map((obj) => (obj.tags.length ? obj.tags : false)).filter(Boolean)));
};

export const getDate = (timestamp) => (timestamp !== '' ? moment.unix(timestamp).format(DEFAULT_DATE_FORMAT) : '');

export const dateOfLossHelper = (payload) => {
  const dateOfLossString = get(payload, 'Info.Details.DateOfLossString', false);
  if (dateOfLossString) return moment(dateOfLossString).format(DEFAULT_DATE_FORMAT);
  return getDate(get(payload, 'Info.Details.DateOfLoss', ''));
};

export const isOrderComplete = (orderStatus) => ([claimStatus.ORDERCLOSED, claimStatus.COMPLETED].includes(orderStatus));
export const mapAdjusterDetails = (payload) => ({
  orderId: payload.OrderID,
  orderStatus: payload.Status,
  claimId: get(payload, 'Info.Details.ClaimNumber', ''),
  addressStreet: get(payload, 'Address.StreetAddress1', ''),
  addressCity: [
    get(payload, 'Address.City', false),
    get(payload, 'Address.State', false),
    get(payload, 'Address.Zip', false),
  ]
    .filter(Boolean)
    .join(' '),
  policyInsurancePerilCode: get(payload, 'Info.Details.PerilCode', ''),
  report: payload.report || {},
  policyDateOfLoss: dateOfLossHelper(payload),
  stormDate: getDate(get(payload, 'Info.ConsolidatedWeather.StormDate', '')),
  windDirection: get(payload, 'Info.ConsolidatedWeather.WindDirection', ''),
  windGust: get(payload, 'Info.ConsolidatedWeather.Wind', ''),
  hailSize: get(payload, 'Info.ConsolidatedWeather.HailSize', 0),
  EVRoofReports: get(payload, 'EVRoofReports', []),
  EVRoofReport: get(payload, 'Info.EVRoofReport', false),
  orderCompleted: isOrderComplete(payload.Status),
  IsRoofMeasurementEnabled: get(payload, 'IsRoofMeasurementEnabled', false),
  roofMeasurementReportStatus: get(payload, 'Measurements.Status', ''),
  flightType: get(payload, 'FlightType', false),
  dateOrderCapture: getDate(get(payload, 'DateOrderCapture', '')),
  dateCreated: getDate(get(payload, 'DateCreated', '')),
  dateCreatedUnixCode: get(payload, 'DateCreated', ''),
  legacyStatus: get(payload, 'LegacyStatus', ''),
  isMultiFamilyResidential: get(payload, 'IsMultiFamilyResidential', false),
  isPhotosUnspported: !get(payload, 'ImageryUploadComplete', false)
    || [QUICK_CAPTURE, ASSESS_MEASURE].includes(get(payload, 'FlightType', '')),
}
);

export const getRegenerateReportAssessStatus = (orderId, currentAssessStatus) => {
  const regenerateRoofReportLogs = JSON.parse(localStorage.getItem('regenerateRoofReportLogs')) || {};

  if (currentAssessStatus === REGENERATE_REPORT_STATUS_FAILED) {
    const failedAttempts = (regenerateRoofReportLogs[orderId] && regenerateRoofReportLogs[orderId].FailedAttempts) || 0;
    return failedAttempts >= 3 ? REGENERATE_REPORT_STATUS_FAILED : REGENERATE_REPORT_STATUS_FAILED_RETRY;
  }

  return currentAssessStatus;
};

export const getFacetPolygons = (facets) => getMapviewerAsset({
  assetType: 'annotation',
  data: facets.filter((x) => !x.underHang).map((x) => ({
    geometries: [x.facetOutline.value],
    properties: { facetId: x.facetId, facetAliasName: x.facetAliasName },
  })),
});

export const getBackgroundImage = (decision) => (decision === 'UNDECIDED' || decision.trim() === '' ? bgImageUndecided : bgImageDecided);

export const getLabelAssetsFromFacets = (facets) => {
  const data = facets.filter((x) => !x.underHang).map((x, index) => {
    const averageElevationPoint = sum(x.facetOutline.value.coordinates[0].map((y) => y[2]))
      / x.facetOutline.value.coordinates[0].length;

    return {
      id: index,
      geometries: [
        {
          type: 'Point',
          coordinates: [...x.facetOutline.centroid.coordinates, averageElevationPoint],
        },
      ],
      properties: {
        decision: x.decision,
        facetId: x.facetId,
        annotationType: 'label',
        name: x.facetAliasName,
        trackEnabled: false,
        cursorStyle: 'pointer',
      },
      style: {
        text: {
          color: '#fff',
          backgroundImage: getBackgroundImage(x.decision),
        },
      },
    };
  });
  return getMapviewerAsset({
    assetType: 'annotation',
    data,
  });
};

/**
 * Generate heatmap based on scores and buckets
 * scores= [ (facet1.total_anomalies / facet1.sqft), (facet2...  ]
 * bucket groupings: ( max(scores) - min(scores) ) / num_buckets
 */
export const getBucketsForFacets = (facets) => {
  const scores = facets.map((x) => {
    const anomalyList = get(x, 'anomalyList', []);
    const area = get(x, 'facetOutline.area', 1);
    return { facetAliasName: x.facetAliasName, score: anomalyList.length / area };
  });
  const maxScore = Math.max(...scores.map((x) => x.score));
  const minScore = Math.min(...scores.map((x) => x.score));
  const step = (maxScore - minScore) / 5;
  const buckets = range(minScore, maxScore, step).map((val, index, orig) => [
    val,
    orig[index + 1] ? orig[index + 1] : maxScore,
  ]);
  const final = Object.assign(
    ...scores.map((x) => ({
      [x.facetAliasName]: buckets.findIndex((y) => x.score >= y[0] && x.score <= y[1]) + 1,
    })),
  );
  // prevent zero buckets
  if (maxScore === minScore) {
    return Object.assign(...Object.keys(final).map((key) => ({ [key]: 1 })));
  }
  return final;
};

export const getSortedAnomalyList = (list) => {
  if (list) {
    return list.sort((a, b) => {
      const confidenceA = a.properties.confidence || 0;
      const confidenceB = b.properties.confidence || 0;
      if (confidenceA === confidenceB) return 0;
      return confidenceA < confidenceB ? 1 : -1;
    });
  }
  return [];
};

export const tempRoofFacetMapper = (payload) => ({
  ...payload,
  facets: payload.facets.map((facet) => ({
    ...facet,
    anomalyList: getSortedAnomalyList(facet.anomalyList),
  })),
});

export const clearGalleryImageFilters = (filters) => {
  const updatedFilters = { ...filters };
  Object.keys(updatedFilters).forEach((filter) => { updatedFilters[filter] = false; });
  return updatedFilters;
};

export const getAnnotationFromAnomaly = (anomaly) => ({
  assetType: 'annotation',
  data: [
    {
      geometries: [
        {
          type: 'Polygon',
          coordinates: anomaly.geometry.coordinates,
        },
      ],
      properties: {
        facetId: anomaly.facetId,
        status: anomaly.decision,
        anomalyId: anomaly.anomalyId,
        isUserAdded: anomaly.isUserAdded,
        imageUrn: anomaly.imageUrn,
        confidence: get(anomaly, 'properties.confidence', -1),
        type: get(anomaly, 'properties.type'),
        includeInReport: anomaly.includeInReport,
      },
    },
  ],
});
/**
 * returns true or false for an anomaly based on whether the anomaly
   should be kept or filtered out based on the selected confidence factor
 * @param {Object} anomaly - anomaly object
 * @param {String} confidenceFactor - confidence factor status i.e. Recommended, More;
 * @params {Object} - appSettings - object containing various threshold settings of
 * confidence factors for all possible anomaly types
 * @returns {Boolean} - returns true in case anomaly should be kept,
 * and false in case it should be filtered out.
 */

export const confidenceFactorFilter = (anomaly, confidenceFactor, appSettings) => {
  if (isEmpty(appSettings)) return true;
  const anomalyStatus = get(anomaly, 'data.0.properties.status', 'UNCONFIRMED');
  const isAnomalyConfirmed = anomalyStatus === 'CONFIRMED';
  if (isAnomalyConfirmed) return true;
  let filteredSettings = null;
  const anomalyType = get(anomaly, 'data.0.properties.type');
  const anomalyConfidence = get(anomaly, 'data.0.properties.confidence');
  const CONFIDENCE_FACTOR_MAP = CONFIDENCE_FACTOR_MAPPING[anomalyType];
  if (!CONFIDENCE_FACTOR_MAP) return false;
  const confidenceFactorKey = CONFIDENCE_FACTOR_MAP[confidenceFactor];
  filteredSettings = appSettings.groups.find((setting) => setting.groupName === ANOMALY_TYPE_MAP[anomalyType]);
  if (isEmpty(filteredSettings)) return true;
  return anomalyConfidence >= parseFloat(filteredSettings.settings[confidenceFactorKey]);
};

export const parseAnomaliesFromFacets = (facets) => {
  const facetsWithAnomalies = facets.filter((x) => !isEmpty(x.anomalyList));
  const allAnomalies = flatten(
    facetsWithAnomalies.map((x) => x.anomalyList.map((y) => ({ ...y, facetId: x.facetId }))),
  );
  const annotations = allAnomalies.map((anomaly) => getAnnotationFromAnomaly(anomaly));
  return annotations;
};

/**
 * return best unconfirmed anomaly id based on the confidence score
 * @param {object} facet - facet anomaly list ordered by confidence score
 * @param {number} index - position
 */
export const getBestUnConfirmedAnomalyId = (facet, currentAnomalyId) => {
  const list = get(facet, 'anomalyList', []);
  return get(list.find(({ anomalyId, decision }) => {
    const isPotential = anomalyId !== currentAnomalyId && CONFIRMABLE_DECISIONS.includes(decision);
    return isPotential;
  }), 'anomalyId', UNSET_ANOMALY_ID);
};

/**
 * return first unconfirmed anomaly id | if not found return first anomaly id
 * @param {object} facet - facet object
 */
export const getInitialAnomalyId = (facet) => {
  const isUnconfirmed = (anomaly) => [ANOMALY_DECISIONS.UNCONFIRMED, ''].includes(anomaly.decision);
  const backupAnomalyId = get(facet, 'anomalyList[0].anomalyId', UNSET_ANOMALY_ID);
  const firstUnconfirmedAnomalyObj = facet.anomalyList.find(isUnconfirmed);
  const firstUnconfirmedAnomalyId = get(firstUnconfirmedAnomalyObj, 'anomalyId', UNSET_ANOMALY_ID);
  /* istanbul ignore else */
  // fixes bad coverage because of code style
  if (firstUnconfirmedAnomalyObj) {
    return firstUnconfirmedAnomalyId;
  }
  return backupAnomalyId;
};

/**
 * return boolean depending if point is inside polygon
 * @param {geojson} polygon
 * @param {geojson} point
 * @returns {boolean}
 */
export const polygonContainsPoint = (polygon, point) => turf.booleanPointInPolygon(turf.point(point), turf.polygon(polygon));

/**
 * return next highest confidence unconfirmed anomaly
 * @param {object} facet
 * @param {string} id
 */
export const getNextBestUnconfirmedAnomalyId = (facet, id, fromIndex) => {
  const list = facet.anomalyList;
  const index = id ? list.findIndex((a) => a && a.anomalyId === id) : fromIndex;
  if (index === -1) return UNSET_ANOMALY_ID;
  const total = list.length;
  if (total <= 0) return UNSET_ANOMALY_ID;
  for (let i = index + 1; i < total; i += 1) {
    if (
      list[i]
      && (list[i].decision === ANOMALY_DECISIONS.UNCONFIRMED || list[i].decision === '')
    ) {
      return list[i].anomalyId;
    }
  }
  for (let i = 0; i <= index - 1; i += 1) {
    if (
      list[i]
      && (list[i].decision === ANOMALY_DECISIONS.UNCONFIRMED || list[i].decision === '')
    ) {
      return list[i].anomalyId;
    }
  }
  return UNSET_ANOMALY_ID;
};

/**
 * return previous unconfirmed anomaly
 * @param {object} facet
 * @param {string} id
 */
export const getPreviousUnconfirmedAnomalyId = (facet, id, fromIndex) => {
  const list = facet.anomalyList;
  const index = id ? list.findIndex((a) => a && a.anomalyId === id) : fromIndex;
  if (index === -1) return UNSET_ANOMALY_ID;
  const total = list.length;
  if (total <= 0) return UNSET_ANOMALY_ID;
  for (let i = index - 1; i >= 0; i -= 1) {
    if (
      list[i]
      && (list[i].decision === ANOMALY_DECISIONS.UNCONFIRMED || list[i].decision === '')
    ) {
      return list[i].anomalyId;
    }
  }
  for (let i = total - 1; i > index; i -= 1) {
    if (
      list[i]
      && (list[i].decision === ANOMALY_DECISIONS.UNCONFIRMED || list[i].decision === '')
    ) {
      return list[i].anomalyId;
    }
  }
  return UNSET_ANOMALY_ID;
};

/**
   * coverts longitude point to pixel(x) on mercator projection
   * refer: https://stackoverflow.com/questions/14329691/convert-latitude-longitude-point-to-a-pixels-x-y-on-mercator-projection
   *
   * @param {Number} lng
   */
export const mercatorXfromLng = (lng) => (180 + lng) / 360;

/**
 * coverts latitude point to pixel(y) on mercator projection
 * refer: https://stackoverflow.com/questions/14329691/convert-latitude-longitude-point-to-a-pixels-x-y-on-mercator-projection
 *
 * @param {Number} lat
 */
// eslint-disable-next-line no-mixed-operators
export const mercatorYfromLat = (lat) => (180 - (180 / Math.PI * Math.log(Math.tan(Math.PI / 4 + lat * Math.PI / 360)))) / 360;

export const lngFromMercatorX = (x) => x * 360 - 180;

export const latFromMercatorY = (y) => {
  const y2 = 180 - y * 360;
  return (360 / Math.PI) * Math.atan(Math.exp(y2 * (Math.PI / 180))) - 90;
};

export const getBounds = (coordinates, viewportWidth, viewportHeight) => {
  let maxRoofLat;
  let minRoofLat;
  let minRoofLon;
  let maxRoofLon;
  const bufferDistanceH = 0.0001;
  let bufferDistanceV = 0.0001;

  for (let i = 0; i < coordinates.length; i += 1) {
    const coord = coordinates[i];
    const lon = coord[0];
    const lat = coord[1];

    if (maxRoofLat === undefined || lat > maxRoofLat) {
      maxRoofLat = lat;
    }

    if (minRoofLat === undefined || lat < minRoofLat) {
      minRoofLat = lat;
    }

    if (minRoofLon === undefined || lon < minRoofLon) {
      minRoofLon = lon;
    }

    if (maxRoofLon === undefined || lon > maxRoofLon) {
      maxRoofLon = lon;
    }
  }

  if (!disableAutoFit) {
    const minRoofX = mercatorXfromLng(minRoofLon);
    const minRoofY = mercatorYfromLat(minRoofLat);
    const maxRoofX = mercatorXfromLng(maxRoofLon);
    const maxRoofY = mercatorYfromLat(maxRoofLat);
    const minBoundX = mercatorXfromLng(minRoofLon - bufferDistanceH);
    const maxBoundX = mercatorXfromLng(maxRoofLon + bufferDistanceH);

    const viewportAspectRatio = viewportWidth / viewportHeight;
    const boundWidth = Math.abs(maxBoundX - minBoundX);
    const boundHeightByAspectRatio = boundWidth / viewportAspectRatio;
    const roofHeight = Math.abs(maxRoofY - minRoofY);

    logger('debug', `Roof Height to Map viewport height Ratio : ${roofHeight / boundHeightByAspectRatio}`);

    if (roofHeight / boundHeightByAspectRatio > (ROOF_TO_VIEWPORT_RATIO || 2)) {
      const roofWidthByAspectRatio = roofHeight * viewportAspectRatio;
      const roofWidth = Math.abs(maxRoofX - minRoofX);
      const diff = roofWidthByAspectRatio - roofWidth;
      minRoofLon = lngFromMercatorX(minRoofX - diff / 2);
      maxRoofLon = lngFromMercatorX(maxRoofX + diff / 2);
      bufferDistanceV = 0.00001;
    }
  }

  // @todo - remove +/- 0.0001 once bounds are projected to image
  // @todo - we are probably going to want to buffer by a distance
  return {
    ul: {
      lat: maxRoofLat + bufferDistanceV,
      lon: minRoofLon - bufferDistanceH,
    },
    ur: {
      lat: maxRoofLat + bufferDistanceV,
      lon: maxRoofLon + bufferDistanceH,
    },
    br: {
      lat: minRoofLat - bufferDistanceV,
      lon: maxRoofLon + bufferDistanceH,
    },
    bl: {
      lat: minRoofLat - bufferDistanceV,
      lon: minRoofLon - bufferDistanceH,
    },
  };
};

/**
 * return centroid from geometry
 * @param {geojson} geometry
 */
export const getCentroidForGeometry = (geometry) => {
  if (geometry && geometry.coordinates) {
    const polygon = turf.polygon(geometry.coordinates);
    return turf.centroid(polygon);
  }
  return {};
};

export const getMercatorCoordinatesForPoint = (geometry, asset) => {
  if (!asset || !geometry) return [];
  const translator = asset.pointTranslator;
  const point = get(geometry, 'coordinates', []);
  const lonLat = LonLat.getInstance({ lon: point[0], lat: point[1], elevation: point[2] || 0 });
  const contextCoord = translator.lonLatToContextCoordinate(lonLat, true);
  return { coordinates: [contextCoord.x, contextCoord.y] };
};

export const getMercatorCoordinatesForPolygon = (geometry, asset) => {
  if (!asset || !geometry) return [];
  const translator = asset.pointTranslator;
  const newGeoJSON = GeoJSONUtil.translate(geometry, (point) => {
    const lonLat = LonLat.getInstance({ lon: point[0], lat: point[1], elevation: point[2] || 0 });
    const contextCoord = translator.lonLatToContextCoordinate(lonLat, true);
    return [contextCoord.x, contextCoord.y];
  });
  return newGeoJSON;
};

/**
 * Transforms orientation to be in range [0,360]
 *
 * @param {*} orientation
 */
export const transformOrientation = (orientation) => {
  let newOrientation = orientation % 360;// transforms orientation to range [-360,0,360]
  newOrientation = newOrientation < 0 ? (360 + newOrientation) : newOrientation;
  newOrientation -= (newOrientation % 90);// transforms orientation to modulus of 90 (0,90,180,270,360)
  return newOrientation;
};

export const getTopRightCoordinate = (coordinates) => {
  if (!coordinates || coordinates.length < 2) return null;
  let topRightCoordinate = coordinates[0];
  for (let i = 1; i < coordinates.length; i += 1) {
    const coord = coordinates[i];
    if (coord[1] > topRightCoordinate[1] || coord[0] > topRightCoordinate[0]) {
      topRightCoordinate = coord;
    }
  }
  return topRightCoordinate;
};

export const getRightMostCoordinate = (coordinates, mapRotationDegree = 0) => {
  if (!coordinates || coordinates.length < 2) return null;
  const transformDegree = transformOrientation(mapRotationDegree);

  let rightMostCoordinate = coordinates[0];
  for (let i = 1; i < coordinates.length; i += 1) {
    const coord = coordinates[i];
    if (
      (transformDegree === 0 && coord[0] > rightMostCoordinate[0])
      || (transformDegree === 90 && coord[1] < rightMostCoordinate[1])
      || (transformDegree === 180 && coord[0] < rightMostCoordinate[0])
      || (transformDegree === 270 && coord[1] > rightMostCoordinate[1])
    ) {
      rightMostCoordinate = coord;
    }
  }
  return rightMostCoordinate;
};

export const getLeftMostCoordinate = (coordinates, mapRotationDegree = 0) => {
  if (!coordinates || coordinates.length < 2) return null;
  const transformDegree = transformOrientation(mapRotationDegree);
  let leftMostCoordinate = coordinates[0];
  for (let i = 1; i < coordinates.length; i += 1) {
    const coord = coordinates[i];

    if (
      (transformDegree === 0 && coord[0] < leftMostCoordinate[0])
      || (transformDegree === 90 && coord[1] > leftMostCoordinate[1])
      || (transformDegree === 180 && coord[0] > leftMostCoordinate[0])
      || (transformDegree === 270 && coord[1] < leftMostCoordinate[1])

    ) {
      leftMostCoordinate = coord;
    }
  }
  return leftMostCoordinate;
};

export const getBoxAnnotationDescIconCoordinates = (annotationData, asset) => {
  let pointCoordinate = [];
  if (get(annotationData, 'properties.annotationType') === 'box') {
    pointCoordinate = getTopRightCoordinate(get(annotationData.geometries[0], 'coordinates.0', []));
    if (asset && asset.pointTranslator) {
      const lonLat = LonLat.getInstance({ lon: pointCoordinate[0], lat: pointCoordinate[1], elevation: pointCoordinate[2] || 0 });
      const contextCoord = asset.pointTranslator.lonLatToContextCoordinate(lonLat, true);
      pointCoordinate = [contextCoord.x, contextCoord.y];
    }
  }
  return pointCoordinate;
};

export const getBoxAnnotationDescPanelData = (annotationDescPanelData, boxAnnotations) => {
  const annotationId = get(annotationDescPanelData, 'annotation.id', '');
  const gallaryAnnotation = boxAnnotations.find((boxAnnotation) => get(boxAnnotation, 'annotationId', '') === annotationId);
  return {
    ...annotationDescPanelData,
    description: get(gallaryAnnotation, 'comment', ''),
    includeInReport: get(gallaryAnnotation, 'includeInReport', true),
  };
};

export const getWorkFlowPanelViewFromAnomaly = (geometry) => {
  const centroid = getCentroidForGeometry(geometry);
  const coordinates = get(geometry, 'coordinates.0', []);
  const zVal = coordinates.reduce((z, xyz) => z + parseFloat(xyz[2] || 0), 0) / coordinates.length;
  return {
    lonLat: {
      lon: get(centroid, 'geometry.coordinates.0', 0),
      lat: get(centroid, 'geometry.coordinates.1', 0),
      elevation: zVal,
    },
    zoom: DEFAULT_MAX_ZOOM,
    rotation: 0,
  };
};

export const getViewFromAnomaly = (facetMosaicAsset, geometry, viewportWidth, viewportHeight) => {
  if (facetMosaicAsset && facetMosaicAsset.length) {
    const translator = facetMosaicAsset[facetMosaicAsset.length - 1].pointTranslator;
    const newGeoJSON = GeoJSONUtil.translate(geometry, (point) => {
      const lonLat = LonLat.getInstance({ lon: point[0], lat: point[1], elevation: point[2] || 0 });
      const contextCoord = translator.lonLatToContextCoordinate(lonLat, true);
      return [contextCoord.x, contextCoord.y];
    });
    const view = Util.getViewFromGeometry(newGeoJSON, viewportWidth, viewportHeight, 0, DEFAULT_MAX_ZOOM);
    // TODO - Remove this once Util.getViewFromGeometry supports min/max zoom
    view.zoom = DEFAULT_MAX_ZOOM;

    return view;
  }
  return Util.getViewFromGeometry(geometry, viewportWidth, viewportHeight);
};

export const getRoofPolygon = (payload) => {
  const roofType = get(payload, 'roof.roofOutline.value.type');
  const polygon = get(payload, 'roof.roofOutline.value.coordinates');
  if (roofType === 'Polygon') {
    return [polygon];
  }
  return polygon;
};

export const getPolygonFromCorners = ({
  bl, br, ur, ul,
}) => turf.polygon([
  [
    [bl.lon, bl.lat],
    [br.lon, br.lat],
    [ur.lon, ur.lat],
    [ul.lon, ul.lat],
    [bl.lon, bl.lat],
  ],
]);

export const parseFalconTags = (images) => images.map((image) => ({ imageID: image.imageURN, tags: image.tags }));

export const combineTagArrays = (firstArr, secondArr) => {
  const firstArrImageIDs = firstArr.map((image) => image.imageID);
  const secondArrImageIDs = secondArr.map((image) => image.imageID);
  const allImageIDs = uniq([...firstArrImageIDs, ...secondArrImageIDs]);
  return allImageIDs.map((id) => {
    const firstFind = firstArr.find((tag) => tag.imageID === id) || false;
    const secondFind = secondArr.find((tag) => tag.imageID === id) || false;
    if (firstFind && secondFind) {
      return {
        imageID: id,
        tags: uniq([...firstFind.tags, ...secondFind.tags]),
      };
    }
    if (firstFind && !secondFind) {
      return firstFind;
    }
    return secondFind;
  });
};

export const getUpdatedGalleryImages = (payload, images) => {
  const { image: metadata, image_urn: urn } = payload;
  const newImages = images.map((image) => ((image.urn === urn) ? {
    ...image,
    metadata,
  } : image));
  return newImages;
};

export const getGalleryFalconTags = (images) => (images.some((image) => image.tags.includes(GALLERY_IMAGE_CATEGORY_MANUAL))
  ? [...GALLERY_IMAGE_CATEGORIES, GALLERY_IMAGE_CATEGORY_MANUAL] : GALLERY_IMAGE_CATEGORIES);

export const getUpdatedOrientationForGalleryImages = (payload, images) => {
  const { orientation, imageURN } = payload;
  const newImages = images.map((image) => ((image.urn === imageURN) ? {
    ...image,
    orientation,
  } : image));
  return newImages;
};
export const getUpdatedOrientationForRiskShotImages = (payload, riskShots) => {
  const { orientation, imageURN } = payload;
  const updatedRiskShots = {};
  Object.entries(riskShots)
    .forEach(([structureId, riskShot]) => {
      if (!isEmpty(riskShot) && isArray(riskShot.images)) {
        const updatedImages = riskShot.images.map((image) => (
          (image.imageURN === imageURN) ? {
            ...image,
            customMeta: {
              ...image.customMeta,
              orientation,
            },
          } : image
        ));
        updatedRiskShots[structureId] = {
          ...riskShot,
          images: updatedImages,
        };
      } else {
        updatedRiskShots[structureId] = riskShot;
      }
    });
  return updatedRiskShots;
};

export const addTags = (stateTags, imageUrn, imageTag) => stateTags.map((image) => {
  if (image.imageID === imageUrn) {
    return { ...image, tags: [...image.tags, imageTag] };
  }
  return image;
});

export const removeTags = (stateTags, imageUrn, imageTag) => stateTags.map((image) => {
  if (image.imageID === imageUrn) {
    return { ...image, tags: image.tags.filter((oldTag) => oldTag !== imageTag) };
  }
  return image;
});

export const addCustomTags = (stateTags, imageUrn, imageTag) => {
  let found = false;
  const tempArray = stateTags.map((image) => {
    if (image.imageID === imageUrn) {
      found = true;
      return { ...image, tags: [...image.tags, imageTag] };
    }
    return image;
  });
  // if we are adding tag to new image then that image is not present in customTags array, hence this check is needed.
  if (!found) {
    return [...stateTags, {
      imageID: imageUrn,
      tags: [imageTag],
    }];
  }
  return tempArray;
};

export const removeCustomTags = (stateTags, imageUrn, imageTag) => {
  let removeImage = false;
  const tempArray = stateTags.map((image) => {
    let tempTags = [];
    if (image.imageID === imageUrn) {
      tempTags = image.tags.filter((oldTag) => oldTag !== imageTag);
      if (tempTags.length === 0) {
        removeImage = true;
      }
    }
    return {
      ...image, tags: tempTags,
    };
  });
  /* incase there are no more tags present in the tags array of the image
    we are removing the image from customTags array */
  if (removeImage) {
    return stateTags.filter((image) => image.imageID !== imageUrn);
  }
  return tempArray;
};

export const fetchTestSquareErrorCode = (selectedFacetObj) => {
  if (selectedFacetObj.properties) {
    return selectedFacetObj.properties.testsquare_error_code || null;
  }
  return null;
};

export const userNameToLowerCase = (payload) => mapValues(payload, toLower);

/**
 * returns boolean, depending if annotation is inside the polygon
 * @param {<Object>} annotation - geometry of annotation
 * @param {<Object>} polygon - geometry of bolygon
 * @returns {Boolean}
 */
export const isAnnotationCompletelyOutside = (annotation, polygon) => turf.booleanDisjoint(annotation, polygon);

export const extractFileName = (request) => {
  const disposition = request.getResponseHeader('Content-Disposition');
  return disposition.split('filename=')[1];
};

export const downloadZip = ({ data, request }) => {
  const file = new Blob([data], { type: 'application/zip' });
  const downloadUrl = window.URL.createObjectURL(file);
  const fileName = extractFileName(request);
  const a = document.createElement('a');
  a.href = downloadUrl;
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(downloadUrl);
  document.body.removeChild(a);
};

export const mapReportPayload = (payload) => {
  const reportArr = payload;

  let prevTimestamp = 0;
  let index = 1;
  for (let i = reportArr.length - 1; i >= 0; i -= 1) {
    const isSame = moment.unix(reportArr[i].createdOn).isSame(moment.unix(prevTimestamp), 'minute');

    if (isSame) {
      index += 1;
    } else {
      index = 1;
    }

    prevTimestamp = reportArr[i].createdOn;
    const leftBracket = ' (';
    const rightBracket = ')';
    if (index > 1) {
      if (index === 2) {
        reportArr[i + 1].date = `${moment.unix(reportArr[i + 1].createdOn).format(REPORT_DATE_FORMAT)}${leftBracket}${(index - 1)}${rightBracket}`;
      }
      reportArr[i].date = `${moment.unix(reportArr[i].createdOn).format(REPORT_DATE_FORMAT)}${leftBracket}${index}${rightBracket}`;
    } else {
      reportArr[i].date = moment.unix(reportArr[i].createdOn).format(REPORT_DATE_FORMAT);
    }
  }

  return reportArr;
};

/**
 * return facet object with filtered anomalies
 * @param {Array.<Object>} facets - facets array for the active order
 * @param {string} selectedFacet - selected facet id
 * @param {Array.<Object>} anomalies - array of anomalies containing anomaly data
 * @returns {Object}
 */
export const getFacetWithFilteredAnomalies = (facetsArray, selectedFacet, anomalies) => {
  const selectedFacetObject = facetsArray.find((f) => f.facetId === selectedFacet);
  const anomalyIdsMap = anomalies.map((anomaly) => get(anomaly, 'data.0.properties.anomalyId'));
  const facetWithFilteredAnomalies = isEmpty(selectedFacetObject) ? {}
    : {
      ...selectedFacetObject,
      anomalyList: !isEmpty(selectedFacetObject)
        ? selectedFacetObject.anomalyList.filter((anomaly) => anomalyIdsMap.includes(anomaly.anomalyId))
        : [],
    };
  return facetWithFilteredAnomalies;
};
/**
 * return object with anomalies breakdown
 * @param {Array.<Object>} anomalies - Array of anomalies
 * @returns {Object} - An object with breakdown of anomalies
 */
export const getAnomaliesBreakdown = (anomalies) => (!isEmpty(anomalies) ? {
  totalCV: anomalies.filter((x) => !x.isUserAdded),
  confirmedCV: anomalies.filter((x) => !x.isUserAdded && x.decision === ANOMALY_DECISIONS.CONFIRMED),
  potentialCV: anomalies.filter((x) => !x.isUserAdded && x.decision === ''),
  removed: anomalies.filter((x) => x.decision === ANOMALY_DECISIONS.REMOVED),
  userAdded: anomalies.filter((x) => x.isUserAdded),
  totalConfirmed: anomalies.filter((x) => (!x.isUserAdded && x.decision === ANOMALY_DECISIONS.CONFIRMED) || x.isUserAdded),
} : {
  totalCV: [],
  confirmedCV: [],
  potentialCV: [],
  removed: [],
  userAdded: [],
  totalConfirmed: [],
});

/**
 * returns filtered anomalies from rawImagery AnomalyList array using confidence factor
 * @param {Array.<Object>} anomalyList - Array of anomalies for the raw image
 * @param {String} confidence factor - confidence factor i.e. 'Fewer', 'Recommended', 'More';
 * @returns {Array.<Object>} - An array of anomalies objects
 */
export const getUpdatedAnomaliesRawImagery = (anomalyList, confidenceFactor, assessAppSettings) => {
  if (!confidenceFactor || isEmpty(anomalyList)) return [];
  const allAnnotations = anomalyList.map((anomaly) => getAnnotationFromAnomaly(anomaly));
  const updatedAnomalies = allAnnotations.filter(
    (anomaly) => confidenceFactorFilter(anomaly, confidenceFactor, assessAppSettings),
  );
  return updatedAnomalies;
};

/**
 * returns filtered anomalies from facets array using confidence factor
 * @param {Array.<Object>} facets - Array of facets
 * @param {String} confidence factor - confidence factor i.e. 'Fewer', 'Recommended', 'More';
 * @returns {Array.<Object>} - An array of anomalies objects
 */
export const getUpdatedAnomaliesFromFacets = (facets, confidenceFactor, assessAppSettings) => {
  if (isEmpty(facets) || !confidenceFactor) return [];
  const updatedAnomalies = parseAnomaliesFromFacets(facets).filter(
    (anomaly) => confidenceFactorFilter(anomaly, confidenceFactor, assessAppSettings),
  );
  return updatedAnomalies;
};
/**
 * returns filtered anomalies from anomalies list which are in test square
 * @param {Array.<Object>} anomalyList - Array of anomalies
 * @param {Array} testSquareAnomalies - Array of anomalies within test square;
 * @returns {Array} - An array of filtered anomaly ids
 */
export const getFilteredAnomaliesFromTestSquare = (anomalyList, testSquareAnomalies) => {
  if (isEmpty(anomalyList) || isEmpty(testSquareAnomalies)) return testSquareAnomalies;
  const anomalyIdsMap = anomalyList.map((anomaly) => get(anomaly, 'data.0.properties.anomalyId'));
  const filteredAnomalies = testSquareAnomalies.filter((anomaly) => anomalyIdsMap.includes(anomaly));
  return filteredAnomalies;
};

/**
 * returns converted anomalies data from point coordinates to polygon coordinates
 * @param {Object} asset - anomaly asset
 * @param {Array} geometry - array of anomaly coordinate
 * @returns {Object}
 */
// eslint-disable-next-line consistent-return
export const convertCoordinatesToXYs = (asset, geometry) => {
  if (isEmpty(asset) || isEmpty(geometry)) return {};
  const translator = asset.pointTranslator;
  const centerLonLat = LonLat.getInstance({
    lon: geometry.coordinates[0],
    lat: geometry.coordinates[1],
    elevation: geometry.coordinates[2],
  });
  const centerXY = translator.lonLatToXY(centerLonLat);
  const anomalySizePx = 50;
  const anomalyXYs = [
    ImageryWarehouseImageXYCoordinate.getInstance({
      x: centerXY.x - anomalySizePx,
      y: centerXY.y - anomalySizePx,
    }),
    ImageryWarehouseImageXYCoordinate.getInstance({
      x: centerXY.x + anomalySizePx,
      y: centerXY.y - anomalySizePx,
    }),
    ImageryWarehouseImageXYCoordinate.getInstance({
      x: centerXY.x + anomalySizePx,
      y: centerXY.y + anomalySizePx,
    }),
    ImageryWarehouseImageXYCoordinate.getInstance({
      x: centerXY.x - anomalySizePx,
      y: centerXY.y + anomalySizePx,
    }),
  ];
  anomalyXYs.push(anomalyXYs[0]);

  const anomalyLonLats = anomalyXYs.map((xy) => translator.xyToLonLat(xy));
  const anomalyCoords = anomalyLonLats.map((lonlat) => [
    lonlat.lon,
    lonlat.lat,
    lonlat.elevation,
  ]);

  return { anomalyCoords, anomalyXYs };
};

export const convertCoordinatesToCentroidCoords = (asset, coordinates) => {
  if (isEmpty(asset) || isEmpty(coordinates)) return {};
  let x = 0;
  let y = 0;
  const translator = asset.pointTranslator;
  coordinates.forEach((coords) => {
    const lonLat = LonLat.getInstance({
      lon: coords[0],
      lat: coords[1],
      elevation: coords[2],
    });
    const xyCoords = translator.lonLatToXY(lonLat);
    x += xyCoords.x;
    y += xyCoords.y;
  });
  x /= coordinates.length;
  y /= coordinates.length;

  return translator.xyToLonLat({ x, y });
};

/**
 * returns converted anomalies data from point coordinates to polygon coordinates
 * @param {Object} asset - anomaly asset
 * @param {Array} polygonCoordinates - polygon coordinates
 * @returns {Object}
 */
// eslint-disable-next-line consistent-return
export const getPolygonPixelCoords = (polygonCoordinates, asset) => {
  if (isEmpty(asset) || isEmpty(polygonCoordinates)) return {};
  const polygonAnnotationXYs = [];
  const translator = asset.pointTranslator;
  polygonCoordinates.forEach((coords) => {
    const lonLat = LonLat.getInstance({
      lon: coords[0],
      lat: coords[1],
      elevation: coords[2],
    });
    polygonAnnotationXYs.push(translator.lonLatToXY(lonLat));
  });

  return [polygonAnnotationXYs.map((annotationXY) => [annotationXY.x, annotationXY.y])];
};

export const validatePointWithPlaneEquation = (point, planeConstants) => {
  const {
    a, b, c, d,
  } = planeConstants;
  return Number(((a * point[0]) + (b * point[1]) + (c * point[2]) + d).toFixed(9)) === 0;
};

/**
 * validates facet annotations and console logs errors
 * @param {Object} annotations - facet annotations
 *
 */
export const validateFacetAnnotations = (facetId, facetAnnotations, plane) => {
  let errorMessage = '';
  if (!facetAnnotations) {
    errorMessage = errorMessage.concat(`FacetAnnotationDetails for ${facetId} :: No Annotations found\n`);
  } else {
    get(facetAnnotations, 'facets.0.annotations', [])
      .forEach(({ annotationId, geometry }) => {
        if (!validateGeojson.isPolygon(geometry)) {
          errorMessage = errorMessage.concat(`FacetAnnotationDetails for ${facetId} :: ${annotationId}:geometry is not a valid Polygon\n`);
        }
        if (plane && !get(geometry, 'coordinates.0', []).every((coord) => validatePointWithPlaneEquation(coord, plane))) {
          errorMessage = errorMessage.concat(`FacetAnnotationDetails for ${facetId} :: ${annotationId}:geometry do not obey plane equation\n`);
        }
      });
  }
  /* eslint-disable no-console */
  if (errorMessage.length > 0) {
    console.log(errorMessage);
  } else {
    console.log(`FacetAnnotationDetails for ${facetId} :: Data is valid\n`);
  }
  /* eslint-enable no-console */
};

/**
 * validates roof facet details from /assets call and console logs errors
 * @param {Object} facetDetails - roof facet details from /assets call
 *
 */
export const validateRoofFacetDetails = (facetDetails) => {
  let errorMessage = '';
  const {
    roof: {
      roofId,
      roofOutline: {
        boundingBox: roofBoundingBox,
        centroid: roofCentroid,
        value: roofValue,
      },
    },
    facets = [],
  } = facetDetails;
  if (!roofId) { errorMessage = errorMessage.concat('RoofFacetDetails :: roofId missing\n'); }
  try {
    turf.geojsonType(roofBoundingBox, 'Polygon', 'roofOutline:boundingBox');
    turf.geojsonType(roofCentroid, 'Point', 'roofOutline:centroid');
    if (!validateGeojson.isPolygon(roofValue) && !validateGeojson.isMultiPolygon(roofValue)) {
      errorMessage = errorMessage.concat('RoofFacetDetails :: roofOutline:value is not a valid Polygon|MultiPolygon\n');
    }
  } catch (e) {
    errorMessage = errorMessage.concat(`RoofFacetDetails :: ${e.message}\n`);
  }
  facets.forEach((facet) => {
    const {
      facetAliasName,
      facetId,
      facetOutline: {
        centroid: facetOutlineCentroid,
        value: facetOutlineValue,
      },
      properties = {},
      anomalyList = [],
      testsquare,
    } = facet;
    const plane = get(properties, 'plane');
    if (!facetId) { errorMessage = errorMessage.concat(`RoofFacetDetails :: facet: ${facetAliasName} - facetId missing\n`); }
    if (!facetAliasName) { errorMessage = errorMessage.concat(`RoofFacetDetails :: facet: ${facetId} - facetAliasName missing\n`); }
    if (!plane) { errorMessage = errorMessage.concat(`RoofFacetDetails :: facet: ${facetAliasName} - plane constants missing\n`); }
    try {
      turf.geojsonType(facetOutlineCentroid, 'Point', `facet:${facetAliasName}:centroid`);
      turf.geojsonType(facetOutlineValue, 'Polygon', `facet:${facetAliasName}:value`);
      if (!validateGeojson.isPolygon(facetOutlineValue)) {
        errorMessage = errorMessage.concat(`RoofFacetDetails :: facet:${facetAliasName}:value is not a valid Polygon\n`);
      }
    } catch (e) {
      errorMessage = errorMessage.concat(`RoofFacetDetails :: ${e.message}\n`);
    }
    if (anomalyList) {
      anomalyList.forEach((anomaly) => {
        const { geometry, facetId: anomalyFacetId, anomalyId } = anomaly;
        if (facetId !== anomalyFacetId) {
          errorMessage = errorMessage.concat(`RoofFacetDetails :: facet:${facetAliasName}: Anomaly: ${anomalyId} - facetId miss-match\n`);
        }
        try {
          turf.geojsonType(geometry, 'Polygon', `facet:${facetAliasName}: Anomaly:${anomalyId}: geometry`);
          if (plane && !get(geometry, 'coordinates.0', []).every((coord) => validatePointWithPlaneEquation(coord, plane))) {
            errorMessage = errorMessage.concat(`RoofFacetDetails :: Anomaly:${anomalyId} of facet:${facetAliasName} do not obey plane equation\n`);
          }
          const anomalyCoords = get(getCentroidForGeometry(geometry), 'geometry.coordinates', []);
          if (!polygonContainsPoint(facetOutlineValue.coordinates, anomalyCoords)) {
            errorMessage = errorMessage.concat(`RoofFacetDetails :: Anomaly:${anomalyId} is outside facet:${facetAliasName}\n`);
          }
        } catch (e) {
          errorMessage = errorMessage.concat(`RoofFacetDetails :: ${e.message}\n`);
        }
      });
    }
    if (testsquare) {
      const {
        testSquareId, facetId: testSquareFacetId, centroid, geometry,
      } = testsquare;
      if (facetId !== testSquareFacetId) {
        errorMessage = errorMessage.concat(`RoofFacetDetails :: facet:${facetAliasName}: Test Square:${testSquareId} - facetId miss-match\n`);
      }
      try {
        turf.geojsonType(centroid, 'Point', `facet:${facetAliasName}:Test Square:${testSquareId}:centroid`);
        turf.geojsonType(geometry, 'Polygon', `facet:${facetAliasName}:Test Square:${testSquareId}:geometry`);
      } catch (e) {
        errorMessage = errorMessage.concat(`RoofFacetDetails :: ${e.message}\n`);
      }
    }
  });
  /* eslint-disable no-console */
  if (errorMessage.length > 0) {
    console.log(errorMessage);
  } else {
    console.log('RoofFacetDetails :: Data from \\assets api is valid');
  }
  /* eslint-enable no-console */
};

function isUnderHangingPoint(current2dPoint, current3dPoint, facet) {
  let elevationDiff = 0;
  const isOverlap = turf.booleanPointInPolygon(
    turf.point(current2dPoint),
    turf.polygon(facet['2d'].coordinates),
    { ignoreBoundary: true },
  );
  if (isOverlap) {
    const [x, y, z] = current3dPoint;
    const {
      a, b, c, d,
    } = facet.plane;
    const z1 = (-(a * x + b * y + d)) / c;
    elevationDiff = z1 - z;
  }
  return [isOverlap, elevationDiff];
}

export function checkForUnderHang(facetDetails, baseImageMetadata, authtoken) {
  logger('debug', 'UnderHang check :: start');
  const start = performance.now();
  const { facets } = facetDetails;
  const asset = getMapviewerAsset({
    assetType: 'iwimage',
    tileUrls: [
      `${ASSESS_IMAGES_API_ENDPOINT}/${baseImageMetadata.image_urn}/tiles/{z}/{x}/{y}?format=IMAGE_FORMAT_JPEG_PNG&`
      + `${getAuthParam(authtoken)}`,
    ],
    metadata: baseImageMetadata,
  });
  const processedFacets = facets.map((facet) => {
    const facetPolygon = get(facet, 'facetOutline.value', {});
    const plane = get(facet, 'properties.plane', {});
    const newGeoJSON = GeoJSONUtil.translate(facetPolygon, (point) => {
      const lonLat = LonLat.getInstance({ lon: point[0], lat: point[1], elevation: point[2] || 0 });
      const contextCoord = asset.pointTranslator.lonLatToContextCoordinate(lonLat, true);
      return [contextCoord.x, contextCoord.y];
    });

    return {
      '3d': facetPolygon,
      '2d': newGeoJSON,
      plane,
      ignore: isEmpty(plane), // plane is must for calculations,
      originalFacet: facet,
    };
  });
  processedFacets.forEach((currentFacet) => {
    if (currentFacet.ignore) {
      return;
    }
    let underHang = true;
    let idx = 0;
    let totalElevationDiff = 0;
    while (idx < currentFacet['2d'].coordinates[0].length && underHang) {
      const current2dPoint = currentFacet['2d'].coordinates[0][idx];
      const current3dPoint = currentFacet['3d'].coordinates[0][idx];
      const elevationDiff = processedFacets.reduce((prevDiff, facet) => {
        if (currentFacet === facet || facet.ignore) return prevDiff;
        const [isOverlap, diff] = isUnderHangingPoint(current2dPoint, current3dPoint, facet);
        if (isOverlap) {
          logger('debug', `UnderHang check :: CurrentFacet: ${currentFacet.originalFacet.facetAliasName}, 
                          Facet: ${facet.originalFacet.facetAliasName}, 
                          Elevation Diff: ${diff}, 
                          Previous Elevation Diff: ${prevDiff}`);
        }
        return diff > 0 && diff > prevDiff ? diff : prevDiff;
      }, 0);
      logger('debug', `UnderHang check :: CurrentFacet: ${currentFacet.originalFacet.facetAliasName}, 
                      Point Idx: ${idx}, 
                      Total Elevation Diff: ${elevationDiff}`);
      if (elevationDiff === 0) {
        underHang = false;
      }
      totalElevationDiff += elevationDiff;
      idx += 1;
    }
    const { originalFacet } = currentFacet;
    originalFacet.underHang = (underHang && totalElevationDiff > 0.5);
    if (originalFacet.underHang) {
      logger('debug', `UnderHang check :: Facet ${currentFacet.originalFacet.facetAliasName} is UnderHang`);
    }
  });
  const end = performance.now();
  logger('debug', `UnderHang check :: took ${+(end - start).toFixed(2)}ms`);
  return facetDetails;
}

export const getQcStructureCapturedValues = (orderId, structures) => {
  let rejectedStructures = Immutable.Map({});
  if (structures.length > 0) {
    structures.forEach((str) => {
      rejectedStructures = rejectedStructures.setIn(
        [orderId, str.structureID],
        {
          rejected: !str.isCaptured, roofSkippedState: get(str, 'meta.roofSkippedState', ''),
        },
      );
    });
  }
  return rejectedStructures;
};

export const filterAnomalyImages = (anomalyImages) => {
  const response = [];
  anomalyImages.map((item) => response.push({ urn: item.imageURN, name: item.imageName }));
  return response;
};

export const getOrderedFalconTags = (tags) => {
  if (tags.length > 1 && tags.includes(GALLERY_IMAGE_EXPLORE_OVERVIEW)) {
    tags.splice(1, 0, ...tags.splice(tags.findIndex((tag) => tag === GALLERY_IMAGE_EXPLORE_OVERVIEW), 1));
  }
  return tags;
};

export const publishPendoEvent = (eventName, meta) => {
  const { pendo } = window;
  if (pendo && pendo.isReady && pendo.isReady()) {
    logger('debug', 'pendo event ', eventName);
    pendo.track(eventName, { ...meta, env: REACT_APP_ENV });
  }
};

export const getClickedFacetAssets = (clickedFacet) => {
  const data = [{
    geometries: [get(clickedFacet, 'meta.geometry', {})],
    properties: {
      imageUrn: get(clickedFacet, 'imageURN', ''),
    },
  }];
  return { assetType: 'annotation', data };
};

export const filterFalconTagsByTab = (tags, tab) => (
  tags.filter((tag) => (
    [TABS.DETAILS_LITE, TABS.ROOF_LITE].includes(tab)
      ? ASSESS_LITE_ROOF_IMAGE_CATEGORIES.includes(tag)
      : !ASSESS_LITE_ROOF_IMAGE_CATEGORIES.includes(tag)
  ))
);

export const filterGalleryImagesByTab = (galleryImages, tab) => galleryImages.filter((image) => {
  const { tags = [] } = image;
  const commonTags = intersection(tags, ASSESS_LITE_ROOF_IMAGE_CATEGORIES);

  return [TABS.DETAILS_LITE, TABS.ROOF_LITE].includes(tab)
    ? commonTags.length > 0
    : commonTags.length <= 0;
});

export const mapRotationToCompassAngle = (rotation) => {
  if (rotation < 0) {
    return rotation * -1;
  }
  return 360 - rotation;
};

/**
 * return previous confirmed anomaly
 * @param {object} facet
 * @param {string} id
 */
export const getPreviousConfirmedAnomalyId = (facet, id, fromIndex) => {
  const list = facet.anomalyList;
  const index = id ? list.findIndex((a) => a && a.anomalyId === id) : fromIndex;
  if (index === -1 || list.length <= 0) return UNSET_ANOMALY_ID;
  for (let i = index - 1; i >= 0; i -= 1) {
    if (
      list[i]
      && (list[i].decision === ANOMALY_DECISIONS.CONFIRMED)
    ) {
      return list[i].anomalyId;
    }
  }
  for (let i = list.length - 1; i > index; i -= 1) {
    if (
      list[i]
      && (list[i].decision === ANOMALY_DECISIONS.CONFIRMED)
    ) {
      return list[i].anomalyId;
    }
  }
  return UNSET_ANOMALY_ID;
};

/**
 * return next highest confidence confirmed anomaly
 * @param {object} facet
 * @param {string} id
 */
export const getNextBestConfirmedAnomalyId = (facet, id, fromIndex) => {
  const list = facet.anomalyList;
  const index = id ? list.findIndex((a) => a && a.anomalyId === id) : fromIndex;
  if (index === -1 || list.length <= 0) return UNSET_ANOMALY_ID;
  for (let i = index + 1; i < list.length; i += 1) {
    if (
      list[i]
      && (list[i].decision === ANOMALY_DECISIONS.CONFIRMED)
    ) {
      return list[i].anomalyId;
    }
  }
  for (let i = 0; i <= index - 1; i += 1) {
    if (
      list[i]
      && (list[i].decision === ANOMALY_DECISIONS.CONFIRMED)
    ) {
      return list[i].anomalyId;
    }
  }
  return UNSET_ANOMALY_ID;
};

export const getIntersection = (arrayOfObj1, arrayOfObj2, identifier1, identifier2) => intersectionWith(arrayOfObj1, arrayOfObj2,
  (obj1, obj2) => isEqual(get(obj1, identifier1), get(obj2, identifier2)));

export const getRoofReportCostLabelFromResp = (amount, currencyCode) => {
  switch (currencyCode) {
    case 'USD':
    case 'CAD': return `$${parseFloat(amount).toFixed(2)}`;
    case 'GBP': return `£${parseFloat(amount).toFixed(2)}`;
    default: return `${amount} ${currencyCode}`;
  }
};
