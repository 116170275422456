/* eslint-disable import/prefer-default-export */
import { v4 as uuidv4 } from 'uuid';
import { getUser } from 'utils/auth.utils';
import moment from 'moment';
import { DEFAULT_DATE_FORMAT } from 'constants.js';

export const newNoteTemplate = (includeNoteInReport) => {
  const user = getUser();
  return {
    noteId: uuidv4(),
    note: '',
    dateUpdated: moment().format(DEFAULT_DATE_FORMAT),
    selected: includeNoteInReport,
    author: {
      userName: user.userName || '',
      firstName: user.firstName || '',
      lastName: user.lastName || '',
    },
  };
};
export const convertTimesToClientTimeZone = (date) => {
  const clientTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return new Date(date).toLocaleString('en-US', { timeZone: clientTimeZone });
};
export const newQcNoteTemplate = () => {
  const user = getUser();
  return {
    // noteId: uuidv4(),
    note: '',
    dateUpdated: convertTimesToClientTimeZone(new Date()),
    author: {
      userName: user.userName || 'user.name@abc.com',
      firstName: user.firstName || 'user',
      lastName: user.lastName || 'name',
    },
  };
};

export const tagsFromImage = (currentImage) => {
  if (currentImage && currentImage.tags && currentImage.tags.length) {
    return currentImage.tags;
  }
  return [];
};

export const countSelectedTags = (tags) => (tags && tags.length
  ? tags.reduce((prevTag, currTag) => prevTag + (currTag.associationCount === 1 ? 1 : 0), 0)
  : 0);

export const mapNotes = (item) => ({
  noteId: item.noteID,
  note: item.note,
  dateUpdated: moment.unix(item.dateUpdated).format(DEFAULT_DATE_FORMAT),
  selected: item.includeInReport,
  author: item.author,
});

export const convertTimesToISOFormat = (date) => new Date(date).toISOString();
