import { makeStyles } from '@eagleview/ev-comp-library';

const useStyles = makeStyles((theme) => ({
  statusBar: {
    height: theme.evSpacing['ev-spacing--32'],
    position: 'absolute',
    bottom: 0,
    right: 0,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: theme.evPalette.salt[100],
    color: theme.evPalette.charcoal[800],
    paddingLeft: theme.evSpacing['ev-spacing--8'],
  },
  statusBarButton: {
    textTransform: 'unset',
    color: 'unset',
    padding: `0 ${theme.evSpacing['ev-spacing--16']}`,
    height: theme.evSpacing['ev-spacing--32'],
    borderRadius: 0,
    borderLeft: `1px solid ${theme.evPalette.charcoal[300]}`,
    marginLeft: 'auto',
  },
  statusBarText: {
    margin: `0 ${theme.evSpacing['ev-spacing--8']}`,
  },
  inactiveIcon: {
    color: theme.evPalette.charcoal[600],
  },
  activeIcon: {
    color: theme.evPalette.ocean[500],
  },
}));

export default useStyles;
