import React from 'react';
import { useSelector } from 'react-redux';
import { EVBox, EVTypography } from '@eagleview/ev-comp-library';
import ErrorBoundary from 'components/ErrorBoundary';
import { useTranslation } from 'react-i18next';
import useStyles from './PhotosUI.styles';

const Statusbar = ({ data }) => {
  const { t } = useTranslation();
  // Selectors
  const sidePanelWidth = useSelector((state) => state.adjusterReducer.sidePanelWidth);
  // Hooks
  const styles = useStyles({ sidePanelWidth });

  return (
    <ErrorBoundary componentName="PhotosGallery: Statusbar">
      <EVBox className={styles.statusBar}>
        {
          data.map((d) => (
            <EVTypography className={styles.imageNameStyle} component="span">
              {t(d.label)}
              {d.value}
            </EVTypography>
          ))
        }
      </EVBox>
    </ErrorBoundary>
  );
};

Statusbar.propTypes = {
  data: Array,
};
Statusbar.defaultProps = {
  data: [],
};

export default Statusbar;
