const prefix = 'droneToMeasure';

export const FETCH_ORTHO_IMAGE = `${prefix}/FETCH_ORTHO_IMAGE`;
export const FETCH_ORTHO_IMAGE_SUCCESS = `${prefix}/FETCH_ORTHO_IMAGE_SUCCESS`;
export const FETCH_ORTHO_IMAGE_FAILURE = `${prefix}/FETCH_ORTHO_IMAGE_FAILURE`;

export const FETCH_ORTHO_POLYGON = `${prefix}/FETCH_ORTHO_POLYGON`;
export const FETCH_ORTHO_POLYGON_SUCCESS = `${prefix}/FETCH_ORTHO_POLYGON_SUCCESS`;
export const FETCH_ORTHO_POLYGON_FAILURE = `${prefix}/FETCH_ORTHO_POLYGON_FAILURE`;

export const FETCH_ORDER_DETAILS = `${prefix}/FETCH_ORDER_DETAILS`;
export const FETCH_ORDER_DETAILS_SUCCESS = `${prefix}/FETCH_ORDER_DETAILS_SUCCESS`;
export const FETCH_ORDER_DETAILS_FAILURE = `${prefix}/FETCH_ORDER_DETAILS_FAILURE`;

export const FETCH_IMAGES_AND_DETAILS = `${prefix}/FETCH_IMAGES_AND_DETAILS`;
export const FETCH_IMAGES_AND_DETAILS_FAILURE = `${prefix}/FETCH_IMAGES_AND_DETAILS_FAILURE`;

export const CLOSE_ERROR_TOAST = `${prefix}/CLOSE_ERROR_TOAST`;
export const SET_VIEW = `${prefix}/SET_VIEW`;
export const ANNOTATION_TO_EDIT = `${prefix}/ANNOTATION_TO_EDIT`;
export const UPDATE_VERTICES_POLYGONS = `${prefix}/UPDATE_VERTICES_POLYGONS`;

export const INITIAL_VIEW_ORTHO = {
  lonLat: { lat: 32.5429065494186, lon: -96.88171383777551 },
  zoom: 20,
  rotation: -0,
};
