import get from 'lodash/get';

const prefix = 'shareOTPLoginReducer';

export const GENERATE_OTP = `${prefix}/GENERATE_OTP`;
export const GENERATE_OTP_SUCCESS = `${prefix}/GENERATE_OTP_SUCCESS`;
export const GENERATE_OTP_FAILURE = `${prefix}/GENERATE_OTP_FAILURE`;

export const VERIFY_OTP = `${prefix}/VERIFY_OTP`;
export const VERIFY_OTP_SUCCESS = `${prefix}/VERIFY_OTP_SUCCESS`;
export const VERIFY_OTP_FAILURE = `${prefix}/VERIFY_OTP_FAILURE`;

export const RESEND_OTP = `${prefix}/RESEND_OTP`;

export const RESEND_OTP_COUNTER = get(process.env, 'REACT_APP_RESEND_OTP_COUNTER', 30);
export const OTP_RETRY_LIMIT = get(process.env, 'REACT_APP_OTP_RETRY_LIMIT', 10);
