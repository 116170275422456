import {
  takeLatest, call, put,
} from 'redux-saga/effects';
import { parseServerError } from 'utils/utils';
import * as constants from './ShareOTPLoginPage.constant';
import * as api from './ShareOTPLoginPage.api';
import * as action from './ShareOTPLoginPage.actions';

export function* generateOtpSaga({ payload }) {
  try {
    const response = yield call(api.generateOtp, payload);
    yield put(action.generateOTPSuccessAction(response));
  } catch (error) {
    yield put(action.generateOTPFailureAction(parseServerError(error)));
  }
}

export function* verifyOtpSaga({ payload }) {
  try {
    const response = yield call(api.verifyOtp, payload);
    yield put(action.verifyOTPSuccessAction(response));
  } catch (error) {
    yield put(action.verifyOTPFailureAction(parseServerError(error)));
  }
}

export default function* ShareOTPLoginSaga() {
  yield takeLatest(constants.GENERATE_OTP, generateOtpSaga);
  yield takeLatest(constants.VERIFY_OTP, verifyOtpSaga);
}
