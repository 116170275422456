import { makeStyles } from '@eagleview/ev-comp-library';

export default makeStyles((theme) => ({
  panel: {
    margin: 'auto',
    zIndex: 8,
    position: 'absolute',
    top: (P) => `calc(50% - ${P.infoPanelHeight / 2}px)`,
    left: (P) => `calc(50% - ${P.infoPanelWidth / 2}px)`,
    boxShadow: theme.shadows[4],
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
  },
  button: {
    // width: theme.spacing(22),
    marginLeft: theme.spacing(2),
    textTransform: 'none',
  },
  bodyContainer: {
    padding: `${theme.spacing(3)}px ${theme.spacing(3)}px`,
    borderBottom: `1px solid ${theme.evPalette.charcoal[200]}`,
    borderTop: `1px solid ${theme.evPalette.charcoal[200]}`,
  },
  modalFooter: {
    paddingTop: theme.spacing(2),
    textAlign: 'end',
  },
  reportLink: {
    cursor: 'pointer',
    paddingLeft: '10px',
    color: theme.evPalette.ocean[600],
  },
  contactUs: {
    color: theme.evPalette.ocean[600],
    cursor: 'pointer',
  },
  purchaseMsg: {
    color: theme.evPalette.charcoal[700],
  },
  titleContainer: {
    display: 'flex',
  },
  noteIcon: {
    color: theme.evPalette.charcoal[600],
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  selectOrderMsg: {
    color: theme.evPalette.nickel[700],
    lineHeight: '27px',
  },
  backdrop: {
    zIndex: 7,
    backgroundColor: 'transparent',
  },
}));
