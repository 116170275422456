import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { push } from 'connected-react-router';
import { useDispatch, useSelector } from 'react-redux';
import HomeLayout from 'layout/homeLayout/HomeLayout';
import Authorize from 'layout/authorize';
import PageNotFound from 'layout/pageNotFound/pageNotFound';
import Adjuster from 'layout/adjuster/Adjuster';
import { history } from 'store/ConfigureStore';
import { ASSESS_BASE_PATH } from 'constants.js';
import ManualAtMultiStructure from 'layout/manual-at-multi-structure/ManualAtAdjustment';
import DroneToMeasure from 'layout/drone-to-measure/DroneToMeasure';
import PhotosGallery from 'layout/photos/PhotosUI';
import ShareOTPLoginPage from 'layout/share-otp-login';
import {
  ASSESS_PHOTO_VIEWER,
  ASSESS_DIGITAL_DELIVERY,
} from 'layout/entitleUser/EntitleUser.constants';
import { get } from 'lodash';
import PrivateRoute from './privateRoute';
import SharedRoute from './sharedRoute';
import { ASSESS_SHAREDURL_APP_ORIGIN } from './constants';

/**
 * Defines components to routes mapping.
 *
 * Every time a new view is created, entry
 * is required here to map the component to a specific route.
 *
 * [IMPORTANT]
 * All route entries are required to be done before the notFound component.
 *
 * @returns {XML}
 */
export default () => {
  const historyPathname = history.location.pathname;
  const isLinkShared = (window.location.origin === ASSESS_SHAREDURL_APP_ORIGIN) || false;
  const assessPhotoViewerEnabled = useSelector((state) => get(state.entitleUserReducer.featureFlags, ASSESS_PHOTO_VIEWER, false));
  const assessDigitalDeliveryFF = useSelector((state) => get(state.entitleUserReducer.featureFlags, ASSESS_DIGITAL_DELIVERY, false));

  if (
    window.location.pathname === ASSESS_BASE_PATH
    && historyPathname !== ASSESS_BASE_PATH
    && historyPathname !== '/'
  ) {
    const dispatch = useDispatch();
    dispatch(push('/'));
  }
  return (
    <>
      {isLinkShared
        ? (
          <Switch>
            <SharedRoute
              path="/order-view/:id"
              name="order"
              component={(props) => (assessDigitalDeliveryFF && <ShareOTPLoginPage {...props} />) || <Adjuster {...props} />}
            />
            <Route
              path="/*"
              render={() => (
                <PageNotFound validateCode={404} />
              )}
            />
          </Switch>
        )
        : (
          <Switch>
            <Route path="/authorize/callback" component={(props) => <Authorize {...props} />} />
            <PrivateRoute
              path="/adjuster-review/:id"
              name="adjuster"
              component={(props) => <Adjuster {...props} />}
            />
            <PrivateRoute
              exact
              path="/manual-at-adjustment/v2/:orderId"
              name="manual-at-adjustment"
              component={(props) => <ManualAtMultiStructure {...props} />}
            />
            <PrivateRoute
              exact
              path="/drone-to-measure/:orderId"
              name="drone-to-measure"
              component={(props) => <DroneToMeasure {...props} />}
            />
            {
              assessPhotoViewerEnabled && (
                <PrivateRoute
                  path="/photos-view/:id"
                  name="photos-view"
                  component={(props) => <PhotosGallery {...props} />}
                />
              )
            }
            <PrivateRoute exact path="/:tab?/:orderId?" name="home" component={(props) => <HomeLayout {...props} />} />
            <Redirect to="/" />
          </Switch>
        )}
    </>
  );
};
