import {
  call, put, takeLatest, cancelled,
} from 'redux-saga/effects';
import get from 'lodash/get';
import axios from 'axios';
import * as util from 'utils/utils';
import * as action from './QcHome.actions';
import * as api from './QcHome.api';
import {
  FETCH_MANUAL_AT_CLAIMS,
  FETCH_QC_ESCALATED_CLAIMS,
  FETCH_QC_PENDING_CLAIMS,
  FETCH_OPEN_ORDERS,
  FETCH_COMPLETED_ORDERS,
  RESET_ORDER_TO_READY_FOR_REVIEW,
  FETCH_ASSESS_APP_SETTINGS,
  FETCH_INTERNAL_SCHEDULER_ENTITLEMENTS,
  FETCH_ORGANIZATIONS,
  DUPLICATE_ORDER,
  REFLY_RQUIRED,
} from './QcHome.constants';

export function* loadQcEscalatedClaimsSaga() {
  try {
    const response = yield call(api.loadQcEscalatedClaims);
    const qcEscalatedClaims = get(response, 'Claims');
    yield put(action.fetchQcEscalatedClaimsSuccessAction(qcEscalatedClaims));
  } catch (error) {
    yield put(
      action.fetchQcEscalatedClaimsFailureAction(util.parseServerError(error)),
    );
  }
}

export function* loadQcPendingClaimsSaga() {
  try {
    const response = yield call(api.loadQcPendingClaims);
    const qcPendingClaims = get(response, 'Claims');
    yield put(action.fetchQcPendingClaimsSuccessAction(qcPendingClaims));
  } catch (error) {
    yield put(action.fetchQcPendingClaimsFailureAction(util.parseServerError(error)));
  }
}

export function* loadQcManualAtClaimsSaga() {
  try {
    const response = yield call(api.loadQcManualAtClaims);
    yield put(action.fetchManualAtClaimsSuccessAction(response));
  } catch (error) {
    yield put(action.fetchManualAtClaimsFailureAction(util.parseServerError(error)));
  }
}

export function* fetchOpenOrdersSaga() {
  try {
    const response = yield call(api.fetchOpenOrders);
    const openOrders = get(response, 'Orders');
    yield put(action.fetchOpenOrdersSuccessAction(openOrders));
  } catch (error) {
    yield put(action.fetchOpenOrdersFailureAction(util.parseServerError(error)));
  }
}
export function* fetchCompletedOrdersSaga() {
  try {
    const response = yield call(api.fetchCompletedOrders);
    const completedOrders = get(response, 'Orders');
    yield put(action.fetchCompletedOrdersSuccessAction(completedOrders));
  } catch (error) {
    yield put(action.fetchCompletedOrdersFailureAction(util.parseServerError(error)));
  }
}

export function* resetOrderToReadyForReviewSaga(actionData) {
  try {
    const { payload } = actionData;
    yield call(api.resetOrderToReadyForReview, payload);
    yield put(action.resetOrderToReadyForReviewSuccessAction(payload));
  } catch (error) {
    yield put(action.resetOrderToReadyForReviewFailureAction(util.parseServerError(error)));
  }
}

export function* fetchAssessAppSettingsSaga() {
  try {
    const response = yield call(api.fetchAssessAppSettingsApi);
    yield put(action.fetchAssessAppSettingsSuccessAction(response));
  } catch (error) {
    yield put(action.fetchAssessAppSettingsFailureAction(util.parseServerError(error)));
  }
}

export function* fetchInternalSchedulerEntitlementsSaga() {
  try {
    const response = yield call(api.fetchInternalSchedulerEntitlements);
    const internalSchedulerEntitlements = get(response, 'entitlements.internal_scheduler');
    yield put(action.fetchInternalSchedulerEntitlementsSuccessAction({ internalSchedulerEntitlements }));
  } catch (error) {
    yield put(action.fetchInternalSchedulerEntitlementsFailureAction(util.parseServerError(error)));
  }
}

export function* fetchOrganizationsSaga({ payload }) {
  const cancelSource = axios.CancelToken.source();
  try {
    const response = yield call(api.fetchOrganizations, payload, cancelSource.token);
    const orgList = get(response, 'organizations');
    yield put(action.fetchOrganizationsSuccessAction(orgList));
  } catch (error) {
    yield put(action.fetchOrganizationsFailureAction(util.parseServerError(error)));
  } finally {
    if (yield cancelled()) {
      yield call(cancelSource.cancel);
    }
  }
}

export function* duplicateOrderSaga({ payload }) {
  try {
    const response = yield call(api.duplicateOrder, payload);
    const duplicateOrderId = get(response, 'duplicateOrderId');
    yield put(action.duplicateOrderSuccessAction({ duplicateOrderId }));
  } catch (error) {
    yield put(action.duplicateOrderFailureAction(util.parseServerError(error)));
  }
}

export function* reflyRequiredSaga({ payload }) {
  try {
    const response = yield call(api.reflyRequired, payload);
    yield put(action.reflyRequiredSuccessAction({ response }));
    yield window.location.reload();
  } catch (error) {
    yield put(action.reflyRequiredFailureAction(util.parseServerError(error)));
  }
}

export default function* qcHomeSaga() {
  yield takeLatest(FETCH_QC_ESCALATED_CLAIMS, loadQcEscalatedClaimsSaga);
  yield takeLatest(FETCH_QC_PENDING_CLAIMS, loadQcPendingClaimsSaga);
  yield takeLatest(FETCH_MANUAL_AT_CLAIMS, loadQcManualAtClaimsSaga);
  yield takeLatest(FETCH_OPEN_ORDERS, fetchOpenOrdersSaga);
  yield takeLatest(FETCH_COMPLETED_ORDERS, fetchCompletedOrdersSaga);
  yield takeLatest(RESET_ORDER_TO_READY_FOR_REVIEW, resetOrderToReadyForReviewSaga);
  yield takeLatest(FETCH_ASSESS_APP_SETTINGS, fetchAssessAppSettingsSaga);
  yield takeLatest(FETCH_INTERNAL_SCHEDULER_ENTITLEMENTS, fetchInternalSchedulerEntitlementsSaga);
  yield takeLatest(FETCH_ORGANIZATIONS, fetchOrganizationsSaga);
  yield takeLatest(DUPLICATE_ORDER, duplicateOrderSaga);
  yield takeLatest(REFLY_RQUIRED, reflyRequiredSaga);
}
