import React, { useState } from 'react';
import { string, func, bool } from 'prop-types';
import get from 'lodash/get';
import {
  EVDialogTitle,
  EVDialogActions,
  EVTextField,
  EVButton,
  EVTypography,
  EVDialog,
  EVRadio,
  EVBox,
  EVCheckbox,
  EVSelect,
  EVMenuItem,
} from '@eagleview/ev-comp-library';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { QC_ENHANCEMENT } from 'layout/entitleUser/EntitleUser.constants';
import * as action from '../../Adjuster.actions';
import { QC_DECISIONS } from '../QCPendingPanel.constants';
import useStyles from './QcNotes.styles';

const QcNotes = (props) => {
  const {
    actionEvent,
    shouldBeSelfAssigned,
    orderId,
    isOpsUser,
  } = props;

  const classes = useStyles();
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const [isnotesRequired, setQcNotesRequired] = useState(false);
  const [option, setOption] = React.useState('Select Stage');
  const [qcRejectStageAction, setQcRejectStageAction] = React.useState('');

  const assignQcOpsTiepointingToSelf = useSelector((state) => state.adjusterReducer.assignQcOpsTiepointingToSelf);
  const qcNotes = useSelector((state) => state.adjusterReducer.qcNotes);
  const qcReason = useSelector((state) => state.adjusterReducer.qcReason);
  const qcReasons = useSelector((state) => state.adjusterReducer.qcReasons);
  const isQcD2m = useSelector((state) => state.adjusterReducer.isQcD2m);
  const enableQcEnhancement = useSelector((state) => get(state.entitleUserReducer.featureFlags, QC_ENHANCEMENT, false));

  const dispatch = useDispatch();
  const setAssignSelfFlagToStore = (payload) => dispatch(action.qcOpsAssignSelf(payload));
  const setNoteToStore = (payload) => dispatch(action.handleQcNoteChange(payload));
  const setQcReasonToStore = (payload) => dispatch(action.handleQcReasonChange(payload));
  const setQcReasonsToStore = (payload) => dispatch(action.handleQcReasonsChange(payload));
  const emptyQcReasonsToStore = () => dispatch(action.handleQcReasonsEmptyRequest());

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    emptyQcReasonsToStore();
    setOpen(false);
  };

  const handleNoteChange = (e) => {
    setNoteToStore({ notes: e.target.value, orderId });
  };

  const handleReasonTypeChange = (e) => {
    setQcReasonToStore(e.target.value);
  };

  const handleReasonsTypeChange = (e) => {
    if (e.target.value === 'other' && option === 'Escalate') {
      setQcNotesRequired(e.target.checked);
    }
    setQcReasonsToStore({ value: e.target.value, ischecked: e.target.checked });
  };

  const handleSelfAssign = (e) => {
    setAssignSelfFlagToStore(e.target.checked);
  };
  const handleRejectConfirmationdisable = () => {
    if (!enableQcEnhancement) {
      return qcRejectStageAction === '';
    }
    if (qcRejectStageAction === QC_DECISIONS.RESCHEDULE) {
      return false;
    }
    if (qcRejectStageAction === QC_DECISIONS.RETIEPOINT) {
      return !qcNotes.get(orderId);
    }
    if (qcRejectStageAction === QC_DECISIONS.REJECT) {
      if (qcReasons.length === 0) {
        return true;
      }
      if (isnotesRequired) {
        return !qcNotes.get(orderId);
      }
      return false;
    }
    if (qcRejectStageAction === QC_DECISIONS.ESCALATE_TECHNICAL) {
      if (qcReasons.length === 0) {
        return true;
      }
      return !qcNotes.get(orderId);
    }
    return true;
  };

  const handleRejectOptionChange = ({ target: { value } }) => {
    setOption(value);
    if (value === 'Reschedule') {
      emptyQcReasonsToStore();
      setQcNotesRequired(false);
      setQcRejectStageAction(QC_DECISIONS.RESCHEDULE);
    } else if (value === 'Retiepoint') {
      emptyQcReasonsToStore();
      setQcNotesRequired(true);
      setQcRejectStageAction(QC_DECISIONS.RETIEPOINT);
    } else if (value === 'Escalate') {
      setQcNotesRequired(qcReasons.indexOf('other') > -1);
      setQcRejectStageAction(QC_DECISIONS.REJECT);
    } else if (value === 'Escalate_Technical') {
      setQcNotesRequired(true);
      setQcRejectStageAction(QC_DECISIONS.ESCALATE_TECHNICAL);
    } else {
      emptyQcReasonsToStore();
      setQcNotesRequired(false);
      setQcRejectStageAction('');
    }
  };

  return (
    <>
      {
        enableQcEnhancement === true ? (
          <EVButton
            variant="contained"
            color="primary"
            onClick={handleClickOpen}
            className={classes.rejectPropertyActionButton}
          >
            {t('RejectProperty.title')}
          </EVButton>
        )
          : (
            <EVButton
              variant="contained"
              color="primary"
              onClick={handleClickOpen}
              className={classes.rejectActionButton}
            >
              {t('Reject.title')}
            </EVButton>
          )
      }
      <EVDialog
        open={open}
        onClose={handleClose}
        fullWidth
        classes={{ paper: classes.paper }}
      >
        <EVDialogTitle className={classes.title}>
          {t('adjuster.qcPendingPanelRejectOrder')}
        </EVDialogTitle>

        <EVTypography className={classes.qcRejectReasonHeading} variant="h6">{t('adjuster.qcPendingPanelNotesModalSelectHeading')}</EVTypography>
        <EVSelect
          value={option}
          onChange={(e) => handleRejectOptionChange(e)}
          variant="outlined"
          className={classes.rejectStageDropdown}
        >
          <EVMenuItem value="Select Stage">Select Stage</EVMenuItem>
          {
            isOpsUser === true
              ? <EVMenuItem value="Escalate">{t('adjuster.qcPendingPanelEscalate')}</EVMenuItem>
              : <EVMenuItem value="Reschedule">{t('adjuster.qcPendingPanelReschedule')}</EVMenuItem>
          }
          {
            isOpsUser === true && enableQcEnhancement === true
              ? <EVMenuItem value="Escalate_Technical">{t('adjuster.qcPendingPanelEscalateTechnical')}</EVMenuItem>
              : null
          }
          {
            isQcD2m === true
              ? null
              : <EVMenuItem value="Retiepoint">{t('adjuster.qcPendingPanelRetiepoint')}</EVMenuItem>
          }
        </EVSelect>

        {
          (option === 'Escalate' || option === 'Escalate_Technical') && enableQcEnhancement && (
            <div className={classes.escalateReviewReasons}>
              <EVTypography className={classes.rejectReasonHeading} variant="h6">{t('adjuster.qcPendingPanelNotesModalRejectReason')}</EVTypography>
              <EVBox className={classes.Box}>
                <EVCheckbox
                  className={classes.checkBox}
                  value={t('adjuster.qcPendingPanelNotesModalCheckboxBadAlignment')}
                  color="primary"
                  onChange={(e) => handleReasonsTypeChange(e)}
                />
                <EVTypography className={classes.radioText}>{t('adjuster.qcPendingPanelNotesModalCheckboxBadAlignment')}</EVTypography>
              </EVBox>
              <EVBox className={classes.Box}>
                <EVCheckbox
                  value={t('adjuster.qcPendingPanelNotesModalCheckboxMosaicGaps')}
                  className={classes.checkBox}
                  color="primary"
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                  onChange={(e) => handleReasonsTypeChange(e)}
                />
                <EVTypography className={classes.radioText}>{t('adjuster.qcPendingPanelNotesModalCheckboxMosaicGaps')}</EVTypography>
              </EVBox>
              <EVBox className={classes.Box}>
                <EVCheckbox
                  value={t('adjuster.qcPendingPanelNotesModalCheckboxFullFacets')}
                  className={classes.checkBox}
                  color="primary"
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                  onChange={(e) => handleReasonsTypeChange(e)}
                />
                <EVTypography className={classes.radioText}>{t('adjuster.qcPendingPanelNotesModalCheckboxFullFacets')}</EVTypography>
              </EVBox>
              <EVBox className={classes.Box}>
                <EVCheckbox
                  value={t('adjuster.qcPendingPanelNotesModalCheckboxPoorlyStitched')}
                  className={classes.checkBox}
                  color="primary"
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                  onChange={(e) => handleReasonsTypeChange(e)}
                />
                <EVTypography className={classes.radioText}>{t('adjuster.qcPendingPanelNotesModalCheckboxPoorlyStitched')}</EVTypography>
              </EVBox>
              <EVBox className={classes.Box}>
                <EVCheckbox
                  value={t('adjuster.qcPendingPanelNotesModalCheckboxImageryNotLoad')}
                  className={classes.checkBox}
                  color="primary"
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                  onChange={(e) => handleReasonsTypeChange(e)}
                />
                <EVTypography className={classes.radioText}>{t('adjuster.qcPendingPanelNotesModalCheckboxImageryNotLoad')}</EVTypography>
              </EVBox>
              <EVBox className={classes.Box}>
                <EVCheckbox
                  value={t('adjuster.qcPendingPanelNotesModalCheckboxMetalRoof')}
                  className={classes.checkBox}
                  color="primary"
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                  onChange={(e) => handleReasonsTypeChange(e)}
                />
                <EVTypography className={classes.radioText}>{t('adjuster.qcPendingPanelNotesModalCheckboxMetalRoof')}</EVTypography>
              </EVBox>
              <EVBox className={classes.Box}>
                <EVCheckbox
                  onChange={(e) => handleReasonsTypeChange(e)}
                  className={classes.checkBox}
                  value={t('adjuster.qcPendingPanelNotesModalRadio')}
                  color="primary"
                  inputProps={{ 'aria-label': 'other' }}
                />
                <EVTypography className={classes.radioText}>{t('adjuster.qcPendingPanelNotesModalRadio')}</EVTypography>
              </EVBox>
            </div>
          )
        }
        {!enableQcEnhancement && (
          <EVBox className={classes.Box}>
            <EVRadio
              checked={qcReason === 'other'}
              className={classes.checkBox}
              onChange={(e) => handleReasonTypeChange(e)}
              value="other"
              name="reason-demo-button"
              color="default"
              inputProps={{ 'aria-label': 'other' }}
            />
            <EVTypography className={classes.radioText}>{t('adjuster.qcPendingPanelNotesModalRadio')}</EVTypography>
          </EVBox>
        )}

        <EVTypography className={classes.notesHeading} variant="h6">{t('adjuster.qcPendingPanelNotesModalNotes')}</EVTypography>
        <EVTextField
          required={isnotesRequired}
          multiline
          rows={5}
          className={classes.notesEditField}
          value={qcNotes.get(orderId)}
          onChange={(e) => handleNoteChange(e)}
          variant="outlined"
        />
        {isnotesRequired && enableQcEnhancement
        && <EVTypography className={classes.required}>{t('adjuster.qcPendingPanelNotesModalNotesRequired')}</EVTypography>}
        {
          option === 'Retiepoint' && shouldBeSelfAssigned && (
            <div className={classes.selfAssignmentCheckbox}>
              <EVCheckbox
                className={classes.checkBox}
                checked={assignQcOpsTiepointingToSelf}
                value="secondary"
                color="primary"
                inputProps={{ 'aria-label': 'secondary checkbox' }}
                onChange={(e) => handleSelfAssign(e)}
              />
              <EVTypography className={classes.radioText}>{t('adjuster.qcPendingPanelNotesModalCheckbox')}</EVTypography>
            </div>
          )
        }
        <EVDialogActions>
          <EVButton variant="text" color="primary" onClick={handleClose}>
            {t('adjuster.qcPendingPanelNotesModalCancel')}
          </EVButton>
          <EVButton
            variant="contained"
            color="primary"
            disabled={handleRejectConfirmationdisable()}
            onClick={() => actionEvent(qcRejectStageAction)}
            className={classes.rejectConfirmationButton}
          >
            {t('Reject.title')}
          </EVButton>
        </EVDialogActions>

      </EVDialog>
    </>
  );
};

QcNotes.propTypes = {
  actionEvent: func.isRequired,
  shouldBeSelfAssigned: bool,
  orderId: string.isRequired,
  isOpsUser: bool.isRequired,
};

QcNotes.defaultProps = {
  shouldBeSelfAssigned: false,
};

export default QcNotes;
