import React, { useEffect, useState, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import clsx from 'clsx';
import {
  EVChevronLeft,
  EVChevronRight,
  EVDialog,
  EVBox,
  EVIconButton,
  EVClear,
  EVToolbarPanel,
  EVAdd,
  EVRemove,
  EVFullSize,
  EVCropFree,
  EVRotateRight,
  useTheme,
  EVPaperClipIcon,
  EVTypography,
  EVButton,
  EVVisibilityOptions,
  EVMenu,
  EVMenuItem,
  EVVisibility,
  EVVisibilityOff,
  EVDivider,
  EVNoteIcon,
  EVDialogContent,
  EVDialogContentText,
  EVDialogActions,
} from '@eagleview/ev-comp-library';
import chunk from 'lodash/chunk';
import get from 'lodash/get';
import debounce from 'lodash/debounce';
import { bool, func, string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { ASSESS_IMAGES_API_ENDPOINT, REACT_APP_ENV } from 'constants.js';
import * as sharedUtil from 'utils/utils';
import EVMapViewer, {
  INTERACTION_STATES,
} from '@eagleview/mapviewer-react';
import * as action from 'layout/adjuster/Adjuster.actions';
import EntitledComponent from 'components/EntitledComponent';
import { FEATURE_ENTITLEMENTS, ASSESS_SKYDIO_IMAGERY_IN_REPORT } from 'layout/entitleUser/EntitleUser.constants';
import { isEntitled } from 'utils/auth.utils';
import { useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { errorHandlerService } from 'utils/utils';
import cloneDeep from 'lodash/cloneDeep';
import selectedImg from 'assets/anomaly-selected.svg';
import hiddenImg from 'assets/hidden.svg';
import userAnomalyImg from 'assets/manual-count.svg';
import userAnomalySelectedImg from 'assets/manual-selected.svg';
import opportunityImg from 'assets/opportunity.svg';
import validatedImg from 'assets/validated.svg';
import {
  ANOMALY_DECISIONS,
  MIN_IMAGE_ROW_INDEX,
  MAX_IMAGE_ROW_INDEX,
  MAX_IMAGES_BY_ROW,
  KEY_CODE,
  DEFAULT_MAX_ZOOM,
  UNSET_ANOMALY_ID,
  UNSET_DELETE_INDEX,
  CONFIRMABLE_DECISIONS,
  GET_ANOMALY_NOTES,
} from '../Adjuster.constants';
import useStyles from './FacetImagesRawImageryDialog.styles';
import * as selectors from '../workflow-panel/WorkflowPanel.selectors';
import ReportSwitch from './ReportSwitchRawImagery';
import * as util from '../Adjuster.utils';
import AnomalyDescPanel from '../anomaly-description/AnomalyDescPanel';
import { annotationDescPanelConstants, isCompleteVisibleH, isCompleteVisibleV } from '../annotation-description';

const FacetImagesRawImageryDialog = ({
  open, handleClose, facetAliasName, facetId, facetDecision, facetIncludeInReport,
}) => {
  // utility hooks
  const styles = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();

  // actions
  const dispatch = useDispatch();
  const fetchFacetImageMetadata = (payload) => dispatch(action.fetchFacetImageMetaAction(payload));
  const fetchSkydioImagesIncludedInReport = (payload) => dispatch(action.fetchSkydioImagesIncludedInReportAction(payload));
  const saveSkydioImageReportInclusion = (payload) => dispatch(action.saveSkydioImageReportInclusionAction(payload));
  const deleteSkydioImageReportInclusion = (payload) => dispatch(action.deleteSkydioImageReportInclusionAction(payload));
  const saveFacetReportInclusion = (payload) => dispatch(action.saveFacetReviewDecisionAction(payload));
  const showCustomErrorToast = (payload) => dispatch(action.showErrorToastAction(payload));
  const addUserAnomaly = (payload) => dispatch(action.addUserAnomalyAction(payload));
  const fetchImageAnomalies = (payload) => dispatch(action.fetchImageAnomaliesAction(payload));
  const fetchAnomalyNoteDetails = (payload) => dispatch(action.getAnomalyNotesAction(payload));
  const deleteUserAnomaly = (payload) => dispatch(action.deleteUserAnomalyAction(payload));
  const setAnomalyDecision = (payload) => dispatch(action.setAnomalyDecisionAction(payload));
  const saveAnomalyDecision = (payload) => dispatch(action.saveAnomalyDecisionAction(payload));
  const saveDecision = (payload) => dispatch(action.saveFacetReviewDecisionAction(payload));
  const setSelectedAnomalyID = (payload) => dispatch(action.setRawImageSelectedAnomalyAction(payload));
  const anomalyNote = useSelector((state) => state.adjusterReducer.anomalyNotes)[0];
  const toggleAnomalyIncludeInReport = (payload) => dispatch(action.toggleAnomalyIncludeInReportAction(payload));
  const refreshTokenCompleted = () => dispatch(action.updateAccessTokenCompletedAction());

  // constants
  const defaultView = { lonLat: { lat: 0, lon: 0 }, zoom: 1, rotation: 0 };
  // selectors
  const anomalyImages = useSelector(selectors.anomalyImagesSelector);
  const orderCompleted = useSelector((state) => state.adjusterReducer.orderCompleted);
  const entitlements = useSelector((state) => state.entitleUserReducer.entitlements);
  const enableSkydioImageryReportInclusion = useSelector((state) => get(state.entitleUserReducer.featureFlags, ASSESS_SKYDIO_IMAGERY_IN_REPORT, false));
  const reportIncludedSkydioImages = useSelector((state) => state.adjusterReducer.reportIncludedSkydioImages);
  const roofId = useSelector((state) => state.adjusterReducer.roofId);
  const facetScanImageAssets = useSelector((state) => state.adjusterReducer.facetScanImageAssets);
  const selectedStructureId = useSelector((state) => state.adjusterReducer.selectedStructureId);
  const rawImageAnomalies = useSelector((state) => state.adjusterReducer.rawImageAnomalies);
  const selectedAnomalyID = useSelector((state) => state.adjusterReducer.rawImageSelectedAnomalyId);
  const facetPolygons = useSelector((state) => state.adjusterReducer.facetPolygons);
  const loading = useSelector((state) => state.adjusterReducer.loading);
  const authToken = useSelector((state) => state.adjusterReducer.accessToken);

  // local state
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedFacetImageIndex, setSelectedFacetImageIndex] = useState({
    x: MIN_IMAGE_ROW_INDEX,
    y: MIN_IMAGE_ROW_INDEX,
  });

  const [keyPress, setKeyPress] = useState();
  const [view, setView] = useState(defaultView);
  const [localView, setLocalView] = useState(defaultView);
  const [isMapReady, setIsMapReady] = useState(false);
  const [interactionObject, setInteractionObject] = useState({});
  const [imageAsset, setImageAsset] = useState({});
  const [mask, setMask] = useState([]);
  const [imageAnomalyAsset, setImageAnomalyAsset] = useState([]);
  const [showRemovedAnomalies, toggleRemovedAnomalies] = useState(false);
  const [showPotentialAnomalies, togglePotentialAnomalies] = useState(true);
  // const [selectedAnomalyID, setSelectedAnomalyID] = useState(-1);
  const [deleteAnomalyIndex, setDeleteAnomalyIndex] = useState(UNSET_DELETE_INDEX);
  const [filteredAnomalies, setFilteredAnomalies] = useState([]);
  const [showAnomalyDescription, setShowAnomalyDescription] = useState(false);
  const [anomalyCoordinates, setAnomalyCoordinates] = useState({});
  const [selectedAnomalyIsUserAdded, setIsSelectedAnomalyIsUserAdded] = useState(false);
  const [selectedAnomalyIsRemoved, setSelectedAnomalyIsRemoved] = useState(false);
  const [selectedAnomalyIsConfirmed, setSelectedAnomalyIsConfirmed] = useState(false);
  const [selectedAnomalyIsConfirmable, setSelectedAnomalyIsConfirmable] = useState(false);
  const [deleteUserAnomalyDialogOpen, setDeleteUserAnomalyDialogOpen] = useState(false);
  const [isAnomalyDescriptionPresent, setIsAnomalyDescriptionPresent] = useState(false);

  const hasManageDecisionEntitlement = isEntitled(entitlements, FEATURE_ENTITLEMENTS.MANAGE_DECISIONS);

  const mapViewerRef = useRef();
  const wrapperRef = useRef();
  const { id: orderId } = useParams();

  const handleMenuClick = (event) => setAnchorEl(event.currentTarget);
  const handleMenuClose = () => setAnchorEl(null);

  // derived values
  const MAPVIEWER_MAX_ZOOM = useSelector((state) => {
    const groups = get(state, 'adjusterReducer.assessAppSettings.groups', []);
    const zoomGroup = groups.find((group) => group.groupName === 'ZOOM_LEVEL');
    return parseFloat(get(zoomGroup, 'settings.MAX_ZOOM_LEVEL', DEFAULT_MAX_ZOOM));
  });

  let handleAnomalyPointClick;

  const handleDeleteUserAnomalyOpen = () => setDeleteUserAnomalyDialogOpen(true);
  const handleDeleteUserAnomalyClose = () => setDeleteUserAnomalyDialogOpen(false);

  const handleDeleteUserAnomalyConfirm = () => {
    if (!orderId || !selectedAnomalyID || selectedAnomalyID === UNSET_ANOMALY_ID) return;
    const index = filteredAnomalies.findIndex(
      (a) => a && a.anomalyId === selectedAnomalyID,
    );
    setDeleteAnomalyIndex(index);
    setSelectedAnomalyID(UNSET_ANOMALY_ID);
    deleteUserAnomaly({
      orderId, facetId, anomalyId: selectedAnomalyID, isRawImageryWorkflow: true,
    });
    setDeleteUserAnomalyDialogOpen(false);
  };

  const getBestUnConfirmedAnomalyId = () => get(filteredAnomalies.find(({ anomalyId, decision }) => {
    const isPotential = anomalyId !== selectedAnomalyID && CONFIRMABLE_DECISIONS.includes(decision);
    return isPotential;
  }), 'anomalyId', UNSET_ANOMALY_ID);

  const facetImages = anomalyImages;
  const enableInclusion = enableSkydioImageryReportInclusion;
  const reportIncludedImages = reportIncludedSkydioImages;
  const chunkedFacetImageIds = chunk(
    facetImages.map((image) => image.urn),
    MAX_IMAGES_BY_ROW,
  );
  const selectedImage = get(
    chunkedFacetImageIds,
    [selectedFacetImageIndex.x, selectedFacetImageIndex.y],
    '',
  );

  const anomalyFilterCheck = (anomaly, decision) => {
    try {
      const anomalyDecision = decision === 'removed' ? ANOMALY_DECISIONS.REMOVED : '';
      return anomaly.decision !== anomalyDecision;
    } catch (err) {
      setTimeout(() => {
        errorHandlerService(err, err.message || '', 'FacetImagesRawImagery');
        showCustomErrorToast({ message: 'adjuster.somethingWentWrong' });
      }, 0);
      return false;
    }
  };

  const removedAnomaliesFilter = (anomaly) => {
    if (showRemovedAnomalies) return true;
    return anomalyFilterCheck(anomaly, 'removed');
  };

  const potentialAnomaliesFilter = (anomaly) => {
    if (showPotentialAnomalies) return true;
    return anomalyFilterCheck(anomaly, 'potential');
  };

  const showMapviewerError = () => showCustomErrorToast({ message: 'adjuster.mapViewerError', toastAutoHideDuration: null });

  const getAnomalyLatLonCentroidPostTranslation = (selectedImagedata, anomaly) => {
    let x = 0;
    let y = 0;
    const isUserAddedAnomaly = get(anomaly, 'isUserAdded', false);
    const anomalyPixelCoords = isUserAddedAnomaly
      ? get(anomaly, 'properties.pixel_coords.0', [])
      : get(anomaly, 'properties.pixel_coords', []);
    anomalyPixelCoords.forEach((anomalyPixelCoord) => {
      x += anomalyPixelCoord[0];
      y += anomalyPixelCoord[1];
    });
    x /= anomalyPixelCoords.length;
    y /= anomalyPixelCoords.length;
    const { maximum_zoom_level: maxZoomLevel } = get(selectedImagedata, 'metadata.zoom_range', {});
    const maxPixel = (2 ** maxZoomLevel) * 256;
    const lat = util.latFromMercatorY(y / maxPixel);
    const lon = util.lngFromMercatorX(x / maxPixel);
    return { lat, lon };
  };

  const setCurrentViewForNavigation = (anomalyId) => {
    const anomaly = filteredAnomalies.find((a) => a && a.anomalyId === anomalyId);
    const selectedImagedata = facetImages.find((x) => x.urn === selectedImage);
    selectedImagedata.image = selectedImagedata.metadata;

    const { lat, lon } = getAnomalyLatLonCentroidPostTranslation(selectedImagedata, anomaly);
    setView({
      ...view,
      lonLat: { lon, lat },
    });
  };

  const setPreviousAnomaly = () => {
    if (!filteredAnomalies.length) return;
    const anomalyId = selectedAnomalyID === UNSET_ANOMALY_ID ? null : selectedAnomalyID;
    const anomaly = filteredAnomalies.find((a) => a && a.anomalyId === anomalyId);
    if (showPotentialAnomalies || anomaly.decision === ANOMALY_DECISIONS.CONFIRMED) {
      const prvAnomalyId = anomaly.decision === ANOMALY_DECISIONS.CONFIRMED
        ? util.getPreviousConfirmedAnomalyId(
          { anomalyList: filteredAnomalies },
          anomalyId,
          deleteAnomalyIndex,
        )
        : util.getPreviousUnconfirmedAnomalyId(
          { anomalyList: filteredAnomalies },
          anomalyId,
          deleteAnomalyIndex,
        );
      if (prvAnomalyId !== UNSET_ANOMALY_ID) {
        setDeleteAnomalyIndex(UNSET_DELETE_INDEX);
        setSelectedAnomalyID(prvAnomalyId);
        setCurrentViewForNavigation(prvAnomalyId);
      }
    } else {
      setSelectedAnomalyID(UNSET_ANOMALY_ID);
    }
  };

  const setNextAnomaly = (forceToUnconfirmed = false) => {
    if (!filteredAnomalies.length) return;
    const anomalyId = selectedAnomalyID === UNSET_ANOMALY_ID ? null : selectedAnomalyID;
    const anomaly = filteredAnomalies.find((a) => a && a.anomalyId === anomalyId);
    if (showPotentialAnomalies || anomaly.decision === ANOMALY_DECISIONS.CONFIRMED) {
      const fromIndex = deleteAnomalyIndex > 0 ? deleteAnomalyIndex - 1 : 0;
      const nextAnomalyId = anomaly.decision === ANOMALY_DECISIONS.CONFIRMED && !forceToUnconfirmed
        ? util.getNextBestConfirmedAnomalyId(
          { anomalyList: filteredAnomalies },
          anomalyId,
          fromIndex,
        )
        : util.getNextBestUnconfirmedAnomalyId(
          { anomalyList: filteredAnomalies },
          anomalyId,
          fromIndex,
        );
      if (nextAnomalyId !== UNSET_ANOMALY_ID) {
        setDeleteAnomalyIndex(UNSET_DELETE_INDEX);
        setSelectedAnomalyID(nextAnomalyId);
        setCurrentViewForNavigation(nextAnomalyId);
      }
    } else {
      setSelectedAnomalyID(UNSET_ANOMALY_ID);
    }
  };

  const confirmOrRemoveAnomalyDecision = (isRemove) => {
    if (!orderId || !selectedAnomalyID || selectedAnomalyID === UNSET_ANOMALY_ID) return;
    const anomaly = filteredAnomalies.find((a) => a.anomalyId === selectedAnomalyID);
    if (anomaly) {
      const orgDecision = anomaly.decision;
      anomaly.decision = isRemove ? ANOMALY_DECISIONS.REMOVED : ANOMALY_DECISIONS.CONFIRMED;
      anomaly.includeInReport = isEntitled(entitlements, FEATURE_ENTITLEMENTS.MANAGE_REPORTS) ? !isRemove : anomaly.includeInReport;
      anomaly.isRawImageryWorkflow = true;
      anomaly.roofId = roofId;
      anomaly.facetId = facetId;
      anomaly.facetAliasName = facetAliasName;
      setAnomalyDecision(anomaly);
      const anomalyId = getBestUnConfirmedAnomalyId();
      if (anomalyId !== UNSET_ANOMALY_ID) {
        setNextAnomaly(!isRemove);
      }
      saveAnomalyDecision({
        orderId, anomalyData: anomaly, orgDecision, isRawImageryWorkflow: true,
      });
    }
    if (!facetIncludeInReport && !isRemove) {
      saveDecision({
        orderId,
        facetId,
        data: {
          decision: facetDecision,
          facetAliasName,
          roofId,
          includeInReport: true,
        },
      });
    }
  };

  const anomaliesBreakdown = util.getAnomaliesBreakdown(rawImageAnomalies);

  const isReportAttached = (urn) => reportIncludedImages.includes(urn);
  const reportInclusionPayload = () => ({ orderId, facetAliasName, data: { referenceIds: [selectedImage] } });

  const fetchImagesIncludedInReport = () => {
    fetchSkydioImagesIncludedInReport({ orderId, facetAliasName });
  };

  const saveImageReportInclusion = () => {
    saveSkydioImageReportInclusion(reportInclusionPayload());
  };

  const deleteImageReportInclusion = () => {
    deleteSkydioImageReportInclusion(reportInclusionPayload());
  };

  const handleReportAttached = (isIncluded) => {
    if (enableInclusion && isEntitled(entitlements, FEATURE_ENTITLEMENTS.MANAGE_REPORTS) && !orderCompleted) {
      if (isIncluded) {
        saveImageReportInclusion();
        if (!facetIncludeInReport) {
          saveFacetReportInclusion({
            orderId,
            facetId,
            data: {
              decision: facetDecision,
              facetAliasName,
              roofId,
              includeInReport: true,
            },
          });
        }
      } else {
        deleteImageReportInclusion();
      }
    }
  };

  const imageMetadata = get(
    facetImages.find((x) => x.urn === selectedImage),
    'metadata',
  );
  const imageName = get(imageMetadata, 'tags.name', '').replace(/\.[^/.]+$/, '');
  const bounds = {
    bl: { lon: -180, lat: -85 },
    ur: { lon: 180, lat: 85 },
    ul: { lon: -180, lat: 85 },
    br: { lon: 180, lat: -85 },
  };
  const maxZoom = get(imageMetadata, ['zoom_range', 'maximum_zoom_level'], MAPVIEWER_MAX_ZOOM);

  // eslint-disable-next-line consistent-return
  const isInViewport = (element) => {
    if (element) {
      const rect = element.getBoundingClientRect();
      return (
        rect.top >= 0
        && rect.left >= 0
        && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)
        && rect.right <= (window.innerWidth || document.documentElement.clientWidth)
      );
    }
  };

  const scrollToGalleryThumbnail = (x, y) => {
    const currentFacetImageEl = document.getElementById(`metalSoftGalleryThumbnail-${x}-${y}`);
    if (!isInViewport(currentFacetImageEl) && currentFacetImageEl) {
      currentFacetImageEl.scrollIntoView({ block: 'start', behavior: 'smooth' });
    }
  };

  const getAnomalyStyleBackground = (anomaly) => {
    let style = opportunityImg;
    try {
      const anomalyId = get(anomaly, 'anomalyId');
      const status = get(anomaly, 'decision');
      const userAddedAnomaly = get(anomaly, 'isUserAdded', false);
      if (anomalyId === selectedAnomalyID) {
        style = userAddedAnomaly ? userAnomalySelectedImg : selectedImg;
      } else if (userAddedAnomaly) {
        style = userAnomalyImg;
      } else {
        switch (status) {
          case 'CONFIRMED':
            style = validatedImg;
            break;
          case 'HIDDEN':
            style = hiddenImg;
            break;
          case 'REMOVED':
            style = hiddenImg;
            break;
          case 'UNCONFIRMED':
            style = opportunityImg;
            break;
          default:
            style = opportunityImg;
            break;
        }
      }
      return style;
    } catch (err) {
      setTimeout(() => {
        errorHandlerService(err, err.message || '', 'FacetImagesRawImageryDialog');
        showCustomErrorToast({ message: 'adjuster.somethingWentWrong' });
      }, 0);
      return style;
    }
  };

  const checkValidItem = (x, y) => get(chunkedFacetImageIds, `[${x}][${y}]`, false);

  const selectFacetImage = (x, y) => {
    setSelectedFacetImageIndex({ x, y });
    scrollToGalleryThumbnail(x, y);
  };

  const setAnomalyAsset = (filteredAnomalyList) => {
    const selectedImagedata = facetImages.find((x) => x.urn === selectedImage);
    selectedImagedata.image = selectedImagedata.metadata;
    const asset = util.getMapviewerAsset({
      assetType: 'annotation',
      uuid: selectedImage,
      data: [
        ...filteredAnomalyList
          .map((x) => {
            const { lat, lon } = getAnomalyLatLonCentroidPostTranslation(selectedImagedata, x);
            return {
              id: get(x, 'anomalyId'),
              geometries: [
                {
                  type: 'Point',
                  coordinates: [
                    lon,
                    lat,
                  ],
                },
              ],
              properties: {
                ...x.properties,
                annotationType: 'label',
                anomalyId: get(x, 'anomalyId'),
                cursorStyle: get(x, 'anomalyId') === selectedAnomalyID ? '' : 'pointer',
              },
              style: {
                text: {
                  backgroundImage: getAnomalyStyleBackground(x),
                },
              },
            };
          }).filter((x) => !isEmpty(x)),
      ],
    }, showMapviewerError);
    setImageAnomalyAsset([asset]);
  };

  useEffect(() => {
    if (enableInclusion && !isEmpty(orderId)) {
      fetchImagesIncludedInReport();
    }
    return () => {
      setImageAnomalyAsset([]);
      toggleRemovedAnomalies(false);
      togglePotentialAnomalies(true);
      setSelectedAnomalyID(UNSET_ANOMALY_ID);
      setDeleteAnomalyIndex('');
      setFilteredAnomalies([]);
    };
  }, []);

  useEffect(() => {
    if (selectedImage) {
      const imageUrl = `${ASSESS_IMAGES_API_ENDPOINT}/${selectedImage}/tiles/{z}/{x}/{y}?format=IMAGE_FORMAT_JPEG_PNG&`
      + `${sharedUtil.getAuthParam(authToken)}`;
      const imgAsset = {
        uuid: uuidv4(),
        assetType: 'mosaic',
        tileUrls: [imageUrl],
      };
      const maskAssetObject = {
        uuid: uuidv4(),
        assetType: 'annotation',
        data: [
          {
            geometries: [
              {
                type: 'Polygon',
                coordinates: [
                  [
                    [-180, -85],
                    [180, -85],
                    [180, 85],
                    [-180, 85],
                    [-180, -85],
                  ],
                ],
              },
            ],
            properties: {
              trackEnabled: false,
              cursorStyle: 'pointer',
            },
          },
        ],
        style: {
          polygon: {
            strokeColor: '#000',
            fillColor: '#000',
            strokeWidth: 0,
            fillOpacity: 0,
          },
        },
      };
      const newMask = util.getMapviewerAsset(
        maskAssetObject, showMapviewerError,
      );
      setMask(newMask ? [newMask] : []);

      setImageAsset(imgAsset);
      setView(defaultView);
      setLocalView(defaultView);
    }
    if (selectedImage && !imageMetadata) {
      fetchFacetImageMetadata({ imageURN: selectedImage, isAnomalyImages: true });
    }
    if (selectedImage) {
      fetchImageAnomalies({ orderId, structureID: selectedStructureId, imageURN: selectedImage });
    }
  }, [selectedImage, authToken]);

  useEffect(() => {
    const filteredAnomalyList = rawImageAnomalies
      .filter(potentialAnomaliesFilter)
      .filter(removedAnomaliesFilter);
    setFilteredAnomalies(filteredAnomalyList);
    if (!isEmpty(imageAsset) && filteredAnomalyList.length) {
      const initialAnomaly = util.getInitialAnomalyId({ anomalyList: filteredAnomalyList });
      setSelectedAnomalyID(initialAnomaly);
      setAnomalyAsset(filteredAnomalyList);
    } else {
      setImageAnomalyAsset([]);
      setSelectedAnomalyID(UNSET_ANOMALY_ID);
    }
  }, [rawImageAnomalies, showPotentialAnomalies, showRemovedAnomalies]);

  const setActionToInitialState = () => {
    setIsSelectedAnomalyIsUserAdded(false);
    setSelectedAnomalyIsRemoved(false);
    setSelectedAnomalyIsConfirmable(false);
    setSelectedAnomalyIsConfirmed(false);
  };

  const includeFacetAndDamageInReport = () => {
    setIsAnomalyDescriptionPresent(true);
    if (!facetIncludeInReport) {
      saveFacetReportInclusion({
        orderId,
        facetId,
        data: {
          facetDecision,
          facetAliasName,
          roofId,
          includeInReport: true,
        },
      });
    }
    if (selectedAnomalyIsUserAdded
      || (!selectedAnomalyIsUserAdded && selectedAnomalyIsConfirmed)) {
      toggleAnomalyIncludeInReport({
        orderID: orderId,
        anomalyID: selectedAnomalyID,
        data: {
          includeInReport: true,
        },
      });
    }
  };

  useEffect(() => {
    if (loading[GET_ANOMALY_NOTES] === false) {
      setIsAnomalyDescriptionPresent(!isEmpty(get(anomalyNote, 'note', '')));
    }
  }, [anomalyNote, loading[GET_ANOMALY_NOTES]]);

  useEffect(() => {
    if (selectedAnomalyID !== UNSET_ANOMALY_ID && !isEmpty(imageAsset) && filteredAnomalies.length) {
      setAnomalyAsset(filteredAnomalies);
      fetchAnomalyNoteDetails({
        orderId,
        anomalyId: selectedAnomalyID,
      });
    }
    if (selectedAnomalyID.toString() !== UNSET_ANOMALY_ID) {
      const anomaly = filteredAnomalies.find((a) => a && a.anomalyId === selectedAnomalyID);
      if (anomaly) {
        setIsSelectedAnomalyIsUserAdded(anomaly.isUserAdded);
        setSelectedAnomalyIsRemoved(anomaly.decision === ANOMALY_DECISIONS.REMOVED);
        setSelectedAnomalyIsConfirmed(anomaly.decision === ANOMALY_DECISIONS.CONFIRMED);
        setSelectedAnomalyIsConfirmable(CONFIRMABLE_DECISIONS.includes(anomaly.decision));
      } else {
        setActionToInitialState();
      }
    } else {
      setActionToInitialState();
    }
  }, [selectedAnomalyID]);

  useEffect(() => {
    setImageAnomalyAsset([]);
  }, [selectedFacetImageIndex]);

  const moveAnomalyNotesPanel = (geometries) => {
    if (isEmpty(geometries) || !mapViewerRef.current || !wrapperRef.current) return;

    const position = mapViewerRef.current.project(get(geometries, 'coordinates', []));

    const isDescPanelVisibleH = isCompleteVisibleH(position.x + 8, wrapperRef);
    const isDescPanelVisibleV = isCompleteVisibleV(position.y + 8, wrapperRef);
    let top = position.y + (isDescPanelVisibleV ? 8 : (-annotationDescPanelConstants.HEIGHT));
    let left = position.x + (isDescPanelVisibleH ? 8 : (-annotationDescPanelConstants.WIDTH));
    const { offsetWidth, offsetHeight } = wrapperRef.current;
    if (top < 0) top = 0;
    if (left < 0) left = 0;
    if (top > (offsetHeight - annotationDescPanelConstants.HEIGHT)) top = offsetHeight - annotationDescPanelConstants.HEIGHT;
    if (left > (offsetWidth - annotationDescPanelConstants.WIDTH)) left = offsetWidth - annotationDescPanelConstants.WIDTH;

    setAnomalyCoordinates({
      top,
      left,
    });
  };

  useEffect(() => {
    if (showAnomalyDescription) {
      const iAsset = get(imageAnomalyAsset, '[0].data', []).find((a) => get(a, 'id') === selectedAnomalyID);
      const geometry = get(iAsset, 'geometries[0]', {});
      moveAnomalyNotesPanel(geometry);
    } else if (!isEmpty(anomalyCoordinates)) {
      setAnomalyCoordinates({});
    }
  }, [showAnomalyDescription]);

  const setPreviousFacetImage = () => {
    const newYIndex = selectedFacetImageIndex.y - 1;
    if (checkValidItem(selectedFacetImageIndex.x, newYIndex)) {
      setSelectedFacetImageIndex({ x: selectedFacetImageIndex.x, y: newYIndex });
      scrollToGalleryThumbnail(selectedFacetImageIndex.x, newYIndex);
    } else if (checkValidItem(selectedFacetImageIndex.x - 1, MAX_IMAGE_ROW_INDEX)) {
      setSelectedFacetImageIndex({
        x: selectedFacetImageIndex.x - 1,
        y: MAX_IMAGE_ROW_INDEX,
      });
      scrollToGalleryThumbnail(selectedFacetImageIndex.x - 1, MAX_IMAGE_ROW_INDEX);
    }
  };

  const setNextFacetImage = () => {
    const newYIndex = selectedFacetImageIndex.y + 1;
    if (checkValidItem(selectedFacetImageIndex.x, newYIndex)) {
      setSelectedFacetImageIndex({ x: selectedFacetImageIndex.x, y: newYIndex });
      scrollToGalleryThumbnail(selectedFacetImageIndex.x, newYIndex);
    } else if (checkValidItem(selectedFacetImageIndex.x + 1, MIN_IMAGE_ROW_INDEX)) {
      setSelectedFacetImageIndex({
        x: selectedFacetImageIndex.x + 1,
        y: MIN_IMAGE_ROW_INDEX,
      });
      scrollToGalleryThumbnail(selectedFacetImageIndex.x + 1, MIN_IMAGE_ROW_INDEX);
    }
  };

  const setLeftOrRightFacetImage = (keyCode) => {
    const newYIndex = keyCode === KEY_CODE.LEFT
      ? selectedFacetImageIndex.y - 1
      : selectedFacetImageIndex.y + 1;
    if (checkValidItem(selectedFacetImageIndex.x, newYIndex)) {
      setSelectedFacetImageIndex({ x: selectedFacetImageIndex.x, y: newYIndex });
      scrollToGalleryThumbnail(selectedFacetImageIndex.x, newYIndex);
    }
  };

  const setUpOrDownFacetImage = (keyCode) => {
    const newXIndex = keyCode === KEY_CODE.UP
      ? selectedFacetImageIndex.x - 1
      : selectedFacetImageIndex.x + 1;
    if (checkValidItem(newXIndex, selectedFacetImageIndex.y)) {
      setSelectedFacetImageIndex({ x: newXIndex, y: selectedFacetImageIndex.y });
      scrollToGalleryThumbnail(newXIndex, selectedFacetImageIndex.y);
    }
  };

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (keyPress) {
      switch (keyPress.keyCode) {
        case KEY_CODE.LEFT:
          setLeftOrRightFacetImage(KEY_CODE.LEFT);
          break;
        case KEY_CODE.RIGHT:
          setLeftOrRightFacetImage(KEY_CODE.RIGHT);
          break;
        case KEY_CODE.UP:
          setUpOrDownFacetImage(KEY_CODE.UP);
          break;
        case KEY_CODE.DOWN:
          setUpOrDownFacetImage(KEY_CODE.DOWN);
          break;
        default:
          break;
      }
    }
    // eslint-disable-next-line
  }, [keyPress]);

  const handleKeyUp = (event) => {
    setKeyPress(event);
  };

  const addKeyUpHandler = () => {
    window.addEventListener('keyup', handleKeyUp);
  };

  const removeKeyUpHandler = () => {
    setSelectedFacetImageIndex({ x: MIN_IMAGE_ROW_INDEX, y: MIN_IMAGE_ROW_INDEX });
    window.removeEventListener('keyup', handleKeyUp);
  };

  const handleRotate = () => {
    setView({ ...localView, rotation: localView.rotation - 90 });
  };
  const handleZoomIn = () => {
    if (view.zoom < maxZoom) setView({ ...localView, zoom: localView.zoom + 1 });
  };
  const handleZoomOut = () => {
    if (view.zoom > 0) {
      setView({ ...localView, zoom: localView.zoom - 1 });
    }
  };
  const handleFitToWindow = () => {
    if (imageMetadata && mapViewerRef.current) {
      mapViewerRef.current.fitImageToWindow({ image: imageMetadata });
    }
  };
  const handleFullSize = () => {
    if (imageMetadata && mapViewerRef.current) {
      mapViewerRef.current.oneToOneFit({ image: imageMetadata });
    }
  };

  const updateLocalView = (updatedView) => {
    setLocalView({ ...updatedView });
  };
  const setDebounceLocalView = debounce(updateLocalView, 250);

  const onViewUpdated = (updatedView) => {
    setDebounceLocalView(updatedView);
    refreshTokenCompleted();
  };

  const drawingAsset = util.getMapviewerAsset({
    assetType: 'annotation',
    data: [],
    style: {},
  }, showMapviewerError) || [];

  const handleMapClick = (e) => {
    const clickedAnomalyId = get(
      e.annotation,
      'annotation.properties.anomalyId',
      false,
    );
    if (clickedAnomalyId) {
      setSelectedAnomalyID(clickedAnomalyId);
    }
  };

  const updateInteractionObject = (interaction, mapReadyParam = false) => {
    try {
      if (isEmpty(interaction) || typeof (interaction) !== 'object' || !get(interaction, 'type', null)) {
        throw new Error('Interaction object is not set properly');
      }
      let newInteractionObj = {
        enable: mapReadyParam || isMapReady,
      };
      if (interaction.enable === false) {
        newInteractionObj = {
          ...interactionObject,
          enable: false,
        };
      } else if (interaction.type === 'tracking') {
        newInteractionObj = {
          ...newInteractionObj,
          type: 'tracking',
          mode: { hover: false, click: true },
          handler: handleMapClick,
        };
      } else {
        newInteractionObj = {
          ...interaction,
          ...newInteractionObj,
        };
      }
      setInteractionObject(newInteractionObj);
    } catch (err) {
      setTimeout(() => {
        errorHandlerService(err, err.message || '', 'FacetImagesRawImageryDialog');
        showCustomErrorToast({ message: 'adjuster.somethingWentWrong' });
      }, 0);
    }
  };

  const resetInteractionToTracking = () => {
    updateInteractionObject({
      type: 'tracking',
    });
  };

  const initiateAnomalyDrawing = (anomalyDrawing) => {
    try {
      if (anomalyDrawing) {
        updateInteractionObject({
          type: 'drawPoint',
          handler: handleAnomalyPointClick,
          asset: drawingAsset,
        });
      } else {
        resetInteractionToTracking();
      }
      const updatedMask = cloneDeep(mask[0]);
      updatedMask.data[0].properties.customCursor = anomalyDrawing && styles.addAnomalies;
      setMask([updatedMask]);
    } catch (err) {
      setTimeout(() => {
        showCustomErrorToast({ message: 'adjuster.somethingWentWrong' });
      }, 0);
    }
  };

  const translatePointForMosaic = (e) => {
    const geometries = get(e, 'geometry.geometries', false);
    const geometry = geometries[0];

    const selectedImagedata = facetImages.find((x) => x.urn === selectedImage);
    selectedImagedata.image = selectedImagedata.metadata;

    // create raw image asset
    const selectedImageAsset = util.getMapviewerAsset({
      assetType: 'iwimage',
      tileUrls: [`${ASSESS_IMAGES_API_ENDPOINT}/${selectedImage}/tiles/{z}/{x}/{y}?format=IMAGE_FORMAT_JPEG_PNG&${sharedUtil.getAuthParam(authToken)}`],
      metadata: selectedImagedata,
    }, showMapviewerError);

    const { maximum_zoom_level: maxZoomLevel } = get(selectedImagedata, 'metadata.zoom_range', {});
    const maxPixel = (2 ** maxZoomLevel) * 256;

    const coordinates = selectedImageAsset.pointTranslator.xyToLonLat({
      x: (util.mercatorXfromLng(geometry.coordinates[0])) * maxPixel,
      y: (util.mercatorYfromLat(geometry.coordinates[1])) * maxPixel,
    });

    const pointGeometry = {
      type: 'Point',
      coordinates: [
        coordinates.lon,
        coordinates.lat,
        coordinates.elevation,
      ],
    };

    return {
      translatedAnomalyMosaic: util.convertCoordinatesToXYs(facetScanImageAssets[0], pointGeometry),
      pointGeometry,
    };
  };

  handleAnomalyPointClick = (e) => {
    try {
      if (e.state === INTERACTION_STATES.completed) {
        // to toggle Add Damage button & cursor
        setTimeout(() => initiateAnomalyDrawing(false));
        // send enable:false for drawPoint interaction to update the interactionObject,
        // since the interaction for type:drawPoint is completed on a click on the map.
        updateInteractionObject({
          type: 'drawPoint',
          enable: false,
        });

        if (facetScanImageAssets && !isEmpty(facetScanImageAssets[0])) {
          const { translatedAnomalyMosaic, pointGeometry } = translatePointForMosaic(e);

          const selectedFacetPolygon = facetPolygons.data.find(
            (facet) => get(facet, 'properties.facetId') === facetId,
          );
          const selectedFacetCoordinates = get(
            selectedFacetPolygon,
            'geometries[0].coordinates',
          );
          const isInsideSelectedFacet = util.polygonContainsPoint(selectedFacetCoordinates, pointGeometry.coordinates);
          if (!isInsideSelectedFacet) {
            setTimeout(() => showCustomErrorToast({ message: 'adjuster.outsideOfBounds' }), 0);
            return;
          }
          if (!isEmpty(translatedAnomalyMosaic)) {
            addUserAnomaly({
              long: pointGeometry.coordinates[0],
              lat: pointGeometry.coordinates[1],
              structureID: selectedStructureId,
              isRawImageryWorkflow: true,
              orderId,
              anomalyData: {
                decision: 'CONFIRMED',
                facetAliasName,
                facetId,
                imageURN: selectedImage,
                geometry: {
                  coordinates: [translatedAnomalyMosaic.anomalyCoords],
                  type: 'Polygon',
                },
                roofId,
              },
              includeInReport: facetIncludeInReport,
            });
          }
        }
      }
    } catch (err) {
      setTimeout(() => {
        errorHandlerService(err, err.message || '', 'FacetImagesRawImageryDialog');
        showCustomErrorToast({ message: 'adjuster.somethingWentWrong' });
      }, 0);
    }
  };

  const handleOnMapReady = () => {
    setIsMapReady(true);
    updateInteractionObject(
      { type: 'tracking' },
      true,
    );
  };

  const openAnomalyDescription = () => {
    setShowAnomalyDescription(true);
  };

  const getAnomalyType = () => {
    if (!selectedAnomalyIsUserAdded && !selectedAnomalyIsConfirmed) {
      return 'workflowpanel.anomaly';
    }
    if (selectedAnomalyIsUserAdded || selectedAnomalyIsConfirmed) {
      return 'workflowpanel.damage';
    }
    return '';
  };

  return (
    <EVDialog
      open={open}
      onClose={handleClose}
      onEntered={addKeyUpHandler}
      onExit={removeKeyUpHandler}
      PaperProps={{ className: styles.paperStyle }}
    >
      <EVBox sx={{
        display: 'flex',
        height: '100%',
        padding: `${theme.spacing(2)}px 0 ${theme.spacing(2)}px ${theme.spacing(2)}px`,
      }}
      >
        <EVBox position="relative" flex="1 1 auto" className={styles.gallerySelectedImageContainer} ref={wrapperRef}>
          {!isEmpty(imageAsset)
            ? (
              <EVMapViewer
                ref={mapViewerRef}
                view={view}
                assets={
                  [
                    imageAsset,
                    ...mask,
                    ...imageAnomalyAsset,
                  ]
                }
                maxZoom={maxZoom}
                onViewUpdated={onViewUpdated}
                interaction={interactionObject}
                onMapReady={handleOnMapReady}
                baseMap="blank"
                bound={bounds}
                initialConfig={{
                  style: { backgroundColor: '#000' },
                  envName: REACT_APP_ENV,
                }}
              />
            ) : null}
          {showAnomalyDescription && !isEmpty(anomalyCoordinates) ? (
            <AnomalyDescPanel
              coordinates={anomalyCoordinates}
              selectedAnomalyId={selectedAnomalyID}
              setShowAnomalyDescription={setShowAnomalyDescription}
              includeFacetAndDamageInReport={includeFacetAndDamageInReport}
            />
          ) : null}
          <EVBox position="absolute" right={theme.spacing(2)} bottom={theme.spacing(2)}>
            <EVToolbarPanel
              displayType="vertical"
              iconStyles={{ height: 'auto', borderRadius: '4px' }}
              className={styles.toolbarPanel}
            >
              <EVIconButton data-testid="full-size-icon" tooltip={t('workflowpanelTooltip.fullSize')} onClick={handleFullSize}>
                <EVFullSize />
              </EVIconButton>
              <EVIconButton data-testid="fit-to-window-icon" tooltip={t('workflowpanelTooltip.fitToWindow')} onClick={handleFitToWindow}>
                <EVCropFree />
              </EVIconButton>
              <EVIconButton data-testid="rotate-icon" tooltip={t('workflowpanelTooltip.rotate')} onClick={handleRotate}>
                <EVRotateRight />
              </EVIconButton>
            </EVToolbarPanel>
            <EVToolbarPanel
              displayType="vertical"
              iconStyles={{ height: 'auto', borderRadius: '4px' }}
              className={clsx(styles.toolbarPanel, styles.toolbarPanelSpacing)}
            >
              <EVIconButton data-testid="zoom-in-icon" tooltip={t('workflowpanelTooltip.zoomIn')} onClick={handleZoomIn}>
                <EVAdd />
              </EVIconButton>
              <EVIconButton data-testid="zoom-out-icon" tooltip={t('workflowpanelTooltip.zoomOut')} onClick={handleZoomOut}>
                <EVRemove />
              </EVIconButton>
            </EVToolbarPanel>
          </EVBox>
          <EVBox
            clone
            position="absolute"
            left={theme.spacing(2)}
            top="50%"
            className={styles.galleryArrowButton}
          >
            <EVIconButton data-testid="navigate-previous-facet-image-icon" onClick={setPreviousFacetImage}>
              <EVChevronLeft />
            </EVIconButton>
          </EVBox>
          <EVBox
            clone
            position="absolute"
            right={theme.spacing(2)}
            top="50%"
            className={styles.galleryArrowButton}
          >
            <EVIconButton data-testid="navigate-next-facet-image-icon" onClick={setNextFacetImage}>
              <EVChevronRight />
            </EVIconButton>
          </EVBox>
        </EVBox>
        <EVBox display="flex" flex="0 0 324px" overflow="hidden" flexDirection="column">
          <EVBox sx={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: `0 ${theme.spacing(2)}px`,
            height: 56,
          }}
          >
            <EVBox>
              <EVBox>
                <div className={styles.roofItemsDialogTitle}>
                  {`${t('facetImageDialog.facet')} [${facetAliasName}]`}
                </div>
              </EVBox>
              <EVBox>
                <div className={styles.imageName}>
                  {imageName}
                </div>
              </EVBox>
            </EVBox>
            <EVIconButton data-testid="modal-close-icon" onClick={handleClose} className={styles.closeButton}>
              <EVClear />
            </EVIconButton>
          </EVBox>

          <EVBox>
            <EVBox
              sx={{
                display: 'flex',
                height: 80,
                overflow: 'hidden',
                flexDirection: 'column',
                justifyContent: 'space-between',
                padding: `0 ${theme.spacing(2)}px`,
              }}
            >
              <EVBox className={styles.heightBarDamages}>
                <EVTypography className={styles.anomalyCountFont}>
                  {`${get(anomaliesBreakdown, 'totalConfirmed', []).length} ${t('workflowpanel.damages')}`}
                </EVTypography>
              </EVBox>
              <EVBox className={styles.heightBar}>
                <EVTypography>
                  {`${get(anomaliesBreakdown, 'potentialCV', []).length} ${t('workflowpanel.anomalies')}`}
                </EVTypography>
                <EVIconButton
                  id="toggleAnomalies"
                  data-testid="toggle-anomalies-icon"
                  className={styles.menuItemButton}
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  onClick={handleMenuClick}
                >
                  <EVVisibilityOptions htmlColor={theme.palette.common.white} />
                </EVIconButton>
                <EVMenu
                  id="simple-menu"
                  data-testid="toggle-anomalies-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                >
                  <EVMenuItem
                    id="setPotentialAnomalies"
                    data-testid="toggle-potential-anomalies"
                    onClick={() => togglePotentialAnomalies(!showPotentialAnomalies)}
                  >
                    {`${get(anomaliesBreakdown, 'potentialCV', []).length} ${t('workflowpanel.potential')}`}
                    {showPotentialAnomalies ? (
                      <EVVisibility className={styles.menuItemIcon} />
                    ) : (
                      <EVVisibilityOff className={styles.menuItemIcon} />
                    )}
                  </EVMenuItem>
                  <EVMenuItem
                    id="setRemovedAnomalies"
                    data-testid="toggle-removed-anomalies"
                    onClick={() => toggleRemovedAnomalies(!showRemovedAnomalies)}
                  >
                    {`${get(anomaliesBreakdown, 'removed', []).length} ${t('workflowpanel.removed')}`}
                    {showRemovedAnomalies ? (
                      <EVVisibility className={styles.menuItemIcon} />
                    ) : (
                      <EVVisibilityOff className={styles.menuItemIcon} />
                    )}
                  </EVMenuItem>
                </EVMenu>
              </EVBox>
            </EVBox>
            <EVDivider className={styles.divider} />

            <EVBox className={styles.subContainer}>
              <EVBox className={clsx([styles.heightBar, styles.flexEnd])}>
                <EVTypography className={clsx([styles.remainingSpace], styles.anomalyTypeText)}>
                  {t(getAnomalyType())}
                </EVTypography>

                <>
                  <EVButton
                    className={styles.iconButtons}
                    data-testid="navigate-previous-anomaly"
                    id="previousUnconfirmedAnomaly"
                    onClick={setPreviousAnomaly}
                  >
                    <EVChevronLeft />
                  </EVButton>
                  <EVButton
                    className={styles.iconButtons}
                    data-testid="navigate-next-anomaly"
                    id="nextUnconfirmedAnomaly"
                    onClick={() => setNextAnomaly(false)}
                  >
                    <EVChevronRight />
                  </EVButton>
                </>
                <EVIconButton
                  data-testid="anomaly-note-icon"
                  className={clsx({ [styles.iconButtons]: true },
                    { [styles.anomalyNotesButtonSelected]: showAnomalyDescription },
                    { [styles.anomalyNotesButtonHighlighted]: isAnomalyDescriptionPresent })}
                  onClick={openAnomalyDescription}
                  disabled={!selectedAnomalyID || loading[GET_ANOMALY_NOTES]}
                >
                  <EVNoteIcon />
                </EVIconButton>
              </EVBox>
              { (selectedAnomalyID !== UNSET_ANOMALY_ID
                && hasManageDecisionEntitlement) ? (
                  <EVBox className={styles.actionButtons}>
                    {!selectedAnomalyIsUserAdded && selectedAnomalyIsRemoved && (
                      <EVButton
                        id="reinstateAnomalyDecision"
                        variant="outlined"
                        className={styles.buttonRemove}
                        onClick={() => confirmOrRemoveAnomalyDecision(false)}
                      >
                        {t('workflowpanel.reinstate')}
                      </EVButton>
                    )}
                    {selectedAnomalyIsUserAdded && (
                      <EVButton
                        id="deleteAnomalyUserAdded"
                        className={styles.buttonRemove}
                        variant="outlined"
                        onClick={handleDeleteUserAnomalyOpen}
                      >
                        {t('workflowpanel.remove')}
                      </EVButton>
                    )}
                    {!selectedAnomalyIsUserAdded && (
                      <>
                        {selectedAnomalyIsConfirmable && (
                          <EVButton
                            id="confirmAnomalyDecision"
                            data-testid="confirm-anomaly-decision-button"
                            className={styles.buttonConfirm}
                            color="primary"
                            variant="contained"
                            disableElevation
                            onClick={() => confirmOrRemoveAnomalyDecision(false)}
                          >
                            {t('workflowpanel.markDamage')}
                          </EVButton>
                        )}
                        {
                          !selectedAnomalyIsRemoved && (
                            <EVButton
                              ml={1}
                              data-testid="remove-anomaly-decision-button"
                              id="removeAnomalyDecision"
                              className={styles.buttonRemove}
                              variant="outlined"
                              onClick={() => confirmOrRemoveAnomalyDecision(true)}
                            >
                              {selectedAnomalyIsConfirmed ? t('workflowpanel.remove') : t('workflowpanel.hide')}
                            </EVButton>
                          )
                        }
                      </>
                    )}
                  </EVBox>
                )
                : (
                  <EVBox>
                    <EVTypography>
                      {(selectedAnomalyIsConfirmed && t('workflowPanel.confirmed'))
                    || (selectedAnomalyIsRemoved && t('workflowPanel.removed'))}
                    </EVTypography>
                  </EVBox>
                )}
            </EVBox>

            <EVDivider className={styles.divider} />

          </EVBox>

          <EVDialog open={deleteUserAnomalyDialogOpen} onClose={handleDeleteUserAnomalyClose}>
            <EVDialogContent>
              <EVDialogContentText>{t('workflowpanel.userAnomalyDeletConfirm')}</EVDialogContentText>
            </EVDialogContent>
            <EVDialogActions>
              <EVButton onClick={handleDeleteUserAnomalyClose}>{t('cancel')}</EVButton>
              <EVButton onClick={handleDeleteUserAnomalyConfirm} color="primary" autoFocus>
                {t('delete')}
              </EVButton>
            </EVDialogActions>
          </EVDialog>

          <EVBox sx={{ paddingLeft: theme.spacing(2), maxWidth: '312px' }}>
            {chunkedFacetImageIds.map((subarr, x) => subarr.map((urn, y) => {
              const imageIsSelected = urn === selectedImage;
              return (
                <EVBox
                  key={urn}
                  className={styles.galleryThumbnailListItem}
                  onClick={() => !imageIsSelected && selectFacetImage(x, y)}
                >
                  <img
                    id={`metalSoftGalleryThumbnail-${x}-${y}`}
                    className={clsx(
                      styles.galleryImageThumbnail,
                      imageIsSelected && styles.galleryImageThumbnailActive,
                    )}
                    src={`${ASSESS_IMAGES_API_ENDPOINT}/${urn}/thumb?width=75&height=75&${sharedUtil.getAuthParam(authToken)}`}
                    alt="facetImagethumbnail"
                  />
                  {enableInclusion && isReportAttached(urn)
                    && (
                      <EVBox className={clsx(styles.thumbnailOverlay, imageIsSelected && styles.thumbnailOverlaySelected)}>
                        <EVPaperClipIcon className={clsx(styles.tagIconStyle, !isReportAttached(urn) && styles.iconOpacity)} />
                      </EVBox>
                    )}
                </EVBox>
              );
            }))}
          </EVBox>
          {enableInclusion
            && (
              <EntitledComponent entitlement={FEATURE_ENTITLEMENTS.VIEW_REPORTS}>
                <ReportSwitch
                  included={isReportAttached(selectedImage)}
                  disabled={!isEntitled(entitlements, FEATURE_ENTITLEMENTS.MANAGE_REPORTS) || orderCompleted}
                  setIncluded={handleReportAttached}
                  isSoftMetal
                />
              </EntitledComponent>
            )}
        </EVBox>
      </EVBox>
    </EVDialog>
  );
};

FacetImagesRawImageryDialog.propTypes = {
  open: bool.isRequired,
  handleClose: func.isRequired,
  facetAliasName: string.isRequired,
  facetId: string.isRequired,
  facetDecision: string.isRequired,
  facetIncludeInReport: bool.isRequired,
};

export default FacetImagesRawImageryDialog;
