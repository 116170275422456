import React, { useEffect, useState } from 'react';
import {
  EVBox,
  EVPaper,
  useTheme,
  EVReactVirtualized,
  EVUserOutlinedIcon,
  EVChip,
  EVButton,
  EVDialog,
} from '@eagleview/ev-comp-library';
import { arrayOf, number, shape } from 'prop-types';
import { push } from 'connected-react-router';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import RiskImage from 'assets/riskImage.png';
import clsx from 'clsx';
import { ASSESS_IMAGES_API_ENDPOINT } from 'constants.js';
import { isEntitled } from 'utils/auth.utils';
import {
  FEATURE_ENTITLEMENTS, RESCHEDULE_DRONE_INSPECTION_FLAG, ASSESS_MARKETING_PRODUCTNAMES, ASSESS_DISTRO, ASSESS_PHOTO_VIEWER,
} from 'layout/entitleUser/EntitleUser.constants';
import * as sharedUtil from 'utils/utils';
import { get, isEmpty } from 'lodash';
import useStyles from '../Home.styles';
import * as actions from '../Home.actions';
import { setSelectedRowDataAction } from '../../qcHome/QcHome.actions';
import HomeStatusChip from '../home-status-chip/HomeStatusChip';
import {
  claimStatus, TRANSLATION_MAPPING, CLOSED_OR_COMPLETED_ORDER_STATUS, MAP_CLAIM_TITLE_WITH_MARKETING_PRODUCT_NAME,
  READY_FOR_IMG_CAPTURE_STATUS,
} from '../Home.constants';
import HomeListItemWrapper from '../home-list-item/HomeListItem';
import RescheduleAppointmentDialog from './RescheduleAppointmentDialog';

const { List } = EVReactVirtualized;

/**
 * HomeVirtualizedList is the virtualized list used to render orders in adjuster home page
 */
const HomeVirtualizedList = (props) => {
  // utility hooks
  const styles = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();

  const {
    claimsList, width, height,
  } = props;

  // selectors
  const entitlements = useSelector((state) => state.entitleUserReducer.entitlements);
  const enableMarketingProductNames = useSelector((state) => get(state.entitleUserReducer.featureFlags, ASSESS_MARKETING_PRODUCTNAMES, false));
  const enableReschedule = useSelector((state) => get(state.entitleUserReducer.featureFlags, RESCHEDULE_DRONE_INSPECTION_FLAG, false));
  const isAssessDistro = useSelector((state) => get(state.entitleUserReducer.featureFlags, ASSESS_DISTRO, false));
  const fetchedAppointmentDetails = useSelector((state) => get(state.homeReducer, 'appointmentDetails', {}));
  const isPhotoViewerEnabled = useSelector((state) => get(state.entitleUserReducer.featureFlags, ASSESS_PHOTO_VIEWER, false));

  // actions
  const dispatch = useDispatch();
  const setShowAssignmentPanel = (payload) => dispatch(actions.setShowAssignmentPanelAction(payload));
  const setClaimUnderAssignment = (payload) => dispatch(actions.setClaimUnderAssignmentAction(payload));
  const setShowTrackPanel = (payload) => dispatch(actions.setShowTrackPanelAction(payload));
  const setClaimBeingTracked = (payload) => dispatch(actions.setClaimBeingTrackedAction(payload));
  const getAppointmentDetails = (payload) => dispatch(actions.getAppointmentDetails(payload));
  const setSelectedRowData = (payload) => dispatch(setSelectedRowDataAction(payload));

  // local state
  const [hoveredOrder, setHoveredOrder] = useState('');
  const [showRescheduleDialog, setRescheduleDialog] = useState(false);
  const [selectedClaim, selectClaim] = useState({});

  const composeRiskShotURL = (urn) => `${ASSESS_IMAGES_API_ENDPOINT}/${urn}/thumb?width=200&height=128&${sharedUtil.authParam()}`;

  const handleAdjusterAssignmentClick = (event, claimData) => {
    event.stopPropagation();
    setShowAssignmentPanel(true);
    setClaimUnderAssignment(claimData);
  };

  const handleTrackOverlayClick = (event, claimData) => {
    event.stopPropagation();
    setShowTrackPanel(true);
    setClaimBeingTracked(claimData);
  };

  const getOrderTitle = (claim) => {
    if (enableMarketingProductNames) {
      return MAP_CLAIM_TITLE_WITH_MARKETING_PRODUCT_NAME[get(claim, 'flightType', '')];
    }
    return claim.isAssessLite ? 'claim.flightType.quickCapture' : '';
  };

  const loadRescheduleModal = (event, claimData) => {
    event.stopPropagation();
    selectClaim(claimData);
    getAppointmentDetails({ orderId: claimData.orderId });
  };

  useEffect(() => {
    if (!isEmpty(fetchedAppointmentDetails)) {
      setRescheduleDialog(true);
    }
  }, [fetchedAppointmentDetails]);

  const handleOnClick = (claim) => {
    const canOpenAsAdjusterPhoto = claim.imageryUploadComplete
      && ![claimStatus.READYFORREVIEW, claimStatus.COMPLETED, claimStatus.ORDERCLOSED].includes(claim.status);
    if (isPhotoViewerEnabled && canOpenAsAdjusterPhoto) {
      setSelectedRowData({ orderId: claim.orderId, canOpenAsAdjusterReview: !canOpenAsAdjusterPhoto });
      dispatch(push(`/photos-view/${claim.orderId}`));
    } else {
      dispatch(push(`/adjuster-review/${claim.orderId}`));
    }
  };

  const isListitemDisabled = (claim) => {
    if (isPhotoViewerEnabled && claim.imageryUploadComplete) {
      return false;
    }
    return ![claimStatus.READYFORREVIEW, claimStatus.COMPLETED].includes(claim.status);
  };

  return (
    <>
      <List
        width={width}
        height={height}
        rowCount={claimsList.length}
        rowHeight={138}
        className={styles.list}
        rowRenderer={({ key, index, style }) => {
          const claim = claimsList[index];
          const rotateAngle = 360 - claim.riskShotImageOrientation;
          const isDisabled = isListitemDisabled(claim);
          const isClosedOrCompletedOrder = CLOSED_OR_COMPLETED_ORDER_STATUS.includes(claim.status);
          const isEntitledForAssignment = isEntitled(entitlements, FEATURE_ENTITLEMENTS.ASSIGN_ASSESS_ORDER);
          const isAdjusterUser = isEntitled(entitlements, FEATURE_ENTITLEMENTS.VIEW_ASSESS_SELF_ORGANIZATION_ORDERS);
          const orderTitle = getOrderTitle(claim);
          return (
            <EVBox key={key} style={style} id={`claim-${claim.orderId}`}>
              <HomeListItemWrapper
                disabled={isDisabled}
                className={clsx(styles.link, isDisabled && styles.disabledLink)}
                onClick={() => handleOnClick(claim)}
                data-testid="orderListItem"
              >
                <EVBox clone ml={4} mr={4} mb={1} cursor="not-allowed" height="130px">
                  {/* eslint-disable-next-line */}
                <EVPaper
                  elevation={hoveredOrder === claim.orderId ? 8 : 2}
                  onMouseOver={() => setHoveredOrder(claim.orderId)}
                  onMouseOut={() => setHoveredOrder('')}
                  data-testid="orderListItemPaper"
                >
                  <EVBox
                    display="flex"
                    justifyContent="stretch"
                    alignItems="stretch"
                    height="100%"
                  >
                    <EVBox className={styles.riskShotImageBox}>
                      <img
                        width="200px"
                        alt="house"
                        className={styles.riskShotImage}
                        style={{ transform: `rotate(${rotateAngle}deg)` }}
                        src={
                          claim.riskShotImage
                            ? composeRiskShotURL(claim.riskShotImage)
                            : RiskImage
                        }
                      />
                    </EVBox>
                    <EVBox
                      display="flex"
                      flexDirection="column"
                      justifyContent="space-between"
                      p={2}
                      flex="1 1 auto"
                    >
                      <EVBox
                        fontSize={theme.spacing(1.75)}
                        color={theme.evPalette.charcoal[900]}
                      >
                        {claim.orderId}
                      </EVBox>
                      <EVBox>
                        <EVBox
                          fontSize={theme.spacing(3)}
                          color={theme.evPalette.charcoal[900]}
                          mb={0.5}
                        >
                          {claim.address}
                        </EVBox>
                        <EVBox
                          fontSize={theme.spacing(1.75)}
                          color={theme.evPalette.nickel[700]}
                          mb={1}
                        >
                          {claim.address2}
                        </EVBox>
                      </EVBox>
                    </EVBox>
                    <EVBox
                      display="flex"
                      flexDirection="column"
                      justifyContent="flex-end"
                      px={2}
                      py={1}
                      flex="0 0 198px"
                      mt={6.5}
                      mb={2.125}
                      className={clsx(styles.adjusterAssignmentDiv,
                        (isEntitledForAssignment && !isClosedOrCompletedOrder) && styles.adjusterAssignmentDivHover)}
                      onClick={(e) => (isEntitledForAssignment && !isClosedOrCompletedOrder) && handleAdjusterAssignmentClick(e, claim)}
                      data-testid="adjusterAssignmentColumn"
                    >
                      <EVBox
                        className={styles.adjusterLabel}
                        mb={0.625}
                      >
                        {t('home.adjuster')}
                      </EVBox>
                      <EVBox
                        display="flex"
                      >
                        <EVUserOutlinedIcon className={styles.userIcon} />
                        <EVBox
                          id="adjusterName"
                          className={claim.claimOwner ? styles.assignedAdjusterText : styles.unassignedAdjusterText}
                        >
                          {claim.claimOwner || t('home.unassigned')}
                        </EVBox>
                      </EVBox>
                    </EVBox>
                    {
                      isAssessDistro && (
                      <EVBox
                        display="flex"
                        flexDirection="column"
                        justifyContent="flex-end"
                        p={2}
                        flex="0 0 130px"
                      >
                        <EVBox
                          fontSize={theme.spacing(1.75)}
                          color={theme.evPalette.nickel[700]}
                          mb={0.5}
                        >
                          {t('home.affiliatedOrgName')}
                        </EVBox>
                        <EVBox
                          data-testid="affiliatedOrgNameField"
                          className={styles.claimId}
                          fontSize={theme.spacing(1.75)}
                          color={theme.evPalette.nickel[900]}
                          mb={1}
                        >
                          {claim.affiliatedOrganizationName}
                        </EVBox>
                      </EVBox>
                      )
                    }
                    <EVBox
                      display="flex"
                      flexDirection="column"
                      justifyContent="flex-end"
                      p={2}
                      flex="0 0 130px"
                    >
                      <EVBox
                        fontSize={theme.spacing(1.75)}
                        color={theme.evPalette.nickel[700]}
                        mb={0.5}
                      >
                        {t('home.claimId')}
                      </EVBox>
                      <EVBox
                        className={styles.claimId}
                        fontSize={theme.spacing(1.75)}
                        color={theme.evPalette.nickel[900]}
                        mb={1}
                      >
                        {claim.claimId}
                      </EVBox>
                    </EVBox>
                    <EVBox
                      display="flex"
                      flexDirection="column"
                      justifyContent="flex-end"
                      pl={2}
                      pb={2}
                      pr={2}
                      alignItems="flex-end"
                      width={theme.spacing(26)}
                    >
                      <EVBox flex="1 1 auto" pr={1} display="flex" alignItems="center">
                        {enableReschedule && isAdjusterUser && claim.legacySubStatus === READY_FOR_IMG_CAPTURE_STATUS
                          && claim.isWeFly && claim.status === claimStatus.SCHEDULED ? (
                            <EVButton
                              data-testid="rescheduleAppointmentBtn"
                              className={styles.rescheduleBtn}
                              variant="outlined"
                              onClick={(e) => loadRescheduleModal(e, claim)}
                            >
                              {t('home.reschedule')}
                            </EVButton>
                          ) : !isEmpty(orderTitle) && (
                          <EVChip
                            size="small"
                            className={styles.captureType}
                            label={t(orderTitle)}
                          />
                          )}
                        <HomeStatusChip status={claim.status} label={t(TRANSLATION_MAPPING[claim.status].status)} />
                      </EVBox>
                      <EVBox
                        display="flex"
                        flexDirection="column"
                        alignItems="flex-end"
                        px={2}
                        pt={1}
                        className={!isClosedOrCompletedOrder && styles.overlayTrackingDivHover}
                        onClick={(e) => !isClosedOrCompletedOrder && handleTrackOverlayClick(e, claim)}
                        data-testid="overlayTrackingColumn"
                      >
                        <EVBox
                          fontSize={theme.spacing(1.75)}
                          color={theme.evPalette.nickel[700]}
                          mb={0.5}
                        >
                          {t(TRANSLATION_MAPPING[claim.status].additionalInfo)}
                        </EVBox>
                        <EVBox
                          fontSize={theme.spacing(1.75)}
                          color={theme.evPalette.nickel[900]}
                          mb={1}
                        >
                          {claim.status === claimStatus.SCHEDULED ? claim.appointmentDate : claim.statusChangeDate}
                        </EVBox>
                      </EVBox>
                    </EVBox>
                  </EVBox>
                </EVPaper>
                </EVBox>
              </HomeListItemWrapper>
            </EVBox>
          );
        }}
      />
      <EVDialog
        id="rescheduleDialog"
        open={showRescheduleDialog}
        PaperProps={{ className: styles.rescheduleDialogPaper }}
      >
        <RescheduleAppointmentDialog
          id="rescheduleDialogCmp"
          claimData={selectedClaim}
          closeDialogPanel={() => setRescheduleDialog(false)}
        />
      </EVDialog>
    </>
  );
};

HomeVirtualizedList.propTypes = {
  /**
     * @property {Array} claimsList - List of orders/claims to be displayed
     */
  claimsList: arrayOf(shape({})).isRequired,
  /**
   * @property {Number} height - Height of the AutoSizer component
   */
  height: number.isRequired,
  /**
   * @property {Number} tableHeight - Width of the AutoSizer component
   */
  width: number.isRequired,
};

export default HomeVirtualizedList;
