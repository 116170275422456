import {
  CLOSE_ERROR_TOAST,
  FETCH_ORTHO_IMAGE,
  FETCH_ORTHO_IMAGE_SUCCESS,
  FETCH_ORTHO_IMAGE_FAILURE,
  FETCH_ORTHO_POLYGON,
  FETCH_ORTHO_POLYGON_SUCCESS,
  FETCH_ORTHO_POLYGON_FAILURE,
  SET_VIEW,
  FETCH_ORDER_DETAILS,
  FETCH_ORDER_DETAILS_SUCCESS,
  FETCH_ORDER_DETAILS_FAILURE,
  FETCH_IMAGES_AND_DETAILS,
  FETCH_IMAGES_AND_DETAILS_FAILURE,
  ANNOTATION_TO_EDIT,
  UPDATE_VERTICES_POLYGONS,
} from './DroneToMeasure.constants';

export const fetchOrthoImageAction = (payload) => ({
  type: FETCH_ORTHO_IMAGE,
  payload,
});
export const fetchOrthoImageSuccessAction = (payload) => ({
  type: FETCH_ORTHO_IMAGE_SUCCESS,
  payload,
});
export const fetchOrthoImageFailureAction = (payload) => ({
  type: FETCH_ORTHO_IMAGE_FAILURE,
  payload,
});

export const fetchOrthoPolygonAction = (payload) => ({
  type: FETCH_ORTHO_POLYGON,
  payload,
});
export const fetchOrthoPolygonSuccessAction = (payload) => ({
  type: FETCH_ORTHO_POLYGON_SUCCESS,
  payload,
});
export const fetchOrthoPolygonFailureAction = (payload) => ({
  type: FETCH_ORTHO_POLYGON_FAILURE,
  payload,
});

export const closeErrorToast = () => ({
  type: CLOSE_ERROR_TOAST,
});

export const setViewAction = (payload) => ({
  type: SET_VIEW,
  payload,
});

export const fetchOrderDetailsAction = (payload) => ({
  type: FETCH_ORDER_DETAILS,
  payload,
});

export const fetchOrderDetailsSuccessAction = (payload) => ({
  type: FETCH_ORDER_DETAILS_SUCCESS,
  payload,
});

export const fetchOrderDetailsFailureAction = (payload) => ({
  type: FETCH_ORDER_DETAILS_FAILURE,
  payload,
});

export const fetchImagesAndDetailsAction = (payload) => ({
  type: FETCH_IMAGES_AND_DETAILS,
  payload,
});

export const fetchImagesAndDetailsFailureAction = (payload) => ({
  type: FETCH_IMAGES_AND_DETAILS_FAILURE,
  payload,
});

export const setAnnotationToEditAction = (payload) => ({
  type: ANNOTATION_TO_EDIT,
  payload,
});

export const updateVerticesAndPolygonsAction = (payload) => ({
  type: UPDATE_VERTICES_POLYGONS,
  payload,
});
