import React from 'react';
import { string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  EVStepper,
  EVStep,
  EVStepLabel,
  EVTypography,
} from '@eagleview/ev-comp-library';
import { STEPPER_STEPS, STATUS_STEPPER_STEPS_MAPPING } from 'layout/homeLayout/home/Home.constants';
import clsx from 'clsx';
import CustomConnector from './CustomConnector';
import CustomStepIcon from './CustomStepIcon';
import useStyles from './ClaimStatusStepper.styles';

/**
 * Component to track the claim status
 */
const ClaimStatusStepper = ({ activeStep }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.stepperRoot}>
      <EVStepper className={classes.stepper} alternativeLabel activeStep={STEPPER_STEPS.indexOf(activeStep)} connector={<CustomConnector />}>
        {STEPPER_STEPS.map((label) => (
          <EVStep className={classes.step} key={label} data-testid="stepper-step">
            <EVStepLabel
              className={classes.stepLabel}
              StepIconComponent={CustomStepIcon}
            >
              <EVTypography
                className={clsx({
                  [classes.stepLabelActive]: label === activeStep,
                  [classes.stepLabelComplete]: STEPPER_STEPS.indexOf(label) < STEPPER_STEPS.indexOf(activeStep),
                })}
                variant="caption"
              >
                {t(STATUS_STEPPER_STEPS_MAPPING[label])}
              </EVTypography>
            </EVStepLabel>
          </EVStep>
        ))}
      </EVStepper>
    </div>
  );
};

ClaimStatusStepper.propTypes = {
  activeStep: string.isRequired,
};

export default ClaimStatusStepper;
