import React from 'react';
import { EVBox, useTheme } from '@eagleview/ev-comp-library';
import { arrayOf, number } from 'prop-types';

const HeatMap = ({ values }) => {
  const theme = useTheme();
  return (
    <EVBox display="flex" m={1}>
      {values.map((x) => (
        <EVBox
          key={x}
          bgcolor={theme.evPalette.amber[x]}
          width={theme.spacing(2.5)}
          height={theme.spacing(1)}
          mr={theme.evSpacing['ev-spacing--2']}
        />
      ))}
    </EVBox>
  );
};

HeatMap.propTypes = {
  values: arrayOf(number).isRequired,
};

export default HeatMap;
