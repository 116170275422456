import { makeStyles } from '@eagleview/ev-comp-library';
import addAnomalies from '../../assets/addCountIcn.svg';

export default makeStyles((theme) => ({
  wrapper: {
    width: (props) => `calc(100% - ${props.sidePanelWidth})`,
    height: 'calc(100% - 32px)',
    position: 'relative',
    marginLeft: (props) => props.sidePanelWidth,
    backgroundColor: theme.palette.common.black,
    '& canvas': {
      outline: 'none',
    },
  },
  compassStyle: {
    position: 'absolute',
    right: theme.spacing(1),
    bottom: '190px',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
  circularProgressBar: {
    color: theme.evPalette.charcoal[300],
  },
  addAnomalies: {
    cursor: `url(${addAnomalies}) 10 10, auto`,
  },
  descPanelBackDrop: {
    zIndex: 7,
    backgroundColor: 'transparent',
  },
  viewSwitcherStyle: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    top: '17px',
    width: '100%',
  },
}));
