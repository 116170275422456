const prefix = 'qcHomeReducer';

export const FETCH_QC_ESCALATED_CLAIMS = `${prefix}/FETCH_QC_ESCALATED_CLAIMS`;
export const FETCH_QC_ESCALATED_CLAIMS_SUCCESS = `${prefix}/FETCH_QC_ESCALATED_CLAIMS_SUCCESS`;
export const FETCH_QC_ESCALATED_CLAIMS_FAILURE = `${prefix}/FETCH_QC_ESCALATED_CLAIMS_FAILURE`;

export const FETCH_QC_PENDING_CLAIMS = `${prefix}/FETCH_QC_PENDING_CLAIMS`;
export const FETCH_QC_PENDING_CLAIMS_SUCCESS = `${prefix}/FETCH_QC_PENDING_CLAIMS_SUCCESS`;
export const FETCH_QC_PENDING_CLAIMS_FAILURE = `${prefix}/FETCH_QC_PENDING_CLAIMS_FAILURE`;

export const FETCH_MANUAL_AT_CLAIMS = `${prefix}/FETCH_MANUAL_AT_CLAIMS`;
export const FETCH_MANUAL_AT_CLAIMS_SUCCESS = `${prefix}/FETCH_MANUAL_AT_CLAIMS_SUCCESS`;
export const FETCH_MANUAL_AT_CLAIMS_FAILURE = `${prefix}/FETCH_MANUAL_AT_CLAIMS_FAILURE`;

export const FETCH_OPEN_ORDERS = `${prefix}/FETCH_OPEN_ORDERS`;
export const FETCH_OPEN_ORDERS_SUCCESS = `${prefix}/FETCH_OPEN_ORDERS_SUCCESS`;
export const FETCH_OPEN_ORDERS_FAILURE = `${prefix}/FETCH_OPEN_ORDERS_FAILURE`;

export const FETCH_COMPLETED_ORDERS = `${prefix}/FETCH_COMPLETED_ORDERS`;
export const FETCH_COMPLETED_ORDERS_SUCCESS = `${prefix}/FETCH_COMPLETED_ORDERS_SUCCESS`;
export const FETCH_COMPLETED_ORDERS_FAILURE = `${prefix}/FETCH_COMPLETED_ORDERS_FAILURE`;

export const FETCH_INTERNAL_SCHEDULER_ENTITLEMENTS = `${prefix}/FETCH_INTERNAL_SCHEDULER_ENTITLEMENTS`;
export const FETCH_INTERNAL_SCHEDULER_ENTITLEMENTS_SUCCESS = `${prefix}/FETCH_INTERNAL_SCHEDULER_ENTITLEMENTS_SUCCESS`;
export const FETCH_INTERNAL_SCHEDULER_ENTITLEMENTS_FAILURE = `${prefix}/FETCH_INTERNAL_SCHEDULER_ENTITLEMENTS_FAILURE`;

export const CLOSE_QC_ERROR_TOAST = `${prefix}/CLOSE_ERROR_TOAST`;
export const SET_CURRENT_TAB = `${prefix}/SET_CURRENT_TAB`;
export const UPDATE_SEARCH_TERM = `${prefix}/UPDATE_SEARCH_TERM`;

export const RESET_ORDER_TO_READY_FOR_REVIEW = `${prefix}/RESET_ORDER_TO_READY_FOR_REVIEW`;
export const RESET_ORDER_TO_READY_FOR_REVIEW_SUCCESS = `${prefix}/RESET_ORDER_TO_READY_FOR_REVIEW_SUCCESS`;
export const RESET_ORDER_TO_READY_FOR_REVIEW_FAILURE = `${prefix}/RESET_ORDER_TO_READY_FOR_REVIEW_FAILURE`;

/* App Settings */
export const FETCH_ASSESS_APP_SETTINGS = `${prefix}/FETCH_ASSESS_APP_SETTINGS`;
export const FETCH_ASSESS_APP_SETTINGS_SUCCESS = `${prefix}/FETCH_ASSESS_APP_SETTINGS_SUCCESS`;
export const FETCH_ASSESS_APP_SETTINGS_FAILURE = `${prefix}/FETCH_ASSESS_APP_SETTINGS_FAILURE`;

/* Order Duplication */
export const SET_SHOW_ORDER_DUPLICATION_DIALOG = `${prefix}/SET_SHOW_ORDER_DUPLICATION_DIALOG`;
export const SHOW_ERROR_TOAST = `${prefix}/SHOW_ERROR_TOAST`;
export const SET_SHOW_REFLY_NEEDED_DIALOG = `${prefix}/SET_SHOW_REFLY_NEEDED_DIALOG`;

export const FETCH_ORGANIZATIONS = `${prefix}/FETCH_ORGANIZATIONS`;
export const FETCH_ORGANIZATIONS_SUCCESS = `${prefix}/FETCH_ORGANIZATIONS_SUCCESS`;
export const FETCH_ORGANIZATIONS_FAILURE = `${prefix}/FETCH_ORGANIZATIONS_FAILURE`;
export const RESET_ORGANIZATION_LIST = `${prefix}/RESET_ORGANIZATION_LIST`;

export const REFLY_RQUIRED = `${prefix}/REFLY_RQUIRED`;
export const REFLY_RQUIRED_SUCCESS = `${prefix}/REFLY_RQUIRED_SUCCESS`;
export const REFLY_RQUIRED_FAILURE = `${prefix}/REFLY_RQUIRED_FAILURE`;

export const DUPLICATE_ORDER = `${prefix}/DUPLICATE_ORDER`;
export const DUPLICATE_ORDER_SUCCESS = `${prefix}/DUPLICATE_ORDER_SUCCESS`;
export const DUPLICATE_ORDER_FAILURE = `${prefix}/DUPLICATE_ORDER_FAILURE`;
export const SET_SHOW_DUPLICATION_CONFIRMATION = `${prefix}/SET_SHOW_DUPLICATION_CONFIRMATION`;

export const SET_SELECTED_ROW_DATA = `${prefix}/SET_SELECTED_ROW_DATA`;

export const SORT_DIRECTION = {
  ASC: 'asc',
  DESC: 'desc',
};

export const HEAD_CELL_KEY = {
  orderId: 'orderId',
  claimNo: 'claimNo',
  address: 'address',
  status: 'status',
  overallCoverage: 'overallCoverage',
  facetCoverage: 'facetCoverage',
  startTime: 'startTime',
  organizationName: 'organizationName',
  affiliatedOrganizationName: 'affiliatedOrganizationName',
  menu: 'menu',
  assignedTo: 'assignedTo',
  pendingAdjustmentFrom: 'pendingAdjustmentFrom',
  pendingFrom: 'pendingFrom',
  completedOn: 'completedOn',
  workflowStatus: 'workflowStatus',
};

export const QC_ESCALATED_HEAD_CELLS = [
  {
    width: 200,
    minWidth: 100,
    label: 'qcHome.orderId',
    dataKey: HEAD_CELL_KEY.orderId,
  },
  {
    width: 450,
    minWidth: 100,
    label: 'qcHome.claimNo',
    dataKey: HEAD_CELL_KEY.claimNo,
  },
  {
    width: 500,
    minWidth: 100,
    label: 'qcHome.address',
    dataKey: HEAD_CELL_KEY.address,
  },
  {
    width: 350,
    minWidth: 120,
    label: 'qcHome.reachedQcState',
    dataKey: HEAD_CELL_KEY.startTime,
  },
  {
    width: 300,
    minWidth: 110,
    label: 'qcHome.organizationName',
    dataKey: HEAD_CELL_KEY.organizationName,
  },
  {
    width: 250,
    minWidth: 110,
    label: 'qcHome.workflowStatus',
    dataKey: HEAD_CELL_KEY.workflowStatus,
  },
  {
    width: 150,
    minWidth: 20,
    label: '',
    dataKey: HEAD_CELL_KEY.menu,
  },
];

export const QC_PENDING_HEAD_CELLS = [
  {
    width: 200,
    minWidth: 100,
    label: 'qcHome.orderId',
    dataKey: HEAD_CELL_KEY.orderId,
  },
  {
    width: 450,
    minWidth: 100,
    label: 'qcHome.claimNo',
    dataKey: HEAD_CELL_KEY.claimNo,
  },
  {
    width: 500,
    minWidth: 100,
    label: 'qcHome.address',
    dataKey: HEAD_CELL_KEY.address,
  },
  {
    width: 350,
    minWidth: 120,
    label: 'qcHome.reachedQcState',
    dataKey: HEAD_CELL_KEY.startTime,
  },
  {
    width: 300,
    minWidth: 110,
    label: 'qcHome.organizationName',
    dataKey: HEAD_CELL_KEY.organizationName,
  },
  {
    width: 250,
    minWidth: 110,
    label: 'qcHome.workflowStatus',
    dataKey: HEAD_CELL_KEY.workflowStatus,
  },
  {
    width: 150,
    minWidth: 20,
    label: '',
    dataKey: HEAD_CELL_KEY.menu,
  },
];

export const QC_MANUAL_AT_TABLE_HEAD_CELLS = [
  {
    width: 150,
    minWidth: 80,
    label: 'qcHome.orderId',
    dataKey: HEAD_CELL_KEY.orderId,
  },
  {
    width: 250,
    minWidth: 70,
    label: 'qcHome.claimNo',
    dataKey: HEAD_CELL_KEY.claimNo,
  },
  {
    width: 500,
    minWidth: 100,
    label: 'qcHome.address',
    dataKey: HEAD_CELL_KEY.address,
  },
  {
    width: 150,
    minWidth: 100,
    label: 'qcHome.assignedTo',
    dataKey: HEAD_CELL_KEY.assignedTo,
  },
  {
    width: 300,
    minWidth: 150,
    label: 'qcHome.pendingAdjustmentFrom',
    dataKey: HEAD_CELL_KEY.pendingAdjustmentFrom,
  },
  {
    width: 250,
    minWidth: 110,
    label: 'qcHome.organizationName',
    dataKey: HEAD_CELL_KEY.organizationName,
  },
  {
    width: 250,
    minWidth: 110,
    label: 'qcHome.workflowStatus',
    dataKey: HEAD_CELL_KEY.workflowStatus,
  },
  {
    width: 150,
    minWidth: 20,
    label: '',
    dataKey: HEAD_CELL_KEY.menu,
  },
];

export const OPEN_ORDERS_HEAD_CELLS = [
  {
    width: 150,
    minWidth: 80,
    label: 'qcHome.orderId',
    dataKey: HEAD_CELL_KEY.orderId,
  },
  {
    width: 250,
    minWidth: 70,
    label: 'qcHome.claimNo',
    dataKey: HEAD_CELL_KEY.claimNo,
  },
  {
    width: 500,
    minWidth: 100,
    label: 'qcHome.address',
    dataKey: HEAD_CELL_KEY.address,
  },
  {
    width: 300,
    minWidth: 150,
    label: 'qcHome.status',
    dataKey: HEAD_CELL_KEY.status,
  },
  {
    width: 300,
    minWidth: 150,
    label: 'qcHome.pendingFrom',
    dataKey: HEAD_CELL_KEY.pendingFrom,
  },
  {
    width: 250,
    minWidth: 120,
    label: 'qcHome.organizationName',
    dataKey: HEAD_CELL_KEY.organizationName,
  },
  {
    width: 250,
    minWidth: 120,
    label: 'qcHome.affiliatedOrganizationName',
    dataKey: HEAD_CELL_KEY.affiliatedOrganizationName,
  },
  {
    width: 250,
    minWidth: 110,
    label: 'qcHome.workflowStatus',
    dataKey: HEAD_CELL_KEY.workflowStatus,
  },
  {
    width: 150,
    minWidth: 20,
    label: '',
    dataKey: HEAD_CELL_KEY.menu,
  },
];

export const COMPLETED_ORDERS_HEAD_CELLS = [
  {
    width: 150,
    minWidth: 80,
    label: 'qcHome.orderId',
    dataKey: HEAD_CELL_KEY.orderId,
  },
  {
    width: 250,
    minWidth: 70,
    label: 'qcHome.claimNo',
    dataKey: HEAD_CELL_KEY.claimNo,
  },
  {
    width: 500,
    minWidth: 100,
    label: 'qcHome.address',
    dataKey: HEAD_CELL_KEY.address,
  },
  {
    width: 300,
    minWidth: 150,
    label: 'qcHome.status',
    dataKey: HEAD_CELL_KEY.status,
  },
  {
    width: 300,
    minWidth: 150,
    label: 'qcHome.completedOn',
    dataKey: HEAD_CELL_KEY.completedOn,
  },
  {
    width: 250,
    minWidth: 120,
    label: 'qcHome.organizationName',
    dataKey: HEAD_CELL_KEY.organizationName,
  },
  {
    width: 250,
    minWidth: 120,
    label: 'qcHome.affiliatedOrganizationName',
    dataKey: HEAD_CELL_KEY.affiliatedOrganizationName,
  },
  {
    width: 250,
    minWidth: 110,
    label: 'qcHome.workflowStatus',
    dataKey: HEAD_CELL_KEY.workflowStatus,
  },
  {
    width: 150,
    minWidth: 20,
    label: '',
    dataKey: HEAD_CELL_KEY.menu,
  },
];

export const QC_CLAIMS_LIST_DATE_FORMAT = 'MMM Do, YYYY HH:mm (UTC)';

export const TABS = {
  QC_ESCALATED: 'qcEscalatedTab',
  QC_PENDING: 'qcPending',
  PENDING_ADJUSTMENT: 'pendingAdjustment',
  OPEN_ORDERS: 'openOrders',
  COMPLETED_ORDERS: 'completedOrders',
};

export const MIN_SEARCH_TERM_LENGTH = 2;

export const ORDER_STATUSES = {
  Ordered: 'Ordered',
  Scheduled: 'Scheduled',
  Processing: 'Processing',
  ReadyForReview: 'ReadyForReview',
  Completed: 'Completed',
  OrderClosed: 'OrderClosed',
};

export const TOAST_DURATION = {
  SHORT: 10000,
  LONG: 20000,
};

export const TOAST_SEVERITY = {
  ERROR: 'error',
  WARNING: 'warning',
  INFO: 'info',
  SUCCESS: 'success',
};
export const SEARCH_COLUMNS = ['orderId', 'claimNo', 'address', 'organizationName', 'organizationId', 'status', 'workflowStatus'];
export const VIEW_ASSESS_INTERNAL_SCHEDULING = 'VIEW_ASSESS_INTERNAL_SCHEDULING';

export const WORKFLOW_STATUS_MAP = {
  TRIAGE_COMPLETED: {
    title: 'qcHome.orderDuplication.triageCompleted',
    value: 'TRIAGE_COMPLETED',
  },
  MANUAL_AT_PENDING: {
    title: 'qcHome.orderDuplication.manualATPending',
    value: 'MANUAL_AT_PENDING',
  },
  MANUAL_QC_PENDING: {
    title: 'qcHome.orderDuplication.manualQCPending',
    value: 'MANUAL_QC_PENDING',
  },
  READY_FOR_REVIEW: {
    title: 'qcHome.orderDuplication.readyForReview',
    value: 'READY_FOR_REVIEW',
  },
};

export const DUPLICATE_INPROGRESS = {
  status: 'DUPLICATE_INPROGRESS',
  text: 'qcHome.orderDuplication.duplicating',
};

export const DUPLICATE_ERROR_MAP = {
  89066: 'qcHome.orderDuplication.orgNotRegistered',
};

export const ERROR_TOAST_MAP = {
  D2M_DUPLICATION_ERROR: 'qcHome.orderDuplication.d2m',
  MULTISTRUCTURE_DUPLICATION_ERROR: 'qcHome.orderDuplication.noMultistructureOrderDuplication',
};

export const ASSESS_WORKFLOW_STATUS_MAP = {
  qcreviewrejectedtechnical: 'Escalated-Technical',
  underreview: 'Escalated',
  qcreviewpending: 'Escalated',
};
