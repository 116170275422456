import {
  FETCH_QC_ESCALATED_CLAIMS,
  FETCH_QC_ESCALATED_CLAIMS_SUCCESS,
  FETCH_QC_ESCALATED_CLAIMS_FAILURE,
  FETCH_QC_PENDING_CLAIMS,
  FETCH_QC_PENDING_CLAIMS_SUCCESS,
  FETCH_QC_PENDING_CLAIMS_FAILURE,
  CLOSE_QC_ERROR_TOAST,
  SET_CURRENT_TAB,
  FETCH_MANUAL_AT_CLAIMS,
  FETCH_MANUAL_AT_CLAIMS_SUCCESS,
  FETCH_MANUAL_AT_CLAIMS_FAILURE,
  FETCH_OPEN_ORDERS,
  FETCH_OPEN_ORDERS_SUCCESS,
  FETCH_OPEN_ORDERS_FAILURE,
  FETCH_COMPLETED_ORDERS,
  FETCH_COMPLETED_ORDERS_SUCCESS,
  FETCH_COMPLETED_ORDERS_FAILURE,
  UPDATE_SEARCH_TERM,
  RESET_ORDER_TO_READY_FOR_REVIEW,
  RESET_ORDER_TO_READY_FOR_REVIEW_FAILURE,
  RESET_ORDER_TO_READY_FOR_REVIEW_SUCCESS,
  FETCH_ASSESS_APP_SETTINGS,
  FETCH_ASSESS_APP_SETTINGS_SUCCESS,
  FETCH_ASSESS_APP_SETTINGS_FAILURE,
  FETCH_INTERNAL_SCHEDULER_ENTITLEMENTS,
  FETCH_INTERNAL_SCHEDULER_ENTITLEMENTS_SUCCESS,
  FETCH_INTERNAL_SCHEDULER_ENTITLEMENTS_FAILURE,
  SET_SHOW_ORDER_DUPLICATION_DIALOG,
  SHOW_ERROR_TOAST,
  FETCH_ORGANIZATIONS,
  FETCH_ORGANIZATIONS_SUCCESS,
  FETCH_ORGANIZATIONS_FAILURE,
  RESET_ORGANIZATION_LIST,
  DUPLICATE_ORDER,
  DUPLICATE_ORDER_SUCCESS,
  DUPLICATE_ORDER_FAILURE,
  SET_SHOW_DUPLICATION_CONFIRMATION,
  SET_SELECTED_ROW_DATA,
  SET_SHOW_REFLY_NEEDED_DIALOG,
  REFLY_RQUIRED,
  REFLY_RQUIRED_SUCCESS,
  REFLY_RQUIRED_FAILURE,
} from './QcHome.constants';

export const fetchQcEscalatedClaimsAction = () => ({
  type: FETCH_QC_ESCALATED_CLAIMS,
});

export const fetchQcEscalatedClaimsSuccessAction = (payload) => ({
  type: FETCH_QC_ESCALATED_CLAIMS_SUCCESS,
  payload,
});

export const fetchQcEscalatedClaimsFailureAction = (payload) => ({
  type: FETCH_QC_ESCALATED_CLAIMS_FAILURE,
  payload,
});

export const fetchQcPendingClaimsAction = () => ({
  type: FETCH_QC_PENDING_CLAIMS,
});

export const fetchQcPendingClaimsSuccessAction = (payload) => ({
  type: FETCH_QC_PENDING_CLAIMS_SUCCESS,
  payload,
});

export const fetchQcPendingClaimsFailureAction = (payload) => ({
  type: FETCH_QC_PENDING_CLAIMS_FAILURE,
  payload,
});

export const fetchManualAtClaimsAction = () => ({
  type: FETCH_MANUAL_AT_CLAIMS,
});

export const fetchManualAtClaimsSuccessAction = (payload) => ({
  type: FETCH_MANUAL_AT_CLAIMS_SUCCESS,
  payload,
});

export const fetchManualAtClaimsFailureAction = (payload) => ({
  type: FETCH_MANUAL_AT_CLAIMS_FAILURE,
  payload,
});

export const fetchOpenOrdersAction = () => ({
  type: FETCH_OPEN_ORDERS,
});

export const fetchOpenOrdersSuccessAction = (payload) => ({
  type: FETCH_OPEN_ORDERS_SUCCESS,
  payload,
});

export const fetchOpenOrdersFailureAction = (payload) => ({
  type: FETCH_OPEN_ORDERS_FAILURE,
  payload,
});

export const fetchCompletedOrdersAction = () => ({
  type: FETCH_COMPLETED_ORDERS,
});

export const fetchCompletedOrdersSuccessAction = (payload) => ({
  type: FETCH_COMPLETED_ORDERS_SUCCESS,
  payload,
});

export const fetchCompletedOrdersFailureAction = (payload) => ({
  type: FETCH_COMPLETED_ORDERS_FAILURE,
  payload,
});

export const closeErrorToast = () => ({
  type: CLOSE_QC_ERROR_TOAST,
});

export const setCurrentTab = (payload) => ({
  type: SET_CURRENT_TAB,
  payload,
});

export const updateSearchTermAction = (payload) => ({
  type: UPDATE_SEARCH_TERM,
  payload,
});

export const resetOrderToReadyForReviewAction = (payload) => ({
  type: RESET_ORDER_TO_READY_FOR_REVIEW,
  payload,
});

export const resetOrderToReadyForReviewSuccessAction = (payload) => ({
  type: RESET_ORDER_TO_READY_FOR_REVIEW_SUCCESS,
  payload,
});

export const resetOrderToReadyForReviewFailureAction = (payload) => ({
  type: RESET_ORDER_TO_READY_FOR_REVIEW_FAILURE,
  payload,
});

/* App settings */
export const fetchAssessAppSettingsAction = (payload) => ({
  type: FETCH_ASSESS_APP_SETTINGS,
  payload,
});

export const fetchAssessAppSettingsSuccessAction = (payload) => ({
  type: FETCH_ASSESS_APP_SETTINGS_SUCCESS,
  payload,
});

export const fetchAssessAppSettingsFailureAction = (payload) => ({
  type: FETCH_ASSESS_APP_SETTINGS_FAILURE,
  payload,
});

export const fetchInternalSchedulerEntitlementsAction = () => ({
  type: FETCH_INTERNAL_SCHEDULER_ENTITLEMENTS,
});

export const fetchInternalSchedulerEntitlementsSuccessAction = (payload) => ({
  type: FETCH_INTERNAL_SCHEDULER_ENTITLEMENTS_SUCCESS,
  payload,
});

export const fetchInternalSchedulerEntitlementsFailureAction = (payload) => ({
  type: FETCH_INTERNAL_SCHEDULER_ENTITLEMENTS_FAILURE,
  payload,
});

export const setShowOrderDuplicationDialog = (payload) => ({
  type: SET_SHOW_ORDER_DUPLICATION_DIALOG,
  payload,
});

export const setShowReFlyNeededDialog = (payload) => ({
  type: SET_SHOW_REFLY_NEEDED_DIALOG,
  payload,
});

export const showError = (payload) => ({
  type: SHOW_ERROR_TOAST,
  payload,
});

export const fetchOrganizationsAction = (payload) => ({
  type: FETCH_ORGANIZATIONS,
  payload,
});

export const fetchOrganizationsSuccessAction = (payload) => ({
  type: FETCH_ORGANIZATIONS_SUCCESS,
  payload,
});

export const fetchOrganizationsFailureAction = (payload) => ({
  type: FETCH_ORGANIZATIONS_FAILURE,
  payload,
});

export const resetOrganizationListAction = () => ({
  type: RESET_ORGANIZATION_LIST,
});

export const duplicateOrderAction = (payload) => ({
  type: DUPLICATE_ORDER,
  payload,
});

export const duplicateOrderSuccessAction = (payload) => ({
  type: DUPLICATE_ORDER_SUCCESS,
  payload,
});

export const duplicateOrderFailureAction = (payload) => ({
  type: DUPLICATE_ORDER_FAILURE,
  payload,
});

export const reflyRequiredAction = (payload) => ({
  type: REFLY_RQUIRED,
  payload,
});

export const reflyRequiredSuccessAction = (payload) => ({
  type: REFLY_RQUIRED_SUCCESS,
  payload,
});

export const reflyRequiredFailureAction = (payload) => ({
  type: REFLY_RQUIRED_FAILURE,
  payload,
});

export const setShowDuplicationConfirmationDialog = (payload) => ({
  type: SET_SHOW_DUPLICATION_CONFIRMATION,
  payload,
});

export const setSelectedRowDataAction = (payload) => ({
  type: SET_SELECTED_ROW_DATA,
  payload,
});
