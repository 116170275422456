import React from 'react';
import {
  EVButton,
  EVKeyboardArrowUp,
  EVKeyboardArrowDown,
  EVTypography,
} from '@eagleview/ev-comp-library';
import {
  func, node, string, bool,
} from 'prop-types';
import useStyles from './Statusbar.styles';

const StatusbarButton = ({
  active, text, icon, onClick, id,
}) => {
  const styles = useStyles({ active });
  return (
    <EVButton onClick={onClick} className={styles.statusBarButton} id={id}>
      {icon}
      <EVTypography variant="body2" className={styles.statusBarText}>
        {text}
      </EVTypography>
      {active ? <EVKeyboardArrowDown /> : <EVKeyboardArrowUp />}
    </EVButton>
  );
};

StatusbarButton.propTypes = {
  active: bool.isRequired,
  text: string.isRequired,
  icon: node.isRequired,
  onClick: func.isRequired,
  id: string,
};

StatusbarButton.defaultProps = {
  id: 'button',
};

export default StatusbarButton;
