import React from 'react';
import { useTranslation } from 'react-i18next';
import ToastMessage from 'components/toast-message';

/**
 * Fallback screen to be shown if user has no entitlements
 */
const EntitleUser = () => {
  // utility hooks
  const { t } = useTranslation();

  return (
    <ToastMessage
      alertMsg={`${t('entitleUser.noEntitlements')}`}
      severity="error"
      open
      onClose={() => {
        // Empty Block
      }}
    />
  );
};

export default EntitleUser;
