import React, { useEffect, useState } from 'react';
import {
  EVButton,
  EVInformationPanel,
  EVDivider,
  EVTypography,
  EVTextField,
  EVLandscapeIcon,
  EVBox,
  EVBackdrop,
  EVInputLabel,
  useTheme,
} from '@eagleview/ev-comp-library';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { func } from 'prop-types';
import * as action from 'layout/adjuster/Adjuster.actions';
import { get } from 'lodash';
import * as sharedUtil from 'utils/utils';
import { ASSESS_IMAGES_API_ENDPOINT } from 'constants';
import MultiEmailInput from 'components/MultipleEmailInput';
import useStyles from './ShareReportPanel.styles';
import PanelTitle from './ShareReportPanelTitle';
import { CREATE_SHARE_LINK } from '../Adjuster.constants';

const ShareReportPanel = ({ closePanel }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const panelHeight = theme.spacing(68);
  const panelWidth = theme.spacing(62);
  const styles = useStyles({ panelHeight, panelWidth });

  // selectors
  const orderId = useSelector((state) => state.adjusterReducer.orderId);
  const userID = useSelector((state) => state.entitleUserReducer.userID);
  const sharedUserslIst = useSelector((state) => get(state.adjusterReducer, 'sharedUsersList', []));
  const loading = useSelector((state) => state.adjusterReducer.loading);
  const riskShot = useSelector((state) => state.adjusterReducer.riskShot);
  const urn = get(riskShot, 'structure1.images[0].imageURN');
  const riskShotOrientation = get(riskShot, 'structure1.images[0].customMeta.orientation', 0);
  const rotateAngle = 360 - riskShotOrientation;
  let translateX = 0;
  switch (Math.abs(rotateAngle % 360)) {
    case 90: translateX = 21; break;
    case 270: translateX = -21; break;
    default: translateX = 0;
  }

  // local state
  const [notes, setNotes] = useState('');
  const [emailIds, setEmailIds] = useState([]);

  // actions
  const dispatch = useDispatch();
  const fetchSharedUsers = (payload) => dispatch(action.fetchSharedUsersListAction(payload));
  const createShareLinkAndSend = (payload) => dispatch(action.createShareLinkAction(payload));

  const composeRiskShotURL = (link) => `${ASSESS_IMAGES_API_ENDPOINT}/${link}/thumb?width=170&height=128&${sharedUtil.authParam()}`;

  useEffect(() => {
    fetchSharedUsers({ orderId });
  }, []);

  const handleShareReport = () => {
    createShareLinkAndSend({
      orderID: orderId,
      sharedUserID: emailIds,
      notes,
      evUserID: userID,
    });
    setEmailIds([]);
    setNotes('');
  };

  return (
    <>
      <EVInformationPanel
        className={styles.infoPanel}
        titleComponent={<PanelTitle />}
        enableExpandCollapseOnUI={false}
        onCloseInfoPanel={closePanel}
        width={panelWidth}
        height="auto"
        headerInputProps={{}}
      >
        <EVBox className={styles.panelContainer}>
          <EVBox display="flex" mb={2}>
            <div className={styles.roofImgPanel}>
              <div className={styles.subTitle}>Main Structure</div>
              {urn && (
              <div className={styles.roofImg}>
                <img
                  style={{ transform: `rotate(${rotateAngle}deg) translateX(${translateX}px)` }}
                  alt="Roof"
                  src={composeRiskShotURL(urn)}
                />
              </div>
              )}
              {!urn && (
              <EVBox
                className={styles.roofImg}
                bgcolor="grey.300"
                color="common.white"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <EVLandscapeIcon style={{ fontSize: 100 }} />
              </EVBox>
              )}
            </div>
            <div className={styles.notesColumn}>
              <EVInputLabel className={styles.subTitle}>{t('shareReport.emailInputLabel')}</EVInputLabel>
              <EVBox mt={1}>
                <MultiEmailInput
                  styles={styles}
                  limit={5}
                  placeHolder={t('shareReport.emailPlaceHolder')}
                  emailIds={emailIds}
                  onEmailsChange={setEmailIds}
                />
              </EVBox>
              <EVBox display="flex" alignItems="baseline" width="100%" justifyContent="space-between">
                <span className={styles.subTitle}>{t('shareReport.notesInputLabel')}</span>
                <span className={styles.helperLabel}>{t('shareReport.characterLimitLabel')}</span>
              </EVBox>
              <EVBox mt={1}>
                <EVTextField
                  id="notes"
                  fullWidth
                  placeholder={t('shareReport.notesPlaceHolder')}
                  multiline
                  inputProps={{
                    maxLength: 2500,
                  }}
                  rows={5}
                  className={styles.notesTextField}
                  value={notes}
                  onChange={(e) => setNotes(e.target.value)}
                  variant="outlined"
                />
              </EVBox>
            </div>
          </EVBox>
          <EVDivider />
          <EVBox mb={2}>
            <EVTypography className={styles.subTitle}>{t('shareReport.sharedWithTitle')}</EVTypography>
            <EVBox className={styles.sharedUsersListContainer} mt={1}>
              {
                sharedUserslIst.map((l) => (
                  <EVTypography className={styles.sharedEmailId}>{l}</EVTypography>
                ))
              }
            </EVBox>
          </EVBox>
          <EVDivider />
          <EVBox className={styles.footer}>
            <EVButton
              disabled={!emailIds.length || loading[CREATE_SHARE_LINK]}
              id="shareReport"
              className={styles.exportButton}
              color="primary"
              variant="contained"
              onClick={handleShareReport}
            >
              {t('shareReport.footerButton')}
            </EVButton>
          </EVBox>
        </EVBox>
      </EVInformationPanel>
      <EVBackdrop className={styles.backdrop} open onClick={closePanel} />
    </>
  );
};

ShareReportPanel.propTypes = {
  closePanel: func.isRequired,
};

export default ShareReportPanel;
