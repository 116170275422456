import { makeStyles } from '@eagleview/ev-comp-library';

const useStyles = makeStyles((theme) => ({
  container: {
    overflowY: 'scroll',
  },
  reportStructureContainer: {
    marginTop: theme.spacing(5),
    display: 'flex',
  },
  reportsContainer: {
    width: '40%',
    marginRight: '2.5%',
  },
  structureContainer: {
    width: '60%',
    marginLeft: '2.5%',
  },
  reportStructureText: {
    fontSize: theme.typography.h4.fontSize,
    fontWeight: theme.typography.h3.fontWeight,
    color: theme.evPalette.nickel[700],
    marginBottom: theme.spacing(1.5),
  },
  reportsListContainer: {
    minHeight: '80vh',
    height: '80vh',
    overflowY: 'scroll',
  },
  reportCard: {
    width: '98%',
    marginBottom: theme.spacing(3),
  },
  cardHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: theme.spacing(7),
  },
  reportHeaderText: {
    color: theme.evPalette.nickel[700],
  },
  imageContainer: {
    position: 'relative',
  },
  image: {
    position: 'relative',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    display: 'block',
  },
  overlay: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: theme.evPalette.scrim,
    top: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  checkIcon: {
    color: theme.evPalette.salt[100],
    width: '30%',
    height: '30%',
  },
  structureImageContainer: {
    overflowY: 'scroll',
  },
  cursorPointer: {
    cursor: 'pointer',
  },
}));

export default useStyles;
