import React from 'react';
import { useSelector } from 'react-redux';
import PropType from 'prop-types';
import { ASSESS_IMAGES_API_ENDPOINT } from 'constants.js';
import * as sharedUtil from 'utils/utils';
import {
  EVBox,
  EVLandscapeIcon,
} from '@eagleview/ev-comp-library';
import get from 'lodash/get';
import ReportDecision from './ReportDecisions';

const ReportDecisionMultiStructure = ({
  orderId, structures, riskShot, decisions, structureRoofMapping, notes, styles,
}) => {
  const authToken = useSelector((state) => state.adjusterReducer.accessToken);
  const composeRiskShotURL = (urn) => `${ASSESS_IMAGES_API_ENDPOINT}/${urn}/thumb?width=170&height=128&${sharedUtil.getAuthParam(authToken)}`;
  return (
    <>
      {
      structures.map((structure) => {
        const { structureID, structureName, isCaptured } = structure;
        if (isCaptured) {
          const roofId = structureRoofMapping[structureID];
          const urn = get(riskShot, `${structureID}.images[0].imageURN`);
          const riskShotOrientation = get(riskShot, `${structureID}.images[0].customMeta.orientation`, 0);
          const rotateAngle = 360 - riskShotOrientation;
          // when image rotated 90/180 deg, due to aspect ratio image renders beyond the parent.
          // So we translate the image (windth-height)/2 => (170 - 128) / 2 => 21
          let translateX = 0;
          switch (Math.abs(rotateAngle % 360)) {
            case 90: translateX = 21; break;
            case 270: translateX = -21; break;
            default: translateX = 0;
          }
          return (
            <div className={styles.decisionMultiStructurePanel}>
              <div className={styles.roofImgPanel}>
                <div className={styles.structureName}>{structureName}</div>
                {urn && (
                <div className={styles.roofImg}>
                  <img
                    style={{ transform: `rotate(${rotateAngle}deg) translateX(${translateX}px)` }}
                    width="170px"
                    alt="Roof"
                    src={composeRiskShotURL(urn)}
                  />
                </div>
                )}
                {!urn && (
                <EVBox
                  className={styles.roofImg}
                  width="170px%"
                  height="130px"
                  bgcolor="grey.300"
                  color="common.white"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <EVLandscapeIcon style={{ fontSize: 100 }} />
                </EVBox>
                )}
              </div>
              <div className={styles.decisionMultiStructure}>
                <ReportDecision
                  orderId={orderId}
                  roofId={roofId}
                  decisions={decisions}
                  notes={notes}
                  styles={styles}
                />
              </div>
            </div>
          );
        }
        return <></>;
      })
      }
    </>
  );
};

ReportDecisionMultiStructure.propTypes = {
  orderId: PropType.string.isRequired,
  structures: PropType.arrayOf(
    PropType.shape({}),
  ).isRequired,
  notes: PropType.shape({}).isRequired,
  decisions: PropType.shape({}).isRequired,
  riskShot: PropType.shape({}).isRequired,
  structureRoofMapping: PropType.shape({}).isRequired,
  styles: PropType.shape({
    roofImg: PropType.string,
    roofImgPanel: PropType.string,
    decisionMultiStructure: PropType.string,
    decisionMultiStructurePanel: PropType.string,
    structureName: PropType.string,
  }).isRequired,
};

export default ReportDecisionMultiStructure;
