import axios from 'axios';
import * as util from 'utils/utils';
import { VALIDATE_SHARELINK_ENDPOINT } from 'constants.js';

export const fetchValidateLink = async ({ orderUuid }) => {
  try {
    const { data } = await axios.get(`${VALIDATE_SHARELINK_ENDPOINT}/${orderUuid}`);
    return data;
  } catch (error) {
    return util.handleHttpError(error);
  }
};
