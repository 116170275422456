import { makeStyles } from '@eagleview/ev-comp-library';

export default makeStyles((theme) => ({
  roofDetails: {
    cursor: 'pointer',
  },
  anomaliesCounter: {
    display: 'flex',
    justifyContent: 'center',
  },
  totalCount: {
    color: theme.evPalette.mango[600],
  },
  roofList: {
    padding: 0,
  },
  decidedChip: {
    background: theme.evPalette.huckleberry[100],
    color: theme.evPalette.huckleberry[700],
  },
  undecidedChip: {
    background: theme.evPalette.charcoal[100],
    color: theme.evPalette.nickel[700],
  },
  userDefinedCount: {
    color: theme.evPalette.charcoal[900],
  },
  selected: {
    backgroundColor: theme.evPalette.nickel[100],
  },
  chipStyle: {
    fontSize: theme.spacing(1.25),
    height: theme.spacing(2.5),
    fontWeight: 'bold',
  },
  roofListItemCenterAligned: {
    textAlign: 'center',
  },
  roofListItemRightAligned: {
    textAlign: 'right',
  },
  utc: {
    color: theme.evPalette.charcoal[500],
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(22),
    textAlign: 'center',
    fontWeight: '500',
    marginTop: theme.spacing(3),
  },
}));
