import { makeStyles } from '@eagleview/ev-comp-library';

export default makeStyles((theme) => ({
  panel: {
    position: 'absolute',
    right: theme.spacing(10),
    top: theme.spacing(2),
    zIndex: 4,
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '4px',
  },
  container: {
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    borderTop: `1px solid ${theme.palette.grey[300]}`,
  },
  header: {
    fontSize: theme.typography.pxToRem(12),
    fontWeight: '700',
    color: theme.evPalette.charcoal[900],
    lineHeight: theme.typography.pxToRem(16.34),
  },
  accessoryItem: {
    marginTop: theme.spacing(1),
    fontSize: theme.typography.pxToRem(14),
    fontWeight: '400',
    color: theme.evPalette.charcoal[900],
    lineHeight: theme.typography.pxToRem(14),
  },
  count: {
    color: theme.palette.common.black,
    fontSize: theme.typography.pxToRem(14),
    fontWeight: '400',
    lineHeight: theme.typography.pxToRem(14),
  },
  panelHeader: {
    marginLeft: theme.spacing(2),
    fontSize: theme.typography.pxToRem(14),
    color: theme.evPalette.ocean[900],
    fontWeight: '600',
    lineHeight: theme.typography.pxToRem(14),
  },
  noDataFound: {
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    borderTop: `1px solid ${theme.palette.grey[300]}`,
  },
}));
