import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import get from 'lodash/get';
import {
  EVBox,
  EVTypography,
} from '@eagleview/ev-comp-library';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import * as action from '../Photos.actions';
import useStyles from './PhotosMissingSummaryJsonView.styles';
import PhotosThumbnailGrid from '../photos-thumbnails-grid/PhotosThumbnailsGrid';
import PhotosMissingSummaryJsonModal from '../photos-missing-summary-json-modal';

const PhotosMissingSummaryJsonView = ({ orderId }) => {
  // utility hooks
  const { t } = useTranslation();
  const styles = useStyles();

  const [selectedImage, setSelectedImage] = useState({});

  // actions
  const dispatch = useDispatch();
  const fetchThumbnailAction = (payload) => dispatch(action.fetchThumbnailAction(payload));

  // selectors
  const allThumbnail = useSelector((state) => get(state, 'photosReducer.thumbnails.all', []));
  const selectedStructureId = useSelector((state) => get(state, 'photosReducer.selectedStructureId', ''));

  const handleItemClicked = (image) => {
    setSelectedImage(image);
  };

  useEffect(() => {
    fetchThumbnailAction({
      orderId,
      structureId: selectedStructureId, // hardcoded for now as multi-structure is not yet supported.
      imageType: 'all',
    });
  }, [selectedStructureId]);

  return (
    <EVBox display="flex" flex="0 0 324px" overflow="hidden" flexDirection="column" className={styles.missingSummaryJsonContent}>
      <EVBox sx={{
        display: 'flex',
        alignItems: 'flex-start',
        flexDirection: 'column',
        m: 1,
        bgcolor: 'background.paper',
        borderRadius: 1,
      }}
      />
      <EVTypography variant="h4" className={styles.capturedImageryTitle}>
        {t('photos-ui.missingSummaryJson.capturedImagery')}
      </EVTypography>
      <EVBox className={styles.thumbnails}>
        <PhotosThumbnailGrid
          images={allThumbnail}
          onItemClicked={handleItemClicked}
        />
      </EVBox>
      { selectedImage && selectedImage.imageName && (
        <PhotosMissingSummaryJsonModal
          orderId={orderId}
          allImages={allThumbnail}
          selectedImage={selectedImage}
          setSelectedImage={handleItemClicked}
        />
      )}
    </EVBox>
  );
};

PhotosMissingSummaryJsonView.propTypes = {
  orderId: PropTypes.string.isRequired,
};

export default PhotosMissingSummaryJsonView;
