import { makeStyles } from '@eagleview/ev-comp-library';

export default makeStyles((theme) => ({
  titleContainer: {
    display: 'flex',
    paddingLeft: theme.spacing(1),
  },
  titleIcon: {
    color: theme.evPalette.charcoal[600],
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  infoPanel: {
    zIndex: 8,
    position: 'absolute',
    left: (P) => `calc(50% - ${P.exportPanelWidth / 2}px)`,
    top: (P) => `calc(50% - ${P.exportPanelHeight / 2}px)`,
    boxShadow: theme.shadows[4],
  },
  panelContainer: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2.5),
    display: 'flex',
    flexDirection: 'column',
  },
  radioHeader: {
    marginBottom: theme.spacing(1),
  },
  radioContainer: {
    display: 'flex',
    marginTop: theme.spacing(1),
  },
  radioLabel: {
    marginTop: theme.spacing(1),
  },
  exportButton: {
    textTransform: 'none',
    float: 'right',
  },
  footer: {
    margin: `${theme.spacing(4)}px ${theme.spacing(2)}px ${theme.spacing(2)}px ${theme.spacing(1)}px`,
  },
  backdrop: {
    zIndex: 7,
    backgroundColor: 'transparent',
  },
}));
