import { makeStyles } from '@eagleview/ev-comp-library';

export default makeStyles((theme) => ({
  title: {
    fontSize: '18px',
    color: theme.evPalette.charcoal[900],
    fontweight: 600,
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1),
  },
  notesHeading: {
    fontSize: '16px',
    color: theme.evPalette.charcoal[900],
    marginLeft: theme.spacing(3),
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  notesEditField: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  rejectReasonHeading: {
    fontSize: '16px',
    color: theme.evPalette.charcoal[900],
    marginLeft: theme.spacing(3),
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  paper: {
    padding: theme.spacing(2),
  },
  radioBox: {
    display: 'flex',
  },
  radioText: {
    fontSize: '16px',
    color: theme.evPalette.charcoal[900],
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  selfAssignmentCheckbox: {
    display: 'flex',
  },
  Box: {
    display: 'flex',
  },
  checkBox: {
    marginLeft: theme.spacing(3),
    color: theme.evPalette.charcoal[500],
  },
  required: {
    fontSize: '10px',
    color: theme.evPalette.mango[700],
  },
  rejectActionButton: {
    background: 'transparent',
    boxShadow: 'none',
    color: theme.evPalette.ocean[500],
    maxWidth: '50px',
    maxHeight: '30px',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    fontSize: '13px',
    '&:hover': {
      background: 'transparent',
      boxShadow: 'none',
      cursor: 'pointer',
    },
  },
  rejectPropertyActionButton: {
    background: 'transparent',
    boxShadow: 'none',
    color: theme.evPalette.ocean[500],
    border: '1px solid rgba(0, 0, 0, 0.12)',
    fontSize: '12px',
    '&:hover': {
      background: 'transparent',
      boxShadow: 'none',
      cursor: 'pointer',
    },
  },
  rejectStageDropdown: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    color: theme.evPalette.charcoal[500],
    maxWidth: '80%',
  },
  rejectConfirmationButton: {
    background: theme.evPalette.raspberry[500],
    boxShadow: 'none',
    '&:hover': {
      background: theme.evPalette.raspberry[500],
      cursor: 'pointer',
    },
  },
  qcRejectReasonHeading: {
    fontSize: '16px',
    color: theme.evPalette.charcoal[900],
    marginLeft: theme.spacing(3),
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));
