import { makeStyles } from '@eagleview/ev-comp-library';

export default makeStyles((theme) => ({
  qcTableContainer: {
    height: '100%',
    padding: `${theme.spacing(0)}px ${theme.spacing(1)}px`,
  },
  qcTableHeader: {
    height: '20%',
  },
  headerCells: {
    textAlign: 'center',
  },
  qcTableBody: {
    height: '80%',
    overflow: 'auto',
  },
  tableHeaderText: {
    fontWeight: theme.spacing(75),
    textAlign: 'center',
  },
  tableHeaderTextRightAlign: {
    fontWeight: theme.spacing(75),
    textAlign: 'right',
  },
  facetNameCell: {
    textAlign: 'center',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  facetCoverageCell: {
    textAlign: 'right',
  },
  facetAreaCell: {
    textAlign: 'right',
  },
  qcCoverageTableLoading: {
    margin: '0 auto',
    display: 'block',
  },
  facetNameButton: {
    boxShadow: 'none',
    color: theme.evPalette.ocean[500],
    background: 'transparent',
    padding: theme.spacing(0),
    minWidth: theme.spacing(2),
    '&:hover': {
      background: 'transparent',
      boxShadow: 'none',
      textDecoration: 'underline',
    },
  },
}));
