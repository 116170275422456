import {
  FETCH_FULL_IMAGE,
  FETCH_FULL_IMAGE_SUCCESS,
  FETCH_FULL_IMAGE_FAILURE,
  FETCH_ORDER_DETAILS,
  FETCH_ORDER_DETAILS_SUCCESS,
  FETCH_ORDER_DETAILS_FAILURE,
  SET_CURRENT_VIEW,
  FETCH_THUMBNAILS,
  FETCH_THUMBNAILS_SUCCESS,
  FETCH_THUMBNAILS_FAILURE,
  FETCH_RISK_SHOT,
  FETCH_RISK_SHOT_SUCCESS,
  FETCH_RISK_SHOT_FAILURE,
  FETCH_PHOTO_ASSETS,
  FETCH_PHOTO_ASSETS_SUCCESS,
  FETCH_PHOTO_ASSETS_FAILURE,
  RESET_FACET_OVERVIEW,
  INITIATE_DOWNLOAD_ZIP,
  INITIATE_DOWNLOAD_ZIP_SUCCESS,
  INITIATE_DOWNLOAD_ZIP_FAILURE,
  ZIP_STATUS,
  ZIP_STATUS_SUCCESS,
  ZIP_STATUS_FAILURE,
  CLEAR_ZIP_STATUS_ID,
  CLOSE_ERROR_TOAST,
  SET_SELECTED_STRUCTURE_ID,
  SET_CURRENT_TAB,
} from './photos.constant';

export const fetchFullImageAction = (payload) => ({
  type: FETCH_FULL_IMAGE,
  payload,
});
export const fetchFullImageSuccessAction = (payload) => ({
  type: FETCH_FULL_IMAGE_SUCCESS,
  payload,
});
export const fetchFullImageFailureAction = (payload) => ({
  type: FETCH_FULL_IMAGE_FAILURE,
  payload,
});

export const fetchOrderDetailsAction = (payload) => ({
  type: FETCH_ORDER_DETAILS,
  payload,
});
export const fetchOrderDetailsSuccessAction = (payload) => ({
  type: FETCH_ORDER_DETAILS_SUCCESS,
  payload,
});
export const fetchOrderDetailsFailureAction = (payload) => ({
  type: FETCH_ORDER_DETAILS_FAILURE,
  payload,
});

export const setCurrentViewAction = (payload) => ({
  type: SET_CURRENT_VIEW,
  payload,
});

export const fetchThumbnailAction = (payload) => ({
  type: FETCH_THUMBNAILS,
  payload,
});

export const fetchThumbnailSuccessAction = (payload) => ({
  type: FETCH_THUMBNAILS_SUCCESS,
  payload,
});

export const fetchThumbnailFailureAction = (payload) => ({
  type: FETCH_THUMBNAILS_FAILURE,
  payload,
});

export const fetchRiskshotAction = (payload) => ({
  type: FETCH_RISK_SHOT,
  payload,
});

export const fetchRiskshotSuccessAction = (payload) => ({
  type: FETCH_RISK_SHOT_SUCCESS,
  payload,
});

export const fetchRiskshotFailureAction = (payload) => ({
  type: FETCH_RISK_SHOT_FAILURE,
  payload,
});

export const fetchPhotoAssetsAction = (payload) => ({
  type: FETCH_PHOTO_ASSETS,
  payload,
});

export const fetchPhotoAssetsSuccessAction = (payload) => ({
  type: FETCH_PHOTO_ASSETS_SUCCESS,
  payload,
});

export const fetchPhotoAssetsFailureAction = (payload) => ({
  type: FETCH_PHOTO_ASSETS_FAILURE,
  payload,
});

export const resetFacetOverviewAction = () => ({
  type: RESET_FACET_OVERVIEW,
});

export const initiateZipDownloadAction = (payload) => ({
  type: INITIATE_DOWNLOAD_ZIP,
  payload,
});

export const initiateZipDownloadSuccessAction = (payload) => ({
  type: INITIATE_DOWNLOAD_ZIP_SUCCESS,
  payload,
});

export const initiateZipDownloadFailureAction = (payload) => ({
  type: INITIATE_DOWNLOAD_ZIP_FAILURE,
  payload,
});

export const checkZipStatusAction = (payload) => ({
  type: ZIP_STATUS,
  payload,
});

export const checkZipStatusSuccessAction = (payload) => ({
  type: ZIP_STATUS_SUCCESS,
  payload,
});

export const checkZipStatusFailureAction = (payload) => ({
  type: ZIP_STATUS_FAILURE,
  payload,
});

export const clearZipStatusIdAction = () => ({
  type: CLEAR_ZIP_STATUS_ID,
});

export const closeErrorToast = () => ({
  type: CLOSE_ERROR_TOAST,
});

export const setSelectedStructureId = (payload) => ({
  type: SET_SELECTED_STRUCTURE_ID,
  payload,
});

export const setCurrentTabAction = (payload) => ({
  type: SET_CURRENT_TAB,
  payload,
});
