import { makeStyles } from '@eagleview/ev-comp-library';

const useStyles = makeStyles((theme) => ({
  statusBarIncluded: {
    borderLeft: `1px solid ${theme.evPalette.charcoal[200]}`,
    display: 'flex',
    alignItems: 'center',
    height: theme.evSpacing['ev-spacing--32'],
    padding: `0 ${theme.evSpacing['ev-spacing--16']}`,
    overflow: 'hidden',
    minHeight: '50px',
  },
  inactiveIcon: {
    color: theme.evPalette.charcoal[600],
  },
  activeIcon: {
    color: theme.evPalette.ocean[500],
  },
}));

export default useStyles;
