import axios from 'axios';
import * as util from 'utils/utils';
import {
  ASSESS_ORDER_MANAGER_API_ENDPOINT,
  OPS_PRIME_API_ENDPOINT,
} from 'constants.js';

export const fetchImagesApi = async (orderId) => {
  try {
    const { data } = await axios.get(
      `${ASSESS_ORDER_MANAGER_API_ENDPOINT}/manualat/orders/${orderId}/overview-images`,
    );
    return data;
  } catch (error) {
    return util.handleHttpError(error);
  }
};

export const fetchOrthoImageMeta = async (reportId) => {
  try {
    const { data } = await axios.get(
      `${ASSESS_ORDER_MANAGER_API_ENDPOINT}/manualat/reports/${reportId}/ev-ortho/imeta`,
    );
    return data;
  } catch (error) {
    return util.handleHttpError(error);
  }
};

export const fetchOrthoImage = async (reportId) => {
  try {
    const { data } = await axios.get(
      `${ASSESS_ORDER_MANAGER_API_ENDPOINT}/manualat/reports/${reportId}/download-ortho`, { responseType: 'blob' },
    );
    return data;
  } catch (error) {
    return util.handleHttpError(error);
  }
};

export const fetchOrthoPolygon = async (reportId) => {
  try {
    const { data } = await axios.get(
      `${ASSESS_ORDER_MANAGER_API_ENDPOINT}/manualat/reports/${reportId}/roof-report`,
    );
    return data;
  } catch (error) {
    return util.handleHttpError(error);
  }
};

export const saveApi = async ({ data, orderId }) => {
  try {
    const { data: response } = await axios.put(
      `${ASSESS_ORDER_MANAGER_API_ENDPOINT}/manualat/orders/${orderId}`,
      data,
    );
    return response;
  } catch (error) {
    return util.handleHttpError(error);
  }
};

export const fetchOrderDetails = async (orderId) => {
  try {
    const { data: response } = await axios.get(`${ASSESS_ORDER_MANAGER_API_ENDPOINT}/manualat/orders/${orderId}`);
    return response;
  } catch (error) {
    return util.handleHttpError(error);
  }
};

// legacy reportId === current orderId
export const checkoutByReportId = async (orderId) => {
  try {
    const { data } = await axios.get(`${OPS_PRIME_API_ENDPOINT}/CheckOutByReportId?ReportId=${orderId}`);
    return data;
  } catch (error) {
    if (error.response && error.response.data && error.response.data.errorCode) {
      return util.handleHttpError(error);
    }
    if (error.response) {
      throw new util.ServerError(0, error.response.data, error.response.status);
    } else {
      throw new Error();
    }
  }
};

export const fetchRoofImageApi = async (payload) => {
  const { orderId, imageName } = payload;
  try {
    const { data } = await axios.get(
      `${ASSESS_ORDER_MANAGER_API_ENDPOINT}/manualat/orders/${orderId}/download-overview/image/${imageName}`, { responseType: 'blob' },
    );
    return { ...payload, data };
  } catch (error) {
    return util.handleHttpError(error);
  }
};

export const checkInSimple = async (taskStateId) => {
  try {
    const { data: response } = await axios.get(
      `${OPS_PRIME_API_ENDPOINT}/CheckInSimple?TaskStateId=${taskStateId}`,
    );
    return response;
  } catch (error) {
    return util.handleHttpError(error);
  }
};

export const rejectSimpleNotes = async ({ taskStateId, rejectReasonId, body }) => {
  try {
    const { data: response } = await axios.post(
      `${OPS_PRIME_API_ENDPOINT}/RejectSimpleNotes?TaskStateId=${taskStateId}&RejectReasonId=${rejectReasonId}&AssignPreferredUser=false`,
      body,
    );
    return response;
  } catch (error) {
    return util.handleHttpError(error);
  }
};
