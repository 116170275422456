import { makeStyles } from '@eagleview/ev-comp-library';

export default makeStyles((theme) => ({
  utcContainer: {
    width: '100%',
    height: '100%',
    backgroundColor: theme.evPalette.charcoal[200],
  },
  utcContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    gap: theme.spacing(1),
    flexDirection: 'column',
  },
  utcImage: {
    width: '380px',
    height: '270px',
  },
  utcDesc: {
    fontSize: theme.typography.pxToRem(24),
    color: theme.evPalette.charcoal[700],
    fontWeight: '600',
    lineHeight: theme.typography.pxToRem(32),
  },
  utcHint: {
    fontSize: theme.typography.pxToRem(14),
    color: theme.evPalette.nickel[700],
    fontWeight: '600',
    lineHeight: theme.typography.pxToRem(19),
  },
  utcBtn: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: '600',
    lineHeight: theme.typography.pxToRem(19),
    margin: '0 0 2px',
  },
}));
