import React, { useState } from 'react';
import {
  EVIconButton,
  EVMoreVertIcon,
  EVMenu,
  EVMenuItem,
} from '@eagleview/ev-comp-library';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import PropTypes from 'prop-types';
import {
  FEATURE_ENTITLEMENTS,
  ASSESS_VIEW_SCHEDULING_DETAILS_ORDER_LISTING,
  ASSESS_MEASUREMENT_FEE_PRODUCT,
  ASSESS_PHOTO_VIEWER,
  ASSESS_INTERNAL_REFLY,
} from 'layout/entitleUser/EntitleUser.constants';
import {
  VIEW_ASSESS_INTERNAL_SCHEDULING, ORDER_STATUSES, TABS, DUPLICATE_ORDER, ERROR_TOAST_MAP,
} from 'layout/homeLayout/qcHome/QcHome.constants';
import { isEntitled } from 'utils/auth.utils';
import { INTERNAL_SCHEDULER_REDIRECT_URL } from 'constants.js';
import get from 'lodash/get';
import * as action from '../QcHome.actions';

const QcContextualMenu = (props) => {
  // utility hooks
  const { t } = useTranslation();

  // selectors
  const entitlements = useSelector((state) => get(state, 'entitleUserReducer.entitlements'));
  const internalSchedulerEntitlements = useSelector((state) => get(state, 'qcHomeReducer.internalSchedulerEntitlements'));
  const currentTab = useSelector((state) => get(state, 'qcHomeReducer.currentTab'));
  const loading = useSelector((state) => get(state, 'qcHomeReducer.loading', {}));
  const isViewSchedulingDetailsMenuEnabled = useSelector((state) => get(
    state.entitleUserReducer.featureFlags,
    ASSESS_VIEW_SCHEDULING_DETAILS_ORDER_LISTING,
    false,
  ));

  const isAssessMeasurementFeeProductEnabled = useSelector((state) => get(
    state.entitleUserReducer.featureFlags,
    ASSESS_MEASUREMENT_FEE_PRODUCT,
    false,
  ));

  const isAssessPhotoViewerEnabled = useSelector((state) => get(
    state.entitleUserReducer.featureFlags,
    ASSESS_PHOTO_VIEWER,
    false,
  ));

  const isReflyNeededEnabled = useSelector((state) => get(
    state.entitleUserReducer.featureFlags,
    ASSESS_INTERNAL_REFLY,
    false,
  ));

  // actions
  const dispatch = useDispatch();
  const resetJobToReadyForReview = (payload) => dispatch(action.resetOrderToReadyForReviewAction(payload));
  const setShowOrderDuplicationDialog = (payload) => dispatch(action.setShowOrderDuplicationDialog(payload));
  const setReflyNeededDialog = (payload) => dispatch(action.setShowReFlyNeededDialog(payload));
  const showError = (payload) => dispatch(action.showError(payload));
  const setSelectedRowData = (payload) => dispatch(action.setSelectedRowDataAction(payload));

  // local state
  const [anchorEl, setAnchorEl] = useState(null);

  const { rowData } = props;
  // Todo - replace 'isLite' with the actual key name received with order details, once implemented.
  const isLiteOrder = get(rowData, 'isAssessLite', false);

  const ASSESS_RESET_JOB = useSelector((state) => {
    const groups = get(state, 'qcHomeReducer.assessAppSettings.groups', []);
    const assessFeatureGroup = groups.find((group) => group.groupName === 'ASSESS_FEATURE_FLAGS');
    return get(assessFeatureGroup, 'settings.ALLOW_ORDERS_TO_BE_RESET', false);
  });
  const isCompletedOrdersTab = currentTab === TABS.COMPLETED_ORDERS;

  // if launchDarkly featureFlag is true then enable based on the value of DB flag
  const isOrderPlacedWithMeasurementFeeProduct = get(rowData, 'isOrderPlacedWithMeasurementFeeProduct', false);
  const isMeasurementFeeProductFFEnabled = isAssessMeasurementFeeProductEnabled ? isOrderPlacedWithMeasurementFeeProduct : true;

  const handleClick = (event) => {
    setAnchorEl(event.target);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // Handler
  /**
   * Redirects to the adjuster review page
   */
  const handleOpenAsAnAdjuster = (row) => {
    if (isAssessPhotoViewerEnabled && !row.canOpenAsAdjusterReview) {
      setSelectedRowData(row);
      dispatch(push(`/photos-view/${row.orderId}`));
    } else {
      dispatch(push(`/adjuster-review/${row.orderId}`));
    }
  };
  /**
   * Redirects to the measurement tech user page
   */
  const handleOpenAsMeasurementTech = (row) => {
    dispatch(push(`/manual-at-adjustment/v2/${row.orderId}`));
  };

  /**
   * Resets job to Ready for review
   */
  const handleResetJobToReadyForReview = (row, retainData) => {
    handleClose();
    resetJobToReadyForReview({ orderId: row.orderId, retainData });
  };

  /**
   * Redirects to the QC review page
   */
  const handleOpenAsAQC = (row) => {
    dispatch(push(`/adjuster-review/${row.orderId}`));
  };

  /**
   * Redirects to the WeFly test job page.
   */
  const handleOpenAsWeFly = (row) => {
    dispatch(push(`/adjuster-review/${row.orderId}`));
  };

  /**
   * Redirects to Internal Scheduler application
   */
  const handleViewSchedulingDetails = (row) => {
    window.location = `${INTERNAL_SCHEDULER_REDIRECT_URL}/${row.orderId}`;
  };

  /**
   * Opens create duplicate order dialog
   */
  const handleCreateDuplicateOrder = (row) => {
    handleClose();
    if (row.isD2m) {
      showError(ERROR_TOAST_MAP.D2M_DUPLICATION_ERROR);
    } else {
      setShowOrderDuplicationDialog({ orderToBeDuplicatedDetails: row, showDialog: true });
    }
  };

  /**
   * Opens create refly needed dialog
   */
  const handleReflyNeeded = (row) => {
    setReflyNeededDialog({ orderToBeReflown: row, showDialog: true });
    handleClose();
  };

  let canShowOpenAsAdjuster = rowData.canOpenAsAdjusterReview;

  if (isAssessPhotoViewerEnabled) {
    canShowOpenAsAdjuster = canShowOpenAsAdjuster || rowData.imageryUploadComplete;
  }

  const checkIfReflyAllowedForStatus = (status) => ![
    ORDER_STATUSES.Ordered,
    ORDER_STATUSES.Scheduled,
    ORDER_STATUSES.ReadyForReview,
    ORDER_STATUSES.Completed,
    ORDER_STATUSES.OrderClosed,
  ].includes(status);

  const menuItems = [
    {
      show: isEntitled(entitlements, FEATURE_ENTITLEMENTS.VIEW_ASSESS_ALL_ORGANIZATION_ORDERS)
            && canShowOpenAsAdjuster,
      handler: () => handleOpenAsAnAdjuster(rowData),
      title: 'qcHome.contextualMenuItemOpenAsAnAdjuster',
    },
    {
      show: (isEntitled(entitlements, FEATURE_ENTITLEMENTS.VIEW_MANUAL_AT_VIA_OPS_PRIME)
            || isEntitled(entitlements, FEATURE_ENTITLEMENTS.MANAGE_MANUAL_AT_TIE_POINTS))
            && rowData.canOpenAsMeasurementTech && !isLiteOrder,
      handler: () => handleOpenAsMeasurementTech(rowData),
      title: 'qcHome.contextualMenuItemOpenAsMeasurementTech',
    },
    {
      show: (isEntitled(entitlements, FEATURE_ENTITLEMENTS.MOVE_ASSESS_ORDER_BACK_TO_READY_FOR_REVIEW))
            && rowData.canResetJobToReview && ASSESS_RESET_JOB,
      handler: () => handleResetJobToReadyForReview(rowData, false),
      title: 'qcHome.contextualMenuResetOrderToReadyForReview',
    },
    {
      show: isEntitled(entitlements, FEATURE_ENTITLEMENTS.VIEW_ASSESS_ALL_ORGANIZATION_ORDERS) && !isLiteOrder
      && (isEntitled(entitlements, FEATURE_ENTITLEMENTS.VIEW_ASSESS_MANUAL_QC_INFO) || isEntitled(entitlements, FEATURE_ENTITLEMENTS.MANAGE_QC_DECISIONS))
            && rowData.canOpenAsQCUser,
      handler: () => handleOpenAsAQC(rowData),
      title: 'qcHome.contextualMenuItemOpenAsQC',
    },
    {
      show: isEntitled(entitlements, FEATURE_ENTITLEMENTS.DEMO_MANAGER)
        && isReflyNeededEnabled
        && checkIfReflyAllowedForStatus(rowData.status),
      handler: () => handleReflyNeeded(rowData),
      title: 'qcHome.contextualMenuItemReflyNeeded',
    },
    {
      show: isViewSchedulingDetailsMenuEnabled && isEntitled(internalSchedulerEntitlements, VIEW_ASSESS_INTERNAL_SCHEDULING),
      handler: () => handleViewSchedulingDetails(rowData),
      title: 'qcHome.contextualMenuItemViewSchedulingDetails',
    },
    {
      show: (isEntitled(entitlements, FEATURE_ENTITLEMENTS.VIEW_SELF_JOBS) && !isEntitled(entitlements, FEATURE_ENTITLEMENTS.MANAGE_DECISIONS))
              && rowData.canOpenAsQCUser,
      handler: () => handleOpenAsWeFly(rowData),
      title: 'qcHome.contextualMenuItemViewClaim',
    },
    {
      show: (isEntitled(entitlements, FEATURE_ENTITLEMENTS.ASSESS_REOPEN_CLOSED_CLAIM) && ASSESS_RESET_JOB
              && isCompletedOrdersTab && rowData.status === ORDER_STATUSES.Completed),
      handler: () => handleResetJobToReadyForReview(rowData, true),
      title: 'qcHome.contextualMenuItemReopenClaim',
    },
    {
      show: (isMeasurementFeeProductFFEnabled && isEntitled(entitlements, FEATURE_ENTITLEMENTS.DUPLICATE_ASSESS_ORDER) && !isLiteOrder),
      handler: () => handleCreateDuplicateOrder(rowData),
      title: 'qcHome.createDuplicateOrder',
      isDisabled: loading[DUPLICATE_ORDER],
    },
  ];

  const disableContextualMenu = !menuItems.some((item) => item.show);

  return (
    <div>
      <EVIconButton
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        width={2000}
        data-testid="contextual-menu-button"
        disabled={disableContextualMenu}
      >
        <EVMoreVertIcon />
      </EVIconButton>
      <EVMenu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        getContentAnchorEl={null}
        data-testid="contextual-menu"
      >
        {menuItems.map((item) => {
          if (item.show) {
            return (
              <EVMenuItem onClick={item.handler} key={item.title} disabled={item.isDisabled} data-testid={item.title}>
                {t(item.title)}
              </EVMenuItem>
            );
          }
          return null;
        })}
      </EVMenu>
    </div>
  );
};

QcContextualMenu.propTypes = {
  /**
   * @property {Object} rowData - Object representing the row data
   */
  rowData: PropTypes.shape().isRequired,
};

export default QcContextualMenu;
