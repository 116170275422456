import { makeStyles } from '@eagleview/ev-comp-library';
import { WIND_DIRECTION_ANGLE } from './sidepanel-details/SidePanel.constants';

const useStyles = makeStyles((theme) => ({
  sidepanel: {
    border: 'none',
    zIndex: 6,
  },
  claimDetails: {
    padding: theme.spacing(2.5),
  },
  claimDetailsHeader: {
    overflowWrap: 'anywhere',
  },
  claimId: {
    marginRight: '5px',
  },
  chipStyle: {
    fontSize: theme.spacing(1.25),
    height: theme.spacing(2.5),
    fontWeight: 'bold',
  },
  completedChip: {
    background: theme.evPalette.nickel[100],
    color: theme.evPalette.nickel[700],
  },
  inReviewChip: {
    background: theme.evPalette.huckleberry[100],
    color: theme.evPalette.huckleberry[700],
  },
  dialogBox: {
    width: theme.spacing(40),
    padding: 0,
  },
  scrollableDiv: {
    overflowY: 'scroll',
    height: (p) => (`calc(100vh - ${p.totalHeight}px)`),
  },
  emptyDivHeight: {
    height: '350px',
  },
  detailsTab: {
    padding: `${theme.evSpacing['ev-spacing--8']} ${theme.evSpacing['ev-spacing--16']}`,
    overflowY: 'auto',
  },
  tabs: {
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
  },
  tab: {
    overflowY: 'auto',
  },
  detailsTabSectionText: {
    color: theme.palette.grey[800],
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  thumbnailStyle: {
    width: theme.spacing(10),
    height: theme.spacing(10),
    objectFit: 'cover',
    borderRadius: theme.spacing(1),
  },
  thumbnailSelected: {
    border: `${theme.evSpacing['ev-spacing--4']} solid ${theme.evPalette.huckleberry[500]}`,
    borderRadius: theme.evSpacing['ev-spacing--4'],
    padding: theme.spacing(0.25),
  },
  galleryTab: {
    overflowY: 'auto',
    display: 'grid',
    gridTemplateColumns: 'repeat( auto-fit, minmax(80px, 80px) )',
    gridGap: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  thumbnailWrapper: {
    width: theme.spacing(10),
    height: theme.spacing(10),
    position: 'relative',
  },
  checkbox: {
    color: theme.palette.primary,
    cursor: 'pointer',
  },
  checkboxItem: {
    cursor: 'pointer',
  },
  riskShotStyle: {
    display: 'block',
    width: '100%',
    height: theme.spacing(34.75),
  },
  tabTableText: {
    textTransform: 'unset',
    marginRight: '8px',
  },
  tabTableValues: {
    color: theme.palette.grey[800],
    fontWeight: 'bold',
    textTransform: 'unset',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  tabBar: {
    minWidth: 120,
    textTransform: 'none',
  },
  tabBarNoFocus: {
    '&.Mui-focusVisible': {
      display: 'none',
    },
  },
  thumbnailOverlay: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    background: 'rgba(0,0,0,0.5)',
    width: '100%',
    padding: theme.evSpacing['ev-spacing--4'],
    display: 'flex',
    borderRadius: `0px 0px ${theme.spacing(1)}px ${theme.spacing(1)}px`,
  },
  thumbnailOverlaySelected: {
    borderRadius: `0px 0px ${theme.spacing(0.8)} ${theme.spacing(0.8)}`,
  },
  tagIconStyle: {
    color: theme.evPalette.salt[100],
    display: 'block',
    width: '26px',
    height: 'auto',
    padding: theme.spacing(0.125),
  },
  filtersBox: {
    display: 'flex',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    justifyContent: 'flex-end',
    flexDirection: 'column',
  },
  chip: {
    marginRight: theme.spacing(0.5),
    marginTop: theme.spacing(0.5),
  },
  chipContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  filterButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginBottom: theme.spacing(0.5),
    color: theme.evPalette.salt[100],
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  },
  filterButton: {
    minWidth: '40px',
    width: '40px',
    height: '40px',
    marginLeft: theme.spacing(1),
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: theme.evPalette.ocean[100],
    },
    '&:disabled': {
      backgroundColor: theme.evPalette.charcoal[100],
    },
  },
  stickyHeader: {
    position: 'sticky',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 10,
    backgroundColor: theme.evPalette.salt[100],
  },
  filterButtonIcon: {
    width: theme.evSpacing['ev-spacing--40'],
    color: theme.evPalette.charcoal[600],
  },
  filterButtonDialogOpen: {
    backgroundColor: theme.evPalette.ocean[100],
  },
  filterButtonIconDialogOpen: {
    color: theme.evPalette.ocean[600],
  },
  addressStreetStyle: {
    marginTop: theme.evSpacing['ev-spacing--32'],
  },
  riskShowWrapper: {
    position: 'relative',
  },
  backButton: {
    position: 'absolute',
    top: theme.evSpacing['ev-spacing--4'],
    left: theme.evSpacing['ev-spacing--4'],
    color: theme.palette.common.white,
  },
  iconOpacity: {
    opacity: 0,
  },
  reportIcon: {
    color: theme.evPalette.ocean[500],
  },
  reportLink: {
    cursor: 'pointer',
    color: theme.evPalette.ocean[600],
    marginLeft: theme.spacing(3),
  },
  collapseButton: {
    zIndex: 6,
  },
  windDirectionIcon: {
    color: theme.evPalette.ocean[500],
    fontSize: theme.evSpacing['ev-spacing--16'],
    transform: (P) => `rotate(${WIND_DIRECTION_ANGLE[P.windDirection]}deg)`,
    marginLeft: theme.evSpacing['ev-spacing--8'],
  },
  categoryComponentBox: {
    borderTop: `1px solid ${theme.palette.grey[300]}`,
    paddingLeft: theme.spacing(1),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  categoryComponentTitle: {
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(28),
    color: theme.evPalette.charcoal[900],
    paddingBottom: theme.spacing(2),
  },
  unableToCaptureBox: {
    width: '350px',
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    background: theme.evPalette.charcoal[100],
    borderRadius: theme.spacing(1),
  },
  unableToCaptureText: {
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(18),
    color: theme.evPalette.charcoal[900],
  },
  reasonText: {
    fontSize: theme.typography.pxToRem(14),
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(1),
    color: theme.evPalette.charcoal[900],
  },
  select: {
    minWidth: '300px',
    marginLeft: theme.evSpacing['ev-spacing--12'],
  },
  arrow: {
    marginBottom: theme.evSpacing['ev-spacing--16'],
    color: theme.evPalette.charcoal[600],
    marginRight: theme.evSpacing['ev-spacing--24'],
  },
  menuItem: {
    flex: 'none',
    order: '1',
    flexGrow: '0',
    margin: `${theme.spacing(0.5)}px 0px`,
  },
  categories: {
    position: 'static',
    height: '12px',
    left: '0px',
    right: '0px',
    top: '0px',
    fontFamily: 'Noto Sans',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(16),
    color: theme.evPalette.charcoal[700],
    marginLeft: theme.spacing(2),
  },
  Divider: {
    height: 60,
    marginTop: `-${theme.spacing(1)}px`,
  },
  statusSelect: {
    marginTop: theme.spacing(0.5),
    marginLeft: theme.spacing(0.5),
    '&:focus': {
      backgroundColor: 'transparent',
    },
  },
  iconStatus: {
    marginRight: '16px',
    top: 'calc(50% - 20px)',
  },
  roofReportBox: {
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing(1),
  },
  utc: {
    color: theme.evPalette.charcoal[500],
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(22),
    textAlign: 'center',
    fontWeight: '500',
    marginTop: theme.spacing(3),
  },
  captureType: {
    background: `${theme.evPalette.iceberg[100]}19`,
    color: theme.evPalette.iceberg[700],
    marginRight: theme.spacing(1),
  },
  purchaseReportDialogPaper: {
    height: 'auto',
    width: 575,
  },
  statusLink: {
    cursor: 'pointer',
    marginLeft: theme.spacing(2),
    color: theme.evPalette.charcoal[750],
  },
  infoIcon: {
    color: `${theme.evPalette.charcoal[600]} !important`,
  },
  reportPurchaseStatus: {
    marginLeft: theme.spacing(3),
    fontWeight: 200,
  },
  regenerateReportMessage: {
    background: theme.evPalette.salt[100],
    paddingTop: '12px',
    paddingBottom: '12px',
    color: theme.evPalette.raspberry[600],
    textAlign: 'left',
  },
}));

export default useStyles;
