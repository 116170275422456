import { makeStyles } from '@eagleview/ev-comp-library';

export default makeStyles((theme) => ({
  button: {
    textTransform: 'none',
  },
  crosshair: {
    '& canvas': {
      cursor: 'crosshair',
    },
    cursor: 'crosshair',
  },
  paper: {
    backgroundColor: theme.evPalette.charcoal[100],
  },
  container: {
    height: '95%',
    overflowY: 'scroll',
  },
  orthoImage: {
    left: '25%',
  },
  imageType: {
    color: theme.palette.primary.main,
  },
  images: {
    width: theme.spacing(38),
    height: theme.spacing(25),
  },
  title: {
    marginBottom: '10px',
  },
}));
