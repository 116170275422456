import React from 'react';
import {
  EVButton,
  EVKeyboardArrowUp,
  EVKeyboardArrowDown,
  EVTypography,
} from '@eagleview/ev-comp-library';
import {
  func, node, string, bool,
} from 'prop-types';
import useStyles from './StatusBar.styles';

const StatusBarButton = ({
  active, text, icon, onClick, id, testid,
}) => {
  const styles = useStyles({ active });
  return (
    <EVButton onClick={onClick} className={styles.statusBarButton} id={id} data-testid={testid}>
      {icon}
      <EVTypography variant="body2" className={styles.statusBarText}>
        {text}
      </EVTypography>
      {active ? <EVKeyboardArrowDown data-testid="down-arrow" /> : <EVKeyboardArrowUp data-testid="up-arrow" />}
    </EVButton>
  );
};

StatusBarButton.propTypes = {
  active: bool.isRequired,
  text: string.isRequired,
  icon: node.isRequired,
  onClick: func.isRequired,
  id: string,
  testid: string,
};

StatusBarButton.defaultProps = {
  id: 'button',
  testid: 'test-button',
};

export default StatusBarButton;
