import React from 'react';
import { EVTooltip, EVTypography } from '@eagleview/ev-comp-library';
import { string, number } from 'prop-types';
import { TOOLTIP_DELAY } from 'constants.js';
import clsx from 'clsx';
import useStyles from './WorkflowPanel.styles';

const WorkflowPanelTitle = ({ facetName, facetDecision, area }) => {
  // utility hooks
  const styles = useStyles();

  const colorByStatus = {
    REPLACE: styles.decidedBgColor,
    UNDECIDED: styles.undecidedBgColor,
    REPAIR: styles.decidedBgColor,
    'NO DAMAGE': styles.decidedBgColor,
  };

  return (
    <EVTooltip title={`${facetName} ${area} sq ft`} enterDelay={TOOLTIP_DELAY}>
      <div className={styles.titleContainer}>
        <div className={clsx(styles.titleFacetName, colorByStatus[facetDecision])}>
          <EVTypography id="titleFacetName" variant="h3">
            {facetName}
          </EVTypography>
        </div>
        <EVTypography className={styles.titleFacetAreaStyle} id="titleFacetArea" variant="h4">
          {`${area} sq ft`}
        </EVTypography>
      </div>
    </EVTooltip>
  );
};

WorkflowPanelTitle.propTypes = {
  facetName: string.isRequired,
  facetDecision: string.isRequired,
  area: number.isRequired,
};

export default WorkflowPanelTitle;
