import {
  GENERATE_OTP,
  GENERATE_OTP_SUCCESS,
  GENERATE_OTP_FAILURE,
  VERIFY_OTP,
  VERIFY_OTP_FAILURE,
  VERIFY_OTP_SUCCESS,
  RESEND_OTP,
} from './ShareOTPLoginPage.constant';

const initialState = {
  otp: '',
  optVerified: false,
  availableAttempts: 0,
  validationMessage: '',
  loading: {
    GENERATE_OTP: false,
    VERIFY_OTP: false,
  },
  apiSuccess: {
    GENERATE_OTP: false,
    VERIFY_OTP: false,
  },
  nextAvailableLoginTime: '',
};

const shareOTPLoginReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GENERATE_OTP:
      return {
        ...state,
        loading: {
          ...state.loading,
          [GENERATE_OTP]: true,
        },
        apiSuccess: {
          ...state.apiSuccess,
          [GENERATE_OTP]: false,
        },
      };
    case GENERATE_OTP_SUCCESS:
      return {
        ...state,
        otp: payload.data.otp,
        availableAttempts: payload.data.available_attempts,
        nextAvailableLoginTime: payload.data.next_available_login_time,
        loading: {
          ...state.loading,
          [GENERATE_OTP]: false,
        },
        apiSuccess: {
          ...state.apiSuccess,
          [GENERATE_OTP]: true,
        },
      };
    case GENERATE_OTP_FAILURE:
      return {
        ...state,
        error: payload,
        loading: {
          ...state.loading,
          [GENERATE_OTP]: false,
        },
        apiSuccess: {
          ...state.apiSuccess,
          [GENERATE_OTP]: false,
        },
      };
    case VERIFY_OTP:
      return {
        ...state,
        loading: {
          ...state.loading,
          [VERIFY_OTP]: true,
        },
        apiSuccess: {
          ...state.apiSuccess,
          [VERIFY_OTP]: false,
        },
      };
    case VERIFY_OTP_SUCCESS:
      return {
        ...state,
        optVerified: payload.data.is_valid,
        availableAttempts: payload.data.available_attempts,
        validationMessage: payload.data.message,
        nextAvailableLoginTime: payload.data.next_available_login_time,
        loading: {
          ...state.loading,
          [VERIFY_OTP]: false,
        },
        apiSuccess: {
          ...state.apiSuccess,
          [VERIFY_OTP]: true,
        },
      };
    case VERIFY_OTP_FAILURE:
      return {
        ...state,
        error: payload,
        loading: {
          ...state.loading,
          [VERIFY_OTP]: false,
        },
        apiSuccess: {
          ...state.apiSuccess,
          [VERIFY_OTP]: false,
        },
      };
    case RESEND_OTP:
      return {
        ...state,
        optVerified: false,
        apiSuccess: {
          ...state.apiSuccess,
          [VERIFY_OTP]: false,
        },
        loading: {
          ...state.loading,
          [VERIFY_OTP]: false,
        },
      };
    default:
      return state;
  }
};

export default shareOTPLoginReducer;
