import React from 'react';
import {
  EVBox, EVRadio, EVTypography,
} from '@eagleview/ev-comp-library';
import { bool, func, string } from 'prop-types';
import useStyles from './RejectPanel.styles';

/**
 * Reject panel radio component with a radio button and label
 */
const RejectPanelRadio = ({
  checked, handleRadioSelectChange, radioValue, radioLabel,
}) => {
  const styles = useStyles();

  return (
    <EVBox className={styles.radioContainer}>
      <EVRadio
        id={radioValue}
        checked={checked}
        onChange={handleRadioSelectChange}
        value={radioValue}
      />
      <EVTypography className={styles.radioLabel}>{radioLabel}</EVTypography>
    </EVBox>
  );
};

RejectPanelRadio.propTypes = {
  checked: bool.isRequired,
  handleRadioSelectChange: func.isRequired,
  radioValue: string.isRequired,
  radioLabel: string.isRequired,
};

export default RejectPanelRadio;
