import React, { useState } from 'react';
import { string } from 'prop-types';
import {
  EVDialogActions,
  EVButton,
  EVTypography,
  EVDialog,
  EVBox,
} from '@eagleview/ev-comp-library';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as action from '../../Adjuster.actions';
import useStyles from './QcStructureReject.styles';

const QcStructureReject = ({ orderId, qcPanelType }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  // local state
  const [open, setDialogOpen] = useState(false);

  // selecting values from redux store
  const selectedStructureId = useSelector((state) => state.adjusterReducer.selectedStructureId);
  const rejectedQcStructures = useSelector((state) => state.adjusterReducer.rejectedQcStructures);

  // dispatching actions to reducer
  const dispatch = useDispatch();
  const rejectQcStructureApi = (payload) => dispatch(action.rejectQcStructureApi(payload));
  const undoQcStructureRejectionApi = (payload) => dispatch(action.undoQcStructureRejectionApi(payload));

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleRejectQcStructure = () => {
    rejectQcStructureApi({ orderId, structureId: selectedStructureId, roofSkippedState: 'MANUAL_QC' });
    setDialogOpen(false);
  };

  const handleUndoQcStructureRejection = () => {
    undoQcStructureRejectionApi({ orderId, structureId: selectedStructureId, roofSkippedState: '' });
  };

  return (qcPanelType === 'QC_PENDING' && (
    <>
      {
        rejectedQcStructures.getIn([orderId, selectedStructureId, 'rejected']) === true
          ? (
            <EVBox className={classes.rejectedStructureStatusAndButton}>
              {
                rejectedQcStructures.getIn([orderId, selectedStructureId, 'roofSkippedState']) === 'MANUAL_QC' && (
                  <>
                    <EVTypography className={classes.rejectedStructureStatusStr}>
                      {t('qcPanel.rejectedQcStructureStatusText')}
                    </EVTypography>

                    <EVButton
                      color="primary"
                      variant="contained"
                      className={classes.undoStructureRejectionButton}
                      onClick={handleUndoQcStructureRejection}
                    >
                      {t('qcPanel.undoRejectedQcStructureButtonText')}
                    </EVButton>
                  </>
                )
              }
            </EVBox>
          )
          : (
            <EVButton
              color="primary"
              variant="contained"
              className={classes.rejectStructureButton}
              onClick={handleDialogOpen}
            >
              {t('qcPanel.qcStructureRejectButtonText')}
            </EVButton>
          )
      }

      <EVDialog
        open={open}
        onClose={handleDialogClose}
        fullWidth
        classes={{ paper: classes.paper }}
      >
        <EVTypography
          variant="h6"
          className={classes.rejectStructureMessage}
        >
          {t('qcPanel.qcStructureRejectConfirmation')}
        </EVTypography>
        <EVDialogActions>
          <EVButton
            variant="contained"
            color="primary"
            onClick={handleDialogClose}
          >
            {t('cancel')}
          </EVButton>
          <EVButton
            variant="contained"
            color="primary"
            onClick={handleRejectQcStructure}
          >
            {t('confirm')}
          </EVButton>
        </EVDialogActions>
      </EVDialog>

    </>
  ));
};

QcStructureReject.propTypes = {
  orderId: string.isRequired,
  qcPanelType: string.isRequired,
};

export default QcStructureReject;
