import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  EVBox,
  EVTypography,
  EVTextField,
  EVButton,
  EVDialogTitle,
  EVDialogContent,
  EVDialogActions,
  EVDialog,
} from '@eagleview/ev-comp-library';
import { useTranslation } from 'react-i18next';
import { func, bool } from 'prop-types';
import { get } from 'lodash';
import { REMEASURE_WRONG_HOUSE_FLAG } from 'layout/entitleUser/EntitleUser.constants';
import useStyles from './RejectPanel.styles';
import RejectPanelRadio from './RejectPanelRadio';
import {
  rejectReasonToTranslation,
  rejectReasonToId,
  ORDER_STATUS_MANUAL_ADJUSTMENT_INITIATED,
  ORDER_STATUS_ASSESS_ESCALATION,
  ORDER_STATUS_UNDER_REVIEW,
  REJECTED_WRONG_HOUSE,
  REJECTED_REMEASURE_WRONG_HOUSE,
  REJECTED_NO_USABLE_POINTS,
  REJECTED_OTHER,
  REJECTED_REQUEUE,
  REJECTED_REMEASURE,
  REJECTED_NEEDS_REVIEW,
  STATUS_INCOMPLETE,
} from '../ManualAtAdjustment.constants';
import * as action from '../ManualAtAdjustment.actions';

let enableRemeasureWrongHouse = false;
const getRejectReasonsBasedOnOrderStatus = (status) => {
  switch (status) {
    case ORDER_STATUS_MANUAL_ADJUSTMENT_INITIATED:
      return [
        REJECTED_WRONG_HOUSE, REJECTED_NO_USABLE_POINTS, REJECTED_OTHER,
      ];
    case ORDER_STATUS_ASSESS_ESCALATION:
      if (enableRemeasureWrongHouse === true) {
        return [
          REJECTED_REQUEUE, REJECTED_REMEASURE, REJECTED_NEEDS_REVIEW, REJECTED_REMEASURE_WRONG_HOUSE,
        ];
      }
      return [
        REJECTED_REQUEUE, REJECTED_REMEASURE, REJECTED_NEEDS_REVIEW,
      ];
    case ORDER_STATUS_UNDER_REVIEW:
      return [
        REJECTED_REQUEUE, REJECTED_REMEASURE,
      ];
    default:
      return [];
  }
};

const RejectPanel = ({ closeRejectPanel, openRejectPanel }) => {
  // selectors
  const coordinatePairings = useSelector((state) => get(state, 'manualAtMultiStructureReducer.coordinatePairings'));
  const taskStateId = useSelector((state) => get(state, 'manualAtMultiStructureReducer.taskStateId', ''));
  const orderStatus = useSelector((state) => get(state, 'manualAtMultiStructureReducer.orderDetails.orderStatus'));
  const selectedImagesList = useSelector((state) => get(state, 'manualAtMultiStructureReducer.selectedImagesList'));
  const selectedStructure = useSelector((state) => get(state, 'manualAtMultiStructureReducer.selectedStructure', {}));
  const structureReportMap = useSelector((state) => get(state, 'manualAtMultiStructureReducer.structureReportMap', {}));
  enableRemeasureWrongHouse = useSelector((state) => get(state.entitleUserReducer.featureFlags, REMEASURE_WRONG_HOUSE_FLAG, false));

  // redux actions
  const dispatch = useDispatch();
  const reject = (payload) => dispatch(action.rejectManualATOrderAction(payload));
  const showEmptyRejectNotesError = () => dispatch(action.showEmptyRejectNotesError());
  const showRejectReasonNotSelectedError = () => dispatch(action.showRejectReasonNotSelectedError());
  // local state
  const [rejectReason, setRejectReason] = useState('');

  const [note, setNote] = useState('');

  // utility hooks
  const params = useParams();
  const { t } = useTranslation();
  const styles = useStyles();

  const selectedStructureId = selectedStructure.structureID || '';
  const handleRejectReasonChange = (event) => {
    setRejectReason(event.target.value);
  };

  const handleNoteChange = (event) => {
    setNote(event.target.value);
  };

  const handleCancelButton = () => {
    closeRejectPanel();
  };

  const handleSubmitButton = () => {
    if (rejectReason === '') {
      showRejectReasonNotSelectedError();
      return;
    }

    if (note === '') {
      showEmptyRejectNotesError();
      return;
    }

    reject({
      omBody: {
        status: STATUS_INCOMPLETE,
        note,
        pairing: coordinatePairings.map((data) => ({
          roofPointCoordinates: {
            geometry: {
              type: 'Point',
              coordinates: [parseFloat(data.dst.x.toFixed(13)), parseFloat(data.dst.y.toFixed(13)), parseFloat(data.dst.z.toFixed(13))],
            },
            srid: 4326,
          },
          imagePoints: Object.keys(data.src).map((srcImage) => ({
            pixelCoords: [parseInt(data.src[srcImage].pixel.x, 10), parseInt(data.src[srcImage].pixel.y, 10)],
            geometry: {
              type: 'Point',
              coordinates: [parseFloat(data.src[srcImage].lonLat.lon.toFixed(13)), parseFloat(data.src[srcImage].lonLat.lat.toFixed(13))],
            },
            image: srcImage,
          })),
        })),
        selectedImages: selectedImagesList,
        reportID: structureReportMap[selectedStructureId],
      },
      opBody: {
        rejectNotes: note,
      },
      rejectReasonId: rejectReasonToId[rejectReason],
      rejectReason,
      taskStateId,
      orderId: params.orderId,
      structureId: selectedStructureId,
    });
    closeRejectPanel();
  };

  return (
    <>
      <EVDialog
        open={openRejectPanel}
        onClose={closeRejectPanel}
        fullWidth
        data-testid="reject-panel"
      >
        <EVDialogTitle className={styles.title}>
          {t('manualAtRejectPanel.title')}
        </EVDialogTitle>
        <EVDialogContent>
          {getRejectReasonsBasedOnOrderStatus(orderStatus).map((reason) => (
            <RejectPanelRadio
              key={reason}
              checked={rejectReason === reason}
              handleRadioSelectChange={handleRejectReasonChange}
              radioValue={reason}
              radioLabel={t(rejectReasonToTranslation[reason])}
            />
          ))}

          <EVBox className={styles.notesContainer}>
            <EVTypography className={styles.notesTitle}>
              {t('manualAtRejectPanel.notesTitle')}
            </EVTypography>
            <EVTextField
              rows={5}
              multiline
              variant="outlined"
              placeholder={t('manualAtRejectPanel.notesPlaceholder')}
              value={note}
              onChange={handleNoteChange}
              className={styles.notesField}
              fullWidth
              data-testid="reject-note"
            />
          </EVBox>
        </EVDialogContent>
        <EVDialogActions>
          <EVButton color="primary" variant="contained" onClick={handleCancelButton}>
            {t('manualAtRejectPanel.cancel')}
          </EVButton>
          <EVButton color="primary" variant="contained" onClick={handleSubmitButton}>
            {t('manualAtRejectPanel.submit')}
          </EVButton>
        </EVDialogActions>
      </EVDialog>
    </>
  );
};

RejectPanel.propTypes = {
  closeRejectPanel: func.isRequired,
  openRejectPanel: bool.isRequired,
};

export default RejectPanel;
