import React from 'react';
import {
  EVPaperClipIcon, EVSwitch, EVTypography, EVBox,
} from '@eagleview/ev-comp-library';
import { bool, func } from 'prop-types';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import useStyles from './ReportSwitchRawImagery.styles';

const ReportSwitch = ({
  included, setIncluded, disabled,
}) => {
  const { t } = useTranslation();
  const styles = useStyles();
  return (
    <EVBox className={clsx({ [styles.statusBarIncluded]: true })}>
      <EVPaperClipIcon className={included ? styles.activeIcon : styles.inactiveIcon} />
      <EVSwitch color="primary" disabled={disabled} checked={included} onChange={() => setIncluded(!included)} />
      <EVTypography variant="body2">{t('rawImagery.includeInReportText')}</EVTypography>
    </EVBox>
  );
};

ReportSwitch.propTypes = {
  included: bool.isRequired,
  setIncluded: func.isRequired,
  disabled: bool,
};

ReportSwitch.defaultProps = {
  disabled: false,
};

export default ReportSwitch;
