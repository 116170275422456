import React, { useEffect } from 'react';
import {
  EVButton,
  EVBox,
} from '@eagleview/ev-comp-library';
import EntitledComponent from 'components/EntitledComponent';
import { FEATURE_ENTITLEMENTS, ASSESS_DIGITAL_DELIVERY } from 'layout/entitleUser/EntitleUser.constants';
import { useDispatch, useSelector } from 'react-redux';
import { isEntitled } from 'utils/auth.utils';
import PropType from 'prop-types';
import { useTranslation } from 'react-i18next';
import { get } from 'lodash';
import * as action from '../Adjuster.actions';
import { POST_REPORT, ORDER_SUMMARY_REPORT_PENDO_EVENT } from '../Adjuster.constants';
import * as util from '../Adjuster.utils';

const ReportFooter = ({ orderId, styles }) => {
  // selectors
  const loading = useSelector((state) => state.adjusterReducer.loading);
  const entitlements = useSelector((state) => state.entitleUserReducer.entitlements);
  const assessDigitalDeliveryrFF = useSelector((state) => get(state.entitleUserReducer.featureFlags, ASSESS_DIGITAL_DELIVERY, false));
  const isDisabledAfterClicked = useSelector((state) => state.adjusterReducer.disableGenerateReport);
  const canGenerateReport = useSelector((state) => state.adjusterReducer.canGenerateReport);

  // actions
  const dispatch = useDispatch();
  const postReport = () => dispatch(action.postReport(orderId));
  const getReportAttemptsAction = () => dispatch(action.getReportAttempts(orderId));

  // constants
  const isSharedUrl = window.location.origin === process.env.REACT_APP_ASSESS_SHAREDURL_APP_ORIGIN;

  const handleSubmit = () => {
    util.publishPendoEvent(ORDER_SUMMARY_REPORT_PENDO_EVENT, { orderId });
    postReport();
  };

  useEffect(() => {
    if (isSharedUrl) {
      getReportAttemptsAction();
    }
  }, []);

  // utility hooks
  const { t } = useTranslation();
  return (
    <EntitledComponent entitlement={FEATURE_ENTITLEMENTS.VIEW_REPORTS}>
      <EVBox className={styles.reportFooter}>
        <EVButton
          id="generateReport"
          onClick={() => (isEntitled(entitlements, FEATURE_ENTITLEMENTS.MANAGE_REPORTS)
            || isEntitled(entitlements, FEATURE_ENTITLEMENTS.GENERATE_REPORTS_INTERNAL)
            || assessDigitalDeliveryrFF) && handleSubmit()}
          className={styles.button}
          color="primary"
          variant="contained"
          disabled={((!isEntitled(entitlements, FEATURE_ENTITLEMENTS.MANAGE_REPORTS)
                && !isEntitled(entitlements, FEATURE_ENTITLEMENTS.GENERATE_REPORTS_INTERNAL)
                && !assessDigitalDeliveryrFF)
                || loading[POST_REPORT])
                || isDisabledAfterClicked
                || (isSharedUrl && !canGenerateReport)}
          disableElevation
        >
          {loading[POST_REPORT] ? t('report.generating') : t('report.generate')}
        </EVButton>
      </EVBox>
    </EntitledComponent>
  );
};

ReportFooter.propTypes = {
  orderId: PropType.string.isRequired,
  styles: PropType.shape({
    reportFooter: PropType.string,
    button: PropType.string,

  }).isRequired,
};

export default ReportFooter;
